import { Box, styled, Typography } from "@mui/material";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import PLModal from "components/patternLib/PLModal";
import React, { useState } from "react";
import theme from "themes/theme";
import translations from "translations";
import useHandleSaveConfig from "./useHandleSaveConfig";

const OvertakeButtonSC = styled("div")({
  display: "flex",
  flexDirection: "row-reverse",
  marginTop: theme.spacing(6),
});
interface ModalConfirmationHandlerProps {
  currentDeviceId: string;
  otherDeviceId: string | undefined;
  otherDeviceNameAndConf: { config: string | undefined; deviceName: string | undefined };
  setCloseTable: (isOpen: boolean) => void;
}
const ModalConfirmationHandler: React.FC<ModalConfirmationHandlerProps> = ({
  otherDeviceId,
  currentDeviceId,
  otherDeviceNameAndConf,
  setCloseTable,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSaveConfig = useHandleSaveConfig(currentDeviceId);

  return (
    <>
      <OvertakeButtonSC>
        <PLButton
          iconPosition="right"
          label={
            translations.pages.deviceManagement.deviceDetails.deviceConfiguration.overtakeConfig.tableModalButtonLabel
          }
          disabled={!otherDeviceId}
          onClick={() => setIsModalOpen(true)}
        >
          <PLIcon iconName="font-arrow" slot="icon" />
        </PLButton>
      </OvertakeButtonSC>
      <PLModal
        showIcon
        allowCloseOnBackdrop
        modalTitle={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.overtakeConfig.modalTitle}
        lhCancel={() => setIsModalOpen(false)}
        lhConfirm={async () => {
          await handleSaveConfig(otherDeviceNameAndConf.config);
          setIsModalOpen(false);
          setCloseTable(false);
        }}
        lhClose={() => setIsModalOpen(false)}
        show={isModalOpen}
        iconName="filter-reset"
      >
        <>
          <Box slot="modal-content">
            <Typography variant="copy">
              {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.overtakeConfig.message}
              {otherDeviceNameAndConf ? otherDeviceNameAndConf.deviceName : ""}.{" "}
              {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.cloudMessage}
            </Typography>
          </Box>
        </>
      </PLModal>
    </>
  );
};

export default ModalConfirmationHandler;
