import { useGetDevicesWithLowBatteryQuery } from "graphqlBase/sensorMeasurementCurrentValues/__generated__/getDevicesWithLowBattery";
import { useCallback } from "react";
import { Device, DeviceListItem } from ".";

const useGetDevicesWithLowBattery = (devices: Device[] | undefined) => {
  const { data: valuesLowBattery, loading: valuesLowBatteryLoading } = useGetDevicesWithLowBatteryQuery();

  const devicesWithLowBattery = useCallback(() => {
    if (!devices || !valuesLowBattery) return;

    const deviceIds: string[] = valuesLowBattery.sensorMeasurementCurrentValues.map((item) => item.deviceId);

    return devices?.reduce<DeviceListItem[]>((devicesLowBattery, current) => {
      if (devicesLowBattery.length < 5) {
        if (deviceIds.find((id) => current.id === id)) {
          const batteryValue =
            valuesLowBattery?.sensorMeasurementCurrentValues.find((value) => value.deviceId === current.id)
              ?.valueFloat ?? 0;
          return devicesLowBattery.concat({
            batteryValue,
            deviceName: current.deviceName ?? "",
            deviceId: current.id,
          });
        }
      }
      return devicesLowBattery;
    }, []);
  }, [valuesLowBattery, devices]);

  return {
    devicesLowBattery: devicesWithLowBattery(),
    totalLowBatteryDevicesCount: valuesLowBattery?.sensorMeasurementCurrentValues?.length ?? 0,
    loading: valuesLowBatteryLoading,
  };
};

export default useGetDevicesWithLowBattery;
