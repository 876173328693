import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceIdsForConnectedDevicesToGatewayQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.SensorMeasurementCurrentValueFilterInput>;
  order?: Types.InputMaybe<Array<Types.SensorMeasurementCurrentValueSortInput> | Types.SensorMeasurementCurrentValueSortInput>;
}>;


export type GetDeviceIdsForConnectedDevicesToGatewayQuery = { sensorMeasurementCurrentValues: Array<{ __typename?: 'SensorMeasurementCurrentValue', id: string, deviceId: string }> };


export const GetDeviceIdsForConnectedDevicesToGatewayDocument = gql`
    query getDeviceIdsForConnectedDevicesToGateway($skip: Int, $take: Int, $where: SensorMeasurementCurrentValueFilterInput, $order: [SensorMeasurementCurrentValueSortInput!]) {
  sensorMeasurementCurrentValues(skip: $skip, take: $take, where: $where, order: $order) {
    id
    deviceId
  }
}
    `;

/**
 * __useGetDeviceIdsForConnectedDevicesToGatewayQuery__
 *
 * To run a query within a React component, call `useGetDeviceIdsForConnectedDevicesToGatewayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceIdsForConnectedDevicesToGatewayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceIdsForConnectedDevicesToGatewayQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDeviceIdsForConnectedDevicesToGatewayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceIdsForConnectedDevicesToGatewayQuery, GetDeviceIdsForConnectedDevicesToGatewayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceIdsForConnectedDevicesToGatewayQuery, GetDeviceIdsForConnectedDevicesToGatewayQueryVariables>(GetDeviceIdsForConnectedDevicesToGatewayDocument, options);
      }
export function useGetDeviceIdsForConnectedDevicesToGatewayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceIdsForConnectedDevicesToGatewayQuery, GetDeviceIdsForConnectedDevicesToGatewayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceIdsForConnectedDevicesToGatewayQuery, GetDeviceIdsForConnectedDevicesToGatewayQueryVariables>(GetDeviceIdsForConnectedDevicesToGatewayDocument, options);
        }
export type GetDeviceIdsForConnectedDevicesToGatewayQueryHookResult = ReturnType<typeof useGetDeviceIdsForConnectedDevicesToGatewayQuery>;
export type GetDeviceIdsForConnectedDevicesToGatewayLazyQueryHookResult = ReturnType<typeof useGetDeviceIdsForConnectedDevicesToGatewayLazyQuery>;
export type GetDeviceIdsForConnectedDevicesToGatewayQueryResult = Apollo.QueryResult<GetDeviceIdsForConnectedDevicesToGatewayQuery, GetDeviceIdsForConnectedDevicesToGatewayQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
