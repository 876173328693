import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersForPreviousDeviceConfigurationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUsersForPreviousDeviceConfigurationsQuery = { users: Array<{ __typename?: 'User', id: string, userName: string }> };


export const GetUsersForPreviousDeviceConfigurationsDocument = gql`
    query GetUsersForPreviousDeviceConfigurations {
  users {
    id
    userName
  }
}
    `;

/**
 * __useGetUsersForPreviousDeviceConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetUsersForPreviousDeviceConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForPreviousDeviceConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForPreviousDeviceConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersForPreviousDeviceConfigurationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersForPreviousDeviceConfigurationsQuery, GetUsersForPreviousDeviceConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUsersForPreviousDeviceConfigurationsQuery, GetUsersForPreviousDeviceConfigurationsQueryVariables>(GetUsersForPreviousDeviceConfigurationsDocument, options);
      }
export function useGetUsersForPreviousDeviceConfigurationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersForPreviousDeviceConfigurationsQuery, GetUsersForPreviousDeviceConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUsersForPreviousDeviceConfigurationsQuery, GetUsersForPreviousDeviceConfigurationsQueryVariables>(GetUsersForPreviousDeviceConfigurationsDocument, options);
        }
export type GetUsersForPreviousDeviceConfigurationsQueryHookResult = ReturnType<typeof useGetUsersForPreviousDeviceConfigurationsQuery>;
export type GetUsersForPreviousDeviceConfigurationsLazyQueryHookResult = ReturnType<typeof useGetUsersForPreviousDeviceConfigurationsLazyQuery>;
export type GetUsersForPreviousDeviceConfigurationsQueryResult = Apollo.QueryResult<GetUsersForPreviousDeviceConfigurationsQuery, GetUsersForPreviousDeviceConfigurationsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
