import { atom } from "recoil";

export const toastStateAtom = atom<
  | {
      message: string;
      open: boolean;
      severity: "success" | "error";
    }
  | undefined
>({
  key: "toastStateAtom",
  default: undefined,
});
