import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLHeaderPortalProps = DropOnONLh<JSX.PatternlibHeaderPortal>;
export type PLHeaderPortalExtendedProps = PLHeaderPortalProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedHeaderPortal = wrapWc<JSX.PatternlibHeaderPortal>("patternlib-header-portal");
export const useRefPLHeaderPortal = () => useRef<HTMLPatternlibHeaderPortalElement>(null);

const PLHeaderPortal = React.forwardRef<HTMLPatternlibHeaderPortalElement, PLHeaderPortalExtendedProps>((props, ref) => {
  const myRef = useRefPLHeaderPortal();
  return (
    <WrappedHeaderPortal ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedHeaderPortal>
  );
});
PLHeaderPortal.displayName = "PLHeaderPortal";

export default PLHeaderPortal;
