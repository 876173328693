import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import PLPill from "components/patternLib/PLPill";
import { styled } from "@mui/system";

interface TagChipWithTooltipProps {
  tagValue: string;
}

const TagChipContainerSC = styled("div")(
  () => `
      margin-right: 8px;
      min-width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 25px;
        
`
);

const TagChipWithTooltip: React.FC<TagChipWithTooltipProps> = ({ tagValue }) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    if (textElementRef.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip title={tagValue} placement="bottom-start" disableHoverListener={!hoverStatus}>
      <TagChipContainerSC ref={textElementRef}>
        <PLPill label={tagValue} height="22px" noStates />
      </TagChipContainerSC>
    </Tooltip>
  );
};
export default TagChipWithTooltip;
