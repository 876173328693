export default {
  name: "gateway device connection",
  plural: "gateway device connections",
  columns: {
    device: "device",
    deviceId: "device ID",
    gatewayDeviceName: "gateway device name",
    id: "ID",
    isDeleted: "is deleted",
    primaryAuthenticationKey: "primary Authentication Key",
    primaryConnectionString: "primary Connection String",
    secondaryAuthenticationKey: "secondary Authentication Key",
    secondaryConnectionString: "secondary Connection String",
    tenantId: "tenant ID",
  },
};
