import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceFirmwaresQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFirmwareFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetDeviceFirmwaresQuery = { deviceFirmwares: Array<{ __typename?: 'DeviceFirmware', id: string, firmwareVersion: string, firmwareRevision: string, description?: Maybe<string>, releaseDate: string, unlockRelease?: Maybe<boolean>, deviceFirmwareReleaseType: { __typename?: 'DeviceFirmwareReleaseType', id: string, deviceFirmwareReleaseTypeName: string } }> };


export const GetDeviceFirmwaresDocument = gql`
    query getDeviceFirmwares($where: DeviceFirmwareFilterInput, $take: Int, $skip: Int) {
  deviceFirmwares(where: $where, take: $take, skip: $skip) {
    id
    firmwareVersion
    firmwareRevision
    description
    releaseDate
    unlockRelease
    deviceFirmwareReleaseType {
      id
      deviceFirmwareReleaseTypeName
    }
  }
}
    `;

/**
 * __useGetDeviceFirmwaresQuery__
 *
 * To run a query within a React component, call `useGetDeviceFirmwaresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceFirmwaresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceFirmwaresQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetDeviceFirmwaresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>(GetDeviceFirmwaresDocument, options);
      }
export function useGetDeviceFirmwaresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>(GetDeviceFirmwaresDocument, options);
        }
export type GetDeviceFirmwaresQueryHookResult = ReturnType<typeof useGetDeviceFirmwaresQuery>;
export type GetDeviceFirmwaresLazyQueryHookResult = ReturnType<typeof useGetDeviceFirmwaresLazyQuery>;
export type GetDeviceFirmwaresQueryResult = Apollo.QueryResult<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
