import colors from "../colors";

const commonCheckboxStyles = {
  border: "2px solid black",
  borderRadius: "0px",
  height: "1.2em",
  margin: "0.3em 0.3em 0.3em 0.3em",
  width: "1.2em",
  color: colors.tBlack,
  "& svg": { fill: "none" },
  "&.Mui-disabled": {
    opacity: 0.5,
    borderColor: "black",
    "&:hover": {
      borderColor: "black",
    },
    "&.Mui-checked": {
      backgroundColor: colors.lbcGrey,
      borderColor: colors.lbcGrey,
      "&:hover": {
        backgroundColor: colors.lbcGrey,
        borderColor: colors.lbcGrey,
      },
    },
  },
};

export const muiCheckboxOverrides = {
  root: {
    ...commonCheckboxStyles,
  },
  checked: {
    backgroundColor: colors.brandYellow,
    borderColor: colors.brandYellow,
    color: colors.tBlack,
    "&:hover": {
      backgroundColor: colors.brandYellow,
    },
  },
};
