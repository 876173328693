import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import de from "./de";
import en from "./en";

export type TranslationStrings = typeof de;

type IStrings = LocalizedStringsMethods & TranslationStrings;

export const languages = {
  // de,
  en,
};

export const translations: IStrings = new LocalizedStrings(languages, {
  customLanguageInterface: () => navigator.language,
});

export const getStringsSave = (value: string) => {
  return translations.getString(value, translations.getLanguage(), true);
};

export default translations;

export const languageNames = {
  en: "English",
  // de: "Deutsch",
};
