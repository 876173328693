import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLCheckboxProps = DropOnONLh<JSX.PatternlibCheckbox>;
export type PLCheckboxExtendedProps = PLCheckboxProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedCheckbox = wrapWc<JSX.PatternlibCheckbox>("patternlib-checkbox");
export const useRefPLCheckbox = () => useRef<HTMLPatternlibCheckboxElement>(null);

const PLCheckbox = React.forwardRef<HTMLPatternlibCheckboxElement, PLCheckboxExtendedProps>((props, ref) => {
  const myRef = useRefPLCheckbox();
  return (
    <WrappedCheckbox ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedCheckbox>
  );
});
PLCheckbox.displayName = "PLCheckbox";

export default PLCheckbox;
