export default {
  name: "device type",
  plural: "device types",
  columns: {
    device: "Device",
    deviceTypeName: "Device Type Name",
    fieldDeviceIdFieldSelector: "Field Device ID Field Selector",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "Tenant ID",
  },
};
