import client from "apollo/client";
import { GetAssetNameByDeviceIdDocument } from "graphqlBase/Assets/__generated__/getAssetNameByDeviceId";
import { GetUserInfoByIdDocument } from "graphqlBase/Settings/__generated__/getUserInfoById";
import translations from "translations";

export const getUserById = (id: string) =>
  client
    .query({
      query: GetUserInfoByIdDocument,
      variables: { id },
    })
    .then((result) => {
      return result.data.user;
    })
    .catch(() => {
      return "";
    });

export const getAssetByDeviceId = (deviceId: string) => {
  return client
    .query({
      query: GetAssetNameByDeviceIdDocument,
      variables: { where: { deviceId: { eq: deviceId } } },
    })
    .then((result) => {
      return result?.data?.assetDevices?.[0]?.asset;
    })
    .catch(() => {
      return "";
    });
};

export const getBatteryHealthInfo = (voltage: number | null) => {
  return {
    label: !voltage ? translations.globals.terms.unknown : `${voltage} V`,
    type: !voltage ? "default" : voltage > 3 ? "success" : "alert",
  };
};
