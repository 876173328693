import React from "react";
import { useParams } from "react-router-dom";
import AssetGallery from "./AssetGallery";
import AssetAssignmentTable from "./AssetAssignmentTable";

const AssetAssignment = () => {
  const { assetId }: { assetId: string } = useParams();
  return (
    <div style={{ backgroundColor: "white", padding: "30px 0" }}>
      <div style={{ marginBottom: "40px" }}>
        <AssetGallery assetId={assetId} />
      </div>

      <AssetAssignmentTable assetId={assetId} />
    </div>
  );
};

export default AssetAssignment;
