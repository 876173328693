/* eslint-disable react/jsx-max-depth */
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField/TextField";
import {
  GetMappingRoleScopesForRoleQuery,
  useGetMappingRoleScopesForRoleLazyQuery,
  useGetMappingRoleScopesForRoleQuery,
} from "graphqlBase/MappingRoleScope/__generated__/getMappingRoleScopesForRole";
import { GetScopesQuery, ResultType, useGetScopesQuery } from "graphqlBase/Scope/__generated__/getScopes";
import { SortEnumType } from "graphqlBase/types.d";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { AreaRows } from "./AudienceRows";
import {
  MappingRoleScopesExtendedRegistry,
  mappingRoleScopesState,
} from "../../RecoilState/MappingRoleScopesTableState";
import { TableTitle } from "../TableTitle";
import { InputAdornment, TablePagination } from "@mui/material";
export type Scope = ResultType<GetScopesQuery["scopes"]>;
export type MappingRoleScopes = ResultType<GetMappingRoleScopesForRoleQuery["mappingRoleScopes"]>;

export interface Audience {
  Create?: Scope;
  Delete?: Scope;
  Read?: Scope;
  Update?: Scope;
}
export interface Area {
  [key: string]: Audience;
}

export interface Areas {
  [key: string]: Area;
}

export const audiences = ["Own", "Tenant", "All"];
export const operations: Array<keyof Audience> = ["Read", "Update", "Create", "Delete"];

const flatToTree = ({ flatData, filter }: { filter: string; flatData: Scope[] }): Areas => {
  const areas: Areas = {};

  flatData.forEach((flatNode) => {
    const [areaId, operationId, audienceId] = flatNode.value.split(".");
    if (!areaId.includes(filter)) return;
    if (!(areaId in areas)) {
      areas[areaId] = {};
    }

    if (!(audienceId in areas[areaId])) {
      areas[areaId][audienceId] = {};
    }
    areas[areaId][audienceId][operationId as keyof Audience] = flatNode;
  });

  return areas;
};

const { permissions } = translations.pages.userManagement.userRoleManager;

const MappingRoleScopesTable: React.FC<{ disabled: boolean; roleId: string }> = ({ roleId, disabled }) => {
  const [filter, setFilter] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setPage(0);

  };
  const { data } = useGetScopesQuery({
    variables: { order: [{ value: SortEnumType.Asc }] },
    // variables: { order: [{ value: SortEnumType.Asc }], where: { value: { ncontains: "Home/" } } },
    fetchPolicy: "cache-and-network",
  });
  const rowsForTable = React.useMemo(() => {
    return flatToTree({ flatData: data?.scopes ?? [], filter });
  }, [data, filter]);
  const setMappings = useSetRecoilState(mappingRoleScopesState);
  const [query, { data: mappingRoleScopesForRole }] = useGetMappingRoleScopesForRoleLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (!roleId) return;
    query({ variables: { roleId } });
  }, [query, roleId]);
  useEffect(() => {
    if (mappingRoleScopesForRole?.mappingRoleScopes) {
      const mappings = (mappingRoleScopesForRole?.mappingRoleScopes ?? []).reduce<MappingRoleScopesExtendedRegistry>(
        (nextMappingState, mapping) => {
          return { ...nextMappingState, [mapping.scopeId]: mapping };
        },
        {}
      );
      setMappings(mappings);
    }
  }, [mappingRoleScopesForRole, setMappings]);

  return (
    <div style={{ paddingTop: "30px" }}>
      {TableTitle(permissions)}
      <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  value={filter}
                  onChange={handleChange}
                  variant="outlined"
                />
              </TableCell>
              {operations.map((operation) => (
                <TableCell key={`${operation}`} align="left">{`${operation}`}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(rowsForTable)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(([areaName, area]) => (
                <AreaRows areaName={areaName} key={areaName} area={area} roleId={roleId} disabled={disabled} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={Object.entries(rowsForTable).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default MappingRoleScopesTable;
