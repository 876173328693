import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetRecentNotificationCountForBadgeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRecentNotificationCountForBadgeQuery = { deviceNotifications: Array<{ __typename?: 'DeviceNotification', id: string }> };


export const GetRecentNotificationCountForBadgeDocument = gql`
    query getRecentNotificationCountForBadge {
  deviceNotifications {
    id
  }
}
    `;

/**
 * __useGetRecentNotificationCountForBadgeQuery__
 *
 * To run a query within a React component, call `useGetRecentNotificationCountForBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentNotificationCountForBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentNotificationCountForBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentNotificationCountForBadgeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecentNotificationCountForBadgeQuery, GetRecentNotificationCountForBadgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRecentNotificationCountForBadgeQuery, GetRecentNotificationCountForBadgeQueryVariables>(GetRecentNotificationCountForBadgeDocument, options);
      }
export function useGetRecentNotificationCountForBadgeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecentNotificationCountForBadgeQuery, GetRecentNotificationCountForBadgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRecentNotificationCountForBadgeQuery, GetRecentNotificationCountForBadgeQueryVariables>(GetRecentNotificationCountForBadgeDocument, options);
        }
export type GetRecentNotificationCountForBadgeQueryHookResult = ReturnType<typeof useGetRecentNotificationCountForBadgeQuery>;
export type GetRecentNotificationCountForBadgeLazyQueryHookResult = ReturnType<typeof useGetRecentNotificationCountForBadgeLazyQuery>;
export type GetRecentNotificationCountForBadgeQueryResult = Apollo.QueryResult<GetRecentNotificationCountForBadgeQuery, GetRecentNotificationCountForBadgeQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
