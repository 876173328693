import React from "react";

export const removedEntities = <T extends { id: string }>(
  mappingArray: Array<T>,
  selectedRows: Record<string, boolean>,
  idSelector: (elem: T) => string
  // eslint-disable-next-line max-params
) => {
  return mappingArray.reduce((acc: string[], item) => {
    const fKId = idSelector(item);

    const isEntitySelected = Object.keys(selectedRows).find((selectedEntity) => selectedEntity === fKId);
    if (!isEntitySelected) {
      acc.push(item.id);
    }
    return acc;
  }, []);
};

export const preSelectedEntity = <T,>(array: T[], idSelector: (elem: T) => string) => {
  return array.reduce((acc, curr) => {
    const fKId = idSelector(curr);
    return {
      ...acc,
      [fKId]: true,
    };
  }, {});
};
