import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  GridColDef,
  GridRowId,
  GridRowIdGetter,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridSlotsComponentsProps,
} from "@mui/x-data-grid-pro";
import PLIcon from "components/patternLib/PLIcon";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { CheckboxCell } from "../Cells";
import { FooterWithPagination, NoPaginationFooter } from "../Footers";
import LoadingOverlay from "../LoadingOverlay";
import { StyledContainer, StyledDataGrid, tableComponentStyles } from "../styles";
import { Toolbar } from "../Toolbars";
import NoDataOverlay from "./NoDataOverlay";

export const testIDs = getTestIDs();

const theme = createTheme({
  palette: {
    primary: { main: "#000000" },
  },
});

interface DataGridComponentProps {
  checkboxSelection?: boolean;
  columns: GridColDef[];
  getRowId?: GridRowIdGetter<any> | undefined;
  handleDeleteRows?: (ids: string[]) => void;
  handleEditRow?: (id: string) => void;
  handleOnRowClick?: (params: GridRowParams) => void;
  hasPagination?: boolean;
  hasToolbar?: boolean;
  hideToolbar?: boolean;
  loading?: boolean;
  noDataMessage: string;
  rowEvents?: GridSlotsComponentsProps["row"];
  rows: GridRowsProp;
  selectedRows?: GridRowId[] | undefined;
  setIsTagModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRows?: (rows: GridRowsProp) => void;
  tableContainerMargin?: string;
  tableHeight: string;
  tableTitle?: string;
  withMargin?: boolean;
}

const Table: React.FC<DataGridComponentProps> = ({
  rows,
  columns,
  checkboxSelection = true,
  handleOnRowClick,
  selectedRows,
  hasPagination = true,
  hasToolbar = true,
  setIsTagModalOpen,
  setSelectedRows,
  tableTitle,
  noDataMessage,
  handleDeleteRows,
  handleEditRow,
  loading,
  tableHeight,
  getRowId,
  tableContainerMargin,
  withMargin,
  rowEvents,
}) => {
  const FilterIcon = () => {
    return <PLIcon size="18px" iconName="filter-adjust" />;
  };
  const RemoveIcon = () => {
    return <PLIcon size="small" iconName="x" />;
  };
  const QuickFilterIcon = () => {
    return <PLIcon size="18px" iconName="search" />;
  };

  const NoDataComponent = () => <NoDataOverlay message={noDataMessage} />;

  const customComponents = {
    Toolbar: hasToolbar ? Toolbar : null,
    BaseCheckbox: CheckboxCell,
    OpenFilterButtonIcon: FilterIcon,
    QuickFilterIcon: QuickFilterIcon,
    Footer: hasPagination ? FooterWithPagination : NoPaginationFooter,
    FilterPanelDeleteIcon: RemoveIcon,
    NoRowsOverlay: NoDataComponent,
    LoadingOverlay,
  };

  const customComponentProps = {
    toolbar: {
      showQuickFilter: true,
      quickFilterProps: { debounceMs: 500 },
      selectedRows,
      tableTitle,
      handleDeleteRows,
      handleEditRow,
      setIsTagModalOpen,
    },
    filterPanel: {
      filterFormProps: {
        linkOperatorInputProps: {
          variant: "outlined",
          size: "small",
        },
        columnInputProps: {
          variant: "outlined",
          size: "small",
          sx: { mt: "auto" },
        },
        operatorInputProps: {
          variant: "outlined",
          size: "small",
          sx: { mt: "auto" },
        },
        valueInputProps: {
          variant: "outlined",
          size: "small",
          sx: { mt: "auto" },
        },
      },
      sx: tableComponentStyles,
    },
  };

  const handleSetSelectedRows = (ids: GridSelectionModel) => {
    const selectedIDs = new Set(ids);
    const selectedRowData: GridRowsProp = rows.reduce<GridRowsProp>((acc: any, curr: any) => {
      if (selectedIDs.has(curr.id.toString())) {
        return acc.concat(curr);
      } else {
        return acc;
      }
    }, []);
    setSelectedRows?.(selectedRowData);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "#F0F3F6",
          margin: tableContainerMargin ?? "0 20px 10px 20px",
          minHeight: "20vh",
        }}
      >
        <StyledContainer props={{ height: tableHeight }}>
          <StyledDataGrid
            //@ts-ignore
            props={{ height: tableHeight }}
            rows={rows}
            columns={columns}
            checkboxSelection={checkboxSelection}
            rowHeight={50}
            headerHeight={50}
            pagination
            pageSize={17}
            rowsPerPageOptions={[17]}
            components={customComponents}
            componentsProps={rowEvents ? { ...customComponentProps, row: rowEvents } : customComponentProps}
            onRowClick={handleOnRowClick}
            disableSelectionOnClick
            onSelectionModelChange={handleSetSelectedRows}
            loading={loading}
            getRowId={getRowId}
            selectedModal={selectedRows}
            keepNonExistentRowsSelected
          />
        </StyledContainer>
      </Box>
    </ThemeProvider>
  );
};

export default Table;
