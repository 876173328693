import { Box, Typography } from "@mui/material";
import { differenceInMinutes, subDays } from "date-fns";
import { useGetDeviceNotificationsForRecentAlertsQuery } from "graphqlBase/DeviceNotification/__generated__/getDeviceNotificationsForRecentAlerts";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { formatDateTime, formatHoursAndMinutes, getHoursAndMinutes } from "translations/formatter";
import { currentAlertTab } from "../AlertManagement/alertTabsState";
import { NoDataSummaryTile } from "./NoDataSummaryTile";
import { alertTitleAndDateWrapper, SummaryTableCellSC } from "./styles";
import SummaryTile from "./SummaryTile";

const AlertManagementSummary: React.FC<{}> = () => {
  const { data, loading } = useGetDeviceNotificationsForRecentAlertsQuery({
    variables: { take: 5 },
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });
  const history = useHistory();
  const setCurrentTab = useSetRecoilState(currentAlertTab);

  const handleSeeRecentAlerts = () => {
    setCurrentTab(1);
    history.push("/alertManagement");
  };

  const { label, recentAlerts } = translations.pages.alertManagement;
  const { noDataAlertSummary } = translations.pages.home;

  const tableElements = data?.deviceNotifications?.map((current) => {
    let alertSend: string = "";
    const isoDate = new Date(current.notificationSend ?? new Date());
    const yesterday = subDays(new Date(), 1);

    if (isoDate > yesterday) {
      const hoursAndMins = getHoursAndMinutes(differenceInMinutes(isoDate, yesterday));
      alertSend = formatHoursAndMinutes({ ...hoursAndMins, short: true });
    } else alertSend = formatDateTime(isoDate);

    return (
      <>
        <SummaryTableCellSC first size="small" sx={{ padding: "6px" }}>
          <Box sx={alertTitleAndDateWrapper}>
            <Typography variant="copy">
              {current.notificationDefinition?.notification?.notificationText ?? ""}
            </Typography>
            <Typography color="var(--color-steel-800)" sx={{ fontSize: "16px" }} variant="copy">
              {alertSend}
            </Typography>
          </Box>
        </SummaryTableCellSC>
        <SummaryTableCellSC last sx={{ verticalAlign: "top" }} size="small">
          <Typography variant="copy">
            {current?.notificationDefinition?.notification?.notificationScope?.scopeName?.replace(" Scope", "") ?? ""}
          </Typography>
        </SummaryTableCellSC>
      </>
    );
  });

  const NoTableDataComponent = <NoDataSummaryTile message={noDataAlertSummary} />;

  return (
    <SummaryTile
      iconName="my-notifier"
      tableTitle={recentAlerts}
      title={label}
      loading={loading}
      tableElements={tableElements ?? []}
      handleSeeMore={handleSeeRecentAlerts}
      NoTableDataComponent={NoTableDataComponent}
    />
  );
};

export default AlertManagementSummary;
