const buttonStyle = {
  "&.MuiButton-root": {
    display: "inline-flex",
    width: "100%",
    lineHeight: "24px",
    fontSize: "14px",
    letterSpacing: "var(--letter-spacing-basic)",
    textTransform: "uppercase",
    borderRadius: 0,
    fontFamily: "var(--button-font-family)",
    padding: "10px 24px 10px 20px",
    minHeight: "var(--size-big)",
  },
  "&.MuiButton-textPrimary": {
    borderColor: "var(--primary-color)",
    backgroundColor: "var(--primary-color)",
    color: "var(--button-text-color-primary)",
    "&:hover": {
      borderColor: "var(--primary-color-accent)",
      backgroundColor: "var(--primary-color-accent)",
      color: "var(--color-black)",
    },
  },
  "&.MuiButton-textInherit": {
    fontFamily: "var(--font-liebherr-text)",
    padding: "0px",
    width: "40px",
    "&:hover": {
      background: "inherit",
    },
    "&.Mui-disabled": {
      border: "0px solid var(--color-steel-400)",
      pointerEvents: " auto! important",
      cursor: "not-allowed! important",
    },
  },
  "&.MuiButton-textSecondary": {
    border: "1px solid",
    borderColor: "var(--color-black)",
    backgroundColor: "transparent",
    color: "var(--color-black)",
    "&:hover": {
      borderColor: "var(--secondary-color-accent)",
      color: "var(--secondary-color-accent)",
    },
    "&.Mui-disabled": {
      color: "var(--color-steel-400)",
      backgroundColor: "var(--color-white)",
      border: "1px solid var(--color-steel-400)",
    },
  },
  "&.MuiButton-outlinedSecondary": {
    border: "1px solid",
    borderBottom: "2px solid",
    borderTop: "0px",
    borderRight: "0px",
    borderLeft: "0px",
    borderColor: "var(--color-steel-800)",
    backgroundColor: "transparent",
    color: "var(--color-steel-800)",
    "&:hover": {
      borderColor: "var(--color-steel-600)",
      color: "var(--color-steel-600)",
    },
  },
  "&.Mui-disabled": {
    color: "var(--color-steel-400)",
    backgroundColor: "var(--color-white)",
    border: "1px solid var(--color-steel-400)",
  },
};
export const muiButtonOverrides = {
  root: {
    ...buttonStyle,
  },
};
