import { SelectItem } from "components/molecules/MultiSelectFreeSolo";
import TagsUpdateSidebar from "components/organisms/TagsUpdateSidebar";
import {
  assignedTagErrorAtom,
  freeSoloChipsListAtom,
  tagsToCreateAtom,
} from "components/organisms/TagsUpdateSidebar/tagsSidebarState";
import { useGetAssetTagsForTagsListQuery } from "graphqlBase/AssetTag/__generated__/getAssetTagsForTagsList";
import {
  GetAssetTagsQueryVariables,
  useGetAssetTagsQuery,
} from "graphqlBase/MappingAssetTag/__generated__/getAssetTags";
import {
  GetMappingAssetTagsQuery,
  ResultType,
  useGetMappingAssetTagsQuery,
} from "graphqlBase/MappingAssetTag/__generated__/getMappingAssetTags";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useCallback, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import translations from "translations";
import useCreateAssetTags from "./hooks/useCreateAssetTags";
import useDeleteAssetTags from "./hooks/useDeleteAssetTags";
import { selectedAssetsForTagsAtom } from "./tagModalState";

export const testIDs = getTestIDs();

export type MappingAssetTag = ResultType<GetMappingAssetTagsQuery["mappingAssetTags"]>;

const assetTagVariables: GetAssetTagsQueryVariables = {
  where: { mappingAssetTag: { some: { isDeleted: { neq: true } } } },
};
const TagModal: React.FC<{
  afterMutation: () => Promise<void>;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}> = ({ open = false, onClose, afterMutation, loading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const assetIds = useRecoilValue(selectedAssetsForTagsAtom).map((asset) => asset.id);

  const resetCreateAssetTags = useResetRecoilState(tagsToCreateAtom);
  const resetFreeSoloChips = useResetRecoilState(freeSoloChipsListAtom);
  const resetAssignedTagError = useResetRecoilState(assignedTagErrorAtom);
  const { data: mappingAssetTagsData, loading: mappingAssetTagsLoading } = useGetMappingAssetTagsQuery();

  const { createAssetTagsLoading, handleCreateAssetTags } = useCreateAssetTags();
  const { deleteAssetTagsLoading, handleDeleteAssetTags } = useDeleteAssetTags();

  const { data: assetTagsData, loading: tagsLoading, refetch: refetchTags } = useGetAssetTagsQuery({
    variables: assetTagVariables,
  });

  const variables = { where: { assetId: { in: assetIds } } };

  const { data, loading: assetTagsLoading, refetch: refetchTagsForLists } = useGetAssetTagsForTagsListQuery({
    variables,
  });

  const afterMutationRefetchForList = useCallback(async () => {
    if (refetchTagsForLists) await refetchTagsForLists();
    if (refetchTags) await refetchTags();
  }, [refetchTags, refetchTagsForLists]);

  const mappingAssetTags = data?.assets.flatMap((item) => item.mappingAssetTag);
  const assetTags = mappingAssetTags?.map((item) => item.assetTag);

  const tagOptions =
    assetTagsData?.assetTags.reduce<SelectItem[]>((tagOptions, current) => {
      return tagOptions.concat({ label: current.tagValue, value: current.id });
    }, []) ?? [];

  const handleAdd = async () => {
    setIsLoading(true);
    await handleCreateAssetTags({
      mappingAssetTags: mappingAssetTagsData?.mappingAssetTags ?? [],
    });
    await afterMutation();
    await afterMutationRefetchForList();
    resetFreeSoloChips();
    resetCreateAssetTags();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleDelete = async (id: string) => {
    if (!mappingAssetTagsData) return;
    setIsLoading(true);
    await handleDeleteAssetTags({
      mappingAssetTags: mappingAssetTagsData?.mappingAssetTags ?? [],
      id,
    });
    await afterMutation();
    await afterMutationRefetchForList();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const title = `${translations.pages.assetManagement.assetTags.updateTags}`;

  const handleClose = () => {
    onClose();
    resetCreateAssetTags();
    resetFreeSoloChips();
    resetAssignedTagError();
  };

  const sidebarLoading =
    createAssetTagsLoading ||
    deleteAssetTagsLoading ||
    tagsLoading ||
    assetTagsLoading ||
    loading ||
    isLoading ||
    mappingAssetTagsLoading;

  return (
    <TagsUpdateSidebar
      itemTags={assetTags ?? []}
      tagOptions={tagOptions}
      handleDelete={handleDelete}
      handleAdd={handleAdd}
      onClose={handleClose}
      onCancel={handleClose}
      onDone={handleClose}
      loading={sidebarLoading}
      open={open}
      title={title}
    />
  );
};

export default TagModal;
