import { LhChange } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { Button, Popover, Typography } from "@mui/material";
import { assetDeviceUnpairingModalStateAtom } from "components/organisms/AssetDeviceUnpairing/recoilState";
import PLIcon from "components/patternLib/PLIcon";
import PLCheckbox from "components/patternLib/form/PLCheckbox";
import React, { ChangeEvent, useEffect } from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { IconTextCellWrapperSC } from "./styles";
import { CheckboxCellProps, IconTextCellProps } from "./types";

export const CheckboxCell = ({ tabIndex, checked, disabled = false, onChange, indeterminate }: CheckboxCellProps) => {
  const lhChange = (e: CustomEvent<LhChange>) => {
    if (onChange) {
      const ee = new InputEvent("input", { bubbles: true });

      onChange(
        ({
          target: { checked: e.detail.value },
          nativeEvent: { shiftKey: undefined },
        } as unknown) as ChangeEvent<HTMLInputElement>,
        e.detail.value as boolean
      );
    }
  };

  return (
    <div style={{ marginLeft: "30px" }}>
      <PLCheckbox
        style={{ display: "flex" }}
        checked={!!checked && !indeterminate}
        indeterminate={!!checked && !!indeterminate}
        disabled={disabled}
        lhChange={lhChange}
      />
    </div>
  );
};

export const ActionsCell = ({ handleUnpair }: { handleUnpair?: () => void }) => {
  const [isModalOpen, setIsModalOpen] = useRecoilState(assetDeviceUnpairingModalStateAtom);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    return () => handleClose();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isModalOpen) handleClose();
  }, [isModalOpen]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        disableRipple
        sx={{
          width: "fit-content",
          height: "fit-content",
          padding: 0,
          minWidth: "15px",
          "&:hover": {
            backgroundColor: "transparent",
            opacity: 0.5,
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <PLIcon size="14px" iconName="further-options-2" />
      </Button>
      <Popover
        id={id}
        open={open && !isModalOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
          },
        }}
      >
        <Typography
          sx={{
            p: 2,
            cursor: handleUnpair ? "pointer" : "not-allowed",
            opacity: handleUnpair ? 1 : 0.4,
          }}
          onClick={() => {
            if (!handleUnpair) return;
            handleUnpair();
            setIsModalOpen(true);
          }}
        >
          {translations.globals.button.unpair}
        </Typography>
      </Popover>
    </>
  );
};

export const IconTextCell = ({ text, iconName, color, iconColor, width }: IconTextCellProps) => {
  return (
    <IconTextCellWrapperSC width={width}>
      <PLIcon
        size="1.5em"
        style={{ height: "fit-content", fontWeight: "bold" }}
        color={iconColor}
        iconName={iconName}
      />
      <p style={{ color, marginTop: "10px" }}>{text}</p>
    </IconTextCellWrapperSC>
  );
};
