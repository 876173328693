import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLDropdownProps = DropOnONLh<JSX.PatternlibDropdown>;
export type PLDropdownExtendedProps = PLDropdownProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedDropdown = wrapWc<JSX.PatternlibDropdown>("patternlib-dropdown");
export const useRefPLDropdown = () => useRef<HTMLPatternlibDropdownElement>(null);

const PLDropdown = React.forwardRef<HTMLPatternlibDropdownElement, PLDropdownExtendedProps>((props, ref) => {
  const myRef = useRefPLDropdown();
  return (
    <WrappedDropdown ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedDropdown>
  );
});
PLDropdown.displayName = "PLDropdown";

export default PLDropdown;
