import { Box, Typography } from "@mui/material";
import { LeftTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import useDataGrid from "components/templates/dataGridTable";
import {
  GetNotificationDefinitionsForAssetDetailsQuery,
  ResultType,
  useGetNotificationDefinitionsForAssetDetailsLazyQuery,
} from "graphqlBase/NotificationDefinition/__generated__/getNotificationDefinitionsForAssetDetails";
import React from "react";
import { useRecoilValue } from "recoil";
import translations from "translations";
import { formatDateTime } from "translations/formatter";
import { deviceIdState } from "../../../assetDetailState";

export type NotificationDefinition = ResultType<
  GetNotificationDefinitionsForAssetDetailsQuery["notificationDefinitions"]
>;

const AlertsTile: React.FC<{}> = () => {
  const deviceId = useRecoilValue(deviceIdState);

  const [query, { data, loading }] = useGetNotificationDefinitionsForAssetDetailsLazyQuery();

  const { DataGrid, useMakeColumns, makeSeverSideFilter } = useDataGrid<
    NotificationDefinition,
    "NotificationDefinition"
  >({
    query,
    variables: {
      where: {
        deviceNotification: { any: true, all: { deviceId: { eq: deviceId } } },
        notification: { isRemoved: { neq: true }, isDeleted: { neq: true } },
      },
    },
    tableId: "AssetDetailAlerts",
    persistance: "runTime",
  });

  const columns = useMakeColumns(
    [
      {
        field: "notificationSend",
        headerName: translations.entities.deviceNotification.columns.notificationSend,
        flex: 2,
        valueGetter: (params) => {
          const notificationSend = params?.row?.deviceNotification?.[0]?.notificationSend;
          const isoDate = notificationSend ? new Date(notificationSend) : "";
          return isoDate instanceof Date ? `${formatDateTime(isoDate)}` : "-";
        },
        remoteOrder: (sort) => ({ notification: { createdAt: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            notification: { createdAt: filterValue },
          }),
        }),
        type: "string",
      },
      {
        field: "notificationText",
        headerName: translations.entities.notification.columns.notificationText,
        flex: 2,
        valueGetter: (params) => {
          return params.row?.notification?.notificationText ?? "";
        },
        remoteOrder: (sort) => ({ notification: { notificationText: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            notification: { notificationText: filterValue },
          }),
        }),
        type: "string",
      },
      {
        field: "notificationScope",
        headerName: translations.entities.notificationScope?.columns?.scopeName,
        flex: 2,
        valueGetter: (params) => {
          return params.row.notification?.notificationScope?.scopeName ?? "";
        },
        remoteOrder: (sort) => ({ notification: { notificationScope: { scopeName: sort } } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            notification: { notificationScope: { scopeName: filterValue } },
          }),
        }),
        type: "string",
      },
      {
        field: "severity",
        headerName: translations.entities.notification.columns.severity,
        flex: 1,
        valueGetter: (params) => "High!",
        type: "string",
      },
      {
        field: "deviceNotificationCount",
        headerName: "Subm.",
        flex: 1,
        valueGetter: (params) => `${params?.row?.deviceNotification?.length ?? 0}`,
        type: "string",
      },
      {
        field: "receiver",
        headerName: translations.globals.terms.receiver,
        flex: 2,
        valueGetter: (params) => {
          const users = params.row?.notification?.mappingNotificationUser;
          return `${users?.length ? users?.[0]?.user?.email : ""}${
            users?.length && users?.length > 1 ? `+ ${users?.length - 1}` : ""
          }`;
        },
        type: "string",
      },
    ],
    []
  );

  const { label, noDataMessage } = translations.pages.assetManagement.assetDetails.alertsTile;

  return (
    <LeftTileWrapperSC>
      <Box sx={{ marginBottom: "25px" }}>
        <Typography variant="h4">{label}</Typography>
      </Box>
      <DataGrid
        hasToolbar={false}
        checkboxSelection={false}
        rows={data?.notificationDefinitions ?? []}
        columns={columns}
        loading={loading}
        tableHeight="30vh"
        tableTitle=""
        noDataMessage={noDataMessage}
        withMargin={false}
      />
    </LeftTileWrapperSC>
  );
};
export default AlertsTile;
