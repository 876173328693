import { Autocomplete, Typography, styled } from "@mui/material";

export const autocompleteRootStyles = (isStatusError?: boolean) => {
  return {
    minWidth: 230,
    width: "95%",
    borderColor: "#000",
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 15px)",
      "& .MuiButtonBase-root:hover, .MuiButtonBase-root:active": { backgroundColor: "transparent" },
    },
    "& .MuiAutocomplete-clearIndicator": {
      backgroundColor: "transparent",
      height: "fit-content",
      alignSelf: "center",
      "& svg": { height: 20, width: 20 },
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      alignItems: "center",
      padding: "3px 7px",
      minHeight: "40px",
      fontFamily: "var(--copytext-font-family)",
      fontSize: "var(--copytext-font-size)",
      borderRadius: 0,
      "& .MuiAutocomplete-input": { padding: 0 },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 0,
        borderColor: isStatusError ? "red" : "black",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderRadius: 0,
        borderColor: isStatusError ? "red" : "black",
      },
    },
  };
};

export const inputStyles = { border: "none", backgroundColor: "#fff" };

export const inputLabelStyles = {
  fontFamily: "var(--form-label-font-family)",
  fontWeight: 800,
  fontSize: "var(--form-size-label-text)",
};

export const InputWrapperSC = styled("div")({ display: "flex", flexDirection: "column", width: "22vw" });
export const InputLabelWrapperSC = styled("div")({ height: 32 });
export const InputChipWrapperSC = styled("div")({
  display: "flex",
  marginRight: "3px",
  alignItems: "center",
  padding: 3,
});
