import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserNameForAssetDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetUserNameForAssetDetailsQuery = { user?: Maybe<{ __typename?: 'User', id: string, userName: string, firstName?: Maybe<string>, lastName?: Maybe<string> }> };


export const GetUserNameForAssetDetailsDocument = gql`
    query getUserNameForAssetDetails($id: UUID!) {
  user(id: $id) {
    id
    userName
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUserNameForAssetDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserNameForAssetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNameForAssetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNameForAssetDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserNameForAssetDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserNameForAssetDetailsQuery, GetUserNameForAssetDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserNameForAssetDetailsQuery, GetUserNameForAssetDetailsQueryVariables>(GetUserNameForAssetDetailsDocument, options);
      }
export function useGetUserNameForAssetDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserNameForAssetDetailsQuery, GetUserNameForAssetDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserNameForAssetDetailsQuery, GetUserNameForAssetDetailsQueryVariables>(GetUserNameForAssetDetailsDocument, options);
        }
export type GetUserNameForAssetDetailsQueryHookResult = ReturnType<typeof useGetUserNameForAssetDetailsQuery>;
export type GetUserNameForAssetDetailsLazyQueryHookResult = ReturnType<typeof useGetUserNameForAssetDetailsLazyQuery>;
export type GetUserNameForAssetDetailsQueryResult = Apollo.QueryResult<GetUserNameForAssetDetailsQuery, GetUserNameForAssetDetailsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
