import locationMarker from "components/atomics/markers/locationMarker";
import MapWrapper from "components/molecules/MapWrapper";
import React from "react";
import { Marker } from "react-leaflet";

const zoom: number = 10;

const AssetPositionMap: React.FC<{ coordinates: [number, number] }> = ({ coordinates }) => {
  const icon = locationMarker(coordinates);
  return (
    <MapWrapper
      height="40vh"
      sx={{ marginTop: "2vh" }}
      coordinates={coordinates}
      scrollWheelZoom
      zoomControl
      zoom={zoom}
    >
      <Marker riseOnHover icon={icon} position={coordinates} />
    </MapWrapper>
  );
};

export default AssetPositionMap;
