export const muiCardOverrides = {
  root: {
    borderRadius: "0.5rem",
    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow sans-serif",
    fontSize: 16,
    overflow: "hidden",
    position: "relative",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.25) 0 4px 16px 0px",
    },
    "&:focus": {
      boxShadow: "rgba(0, 0, 0, 0.25) 0 4px 16px 0px",
    },
  },
};
