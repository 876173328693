import MultiSelect from "components/lbc-toolkit/molecules/MultiSelect";
import { SelectItem } from "components/molecules/MultiSelectFreeSolo";
import React, { Key, useEffect } from "react";
import { Empty, ListItem, ListValues, RuleValue } from "react-awesome-query-builder";

interface MultiSelectProps {
  field?: string;
  key?: Key | null | undefined;
  listValues?: ListValues | undefined;
  placeholder?: string;
  setValue?: (val: RuleValue) => void;
  value?: RuleValue | Empty;
}

export default ({ key, placeholder, listValues, setValue, value, field }: MultiSelectProps) => {
  const renderOptions = ((listValues ?? []) as Array<ListItem>).reduce<Array<SelectItem>>(
    (renderOptions: Array<SelectItem>, current: ListItem) => {
      return renderOptions.concat({ label: current?.title ?? "", value: (current?.value as string) ?? "" });
    },
    []
  );

  const handleSelect = (e: any) => {
    setValue?.(e.target.value);
  };

  const handleDeleteItem = (valueToRemove: any) => {
    const currentValues = [...value] as string[];
    const index = currentValues?.findIndex?.((elem: string) => {
      return elem === valueToRemove;
    });
    currentValues?.splice(index, 1);
    setValue?.(currentValues);
  };
  const handleDeleteAll = () => setValue?.([]);

  useEffect(() => {
    setTimeout(() => handleDeleteAll(), 1);
  }, [field]);

  return (
    <MultiSelect
      placeholder={placeholder}
      selected={value}
      id="query-builder-multiselect"
      options={renderOptions}
      onChange={handleSelect}
      handleDeleteAll={handleDeleteAll}
      handleDelete={handleDeleteItem}
    />
  );
};
