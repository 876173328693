import { Input, InputProps } from "@mui/material";
import React from "react";

export interface DefaultInputProps extends InputProps {
  testId?: string;
}

const DefaultInput: React.FC<DefaultInputProps> = (props) => {
  const { testId, ...inputPropsInner } = props;
  return (
    <Input
      {...inputPropsInner}
      data-testid={testId}
      inputProps={{ ...inputPropsInner.inputProps, "data-testid": testId ? `${testId}_Input` : undefined }}
    />
  );
};

export default DefaultInput;
