import { GridCellEditCommitParams, GridApi, GridCellValue } from "@mui/x-data-grid-pro";
import client from "apollo/client";
import { updatedDiff } from "deep-object-diff";
import { PartialDeep } from "graphqlBase/types";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Result, EditableGridStateColDef, Delta } from "./types";
import loadMutationVariables from "./utils/loadMutationVariables";
import makeOperation from "./utils/makeOperation";

const defaultSet = (original: {}, field: string) => ({ value }: { value: unknown }) => ({
  ...original,
  [field]: value,
});

interface UseOnCellEditCommitParams {
  autoSubmitOnChage?: boolean;
}

export type OnCellEditCommit = (params: GridCellEditCommitParams, _e: unknown, { api }: { api?: GridApi }) => void;

const useOnCellEditCommit = ({ autoSubmitOnChage = true }: UseOnCellEditCommitParams = {}) => {
  const [delta, setDelta] = useState<Delta>({});
  const [shouldSubmit, setShouldSubmit] = useState<Boolean>(autoSubmitOnChage ?? true);

  useEffect(() => {
    if (Object.keys(delta).length && shouldSubmit) {
      const { mutation, variables } = makeOperation(delta);
      if (mutation) {
        client.mutate({ mutation, variables }).catch(console.error);
      }
      setDelta({});
      if (!autoSubmitOnChage) {
        setShouldSubmit(false);
      }
    }
  }, [delta, shouldSubmit]);

  const submitCellChanges = () => {
    if (!autoSubmitOnChage) setShouldSubmit(true);
  };

  const onCellEditCommit: OnCellEditCommit = useCallback(
    (params, _e, { api }) => {
      if (api) {
        const original = api.getRow(params.id) ?? {};
        const column = api.getColumn(params.field) as EditableGridStateColDef;
        const valueFormatter = column.valueFormatter
          ? ({ value }: { value: GridCellValue }) => {
              const formatedValue = column.valueFormatter
                ? column.valueFormatter({ ...params, api, value })
                : undefined;
              return { ...original, [params.field]: formatedValue };
            }
          : undefined;

        const mutateRow =
          column.mutator ?? column.valueSetter ?? valueFormatter ?? defaultSet(original ?? {}, params.field);

        if (mutateRow && original) {
          const change = mutateRow({ row: {}, value: params.value });
          const innerDelta = { ...delta };
          loadMutationVariables({
            row: original as Result,
            change:
              column.mutator ?? column.valueSetter ? change : (updatedDiff(original, change) as PartialDeep<Result>),
            delta: innerDelta,
          });
          setDelta(innerDelta);
        }
      }
    },
    [delta]
  );

  return { onCellEditCommit, submitCellChanges };
};

export default useOnCellEditCommit;
