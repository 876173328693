import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from "react";

import { Theme } from "@mui/material";
import Props from "./index.d";

interface GFCFieldSet_Stepper extends Props {
  children: React.ReactNode;
}

const FieldSetHorizontal: React.FC<GFCFieldSet_Stepper> = (props) => <>{props.children}</>;

export default FieldSetHorizontal;
