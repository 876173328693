                                import type * as Types from "./types";
      export type Filtertypes ={
            guid: Types.ComparableGuidOperationFilterInput;
            dateTime: Types.ComparableDateTimeOperationFilterInput;
            string: Types.StringOperationFilterInput;
            boolean: Types.BooleanOperationFilterInput;
            int: Types.ComparableInt32OperationFilterInput;
            decimal: Types.ComparableDoubleOperationFilterInput;
            dimension: Types.DimensionOperationFilterInput;
            ordinates: Types.OrdinatesOperationFilterInput;
            precisionModels: Types.PrecisionModelsOperationFilterInput;
            ogcGeometryType: Types.OgcGeometryTypeOperationFilterInput;
            geometryContains: Types.GeometryContainsOperationFilterInput;
            geometryDistance: Types.GeometryDistanceOperationFilterInput;
            geometryIntersects: Types.GeometryIntersectsOperationFilterInput;
            geometryOverlaps: Types.GeometryOverlapsOperationFilterInput;
            geometryTouches: Types.GeometryTouchesOperationFilterInput;
            geometryWithin: Types.GeometryWithinOperationFilterInput;}
    
    export const filterdefs = {
  "guid": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "dateTime": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "string": [
    "contains",
    "endsWith",
    "eq",
    "ncontains",
    "nendsWith",
    "neq",
    "nstartsWith",
    "startsWith"
  ],
  "boolean": [
    "eq",
    "neq"
  ],
  "int": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "decimal": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "dimension": [
    "eq",
    "neq"
  ],
  "ordinates": [
    "eq",
    "neq"
  ],
  "precisionModels": [
    "eq",
    "neq"
  ],
  "ogcGeometryType": [
    "eq",
    "neq"
  ],
  "geometryContains": [
    "buffer",
    "geometry"
  ],
  "geometryDistance": [
    "buffer",
    "eq",
    "geometry",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "geometryIntersects": [
    "buffer",
    "geometry"
  ],
  "geometryOverlaps": [
    "buffer",
    "geometry"
  ],
  "geometryTouches": [
    "buffer",
    "geometry"
  ],
  "geometryWithin": [
    "buffer",
    "geometry"
  ]
}