import { useCubeQuery } from "@cubejs-client/react";
import { Box } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { useGetStorageAreaTypeColorsQuery } from "graphqlBase/StorageAreas/__generated__/getStorageAreaTypeColors";
import { round } from "lodash";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { getTotalAmountOfHours } from "../../../getTotalAmountOfHours";
import { tooltipCommonStyles } from "../../../styles";
import { filtersSelector, RecoilFilterType } from "../../filtersState";
import { RootChartContainerSC } from "../../styles";
import { DoughnutTableSC } from "../styles";
import useMakeMemoizedCharts from "../../hooks/useMakeMemoizedCharts";

interface ColorType {
  [storageAreaTypeName: string]: string;
}
interface WorkingData {
  "DimStorageAreaType.storageareatypename": string;
  "FactUtilization.utilizationhoursMeasure": number;
}
const dimensions = ["DimStorageAreaType.storageareatypename"];

const GeofencesTypeDoughnut: React.FC<{ groupLoading?: boolean }> = ({ groupLoading }) => {
  const [filters, setFilters] = useRecoilState(filtersSelector);
  const [colors, setColors] = useState<ColorType>();
  const chartInstance: MutableRefObject<any> = useRef();

  const { resultSet, isLoading, error } = useCubeQuery<WorkingData>({
    dimensions,
    order: {
      "FactUtilization.utilizationhoursMeasure": "desc",
    },
    measures: ["FactUtilization.utilizationhoursMeasure"],
    filters: filters.filter((elem) => !dimensions.includes(elem.member as string)),
  });

  const { data: colorData, loading: colorsLoading } = useGetStorageAreaTypeColorsQuery();

  const chartLoading = (isLoading || colorsLoading || !!groupLoading) && !chartInstance?.current;

  useEffect(() => {
    if (colorData?.storageAreaTypes) {
      const objectColors = colorData?.storageAreaTypes.reduce(
        (colors, current) => {
          return { ...colors, [current.storageAreaTypeName]: current.storageAreaTypeColor ?? "" };
        },
        { "Outside of any geofence": "#000" }
      );
      setColors(objectColors);
    }
  }, [colorData]);

  const ownFilterValues = filters
    .filter((elem) => dimensions.includes(elem.member as string))
    .flatMap((filter) => filter.values);

  const totalAmountOfHours = getTotalAmountOfHours(resultSet?.rawData());
  const getSeriesData = () =>
    (resultSet?.rawData() ?? [])?.map((item) => {
      const color =
        (!ownFilterValues.length || ownFilterValues.includes(item["DimStorageAreaType.storageareatypename"])) &&
        colors?.[item["DimStorageAreaType.storageareatypename"]]
          ? colors?.[item["DimStorageAreaType.storageareatypename"]]
          : `rgba(0,0,0,0.1)`;

      return {
        value: totalAmountOfHours
          ? round(100 * (item["FactUtilization.utilizationhoursMeasure"] / totalAmountOfHours), 2)
          : 0,
        valueUnit: "%",
        name: item["DimStorageAreaType.storageareatypename"],
        itemStyle: {
          color,
        },
      };
    });

  const options = {
    tooltip: {
      valueFormatter: (value: number) => `${value}%`,
      textStyle: tooltipCommonStyles,
      position: (pos: Array<number>) => {
        return [pos[0] + 20, pos[1] + 25];
      },
    },
    series: [
      {
        type: "pie",
        data: getSeriesData(),
        label: {
          show: false,
        },
        radius: ["63%", "93%"],
      },
    ],
  };

  const handleClick = (e: any) => {
    const filter: RecoilFilterType = {
      member: "DimStorageAreaType.storageareatypename",
      operator: "equals",
      values: [e.name],
    };
    setFilters([filter]);
  };
  const data = getSeriesData();
  const { MemoizedChart, MemoizedChartList } = useMakeMemoizedCharts({
    options,
    isLoading,
    chartInstance,
    chartLoading,
    resultSet,
    handleClick,
    error,
    isDougnut: true,
    optionsObjForSetOption: { series: [{ data }] },
  });

  return (
    <RootChartContainerSC>
      <LoadingSpinner hideChildrenOnLoad loading={chartLoading}>
        <DoughnutTableSC>
          <Box sx={{ width: "200px" }}>{MemoizedChart}</Box>
          {MemoizedChartList}
        </DoughnutTableSC>
      </LoadingSpinner>
    </RootChartContainerSC>
  );
};

export default GeofencesTypeDoughnut;
