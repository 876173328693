const iconStyles = {
  "&.MuiSvgIcon-root": {
    fontSize: "1.8rem",
  },
};
export const muiIconOverrides = {
  root: {
    ...iconStyles,
  },
};
