const en = {
  name: "Query Statistics OutputType",
  plural: "query Statistics outputtypes",
  columns: {
    filtered_item_count: "filtered item count",
    returned_item_count: "returned item count",
    skip: "skip",
    take: "take",
    total_item_count: "total item count",
  },
};
export default en;
