export default {
  name: "Device",
  plural: "Devices",
  columns: {
    assetDevice: "asset device",
    description: "Description",
    deviceAttributeValue: "device attribute value",
    deviceCapability: "device capability",
    deviceCapabilityId: "device capabilityID",
    deviceModel: "Device Type",
    deviceModelId: "Device Model ID",
    deviceModelVersion: "Device Model Version",
    deviceModelVersionId: "device model version ID",
    deviceName: "Device Name",
    gatewayDeviceConnection: "gateway device connection",
    id: "ID",
    inverseParentDevice: "inverse parent device",
    isDeleted: "is deleted",
    parentDevice: "Parent Device",
    parentDeviceId: "Parent Device ID",
    sensorId: "Sensor ID",
    serialNo: "Serial No",
    tenantId: "Tenant ID",
    isManufacturerDevice: "Liebherr Device",
  },
};
