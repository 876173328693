import { Box, styled, SxProps, Theme } from "@mui/material";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton/src/easy-button.js";
import "material-icons/iconfont/material-icons.css";
import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";
import FullScreenButtons from "./FullScreenButtons";

const MapContainerSC = styled(MapContainer)(() => {
  return {
    zIndex: 0,
    "& .leaflet-popup-content-wrapper": {
      borderRadius: 0,
      border: "none",
    },
    "& .leaflet-popup-close-button": {
      display: "none",
    },
    "& .leaflet-top > .leaflet-bar.easy-button-container.leaflet-control": {
      border: "none",
      borderRadius: 0,
      background: "#FAFAFA",
    },
    "& .easy-button-button.leaflet-bar-part.leaflet-interactive.unnamed-state-active": {
      padding: 0,
      width: 43,
      height: 43,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .material-icons-round": { fontSize: "45px", fontWeight: "200", color: "var(color--steel)" },
    },
  };
});

export interface MapWrapperProps {
  coordinates: [number, number];
  height?: string | number;
  scrollWheelZoom?: boolean;
  sx?: SxProps<Theme>;
  withLayersControl?: boolean;
  zoom?: number | undefined;
  zoomControl?: boolean;
}
const { BaseLayer } = LayersControl;
const MapWrapper: React.FC<MapWrapperProps> = ({
  coordinates,
  scrollWheelZoom,
  zoom,
  zoomControl,
  withLayersControl,
  sx,
  children,
  height,
}) => {
  const [layersControl, setLayersControl] = useState<boolean>(false);
  const [map, setMap] = useState<L.Map | undefined>();
  const handleFullScreen = useFullScreenHandle();
  const { active: isFullScreen, enter: enterFullScreen, exit: exitFullScreen } = handleFullScreen;

  useEffect(() => {
    if (withLayersControl) {
      setLayersControl(true);
    }
  }, [withLayersControl]);

  return (
    <FullScreen handle={handleFullScreen}>
      <Box sx={{ height: isFullScreen ? "100%" : height, ...sx }}>
        <MapContainerSC
          id="MapContainer"
          attributionControl={false}
          center={coordinates as L.LatLngExpression}
          scrollWheelZoom={scrollWheelZoom}
          zoomControl={zoomControl}
          zoom={zoom}
          style={{ height: "inherit" }}
          whenCreated={setMap}
        >
          <FullScreenButtons
            isFullScreen={isFullScreen}
            enterFullScreen={enterFullScreen}
            exitFullScreen={exitFullScreen}
          />
          {!layersControl ? (
            <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          ) : (
            <LayersControl position="topright">
              <BaseLayer checked name="Street View">
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
                  maxZoom={20}
                  subdomains={["mt0", "mt1", "mt2", "mt3"]}
                />
              </BaseLayer>

              <BaseLayer name="Satelite View">
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  maxZoom={20}
                  subdomains={["mt1", "mt2", "mt3"]}
                />
              </BaseLayer>
            </LayersControl>
          )}

          {children}
        </MapContainerSC>
      </Box>
    </FullScreen>
  );
};
export default MapWrapper;
