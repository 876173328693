export default {
  name: "rule reference",
  plural: "rule references",
  columns: {
    id: "ID",
    isDeleted: "is deleted",
    referenceNumber: "reference number",
    referenceUnitId: "reference Unit ID",
    rule: "rule",
    ruleReferenceDescription: "rule reference description",
    ruleReferenceName: "rule reference name",
    ruleReferenceUnit: "rule reference unit",
  },
};
