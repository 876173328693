const paperStyles = {
  "&.MuiPaper-root": {
    "&.MuiTableContainer-root": {
      border: "1px solid var(--color-steel-300)",
      borderRadius: 0,
      boxShadow: "none",
    },
    "&.MuiPopover-paper.MuiPaper-root": {
      borderRadius: "0px",
      marginTop: "-8px",
    },
  },
};
export const muiPaperrOverrides = {
  root: {
    ...paperStyles,
  },
};
