export default {
  name: "device model version",
  plural: "device model versions",
  columns: {
    device: "device",
    deviceModel: "device model",
    deviceModelCapability: "device model capability",
    deviceModelId: "device model ID",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
  },
};
