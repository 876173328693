import RadioCheckboxes from "components/templates/table-factory/Table/Cells/RadioCheckboxes";
import { useAddAssetMasterDataToAssetMutation } from "graphqlBase/AssetMasterData/__generated__/addAssetMasterDataToAsset";
import {
  GetAssetMasterDatasForAssetAssignmentQuery,
  ResultType,
  useGetAssetMasterDatasForAssetAssignmentLazyQuery,
} from "graphqlBase/AssetMasterData/__generated__/getAssetMasterDatasForAssetAssignment";

import { styled } from "@mui/material";
import PLRadiobutton from "components/patternLib/form/PLRadiobutton";
import PLButton from "components/patternLib/PLButton";
import useDataGrid from "components/templates/dataGridTable";
import React, { useCallback, useState } from "react";
import translations from "translations";
import { ability } from "authorization/config/ability";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentDeviceAtom, shoWOpenAssignmentsConfirmMontalAtom } from "../recoilBox";
import PLModal from "components/patternLib/PLModal";
import { currentAssetTab } from "../../../assetTabsState";
import { useHistory } from "react-router-dom";
import { GetAssetsForOpenAssignmentDocument } from "graphqlBase/Assets/__generated__/getAssetsForOpenAssignments";
import { GetOpenAssignmentsForBadgeValueDocument } from "graphqlBase/Assets/__generated__/getOpenAssignmentsForBadgeValue";

const ButtonsContainerSC = styled("div")(
  () => `
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 25px 20px 0 0;
    width: 100%;
`
);

type AssetMasterData = ResultType<GetAssetMasterDatasForAssetAssignmentQuery["assetMasterDatas"]>;
interface AssetAssignmentTableProps {
  assetId: string;
}

const variables = {
  where: { asset: { any: false } },
};

const AssetAssignmentTable: React.FC<AssetAssignmentTableProps> = ({ assetId }) => {
  const [checkedId, setCheckedId] = useState<string | undefined>(undefined);
  const [showConfirmModal, setShowConfirmModal] = useRecoilState(shoWOpenAssignmentsConfirmMontalAtom);
  const currentDeviceName = useRecoilValue(currentDeviceAtom);
  const setCurrentTab = useSetRecoilState(currentAssetTab);
  const history = useHistory();
  const [assignedAssetInfo, setAssignedAssetInfo] = useState<{
    articleName: string;
    articleNr: string;
  }>({
    articleName: "",
    articleNr: "",
  });
  const [addAssetMasterDataToAssetMutation] = useAddAssetMasterDataToAssetMutation({
    refetchQueries: [{ query: GetAssetsForOpenAssignmentDocument }, { query: GetOpenAssignmentsForBadgeValueDocument }],
  });
  const [
    query,
    { data: assetMasterData, loading, error, refetch },
  ] = useGetAssetMasterDatasForAssetAssignmentLazyQuery();

  const { DataGrid, useMakeColumns, makeSeverSideFilter, getRowCount } = useDataGrid<
    AssetMasterData,
    "AssetMasterData"
  >({
    query,
    variables,
    tableId: "OpenAssignments",
    persistance: "runTime",
  });

  const columns = useMakeColumns(
    [
      {
        field: "radiobutton",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        maxWidth: 70,
        renderCell: ({ row }) => (
          <PLRadiobutton value={row.id}>
            <RadioCheckboxes checkedId={checkedId} setCheckedId={setCheckedId} id={row.id} />
          </PLRadiobutton>
        ),
        type: "string",
      },
      {
        field: "articleName",
        headerName: translations.entities.assetMasterData.columns.itemName,
        renderHeader: (params) => `Article name (${getRowCount()})`,
        flex: 2,
        valueGetter: ({ row }) => {
          return row.generalItem ?? "";
        },
        remoteOrder: (sort) => ({ generalItem: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            generalItem: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "artileNr",
        headerName: translations.entities.assetMasterData.columns.artileNr,
        flex: 2,
        valueGetter: ({ row }) => {
          return row.articleNumber ?? "";
        },
        remoteOrder: (sort) => ({ articleNumber: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            articleNumber: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "serialNumber",
        headerName: translations.entities.asset.columns.serialNo,
        flex: 2,
        valueGetter: ({ row }) => {
          return row.serialNumber ?? "";
        },
        remoteOrder: (sort) => ({ serialNumber: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            serialNumber: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "articleWeight",
        headerName: translations.entities.assetMasterData.columns.itemWeight,
        flex: 2,
        renderCell: ({ row }) => {
          return `${row.weight ?? ""}`;
        },
        remoteOrder: (sort) => ({ weight: sort }),
        type: "string",
      },
      {
        field: "manufacturer",
        headerName: translations.entities.assetMasterData.columns.itemManufacturer,
        flex: 2,
        valueGetter: ({ row }) => {
          return row.supplier ?? "";
        },
        remoteOrder: (sort) => ({ supplier: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            supplier: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "constructionNumber",
        headerName: translations.entities.assetMasterData.columns.itemConstructionNumber,
        flex: 2,
        valueGetter: ({ row }) => {
          return row.itemConstructionNumber ?? "";
        },
        remoteOrder: (sort) => ({ itemConstructionNumber: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            itemConstructionNumber: filterValue,
          }),
        }),
        type: "string",
      },
    ],
    []
  );

  const updateAsset = () => {
    return addAssetMasterDataToAssetMutation({
      variables: {
        assets: {
          id: assetId ?? "",
          assetMasterDataId: checkedId,
        },
      },
    });
  };

  const assignmentConfirmation = () => {
    const asset = assetMasterData?.assetMasterDatas.find((item) => item.id === checkedId);
    setAssignedAssetInfo({
      articleName: asset?.generalItem ?? "",
      articleNr: asset?.articleNumber ?? "",
    });
    setShowConfirmModal(true);
  };
  const afterMutation = useCallback(async () => {
    if (refetch) await refetch();
  }, [refetch]);
  return (
    <>
      <div>
        <PLModal
          allowCloseOnBackdrop
          cancelLabel={translations.pages.assetManagement.label}
          confirmLabel={translations.pages.assetManagement.openAssignments}
          height="auto"
          iconName="checkmark"
          modalTitle={translations.pages.assetManagement.assetAssignmentConfirmationModal.title}
          lhCancel={() => {
            history.push(`/assetManagement`);
            setCurrentTab(0);
            setShowConfirmModal(false);
          }}
          lhConfirm={() => {
            history.push(`/assetManagement`);
            setCurrentTab(2);
            setShowConfirmModal(false);
          }}
          lhClose={() => {
            setShowConfirmModal(false);
          }}
          show={showConfirmModal}
          showIcon
          width="654px"
        >
          <span
            slot="modal-content"
            style={{ fontFamily: "var(--copytext-font-family)", fontSize: "var(--copytext-font-size)" }}
          >
            {translations.entities.device.name} {currentDeviceName}{" "}
            {translations.pages.assetManagement.assetAssignmentConfirmationModal.assignmentConfirmationMessage}{" "}
            {assignedAssetInfo.articleName} (articleNr: {assignedAssetInfo.articleNr})
          </span>
        </PLModal>
      </div>
      {!error && (
        <DataGrid
          noDataMessage={translations.pages.assetManagement.noAssetsMessage}
          tableTitle={translations.pages.assetManagement.myAssets}
          tableHeight="40vh"
          checkboxSelection={false}
          columns={columns}
          rows={assetMasterData?.assetMasterDatas}
          hasOnlyDefaultToolbar
          loading={loading}
        />
      )}
      <ButtonsContainerSC>
        <PLButton
          label="Assign"
          disabled={!ability.can("read", "AssetAssignmentTableUI") || !checkedId}
          onClick={async () => {
            checkedId && (await updateAsset());
            await afterMutation();
            checkedId && assignmentConfirmation();
          }}
        />
      </ButtonsContainerSC>
    </>
  );
};
export default AssetAssignmentTable;
