export default (capabilityName: string) => {
  switch (capabilityName) {
    case "Battery Voltage":
      return "V";
    case "Altitude":
      return "m";
    case "Humidity":
      return "%";
    case "Temperature":
      return "°C";
    default:
      if (capabilityName.includes("Acceleration")) return "milli-g";
      return "";
  }
};
