import { LhChange, Status } from "@liebherr/patternlib/dist/types/utils/interfaces";
import PLTextinput from "components/patternLib/form/PLTextinput";
import * as React from "react";
import { useState } from "react";
import translations from "translations";
import Props from "./index.d";

export type TextFieldComponentProps = Props;

const TextFieldComponent: React.FC<Props> = (props) => {
  const [status, setStatus] = useState<Status["status"]>("default");
  const {
    title,
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    placeholder = "placeholder",
    disabled,
    testId,
    errors,
    isPartOfSummary,
    width,
    ...rest
  } = props;

  const handleChange = (event: CustomEvent<LhChange>) => {
    updateValue(event);
    const { value } = event.detail;
    const inputLength = ((value as string) ?? "").trim().length;
    if (!value || inputLength < 1) {
      setStatus("error");
      return;
    }
    setStatus("success");
  };

  return (
    <>
      {props.disabled && isPartOfSummary ? null : (
        <>
          <PLTextinput
            showRemoveIcon={false}
            status={status}
            lhBlur={onBlur}
            value={state}
            lhChange={handleChange}
            readonly={disabled}
            label={title}
            width={width}
            errorText={translations.globals.validationMessages.validation}
          />
        </>
      )}
    </>
  );
};

export default TextFieldComponent;
