import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLBreadcrumbItemProps = DropOnONLh<JSX.PatternlibBreadcrumbItem>;
export type PLBreadcrumbItemExtendedProps = PLBreadcrumbItemProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedBreadcrumbItem = wrapWc<JSX.PatternlibBreadcrumbItem>("patternlib-breadcrumb-item");
export const useRefPLBreadcrumbItem = () => useRef<HTMLPatternlibBreadcrumbItemElement>(null);

const PLBreadcrumbItem = React.forwardRef<HTMLPatternlibBreadcrumbItemElement, PLBreadcrumbItemExtendedProps>((props, ref) => {
  const myRef = useRefPLBreadcrumbItem();
  return (
    <WrappedBreadcrumbItem ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedBreadcrumbItem>
  );
});
PLBreadcrumbItem.displayName = "PLBreadcrumbItem";

export default PLBreadcrumbItem;
