/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line filenames/match-exported
import useDataGrid from "components/templates/dataGridTable";
import React, { useEffect, useMemo } from "react";
import translations from "translations";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { TableTitle } from "../../UserRolesManager/TableTitle";
import { selectedUsersOnRoleState, SelectUser, mappingRoleUsersLoading } from "../../RecoilState/mappingUserRoleState";
import {
  useGetUsersForRoleMappingLazyQuery,
  GetUsersForRoleMappingQuery,
  GetUsersForRoleMappingQueryVariables,
  ResultType,
} from "graphqlBase/Settings/__generated__/getUsersForRoleMapping";
import { QueryLazyOptions } from "@apollo/client";
import { GridValidRowModel } from "@mui/x-data-grid-pro";
export type User = ResultType<GetUsersForRoleMappingQuery["users"]>;
type QueryOptions = QueryLazyOptions<Omit<GetUsersForRoleMappingQueryVariables, "roleIds">> | undefined;

export interface UsersTableRpops {
  roleId: string;
}

const { firstName, lastName, email } = translations.entities.user.columns;
const { plural } = translations.entities.user;
const { assignedTo, noUsersAssignedMessage } = translations.pages.userManagement.userRoleManager;

const RoleUsersTableUpdate: React.FC<UsersTableRpops> = ({ roleId }) => {
  const [selectedUserState, setSelecedtUserState] = useRecoilState(selectedUsersOnRoleState);
  const selectedUserResetState = useResetRecoilState(selectedUsersOnRoleState);

  const variables = {
    where: { mappingRoleUsers: { all: { isDeleted: { neq: true } } } },
  };
  const [queryInner, { data, loading, error }] = useGetUsersForRoleMappingLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: variables,
  });
  const roleUsersLoading = useRecoilValue(mappingRoleUsersLoading);
  const query = (options: QueryOptions = {}) => {
    queryInner({
      ...options,
      variables: { ...options.variables, roleIdFilter: { roleId: { in: [roleId] } } },
    });
  };
  useEffect(() => {
    queryInner({ variables });
  }, [roleId]);
  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef } = useDataGrid<User, "User">({
    query,
    variables,
    tableId: "MappingRoleUsersTable",
    persistance: "runTime",
  });
  const selectedRows = useMemo(
    () =>
      selectedUserState?.reduce<string[]>((userIds, element) => {
        if (element.toDelete) return userIds;
        return userIds.concat(element.userId);
      }, []),
    [selectedUserState]
  );
  useEffect(() => {
    const userMappings = (data?.users ?? []).flatMap((user) => user.mappingRoleUsers);
    if (!userMappings.length) return selectedUserResetState();

    setSelecedtUserState((selectedUsers) => {
      return userMappings.reduce<SelectUser[]>((acc, curr) => {
        const isUserSelected = acc.find(
          (mappedUser) => mappedUser.userId === curr.userId && (mappedUser.roleId ?? "") === roleId
        );
        if (!isUserSelected) {
          const { userId, mappingId, toDelete, roleId } = curr;
          return acc.concat({ userId: userId, mappingId: mappingId, toDelete: toDelete ?? undefined, roleId: roleId });
        }
        return acc;
      }, selectedUsers);
    });
  }, [data?.users]);

  const handleSelectRow = (rows: readonly GridValidRowModel[]) => {
    setSelecedtUserState((selectedUsers) => {
      const userIDsOnUi = apiRef.current.getAllRowIds ? apiRef.current.getAllRowIds() : [];
      const chekedIds = rows.map(({ id }) => id) as string[];
      const SelectedUsersNextPre = selectedUsers.reduce<SelectUser[]>((acc, curr) => {
        if (!userIDsOnUi.includes(curr.userId)) return acc.concat(curr);
        if (chekedIds.includes(curr.userId)) {
          return acc.concat({ ...curr, roleId, toDelete: false });
        } else {
          return acc.concat({ ...curr, toDelete: true, roleId });
        }
      }, []);
      return rows.reduce<SelectUser[]>((acc, curr) => {
        if (acc.find((e) => e.userId === curr.id)) return acc;
        return acc.concat({ userId: curr.id, roleId });
      }, SelectedUsersNextPre);
    });
  };

  const columns = useMakeColumns(
    [
      {
        field: "firstNameUser",
        headerName: firstName,
        flex: 1,
        valueGetter: (params) => {
          return params.row.firstName ?? "";
        },
        remoteOrder: (sort) => ({ firstName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            firstName: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "lastNameUser",
        headerName: lastName,
        flex: 1,
        remoteOrder: (sort) => ({ lastName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            lastName: filterValue,
          }),
        }),
        valueGetter: (params) => {
          return params.row?.lastName ?? "";
        },
        type: "string",
      },
      {
        field: "emailUser",
        headerName: email,
        flex: 1,
        valueGetter: (params) => {
          return params.row?.email ?? "";
        },
        remoteOrder: (sort) => ({ email: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            email: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "tenantUser",
        headerName: translations.entities.tenant.name,
        flex: 1,
        remoteOrder: (sort) => ({ tenant: { tenantName: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            tenant: { tenantName: filterValue },
          }),
        }),
        valueGetter: (params) => {
          return params.row.tenant?.tenantName ?? "";
        },
        type: "string",
      },
    ],
    []
  );

  const tableLoading = loading || !!roleUsersLoading;

  return (
    <>
      {TableTitle(assignedTo)}
      {!error && (
        <DataGrid
          noDataMessage={noUsersAssignedMessage}
          tableTitle={plural}
          tableHeight="40vh"
          columns={columns}
          rows={!tableLoading ? data?.users : []}
          loading={tableLoading}
          selectedRows={selectedRows}
          keepNonExistentRowsSelected
          setSelectedRows={handleSelectRow}
          withMargin={false}
        />
      )}
    </>
  );
};

export default RoleUsersTableUpdate;
