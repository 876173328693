import useDataGrid from "components/templates/dataGridTable";
import { isFuture } from "date-fns";
import { useGetUsersForPreviousDeviceConfigurationsQuery } from "graphqlBase/Devices/__generated__/getUsersForPreviousDeviceConfigurations";
import {
  GetMappingDeviceFirmwareDevicesTwoQuery,
  useGetMappingDeviceFirmwareDevicesTwoLazyQuery,
  ResultType,
} from "graphqlBase/MappingDeviceFirmwareDevice/__generated__/getMappingDeviceFirmwareDevicesTwo";
import { SortEnumType } from "graphqlBase/types";
import React, { useMemo } from "react";
import translations from "translations";
import { formatDateTime } from "translations/formatter";

export type MappingDeviceFirmwareDevice = ResultType<
  GetMappingDeviceFirmwareDevicesTwoQuery["mappingDeviceFirmwareDevices"]
>;
const FirmwareOverview: React.FC<{ deviceId: string }> = ({ deviceId }) => {
  const [query, { data, loading, error }] = useGetMappingDeviceFirmwareDevicesTwoLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const { DataGrid, useMakeColumns, getRowCount } = useDataGrid<
    MappingDeviceFirmwareDevice,
    "MappingDeviceFirmwareDevice"
  >({
    query,
    variables: {
      where: { deviceId: { eq: deviceId } },
      order: [{ dateTo: "DESC" as SortEnumType.Desc }],
    },
    tableId: "FirmwareOverview",
    persistance: "runTime",
  });
  const { data: usersData, loading: usersLoading } = useGetUsersForPreviousDeviceConfigurationsQuery();
  const length = data?.mappingDeviceFirmwareDevices.length;
  const idOfDefaultFirmware = length ? data?.mappingDeviceFirmwareDevices[length - 1].id : "";

  const mappedUsers: { [key: string]: string } | undefined = useMemo(() => {
    return usersData?.users.reduce((acc, currUser) => {
      return { ...acc, [currUser.id]: currUser.userName };
    }, {});
  }, [usersData?.users]);

  const columns = useMakeColumns(
    [
      {
        field: "date",
        headerName: `Date`,
        renderHeader: (params) => `Date (${getRowCount()})`,
        flex: 2,
        valueGetter: (params) => {
          return `${formatDateTime(new Date(params.row.dateFrom ?? ""))} - ${
            !isFuture(new Date(params.row.dateTo ?? "")) ? formatDateTime(new Date(params.row.dateTo ?? "")) : "current"
          }`;
        },
        type: "string",
      },
      {
        field: "version",
        headerName: `${translations.pages.deviceManagement.firmwareUpdate.releaseInfo.firmwareVersion}`,
        flex: 2,
        valueGetter: (params) => {
          return params.row.deviceFirmware?.firmwareVersion ?? "";
        },
        type: "string",
      },
      {
        field: "status",
        headerName: `Status`,
        flex: 2,
        valueGetter: (params) => {
          return params.row.mappingDeviceFirmwareDeviceStatus?.statusName ?? "";
        },
        type: "string",
      },
      {
        field: "setupBy",
        headerName: `Upgrated by`,
        flex: 2,
        valueGetter: (params) => {
          if (!idOfDefaultFirmware ?? !mappedUsers) return;
          if (idOfDefaultFirmware === params.row.id) {
            return "default";
          } else {
            return mappedUsers[params.row.userId as string] ? mappedUsers[params.row.userId as string] : "";
          }
        },
        type: "string",
      },
    ],
    []
  );
  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage=""
          tableTitle=""
          withMargin={false}
          tableHeight="30vh"
          checkboxSelection={false}
          hasToolbar={false}
          columns={columns}
          rows={data?.mappingDeviceFirmwareDevices}
          loading={loading || usersLoading}
        />
      )}
    </>
  );
};

export default FirmwareOverview;
