import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import React from "react";
interface FullScreenButtonsProps {
  enterFullScreen: () => Promise<void>;
  exitFullScreen: () => Promise<void>;
  isFullScreen: boolean;
}
const FullScreenButtons: React.FC<FullScreenButtonsProps> = ({ isFullScreen, enterFullScreen, exitFullScreen }) => {
  return (
    <div className="leaflet-top leaflet-right">
      <div className="leaflet-control leaflet-bar" style={{ border: "none", marginLeft: 0, background: "white" }}>
        {!isFullScreen ? (
          <PLButton type="secondary" onClick={enterFullScreen}>
            <PLIcon iconName="move" size="24px" slot="icon" />
          </PLButton>
        ) : (
          <PLButton type="secondary" onClick={exitFullScreen}>
            <PLIcon iconName="meeting-point" size="24px" slot="icon" />
          </PLButton>
        )}
      </div>
    </div>
  );
};

export default FullScreenButtons;
