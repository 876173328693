import { Button } from "@mui/material";
import PLIcon from "components/patternLib/PLIcon";
import React from "react";

interface DeleteButtonProps {
  onClick?: () => void;
}

export default ({ onClick }: DeleteButtonProps) => {
  return (
    <Button sx={{ minWidth: "fit-content", padding: 0, display: "flex", marginBottom: "-4.5px" }} onClick={onClick}>
      <PLIcon size="24px" color="#000" iconName="bin" />
    </Button>
  );
};
