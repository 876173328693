import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserIdAndLanguageQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserFilterInput>;
}>;


export type GetUserIdAndLanguageQuery = { users: Array<{ __typename?: 'User', id: string, language?: Maybe<string> }> };


export const GetUserIdAndLanguageDocument = gql`
    query getUserIdAndLanguage($where: UserFilterInput) {
  users(where: $where) {
    id
    language
  }
}
    `;

/**
 * __useGetUserIdAndLanguageQuery__
 *
 * To run a query within a React component, call `useGetUserIdAndLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdAndLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdAndLanguageQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserIdAndLanguageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserIdAndLanguageQuery, GetUserIdAndLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserIdAndLanguageQuery, GetUserIdAndLanguageQueryVariables>(GetUserIdAndLanguageDocument, options);
      }
export function useGetUserIdAndLanguageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserIdAndLanguageQuery, GetUserIdAndLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserIdAndLanguageQuery, GetUserIdAndLanguageQueryVariables>(GetUserIdAndLanguageDocument, options);
        }
export type GetUserIdAndLanguageQueryHookResult = ReturnType<typeof useGetUserIdAndLanguageQuery>;
export type GetUserIdAndLanguageLazyQueryHookResult = ReturnType<typeof useGetUserIdAndLanguageLazyQuery>;
export type GetUserIdAndLanguageQueryResult = Apollo.QueryResult<GetUserIdAndLanguageQuery, GetUserIdAndLanguageQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
