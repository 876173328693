export default {
  name: "Notification Definition",
  plural: "Notification Definitions",
  columns: {
    id: "ID",
    notificationId: "Notification Definition ID",
    notificationOperatorId: "Notification Operator ID",
    value: "Value",
    createdAt: "Created At",
    modifiedAt: "Modified At",
    tenantId: "",
  },
};
