import React, { useState, useEffect, useMemo } from "react";
import { TableInstance } from "react-table";
import { atom, useRecoilState } from "recoil";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Dropdown from "components/lbc-toolkit/molecules/Dropdown";
import MuiButton from "@mui/material/Button";
import DragHandleIcon from "../../../../icons/components/DragHandleIcon";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import getTestIDs from "lib/utils/getTestIDs";

export const testIDs = getTestIDs();
interface DropdownOption {
  label: string;
  value: string;
}

interface HiddenColumnsSidebarProps {
  labelCancel: string;
  labelOk: string;
  toggleOpen?: () => void;
}

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      dragClass: {
        backgroundColor: theme.colors.tWhite,
        "& .innerHandleIcon": {
          visibility: "visible",
        },
        "& .removeColumnIcon": {
          visibility: "visible",
        },
      },
      dragList: {
        backgroundColor: "transparent",
        width: "100%",
        "& .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper": {
          overflow: "visible",
        },
      },
      dropdownRoot: {
        display: "flex",
        alignItems: "center",
        width: "100%",
      },
      dropdownSingle: {
        width: "100%",
        margin: "5px 0",
        cursor: "pointer",
      },
      dropdowns: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      handle: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        cursor: "move",
        "&:hover": {
          visibility: "visible",
          backgroundColor: theme.colors.tWhite,
        },
        "&:hover .innerHandleIcon": {
          visibility: "visible",
        },
        "&:hover .removeColumnIcon": {
          visibility: "visible",
          cursor: "pointer",
        },
      },
      innerHandle: {
        margin: "0 5px",
        width: "12%",
        display: "flex",
        justifyContent: "center",
        visibility: "hidden",
      },
      lbcdropdown: {
        "& button": {
          boxSizing: "content-box",
          cursor: "pointer",
        },
        "& div": {
          backgroundColor: theme.colors.grey200,
          color: theme.colors.grey600,
          fontFamily: "Arial",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "16px",
        },
      },
      removeColumn: {
        color: "#BC1A1A",
        marginLeft: "10px",
        marginRight: "10px",
        cursor: "pointer",
        visibility: "hidden",
      },
      root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      buttons: {
        flexGrow: 1,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        marginTop: 30,
        marginLeft: "41px",
        marginRight: "42px",
        marginBottom: "33px",
      },
      addButton: {
        marginLeft: "8px",
        width: "292px",
        paddingTop: "2px",
        paddingBottom: "2px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: theme.colors.grey200,
        "&:disabled": {
          backgroundColor: theme.colors.grey200,
        },
      },
      cancelButton: {
        cursor: "pointer",
        backgroundColor: "transparent",
        "& ._2KqOlPp-tqyIyeEG21oSN7": {
          fontSize: "16px",
        },
      },
      okButton: {
        cursor: "pointer",
        "& ._2KqOlPp-tqyIyeEG21oSN7": {
          fontSize: "16px",
        },
      },
      plus: {
        color: theme.colors.grey600,
      },
    }),
  { name: `useHideColumns` }
);

const dropdownIds = atom<string[]>({ key: "dropdownIds", default: [] });

function reorder(list: string[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function areArraysEqual(array1: string[], array2: string[]) {
  const array1Sorted = array1.sort((a, b) => a.localeCompare(b));
  const array2Sorted = array2.sort((a, b) => a.localeCompare(b));

  return (
    array1Sorted.length === array2Sorted.length &&
    array1Sorted.every(function (element, index) {
      return element === array2Sorted[index];
    })
  );
}

function buildHandle() {
  const Handle: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
      <div className={classes.handle}>
        <span className={classes.innerHandle}>
          <span className="innerHandleIcon" style={{ display: "flex" }}>
            <DragHandleIcon />
          </span>
        </span>
        {children}
      </div>
    );
  };
  return Handle;
}

function buildHideColumnsDropdown<D extends object = {}>(tableinstance: TableInstance<D>, columnId: string) {
  const HideColumnsDropdown: React.FC = () => {
    const classes = useStyles();
    const [currentDropdownIds, setCurrentDropdownIds] = useRecoilState(dropdownIds);
    const [currentDropdownOptions, setCurrentDropdownOptions] = useState<DropdownOption[]>([]);
    const [currentSelectedOption, setCurrentSelectedOption] = useState(columnId);

    useEffect(() => {
      const otherVisibleColumnIds = tableinstance.visibleColumns.reduce((acc: string[], curr) => {
        if (curr.id === columnId) return acc;
        return acc.concat(curr.id);
      }, []);
      const dropdownOptions = tableinstance.allColumns.reduce((acc: DropdownOption[], curr) => {
        if (otherVisibleColumnIds.includes(curr.id)) return acc;
        // @TODO integrate iterating function from filter component
        if (typeof curr.Header !== "string") return acc;
        return acc.concat({ label: curr.Header, value: curr.id });
      }, []);

      setCurrentDropdownOptions(dropdownOptions);
    }, []);

    useEffect(() => {
      const currentDropdownOptions = tableinstance.allColumns.reduce((acc: DropdownOption[], curr) => {
        const visibleColumnsCopy = [...currentDropdownIds];
        const temp2 = visibleColumnsCopy.filter((visibleColumn) => visibleColumn !== currentSelectedOption);
        if (temp2.includes(curr.id)) return acc;
        // @TODO integrate iterating function from filter component
        if (typeof curr.Header !== "string") return acc;
        return acc.concat({ label: curr.Header, value: curr.id });
      }, []);
      const orderedOptions = currentDropdownOptions.sort(function (a, b) {
        const textA = a.label.toUpperCase();
        const textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      setCurrentDropdownOptions(orderedOptions);
    }, [currentDropdownIds]);

    const handleOnSelect = (value: string) => {
      const visibleColumnsCopy = [...currentDropdownIds];
      const indexOld = visibleColumnsCopy.indexOf(currentSelectedOption);
      if (indexOld !== -1) {
        visibleColumnsCopy.splice(indexOld, 1, value);
      }

      setCurrentDropdownIds(visibleColumnsCopy);
      setCurrentSelectedOption(value);
    };

    const handleRemoveDropdown = () => {
      const visibleColumnsCopy = [...currentDropdownIds];
      const index = visibleColumnsCopy.indexOf(currentSelectedOption);
      if (index > -1) {
        visibleColumnsCopy.splice(index, 1);
        setCurrentDropdownIds(visibleColumnsCopy);
      }
    };

    return (
      <div className={classes.dropdownRoot}>
        <div className={classes.dropdownSingle}>
          <Dropdown
            id="use-hide-columns-dropdown"
            options={currentDropdownOptions}
            width="100%"
            onSelect={handleOnSelect}
            selected={currentSelectedOption}
            testId={testIDs.useHideColumnsDropdown}
          />
        </div>
        <div onClick={handleRemoveDropdown} className={classes.removeColumn}>
          <div
            className="removeColumnIcon"
            style={{
              display: "flex",
              alignItems: "center",
              width: "17px",
              height: "27px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "normal",
              justifyContent: "flex-end",
              fontSize: "20px",
            }}
          >
            X
          </div>
        </div>
      </div>
    );
  };

  return HideColumnsDropdown;
}

function createDropdowns<D extends object = {}>(tableinstance: TableInstance<D>, currentVisibleColumnIds: string[]) {
  return currentVisibleColumnIds.map((columnId, index) => {
    const Dropdown = buildHideColumnsDropdown<D>(tableinstance, columnId);
    const Handle = buildHandle();
    return (
      <Draggable key={columnId}>
        <ListItem style={{ padding: 0 }} className="drag-handle">
          <Handle>
            <Dropdown />
          </Handle>
        </ListItem>
      </Draggable>
    );
  });
}

function useHideColumns<D extends object = {}>(tableinstance: TableInstance<D>) {
  const HiddenColumnsSidebar: React.FC<HiddenColumnsSidebarProps> = ({ toggleOpen, labelOk, labelCancel }) => {
    const classes = useStyles();
    const [currentDropdownIds, setCurrentDropdownIds] = useRecoilState(dropdownIds);
    const dropdowns = useMemo(() => createDropdowns(tableinstance, currentDropdownIds), [currentDropdownIds]);

    const allColumnIds = useMemo(() => tableinstance.allColumns.map((col) => col.id), [tableinstance.allColumns]);
    const hiddenColumnIds = useMemo(
      () => allColumnIds.filter((id) => !currentDropdownIds.includes(id) && id !== "selector" && id !== "expander"),
      [currentDropdownIds, allColumnIds]
    );
    const diasbleAddColumn = useMemo(() => areArraysEqual(allColumnIds, [...currentDropdownIds]), [
      allColumnIds,
      currentDropdownIds,
    ]);

    useEffect(() => {
      const ids = tableinstance.visibleColumns.reduce((acc: string[], curr) => {
        if (["selector", "expander"].includes(curr.id)) return acc;
        return acc.concat(curr.id);
      }, []);
      setCurrentDropdownIds(ids);
    }, [setCurrentDropdownIds, tableinstance.visibleColumns]);

    const handleSaveChanges = () => {
      tableinstance.setHiddenColumns(() => hiddenColumnIds);
      const dropdownIds = [...currentDropdownIds];

      if (tableinstance.allColumns.some((column) => column.id === "expander")) {
        dropdownIds.unshift("expander");
      }

      if (tableinstance.allColumns.some((column) => column.id === "selector")) {
        dropdownIds.unshift("selector");
      }
      tableinstance.setColumnOrder(() => dropdownIds);
      if (toggleOpen) toggleOpen();
    };

    const handleAddColumn = () => {
      const [extracted] = hiddenColumnIds;
      if (!extracted) return;
      setCurrentDropdownIds(currentDropdownIds.concat(extracted));
    };

    const handleOnDragEnd = ({ removedIndex, addedIndex }: DropResult) => {
      if (removedIndex == null || addedIndex == null) return;
      const items = reorder(currentDropdownIds, removedIndex, addedIndex);
      setCurrentDropdownIds(items);
    };

    const handleToggleOpen = () => {
      if (toggleOpen) toggleOpen();
    };

    return (
      <div className={classes.root}>
        <div className={classes.dropdowns}>
          <List className={classes.dragList}>
            <Container
              dragClass={classes.dragClass}
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={handleOnDragEnd}
            >
              {dropdowns.map((dropdown) => dropdown)}
            </Container>
          </List>
          <MuiButton
            variant="contained"
            className={classes.addButton}
            startIcon={<div className={classes.plus}>+</div>}
            disabled={diasbleAddColumn}
            onClick={handleAddColumn}
          />
        </div>
        {/* @todo fixTranslation */}
        <div className={classes.buttons}>
          <Button
            style={{ width: "120px" }}
            className={classes.cancelButton}
            buttonType="secondary"
            label={labelCancel}
            onClick={handleToggleOpen}
            testId={testIDs.cancelButton}
          />
          <Button
            className={classes.okButton}
            style={{ width: "120px" }}
            buttonType="primary"
            label={labelOk}
            onClick={handleSaveChanges}
            testId={testIDs.okButton}
          />
        </div>
      </div>
    );
  };

  return useMemo(() => HiddenColumnsSidebar, [tableinstance]);
}

export default useHideColumns;
