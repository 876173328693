import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetScopesQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.ScopeFilterInput>;
  order?: Types.InputMaybe<Array<Types.ScopeSortInput> | Types.ScopeSortInput>;
}>;


export type GetScopesQuery = { scopes: Array<{ __typename?: 'Scope', id: string, name: string, description?: Maybe<string>, value: string }> };


export const GetScopesDocument = gql`
    query getScopes($skip: Int, $take: Int, $where: ScopeFilterInput, $order: [ScopeSortInput!]) {
  scopes(skip: $skip, take: $take, where: $where, order: $order) {
    id
    name
    description
    value
  }
}
    `;

/**
 * __useGetScopesQuery__
 *
 * To run a query within a React component, call `useGetScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScopesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetScopesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScopesQuery, GetScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetScopesQuery, GetScopesQueryVariables>(GetScopesDocument, options);
      }
export function useGetScopesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScopesQuery, GetScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetScopesQuery, GetScopesQueryVariables>(GetScopesDocument, options);
        }
export type GetScopesQueryHookResult = ReturnType<typeof useGetScopesQuery>;
export type GetScopesLazyQueryHookResult = ReturnType<typeof useGetScopesLazyQuery>;
export type GetScopesQueryResult = Apollo.QueryResult<GetScopesQuery, GetScopesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
