import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLRadiobuttonProps = DropOnONLh<JSX.PatternlibRadiobutton>;
export type PLRadiobuttonExtendedProps = PLRadiobuttonProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedRadiobutton = wrapWc<JSX.PatternlibRadiobutton>("patternlib-radiobutton");
export const useRefPLRadiobutton = () => useRef<HTMLPatternlibRadiobuttonElement>(null);

const PLRadiobutton = React.forwardRef<HTMLPatternlibRadiobuttonElement, PLRadiobuttonExtendedProps>((props, ref) => {
  const myRef = useRefPLRadiobutton();
  return (
    <WrappedRadiobutton ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedRadiobutton>
  );
});
PLRadiobutton.displayName = "PLRadiobutton";

export default PLRadiobutton;
