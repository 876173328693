import terms from "./terms/en";
import validationMessages from "./validationMessages/en";
import helpText from "./helpText/en";
import button from "./button/en";
import toastMessages from "./toastMessages/en";
import popupMessages from "./popupMessages/en";
import notifications from "./notifications/en";
import placeholders from "./placeholders/en";
import months from "./months/en";
import shortTerms from "./shortTerms/en";
import headlines from "./headlines/en";
import pluralTerms from "./pluralTerms/en";
import table from "./table/en";
import time from "./time/en";
import filterOperators from "./filterOperators/en";

export default {
  terms,
  validationMessages,
  helpText,
  button,
  toastMessages,
  popupMessages,
  notifications,
  placeholders,
  months,
  shortTerms,
  headlines,
  pluralTerms,
  table,
  time,
  filterOperators,
};
