import React from "react";
import { reactPlugin } from "./AppInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import ErrorComponent from "./ErrorComponent";
import ErrorPage from "./ErrorPage";

const ErrorBoundary: React.FC<{}> = ({ children }) => {
  return (
    <AppInsightsErrorBoundary onError={ErrorPage} appInsights={reactPlugin}>
      {children}
    </AppInsightsErrorBoundary>
  );
};

export default ErrorBoundary;
