const singleMultiNameMappings: { [key: string]: string } = {
          assetAttribute : "assetAttributes",
          assetAttributeDataType : "assetAttributeDataTypes",
          assetAttributeUnit : "assetAttributeUnits",
          assetAttributeValue : "assetAttributeValues",
          asset : "assets",
          assetDevice : "assetDevices",
          assetImage : "assetImages",
          assetMasterData : "assetMasterDatas",
          assetMasterDataSource : "assetMasterDataSources",
          assetNote : "assetNotes",
          assetSource : "assetSources",
          assetTag : "assetTags",
          assetTypeAttributeSet : "assetTypeAttributeSets",
          capability : "capabilities",
          deviceAttribute : "deviceAttributes",
          deviceAttributeValue : "deviceAttributeValues",
          deviceCapability : "deviceCapabilities",
          deviceConfiguration : "deviceConfigurations",
          device : "devices",
          deviceFirmwareConfigurationSchema : "deviceFirmwareConfigurationSchemas",
          deviceFirmware : "deviceFirmwares",
          deviceFirmwareReleaseType : "deviceFirmwareReleaseTypes",
          deviceModelAttributeSet : "deviceModelAttributeSets",
          deviceModelCapability : "deviceModelCapabilities",
          deviceModel : "deviceModels",
          deviceModelVersion : "deviceModelVersions",
          deviceNotification : "deviceNotifications",
          deviceType : "deviceTypes",
          gatewayDeviceConnection : "gatewayDeviceConnections",
          group : "groups",
          groupEntity : "groupEntities",
          groupEntityFilter : "groupEntityFilters",
          groupEntityFilterDataType : "groupEntityFilterDataTypes",
          groupEntityFilterUiType : "groupEntityFilterUiTypes",
          groupOperator : "groupOperators",
          mappingAssetTag : "mappingAssetTags",
          mappingAttributeAssetTypeAttributeSet : "mappingAttributeAssetTypeAttributeSets",
          mappingAttributeDeviceModelAttributeSet : "mappingAttributeDeviceModelAttributeSets",
          mappingDeviceConfigurationDevice : "mappingDeviceConfigurationDevices",
          mappingDeviceConfigurationDeviceStatus : "mappingDeviceConfigurationDeviceStatuses",
          mappingDeviceFirmwareDevice : "mappingDeviceFirmwareDevices",
          mappingDeviceFirmwareDeviceStatus : "mappingDeviceFirmwareDeviceStatuses",
          mappingDeviceFirmwareDeviceType : "mappingDeviceFirmwareDeviceTypes",
          mappingDeviceNotification : "mappingDeviceNotifications",
          mappingDeviceTag : "mappingDeviceTags",
          mappingGroupEntityFilterGroupOperator : "mappingGroupEntityFilterGroupOperators",
          mappingGroupEntityGroupEntityFilter : "mappingGroupEntityGroupEntityFilters",
          mappingNotificationScopeNotificationOperator : "mappingNotificationScopeNotificationOperators",
          mappingNotificationUser : "mappingNotificationUsers",
          mappingRoleScope : "mappingRoleScopes",
          mappingRoleUser : "mappingRoleUsers",
          mappingUserScope : "mappingUserScopes",
          notification : "notifications",
          notificationDefinition : "notificationDefinitions",
          notificationOperator : "notificationOperators",
          notificationScope : "notificationScopes",
          role : "roles",
          ruleAction : "ruleActions",
          rule : "rules",
          ruleReference : "ruleReferences",
          ruleReferenceUnit : "ruleReferenceUnits",
          ruleResultType : "ruleResultTypes",
          scope : "scopes",
          sensorMeasurementCurrentValue : "sensorMeasurementCurrentValues",
          sensorMeasurementHistory : "sensorMeasurementHistories",
          storageArea : "storageAreas",
          storageAreaType : "storageAreaTypes",
          tag : "tags",
          tenant : "tenants",
          translation : "translations",
          unit : "units",
          userConfiguration : "userConfigurations",
          user : "users",
}
export default singleMultiNameMappings