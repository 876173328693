import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables = Types.Exact<{
  geofencesCoordinates?: Types.InputMaybe<Types.Scalars['Coordinates']>;
}>;


export type GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery = { queryDeviceWithinGeofence?: Maybe<{ __typename?: 'GeofenceDevices', geofenceDevices?: Maybe<Array<Maybe<{ __typename?: 'GeofenceDevice', deviceId: string, device: { __typename?: 'Device', id: string, assetDevice?: Maybe<Array<Maybe<{ __typename?: 'AssetDevice', id: string, assetId: string, asset: { __typename?: 'Asset', id: string } }>>> } }>>> }> };


export const GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesDocument = gql`
    query getDeviceIdsOnSensorMeasurementCurrentValuesByCoordinates($geofencesCoordinates: Coordinates) {
  queryDeviceWithinGeofence(geofences: {type: MultiPolygon, coordinates: $geofencesCoordinates}) {
    geofenceDevices {
      deviceId
      device {
        id
        assetDevice {
          id
          assetId
          asset {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery__
 *
 * To run a query within a React component, call `useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery({
 *   variables: {
 *      geofencesCoordinates: // value for 'geofencesCoordinates'
 *   },
 * });
 */
export function useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery, GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery, GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables>(GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesDocument, options);
      }
export function useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery, GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery, GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables>(GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesDocument, options);
        }
export type GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryHookResult = ReturnType<typeof useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery>;
export type GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesLazyQueryHookResult = ReturnType<typeof useGetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesLazyQuery>;
export type GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryResult = Apollo.QueryResult<GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery, GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
