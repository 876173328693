import { Client, QueryArray } from "../formGen.model";

type ResType = {
  [key: string]: Array<{ __typename: string; id: number } & any>;
};

const getAlisases = (entity: string, body: string) => {
  const regexStr = `(?<alias>[\\w\d*]*):\\W*${entity}s {`;
  const regex = new RegExp(regexStr, "gm");

  let m;
  let aliases = [];

  while ((m = regex.exec(body)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    if (m.groups) aliases.push(m.groups.alias);
  }
  return aliases;
};

export default async ({
  data,
  client,
  queries,
  type,
  entity,
}: {
  data: any;
  client: Client;
  queries: QueryArray;
  type: "add" | "delete";
  // cacheUpdaters: {
  //   fields: string[];
  //   fragments: string[];
  // };
  entity: string;
}) => {
  try {
    const updates = queries.map(({ refetch, ...operation }, index) => {
      if (refetch)
        return client
          .query({
            ...operation,
            fetchPolicy: "network-only",
          })
          .catch((e) => console.log("assad", e));
      try {
        const aliases = getAlisases(entity, operation.query.loc?.source.body ?? "");
        const typeName: string = data.__typename;

        const res: ResType =
          client.cache.readQuery({
            ...operation,
          }) || {};

        const toUpate = Object.entries(res).reduce((entries: string[], [key, value]) => {
          if (
            key === `${entity}s` ||
            aliases.includes(key) ||
            value.find((vals) => {
              // console.log(vals, typeName);
              return vals.__typename === typeName;
            })
          )
            return entries.concat(key);
          return entries;
        }, [])[0];
        const nextRes: ResType = { ...res };

        if (type === "delete") {
          const ids = typeof data === "number" ? [data] : data;
          nextRes[toUpate] = nextRes[toUpate].filter((entry) => !ids.includes(entry.id));
        }
        if (type === "add") {
          nextRes[toUpate] = nextRes[toUpate].concat(data);
        }

        client.cache.writeQuery({
          ...operation,
          data: nextRes,
        });

        return client
          .query({
            ...operation,
            fetchPolicy: "cache-only",
          })
          .catch((e) => console.log("assad", e));
      } catch (e) {
        return Promise.resolve();
      }
    });

    return await (await Promise.all(updates)).map((res) => res);
  } catch (e) {
    console.error(e);
    return [];
  }
};
