import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTabsProps = DropOnONLh<JSX.PatternlibTabs>;
export type PLTabsExtendedProps = PLTabsProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTabs = wrapWc<JSX.PatternlibTabs>("patternlib-tabs");
export const useRefPLTabs = () => useRef<HTMLPatternlibTabsElement>(null);

const PLTabs = React.forwardRef<HTMLPatternlibTabsElement, PLTabsExtendedProps>((props, ref) => {
  const myRef = useRefPLTabs();
  return (
    <WrappedTabs ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTabs>
  );
});
PLTabs.displayName = "PLTabs";

export default PLTabs;
