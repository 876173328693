import PLList, { PLListProps } from "components/patternLib/PLList";
import PLListItem, { PLListItemProps } from "components/patternLib/PLListItem";
import React from "react";

export interface ArrayValue {
  label: string;
  value?: string;
}

interface DetailListProps extends PLListProps {
  data: ArrayValue[];
  itemProps?: PLListItemProps;
}

const DetailList: React.FC<DetailListProps> = ({ data, itemProps, ...ListProps }) => {
  return (
    <PLList {...ListProps}>
      {data.map(({ label, value }) => (
        <PLListItem key={label} {...itemProps} labelWidth={"50%"}>
          <span>{label}</span>
          <span slot="value">{value}</span>
        </PLListItem>
      ))}
    </PLList>
  );
};

export default DetailList;
