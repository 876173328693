import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersForRoleMappingQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.UserSortInput> | Types.UserSortInput>;
  roleIdFilter?: Types.InputMaybe<Types.RoleFilterInput>;
}>;


export type GetUsersForRoleMappingQuery = { users: Array<{ __typename?: 'User', id: string, userName: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string>, mappingRoleUsers: Array<{ __typename?: 'MappingRoleUser', id: string, userId: string, roleId: string, mappingId: string, toDelete?: Maybe<boolean>, role: { __typename?: 'Role', id: string, name: string, description?: Maybe<string> } }>, tenant: { __typename?: 'Tenant', id: string, tenantName: string } }> };


export const GetUsersForRoleMappingDocument = gql`
    query getUsersForRoleMapping($where: UserFilterInput, $take: Int, $skip: Int, $order: [UserSortInput!], $roleIdFilter: RoleFilterInput) {
  users(where: $where, take: $take, skip: $skip, order: $order) {
    id
    userName
    firstName
    lastName
    email
    mappingRoleUsers(where: {isDeleted: {neq: true}, role: $roleIdFilter}) {
      id
      mappingId: id
      userId
      toDelete: isDeleted
      roleId
      role {
        id
        name
        description
      }
    }
    tenant {
      id
      tenantName
    }
  }
}
    `;

/**
 * __useGetUsersForRoleMappingQuery__
 *
 * To run a query within a React component, call `useGetUsersForRoleMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForRoleMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForRoleMappingQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      roleIdFilter: // value for 'roleIdFilter'
 *   },
 * });
 */
export function useGetUsersForRoleMappingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersForRoleMappingQuery, GetUsersForRoleMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUsersForRoleMappingQuery, GetUsersForRoleMappingQueryVariables>(GetUsersForRoleMappingDocument, options);
      }
export function useGetUsersForRoleMappingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersForRoleMappingQuery, GetUsersForRoleMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUsersForRoleMappingQuery, GetUsersForRoleMappingQueryVariables>(GetUsersForRoleMappingDocument, options);
        }
export type GetUsersForRoleMappingQueryHookResult = ReturnType<typeof useGetUsersForRoleMappingQuery>;
export type GetUsersForRoleMappingLazyQueryHookResult = ReturnType<typeof useGetUsersForRoleMappingLazyQuery>;
export type GetUsersForRoleMappingQueryResult = Apollo.QueryResult<GetUsersForRoleMappingQuery, GetUsersForRoleMappingQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
