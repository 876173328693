import {
  GridFilterModel,
  gridFilterModelSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridSortModelSelector,
} from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/entityMappings";
import { SortEnumType } from "graphqlBase/types";
import * as React from "react";
import { useMemo } from "react";
import { EditableGridStateColDef, OnFilterOnSortCallBackProps, QuickfilterSeperatorFunc } from "./types";

const defaultQuickfilterSerperatorFunc: QuickfilterSeperatorFunc = (quickFilterValues) => {
  return quickFilterValues
    .join(" ")
    .split(",")
    .map((value) => value.trim());
};

function onQueryParmsChange<E extends keyof EntityMappings>({
  query,
  apiRef,
  variables = {},
  quickfilterSeperatorFunc,
}: OnFilterOnSortCallBackProps<E>) {
  const take = gridPageSizeSelector(apiRef);
  const skip = gridPageSelector(apiRef) * take;
  const filterModel = gridFilterModelSelector(apiRef);
  const filterPre = filterModel.items.reduce<Array<EntityMappings[E]["filter"]>>(
    (where, { columnField, operatorValue, value }) => {
      const column = apiRef.current.getColumn(columnField) as EditableGridStateColDef | undefined;
      if (!column?.remoteFilter?.filterPath || !operatorValue || !value) return where;
      const filterFunc = column.remoteFilter?.filterPath ?? function () {};

      return where.concat(
        filterFunc({
          where,
          filterValue: { [operatorValue]: value },
        }) as EntityMappings[E]["filter"]
      );
    },
    []
  );

  const quickfilterSeperatorFuncInst = quickfilterSeperatorFunc ?? defaultQuickfilterSerperatorFunc;

  const quickValues = quickfilterSeperatorFuncInst(filterModel.quickFilterValues ?? []);

  const fuzzy = (apiRef.current.getVisibleColumns() as EditableGridStateColDef[]).reduce<any[]>(
    (where, { type, remoteFilter }) => {
      if (!quickValues.length || (quickValues.length === 1 && !quickValues[0].length)) return where;
      if (type === "string" && remoteFilter) {
        const filterFunc = remoteFilter?.filterPath ?? function () {};
        const condition = quickValues.map((value) => ({ contains: value }));
        return where.concat(
          filterFunc({
            where,
            filterValue: { or: condition },
          }) as EntityMappings[E]["filter"]
        );
      }
      if (type === "stringArray" && remoteFilter) {
        const filterFunc = remoteFilter?.filterPath ?? function () {};
        const condition = { contains: quickValues[0] };
        return where.concat(
          filterFunc({
            where,
            filterValue: { or: condition },
          }) as EntityMappings[E]["filter"]
        );
      }
      return where;
    },
    []
  );
  const fuzzyFilter = { or: [...fuzzy] };
  const filter = fuzzy.length ? (filterPre.length ? filterPre.concat(fuzzyFilter) : [fuzzyFilter]) : filterPre;

  const combinator = variables.where?.[filterModel.linkOperator ?? "and"];
  const nextWhere = filter.length ? [...(combinator ?? []), ...filter] : undefined;

  const where = nextWhere ? { ...variables.where, [filterModel.linkOperator ?? "and"]: nextWhere } : variables.where;

  const sort = gridSortModelSelector(apiRef).reduce<Array<EntityMappings[E]["sort"]>>((sorts, elem) => {
    const column = apiRef.current.getColumn(elem.field) as EditableGridStateColDef | undefined;
    if (!column?.remoteOrder || !elem.sort) return sorts;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const remoteOrder = column.remoteOrder as (sort: SortEnumType) => any;
    return sorts.concat(remoteOrder(elem.sort.toUpperCase() as SortEnumType));
  }, []);

  const sortIn = variables.order ?? [];
  const order = sortIn || sort.length ? [...sort, ...(Array.isArray(sortIn) ? sortIn : [sortIn])] : undefined;
  query({
    variables: { ...variables, take: take + 1, skip, order, where },
  });
}
export function useExecuteQuery<E extends keyof EntityMappings>({
  apiRef,
  query,
  variables,
  quickfilterSeperatorFunc,
}: OnFilterOnSortCallBackProps<E>): () => void {
  React.useEffect(() => {
    onQueryParmsChange<E>({ query, apiRef, variables, quickfilterSeperatorFunc });
  }, []);
  return useMemo(
    () => () => onQueryParmsChange<E>({ query, apiRef, variables, quickfilterSeperatorFunc }),
    [apiRef]
  );
}
