import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentPositionOfAssetQueryVariables = Types.Exact<{
  deviceIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type GetCurrentPositionOfAssetQuery = { deviceLocation?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementMovementHistory', deviceId: string, utcTimeMeasured: string, dateFrom: string, dateTo: string, isLast?: Maybe<boolean>, distance?: Maybe<number>, location?: Maybe<{ __typename?: 'GeoJSONPointType', coordinates?: Maybe<[number,number]> }>, azureMapsLocations?: Maybe<Array<Maybe<{ __typename?: 'AzureMapReverseBatchResponseItemsContentAddresses', address?: Maybe<{ __typename?: 'AzureMapsReverseBatchResponseItemsContentAddress', streetName?: Maybe<string>, streetNumber?: Maybe<string>, municipalitySubdivision?: Maybe<string>, streetNameAndNumber?: Maybe<string>, municipality?: Maybe<string> }> }>>> }>>> };


export const GetCurrentPositionOfAssetDocument = gql`
    query getCurrentPositionOfAsset($deviceIds: [UUID!]) {
  deviceLocation(deviceIds: $deviceIds) {
    deviceId
    utcTimeMeasured
    dateFrom
    dateTo
    isLast
    distance
    location {
      coordinates
    }
    azureMapsLocations {
      address {
        streetName
        streetNumber
        municipalitySubdivision
        streetNameAndNumber
        municipality
        municipalitySubdivision
      }
    }
  }
}
    `;

/**
 * __useGetCurrentPositionOfAssetQuery__
 *
 * To run a query within a React component, call `useGetCurrentPositionOfAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPositionOfAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPositionOfAssetQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useGetCurrentPositionOfAssetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentPositionOfAssetQuery, GetCurrentPositionOfAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCurrentPositionOfAssetQuery, GetCurrentPositionOfAssetQueryVariables>(GetCurrentPositionOfAssetDocument, options);
      }
export function useGetCurrentPositionOfAssetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentPositionOfAssetQuery, GetCurrentPositionOfAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCurrentPositionOfAssetQuery, GetCurrentPositionOfAssetQueryVariables>(GetCurrentPositionOfAssetDocument, options);
        }
export type GetCurrentPositionOfAssetQueryHookResult = ReturnType<typeof useGetCurrentPositionOfAssetQuery>;
export type GetCurrentPositionOfAssetLazyQueryHookResult = ReturnType<typeof useGetCurrentPositionOfAssetLazyQuery>;
export type GetCurrentPositionOfAssetQueryResult = Apollo.QueryResult<GetCurrentPositionOfAssetQuery, GetCurrentPositionOfAssetQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
