import { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { SelectItem } from "components/lbc-toolkit/molecules/Select";
import MultiSelectFreeSolo from "components/molecules/MultiSelectFreeSolo";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import { TagCreateType } from "graphqlBase/types";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useMemo } from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { Tag } from ".";
import { InputAndButtonWrapperSC, TagCreateContainerSC } from "./styles";
import { freeSoloChipsListAtom, tagsToCreateAtom } from "./tagsSidebarState";

export const testIDs = getTestIDs();

interface TagsInputProps {
  assetTags: Array<Tag>;
  handleAdd: () => Promise<void>;
  loading?: boolean;
  tagOptions: SelectItem[];
}

interface FreeSolo {
  existingTags: SelectItem[];
  newTags: TagCreateType[];
}

const ButtonContainerSC = styled("div")(
  () => `
        display: flex;
        justify-content: center;
`
);

const TagsInput: React.FC<TagsInputProps> = ({ tagOptions, handleAdd, loading, assetTags }) => {
  const [chips, setChips] = useRecoilState(freeSoloChipsListAtom);
  const [createAssetTags, setCreateAssetTags] = useRecoilState(tagsToCreateAtom);

  const selected: SelectItem[] = useMemo(() => {
    return chips.concat(
      createAssetTags.map((elem) => {
        return { label: elem.tagValue, value: "" };
      })
    );
  }, [chips, createAssetTags]);

  const compareIgnoreCase = (str1: string, str2: string) => str1.toLowerCase() === str2.toLowerCase();

  const createTagsCB = (value: (string | SelectItem)[]) => {
    const result = value.reduce(
      (acc: FreeSolo, curr) => {
        const existingTags = tagOptions?.filter((assetTag) =>
          typeof curr === "string"
            ? compareIgnoreCase(assetTag.label, curr)
            : compareIgnoreCase(assetTag.label, curr.label)
        );
        if ((typeof curr === "string" || curr.value === "") && !existingTags?.length) {
          const tagValue = typeof curr === "string" ? curr : curr.label;
          return { ...acc, newTags: acc.newTags.concat({ tagValue }) };
        } else {
          return { ...acc, existingTags: acc.existingTags.concat(existingTags) };
        }
      },
      { existingTags: [], newTags: [] }
    );
    setChips((prevChips) => {
      const updatedChips = [...prevChips];

      result.existingTags.forEach((newTag) => {
        if (
          !prevChips.some((prevTag) => compareIgnoreCase(prevTag.label, newTag.label)) &&
          !createAssetTags.some((chip) => compareIgnoreCase(chip.tagValue, newTag.label))
        ) {
          updatedChips.push(newTag);
        }
      });

      return updatedChips;
    });

    setCreateAssetTags((prevTags) => {
      const updatedChips = [...prevTags];

      result.newTags.forEach((newTag) => {
        if (
          !prevTags.some((prevTag) => compareIgnoreCase(prevTag.tagValue, newTag.tagValue)) &&
          !chips.some((chip) => compareIgnoreCase(chip.label, newTag.tagValue))
        ) {
          updatedChips.push(newTag);
        }
      });

      return updatedChips;
    });
  };

  const handleOnFreeSoloChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: (string | SelectItem)[],
    reason: AutocompleteChangeReason
  ) => {
    if (!value?.length) {
      setChips([]);
      setCreateAssetTags([]);
    }
    if (reason === "removeOption") {
      setChips([]);
      setCreateAssetTags([]);
      return createTagsCB(value);
    } else {
      return createTagsCB(value);
    }
  };

  return (
    <TagCreateContainerSC>
      <InputAndButtonWrapperSC>
        <MultiSelectFreeSolo
          options={tagOptions.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
          onChange={handleOnFreeSoloChange}
          placeholder={translations.globals.placeholders.createTag}
          disabled={loading}
          selected={selected}
        />
        <ButtonContainerSC>
          <PLButton
            type="primary"
            disabled={(!chips?.length && !createAssetTags?.length) || loading}
            onClick={handleAdd}
          >
            <PLIcon slot="icon" iconName="plus" />
          </PLButton>
        </ButtonContainerSC>
      </InputAndButtonWrapperSC>
    </TagCreateContainerSC>
  );
};

export default TagsInput;
