import useDataGrid from "components/templates/dataGridTable";
import React, { useEffect, useCallback, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import useGetUserRoleColumns from "./useGetUserRoleColumns";
import { drawerAtom, mappingRoleUsersLoading, selectedUsersOnRoleState } from "../RecoilState/mappingUserRoleState";
import { Box, IconButton, SwipeableDrawer, Typography, styled } from "@mui/material";
import MappingRoleScopesTable from "../UserRolesManager/MappingRoleScopesTable";
import CloseIcon from "icons/components/CloseIcon";
import { useStyles } from "../FormModal/utils";
import { Buttons } from "./Buttons";
import {
  GetUsersForRoleMappingQueryVariables,
  useGetUsersForRoleMappingLazyQuery,
  GetUsersForRoleMappingQuery,
  ResultType,
} from "graphqlBase/Settings/__generated__/getUsersForRoleMapping";
import { QueryLazyOptions } from "@apollo/client";
import { useGetMappingsUsersToSave } from "../UpdateRole/useGetMappingsUsersToSave";
import useBlockHistory, { UseBlockHistoryCb } from "routes/useBlockHistory";
import FormModal from "../FormModal";

export type User = ResultType<GetUsersForRoleMappingQuery["users"]>;
type QueryOptions = QueryLazyOptions<Omit<GetUsersForRoleMappingQueryVariables, "roleIds">> | undefined;

const DropdownWrapperSC = styled("div")({
  zIndex: 999,
  backgroundColor: "#fff",
  height: "100%",
  minHeight: "40vh",
  padding: "0px 45px 0px 45px",
});
const { firstName, lastName, email } = translations.entities.user.columns;
const { label } = translations.pages.userManagement;
const nameStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "27px",
  marginBottom: "37px",
  width: "100%",
  marginRight: "66px",
};
const drawrStyle = { marginLeft: "29px", marginRight: "29px" };
const UserAndRolesTable: React.FC<{}> = () => {
  const roleUsersLoading = useRecoilValue(mappingRoleUsersLoading);
  const setSelectedUsers = useSetRecoilState(selectedUsersOnRoleState);
  const resetSelectedUsers = useResetRecoilState(selectedUsersOnRoleState);
  const [openModal, setOpenModal] = useState(false);
  const { continueAfterBlock, setBlockCallBack, setPreventPageClose } = useBlockHistory();

  const variables = {
    where: { mappingRoleUsers: { all: { isDeleted: { neq: true } } } },
  };
  const { closeButton } = useStyles({});
  const [queryInner, { data, loading, error, refetch }] = useGetUsersForRoleMappingLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const { roles } = useGetUserRoleColumns(data?.users);

  const query = (options: QueryOptions = {}) => {
    queryInner({ ...options, variables: { ...options.variables, roleIdFilter: { roleId: { nin: [] } } } });
  };

  useEffect(() => {
    queryInner({
      variables,
    });
  }, [queryInner]);

  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef } = useDataGrid<User, "User">({
    query,
    variables,
    tableId: "MappingRoleUsersTable",
    persistance: "runTime",
  });
  const [DrawerState, setDrawerState] = useRecoilState(drawerAtom);

  const roleColumns = useMakeColumns(roles, []);

  const setRemoteDataToRecoilState = () => {
    if (data?.users) {
      const userRoleMappings = (data.users ?? [])
        .flatMap((user) => user.mappingRoleUsers)
        .map((userRoleMapping) => ({ ...userRoleMapping, toDelete: userRoleMapping.toDelete ?? undefined }));

      setSelectedUsers((prevUserRoleMappings) => {
        return userRoleMappings.reduce((userRoleMappingsNew, userRoleMapping) => {
          const existingMapping = userRoleMappingsNew.find((userRoleMappingNew) => {
            return (
              userRoleMappingNew.roleId === userRoleMapping.roleId &&
              userRoleMappingNew.userId === userRoleMapping.userId
            );
          });
          if (existingMapping) return userRoleMappingsNew;
          return userRoleMappingsNew.concat(userRoleMapping);
        }, prevUserRoleMappings);
      });
    }
  };
  const cancelModal = () => {
    setOpenModal(false);
  };
  const saveMappingsUsers = useGetMappingsUsersToSave();
  const doMutations = () => {
    saveMappingsUsers().finally(() => {
      if (refetch) refetch().catch(() => {});
    });
  };
  const cancelRoleToSave = () => {
    resetSelectedUsers();
    setRemoteDataToRecoilState();
  };
  const TriggerBlocker = useCallback((block: boolean) => {
    const blockerCb: UseBlockHistoryCb = () => {
      setOpenModal(true);
      return false;
    };
    setBlockCallBack(() => (block ? blockerCb : undefined));
    setPreventPageClose(block);
  }, []);

  useEffect(() => {
    resetSelectedUsers();
    setRemoteDataToRecoilState();
  }, [data?.users, setSelectedUsers]);

  const columns = useMakeColumns(
    [
      {
        field: "firstName",
        headerName: firstName,
        width: 125,
        minWidth: 150,
        maxWidth: 200,
        resizable: true,
        valueGetter: (params) => {
          return params.row.firstName ?? "";
        },
        remoteOrder: (sort) => ({ firstName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            firstName: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "lastName",
        headerName: lastName,
        minWidth: 150,
        maxWidth: 200,
        resizable: true,
        remoteOrder: (sort) => ({ lastName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            lastName: filterValue,
          }),
        }),
        valueGetter: (params) => {
          return params.row?.lastName ?? "";
        },
        type: "string",
      },
      {
        field: "email",
        headerName: email,
        minWidth: 150,
        maxWidth: 200,
        resizable: true,
        valueGetter: (params) => {
          return params.row?.email ?? "";
        },
        remoteOrder: (sort) => ({ email: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            email: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "tenant",
        headerName: translations.entities.tenant.name,
        minWidth: 150,
        maxWidth: 200,
        resizable: true,
        remoteOrder: (sort) => ({ tenant: { tenantName: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            tenant: { tenantName: filterValue },
          }),
        }),
        valueGetter: (params) => {
          return params.row.tenant?.tenantName ?? "";
        },
        type: "string",
      },
    ],
    []
  ).concat(roleColumns);
  const tableLoading = loading || !!roleUsersLoading;

  useEffect(() => {
    apiRef.current.setPinnedColumns({ left: ["firstName", "lastName", "email", "tenant"] });
  }, []);
  return (
    <>
      {!error && (
        <Box sx={{ pb: "80px" }}>
          <DropdownWrapperSC>
            <DataGrid
              noDataMessage=""
              tableTitle={label}
              tableHeight="60vh"
              disableColumnResize={false}
              columns={columns}
              initialState={{ pinnedColumns: { left: ["firstName", "lastName", "email", "tenant"] } }}
              rows={!tableLoading ? data?.users : []}
              loading={tableLoading}
              checkboxSelection={false}
              withMargin={false}
            />
            <div style={{ marginLeft: "74%", marginTop: "15px" }}>
              <Buttons
                doMutations={() => doMutations()}
                cancelAction={() => cancelRoleToSave()}
                setBlocked={TriggerBlocker}
              />
            </div>
          </DropdownWrapperSC>
          <>
            <SwipeableDrawer
              anchor="right"
              open={!!DrawerState}
              onClose={() => setDrawerState(undefined)}
              onOpen={() => {}}
            >
              <div style={{ display: "flex" }}>
                <IconButton className={closeButton} onClick={() => setDrawerState(undefined)} size="small">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" style={nameStyle}>
                  {DrawerState?.name}
                </Typography>
              </div>
              <div>
                <Typography style={drawrStyle}>{DrawerState?.description}</Typography>
                <div style={drawrStyle}>
                  <MappingRoleScopesTable roleId={DrawerState?.id ?? ""} disabled />
                </div>
              </div>
            </SwipeableDrawer>
            <FormModal
              handleConfirm={() => continueAfterBlock()}
              handleCancel={cancelModal}
              isOpen={openModal}
              titles={translations.pages.userManagement.userRoleManager.discardChanges}
              message={<>{translations.pages.userManagement.userRoleManager.DiscardMessage}</>}
              confirmLabel={translations.globals.button.yes}
              cancelLabel={translations.globals.button.cancel}
            />
          </>
        </Box>
      )}
    </>
  );
};

export default UserAndRolesTable;
