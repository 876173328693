import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesForDeviceManagementQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.DeviceSortInput> | Types.DeviceSortInput>;
  mappingDeviceFirmwareDeviceFilterInput?: Types.InputMaybe<Types.MappingDeviceFirmwareDeviceFilterInput>;
  mappingDeviceConfigurationDeviceFilterInput?: Types.InputMaybe<Types.MappingDeviceConfigurationDeviceFilterInput>;
}>;


export type GetDevicesForDeviceManagementQuery = { devices: Array<{ __typename?: 'Device', id: string, deviceName: string, serialNo: string, description?: Maybe<string>, parentDeviceId?: Maybe<string>, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string }, lastMeasurement?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, utcTimeMeasured: string }>>>, sensorMeasurementCurrentValue?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, valueString?: Maybe<string>, valueInt?: Maybe<number>, capability?: Maybe<{ __typename?: 'Capability', id: string, capabilityName: string, deviceModelCapability: Array<{ __typename?: 'DeviceModelCapability', id: string, unit: { __typename?: 'Unit', id: string, unitSymbol: string } }> }> }>>>, gatewayDeviceConnection?: Maybe<{ __typename?: 'GatewayDeviceConnection', id: string, deviceId: string }>, assetDevice?: Maybe<Array<Maybe<{ __typename?: 'AssetDevice', id: string, dateTo: string, asset: { __typename?: 'Asset', id: string, customUniqueIdentifier: string, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string> }> } }>>>, mappingDeviceTag: Array<{ __typename?: 'MappingDeviceTag', id: string, deviceId: string, tagId: string, isDeleted?: Maybe<boolean>, tag: { __typename?: 'Tag', id: string, tagValue: string } }>, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, dateFrom: string, dateTo: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareVersion: string, deviceFirmwareConfigurationSchema: { __typename?: 'DeviceFirmwareConfigurationSchema', id: string, deviceFirmwareConfigurationJSON: string } } }>, mappingDeviceConfigurationDevice: Array<{ __typename?: 'MappingDeviceConfigurationDevice', id: string, dateFrom: string, dateTo: string, deviceConfiguration: { __typename?: 'DeviceConfiguration', id: string, configurationJSON: string } }> }> };


export const GetDevicesForDeviceManagementDocument = gql`
    query getDevicesForDeviceManagement($where: DeviceFilterInput, $take: Int, $skip: Int, $order: [DeviceSortInput!], $mappingDeviceFirmwareDeviceFilterInput: MappingDeviceFirmwareDeviceFilterInput, $mappingDeviceConfigurationDeviceFilterInput: MappingDeviceConfigurationDeviceFilterInput) {
  devices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    deviceName
    serialNo
    description
    parentDeviceId
    deviceType {
      id
      deviceTypeName
    }
    lastMeasurement: sensorMeasurementCurrentValue(order: {utcTimeMeasured: DESC}) {
      id
      utcTimeMeasured
    }
    sensorMeasurementCurrentValue(where: {capabilityId: {eq: "80bec84b-2c50-4e12-9a70-56b06e5b92e5"}}) {
      id
      valueString
      valueInt
      capability {
        id
        capabilityName
        deviceModelCapability {
          id
          unit {
            id
            unitSymbol
          }
        }
      }
    }
    gatewayDeviceConnection {
      id
      deviceId
    }
    assetDevice {
      id
      dateTo
      asset {
        id
        customUniqueIdentifier
        assetMasterData {
          id
          generalItem
        }
      }
    }
    mappingDeviceTag(where: {isDeleted: {neq: true}}) {
      id
      deviceId
      tagId
      isDeleted
      tag {
        id
        tagValue
      }
    }
    mappingDeviceFirmwareDevice(where: $mappingDeviceFirmwareDeviceFilterInput) {
      id
      dateFrom
      dateTo
      deviceFirmware {
        id
        firmwareVersion
        deviceFirmwareConfigurationSchema {
          id
          deviceFirmwareConfigurationJSON
        }
      }
    }
    mappingDeviceConfigurationDevice(where: $mappingDeviceConfigurationDeviceFilterInput) {
      id
      dateFrom
      dateTo
      deviceConfiguration {
        id
        configurationJSON
      }
    }
  }
}
    `;

/**
 * __useGetDevicesForDeviceManagementQuery__
 *
 * To run a query within a React component, call `useGetDevicesForDeviceManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesForDeviceManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesForDeviceManagementQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      mappingDeviceFirmwareDeviceFilterInput: // value for 'mappingDeviceFirmwareDeviceFilterInput'
 *      mappingDeviceConfigurationDeviceFilterInput: // value for 'mappingDeviceConfigurationDeviceFilterInput'
 *   },
 * });
 */
export function useGetDevicesForDeviceManagementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesForDeviceManagementQuery, GetDevicesForDeviceManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesForDeviceManagementQuery, GetDevicesForDeviceManagementQueryVariables>(GetDevicesForDeviceManagementDocument, options);
      }
export function useGetDevicesForDeviceManagementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesForDeviceManagementQuery, GetDevicesForDeviceManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesForDeviceManagementQuery, GetDevicesForDeviceManagementQueryVariables>(GetDevicesForDeviceManagementDocument, options);
        }
export type GetDevicesForDeviceManagementQueryHookResult = ReturnType<typeof useGetDevicesForDeviceManagementQuery>;
export type GetDevicesForDeviceManagementLazyQueryHookResult = ReturnType<typeof useGetDevicesForDeviceManagementLazyQuery>;
export type GetDevicesForDeviceManagementQueryResult = Apollo.QueryResult<GetDevicesForDeviceManagementQuery, GetDevicesForDeviceManagementQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
