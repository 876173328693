export default {
  name: "asset device",
  plural: "asset devices",
  columns: {
    asset: "asset",
    assetId: "asset ID",
    createdAt: "created At",
    device: "device",
    deviceId: "device ID",
    id: "ID",
    isDeleted: "is deleted",
    lastChange: "last change",
    tenantId: "tenant ID",
  },
};
