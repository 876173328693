export default {
  name: "Role",
  plural: "Roles",
  columns: {
    id: "ID",
    name: "Name",
    description: "Description",
    isDefaultRole: "is default role",
    createdAt: "Created at",
    modifiedAt: "Modified at",
    isDeleted: "is deleted",
  },
};
