import { LhChange, Status } from "@liebherr/patternlib/dist/types/utils/interfaces";
import PLTextinput from "components/patternLib/form/PLTextinput";
import * as React from "react";
import { useEffect, useState } from "react";
import Props from "./index.d";
import {
  CheckAssetCustomUniqueIdentifierDocument,
  CheckAssetCustomUniqueIdentifierQuery,
  CheckAssetCustomUniqueIdentifierQueryVariables,
} from "graphqlBase/Assets/__generated__/checkAssetCustomUniqueIdentifier";
import { useRecoilState, useSetRecoilState } from "recoil";
import { assetCreateFormErrorsAtom } from "components/pages/AssetManagement/AssetCreate/assetCreateFormState";
import { useApolloClient } from "@apollo/client";

const QRregex = /^[A-F0-9]{10}$/;

export type TextFieldComponentProps = Props;

type ValidationMessage = {
  status: Status["status"];
  errorMessage?: string;
  warningMessage?: string;
};

const TextFieldComponent: React.FC<Props> = (props) => {
  const apollo = useApolloClient();
  const [validation, setValidation] = useState<ValidationMessage>({ status: "default", errorMessage: undefined });
  const setAssetCreateFormErrors = useSetRecoilState(assetCreateFormErrorsAtom);

  const {
    title,
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    placeholder = "placeholder",
    disabled,
    testId,
    errors,
    isPartOfSummary,
    isRequiredField,
    width,
    ...rest
  } = props;
  const handleChange = (event: CustomEvent<LhChange>) => {
    updateValue(event);
    const { value } = event.detail;
    const input = ((value as string) ?? "").trim();
    if (!QRregex.test(input)) {
      setValidation({
        status: "error",
        errorMessage: "QR code must be 10 characters long and contain only letters and numbers.",
      });
      return;
    }
    setValidation({
      status: "warning",
      errorMessage: undefined,
      warningMessage: "Checking if QR code already exists.",
    });
    apollo
      .query<CheckAssetCustomUniqueIdentifierQuery, CheckAssetCustomUniqueIdentifierQueryVariables>({
        query: CheckAssetCustomUniqueIdentifierDocument,
        variables: { customUniqueIdentifier: input },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setTimeout(() => {
          if (res.data?.assets.length) {
            setValidation({
              status: "error",
              errorMessage: "QR code already exists.",
              warningMessage: undefined,
            });
            return;
          }
          setValidation({
            status: "success",
            errorMessage: undefined,
            warningMessage: undefined,
          });
        }, 1000);
      });
  };

  useEffect(() => {
    setAssetCreateFormErrors(validation.status === "error");
  }, [validation.status, setAssetCreateFormErrors]);

  return (
    <>
      {
        <>
          <PLTextinput
            showRemoveIcon={false}
            status={validation.status}
            lhBlur={onBlur}
            value={state}
            lhChange={handleChange}
            readonly={disabled}
            label={title}
            width={width}
            errorText={validation.errorMessage}
            warningText={validation.warningMessage}
          />
        </>
      }
    </>
  );
};

export default TextFieldComponent;
