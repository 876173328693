import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { applyPolyfills, defineCustomElements } from "@liebherr/patternlib/loader";
import "@liebherr/patternlib/dist/patternlib/patternlib.css";
import "@liebherr/patternlib/www/assets/css/patternlib-fonts.css";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));
applyPolyfills()
  .then(() => {
    defineCustomElements(window).catch(console.error);
  })
  .catch(console.error);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
