import { atom, selectorFamily, DefaultValue, GetRecoilValue, SetRecoilState } from "recoil";
import { MappingRoleScopes } from "../UserRolesManager/MappingRoleScopesTable";

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
interface Scope {
  id: string;
  scopeName: string;
  scopeDescription?: string;
}

interface ScopeArea {
  scopeId: string;
  scopeName: string;
  scopeAreaName: string;
  scopeAreaPermissions: string[];
}
export interface MappingRoleScopesSelectorGroupState {
  checked: boolean;
  indeterminate: boolean;
}

type MappingRoleScopesExtended = PartialBy<MappingRoleScopes, "id" | "roleId">;

export interface MappingRoleScopesExtendedRegistry {
  [key: string]: MappingRoleScopesExtended;
}

const getMappingState = ({ get, scopeIds }: { get: GetRecoilValue; scopeIds: string[] }) => {
  const mapping = get(mappingRoleScopesState);
  return scopeIds.map((scopeId) => {
    const state = mapping[scopeId];
    if (!state) return false;
    return !state.isDeleted ?? false;
  });
};
const setMappingState = ({
  set,
  scopeIds,
  newValue,
}: {
  set: SetRecoilState;
  scopeIds: string[];
  newValue: boolean | DefaultValue;
}) => {
  set(mappingRoleScopesState, (prevState) => {
    return scopeIds.reduce<MappingRoleScopesExtendedRegistry>((nextMappingState, scopeId) => {
      const isDeleted = newValue instanceof DefaultValue ? true : !newValue;
      return {
        ...nextMappingState,
        [scopeId]: {
          ...nextMappingState[scopeId],
          scopeId,
          isDeleted,
        },
      };
    }, prevState);
  });
};

export const mappingRoleScopesState = atom<MappingRoleScopesExtendedRegistry>({
  key: "mappingRoleScopesState",
  default: {},
});

export const mappingRoleScopesSelector = selectorFamily<boolean, string>({
  key: "mappingRoleScopesStateSelector",

  get: (scopeId) => ({ get }) => getMappingState({ get, scopeIds: [scopeId] })[0],
  set: (scopeId) => ({ set }, newValue) => setMappingState({ set, newValue, scopeIds: [scopeId] }),
});

export const mappingRoleScopesSelectorGroup = selectorFamily<MappingRoleScopesSelectorGroupState, string[]>({
  key: "mappingRoleScopesStateSelectorGroup",

  get: (scopeIds) => ({ get }) => {
    const checkedCount = getMappingState({ get, scopeIds }).filter(Boolean).length;
    const statesCount = scopeIds.length;
    const indeterminate = checkedCount > 0 && checkedCount < statesCount;
    const checked = checkedCount === statesCount;

    return { checked, indeterminate };
  },
  set: (scopeIds) => ({ set }, newValue) => {
    const isDeleted = newValue instanceof DefaultValue ? false : newValue.checked;
    setMappingState({ set, newValue: isDeleted, scopeIds });
  },
});
