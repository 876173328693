import { useApolloClient } from "@apollo/client/react";
import { Box } from "@mui/material";
import { useGetGroupEntitiesForQueryBuilderQuery } from "graphqlBase/GroupEntities/__generated__/getGroupEntitiesForQueryBuilder";
import React, { useEffect, useMemo, useState } from "react";
import { Field } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import config from "../config";
import "../middleware/rawQuerytoGqlQuery";
import QueryComponent from "./QueryComponent";
import { Fields, QueryBuilderComponentProps } from "../types";
import { firstToLowerCase, getDatatype, getListValuesToFill, getToInclude } from "../utils";
import { queryBuilderStyles } from "./styles";

const QueryBuilderComponent: React.FC<QueryBuilderComponentProps> = ({
  entityName,
  queryBuilderId,
  fieldMiddleWares,
}) => {
  const { data, loading } = useGetGroupEntitiesForQueryBuilderQuery({
    variables: { entityName },
    fetchPolicy: "network-only",
  });

  const [fields, setFields] = useState<Fields>({});
  const client = useApolloClient();
  const queryConfig = useMemo(() => config(), []);
  const fieldsPre: Fields = useMemo(() => {
    if (!data?.groupEntities) return {};

    return (data.groupEntities[0].mappingGroupEntityGroupEntityFilter ?? []).reduce<Fields>(
      (
        fields,
        {
          groupEntityFilter: {
            entityColumnName,
            entityName,
            displayName,
            mappingGroupEntityFilterGroupOperator,
            groupEntityFilterUiType,
            groupEntityFilterDataType,
          },
        }
      ) => {
        const allowedOperators = mappingGroupEntityFilterGroupOperator.map(
          (mappingGroupEntityFilterGroupOperator) => mappingGroupEntityFilterGroupOperator.groupOperator.operator
        );

        const key = `${firstToLowerCase(entityName)}.${firstToLowerCase(entityColumnName)}`;
        const uitype = groupEntityFilterUiType.groupEntityFilterUiTypeName.toLowerCase();
        const isSelect = uitype.includes("select");
        const field: Field = {
          tableName: entityName,

          label: displayName,
          type: isSelect ? "select" : getDatatype(groupEntityFilterDataType),
          operators: getToInclude(allowedOperators),
          preferWidgets: [uitype],
          allowCustomValues: true,
          valueSources: ["value"],
        };

        return { ...fields, [key]: field };
      },
      {}
    );
  }, [data]);
  useEffect(() => {
    getListValuesToFill({ setFields, fieldsPre, client, fieldMiddleWares }).catch(console.log);
  }, [fieldsPre, setFields, client, fieldMiddleWares]);

  return (
    <Box className="query-main-container" sx={queryBuilderStyles}>
      <QueryComponent config={{ ...queryConfig, fields }} queryBuilderId={queryBuilderId} loading={loading} />
    </Box>
  );
};

export default QueryBuilderComponent;
