import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLStepsItemProps = DropOnONLh<JSX.PatternlibStepsItem>;
export type PLStepsItemExtendedProps = PLStepsItemProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedStepsItem = wrapWc<JSX.PatternlibStepsItem>("patternlib-steps-item");
export const useRefPLStepsItem = () => useRef<HTMLPatternlibStepsItemElement>(null);

const PLStepsItem = React.forwardRef<HTMLPatternlibStepsItemElement, PLStepsItemExtendedProps>((props, ref) => {
  const myRef = useRefPLStepsItem();
  return (
    <WrappedStepsItem ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedStepsItem>
  );
});
PLStepsItem.displayName = "PLStepsItem";

export default PLStepsItem;
