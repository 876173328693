import { SelectedDevice } from "components/formElements/fields/MappingDeviceNotifications/MappingDeviceNotificationsTable";
import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  MappingDeviceNotification,
  MappingUserNotification,
  NotificationDefinition,
  SelectedAsset,
  SelectedUser,
  preMappedUserNotificationsAtom,
  selectedUsersForAlertAtom,
} from "../recoilState";
import {
  notificationDefinitionsAtom,
  prevNotificationDefinitionsAtom,
} from "../recoilState/notificationDefinitionsState";
import {
  preMappedDeviceNotificationsAtom,
  selectedAssetsForAlertAtom,
} from "../recoilState/selectedAssetsForAlertState";
import {
  preMappedDeviceNotificationsForGateWayAtom,
  selectedDevicesForAlertAtom,
} from "../recoilState/selectedDevicesForAlertState";

export const useGetNotificationsCallback = () => {
  const preMappedDeviceNotifications = useRecoilValue(preMappedDeviceNotificationsAtom);
  const preMappedDeviceNotificationsForGateway = useRecoilValue(preMappedDeviceNotificationsForGateWayAtom);
  const preMappedUserNotifications = useRecoilValue(preMappedUserNotificationsAtom);
  const prevNotificationDefinitions = useRecoilValue(prevNotificationDefinitionsAtom);

  const getMappingDeviceNotifications = (
    selectedAssetsForAlert?: SelectedAsset[],
    selectedDevicesForAlert?: SelectedDevice[]
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (!selectedAssetsForAlert && !selectedDevicesForAlert) return [];

    let newDeviceMappingsFromAssets = selectedAssetsForAlert ?? [];

    const mappingDeviceNotificationsListFromAssets = (preMappedDeviceNotifications ?? []).reduce<
      MappingDeviceNotification[]
    >((acc, current) => {
      const selectedAsset = selectedAssetsForAlert?.find(
        (asset) => asset?.assetDevice?.[0].deviceId === current.deviceId
      );

      newDeviceMappingsFromAssets = newDeviceMappingsFromAssets.filter(
        (mapping) => mapping?.assetDevice?.[0]?.deviceId !== current.deviceId
      );

      // is previously mapped but not selected in current mapping
      if (!selectedAsset || selectedAsset?.toDelete) return acc.concat({ ...current, toDelete: true });
      // if previously mapped and selected in current mapping
      else return acc.concat({ ...current, toDelete: false });
    }, []);
    const newMappingDeviceNotificationsFromAssets = newDeviceMappingsFromAssets.reduce<MappingDeviceNotification[]>(
      (acc, current) => {
        return acc.concat({ deviceId: current?.assetDevice?.[0].deviceId });
      },
      []
    );

    const listFromAssets = mappingDeviceNotificationsListFromAssets.concat(newMappingDeviceNotificationsFromAssets);

    let newDeviceMappingsFromDevices = selectedDevicesForAlert ?? [];

    const mappingDeviceNotificationsListFromDevices = (preMappedDeviceNotificationsForGateway ?? []).reduce<
      MappingDeviceNotification[]
    >((acc, current) => {
      const selectedAsset = selectedDevicesForAlert?.find((device) => device.id === current.deviceId);

      newDeviceMappingsFromDevices = newDeviceMappingsFromDevices.filter((mapping) => mapping?.id !== current.deviceId);

      // is previously mapped but not selected in current mapping
      if (!selectedAsset || selectedAsset?.toDelete) return acc.concat({ ...current, toDelete: true });
      // if previously mapped and selected in current mapping
      else return acc.concat({ ...current, toDelete: false });
    }, []);

    const newMappingDeviceNotificationsFromDevices = newDeviceMappingsFromDevices.reduce<MappingDeviceNotification[]>(
      (acc, current) => {
        return acc.concat({ deviceId: current?.id });
      },
      []
    );

    const listFromDevices = mappingDeviceNotificationsListFromDevices.concat(newMappingDeviceNotificationsFromDevices);
    return listFromAssets.concat(listFromDevices);
  };

  const getMappingUserNotifications = (selectedUsersForAlert: SelectedUser[] | undefined) => {
    let newUserMappings = selectedUsersForAlert ?? [];
    const mappingUserNotificationsList = (preMappedUserNotifications ?? []).reduce<MappingUserNotification[]>(
      (acc, current) => {
        const selectedUser = selectedUsersForAlert?.find((user) => user.value === current.userId);

        newUserMappings = newUserMappings.filter((mapping) => mapping.value !== selectedUser?.value);

        // is previously mapped but not selected in current mapping
        if (!selectedUser) {
          return acc.concat({ ...current, toDelete: true });
        }
        // if previously mapped and selected in current mapping
        else return acc.concat({ ...current, toDelete: false });
      },
      []
    );

    const newMappingUserNotifications = newUserMappings.reduce<MappingUserNotification[]>((acc, current) => {
      return acc.concat({ userId: current.value });
    }, []);
    return mappingUserNotificationsList.concat(newMappingUserNotifications);
  };

  const getNotificationDefinitions = (currentNotificationDefinitions: NotificationDefinition[] | undefined) => {
    let newDefinitions = [...(currentNotificationDefinitions ?? [])];

    const notificationDefinitionsList = (prevNotificationDefinitions ?? []).reduce<NotificationDefinition[]>(
      (acc, current) => {
        const existingDefinition = currentNotificationDefinitions?.find((definition) => definition?.id === current?.id);
        if (existingDefinition) newDefinitions = newDefinitions.filter((def) => def?.id !== existingDefinition?.id);
        // is previously added but not selected in current mapping
        if (!existingDefinition) return acc.concat({ ...current, toDelete: true });

        // if previously added and selected in current mapping
        const toUpdate =
          existingDefinition?.notificationOperatorId !== current?.notificationOperatorId ||
          existingDefinition?.storageArea?.id !== current?.storageArea?.id ||
          existingDefinition?.value !== current?.value;

        return acc.concat({ ...existingDefinition, toDelete: false, toUpdate });
      },
      []
    );

    return notificationDefinitionsList.concat(newDefinitions);
  };

  return useRecoilCallback(({ snapshot }) => async () => {
    const [
      notificationDefinitionsList,
      selectedDevicesForAlert,
      selectedAssetsForAlert,
      selectedUsersForAlert,
    ] = await Promise.all([
      snapshot.getPromise(notificationDefinitionsAtom),
      snapshot.getPromise(selectedDevicesForAlertAtom),
      snapshot.getPromise(selectedAssetsForAlertAtom),
      snapshot.getPromise(selectedUsersForAlertAtom),
    ]);

    return {
      notificationDefinitionsList: getNotificationDefinitions(notificationDefinitionsList),
      mappingDeviceNotificationsList: getMappingDeviceNotifications(selectedAssetsForAlert, selectedDevicesForAlert),
      mappingUserNotificationsList: getMappingUserNotifications(selectedUsersForAlert),
    };
  });
};
