import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"
export const genericDeleteFunction = genericDeleteFunctionPre

export type StorageAreaUpdateOnUpdateDataMiddleWare = OnUpdateDataMiddleWare<SchemaTypes.StorageAreaInputUpdate>
export type StorageAreaUpdateOnUpdateDataMiddleWareProps = OnUpdateDataMiddleWareProps<SchemaTypes.StorageAreaInputUpdate>
export type StorageAreaUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.StorageAreaInputUpdate,
  SchemaTypes.StorageAreaInputUpdateProps["injectedValues"]
>
export type StorageAreaUpdateBeforeRemoteMuatationProps = BeforeRemoteMuatationProps<
  SchemaTypes.StorageAreaInputUpdate,
  SchemaTypes.StorageAreaInputUpdateProps["injectedValues"]
>
export type StorageAreaUpdateValidateForm = ValidateForm<
  SchemaTypes.StorageAreaInputUpdate,
  SchemaTypes.StorageAreaInputUpdateProps["injectedValues"]
>
export type StorageAreaUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.StorageAreaInputUpdate,
  SchemaTypes.StorageAreaInputUpdateProps["injectedValues"]
>

export interface StorageAreaUpdateProps
  extends SchemaTypes.StorageAreaInputUpdateProps {
  id: string
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.StorageAreaInputUpdate>
  afterUpdate?: (data: SchemaTypes.StorageAreaInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.StorageAreaInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.StorageAreaInputUpdate,
    SchemaTypes.StorageAreaInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.StorageAreaInputUpdate,
    SchemaTypes.StorageAreaInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const StorageAreaUpdateForm: React.FC<StorageAreaUpdateProps> = (
  props
) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: StorageAreaUpdate } = makeNewFormMemoized({
      entity: "storageArea",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <StorageAreaUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type StorageAreaCreateOnUpdateDataMiddleWare = OnUpdateDataMiddleWare<SchemaTypes.StorageAreaInputCreate>
export type StorageAreaCreateOnUpdateDataMiddleWareProps = OnUpdateDataMiddleWareProps<SchemaTypes.StorageAreaInputCreate>
export type StorageAreaCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.StorageAreaInputCreate,
  SchemaTypes.StorageAreaInputCreateProps["injectedValues"]
>
export type StorageAreaCreateBeforeRemoteMuatationProps = BeforeRemoteMuatationProps<
  SchemaTypes.StorageAreaInputCreate,
  SchemaTypes.StorageAreaInputCreateProps["injectedValues"]
>
export type StorageAreaCreateValidateForm = ValidateForm<
  SchemaTypes.StorageAreaInputCreate,
  SchemaTypes.StorageAreaInputCreateProps["injectedValues"]
>
export type StorageAreaCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.StorageAreaInputCreate,
  SchemaTypes.StorageAreaInputCreateProps["injectedValues"]
>

export interface StorageAreaCreateProps
  extends SchemaTypes.StorageAreaInputCreateProps {
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.StorageAreaInputCreate>
  afterCreate?: (
    data: SchemaTypes.StorageAreaInputCreate & { id: string }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.StorageAreaInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.StorageAreaInputCreate,
    SchemaTypes.StorageAreaInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.StorageAreaInputCreate,
    SchemaTypes.StorageAreaInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const StorageAreaCreateForm: React.FC<StorageAreaCreateProps> = (
  props
) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: StorageAreaCreate } = makeNewFormMemoized({
      entity: "storageArea",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <StorageAreaCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
