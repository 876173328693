import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLSidebarItemProps = DropOnONLh<JSX.PatternlibSidebarItem>;
export type PLSidebarItemExtendedProps = PLSidebarItemProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedSidebarItem = wrapWc<JSX.PatternlibSidebarItem>("patternlib-sidebar-item");
export const useRefPLSidebarItem = () => useRef<HTMLPatternlibSidebarItemElement>(null);

const PLSidebarItem = React.forwardRef<HTMLPatternlibSidebarItemElement, PLSidebarItemExtendedProps>((props, ref) => {
  const myRef = useRefPLSidebarItem();
  return (
    <WrappedSidebarItem ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedSidebarItem>
  );
});
PLSidebarItem.displayName = "PLSidebarItem";

export default PLSidebarItem;
