import React from "react";
import { Theme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MuiTable from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TablePaginationActions from "../TablePaginationActions";
import { mergedThemes } from "../../theme";
import { CreateTableComponent, TableComponentProps } from "../tableTypes";
import TableHeaderComponent from "../CreateTableComponents/TableHeadComponent";
import TableBodyRowComponent from "../CreateTableComponents/TableBodyRowComponent";
import TableSubcomponent from "../CreateTableComponents/TableSubcomponent";
import PaginationComponent from "../CreateTableComponents/TablePagination";

import getTestIDs from "lib/utils/getTestIDs";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const testIDs = getTestIDs();

const createTableComponent: CreateTableComponent<any> = ({ tableInstance, config }) => {
  const tableId = config.tableId;
  const useStyles = makeStyles<Theme, TableComponentProps>(
    (theme) =>
      createStyles({
        root: {},
        container: () =>
          config.stickyHeader
            ? {
                maxHeight: "70vh",
              }
            : {},
      }),

    { name: `Table-${tableId}` }
  );

  const TableComponent: React.FC<TableComponentProps<object>> = (props) => {
    const classes = useStyles(props);
    const { getTableProps, getTableBodyProps, rows, page, prepareRow } = tableInstance;
    const bodyRows = config.pagination === true ? page : rows;
    const EmptyFiller = props?.emptyTableComponent;

    const Header = config.customTableComponents?.Header ? config.customTableComponents.Header : TableHeaderComponent;
    const BodyRow = config.customTableComponents?.BodyRow
      ? config.customTableComponents.BodyRow
      : TableBodyRowComponent;
    const Subcomponent = config.customTableComponents?.Subcomponent
      ? config.customTableComponents.Subcomponent
      : TableSubcomponent;
    const Pagination = config.customTableComponents?.Pagination
      ? config.customTableComponents?.Pagination
      : PaginationComponent;
    const theme = config.customTableComponents?.Theme ? config.customTableComponents.Theme : mergedThemes;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className={classes.root} {...props.rootProps}>
            <TableContainer
              {...props.tableProps?.container}
              className={classes.container}
              data-testid={testIDs[`UseMakeTable_${tableId}`]}
            >
              <MuiTable {...getTableProps()} {...props.tableProps?.table} stickyHeader={config.stickyHeader}>
                <Header tableId={tableId} config={config} tableInstance={tableInstance} tableProps={props.tableProps} />
                <TableBody {...getTableBodyProps()} {...props.tableProps?.body}>
                  {bodyRows.length > 0 &&
                    bodyRows.map((row, rowIndex) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={`table-${tableId}-br-${row.id}`}>
                          <BodyRow
                            tableId={tableId}
                            row={row}
                            rowIndex={rowIndex}
                            config={config}
                            tableInstance={tableInstance}
                            tableProps={props.tableProps}
                          />
                          {config.subComponent === true &&
                            row.isExpanded &&
                            props.renderRowSubComponent !== undefined && (
                              <Subcomponent
                                tableId={tableId}
                                row={row}
                                config={config}
                                tableInstance={tableInstance}
                                tableProps={props.tableProps}
                                renderRowSubComponent={props.renderRowSubComponent}
                              />
                            )}
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </MuiTable>
              {bodyRows.length === 0 && !props?.loading && EmptyFiller}
            </TableContainer>
            <Pagination
              TablePaginationActions={TablePaginationActions}
              tableProps={props.tableProps}
              config={config}
              tableInstance={tableInstance}
            />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  };
  return TableComponent;
};

export default createTableComponent;
