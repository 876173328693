/* eslint-disable react/jsx-max-depth */
import { OptionItem } from "@liebherr/patternlib/dist/types/components/form/patternlib-dropdown/patternlib-dropdown";
import { Box, Button, CircularProgress, MenuItem, Select, SelectChangeEvent, Typography, styled } from "@mui/material";
import {
  useGetRoleNameAndDescriptionQuery,
  GetRoleNameAndDescriptionDocument,
} from "graphqlBase/Role/__generated__/getRoleNameAndDescription";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
import FormControl from "@mui/material/FormControl";
import MappingRoleUsersTable from "./MappingRoleUsersTable";
import MappingRoleScopesTable from "./MappingRoleScopesTable";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useDeleteRolesMutation } from "graphqlBase/Settings/__generated__/deleteRoles";
import { useStyles } from "./utils";
import { useGetUsersLazyQuery } from "graphqlBase/Settings/__generated__/getUsers";
import FormModal from "../FormModal";
import { useGetRoleByIdLazyQuery, useGetRoleByIdQuery } from "graphqlBase/Settings/__generated__/getRoleById";

const DropdownWrapperSC = styled("div")({
  zIndex: 999,
  backgroundColor: "#fff",
  height: "100%",
  minHeight: "40vh",
  padding: "51px 45px 100px 45px",
});

const { new: newTerm } = translations.globals.terms;
const { name: role } = translations.entities.role;

const UserRolesManager: React.FC<{}> = () => {
  const history = useHistory();
  const { buttons, backButton, roleManager, select, newRoleButton, selectRoot, title } = useStyles({});
  const data = useGetUsersLazyQuery({ fetchPolicy: "cache-and-network" });

  const [selectedRole, setSelectedRole] = useState<string>();
  const [roles, setRoles] = useState<OptionItem[] | undefined>();
  const [roleDescription, setRoleDescription] = useState<string | undefined>();
  const [roleToDelete, setRoleToDelete] = useState<boolean>(false);
  const { data: rolesData, loading: loadingRoles } = useGetRoleNameAndDescriptionQuery({
    fetchPolicy: "cache-and-network",
  });
  const [deleteRoles] = useDeleteRolesMutation({
    refetchQueries: [{ query: GetRoleNameAndDescriptionDocument }],
  });
  const [query, { data: dataUsers }] = useGetRoleByIdLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const usersLength = dataUsers?.role?.mappingRoleUsers.length;
  useEffect(() => {
    if (!selectedRole) return;
    query({ variables: { id: selectedRole } });
  }, [query, selectedRole]);
  useEffect(() => {
    if (!rolesData) return;
    const roleOptions = rolesData?.roles?.reduce<OptionItem[]>((roleOptions, current) => {
      return roleOptions.concat({ label: current?.name ?? "", value: current.id });
    }, []);
    setRoles(roleOptions);
  }, [rolesData, data]);
  const cancelDeleteRole = () => {
    setRoleToDelete(false);
  };
  const handleDeleteRole = async () => {
    await deleteRoles({ variables: { ids: selectedRole } });
    setSelectedRole(undefined);
    setRoleToDelete(false);
  };
  const handleSelectRoleChange = (event: SelectChangeEvent) => {
    const selected = event.target.value;
    setSelectedRole(selected);
    const roleDescription = rolesData?.roles?.find((role) => role.id === selected)?.description;
    setRoleDescription(roleDescription ?? "");
  };
  const handleEditButton = (id: string) => {
    history.push(`/settings/userManagement/userRolesManager/updateRole/${id}`);
  };
  const RoleTables = useMemo(() => {
    return (
      <div style={{ paddingTop: "30px" }}>
        <MappingRoleUsersTable roleId={selectedRole ?? ""} />
        <MappingRoleScopesTable roleId={selectedRole ?? ""} disabled />
      </div>
    );
  }, [selectedRole]);
  const message = (
    <>
      <div style={{ paddingRight: "25px" }}>
        <div>{translations.pages.userManagement.form.message.continueDeleteMessage}</div>
        <div style={{ display: "flex" }}>
          {translations.pages.userManagement.form.message.userRoleDeleteMessagePt1}&nbsp;
          <div style={{ fontWeight: "bold", display: "contents" }}>{`${usersLength} user(s).`}&nbsp;</div>
          {translations.pages.userManagement.form.message.userRoleDeleteMessagePt2}
        </div>
      </div>
    </>
  );
  return (
    <>
      <Box sx={{ pb: "80px", pt: "17px" }}>
        {(loadingRoles && <CircularProgress />) || (
          <DropdownWrapperSC>
            <div className={roleManager}>
              <div className={title}>
                <div style={{ display: "flex" }}>
                  <div>
                    <Button
                      className={backButton}
                      color="inherit"
                      disableTouchRipple
                      onClick={() => history.push(`/settings/userManagement`)}
                    >
                      <TrendingFlatIcon />
                    </Button>
                  </div>
                  <Typography className={title} variant="h2">
                    {translations.pages.userManagement.userRoleManager.label}
                  </Typography>
                </div>
                <div className={newRoleButton}>
                  <Button
                    color="primary"
                    onClick={() => history.push("/settings/userManagement/userRolesManager/newRole")}
                    key="user-roles-manager-button"
                    startIcon={<AddIcon />}
                  >
                    {`${newTerm} ${role}`}
                  </Button>
                </div>
              </div>
            </div>
            <FormControl fullWidth>
              <div style={{ display: "flex", marginRight: "7px", justifyContent: "space-between" }}>
                <Typography variant="h6" style={{ marginBottom: "15px" }}>
                  {translations.pages.userManagement.userRoleManager.selectRole}
                </Typography>
                {selectedRole ? (
                  <div className={buttons}>
                    <Button
                      onClick={() => handleEditButton(selectedRole)}
                      key="user-roles-manager-button"
                      color="inherit"
                      startIcon={<ModeEditIcon />}
                      style={{ marginRight: "35px" }}
                      disableTouchRipple
                    >
                      {translations.globals.button.edit}
                    </Button>
                    {dataUsers?.role?.isDefaultRole ? (
                      <Button
                        variant="text"
                        color="inherit"
                        disableTouchRipple
                        disabled
                        onClick={() => setRoleToDelete(true)}
                        startIcon={<DeleteIcon />}
                      >
                        {translations.globals.terms.delete}
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        color="inherit"
                        disableTouchRipple
                        onClick={() => setRoleToDelete(true)}
                        startIcon={<DeleteIcon />}
                      >
                        {translations.globals.terms.delete}
                      </Button>
                    )}
                  </div>
                ) : null}
              </div>
              <div className={selectRoot}>
                <Select className={select} value={selectedRole} onChange={handleSelectRoleChange}>
                  {roles?.map((e) => (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem value={e.value}>{e.label}</MenuItem>
                  ))}
                </Select>
              </div>
            </FormControl>
            {selectedRole ? (
              <Box>
                <Typography variant="h6" style={{ paddingBottom: "15px" }}>
                  {`${translations.entities.role.name} ${translations.entities.role.columns.description}`}
                </Typography>
                <Typography variant="copy" style={{ marginTop: "30px" }}>
                  {roleDescription}
                </Typography>
                {RoleTables}
              </Box>
            ) : null}
          </DropdownWrapperSC>
        )}
      </Box>
      <FormModal
        handleConfirm={() => handleDeleteRole()}
        handleCancel={cancelDeleteRole}
        isOpen={roleToDelete}
        titles={translations.pages.userManagement.form.label.deleteUserRole}
        message={message}
        confirmLabel={translations.globals.terms.yes}
        cancelLabel={translations.globals.terms.no}
      />
    </>
  );
};

export default UserRolesManager;
