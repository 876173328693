import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLAvatarProps = DropOnONLh<JSX.PatternlibAvatar>;
export type PLAvatarExtendedProps = PLAvatarProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedAvatar = wrapWc<JSX.PatternlibAvatar>("patternlib-avatar");
export const useRefPLAvatar = () => useRef<HTMLPatternlibAvatarElement>(null);

const PLAvatar = React.forwardRef<HTMLPatternlibAvatarElement, PLAvatarExtendedProps>((props, ref) => {
  const myRef = useRefPLAvatar();
  return (
    <WrappedAvatar ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedAvatar>
  );
});
PLAvatar.displayName = "PLAvatar";

export default PLAvatar;
