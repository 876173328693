import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLMultiselectProps = DropOnONLh<JSX.PatternlibMultiselect>;
export type PLMultiselectExtendedProps = PLMultiselectProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedMultiselect = wrapWc<JSX.PatternlibMultiselect>("patternlib-multiselect");
export const useRefPLMultiselect = () => useRef<HTMLPatternlibMultiselectElement>(null);

const PLMultiselect = React.forwardRef<HTMLPatternlibMultiselectElement, PLMultiselectExtendedProps>((props, ref) => {
  const myRef = useRefPLMultiselect();
  return (
    <WrappedMultiselect ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedMultiselect>
  );
});
PLMultiselect.displayName = "PLMultiselect";

export default PLMultiselect;
