import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();
interface DeviceDetails {
  deviceName: string;
  deviceTypeName: string;
  serialNo: string;
}
export const DeviceDetailsAtom = atom<DeviceDetails | undefined>({
  key: "DeviceDetailsAtom",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
