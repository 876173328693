import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceNotificationsForRecentAlertsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.DeviceNotificationFilterInput>;
  order?: Types.InputMaybe<Array<Types.DeviceNotificationSortInput> | Types.DeviceNotificationSortInput>;
}>;


export type GetDeviceNotificationsForRecentAlertsQuery = { deviceNotifications: Array<{ __typename?: 'DeviceNotification', id: string, notificationSend: string, actualValue: string, deviceId: string, device: { __typename?: 'Device', id: string, assetDevice?: Maybe<Array<Maybe<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string> }> } }>>> }, notificationDefinition: { __typename?: 'NotificationDefinition', id: string, value?: Maybe<string>, isRemoved?: Maybe<boolean>, storageArea?: Maybe<{ __typename?: 'StorageArea', id: string, storageAreaName: string }>, notificationOperator: { __typename?: 'NotificationOperator', id: string, operator: string }, notification: { __typename?: 'Notification', id: string, notificationText?: Maybe<string>, mappingNotificationUser: Array<{ __typename?: 'MappingNotificationUser', id: string, userId: string }>, notificationScope: { __typename?: 'NotificationScope', id: string, scopeName?: Maybe<string>, unitSymbol: string } } } }> };


export const GetDeviceNotificationsForRecentAlertsDocument = gql`
    query getDeviceNotificationsForRecentAlerts($skip: Int, $take: Int, $where: DeviceNotificationFilterInput, $order: [DeviceNotificationSortInput!]) {
  deviceNotifications(skip: $skip, take: $take, where: $where, order: $order) {
    id
    device {
      id
      assetDevice {
        id
        asset {
          id
          assetMasterData {
            id
            generalItem
          }
        }
      }
    }
    notificationSend
    actualValue
    deviceId
    notificationDefinition {
      id
      value
      isRemoved
      storageArea {
        id
        storageAreaName
      }
      notificationOperator {
        id
        operator
      }
      notification {
        id
        notificationText
        mappingNotificationUser {
          id
          userId
        }
        notificationScope {
          id
          scopeName
          unitSymbol
        }
      }
    }
  }
}
    `;

/**
 * __useGetDeviceNotificationsForRecentAlertsQuery__
 *
 * To run a query within a React component, call `useGetDeviceNotificationsForRecentAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceNotificationsForRecentAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceNotificationsForRecentAlertsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDeviceNotificationsForRecentAlertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceNotificationsForRecentAlertsQuery, GetDeviceNotificationsForRecentAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceNotificationsForRecentAlertsQuery, GetDeviceNotificationsForRecentAlertsQueryVariables>(GetDeviceNotificationsForRecentAlertsDocument, options);
      }
export function useGetDeviceNotificationsForRecentAlertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceNotificationsForRecentAlertsQuery, GetDeviceNotificationsForRecentAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceNotificationsForRecentAlertsQuery, GetDeviceNotificationsForRecentAlertsQueryVariables>(GetDeviceNotificationsForRecentAlertsDocument, options);
        }
export type GetDeviceNotificationsForRecentAlertsQueryHookResult = ReturnType<typeof useGetDeviceNotificationsForRecentAlertsQuery>;
export type GetDeviceNotificationsForRecentAlertsLazyQueryHookResult = ReturnType<typeof useGetDeviceNotificationsForRecentAlertsLazyQuery>;
export type GetDeviceNotificationsForRecentAlertsQueryResult = Apollo.QueryResult<GetDeviceNotificationsForRecentAlertsQuery, GetDeviceNotificationsForRecentAlertsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
