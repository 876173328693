const en = {
  name: "manufacturer",
  plural: "manufacturers",
  columns: {
    id: "ID",
    isDeleted: "is deleted",
    manufacturerName: "Manufacturer Name",
    manufacturerShorthandName: "Manufacturer Short Hand Name",
    tenantId: "tenant ID",
  },
};
export default en;
