import { useGetMappingDeviceFirmwaresDeviceTypesQuery } from "graphqlBase/MappingDeviceFirmwareDeviceTypes/__generated__/getMappingDeviceFirmwaresDeviceTypes";
import { useCallback } from "react";
import { Device } from ".";

interface LatestFirmware {
  firmwareRevision: string;
  firmwareId: string;
  deviceTypeId: string;
}

const useGetDevicesToUpdateFirmware = (devices: Device[] | undefined) => {
  const { data: firmwaresData, loading: firmwaresLoading } = useGetMappingDeviceFirmwaresDeviceTypesQuery();

  const devicesToUpdateFirmwareCount = useCallback(() => {
    if (!firmwaresData || !devices) return;

    const latestFirmwares = firmwaresData.mappingDeviceFirmwareDeviceTypes.reduce<LatestFirmware[]>(
      (latestFirmwares, current) => {
        const existingFirmware = latestFirmwares.find((firmware) => firmware.deviceTypeId === current.deviceTypeId);

        if (!existingFirmware) {
          return latestFirmwares.concat({
            firmwareId: current.deviceFirmware.id,
            deviceTypeId: current.deviceTypeId,
            firmwareRevision: current.deviceFirmware.firmwareRevision,
          });
        } else {
          const existingRevision = Number(existingFirmware.firmwareRevision);
          const currentRevision = Number(current.deviceFirmware.firmwareRevision);

          if (existingRevision < currentRevision) {
            const index = latestFirmwares.findIndex((firmware) => firmware.firmwareId === existingFirmware.firmwareId);
            latestFirmwares.splice(index, 1, {
              firmwareId: current.deviceFirmware.id,
              deviceTypeId: current.deviceTypeId,
              firmwareRevision: current.deviceFirmware.firmwareRevision,
            });
            return latestFirmwares;
          }
        }
        return latestFirmwares;
      },
      []
    );

    const deviceCountFirmwareUpdate = devices.reduce<number>((deviceCountFirmwareUpdate, current) => {
      const currentDeviceFirmwareId = current?.mappingDeviceFirmwareDevice[0]?.deviceFirmware?.id ?? "";
      const latestFirmwareId = latestFirmwares.find((firmware) => firmware.deviceTypeId === current.deviceTypeId)
        ?.firmwareId;

      if (currentDeviceFirmwareId && currentDeviceFirmwareId !== latestFirmwareId) return deviceCountFirmwareUpdate + 1;
      return deviceCountFirmwareUpdate;
    }, 0);

    return deviceCountFirmwareUpdate;
  }, [firmwaresData, devices]);

  return {
    devicesCountUpdateFirmware: devicesToUpdateFirmwareCount(),
    loading: firmwaresLoading,
  };
};

export default useGetDevicesToUpdateFirmware;
