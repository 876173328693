import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetRoleByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetRoleByIdQuery = { role?: Maybe<{ __typename?: 'Role', id: string, name: string, description?: Maybe<string>, isDefaultRole?: Maybe<boolean>, mappingRoleUsers: Array<{ __typename?: 'MappingRoleUser', id: string, user: { __typename?: 'User', id: string, userName: string } }>, mappingRoleScopes: Array<{ __typename?: 'MappingRoleScope', id: string, scope: { __typename?: 'Scope', id: string, name: string } }> }> };


export const GetRoleByIdDocument = gql`
    query getRoleById($id: UUID!) {
  role(id: $id) {
    id
    name
    description
    isDefaultRole
    mappingRoleUsers(where: {isDeleted: {neq: true}}) {
      id
      user {
        id
        userName
      }
    }
    mappingRoleScopes(where: {isDeleted: {neq: true}}) {
      id
      scope {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRoleByIdQuery, GetRoleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
      }
export function useGetRoleByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleByIdQuery, GetRoleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
        }
export type GetRoleByIdQueryHookResult = ReturnType<typeof useGetRoleByIdQuery>;
export type GetRoleByIdLazyQueryHookResult = ReturnType<typeof useGetRoleByIdLazyQuery>;
export type GetRoleByIdQueryResult = Apollo.QueryResult<GetRoleByIdQuery, GetRoleByIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
