const assetColorsByName = {
  [`Transport`]: "#B0542E",
  [`Construction site`]: "#D09882",
  [`Storage site`]: "#E7CCC0",
  [`Coupled, Active`]: "#B0542E",
  [`Decoupled`]: "#E7CCC0",
  [`Coupled, Idle`]: "#D09882",
  [`Coupled, Passive`]: "#843F22",
};

export type AssetNameKey = keyof typeof assetColorsByName;
export const getColorForAssetState = (state: AssetNameKey) => assetColorsByName[state];
