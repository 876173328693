import { useDeleteMappingDeviceTagsMutation } from "graphqlBase/MappingDeviceTag/__generated__/deleteMappingDeviceTags";
import { GetMappingDeviceTagsDocument } from "graphqlBase/MappingDeviceTag/__generated__/getMappingDeviceTags";
import { useRecoilValue } from "recoil";
import { MappingDeviceTag } from "..";
import { selectedDevicesForTagsAtom } from "../tagModalState";

const useDeleteDeviceTags = () => {
  const deviceIds = useRecoilValue(selectedDevicesForTagsAtom).map((device) => device.id);

  const [deleteMappingDeviceTagsMutation, { loading: deleteDeviceTagsLoading }] = useDeleteMappingDeviceTagsMutation({
    refetchQueries: [{ query: GetMappingDeviceTagsDocument }],
  });

  const handleDeleteDeviceTags = async ({
    mappingDeviceTags,
    id,
  }: {
    mappingDeviceTags: MappingDeviceTag[];
    id: string;
  }) => {
    const deleteDeviceMappings = deviceIds
      .map((currId) => {
        const index = mappingDeviceTags.findIndex((entry) => entry.deviceId === currId && entry.tagId === id);
        if (index !== -1) return mappingDeviceTags[index].id;
      })
      .filter(Boolean);

    if (deleteDeviceMappings.length > 0) {
      await deleteMappingDeviceTagsMutation({ variables: { ids: deleteDeviceMappings as string[] } });
    }
  };

  return {
    deleteDeviceTagsLoading,
    handleDeleteDeviceTags,
  };
};

export default useDeleteDeviceTags;
