import React, { MutableRefObject, useEffect, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { ResultSet } from "@cubejs-client/core";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import DoughnutDataList from "../doughnuts/DoughnutDataList";
import useSetChatOptions from "./useSetChatOptions";
import { chartsLoadingState, noChartDataState } from "../../chartsState";
import { filtersSelector } from "../filtersState";

export interface MakeChartsProps<T, Y, Z> {
  chartInstance: MutableRefObject<any>;
  chartLoading: boolean;
  error: Error | null;
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  isDougnut?: boolean;
  isLoading: boolean;
  options: Y;
  optionsObjForSetOption: Z;
  resultSet: ResultSet<T> | null;
  showHistoryButton?: boolean;
}

const useMakeMemoizedCharts = <T, Y, Z extends { series: any }>({
  isLoading,
  error,
  chartLoading,
  chartInstance,
  handleClick,
  options,
  resultSet,
  showHistoryButton,
  isDougnut,
  optionsObjForSetOption,
}: MakeChartsProps<T, Y, Z>) => {
  const [noChartData, setNoChartData] = useRecoilState(noChartDataState);
  const { data } = useSetChatOptions({ chartInstance, isDougnut, resultSet, optionsObjForSetOption, chartLoading });
  const filters = useRecoilValue(filtersSelector);

  const setChartLoading = useSetRecoilState(chartsLoadingState);
  useEffect(() => {
    return () => {
      chartInstance.current = undefined;
      setNoChartData(undefined);
      setChartLoading(undefined);
    };
  }, []);

  useEffect(() => {
    if (noChartData)
      return () => {
        chartInstance.current = undefined;
        setNoChartData(undefined);
        setChartLoading(undefined);
      };
  }, [noChartData]);

  useEffect(() => {
    if ((isLoading || !resultSet) && !error) return;

    if (!resultSet?.rawData()?.length || error) setNoChartData(true);
    else setNoChartData(false);
  }, [resultSet, isLoading, filters, error]);

  useEffect(() => {
    setChartLoading(chartLoading && resultSet === undefined);
  }, [chartLoading]);

  const onChartReady = (instance: any) => {
    if (!chartInstance.current) chartInstance.current = instance;
  };
  const MemoizedChartList = useMemo(() => {
    return data && <DoughnutDataList showHistoryButton={showHistoryButton} seriesData={data ?? []} />;
  }, [data]);

  const MemoizedChart = useMemo(() => {
    return (
      chartLoading !== undefined &&
      !chartLoading &&
      noChartData !== undefined &&
      !noChartData && (
        <ReactECharts
          style={{ height: "100%" }}
          onChartReady={onChartReady}
          option={options}
          lazyUpdate
          onEvents={{ click: (e: React.MouseEvent<HTMLElement>) => handleClick(e) }}
        />
      )
    );
  }, [chartLoading, noChartData]);

  return { MemoizedChart, MemoizedChartList };
};

export default useMakeMemoizedCharts;
