export default {
  name: "mapping attribute assettype attribute set",
  plural: "mapping attributes assettypes attributes sets",
  columns: {
    assetAttribute: "asset attribute",
    assetAttributeId: "asset attribute ID",
    assetTypeAttributeSet: "asset type attribute Set",
    assetTypeAttributeSetId: "asset type attribute Set ID",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
  },
};
