import SidebarDrawer from "components/organisms/SidebarDrawer";
import addHours from "date-fns/addHours";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import sub from "date-fns/sub";
import { useGetDeviceMovementHistoryQuery } from "graphqlBase/Devices/__generated__/getDeviceMovementHistory";
import { MovementHitoryType } from "./index.d";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import translations from "translations";
import DateToggle from "./DateToggle";
import PositionHistoryDateRangePicker from "./PositionHistoryDateRangePicker";
import PositionHistoryMap from "./PositionHistoryMap";
import PositionHistoryTable from "./PositionHistoryTable";
import { durationOptionForPositionHistoryAtom, dateRangeForPositionHistorySelectorFamily } from "./recoilState";
import styled from "@mui/styled-engine";
import { formatDateTime } from "translations/formatter";

interface PositionHistoryProps {
  deviceId: string;
  open: boolean;
  setOpenPositionHistory: Dispatch<SetStateAction<boolean>>;
}
const PaperProps = {
  zIndex: 51,
};
const DatePickerSC = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  margin: "0 20px 10px 20px",
});
const PositionHistory: React.FC<PositionHistoryProps> = ({ deviceId, open, setOpenPositionHistory }) => {
  const toggleOption = useRecoilValue(durationOptionForPositionHistoryAtom);
  const [startDate, setStartDate] = useRecoilState(dateRangeForPositionHistorySelectorFamily("startDate"));
  const [endDate, setEndDate] = useRecoilState(dateRangeForPositionHistorySelectorFamily("endDate"));
  const startDateToString = () => {
    if (startDate) {
      return addHours(startOfDay(new Date(startDate)), 1).toISOString();
    }
  };
  const endDateToString = () => {
    if (endDate) {
      return addHours(endOfDay(new Date(endDate)), 1).toISOString();
    }
  };

  useEffect(() => {
    if (toggleOption !== "custom") {
      setEndDate(new Date().toISOString());
      const specifiedDurationForSub = toggleOption.split(" ");
      setStartDate(sub(new Date(), { [specifiedDurationForSub[1]]: Number(specifiedDurationForSub[0]) }).toISOString());
    }
  }, [toggleOption]);

  const { data, loading } = useGetDeviceMovementHistoryQuery({
    variables: {
      deviceIds: [deviceId],
      startDate: startDateToString() ?? "",
      endDate: endDateToString() ?? "",
    },
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });

  const movementHistory = data?.deviceLocationHistory;

  const movementHistoryLocations = movementHistory?.map((movement, index) => {
    if (!movement || !movement.azureMapsLocations || !movement.azureMapsLocations[0]) return;

    const address = movement.azureMapsLocations[0].address;
    return {
      id: `${index}`,
      label: `${formatDateTime(new Date(movement.dateFrom))} - ${formatDateTime(new Date(movement.dateTo))}`,
      value: !address
        ? translations.pages.fleetInventory.noLocationMessage
        : `${address?.streetNameAndNumber?.concat(",") ?? ""}
        ${
          address.municipality
            ? address?.municipalitySubdivision
              ? address?.municipality?.concat(",")
              : address.municipality
            : ""
        } ${address?.municipalitySubdivision ?? ""}`,
    };
  });

  const sidebarWidth = 25;
  return (
    <>
      <SidebarDrawer
        closeOnOutsideClick={false}
        anchor="left"
        open={open}
        maxWidth="75vw"
        onClose={() => setOpenPositionHistory(false)}
        PaperProps={{ style: PaperProps }}
      >
        <PositionHistoryMap
          movementHistory={movementHistory as MovementHitoryType[]}
          movementHistoryLocations={movementHistoryLocations}
        />
      </SidebarDrawer>
      <SidebarDrawer
        title="GPS Position history"
        closeOnOutsideClick={false}
        open={open}
        maxWidth={`${sidebarWidth}vw`}
        onClose={() => setOpenPositionHistory(false)}
        PaperProps={{ style: PaperProps }}
      >
        <DatePickerSC>
          <DateToggle />
          {toggleOption === "custom" && <PositionHistoryDateRangePicker />}
        </DatePickerSC>

        <PositionHistoryTable movementHistoryLocations={movementHistoryLocations} loading={loading} />
      </SidebarDrawer>
    </>
  );
};

export default PositionHistory;
