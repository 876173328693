export default {
  name: "Asset Attribute value",
  plural: "Asset Attribute values",
  columns: {
    asset: "asset",
    assetAttribute: "asset attribute",
    assetAttributeId: "asset attribut ID",
    assetId: "asset ID",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
    value: "value",
  },
};
