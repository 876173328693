import { deDE } from "@mui/material/locale";
import merge from "lodash/merge";
import {
  BasicConfig,
  Config,
  ConfirmFunc,
  ConfirmModalProps,
  Conjunctions,
  Fields,
  Funcs,
  LocaleSettings,
  Operators,
  Settings,
  Types,
  Widgets,
} from "react-awesome-query-builder";
import translations from "translations";
import { LocalizationProvider } from "@material-ui/pickers";
import React, { JSXElementConstructor, Key, ReactElement } from "react";
import { ThemeProvider } from "@mui/styles";
import { StyledEngineProvider, Theme } from "@mui/material/styles";
import queryBuilderTheme from "themes/queryBuilder/queryBuilderTheme";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import SelectWidget from "./components/widgets/SelectWidget";
import AddRuleOrGroupWidget from "./components/widgets/AddRuleOrGroupWidget";
import DeleteWidget from "./components/widgets/DeleteWidget";
import ConjunctionsWidget from "./components/widgets/ConjunctionsWidget";
import TextWidget from "./components/widgets/TextWidget";
import MultiSelectWidget from "./components/widgets/MultiSelectWidget";
import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import { getRecoilStore, RecoilTunnel } from "recoil-toolkit";
import { confirmDeleteState } from "./ConfirmDeleteModal/confirmDeleteState";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";
// import MaterialWidgets from "react-awesome-query-builder/components/widgets/material";

// const { MaterialConfirm, MaterialUseConfirm } = MaterialWidgets;

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const MaterialProvider = (props: {
  children?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  config?: Config | undefined;
  key?: Key | null | undefined;
}) => {
  const base = <div className="mui">{props.children}</div>;

  const withProviders = (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={queryBuilderTheme}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          {base}
          <ConfirmDeleteModal />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );

  return withProviders;
};

const InitialConfig = MaterialConfig; // or BasicConfig or MaterialConfig

const conjunctions: Conjunctions = {
  AND: InitialConfig.conjunctions.AND,
  OR: InitialConfig.conjunctions.OR,
};

export const operators: Operators = {
  ...InitialConfig.operators,
  select_any_in: { ...InitialConfig.operators.select_any_in, label: "includes" },
  select_not_any_in: { ...InitialConfig.operators.select_any_in, label: "excludes" },
};

const widgets: Widgets = {
  ...InitialConfig.widgets,
  text: {
    ...BasicConfig.widgets.text,
    valuePlaceholder: "Enter value",
    factory: (props) => <TextWidget {...props} />,
  },
  slider: {
    ...InitialConfig.widgets.slider,
    customProps: {
      width: "300px",
    },
  },
  rangeslider: {
    ...InitialConfig.widgets.rangeslider,
    customProps: {
      width: "300px",
    },
  },
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD",
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: "HH:mm",
    valueFormat: "HH:mm:ss",
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: "HH:mm",
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD HH:mm:ss",
  },
  treeselect: {
    ...InitialConfig.widgets.treeselect,
    customProps: {
      showSearch: true,
      treeSelectOnlyLeafs: true,
    },
    factory: (props) => <SelectWidget {...props} />,
  },
  select: {
    ...InitialConfig.widgets.select,
    factory: (props) => <SelectWidget {...props} />,
  },
  multiselect: {
    ...InitialConfig.widgets.multiselect,
    factory: (props) => <MultiSelectWidget {...props} />,
  },
};

const types: Types = {
  ...InitialConfig.types,
  boolean: merge(InitialConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          operatorInlineLabel: "is",
        },
      },
    },
  }),
};

const localeSettings: () => LocaleSettings = () => ({
  locale: {
    moment: "de",
    material: deDE,
  },
  valueLabel: translations.globals.terms.value,
  valuePlaceholder: translations.globals.terms.value,
  fieldLabel: translations.globals.terms.field,
  operatorLabel: translations.pages.groups.query.operator,
  fieldPlaceholder: translations.pages.groups.query.selectField,
  operatorPlaceholder: translations.pages.groups.query.selectOperator,
  addRuleLabel: translations.pages.groups.query.rule,
  addGroupLabel: translations.pages.groups.query.ruleSet,
  notLabel: translations.pages.groups.query.not,
  valueSourcesPopupTitle: translations.pages.groups.query.selectValueSource,
  removeRuleConfirmOptions: {
    title: translations.globals.popupMessages.confirmDeleteRule,
    okText: translations.globals.terms.yes,
    okType: "danger",
  },
  removeGroupConfirmOptions: {
    title: translations.globals.popupMessages.confirmDeleteGroup,
    okText: translations.globals.terms.yes,
    okType: "danger",
  },
});

const settings: () => Settings = () => ({
  ...InitialConfig.settings,
  ...localeSettings(),
  valueSourcesInfo: {
    value: {
      label: "Value",
    },
    field: {
      label: "Field",
      widget: "field",
    },
    func: {
      label: "Function",
      widget: "func",
    },
  },
  showNot: false,
  canLeaveEmptyGroup: true, //after deletion
  renderConjs: (props) => <ConjunctionsWidget {...props} />,
  renderField: (props) => <SelectWidget {...props} />,
  renderOperator: (props) => <SelectWidget {...props} />,
  renderButton: (props) => {
    if (props) {
      const { type } = props;

      if (type?.includes("del")) return <DeleteWidget {...props} />;
    }
    return <AddRuleOrGroupWidget {...props} />;
  },
  renderProvider: (props) => <MaterialProvider {...props} />,
  renderConfirm: (props: ConfirmModalProps) => {
    const { onOk } = props;
    if (!onOk) return;

    getRecoilStore()
      .then((store) => {
        if (!store) return;
        store.set(confirmDeleteState, { onOk, show: true });
      })
      .catch((error: any) => console.log(error));
  },
});

const funcs: Funcs = {};

const config: () => Config = () => ({
  conjunctions,
  operators,
  widgets,
  types,
  settings: settings(),
  fields: {},
  funcs,
});

export default config;
