import { ApolloClient, gql } from "@apollo/client";
import singleMultiNameMappings from "graphqlBase/singleMultiNameMappings";
import { Field } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import { FieldMiddleWare } from "../types";

export const fillSelect = async ({
  client,
  field,
  table,
  fieldName,
  valueColumn,
  labelColumn,
}: {
  client: ApolloClient<object>;
  field: Field;
  fieldName: string;
  labelColumn?: string;
  table?: string;
  valueColumn?: string;
}) => {
  const [tableDefault, columnDefault] = fieldName.split(".");

  const query = gql`query{
            result:${singleMultiNameMappings[table ?? tableDefault] ?? singleMultiNameMappings[tableDefault]}{
            id
            value:${valueColumn ?? columnDefault}
            title:${labelColumn ?? valueColumn ?? columnDefault}
          }}`;
  try {
    const result = await client.query({ query, fetchPolicy: "network-only" });
    return {
      fieldName,

      field: {
        ...field,

        listValues: (result.data.result ?? []).map(({ value, title }: { title: string; value: string }) => ({
          value,
          title,
        })),
      },
    };
  } catch (e) {
    return { fieldName, field: { ...field, listValues: [] } };
  }
};

export async function runFieldMiddleWare<T>({
  client,
  field,
  fieldName,
  middleWare,
  getOperator,
}: {
  client: ApolloClient<object>;
  field: T;
  fieldName: string;
  getOperator: (val: string) => string;
  middleWare: FieldMiddleWare<T>["middleWare"];
}): Promise<
  | {
      field: T;
      fieldName: string;
    }
  | {
      fieldName: string;
    }
> {
  try {
    const result = await middleWare({ client, field, fieldName, getOperator });

    return result ?? { fieldName };
  } catch (e) {
    // console.log(e);
    return { fieldName };
  }
}
