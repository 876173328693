import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ManageMappingRoleScopesMutationVariables = Types.Exact<{
  creates: Array<Types.MappingRoleScopeCreateType> | Types.MappingRoleScopeCreateType;
  deletes?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type ManageMappingRoleScopesMutation = { createMappingRoleScopes?: Maybe<Array<Maybe<{ __typename?: 'MappingRoleScope', id: string }>>>, deleteMappingRoleScopes?: Maybe<Array<Maybe<{ __typename?: 'MappingRoleScope', id: string }>>> };


export const ManageMappingRoleScopesDocument = gql`
    mutation manageMappingRoleScopes($creates: [MappingRoleScopeCreateType!]!, $deletes: [UUID!]) {
  createMappingRoleScopes(mappingRoleScopes: $creates) {
    id
  }
  deleteMappingRoleScopes(ids: $deletes) {
    id
  }
}
    `;
export type ManageMappingRoleScopesMutationFn = Apollo.MutationFunction<ManageMappingRoleScopesMutation, ManageMappingRoleScopesMutationVariables>;

/**
 * __useManageMappingRoleScopesMutation__
 *
 * To run a mutation, you first call `useManageMappingRoleScopesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageMappingRoleScopesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageMappingRoleScopesMutation, { data, loading, error }] = useManageMappingRoleScopesMutation({
 *   variables: {
 *      creates: // value for 'creates'
 *      deletes: // value for 'deletes'
 *   },
 * });
 */
export function useManageMappingRoleScopesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ManageMappingRoleScopesMutation, ManageMappingRoleScopesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ManageMappingRoleScopesMutation, ManageMappingRoleScopesMutationVariables>(ManageMappingRoleScopesDocument, options);
      }
export type ManageMappingRoleScopesMutationHookResult = ReturnType<typeof useManageMappingRoleScopesMutation>;
export type ManageMappingRoleScopesMutationResult = Apollo.MutationResult<ManageMappingRoleScopesMutation>;
export type ManageMappingRoleScopesMutationOptions = Apollo.BaseMutationOptions<ManageMappingRoleScopesMutation, ManageMappingRoleScopesMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
