const operatorMatches = [
  ["equal", "eq"],
  ["not_equal", "neq"],
  ["less", "lt"],
  ["less_or_equal", "lte"],
  ["greater", "gt"],
  ["greater_or_equal", "gte"],
  ["like", "constains"],
  ["not_like", "ncontains"],
  ["starts_with", "startsWith"],
  ["ends_with", "endsWith"],
  ["between", "$unkown$"],
  ["not_between", "$unkown$"],
  ["is_empty", "$unkown$"],
  ["is_not_empty", "$unkown$"],
  ["select_equals", "eq"],
  ["select_not_equals", "neq"],
  ["select_any_in", "in"],
  ["select_not_any_in", "nin"],
  ["multiselect_equals", "in"],
  ["multiselect_not_equals", "nin"],
  ["proximity", "$unkown$"],
  ["some", "$unkown$"],
  ["all", "$unkown$"],
  ["none", "$unkown$"],
  ["within", "within"],
  ["nwithin", "nwithin"],
];

export default operatorMatches;

export const getOperator = (operator: string) =>
  (operatorMatches.find(([opertorKey]) => operator === opertorKey) ?? ["", ""])[1];
