import { addHours, startOfDay } from "date-fns";
import { atom, selectorFamily } from "recoil";

export interface DateRangeObject {
  endDate: string;
  startDate: string;
}

export const durationOptionForPositionHistoryAtom = atom({
  key: "durationOptionForPositionHistoryAtom",
  default: "0 hours",
});
export const dateRangeForPositionHistoryAtom = atom({
  key: "dateRangeForPositionHistoryAtom",
  default: {
    startDate: addHours(startOfDay(new Date()), 1).toISOString(),
    endDate: new Date().toISOString(),
  },
});

export const dateRangeForPositionHistorySelectorFamily = selectorFamily<string, keyof DateRangeObject>({
  key: "dateRangeForPositionHistorySelectorFamily",
  get: (field) => ({ get }) => {
    const dateRangeObject = get(dateRangeForPositionHistoryAtom);
    return dateRangeObject[field];
  },
  set: (field) => ({ get, set }, newValue) => {
    const dateRangeObject = get(dateRangeForPositionHistoryAtom);
    set(dateRangeForPositionHistoryAtom, { ...dateRangeObject, [field]: newValue });
  },
});
export const tablesRowIdHoverAtom = atom<string | undefined>({
  key: "tablesRowIdHoverAtom",
  default: undefined,
});

export const isPositionHoveredOnTable = selectorFamily<boolean, string>({
  key: "dateRangeForPositionHistorySelectorFamily",
  get: (id) => ({ get }) => {
    const currentActiveId = get(tablesRowIdHoverAtom);
    return id === currentActiveId;
  },
});
