import { Box, SxProps, Theme, Typography } from "@mui/material";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import React, { useMemo } from "react";
import { InputWrapperSC, autocompleteRootStyles, inputLabelStyles, inputStyles } from "./styles";
import ArrowDownIcon from "icons/components/ArrowDownIcon";

export interface SelectItem {
  label: string;
  value: string;
}

interface SelectFreeSoloProps {
  customSx?: SxProps<Theme>;
  disabled?: boolean;
  disabledOptions?: string[];
  inputValue?: string;
  isStatusError?: boolean;
  label?: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | SelectItem | null,
    reason: AutocompleteChangeReason
  ) => void;
  onChangeInput?: OutlinedTextFieldProps["onChange"];
  options: SelectItem[];
  placeholder?: string;
  selected?: string | null;
}

const SelectFreeSolo: React.FC<SelectFreeSoloProps> = ({
  options,
  onChange,
  placeholder,
  disabled,
  selected,
  label,
  onChangeInput,
  inputValue,
  customSx,
  disabledOptions,
  isStatusError,
}) => {
  const sortedOptions: string[] = useMemo(() => {
    return [...(options ?? [])].sort((a, b) => a?.label.localeCompare(b?.label));
  }, [options]).map((option) => option.label);

  return (
    <InputWrapperSC sx={customSx}>
      {label && (
        <div className=".flex.justify-between.items-baseline">
          <Typography style={inputLabelStyles} variant="copy">
            {label}
          </Typography>
        </div>
      )}
      <Autocomplete
        getOptionDisabled={(option) => !!disabledOptions?.find((disabled) => disabled === option)}
        sx={{ ...autocompleteRootStyles(isStatusError), ...customSx }}
        id="tags-filled"
        options={sortedOptions}
        value={selected}
        placeholder={placeholder}
        freeSolo
        forcePopupIcon
        disabled={disabled}
        onChange={onChange}
        popupIcon={<ArrowDownIcon fontSize={23} />}
        renderInput={(params) => (
          <TextField {...params} onChange={onChangeInput} value={inputValue} sx={inputStyles} variant="outlined" />
        )}
      />
    </InputWrapperSC>
  );
};

export default SelectFreeSolo;
