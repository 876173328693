export default {
  name: "Benachrichtigungsumfang",
  plural: "Benachrichtigungsumfänge",
  columns: {
    id: "ID",
    operator: "Operator",
    createdAt: "Erstellt am",
    modifiedAt: "Geändert am",
    tenantId: "",
  },
};
