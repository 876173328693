import useDataGrid from "components/templates/dataGridTable";
import React from "react";
import { formatTimeDistance } from "translations/formatter";
import translations from "translations";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import TagChips from "components/molecules/TagChips";
import PLTextlink from "components/patternLib/PLTextlink";
import { useHistory } from "react-router-dom";
import {
  GetDevicesForConnectedDevicesToGatewayQuery,
  ResultType,
  useGetDevicesForConnectedDevicesToGatewayLazyQuery,
} from "graphqlBase/Devices/__generated__/getDevicesForConnectedDevicesToGateway";
import { LeftTileWrapperSC } from "../../utils/styles";

interface ConnectedDevicesProps {
  deviceIds: string[];
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
}
type Device = ResultType<GetDevicesForConnectedDevicesToGatewayQuery["devices"]>;
const ConnectedDevices: React.FC<ConnectedDevicesProps> = ({ deviceIds, setCurrentTab }) => {
  const history = useHistory();
  const variables = {
    where: {
      deviceId: { in: deviceIds },
    },
  };
  const [query, { data, loading, error }] = useGetDevicesForConnectedDevicesToGatewayLazyQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });
  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef, getRowCount } = useDataGrid<Device, "Device">({
    query,
    variables,
    tableId: "ConnectedDevices",
    persistance: "runTime",
  });
  const columns = useMakeColumns(
    [
      {
        field: "device",
        renderHeader: (params) => `Device (${getRowCount()})`,
        flex: 1,
        remoteOrder: (sort) => ({ serialNo: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ filterValue, where }) => ({ serialNo: filterValue }),
        }),
        renderCell: (params) =>
          params.row?.serialNo ? (
            <PLTextlink
              size="small"
              bold={false}
              label={params.row?.serialNo}
              onClick={() => {
                setCurrentTab(0);
                history.push(`/deviceManagement/deviceDetails/${params.row.id}`);
              }}
            />
          ) : (
            <p style={{ color: "#E52828" }}>{translations.pages.assetManagement.noDeviceText}</p>
          ),
        type: "string",
      },
      {
        field: "deviceTypeName",
        headerName: `Device type`,
        flex: 1.5,
        remoteOrder: (sort) => ({ deviceType: { deviceTypeName: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            deviceType: { deviceTypeName: { and: [{ neq: "M2CP" }, filterValue] } },
          }),
        }),
        valueGetter: (params) => {
          return params.row.deviceType?.deviceTypeName ?? "";
        },
        type: "string",
      },
      {
        field: "lastMeasurementTime",
        headerName: `Last Connection`,
        flex: 1.5,
        valueGetter: (params) => {
          const lastTimeConnection = params.row.lastMeasurement?.[0]?.utcTimeMeasured;
          return lastTimeConnection ? formatTimeDistance(new Date(lastTimeConnection)) : "";
        },
        type: "string",
      },
      {
        field: "firmware",
        headerName: `Firmware`,
        flex: 1,
        valueGetter: (params) => {
          return params.row.mappingDeviceFirmwareDevice?.[0]?.deviceFirmware?.firmwareVersion ?? "";
        },
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 1.5,
        valueGetter: (params) => {
          return params.row.mappingDeviceTag?.map((entry) => entry.tag?.tagValue ?? "") ?? null;
        },
        renderCell: (params) => {
          return <TagChips mappingDeviceTag={params.row.mappingDeviceTag} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators().filter(
            (operator) =>
              operator.value !== "isEmpty" &&
              operator.value !== "equals" &&
              operator.value !== "isNotEmpty" &&
              operator.value !== "isAnyOf"
          ),
          filterPath: ({ where, filterValue }) => ({
            mappingDeviceTag: { some: { tag: { tagValue: filterValue } } },
          }),
        }),
        type: "stringArray",
      },
    ],
    []
  );

  return (
    <LeftTileWrapperSC>
      {!error && (
        <DataGrid
          withMargin={false}
          checkboxSelection={false}
          noDataMessage={translations.pages.deviceManagement.noDeviceMessage}
          tableTitle={translations.pages.deviceManagement.myDevices}
          tableHeight="65vh"
          columns={columns}
          rows={data?.devices}
          loading={loading}
        />
      )}
    </LeftTileWrapperSC>
  );
};

export default ConnectedDevices;
