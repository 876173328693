import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import React, { MutableRefObject, useCallback, useRef } from "react";
import { useRecoilState } from "recoil";
import { filtersSelector, RecoilFilterType } from "../../filtersState";
import { RootChartContainerSC } from "../../styles";
import { Box } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { tooltipCommonStyles } from "../../../styles";
import { getTotalAmountOfHours } from "../../../getTotalAmountOfHours";
import { round } from "lodash";
import useMakeMemoizedCharts from "../../hooks/useMakeMemoizedCharts";

const dimensions = ["DimStorageArea.storageareaname"];

const GeofencesBarChart: React.FC<{ groupLoading?: boolean }> = ({ groupLoading }) => {
  const [filters, setFilters] = useRecoilState(filtersSelector);
  const chartInstance: MutableRefObject<any> = useRef();

  const query: Query = {
    dimensions,
    order: {
      "FactUtilization.utilizationhoursMeasure": "desc",
    },
    measures: ["FactUtilization.utilizationhoursMeasure"],
    filters: filters.filter((elem) => !dimensions.includes(elem.member as string)),
  };

  const { resultSet, isLoading, error } = useCubeQuery(query);

  const chartLoading = (isLoading || !!groupLoading) && !chartInstance?.current;

  const totalAmountOfHours = getTotalAmountOfHours(resultSet?.rawData());

  const getXValues = () => resultSet?.rawData()?.map((item) => item["DimStorageArea.storageareaname"]);

  const getSourceData = () =>
    resultSet
      ?.rawData()
      ?.map((item) => [
        item["DimStorageArea.storageareaname"],
        totalAmountOfHours ? round(100 * (item["FactUtilization.utilizationhoursMeasure"] / totalAmountOfHours), 2) : 0,
      ]);

  const handleClick = useCallback(
    (e: any) => {
      if (!e.name) return;
      const filter: RecoilFilterType = {
        member: query.dimensions?.[0] ?? "",
        operator: "equals",
        values: [e.name],
      };
      setFilters([filter]);
    },
    [query.dimensions, setFilters]
  );

  const ownFilterValues = filters
    .filter((elem) => [dimensions[0]].includes(elem.member as string))
    .flatMap((filter) => filter.values);

  const getSeriesData = () => {
    return {
      barWidth: "30px",
      type: "bar",
      label: {
        show: true,
        position: "top",
        rotate: 90,
        offset: [30, 8],
        fontWeight: 700,
        fontSize: "12px",
        color: "#797979",
        formatter: (item: any) => `${item.data[1]} %`,
      },
      itemStyle: {
        color: ({ name }: { name: string }) => {
          return !ownFilterValues.length || ownFilterValues.includes(name) ? "#3A4046" : `rgba(0,0,0,0.1)`;
        },
      },
    };
  };

  const options = {
    grid: { left: 20, right: 25, top: 50, bottom: 150 },
    tooltip: {
      valueFormatter: (value: number) => `${value}%`,
      textStyle: tooltipCommonStyles,
      position: (pos: Array<number>) => {
        return [pos[0] + 20, pos[1] + 25];
      },
    },
    axisLine: {
      lineStyle: {
        color: "#6B7278",
      },
    },
    axisTick: {
      lineStyle: {
        color: "#6B7278",
      },
    },
    dataset: {
      source: getSourceData() ?? [],
    },
    xAxis: {
      type: "category",
      data: getXValues(),
      showGrid: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: "#51585D",
        rotate: 90,
        fontWeight: 400,
        fontSize: "12px",
      },
    },
    yAxis: {
      show: false,
    },
    legend: {
      display: false,
    },
    series: getSeriesData(),
  };

  const { MemoizedChart } = useMakeMemoizedCharts({
    options,
    isLoading,
    chartInstance,
    chartLoading,
    resultSet,
    handleClick,
    error,
    optionsObjForSetOption: {
      dataset: { source: getSourceData() },
      xAxis: { data: getXValues() },
      series: getSeriesData(),
    },
  });

  return (
    <RootChartContainerSC sx={{ minHeight: "400px" }}>
      <LoadingSpinner hideChildrenOnLoad loading={chartLoading}>
        <Box sx={{ width: "100%", height: "100%" }}>{MemoizedChart}</Box>
      </LoadingSpinner>
    </RootChartContainerSC>
  );
};
export default GeofencesBarChart;
