import { Box, styled, Typography } from "@mui/material";
import DetailInfo from "components/atomics/DetailInfo";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { addMilliseconds, isBefore } from "date-fns";
import { useGetDeviceHeartbeatCurrentValuesByGatewayDeviceConnectionIdQuery } from "graphqlBase/Devices/__generated__/getDeviceHeartbeatCurrentValuesByGatewayDeviceConnectionId";
import React from "react";
import { translations } from "translations";
import { formatTimeDistance } from "translations/formatter";
import { ReactComponent as OnlineEclipse } from "components/icons/iconsSvg/OnlineEclipse.svg";
import { ReactComponent as OfflineEclipse } from "components/icons/iconsSvg/OfflineEclipse.svg";

interface Props {
  deviceTypeName?: string;
  gatewayDeviceConnectionId: string;
  serialNo?: string;
}

export const DotSC = styled(Box)(({ theme }) => ({
  width: "15px",
  height: "15px",
  fill: "black",
}));

const statusStyles = {
  display: "flex",
  columnGap: "10px",
};

const M2CPInfoTile: React.FC<Props> = ({ gatewayDeviceConnectionId, deviceTypeName, serialNo }) => {
  const {
    data: DeviceHeartbeatsCurrentValueData,
    loading,
  } = useGetDeviceHeartbeatCurrentValuesByGatewayDeviceConnectionIdQuery({
    variables: { gateWayDeviceConnectionId: gatewayDeviceConnectionId },
    fetchPolicy: "cache-and-network",
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });

  const utcTime =
    DeviceHeartbeatsCurrentValueData && DeviceHeartbeatsCurrentValueData?.deviceHeartbeatCurrentValues.length
      ? DeviceHeartbeatsCurrentValueData?.deviceHeartbeatCurrentValues[0].utcTimeMeasured
      : "";

  const getOffLineStatus = (utcTime: string | undefined) => {
    if (!utcTime) return true;
    const offlineDateForComparison = addMilliseconds(
      new Date(),
      -Number(process.env.REACT_APP_OFFLINE_THRESHOLD_IN_MILLISECONDS)
    );

    return isBefore(new Date(utcTime), offlineDateForComparison);
  };

  const m2cpInfo = [
    {
      label: translations.entities.deviceType.columns.deviceTypeName,
      value: deviceTypeName ?? "",
    },
    {
      label: translations.entities.device.columns.serialNo,
      value: serialNo ?? "",
    },
    {
      label: translations.globals.terms.status,
      value: getOffLineStatus(utcTime) ? (
        <Box sx={statusStyles}>
          <OfflineEclipse />
          <Typography>Offline</Typography>
        </Box>
      ) : (
        <Box sx={statusStyles}>
          <OnlineEclipse />
          <Typography>Online</Typography>
        </Box>
      ),
    },
    {
      label: "Last Connection",
      value: utcTime ? formatTimeDistance(new Date(utcTime)) : "",
    },
  ];

  return (
    <LoadingSpinner sx={{ height: "fit-content" }} loading={loading}>
      <DetailInfo data={m2cpInfo} itemHeight="30px" />
    </LoadingSpinner>
  );
};

export default M2CPInfoTile;
