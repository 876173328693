import client from "apollo/client";
import {
  UpdateGroupsDocument,
  UpdateGroupsMutation,
  UpdateGroupsMutationVariables,
} from "graphqlBase/Groups/__generated__/updateGroups";
import { GroupUpdateType } from "graphqlBase/types";
import { useState } from "react";

const useUpdateGroups = () => {
  const [loading, setLoading] = useState<boolean | undefined>();

  const handleUpdate = async (group: GroupUpdateType) => {
    setLoading(true);
    const variables = { groups: [{ ...group, groupDescription: group?.groupDescription ?? "" }] };
    return await client
      .mutate<UpdateGroupsMutation, UpdateGroupsMutationVariables>({
        mutation: UpdateGroupsDocument,
        variables,
      })
      .then((result) => result?.data?.updateGroups?.[0]?.id)
      .catch((e: any) => {
        console.error("handle update group error", e.message);
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  return { handleUpdate, loading };
};

export default useUpdateGroups;
