import React, { useEffect, useState } from "react";
import { useGetUserIdAndLanguageQuery } from "graphqlBase/Language/__generated__/getUserIdAndLanguage";
import { useGetAttributesQuery } from "graphqlBase/Mixed/__generated__/getAttributes";
import { useAuth } from "oidc-react";

import { useLanguage } from "translations/hooks/useLanguage";
import LoadingSpinner from "components/atomics/LoadingSpinner";

const StartupOperations: React.FC<{}> = ({ children }) => {
  useGetAttributesQuery();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [language, setLanguage] = useLanguage();
  const variables = {
    where: { userName: { eq: auth?.userData?.profile.preferred_username } },
  };

  const { data } = useGetUserIdAndLanguageQuery({ variables });
  useEffect(() => {
    if (data) {
      setLanguage(data?.users[0].language ?? navigator.language);
      setIsLoading(false);
    }
  }, [data, setLanguage]);

  const height = window.innerHeight;
  if (language) {
    return <>{children}</>;
  } else return <LoadingSpinner sx={{ height, overflow: "hidden" }} loading={true} />;
};
export default StartupOperations;
