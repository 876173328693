import { RawRule } from "@casl/ability";

type Rule = RawRule<[string, string], {}>;

export const parseScopes = (scopes: string[]): Rule[] => {
  return scopes.reduce<Rule[]>((abilities, scope) => {
    const [subject, actionPre] = scope.split(/[.]/);
    const action = actionPre.toLocaleLowerCase();
    if (subject.endsWith("UI")) return abilities.concat(parseRoutes({ subject, action, abilities }));
    return abilities.concat({ action: action.toLocaleLowerCase(), subject });
  }, []);
};

const parseRoutes = ({ subject, action, abilities }: { abilities: Rule[]; action: string; subject: string }) => {
  const routesPre = subject.substr(0, subject.length - 2).split("/");
  return routesPre.reduce<Rule[]>((newAbilities, routePre, index, elems) => {
    const route = routesPre.slice(0, index + 1).join("/");

    if (!abilities.find((rawRule) => rawRule.subject === route)) {
      if (index === elems.length - 1) {
        return newAbilities.concat([
          { action, subject: route },
          { action, subject: `${route}UI` },
          { action, subject: `${routePre}UI` },
        ]);
      }
      return newAbilities.concat({ action, subject: route });
    }
    return newAbilities;
  }, []);
};
