import * as React from "react";
import LoadingIndicator from "components/atomics/LoadingIndicator";
import translations from "translations";
import Props from "./index.d";
import PLButton from "components/patternLib/PLButton";

const CancelButton: React.FC<Props> = (props) => {
  const {
    disabled,
    testId,
    data: { setAndSave },
    title,
  } = props;

  return (
    <div style={{ padding: props.padding }}>
      <PLButton
        data-testid={testId}
        label={title ?? translations.globals.terms.cancel}
        type="secondary"
        onClick={() => setAndSave(true)}
        disabled={disabled}
        style={{ height: props.height, width: props.width }}
      >
        {disabled && <LoadingIndicator color="inherit" padding="0 0 0 1rem" size="2.5rem" />}
      </PLButton>
    </div>
  );
};

export default CancelButton;
