import { SelectItem } from "components/molecules/MultiSelectFreeSolo";
import TagsUpdateSidebar from "components/organisms/TagsUpdateSidebar";
import {
  assignedTagErrorAtom,
  freeSoloChipsListAtom,
  tagsToCreateAtom,
} from "components/organisms/TagsUpdateSidebar/tagsSidebarState";
import {
  GetDeviceTagsForTagsListQueryVariables,
  useGetDeviceTagsForTagsListQuery,
} from "graphqlBase/DeviceTag/__generated__/getDeviceTagsForTagsList";
import {
  GetMappingDeviceTagsQuery,
  ResultType,
  useGetMappingDeviceTagsQuery,
} from "graphqlBase/MappingDeviceTag/__generated__/getMappingDeviceTags";
import { GetTagsQueryVariables, useGetTagsQuery } from "graphqlBase/Tag/__generated__/getTags";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useCallback, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import translations from "translations";
import useCreateDeviceTags from "./hooks/useCreateDeviceTags";
import useDeleteDeviceTags from "./hooks/useDeleteDeviceTags";
import { selectedDevicesForTagsAtom } from "./tagModalState";

export const testIDs = getTestIDs();

export type MappingDeviceTag = ResultType<GetMappingDeviceTagsQuery["mappingDeviceTags"]>;

const TagModal: React.FC<{
  afterMutation: () => Promise<void>;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}> = ({ open = false, onClose, afterMutation, loading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deviceIds = useRecoilValue(selectedDevicesForTagsAtom).map((device) => device.id);

  const resetCreateDeviceTags = useResetRecoilState(tagsToCreateAtom);
  const resetFreeSoloChips = useResetRecoilState(freeSoloChipsListAtom);
  const resetAssignedTagError = useResetRecoilState(assignedTagErrorAtom);

  const variables: GetDeviceTagsForTagsListQueryVariables = {
    where: { deviceId: { in: deviceIds } },
  };

  const { data, loading: mappingDeviceTagsLoading, refetch: refetchTagsForLists } = useGetDeviceTagsForTagsListQuery({
    variables,
  });

  const tagVariables: GetTagsQueryVariables = {
    where: { mappingDeviceTag: { some: { isDeleted: { neq: true } } } },
  };

  const { data: deviceTagsData, loading: deviceTagsLoading, refetch: refetchTags } = useGetTagsQuery({
    variables: tagVariables,
  });

  const { data: mappingDeviceTagsData } = useGetMappingDeviceTagsQuery();

  const { createDeviceTagsLoading, handleCreateDeviceTags } = useCreateDeviceTags();
  const { deleteDeviceTagsLoading, handleDeleteDeviceTags } = useDeleteDeviceTags();

  const afterMutationRefetchForList = useCallback(async () => {
    if (refetchTagsForLists) await refetchTagsForLists();
    if (refetchTags) await refetchTags();
  }, [refetchTags, refetchTagsForLists]);

  const tagOptions =
    deviceTagsData?.tags.reduce<SelectItem[]>((tagOptions, current) => {
      return tagOptions.concat({ label: current.tagValue, value: current.id });
    }, []) ?? [];

  const handleAdd = async () => {
    setIsLoading(true);
    await handleCreateDeviceTags({
      mappingDeviceTags: mappingDeviceTagsData?.mappingDeviceTags ?? [],
    });
    await afterMutation();
    await afterMutationRefetchForList();
    resetFreeSoloChips();
    resetCreateDeviceTags();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleDelete = async (id: string) => {
    if (!mappingDeviceTagsData) return;
    setIsLoading(true);
    await handleDeleteDeviceTags({
      mappingDeviceTags: mappingDeviceTagsData?.mappingDeviceTags ?? [],
      id,
    });
    await afterMutation();
    await afterMutationRefetchForList();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const mappingDeviceTags = data?.devices.flatMap((item) => item.mappingDeviceTag);

  const deviceTags = mappingDeviceTags?.map((item) => item.tag);

  const title = `${translations.pages.deviceManagement.deviceTags.updateTags}`;

  const handleClose = () => {
    onClose();
    resetCreateDeviceTags();
    resetFreeSoloChips();
    resetAssignedTagError();
  };

  const sidebarLoading =
    createDeviceTagsLoading ||
    deleteDeviceTagsLoading ||
    deviceTagsLoading ||
    loading ||
    isLoading ||
    mappingDeviceTagsLoading;

  return (
    <TagsUpdateSidebar
      itemTags={deviceTags ?? []}
      tagOptions={tagOptions}
      handleDelete={handleDelete}
      handleAdd={handleAdd}
      onClose={handleClose}
      onCancel={handleClose}
      onDone={handleClose}
      loading={sidebarLoading}
      open={open}
      title={title}
    />
  );
};

export default TagModal;
