export default {
  config: "Configuration",
  linkText: "Device Detail",
  configuration: {
    line: "line",
    saveConfig: "Are you sure to save the new configuration?",
  },
  configurationMultiple: {
    label: "Device configuration",
    noFirmware: "No firmware version found",
    notCompatible: "Following devices are not compatible with firmware version {0} of reference device {1}",
    firmwareVersion: "Firmware version",
  },
  details: "Details",
  deviceTags: {
    updateTags: "Update Device Tags",
  },
  graph: {
    ambient: "Ambient Measurements",
    battery: "Battery Status",
    label: "Graph",
    voltage: "Voltage",
  },
  info: {
    label: "Info",
    systemManagement: "System Management",
  },
  deviceDetails: {
    label: "Device Details",
    deviceDetailsTile: {
      label: "Basics",
    },
    lowBattery: "Low Battery",
    noSensorMeasurements: "There are no sensor measurements to show",
    deviceConfiguration: {
      label: "States Configuration",
      previousConfig: "Previous Configurations",
      configKeys: {
        accel_level_movt: "Threshold for Movement Detection",
        accel_level_shock: "Threshold for Shock Detection",
        battery_level_period: "Measurement Period for Battery Voltage",
        ble_scan_period: "Scan Period of near by Sensors via Bluetooth Low Energy (BLE) Advertising",
        command_request_period: "Request Period in which the Sensor is requesting Commands",
        data_xfer_period: "Transfer Period Of Measurement And System Data",
        environmental_sense_period: "Measurement Period of Enviromental Measurements (e.g.Temperature,Humidity)",
        gnss_movt_detect_offset: "Measurement Offset of Movement Detection",
        gnss_pos_period: "Measurement Period of GPS Position",
        state_timeout: "State Timeout",
      },
      configStateKeys: {
        STORAGE_SITE: "Storage Site",
        TRANSPORT: "Transport",
        TARGET_SITE: "Target Site",
        SETUP: "Setup",
        USE: "Use",
        REST: "Rest",
        MOVT: "Move",
      },
      newConfiguration: {
        message:
          "You are going to create a new configuration setup which will automatically become active once there is a connection between the device and the cloud. You’ll still be able to switch back to any of your previous setups.",
        modalTitle: "Pull new Configuration",
      },
      reuseConfiguration: {
        message:
          "You are going to switch the states configuration of this device to the setup from the time period between",
        modalTitle: "Reuse configuration",
      },
      defaultConfig: {
        modalTitle: "Default configuration",
        message: "You are going to switch the states configuration of this device to the default setup.",
        buttonLabel: "Restore Defaults",
      },
      overtakeConfig: {
        modalTitle: "Overtake configuration",
        tableModalButtonLabel: "Overtake",
        message: "You are going to apply the configuration setup of the ",
        buttonLabel: "Standardeinstellungen wiederherstellen",
        textLinkLabel: "Overtake a configuration from another device",
      },
      siteDefinitionRadius: {
        distance: "Reach distance (m)",
      },
      cloudMessage:
        "This setup will automatically become active once there is a connection between the device and the cloud.",
    },
    connectionsMonitoring: {
      noDataMessage: "no messages received",
    },
  },
  label: "Device Management",
  map: "Map",
  noDeviceNotification: "There are no notifications for these devices.",
  noDevicesMessage: "There are no devices to show.",
  firmwareUpdate: {
    availableActualisations: "Available actualisations",
    message:
      "The selected update will be automatically installed once there is a connection between the device and the cloud.",
    modalTitle: "Update firmware",
    firmwareSelect: {
      firmwareRevision: "Firmware revision",
      revisionInfo: "Revision info",
    },
    releaseInfo: {
      releaseInfo: "Release Info",
      firmwareVersion: "Firmware version",
      releaseDate: "Release Date",
      releaseData: "Release Data",
      releaseType: "Release Type",
    },
    unlockRelease: {
      unlockRelease: "Unlock Release",
      unlock: "Unlock",
    },
    deviceFirmwareDevicesTable: {
      utcTimeMeasured: "Last connection",
      geofence: "Geofence",
    },
  },
  myDevices: "My Devices",
  myDeviceGroups: "My Device Groups",
  noDeviceMessage: "There are no devices to show.",
  noAssetText: "No asset assignment",
  goToOpenAssignment: "Go To Open Assignmnent",
};
