import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import * as L from "leaflet";
import { StorageArea } from ".";

const SetBoundsRectangles: React.FC<{ storageAreas: StorageArea[] }> = ({ storageAreas }) => {
  const mapSBR = useMap();
  const bounds = L.latLngBounds([]);

  useEffect(() => {
    setTimeout(() => mapSBR.invalidateSize(), 15);
    if (!storageAreas.length) return;
    storageAreas.forEach((storageArea) => {
      (storageArea.geofence?.coordinates ?? []).forEach((coord) => {
        if (coord) bounds.extend(coord as L.LatLngExpression | L.LatLngBoundsExpression);
      });
    });
    if (mapSBR.getZoom() === 0) {
      setTimeout(() => mapSBR.setView(bounds.getCenter()).fitBounds(bounds), 25);
      return;
    }
    setTimeout(() => mapSBR.fitBounds(bounds).getBounds().getCenter(), 50);
  }, [bounds, mapSBR, storageAreas]);
  return null;
};
export default SetBoundsRectangles;
