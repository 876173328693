import { Box, InputBase, Select, SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import PLIcon from "components/patternLib/PLIcon";
import PLPill from "components/patternLib/PLPill";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import translations from "translations";
import { SelectProps } from "../Select";
import { useFormStyles } from "../Select/utils";
import { useOtherStyles } from "./utils";

export const testIDs = getTestIDs();

export type SelectChangeEv = SelectChangeEvent<unknown>;

export interface MultiSelectProps extends SelectProps {
  handleDelete?: (option: string) => void;
  handleDeleteAll?: () => void;
  testId?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  options,
  selected,
  onChange,
  handleDelete,
  handleDeleteAll,
  iconSize,
  placeholder,
  label,
  children,
  disabled,
  testId,
  style,
  classes,
}) => {
  const labelId = `${id}-label`;
  const inputId = `${id}-input`;
  const chipsId = `${id}-chips`;

  const [inputWidth, setInputWidth] = React.useState(260);

  const inputElemWidth = document.getElementById(id)?.clientWidth;

  React.useEffect(() => {
    if (inputElemWidth) setInputWidth(inputElemWidth);
  }, [inputElemWidth]);

  const selectClasses = useFormStyles({ iconSize, style });
  const multiSelectClasses = useOtherStyles({ iconSize, style });

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: 0,
        width: inputWidth ?? 260,
        borderRadius: 0,
        cursor: "default",
        position: "relative",
      } as React.CSSProperties,
    },
    getContentAnchorEl: null,
  };

  const content = options?.map((option, index) => {
    return (
      <MenuItem disableTouchRipple value={option.value} key={index}>
        {option.label}
      </MenuItem>
    );
  });

  return (
    <FormControl disabled={disabled}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select
        classes={classes}
        className={selectClasses.root}
        data-testid={testId ?? testIDs.MultiSelect}
        disabled={disabled}
        labelId={labelId}
        id={id}
        multiple
        IconComponent={(props: any) => (
          <Box {...props}>
            <PLIcon size="24px" iconName="arrow-down" />
          </Box>
        )}
        displayEmpty
        value={selected ?? []}
        input={<InputBase data-testid={testIDs.MultiSelectInput} id={inputId} />}
        inputProps={{
          className: selectClasses.selectInput,
        }}
        renderValue={(selected) => {
          if ((selected as string[])?.length === 0) {
            return placeholder ?? translations.globals.placeholders.Dropdownfield;
          }

          return (
            <div className={multiSelectClasses.inputContent} data-testid="multi-select-input-content">
              <div id={chipsId} data-testid="multi-select-chips" className={multiSelectClasses.chips}>
                {(selected as string[])?.map((item, index) => (
                  <Box
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      handleDelete?.(item);
                    }}
                    sx={{ marginRight: "7px" }}
                    key={index}
                  >
                    <PLPill
                      height="30px"
                      label={options?.reduce<string>((renderLabel, { value, label }) => {
                        if (value === item) {
                          return label;
                        }
                        return renderLabel;
                      }, "")}
                      deleteIcon
                      key={index}
                      data-testid={testIDs.MultiSelectChip}
                    />
                  </Box>
                ))}
              </div>
              <Box
                sx={{ height: "fit-content", display: "flex", alignContent: "center", marginBottom: "-3px" }}
                data-testid="multi-select-delete-all-chips-icon"
                onMouseDown={(event) => {
                  event.stopPropagation();
                  !disabled && handleDeleteAll?.();
                }}
              >
                <PLIcon iconName="x" />
              </Box>
            </div>
          );
        }}
        onChange={onChange}
        MenuProps={MenuProps}
      >
        {options && content}
        {children}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
