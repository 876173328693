import { Grid, Typography } from "@mui/material";
import PLTextlink from "components/patternLib/PLTextlink";
import { isFuture } from "date-fns";
import { GetDeviceForDeviceDetailsQuery } from "graphqlBase/Devices/__generated__/getDeviceForDeviceDetails";
import React from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
interface Props {
  deviceDetailsData: GetDeviceForDeviceDetailsQuery | undefined;
  loading: boolean;
}
const AssetInfo: React.FC<Props> = ({ deviceDetailsData, loading }) => {
  const history = useHistory();
  const asset = deviceDetailsData?.device?.assetDevice?.[0]?.asset;
  return (
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item xs zeroMinWidth>
        <Typography
          noWrap
          sx={{ fontFamily: "var(--copytext-font-family-bold)", fontSize: "var(--copytext-font-size)" }}
        >
          {translations.entities.asset.name}
        </Typography>
      </Grid>
      <Grid item xs zeroMinWidth>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {(!deviceDetailsData?.device?.assetDevice ||
            !isFuture(new Date(deviceDetailsData?.device?.assetDevice?.[0]?.dateTo ?? "2020-01-01T00:04:52.000Z"))) &&
          !loading ? (
            <Typography
              noWrap
              sx={{
                fontFamily: "var(--copytext-font-family)",
                fontSize: "var(--copytext-font-size)",
                color: "#E52828",
              }}
            >
              {translations.pages.deviceManagement.noAssetText}
            </Typography>
          ) : asset && !asset.assetMasterData ? (
            <PLTextlink
              uppercase={false}
              bold={false}
              size="small"
              label={translations.pages.deviceManagement.goToOpenAssignment}
              onClick={() => {
                history.push(
                  `/assetManagement/openAssignments/${deviceDetailsData?.device?.assetDevice?.[0]?.asset.id}`
                );
              }}
            />
          ) : (
            <PLTextlink
              uppercase={false}
              bold={false}
              size="small"
              label={deviceDetailsData?.device?.assetDevice?.[0]?.asset.assetMasterData?.generalItem ?? ""}
              onClick={() => {
                history.push(`/assetManagement/assetDetails/${deviceDetailsData?.device?.assetDevice?.[0]?.asset.id}`);
              }}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default AssetInfo;
