import { styled } from "@mui/material";
import PLImage from "components/patternLib/PLImage";
import PLSteps from "components/patternLib/PLSteps";
import PLStepsItem from "components/patternLib/PLStepsItem";
import React, { useState } from "react";

export interface CarouselImage {
  alt: string;
  id: string;
  src: string;
}

export interface ImageCarouselProps {
  height?: string;
  images: Array<CarouselImage>;
  width?: string;
}

const ImagesBoxSC = styled("div")(
  () => `
      display: flex;
      flex-direction: column;
      width: 100%;
    `
);

const ImageContainerSC = styled("div")(
  ({ active }: { active: string }) => `
    display: ${active === "true" ? "flex" : "none"};
    width: 100%;
    justify-content: center;
    `
);

const CarouselContainerSC = styled("div")(
  () => `
    display: flex;
    flex-direction: row;
    margin: 1.5em 0;
    width: 100%;
    justify-content: center;
    `
);

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images, height, width }) => {
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <>
      <ImagesBoxSC>
        {images?.map((image, index) => {
          return (
            <ImageContainerSC active={String(currentStep === index)} key={image.src}>
              <PLImage
                object-fit="contain"
                alt={image.alt}
                height={height ?? "245px"}
                width={width ?? "100%"}
                src={image.src}
              />
            </ImageContainerSC>
          );
        })}
      </ImagesBoxSC>
      {images?.length > 1 ? (
        <PLSteps>
          <CarouselContainerSC>
            {images.map((image, index) => (
              <PLStepsItem key={image.src} onClick={() => setCurrentStep(index)} active={index === currentStep} />
            ))}
          </CarouselContainerSC>
        </PLSteps>
      ) : null}
    </>
  );
};
export default ImageCarousel;
