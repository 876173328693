import { Box, Typography, styled } from "@mui/material";
import PLAvatar from "components/patternLib/PLAvatar";
import PLButton from "components/patternLib/PLButton";
import PLContact from "components/patternLib/PLContact";
import PLIcon from "components/patternLib/PLIcon";
import PLImage from "components/patternLib/PLImage";
import { useGetAssetDetailsForGalleryQuery } from "graphqlBase/Assets/__generated__/getAssetDetailsForGallery";
import { useGetUserNameForAssetDetailsLazyQuery } from "graphqlBase/Assets/__generated__/getUserNameForAssetGallery";
import React, { useEffect, useState } from "react";
import translations from "translations";
import { formatDate } from "translations/formatter";
import { CarouselButtons, NoImagesBoxSC } from "./styles";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const ImageContainerSC = styled("div")(({ props }: { props: { counter: number; index: number } }) => {
  return {
    border: props.counter === props.index ? "4px solid #FFD000" : "",
    display: "flex",
    justifyContent: "center",
    width: "160px",
  };
});

interface AssetGalleryProps {
  assetId: string;
}
const fullScreenStyles = {
  backgroundColor: "white",
  height: "100%",
  display: "flex",
  alignItems: "center",
};
const AssetGallery: React.FC<AssetGalleryProps> = ({ assetId }) => {
  const handleFullScreen = useFullScreenHandle();
  const { active: isFullscreen, enter: enterFullScreen, exit: exitFullScreen } = handleFullScreen;
  const { data: assetDetails } = useGetAssetDetailsForGalleryQuery({ variables: { id: assetId } });
  const userId = assetDetails?.asset?.userId;
  const [query, { data: userData }] = useGetUserNameForAssetDetailsLazyQuery({ variables: { id: userId ?? "" } });
  const firstName = userData?.user?.firstName;
  const lastName = userData?.user?.lastName;

  const assetImagesNumber = assetDetails?.asset?.assetImage?.length ?? 0;
  const [counter, setCounter] = useState(0);

  const increase = () => {
    if (!!assetImagesNumber && counter < assetImagesNumber - 1) {
      setCounter((count) => count + 1);
    }
  };

  const decrease = () => {
    if (counter > 0) {
      setCounter((count) => count - 1);
    }
  };
  const assetImage = assetDetails?.asset?.assetImage;

  useEffect(() => {
    if (!userId) return;
    query();
  }, [userId]);

  return (
    <>
      <Box sx={{ margin: "0 30px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h3">
              {translations.pages.assetManagement.assetAssignment.assetIdentification} {assetId}
            </Typography>
            <CarouselButtons>
              <Box sx={{ marginRight: "10px" }}>
                <PLButton
                  disabled={assetImagesNumber <= 1 || (!!assetImagesNumber && counter < assetImagesNumber - 1)}
                  size="small"
                  width="40px"
                  onClick={decrease}
                >
                  <PLIcon iconName="font-arrow-left" size="24px" slot="icon" />
                </PLButton>
              </Box>
              <PLButton
                disabled={assetImagesNumber <= 1 || !(!!assetImagesNumber && counter < assetImagesNumber - 1)}
                size="small"
                width="40px"
                onClick={increase}
              >
                <PLIcon iconName="font-arrow" size="24px" slot="icon" />
              </PLButton>
            </CarouselButtons>
          </Box>
          <PLContact
            name={`${firstName ?? ""} ${lastName ?? ""}`}
            description={formatDate(new Date(assetDetails?.asset?.createdAt ?? new Date()))}
          >
            <PLAvatar slot="avatar" initials={`${firstName?.charAt(0) ?? ""}${lastName?.charAt(0) ?? ""}`} />
          </PLContact>

          <Typography variant="copyBold">{translations.globals.terms.description}</Typography>

          <Typography variant="copy">{assetDetails?.asset?.assetDevice[0].description}</Typography>
          <Typography variant="copyBold">Photos ({assetImagesNumber})</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", columnGap: "30px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "15px",
                width: "12%",
                maxHeight: "342px",
                overflowY: "auto",
              }}
            >
              {assetImage?.map((image, index) => {
                return (
                  <ImageContainerSC key={image.id} props={{ counter: counter, index: index }}>
                    <PLImage
                      objectFit="contain"
                      alt="assetImage"
                      src={image.pictureBlobStorageUrl}
                      height="auto"
                      width="160px"
                      onClick={() => setCounter(index)}
                    />
                  </ImageContainerSC>
                );
              })}
            </Box>
            <Box sx={{ backgroundColor: "#888E94", width: "45%", position: "relative" }}>
              {assetImage?.length ? (
                <FullScreen handle={handleFullScreen}>
                  {!!isFullscreen && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        background: "white",
                        padding: "10px 10px 0 0",
                      }}
                    >
                      <PLButton type="secondary" onClick={exitFullScreen}>
                        <PLIcon iconName="meeting-point" size="24px" slot="icon" onClick={exitFullScreen} />
                      </PLButton>
                    </Box>
                  )}
                  <Box sx={isFullscreen ? fullScreenStyles : {}}>
                    {!isFullscreen && (
                      <div
                        style={{
                          background: "white",
                          display: "inline-block",
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      >
                        <PLButton type="secondary" onClick={enterFullScreen}>
                          <PLIcon iconName="move" size="24px" slot="icon" />
                        </PLButton>
                      </div>
                    )}
                    <PLImage
                      objectFit="contain"
                      alt="assetImage"
                      src={assetImage?.[counter]?.pictureBlobStorageUrl ?? ""}
                      height={isFullscreen ? "80vh" : "300px"}
                      width="100%"
                    />
                  </Box>
                </FullScreen>
              ) : (
                <NoImagesBoxSC>
                  <Typography variant="copy">
                    {translations.pages.assetManagement.assetAssignment.imageGallery.noImagesMessage}
                  </Typography>
                </NoImagesBoxSC>
              )}
            </Box>
            <Box sx={{ width: "45%" }}>
              <Typography variant="copy">
                {assetImage?.[counter]?.description ??
                  translations.pages.assetManagement.assetAssignment.imageGallery.noImageDescriptionMessage}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AssetGallery;
