import { atom, selectorFamily } from "recoil";
import { QueryVariables } from "./useMakeServerSideTable";

interface TableQueryVariables {
  [key: string]: QueryVariables<any, any>;
}

const queryVariables = atom<TableQueryVariables>({
  key: "queryVariables",
  default: {},
});

const tableQueryVariables = selectorFamily<QueryVariables<any, any>, keyof TableQueryVariables>({
  key: "tableQueryVariables",
  get: (tableId) => ({ get }) => {
    return get(queryVariables)[tableId];
  },
  set: (tableId) => ({ set }, newValue) =>
    set(queryVariables, (prevState) => ({ ...prevState, [tableId]: { ...newValue } })),
});

export default tableQueryVariables;
