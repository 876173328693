import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTexttoggleItemProps = DropOnONLh<JSX.PatternlibTexttoggleItem>;
export type PLTexttoggleItemExtendedProps = PLTexttoggleItemProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTexttoggleItem = wrapWc<JSX.PatternlibTexttoggleItem>("patternlib-texttoggle-item");
export const useRefPLTexttoggleItem = () => useRef<HTMLPatternlibTexttoggleItemElement>(null);

const PLTexttoggleItem = React.forwardRef<HTMLPatternlibTexttoggleItemElement, PLTexttoggleItemExtendedProps>((props, ref) => {
  const myRef = useRefPLTexttoggleItem();
  return (
    <WrappedTexttoggleItem ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTexttoggleItem>
  );
});
PLTexttoggleItem.displayName = "PLTexttoggleItem";

export default PLTexttoggleItem;
