import React, { useMemo, useState } from "react";
import {
  GetDevicesForConfigurationOvertakeQuery,
  ResultType,
  useGetDevicesForConfigurationOvertakeLazyQuery,
} from "graphqlBase/Devices/__generated__/getDevicesForConfigurationOvertake";
import useDataGrid from "components/templates/dataGridTable";
import RadioCheckboxes from "components/templates/table-factory/Table/Cells/RadioCheckboxes";
import translations from "translations";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import TagChips from "components/molecules/TagChips";
import { Box, Typography } from "@mui/material";
import PLTextlink from "components/patternLib/PLTextlink";
import PLModal from "components/patternLib/PLModal";
import ModalConfirmationHandler from "./ModalConfirmationHandler";
type Device = ResultType<GetDevicesForConfigurationOvertakeQuery["devices"]>;

const OvertakeOtherDevicesConfig: React.FC<{ deviceId: string; deviceTypeName?: string }> = ({
  deviceId,
  deviceTypeName,
}) => {
  const variables = {
    where: {
      and: [
        { deviceType: { deviceTypeName: { eq: deviceTypeName } } },
        { mappingDeviceConfigurationDevice: { any: true } },
      ],
    },
  };
  const [checkedId, setCheckedId] = useState<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, { data, loading, error }] = useGetDevicesForConfigurationOvertakeLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const { DataGrid, useMakeColumns, makeSeverSideFilter, getRowCount } = useDataGrid<Device, "Device">({
    query,
    variables,
    tableId: "OvertakeOtherDevicesConfig",
    persistance: "runTime",
  });

  const mappedDevices:
    | { [key: string]: { config: string | undefined; deviceName: string } }
    | undefined = useMemo(() => {
    return data?.devices.reduce((acc, currDevice) => {
      return {
        ...acc,
        [currDevice.id]: {
          config: currDevice.mappingDeviceConfigurationDevice?.[0]?.deviceConfiguration.configurationJSON,
          deviceName: currDevice.deviceName,
        },
      };
    }, {});
  }, [data?.devices]);

  const columns = useMakeColumns(
    [
      {
        field: "radiobutton",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        maxWidth: 70,
        renderCell: ({ row }) => <RadioCheckboxes checkedId={checkedId} setCheckedId={setCheckedId} id={row.id} />,
        type: "string",
      },
      {
        field: "deviceName",
        headerName: "deviceName",
        renderHeader: (params) => `Device name (${getRowCount()})`,
        flex: 2,
        valueGetter: ({ row }) => {
          return row.deviceName ?? "";
        },
        remoteOrder: (sort) => ({ deviceName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ filterValue, where }) => ({ deviceName: filterValue }),
        }),
        type: "string",
      },
      {
        field: "firmware",
        headerName: "firmware",
        flex: 2,
        valueGetter: ({ row }) => {
          return row.mappingDeviceFirmwareDevice?.[0]?.deviceFirmware?.firmwareVersion ?? "";
        },
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 1.5,
        valueGetter: (params) => {
          return params.row.mappingDeviceTag?.map((entry) => entry.tag?.tagValue ?? "") ?? null;
        },
        renderCell: (params) => {
          return <TagChips mappingDeviceTag={params.row.mappingDeviceTag} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators(),
          filterPath: ({ where, filterValue }) => ({
            mappingDeviceTag: { some: { tag: { tagValue: filterValue } } },
          }),
        }),
        type: "stringArray",
      },
    ],
    []
  );

  return (
    <>
      <PLTextlink
        label={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.overtakeConfig.textLinkLabel}
        size="small"
        uppercase={false}
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
      <PLModal
        allowCloseOnBackdrop
        modalTitle={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.overtakeConfig.textLinkLabel}
        lhCancel={() => setIsModalOpen(false)}
        hideSubmit
        hideCancel
        height="80vh"
        width="60vw"
        lhClose={() => setIsModalOpen(false)}
        show={isModalOpen}
        iconName="settings"
      >
        <>
          <Box slot="modal-content">
            {!error && (
              <DataGrid
                noDataMessage=""
                tableTitle=""
                tableHeight="50vh"
                checkboxSelection={false}
                columns={columns}
                rows={data?.devices}
                loading={loading}
                withMargin={false}
              />
            )}
            <ModalConfirmationHandler
              otherDeviceId={checkedId}
              currentDeviceId={deviceId}
              otherDeviceNameAndConf={
                mappedDevices ? mappedDevices[checkedId as string] : { config: "", deviceName: "" }
              }
              setCloseTable={setIsModalOpen}
            />
          </Box>
        </>
      </PLModal>
    </>
  );
};

export default OvertakeOtherDevicesConfig;
