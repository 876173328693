import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetMappingDeviceFirmwaresDeviceTypesQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.MappingDeviceFirmwareDeviceTypeFilterInput>;
  order?: Types.InputMaybe<Array<Types.MappingDeviceFirmwareDeviceTypeSortInput> | Types.MappingDeviceFirmwareDeviceTypeSortInput>;
}>;


export type GetMappingDeviceFirmwaresDeviceTypesQuery = { mappingDeviceFirmwareDeviceTypes: Array<{ __typename?: 'MappingDeviceFirmwareDeviceType', id: string, deviceTypeId: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareRevision: string } }> };


export const GetMappingDeviceFirmwaresDeviceTypesDocument = gql`
    query getMappingDeviceFirmwaresDeviceTypes($skip: Int, $take: Int, $where: MappingDeviceFirmwareDeviceTypeFilterInput, $order: [MappingDeviceFirmwareDeviceTypeSortInput!]) {
  mappingDeviceFirmwareDeviceTypes(skip: $skip, take: $take, where: $where, order: $order) {
    id
    deviceTypeId
    deviceFirmware {
      id
      firmwareRevision
    }
  }
}
    `;

/**
 * __useGetMappingDeviceFirmwaresDeviceTypesQuery__
 *
 * To run a query within a React component, call `useGetMappingDeviceFirmwaresDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingDeviceFirmwaresDeviceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingDeviceFirmwaresDeviceTypesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetMappingDeviceFirmwaresDeviceTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMappingDeviceFirmwaresDeviceTypesQuery, GetMappingDeviceFirmwaresDeviceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMappingDeviceFirmwaresDeviceTypesQuery, GetMappingDeviceFirmwaresDeviceTypesQueryVariables>(GetMappingDeviceFirmwaresDeviceTypesDocument, options);
      }
export function useGetMappingDeviceFirmwaresDeviceTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMappingDeviceFirmwaresDeviceTypesQuery, GetMappingDeviceFirmwaresDeviceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMappingDeviceFirmwaresDeviceTypesQuery, GetMappingDeviceFirmwaresDeviceTypesQueryVariables>(GetMappingDeviceFirmwaresDeviceTypesDocument, options);
        }
export type GetMappingDeviceFirmwaresDeviceTypesQueryHookResult = ReturnType<typeof useGetMappingDeviceFirmwaresDeviceTypesQuery>;
export type GetMappingDeviceFirmwaresDeviceTypesLazyQueryHookResult = ReturnType<typeof useGetMappingDeviceFirmwaresDeviceTypesLazyQuery>;
export type GetMappingDeviceFirmwaresDeviceTypesQueryResult = Apollo.QueryResult<GetMappingDeviceFirmwaresDeviceTypesQuery, GetMappingDeviceFirmwaresDeviceTypesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
