import { Typography, styled } from "@mui/material";

export const AlertRuleRowSC = styled("div")(
  () => `
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 10px;
        width: fit-content;
        align-content: flex-start;
`
);

export const GeofenceWrapperSC = styled("div")(
  () => `
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        margin-right: 24px;
`
);

export const AlertValueWrapperSC = styled("div")(
  () => `
         justify-content: flex-start;
            width: fit-content;
            margin-left: 24px;
`
);

export const UnitAndDeleteRowButtonSC = styled("div")(
  ({ isfirstrow }: { isfirstrow?: boolean }) => `    
   display: flex;
   margin-left: 4px;
   margin-top: ${isfirstrow ? "35px" : "11px"};
   align-items: flex-start;
`
);

export const UnitTextSC = styled(Typography)(
  () => `    
  padding: 0 7px 0 2px;
`
);
