import PLSpinner from "components/patternLib/PLSpinner";
import React from "react";
import { OverlayContainerSC } from "../styles";
import { CircularProgress } from "@mui/material";


export const LoadingOverlay = () => {
  return (
    <OverlayContainerSC>
      <CircularProgress  />
    </OverlayContainerSC>
  );
};
export default LoadingOverlay;
