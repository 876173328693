import * as React from "react";
import { Theme as AugmentedTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Props from "./index.d";
// @ts-nocheck
const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    VerticalSimple: {
      // width: "100%",
      display: "flex",
      flexDirection: "column",
      // border: "1px solid blue",
      minWidth: 500,
    },
  })
);

// const styles = ({ overflowY: "overlay" } as any) as React.CSSProperties;

interface GFCFieldSet_LayoutVertical extends Props {
  children: React.ReactNode;
}

const FieldSetLayoutVertical: React.FC<GFCFieldSet_LayoutVertical> = (props) => {
  const {} = props;
  const classes = useStyles({});
  // console.log(props);
  // @ts-ignore
  if (props.hidden) return null;
  return <div className={classes.VerticalSimple}>{props.children}</div>;
};
export default FieldSetLayoutVertical;
