import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationDefinitionsOnNotificationQueryVariables = Types.Exact<{
  notificationId: Types.Scalars['UUID'];
}>;


export type GetNotificationDefinitionsOnNotificationQuery = { notificationDefinitions: Array<{ __typename?: 'NotificationDefinition', isRemoved?: Maybe<boolean>, id: string, notificationOperatorId: string, notificationId: string, value?: Maybe<string>, storageArea?: Maybe<{ __typename?: 'StorageArea', id: string, storageAreaName: string }>, notification: { __typename?: 'Notification', notificationScopeId: string, notificationScope: { __typename?: 'NotificationScope', id: string, scopeName?: Maybe<string> } } }> };


export const GetNotificationDefinitionsOnNotificationDocument = gql`
    query getNotificationDefinitionsOnNotification($notificationId: UUID!) {
  notificationDefinitions(where: {notificationId: {eq: $notificationId}, isRemoved: {neq: true}}) {
    isRemoved
    id
    notificationOperatorId
    notificationId
    value
    storageArea {
      id
      storageAreaName
    }
    notification {
      notificationScopeId
      notificationScope {
        id
        scopeName
      }
    }
  }
}
    `;

/**
 * __useGetNotificationDefinitionsOnNotificationQuery__
 *
 * To run a query within a React component, call `useGetNotificationDefinitionsOnNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationDefinitionsOnNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationDefinitionsOnNotificationQuery({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useGetNotificationDefinitionsOnNotificationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetNotificationDefinitionsOnNotificationQuery, GetNotificationDefinitionsOnNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNotificationDefinitionsOnNotificationQuery, GetNotificationDefinitionsOnNotificationQueryVariables>(GetNotificationDefinitionsOnNotificationDocument, options);
      }
export function useGetNotificationDefinitionsOnNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationDefinitionsOnNotificationQuery, GetNotificationDefinitionsOnNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNotificationDefinitionsOnNotificationQuery, GetNotificationDefinitionsOnNotificationQueryVariables>(GetNotificationDefinitionsOnNotificationDocument, options);
        }
export type GetNotificationDefinitionsOnNotificationQueryHookResult = ReturnType<typeof useGetNotificationDefinitionsOnNotificationQuery>;
export type GetNotificationDefinitionsOnNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationDefinitionsOnNotificationLazyQuery>;
export type GetNotificationDefinitionsOnNotificationQueryResult = Apollo.QueryResult<GetNotificationDefinitionsOnNotificationQuery, GetNotificationDefinitionsOnNotificationQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
