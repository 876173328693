import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

interface StyleProps {
  centerButtons?: boolean;
  shiftContentUp?: boolean;
  style?: React.CSSProperties;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootPaper: (props?: StyleProps) => ({
      padding: "2em",
      zIndex: 101,
      borderRadius: 0,
      margin: "auto",
      marginTop: props?.style?.marginTop ?? "35vh",
      width: "max-content",
      height: "max-content",
      "&:focus-visible": {
        outline: "none",
      },
    }),
    modalHeader: {
      position: "relative",
      paddingBottom: (props?: StyleProps) => (props?.shiftContentUp ? 0 : 24),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    modalHeaderLabel: {
      display: "flex",
      flexDirection: "row",
      margin: "0.5em 0 0 0.5em",
      alignItems: "center",
      "& svg": {
        width: "1.2em",
        height: "1.2em",
        color: theme.colors.tBlack,
        marginLeft: "0.4em",
      },
    },

    modalFooter: (props?: StyleProps) => ({
      padding: "0",
      "& div": {
        justifyContent: "space-between",
      },
      "& .MuiButtonBase-root:nth-child(2)": {
        marginLeft: props?.centerButtons ? "3em" : "1.5em",
      },
    }),
    modalContent: (props?: StyleProps) => ({
      overflowY: props?.style?.maxHeight ? "scroll" : "initial",
      maxHeight: props?.style?.maxHeight,
      height: props?.style?.height ?? "min-content",
      width: props?.style?.width ?? "640px",
      minWidth: props?.style?.minWidth,
      maxWidth: props?.style?.maxWidth,
      padding: "10px",
    }),
    closeButton: {
      display: "flex",
      marginTop: "16px",
      marginLeft: "11px",
      backgroundColor: "transparent",
      color: theme.colors.tBlack,
      "&:hover": { backgroundColor: "transparent" },
      "& svg": {
        height: "40px",
        width: "40px",
        margin: 7,
      },
      width: "4em",
      height: "4em",

      right: 0,
      top: 0,
    },
    closeIcon: {
      width: "24px",
      height: "26px",
    },
  })
);
