import L from "leaflet";
export default (coordinates: [number, number]) =>
  L.divIcon({
    iconSize: [0, 0],
    iconAnchor: [15, 30],
    html: `<svg width="30" height="30" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.3" cx="10" cy="10.009" r="10" fill="#2E8DE5"/>
    <circle cx="10" cy="10.009" r="6" fill="white"/>
    <circle cx="10" cy="10.009" r="5" fill="#2E8DE5"/>
</svg>`,
  });
