import GroupEditingLayout from "components/templates/QueryBuilder/GroupEditingLayout";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentDeviceTab } from "../../deviceTabsState";

export const testIDs = getTestIDs();

const DeviceGroupEditing: React.FC<{}> = () => {
  const history = useHistory();
  const setCurrentTab = useSetRecoilState(currentDeviceTab);
  const { deviceGroupId }: { deviceGroupId: string } = useParams();

  const seeAllDeviceGroups = () => {
    setCurrentTab(1);
    history.push("/deviceManagement");
  };

  const seeDeviceGroupDetails = () => {
    // history.push(`/deviceManagement/deviceGroupDetail/${groupId}`);
  };

  return (
    <GroupEditingLayout
      entityName="Device"
      handleGoToAllGroups={seeAllDeviceGroups}
      handleGoToGroup={seeDeviceGroupDetails}
      groupId={deviceGroupId}
    />
  );
};

export default DeviceGroupEditing;
