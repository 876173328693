import { Box, Divider, styled, Typography } from "@mui/material";
import DetailInfo from "components/atomics/DetailInfo";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import ImageCarousel, { CarouselImage } from "components/molecules/ImageCarousel";
import { RightTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import { getBatteryHealthInfo } from "components/pages/utils";
import PLStatusPill, { PLStatusPillProps } from "components/patternLib/PLStatusPill";
import {
  useGetAssetMasterDataForAssetDetailsViewQuery,
  GetAssetMasterDataForAssetDetailsViewQuery,
} from "graphqlBase/AssetMasterData/__generated__/getAssetMasterDataForAssetDetailsView";
import React, { useCallback, useState } from "react";
import translations from "translations";
import { getAssetInfo, getLastConnected } from "./utils";
import PLTextlink from "components/patternLib/PLTextlink";
import { useHistory } from "react-router-dom";
import ImageGallery from "./ImageGallery";
import PhotoUploadButton from "./PhotoUploadButton";

export type Asset = GetAssetMasterDataForAssetDetailsViewQuery["asset"];
interface AssetMasterDataTileProps {
  assetId: string;
}
const titleBoxStyles = { marginBottom: "25px" };
const topListBoxStyles = { marginBottom: "15px" };

const dividerStyles = { margin: 0 };
const imageDividerStyles = { margin: "2em 0 3em 0" };

const DeviceInfoBoxSC = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  min-height: 105px;
`
);

const DeviceNameBoxSC = styled("div")(
  () => `
  display: flex;
  flex-direction: row;
  height: 100px;
  flex: 2;
  align-items: center;
`
);

const BatteryListItemSC = styled("div")(
  () => `
  display: flex;
  flex: 2;
  align-items: center;
`
);

const BatteryVoltageAndPillSC = styled("div")(
  () => `
  display: inline-flex;
  align-self: center;
  flex-wrap: wrap;
  flex: 1;
  margin-top: 5px;
`
);

const ListLabelSC = styled(Typography)(
  () => `
font-family: var(--copytext-font-family-bold);
font-size: var(--copytext-font-size);
flex: 1;
`
);
export interface AssetImageCarousel extends CarouselImage {
  createdAt: string;
}
export interface FileWithPath extends File {
  readonly path?: string;
}
const AssetMasterDataTile: React.FC<AssetMasterDataTileProps> = ({ assetId }) => {
  const { batteryVoltage: batteryLabel } = translations.entities.capability.capabilityNames;
  const history = useHistory();
  const { data, loading, refetch } = useGetAssetMasterDataForAssetDetailsViewQuery({
    variables: { id: assetId ?? "" },
  });
  const assetType = data?.asset?.assetSource?.assetSourceName;
  const isCranePart = assetType?.includes("CRANE");
  const images = data?.asset?.assetImage?.reduce<Array<AssetImageCarousel>>((images, current) => {
    if (current?.pictureBlobStorageUrl)
      return images.concat({
        alt: current?.description ?? "",
        src: current?.pictureBlobStorageUrl,
        id: current?.id,
        createdAt: current.createdAt,
      });

    return images;
  }, []);

  const batteryVoltage = data?.asset?.assetDevice?.[0]?.device?.sensorMeasurementCurrentValue?.find(
    (item) => item?.capability?.capabilityName === "Battery Voltage"
  )?.valueString;

  const batteryHealth = getBatteryHealthInfo(Number(batteryVoltage));
  const assetInfo = getAssetInfo(data?.asset);
  const lastConnectedInfo = getLastConnected(data?.asset);

  const afterMutation = useCallback(async () => {
    if (refetch) await refetch();
  }, [refetch]);

  return (
    <RightTileWrapperSC>
      <Box sx={titleBoxStyles}>
        <Typography variant="h4">
          {translations.pages.assetManagement.assetDetails.assetMasterDataTile.label}
        </Typography>
      </Box>
      <LoadingSpinner sx={{ height: "fit-content" }} loading={loading}>
        <>
          {!isCranePart && images?.length ? (
            <>
              <ImageCarousel images={images} />
              <Divider variant="middle" sx={imageDividerStyles} />
            </>
          ) : null}
          <Box sx={topListBoxStyles}>
            <DetailInfo noSeparator data={assetInfo.concat(lastConnectedInfo)} itemHeight="35px" />
            {isCranePart && images?.length ? <ImageGallery images={images} /> : null}
            {isCranePart && <PhotoUploadButton assetId={assetId} afterMutation={afterMutation} />}
          </Box>
          <Divider variant="middle" sx={dividerStyles} />
          <DeviceInfoBoxSC>
            <DeviceNameBoxSC>
              <ListLabelSC variant="copyBold">{translations.entities.device.name}</ListLabelSC>
              <Box sx={{ flex: 1 }}>
                {/*eslint-disable-next-line*/}
                <PLTextlink
                  label={data?.asset?.assetDevice?.[0]?.device?.deviceName ?? ""}
                  onClick={() =>
                    history.push(`/deviceManagement/deviceDetails/${data?.asset?.assetDevice?.[0]?.device?.id}`)
                  }
                />
              </Box>
            </DeviceNameBoxSC>
            {data?.asset && (
              <BatteryListItemSC>
                <ListLabelSC>{batteryLabel}</ListLabelSC>
                <BatteryVoltageAndPillSC>
                  <PLStatusPill {...(batteryHealth as PLStatusPillProps)} />
                </BatteryVoltageAndPillSC>
              </BatteryListItemSC>
            )}
          </DeviceInfoBoxSC>
        </>
      </LoadingSpinner>
    </RightTileWrapperSC>
  );
};
export default AssetMasterDataTile;
