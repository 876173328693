import { atom } from "recoil";
import { NotificationDefinition } from "./types";

export const notificationDefinitionsAtom = atom<NotificationDefinition[] | undefined>({
  key: "notificationDefinitionsAtom",
  default: undefined,
});

export const prevNotificationDefinitionsAtom = atom<NotificationDefinition[] | undefined>({
  key: "prevNotificationDefinitionsAtom",
  default: undefined,
});

export const isScopeConnectivityAtom = atom({
  key: "isScopeConnectivityAtom",
  default: false,
});
