import colors from "themes/colors";

export const muiChipOverrides = {
  deletable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: colors.tBlack,
    color: colors.tWhite,
    height: "1.5em",
    margin: "0.2em",
    padding: "0.5em",
    "&:focus": { backgroundColor: colors.tBlack },
  },
  disabled: {
    cursor: "not-allowed",
    backgroundColor: colors.tWhite,
    color: colors.grey400,
    border: `1px solid ${colors.lbcGrey}`,
  },
  label: {
    margin: "0.2em 0.2em",
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: "1em",
    fontWeight: 400,
  },
  deleteIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.tWhite,
    width: "1.2em",
    height: "1.2em",
    margin: 0,
    "& .Mui-disabled": {
      cursor: "not-allowed",
      color: colors.grey400,
    },
    "&:hover": { color: colors.tWhite },
  },
};
