import { Box } from "@mui/material";
import SelectFreeSolo, { SelectItem } from "components/molecules/SelectFreeSolo";
import MandatoryFieldMessage from "components/pages/AlertManagement/AlertEditing/MandatoryFieldMessage";
import {
  NotificationDefinition,
  hasFormErrorsAtom,
  notificationDefinitionsAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import React from "react";
import { SetterOrUpdater, useRecoilState, useRecoilValue } from "recoil";
import translations from "translations";
import setNotificationDefinition from "../../setNotificationDefinition";
import { selectedLocationAtomFamily } from "./locationScopeState";
import useManageLocationScopes from "./useManageLocationScopes";

const LocationScopeSelectAutocomplete: React.FC<{
  id: string | undefined;
  label: string | undefined;
  notificationDefinition: NotificationDefinition;
  setNotificationDefinitions: SetterOrUpdater<NotificationDefinition[]>;
}> = ({ label, id, notificationDefinition, setNotificationDefinitions }) => {
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedLocationAtomFamily(id));
  const { fetchStorageAreas, options, setUiOptions, uiOptions } = useManageLocationScopes();
  const formHasErrors = useRecoilValue(hasFormErrorsAtom);
  const notificationDefinitions = useRecoilValue(notificationDefinitionsAtom);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    if (!value || value.length < 3) return;
    fetchStorageAreas({ variables: { where: { storageAreaName: { contains: value } }, take: 8 } });
  };

  const handleSelectChange = (event: React.SyntheticEvent<Element, Event>, value: string | SelectItem | null) => {
    const stringValue = value === null ? null : typeof value === "string" ? value : value?.label;

    if (stringValue != null) {
      if (!options) return;

      const selectedOption = options.find((option) => option.label === stringValue);

      const optionsDeduped = options
        .concat(selectedOption ?? [])
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        })
        .splice(0, 7);
      setUiOptions(optionsDeduped);
    }

    setSelectedGeofence(stringValue);
    const optionToSet = options?.find((option) => option?.label === stringValue);

    setNotificationDefinition(
      {
        storageArea: {
          id: optionToSet?.value ?? "",
          storageAreaName: optionToSet?.label ?? "",
        },
      },
      setNotificationDefinitions,
      notificationDefinition
    );
  };

  const disabledOptions = (notificationDefinitions ?? []).reduce<string[]>((acc, curr) => {
    if (curr.storageArea?.storageAreaName && curr.storageArea.storageAreaName === selectedGeofence) return acc;
    if (curr?.storageArea?.storageAreaName) return acc.concat(curr?.storageArea?.storageAreaName);
    return acc;
  }, []);
  const showMandatoryMessage = !selectedGeofence && formHasErrors
  return (
    <Box>
      <SelectFreeSolo
        isStatusError={showMandatoryMessage}
        onChangeInput={handleInputChange}
        label={label}
        options={options ?? []}
        onChange={handleSelectChange}
        placeholder={translations.globals.placeholders.selectALocation}
        selected={selectedGeofence}
        disabledOptions={disabledOptions}
      />
      {showMandatoryMessage && <MandatoryFieldMessage />}
    </Box>
  );
};

export default LocationScopeSelectAutocomplete;
