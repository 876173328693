import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllDynamicGroupsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.GroupFilterInput>;
  order?: Types.InputMaybe<Array<Types.GroupSortInput> | Types.GroupSortInput>;
}>;


export type GetAllDynamicGroupsQuery = { groups: Array<{ __typename?: 'Group', id: string, groupName: string, groupDescription?: Maybe<string>, createdAt?: Maybe<string>, userId?: Maybe<string>, rAWQuery?: Maybe<string>, jSONQuery: string, groupEntity: { __typename?: 'GroupEntity', id: string, entityName: string, group: Array<{ __typename?: 'Group', id: string }> } }> };


export const GetAllDynamicGroupsDocument = gql`
    query getAllDynamicGroups($skip: Int, $take: Int, $where: GroupFilterInput, $order: [GroupSortInput!]) {
  groups(skip: $skip, take: $take, where: $where, order: $order) {
    id
    groupName
    groupDescription
    createdAt
    userId
    rAWQuery
    jSONQuery
    groupEntity {
      id
      entityName
      group {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAllDynamicGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllDynamicGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDynamicGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDynamicGroupsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAllDynamicGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllDynamicGroupsQuery, GetAllDynamicGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllDynamicGroupsQuery, GetAllDynamicGroupsQueryVariables>(GetAllDynamicGroupsDocument, options);
      }
export function useGetAllDynamicGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllDynamicGroupsQuery, GetAllDynamicGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllDynamicGroupsQuery, GetAllDynamicGroupsQueryVariables>(GetAllDynamicGroupsDocument, options);
        }
export type GetAllDynamicGroupsQueryHookResult = ReturnType<typeof useGetAllDynamicGroupsQuery>;
export type GetAllDynamicGroupsLazyQueryHookResult = ReturnType<typeof useGetAllDynamicGroupsLazyQuery>;
export type GetAllDynamicGroupsQueryResult = Apollo.QueryResult<GetAllDynamicGroupsQuery, GetAllDynamicGroupsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
