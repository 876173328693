import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesByDeviceNameQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
}>;


export type GetDevicesByDeviceNameQuery = { devices: Array<{ __typename?: 'Device', id: string, deviceName: string }> };


export const GetDevicesByDeviceNameDocument = gql`
    query getDevicesByDeviceName($where: DeviceFilterInput) {
  devices(where: $where) {
    id
    deviceName
  }
}
    `;

/**
 * __useGetDevicesByDeviceNameQuery__
 *
 * To run a query within a React component, call `useGetDevicesByDeviceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesByDeviceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesByDeviceNameQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDevicesByDeviceNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesByDeviceNameQuery, GetDevicesByDeviceNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesByDeviceNameQuery, GetDevicesByDeviceNameQueryVariables>(GetDevicesByDeviceNameDocument, options);
      }
export function useGetDevicesByDeviceNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesByDeviceNameQuery, GetDevicesByDeviceNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesByDeviceNameQuery, GetDevicesByDeviceNameQueryVariables>(GetDevicesByDeviceNameDocument, options);
        }
export type GetDevicesByDeviceNameQueryHookResult = ReturnType<typeof useGetDevicesByDeviceNameQuery>;
export type GetDevicesByDeviceNameLazyQueryHookResult = ReturnType<typeof useGetDevicesByDeviceNameLazyQuery>;
export type GetDevicesByDeviceNameQueryResult = Apollo.QueryResult<GetDevicesByDeviceNameQuery, GetDevicesByDeviceNameQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
