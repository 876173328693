import { BinaryFilter } from "@cubejs-client/core";
import { Box, Typography } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import GeofencesBarChart from "components/pages/AssetManagement/AssetDetails/DashboardUtilization/tiles/WorkloadOverviewTile/charts/bar/GeofencesBarChart";
import GeofencesTypeDoughnut from "components/pages/AssetManagement/AssetDetails/DashboardUtilization/tiles/WorkloadOverviewTile/charts/doughnuts/GeofencesTypeDoughnut";
import {
  filtersAtom,
  filtersSelector,
} from "components/pages/AssetManagement/AssetDetails/DashboardUtilization/tiles/WorkloadOverviewTile/charts/filtersState";
import {
  chartsLoadingState,
  noChartDataState,
} from "components/pages/AssetManagement/AssetDetails/DashboardUtilization/tiles/WorkloadOverviewTile/chartsState";
import {
  ChartsContainerSC,
  GraphBoxSC,
} from "components/pages/AssetManagement/AssetDetails/DashboardUtilization/tiles/WorkloadOverviewTile/styles";
import { LeftTileWrapperSC, NoDataContainerSC } from "components/pages/AssetManagement/tileStyles";
import PLTextlink from "components/patternLib/PLTextlink";
import { useGetAssetDevicesForAssetGroupWorkloadOverviewQuery } from "graphqlBase/AssetDevices/__generated__/getAssetDevicesForAssetGroupworkloadOverview";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import translations from "translations";

interface GroupWorkloadOverviewTileProps {
  queryBuilderResultIds?: string[];
}

const GroupWorkloadOverviewTile: React.FC<GroupWorkloadOverviewTileProps> = ({ queryBuilderResultIds }) => {
  const [isFiltersReseted, setIsFilterReseted] = useState(false);
  const [filters, setFilters] = useRecoilState(filtersSelector);
  const resetFilters = useResetRecoilState(filtersAtom);
  const [noChartData, setNoChartData] = useRecoilState(noChartDataState);
  const [chartsLoading, setChartLoading] = useRecoilState(chartsLoadingState);
  const variables = {
    where: { and: [{ deviceId: { in: queryBuilderResultIds }, asset: { assetMasterDataId: { neq: null } } }] },
  };

  const { data, loading } = useGetAssetDevicesForAssetGroupWorkloadOverviewQuery({
    variables,
  });

  const assetIds = data?.assetDevices.map((item) => item.asset.id);
  const filterByAssetId = {
    member: "FactUtilization.dimassetid",
    operator: "equals",
    values: assetIds,
  };
  useEffect(() => {
    setChartLoading(true);
    const timer = setTimeout(() => setChartLoading(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!chartsLoading && assetIds && assetIds?.length === 0) setNoChartData(true);
    if (!chartsLoading && !assetIds) setNoChartData(true);
  }, [assetIds?.length, chartsLoading]);

  useEffect(() => {
    if (!isFiltersReseted && filters.length) {
      resetFilters();
      setIsFilterReseted(true);
    }
  }, [isFiltersReseted]);

  useEffect(() => {
    if (!assetIds?.length) return;
    setFilters([filterByAssetId as BinaryFilter]);
  }, [assetIds?.length]);

  return (
    <LeftTileWrapperSC>
      <Typography variant="h4">
        {translations.pages.groups.assetGroup.assetGroupDetails.groupWorkloadTile.label}
      </Typography>
      {filters.length > 1 && (
        <PLTextlink
          onClick={() => {
            resetFilters();
            setFilters([filterByAssetId as BinaryFilter]);
          }}
          label={translations.pages.assetManagement.assetDetailGraphs.resetFilter}
          uppercase={false}
          bold={false}
          size="small"
          style={{ height: "fit-content" }}
        />
      )}
      {!chartsLoading && !loading && noChartData && (
        <NoDataContainerSC sx={{ height: "50vh" }}>
          <Typography variant="copy">
            {translations.pages.groups.assetGroup.assetGroupDetails.groupWorkloadTile.noDataMessage}
          </Typography>
        </NoDataContainerSC>
      )}
      <ChartsContainerSC sx={{ display: !noChartData || chartsLoading ? "flex" : "none" }}>
        <GraphBoxSC>
          <GeofencesTypeDoughnut groupLoading={loading || !assetIds?.length} />
        </GraphBoxSC>
        <GraphBoxSC>
          <GeofencesBarChart groupLoading={loading || !assetIds?.length} />
        </GraphBoxSC>
      </ChartsContainerSC>
    </LeftTileWrapperSC>
  );
};
export default GroupWorkloadOverviewTile;
