export default {
  name: "Asset",
  plural: "Assets",
  columns: {
    assetAttributeValue: "asset attribute value",
    assetDescription: "asset description",
    assetDevice: "asset device",
    assetImage: "asset image",
    assetName: "Asset Name",
    assetTypeAsset: "Asset Type",
    assetType: "Asset Type",
    buildDate: "Manufacturer Date",
    id: "ID",
    inverseParentAsset: "inverse parent asset",
    isDeleted: "is deleted",
    lMID: "lMID",
    parentAsset: "parent asset",
    parentAssetId: "parent asset ID",
    serialNo: "Serial No",
    tenantId: "tenant ID",
  },
};
