import { Box, Typography } from "@mui/material";
import PLModal from "components/patternLib/PLModal";
import PLTextlink from "components/patternLib/PLTextlink";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import translations from "translations";
import { defaultConfigurationJsonAtom, initialDeviceConfigurationAtom } from "./recoilState";
import useHandleSaveConfig from "./useHandleSaveConfig";

const RestoreDefaultConfig: React.FC<{ deviceId: string }> = ({ deviceId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const defaultConfiguration = useRecoilValue(defaultConfigurationJsonAtom);
  const initialDeviceConfiguration = useRecoilValue(initialDeviceConfigurationAtom);
  const handleSaveConfig = useHandleSaveConfig(deviceId);
  return (
    <>
      <PLTextlink
        label={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.defaultConfig.buttonLabel}
        size="small"
        uppercase={false}
        disabled={JSON.stringify(initialDeviceConfiguration) === defaultConfiguration}
        onClick={() => {
          JSON.stringify(initialDeviceConfiguration) !== defaultConfiguration && setIsModalOpen(true);
        }}
      />
      <PLModal
        showIcon
        allowCloseOnBackdrop
        modalTitle={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.defaultConfig.modalTitle}
        lhCancel={() => setIsModalOpen(false)}
        lhConfirm={async () => {
          await handleSaveConfig(defaultConfiguration);
          setIsModalOpen(false);
        }}
        lhClose={() => setIsModalOpen(false)}
        show={isModalOpen}
        iconName="filter-reset"
      >
        <>
          <Box slot="modal-content">
            <Typography variant="copy">
              {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.defaultConfig.message}

              {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.cloudMessage}
            </Typography>
          </Box>
        </>
      </PLModal>
    </>
  );
};

export default RestoreDefaultConfig;
