export default {
  label: "Settings",
  userManagement: {
    label: "User Management",
    assinRolesToUser: {
      label: "Roles To User Assignment",
    },
    updateRole: {
      label: "Update Role",
    },
    table: {
      label: {
        roles: "Roles",
        scopes: "Permissions",
        users: "Users",
      },
      column: {
        description: "Description",
        email: "Email",
        firstName: "First Name",
        lastName: "Last Name",
        name: "Name",
        role: "Roles of the User",
        tenant: "Tenant / Company",
        value: "Value",
        userRole: "User role name",
        roleDescription: "Role description",
      },
      button: {
        create: {
          role: "New role",
        },
      },
    },
    form: {
      label: {
        role: "Edit Role",
        user: "Edit User",
        deleteUserRole: "Delete user role",
        editRole: "edit role",
      },
      message: {
        deleteMessage: "Are you sure you want to continue?",
      },
      edit: {
        tabLabel: {
          editRole: "Change Role",
          assignScope: "Assign Permissions",
          assignUser: "Assign Users",
        },
        listLabel: {
          assigned: "Assigned",
          unassigned: "Unassigned",
        },
      },
    },
  },
};
