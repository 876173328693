export default {
  name: "asset attribute",
  plural: "assets attributes",
  columns: {
    assetAttributeValue: "asset attribute value",
    attributeDataType: "attribute data type",
    attributeName: "attribute name",
    id: "ID",
    isDeleted: "is deleted",
    mappingAttributeAssetTypeAttributeSet: "mapping attribute asset type attribute set",
    required: "required",
    tenantId: "tenant ID",
  },
};
