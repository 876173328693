import { createTheme, Theme } from "@mui/material/styles";
import colors from "./colors";

import overrides from "./overrides";

declare module "@mui/material/styles" {
  interface Theme {
    colors: typeof colors;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    colors?: typeof colors;
  }
}

const themeV4: Theme = createTheme(overrides, {
  palette: {
    primary: {
      // light: colors.lightblue,
      main: colors.brandYellow,
    },
    secondary: { main: colors.steelBlue },
    success: {
      main: colors.green,
    },
    warning: { main: colors.orange },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.tBlack,
    },
  },
  colors,
  typography: {
    htmlFontSize: 10,
    fontFamily: "Arial",
    h2: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "3.2rem",
      lineHeight: "1.5",
    },
    h5: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "1.6em",
      lineHeight: 2.4,
    },
    h4: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "5em",
      lineHeight: 2.4,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiIconButton: {
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});

export default themeV4;
