import { useDeleteMappingAssetTagsMutation } from "graphqlBase/MappingAssetTag/__generated__/deleteMappingAssetTags";
import { GetMappingAssetTagsDocument } from "graphqlBase/MappingAssetTag/__generated__/getMappingAssetTags";
import { useRecoilValue } from "recoil";
import { MappingAssetTag } from "..";
import { selectedAssetsForTagsAtom } from "../tagModalState";

const useDeleteAssetTags = () => {
  const assetIds = useRecoilValue(selectedAssetsForTagsAtom).map((asset) => asset.id);

  const [deleteMappingAssetTagsMutation, { loading: deleteAssetTagsLoading }] = useDeleteMappingAssetTagsMutation({
    refetchQueries: [{ query: GetMappingAssetTagsDocument }],
  });

  const handleDeleteAssetTags = async ({
    mappingAssetTags,
    id,
  }: {
    mappingAssetTags: MappingAssetTag[];
    id: string;
  }) => {
    const deleteAssetMappings = assetIds
      .map((currId) => {
        const index = mappingAssetTags.findIndex((entry) => entry.assetId === currId && entry.assetTagId === id);
        if (index !== -1) return mappingAssetTags[index].id;
      })
      .filter(Boolean);
    if (deleteAssetMappings.length > 0) {
      await deleteMappingAssetTagsMutation({ variables: { ids: deleteAssetMappings as string[] } });
    }
  };

  return {
    deleteAssetTagsLoading,
    handleDeleteAssetTags,
  };
};

export default useDeleteAssetTags;
