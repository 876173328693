import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceHeartbeatCurrentValuesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDeviceHeartbeatCurrentValuesQuery = { deviceHeartbeatCurrentValues: Array<{ __typename: 'DeviceHeartbeatCurrentValue', id: string, utcTimeMeasured: string, deviceId: string }> };


export const GetDeviceHeartbeatCurrentValuesDocument = gql`
    query getDeviceHeartbeatCurrentValues {
  deviceHeartbeatCurrentValues {
    id
    utcTimeMeasured
    deviceId
    __typename
  }
}
    `;

/**
 * __useGetDeviceHeartbeatCurrentValuesQuery__
 *
 * To run a query within a React component, call `useGetDeviceHeartbeatCurrentValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceHeartbeatCurrentValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceHeartbeatCurrentValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeviceHeartbeatCurrentValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceHeartbeatCurrentValuesQuery, GetDeviceHeartbeatCurrentValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceHeartbeatCurrentValuesQuery, GetDeviceHeartbeatCurrentValuesQueryVariables>(GetDeviceHeartbeatCurrentValuesDocument, options);
      }
export function useGetDeviceHeartbeatCurrentValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceHeartbeatCurrentValuesQuery, GetDeviceHeartbeatCurrentValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceHeartbeatCurrentValuesQuery, GetDeviceHeartbeatCurrentValuesQueryVariables>(GetDeviceHeartbeatCurrentValuesDocument, options);
        }
export type GetDeviceHeartbeatCurrentValuesQueryHookResult = ReturnType<typeof useGetDeviceHeartbeatCurrentValuesQuery>;
export type GetDeviceHeartbeatCurrentValuesLazyQueryHookResult = ReturnType<typeof useGetDeviceHeartbeatCurrentValuesLazyQuery>;
export type GetDeviceHeartbeatCurrentValuesQueryResult = Apollo.QueryResult<GetDeviceHeartbeatCurrentValuesQuery, GetDeviceHeartbeatCurrentValuesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
