export default {
  noPageFound: {
    title: "Not Found (404)",
    text: "The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved. ",
  },
  restrictedLink: {
    title: "This link is restricted.",
    text: "For more information, please contact your system administrator. ",
  },
  goBackLink: "Go back to home page. ",
};
