import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetsForAssetOverviewQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetSortInput> | Types.AssetSortInput>;
}>;


export type GetAssetsForAssetOverviewQuery = { assets: Array<{ __typename?: 'Asset', id: string, customUniqueIdentifier: string, assetMasterDataId?: Maybe<string>, assetSource?: Maybe<{ __typename?: 'AssetSource', id: string, assetSourceName: string }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, articleNumber?: Maybe<string>, generalItem?: Maybe<string>, itemConstructionNumber?: Maybe<string>, supplier?: Maybe<string>, weight?: Maybe<number>, serialNumber?: Maybe<string> }>, mappingAssetTag: Array<{ __typename?: 'MappingAssetTag', id: string, assetId: string, assetTagId: string, isDeleted?: Maybe<boolean>, assetTag: { __typename?: 'AssetTag', id: string, tagValue: string } }>, assetDevice: Array<{ __typename?: 'AssetDevice', id: string, createdAt?: Maybe<string>, deviceId: string, device: { __typename?: 'Device', id: string, serialNo: string, deviceName: string, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string }, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', deviceFirmware: { __typename?: 'DeviceFirmware', firmwareVersion: string } }>, lastMeasurement?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, utcTimeMeasured: string }>>>, sensorMeasurementCurrentValue?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', valueInt?: Maybe<number>, utcTimeMeasured: string }>>> } }> }> };


export const GetAssetsForAssetOverviewDocument = gql`
    query getAssetsForAssetOverview($where: AssetFilterInput, $take: Int, $skip: Int, $order: [AssetSortInput!]) {
  assets(where: $where, take: $take, skip: $skip, order: $order) {
    id
    customUniqueIdentifier
    assetMasterDataId
    assetSource {
      id
      assetSourceName
    }
    assetMasterData {
      id
      articleNumber
      articleNumber
      generalItem
      itemConstructionNumber
      supplier
      weight
      serialNumber
    }
    mappingAssetTag(where: {isDeleted: {neq: true}}) {
      id
      assetId
      assetTagId
      isDeleted
      assetTag {
        id
        tagValue
      }
    }
    assetDevice(where: {dateTo: {gte: "9999-12-31T23:59:59.999Z"}}) {
      id
      createdAt
      deviceId
      device {
        id
        serialNo
        deviceName
        deviceType {
          id
          deviceTypeName
        }
        mappingDeviceFirmwareDevice {
          deviceFirmware {
            firmwareVersion
          }
        }
        lastMeasurement: sensorMeasurementCurrentValue(order: {utcTimeMeasured: DESC}, take: 1) {
          id
          utcTimeMeasured
        }
        sensorMeasurementCurrentValue(where: {capabilityId: {eq: "5063BCD9-9E7B-42F6-84B4-EEAC931A2B1F"}}) {
          valueInt
          utcTimeMeasured
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssetsForAssetOverviewQuery__
 *
 * To run a query within a React component, call `useGetAssetsForAssetOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsForAssetOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsForAssetOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetsForAssetOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetsForAssetOverviewQuery, GetAssetsForAssetOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetsForAssetOverviewQuery, GetAssetsForAssetOverviewQueryVariables>(GetAssetsForAssetOverviewDocument, options);
      }
export function useGetAssetsForAssetOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetsForAssetOverviewQuery, GetAssetsForAssetOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetsForAssetOverviewQuery, GetAssetsForAssetOverviewQueryVariables>(GetAssetsForAssetOverviewDocument, options);
        }
export type GetAssetsForAssetOverviewQueryHookResult = ReturnType<typeof useGetAssetsForAssetOverviewQuery>;
export type GetAssetsForAssetOverviewLazyQueryHookResult = ReturnType<typeof useGetAssetsForAssetOverviewLazyQuery>;
export type GetAssetsForAssetOverviewQueryResult = Apollo.QueryResult<GetAssetsForAssetOverviewQuery, GetAssetsForAssetOverviewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
