import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TableSortLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TableHeaderComponentProps } from "../tableTypes";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import getTestIDs from "lib/utils/getTestIDs";
export const testIDs = getTestIDs();

interface WrapperProps {
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}

const TableHeadComponent: React.FC<TableHeaderComponentProps<object>> = ({
  config,
  tableInstance,
  tableProps,
  tableId,
}) => {
  const { headerGroups } = tableInstance;
  return (
    <TableHead {...tableProps?.head} data-testid={testIDs.TableHead}>
      {headerGroups.map((headerGroup, headerGroupIndex) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          {...tableProps?.headRow}
          key={`table-${tableId}-hg-${headerGroupIndex}`}
          data-testid={testIDs.TableHeadRow}
        >
          {headerGroup.headers.map((column, cIndex) => {
            const getSortByToggleProps = column.canSort
              ? column.getSortByToggleProps({
                  ...column.getSortByToggleProps(),
                  title: undefined,
                })
              : undefined;
            const { width, minWidth, maxWidth } = column;
            let Wrapper = (props: WrapperProps) => (
              <Typography data-testid={testIDs.TableHeadTitle}>{props.children}</Typography>
            );
            if (config.sorting === true && column.canSort) {
              Wrapper = (props) => (
                <TableSortLabel
                  IconComponent={ArrowDropDownIcon}
                  disabled={config.sorting !== true || !column.canSort}
                  active={column.isSorted && column.isSortedDesc !== undefined}
                  hideSortIcon={false}
                  direction={column.isSortedDesc ? "desc" : "asc"}
                  {...getSortByToggleProps}
                  data-testid={testIDs.TableSortLabel}
                  data-testid-sortOrder={column.isSortedDesc}
                >
                  <Typography data-testid={testIDs.TableHeadTitle}>{props.children}</Typography>
                </TableSortLabel>
              );
            }
            return (
              <TableCell
                {...column.getHeaderProps()}
                {...tableProps?.headCell}
                style={{
                  width,
                  minWidth,
                  maxWidth,
                  verticalAlign: "bottom",
                  ...tableProps?.headCell?.style,
                }}
                key={`table-${tableId}-hg-${headerGroupIndex}-c${cIndex}`}
                data-testid={testIDs.TableHeaderTableCell}
              >
                <Wrapper>{column.render("Header")}</Wrapper>
                {column.canFilter && (
                  <div
                    style={{ display: "flex", flex: 1, alignItems: "flex-end" }}
                    data-testid={testIDs.TableHeadFilter}
                  >
                    {column.render("Filter")}
                  </div>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
};

export default TableHeadComponent;
