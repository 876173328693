import { styled, SxProps, Theme } from "@mui/material";
import PLSpinner from "components/patternLib/PLSpinner";
import React from "react";
import { useIsShowingLoader } from "./useIsShowingLoader";
interface LoaderProps {
  hideChildrenOnLoad?: boolean;
  loading: boolean;
  testId?: string;
  sx?: SxProps<Theme>;
}

const LoadingContainerSC = styled("div")(
  () => `
    position: relative;
    width: 100%;
    height: 100%;
    `
);

const LoaderBoxSC = styled("div")(
  ({ hidechildrenonload }: { hidechildrenonload?: string }) => `
    top: calc(50% - 3vh);
    left: calc(50% - 1vw);
    position: ${hidechildrenonload === "true" ? "relative" : "absolute"};
    height: 100%;
    width: fit-content;
  `
);

const FadeOpacitySC = styled("div")(
  ({ props }: { props: LoaderProps }) => `
      opacity: ${props.loading ? (props.hideChildrenOnLoad ? 0 : 0.4) : 1};
      height: 100%;
      width: 100%;
    `
);

const ChildrenContainerSC = styled("div")(
  () => `
     position: relative;
      height: 100%;
      width: 100%;
    `
);

const LoadingSpinner: React.FC<LoaderProps> = (props) => {
  const { loading, hideChildrenOnLoad, children, testId, sx } = props;
  const isLoading = useIsShowingLoader(loading);

  return (
    <LoadingContainerSC sx={sx}>
      {isLoading && (
        <LoaderBoxSC hidechildrenonload={String(hideChildrenOnLoad)} sx={sx}>
          <PLSpinner spin data-testid={testId} />
        </LoaderBoxSC>
      )}
      <FadeOpacitySC props={props}>
        <ChildrenContainerSC>{!(hideChildrenOnLoad && isLoading) && children}</ChildrenContainerSC>
      </FadeOpacitySC>
    </LoadingContainerSC>
  );
};

export default LoadingSpinner;
