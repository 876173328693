import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTextinputProps = DropOnONLh<JSX.PatternlibTextinput>;
export type PLTextinputExtendedProps = PLTextinputProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTextinput = wrapWc<JSX.PatternlibTextinput>("patternlib-textinput");
export const useRefPLTextinput = () => useRef<HTMLPatternlibTextinputElement>(null);

const PLTextinput = React.forwardRef<HTMLPatternlibTextinputElement, PLTextinputExtendedProps>((props, ref) => {
  const myRef = useRefPLTextinput();
  return (
    <WrappedTextinput ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTextinput>
  );
});
PLTextinput.displayName = "PLTextinput";

export default PLTextinput;
