import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CheckAssetCustomUniqueIdentifierQueryVariables = Types.Exact<{
  customUniqueIdentifier: Types.Scalars['String'];
}>;


export type CheckAssetCustomUniqueIdentifierQuery = { assets: Array<{ __typename?: 'Asset', id: string }> };


export const CheckAssetCustomUniqueIdentifierDocument = gql`
    query checkAssetCustomUniqueIdentifier($customUniqueIdentifier: String!) {
  assets(where: {customUniqueIdentifier: {eq: $customUniqueIdentifier}}) {
    id
  }
}
    `;

/**
 * __useCheckAssetCustomUniqueIdentifierQuery__
 *
 * To run a query within a React component, call `useCheckAssetCustomUniqueIdentifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAssetCustomUniqueIdentifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAssetCustomUniqueIdentifierQuery({
 *   variables: {
 *      customUniqueIdentifier: // value for 'customUniqueIdentifier'
 *   },
 * });
 */
export function useCheckAssetCustomUniqueIdentifierQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CheckAssetCustomUniqueIdentifierQuery, CheckAssetCustomUniqueIdentifierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CheckAssetCustomUniqueIdentifierQuery, CheckAssetCustomUniqueIdentifierQueryVariables>(CheckAssetCustomUniqueIdentifierDocument, options);
      }
export function useCheckAssetCustomUniqueIdentifierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckAssetCustomUniqueIdentifierQuery, CheckAssetCustomUniqueIdentifierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CheckAssetCustomUniqueIdentifierQuery, CheckAssetCustomUniqueIdentifierQueryVariables>(CheckAssetCustomUniqueIdentifierDocument, options);
        }
export type CheckAssetCustomUniqueIdentifierQueryHookResult = ReturnType<typeof useCheckAssetCustomUniqueIdentifierQuery>;
export type CheckAssetCustomUniqueIdentifierLazyQueryHookResult = ReturnType<typeof useCheckAssetCustomUniqueIdentifierLazyQuery>;
export type CheckAssetCustomUniqueIdentifierQueryResult = Apollo.QueryResult<CheckAssetCustomUniqueIdentifierQuery, CheckAssetCustomUniqueIdentifierQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
