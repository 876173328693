import { useGetDeviceHeartbeatCurrentValuesQuery } from "graphqlBase/Devices/__generated__/getDeviceHeartbeatCurrentValues";
import { useMemo } from "react";

const useGetHeartbeatCurrentValuesByDeviceId = () => {
  const { data } = useGetDeviceHeartbeatCurrentValuesQuery();
  const heartbeatCurrentValueByDeviceId: { [key: string]: string } = useMemo(() => {
    return (
      data?.deviceHeartbeatCurrentValues.reduce<{ [key: string]: string }>((acc, cur) => {
        acc[cur.deviceId] = cur.utcTimeMeasured;
        return acc;
      }, {}) ?? {}
    );
  }, [data]);
  return { heartbeatCurrentValueByDeviceId };
};

export default useGetHeartbeatCurrentValuesByDeviceId;
