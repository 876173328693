import { LhChange } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { Typography } from "@mui/material";
import PLTextinput from "components/patternLib/form/PLTextinput";
import PLButton from "components/patternLib/PLButton";
import { useGetGroupEntityIdByNameLazyQuery } from "graphqlBase/GroupEntities/__generated__/getGroupEntityIdByName";
import { useGetDynamicGroupByIdLazyQuery } from "graphqlBase/Groups/__generated__/getDynamicGroupById";
import { GroupCreateType, GroupUpdateType } from "graphqlBase/types";
import React, { useEffect, useState } from "react";
import "react-awesome-query-builder/lib/css/styles.css";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import useUpdateGroups from "./hooks/useUpdateGroups";
import { entityIdAtom, groupDescriptionAtom, groupNameAtom, getGroupIdAfterSaveGroupAtom } from "../groupEditingState";
import AfterSaveModal from "./AfterSaveModal";
import {
  ButtonsContainerSC,
  ContainerSC,
  QueryBuilderContainerSC,
  QueryBuilderContentSC,
  QueryBuilderSC,
  QueryBuilderTitleSC,
  TitleBoxSC,
} from "../components/styles";
import useConfigQueryBuilder from "../useConfigQueryBuilder";
import usePersistGroups from "./hooks/usePersistGroups";
import TextArea from "components/atomics/TextArea";

const { entities, pages, globals } = translations;

interface GroupEditingLayoutProps {
  entityName: "AssetDevice" | "Device";
  groupId?: string;
  handleGoToAllGroups: () => void;
  handleGoToGroup: () => void;
  queryStringsReturnValue?: string;
}

const GroupEditingLayout: React.FC<GroupEditingLayoutProps> = ({
  entityName,
  queryStringsReturnValue,
  handleGoToAllGroups,
  handleGoToGroup,
  groupId,
}) => {
  const {
    queryStrings,
    executeQuery,
    executionResult,
    QueryBuilderComponent,
    clearQueryStates,
    setQueryStrings,
  } = useConfigQueryBuilder({ entityName, queryStringsReturnValue });

  const [entityIdQuery, { data: entityIdData }] = useGetGroupEntityIdByNameLazyQuery({
    variables: { where: { entityName: { eq: entityName } } },
  });

  const [groupQuery, { data: groupData, loading: groupLoading, refetch }] = useGetDynamicGroupByIdLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [groupName, setGroupName] = useRecoilState(groupNameAtom);
  const [groupDescription, setGroupDescription] = useRecoilState(groupDescriptionAtom);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [entityId, setEntityId] = useRecoilState(entityIdAtom);
  const setGroupIdAfterSaveGroup = useSetRecoilState(getGroupIdAfterSaveGroupAtom);

  const history = useHistory();

  const { handleAdd, loading: addLoading } = usePersistGroups();
  const { handleUpdate, loading: updateLoading } = useUpdateGroups();

  useEffect(() => {
    if (!groupId) entityIdQuery();
    if (groupId) groupQuery({ variables: { id: groupId } });
  }, [groupId]);

  useEffect(() => {
    if (!entityIdData || entityId) return;
    setEntityId(entityIdData?.groupEntities[0]?.id);
  }, [entityIdData, entityId]);

  useEffect(() => {
    if (!groupData?.group) return;
    const { groupName, groupDescription, rAWQuery, jSONQuery, groupEntityId } = groupData?.group;

    setGroupName(groupName);
    setGroupDescription(groupDescription ?? "");
    setEntityId(groupEntityId);
    setQueryStrings({ rAWQuery: rAWQuery ?? "", jSONQuery });
    setLoading(false);
  }, [groupData]);

  useEffect(() => {
    return () => clearGroupState();
  }, []);

  useEffect(() => {
    if (
      executionResult?.data &&
      groupName &&
      entityId &&
      !addLoading &&
      !updateLoading &&
      queryStrings?.rAWQuery &&
      queryStrings?.jSONQuery
    ) {
      const group = {
        groupName,
        groupDescription,
        rAWQuery: `${queryStrings?.rAWQuery}`,
        jSONQuery: `${queryStrings?.jSONQuery}`,
        groupEntityId: entityId,
      };

      if (!groupId) {
        handleAdd(group as GroupCreateType)
          .then(afterSuccessfulSave)
          .catch(afterErrorSave);
      }

      if (groupId) {
        const groupToUpdate: GroupUpdateType = {
          id: groupId,
          ...group,
        };
        handleUpdate(groupToUpdate).then(afterSuccessfulSave).catch(afterErrorSave);
      }
    } else {
      if (loading) {
        setLoading(false);
      }
    }
  }, [executionResult, queryStrings, entityIdData]);

  const clearGroupState = () => {
    clearQueryStates();
    setGroupName(undefined);
    setGroupDescription(undefined);
    setGroupIdAfterSaveGroup(undefined);
    setEntityId(undefined);
  };

  const afterSuccessfulSave = (groupId?: string) => {
    setGroupIdAfterSaveGroup(groupId);
    setLoading(false);
    if (refetch) refetch().catch((error) => console.error("error saving group: ", error.message));
  };

  const afterErrorSave = (error: any) => {
    clearGroupState();
    setLoading(false);
    console.error("error adding group", error.message);
  };

  const handleSaveChangesGroup = () => {
    if (groupName && queryStrings?.rAWQuery) {
      setLoading(true);
      executeQuery();
    }
  };

  const onNameChange = (e: CustomEvent<LhChange>) => {
    setGroupName(e.detail.value as string);
  };

  const onDescriptionChange = (e: CustomEvent<LhChange>) => {
    setGroupDescription(e.detail.value as string);
  };

  const saveText =
    translations.getLanguage() === "en"
      ? `${globals.button.save} ${entities.group.name}`
      : `${entities.group.name} ${globals.button.save}`;

  const title = groupId ? pages.groups.updateGroup : pages.groups.createGroup;

  return (
    <>
      <ContainerSC>
        <TitleBoxSC>
          <Typography variant="h1">{title}</Typography>
        </TitleBoxSC>
        <QueryBuilderContainerSC>
          <QueryBuilderContentSC>
            <PLTextinput value={groupName} lhChange={onNameChange} label={pages.groups.query.groupName} width="50%" />
            <QueryBuilderSC>
              <QueryBuilderTitleSC>{pages.groups.query.queryBuilder}</QueryBuilderTitleSC>
              <QueryBuilderComponent />
            </QueryBuilderSC>

            <TextArea
              value={groupDescription}
              lhChange={onDescriptionChange}
              label={pages.groups.query.groupDescription}
              isOptional
            />
          </QueryBuilderContentSC>

          <ButtonsContainerSC>
            <PLButton label={globals.button.cancel} type="secondary" onClick={() => history.goBack()} />
            <PLButton label={saveText} onClick={handleSaveChangesGroup} />
          </ButtonsContainerSC>
        </QueryBuilderContainerSC>
      </ContainerSC>
      <AfterSaveModal
        entityName={entityName}
        handleGoToAllGroups={handleGoToAllGroups}
        handleGoToGroup={handleGoToGroup}
        isEditing={!!groupId}
      />
    </>
  );
};

export default GroupEditingLayout;
