import { Typography } from "@mui/material";
import PLModal from "components/patternLib/PLModal";
import React from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { confirmDeleteState } from "./confirmDeleteState";

export const ConfirmDeleteModal = () => {
  const confirmDelete = useRecoilValue(confirmDeleteState);
  const resetConfirmDelete = useResetRecoilState(confirmDeleteState);

  if (confirmDelete) {
    return (
      <PLModal
        allowCloseOnBackdrop
        style={{ display: "block" }}
        confirmLabel="continue"
        modalTitle="Delete changes"
        iconName="triangle-with-exclamation-mark"
        show={confirmDelete?.show}
        showIcon
        lhConfirm={() => {
          confirmDelete?.onOk?.();
          resetConfirmDelete();
        }}
        lhCancel={() => resetConfirmDelete()}
        lhClose={() => resetConfirmDelete()}
      >
        <span slot="modal-content">
          <Typography variant="copy">
            All changes you’ve made won’t be saved. Are you sure you want to continue?
          </Typography>
        </span>
      </PLModal>
    );
  }

  return null;
};
