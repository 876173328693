import { atom, selector } from "recoil";
import { notificationDefinitionsAtom } from "./notificationDefinitionsState";
import { selectedUsersForAlertAtom } from "./selectedUsersForAlertState";
import { selectedAssetsForAlertAtom } from "./selectedAssetsForAlertState";
import { selectedDevicesForAlertAtom } from "./selectedDevicesForAlertState";

interface ResubmissionAndActiveValuesAtom {
  [key: string]: number;
}

export const hasAlertNameAtom = atom({
  key: "hasAlertNameAtom",
  default: false,
});

export const hasResubmissionAndActiveValuesAtom = atom<ResubmissionAndActiveValuesAtom>({
  key: "hasResubmissionAndActiveValuesAtom",
  default: {
    resubmissionValue: 0,
    isActive: 0,
  },
});

export const hasFormErrorsAtom = atom<boolean>({
  key: "showRequiredMessageAtom",
  default: false,
});

export const isTheFormValidSelector = selector({
  key: "isTheFormValidSelector",
  get: ({ get }) => {
    const hasAlertName = get(hasAlertNameAtom);
    const selectedUsersForAlert = get(selectedUsersForAlertAtom);
    const selectedAssetsForAlert = get(selectedAssetsForAlertAtom)?.filter((item) => !item?.toDelete);
    const selectedDevicesForAlert = get(selectedDevicesForAlertAtom)?.filter((item) => !item?.toDelete);
    const notificationsDefinitions = get(notificationDefinitionsAtom);
    const hasResubmissionAndActiveValues = get(hasResubmissionAndActiveValuesAtom);
    const hasDefinitionOperator = notificationsDefinitions?.filter((item) => item.notificationOperatorId);
    const hasDefinitionValue = notificationsDefinitions?.filter((item) => item.value);
    const validNotificationDefinitions = notificationsDefinitions?.filter((item) =>
      item.storageArea ? item.notificationOperatorId && item.storageArea.id : item.notificationOperatorId && item.value
    );
    return {
      hasAlertName: Number(hasAlertName),
      userCount: selectedUsersForAlert?.length,
      definitionRowCount: notificationsDefinitions?.length ?? 0,
      deviceCountFromAssets: selectedAssetsForAlert?.length,
      deviceCountFromDevices: selectedDevicesForAlert?.length,
      resubmissionValue: hasResubmissionAndActiveValues.resubmissionValue,
      isActive: hasResubmissionAndActiveValues.isActive,
      validDefinitionCount: validNotificationDefinitions?.length ?? 0,
      hasDefinitionOperator: hasDefinitionOperator?.length ?? 0,
      hasDefinitionValue: hasDefinitionValue?.length ?? 0,
    };
  },
});
