import colors from "../colors";

export const muiStepperOverrides = {
  root: {
    fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow sans-serif",
    fontSize: 0,
    color: colors.tBlack,
    backgroundColor: "transparent",
  },
  horizontal: {
    paddingTop: "1em",
    overflow: "hidden",
    paddingLeft: "0.3em",
  },
  vertical: {
    display: "grid",
    gridAutoFlow: "row",
    gridRowGap: 0,
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",
    gridTemplateAreas: "path",
    paddingLeft: "0.3em",
    width: "100%",
  },
};

export const muiStepOverrides = {
  root: {
    "&.MuiStep-completed": {
      "& .MuiStepContent-root": {
        borderLeftColor: colors.brandYellow,
      },
    },
  },
  horizontal: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  vertical: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignContent: "flex-start",
    marginLeft: "1.5em",
    justifyContent: "flex-start",
  },
};

export const muiStepButtonOverrides = {
  root: {
    pointerEvents: "all",
    cursor: "initial",
    padding: 0,
    margin: 0,
    "&.Mui-disabled": {
      pointerEvents: "all",
      cursor: "initial",
    },
  },
  vertical: {
    height: "fit-content",
    width: "2em",
    padding: 0,
    margin: 0,
    justifyContent: "center",
    alignItems: "flex-start",
  },
};

export const muiStepContentOverrides = {
  root: {
    paddingLeft: "3em",
    minHeight: "6em",
    marginTop: 0,
    borderLeft: "2px solid",
    fontSize: 16,
    marginLeft: "-0.92em",
    borderLeftColor: colors.lbcGrey,
  },
};

export const muiStepConnectorOverrides = {
  root: {
    "&.Mui-disabled": {
      cursor: "default",
    },
  },
  vertical: {
    padding: 0,
    "& $line": {
      borderLeft: `2px solid ${colors.lbcGrey}`,
    },
  },
  horizontal: {},
  active: {
    "& $line": {
      borderColor: colors.brandYellow,
    },
  },
  completed: {
    "& $line": {
      borderColor: colors.brandYellow,
    },
  },
  lineHorizontal: {
    borderTopWidth: 2,
    borderColor: colors.grey100,
  },
};

export const muiStepLabelOverrides = {
  root: {
    cursor: "pointer",
  },
  vertical: {},
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: colors.tBlack,
  },
  iconContainer: {
    paddingRight: 0,
  },
  labelContainer: {
    marginBottom: "0.5em",
  },
};
