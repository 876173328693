export default {
  name: "device model attribute set",
  plural: "device model attribute sets",
  columns: {
    deviceModel: "device model",
    deviceModelAttributeSetName: "device model attribute Set name",
    deviceModelId: "device model ID",
    id: "ID",
    isDeleted: "is deleted",
    mappingAttributeDeviceModelAttributeSet: "mapping attribute device model attribute Set",
    tenantId: "tenant ID",
  },
};
