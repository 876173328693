import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import React from "react";
import { useRecoilState } from "recoil";
import { isToggleMapButtonActiveAtom } from "../recoilBox";

const ToggleMapButton = () => {
  const [isButtonActive, setIsButtonActive] = useRecoilState(isToggleMapButtonActiveAtom);

  return (
    <div className="leaflet-top leaflet-left">
      <div className="leaflet-control leaflet-bar" style={{ border: "none", marginLeft: 0 }}>
        <PLButton onClick={() => setIsButtonActive(!isButtonActive)} width="42px">
          <PLIcon iconName={isButtonActive ? "arrow-right" : "arrow-left"} size="24px" slot="icon" />
        </PLButton>
      </div>
    </div>
  );
};

export default ToggleMapButton;
