const pathArray = [
  {
    from: "device",
    to: "assetAttribute",
    getFilterPath: (value: object): object => ({
      assetDevice: {
        asset: { assetAttributeValue: { some: { assetAttribute: value } } },
      },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: {
        asset: {
          assetAttributeValue: { assetAttribute: { [value]: "$inner$" } },
        },
      },
    }),
    queryPath: [
      "assetDevice",
      "asset",
      "assetAttributeValue",
      "assetAttribute",
    ],
  },
  {
    from: "device",
    to: "assetAttributeDataType",
    getFilterPath: (value: object): object => ({
      assetDevice: {
        asset: {
          assetAttributeValue: {
            some: { assetAttribute: { assetAttributeDataType: value } },
          },
        },
      },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: {
        asset: {
          assetAttributeValue: {
            assetAttribute: { assetAttributeDataType: { [value]: "$inner$" } },
          },
        },
      },
    }),
    queryPath: [
      "assetDevice",
      "asset",
      "assetAttributeValue",
      "assetAttribute",
      "assetAttributeDataType",
    ],
  },
  {
    from: "device",
    to: "assetAttributeUnit",
    getFilterPath: (value: object): object => ({
      assetDevice: {
        asset: {
          assetAttributeValue: {
            some: { assetAttribute: { assetAttributeUnit: value } },
          },
        },
      },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: {
        asset: {
          assetAttributeValue: {
            assetAttribute: { assetAttributeUnit: { [value]: "$inner$" } },
          },
        },
      },
    }),
    queryPath: [
      "assetDevice",
      "asset",
      "assetAttributeValue",
      "assetAttribute",
      "assetAttributeUnit",
    ],
  },
  {
    from: "device",
    to: "assetAttributeValue",
    getFilterPath: (value: object): object => ({
      assetDevice: { asset: { assetAttributeValue: { some: value } } },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: { asset: { assetAttributeValue: { [value]: "$inner$" } } },
    }),
    queryPath: ["assetDevice", "asset", "assetAttributeValue"],
  },
  {
    from: "device",
    to: "asset",
    getFilterPath: (value: object): object => ({
      assetDevice: { some: { asset: value } },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: { asset: { [value]: "$inner$" } },
    }),
    queryPath: ["assetDevice", "asset"],
  },
  {
    from: "device",
    to: "assetDevice",
    getFilterPath: (value: object): object => ({ assetDevice: value }),
    getQueryBody: (value: string): object => ({
      assetDevice: { [value]: "$inner$" },
    }),
    queryPath: ["assetDevice"],
  },
  {
    from: "device",
    to: "assetImage",
    getFilterPath: (value: object): object => ({
      assetDevice: { asset: { assetImage: { some: value } } },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: { asset: { assetImage: { [value]: "$inner$" } } },
    }),
    queryPath: ["assetDevice", "asset", "assetImage"],
  },
  {
    from: "device",
    to: "assetMasterData",
    getFilterPath: (value: object): object => ({
      assetDevice: { asset: { assetMasterData: value } },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: { asset: { assetMasterData: { [value]: "$inner$" } } },
    }),
    queryPath: ["assetDevice", "asset", "assetMasterData"],
  },
  {
    from: "device",
    to: "assetTypeAttributeSet",
    getFilterPath: (value: object): object => ({
      assetDevice: {
        asset: { assetType: { assetTypeAttributeSet: { some: value } } },
      },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: {
        asset: { assetType: { assetTypeAttributeSet: { [value]: "$inner$" } } },
      },
    }),
    queryPath: ["assetDevice", "asset", "assetType", "assetTypeAttributeSet"],
  },
  {
    from: "device",
    to: "assetType",
    getFilterPath: (value: object): object => ({
      assetDevice: { asset: { assetType: value } },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: { asset: { assetType: { [value]: "$inner$" } } },
    }),
    queryPath: ["assetDevice", "asset", "assetType"],
  },
  {
    from: "device",
    to: "capability",
    getFilterPath: (value: object): object => ({
      deviceCapability: { some: { capability: value } },
    }),
    getQueryBody: (value: string): object => ({
      deviceCapability: { capability: { [value]: "$inner$" } },
    }),
    queryPath: ["deviceCapability", "capability"],
  },
  {
    from: "device",
    to: "deviceAttribute",
    getFilterPath: (value: object): object => ({
      deviceAttributeValue: { some: { deviceAttribute: value } },
    }),
    getQueryBody: (value: string): object => ({
      deviceAttributeValue: { deviceAttribute: { [value]: "$inner$" } },
    }),
    queryPath: ["deviceAttributeValue", "deviceAttribute"],
  },
  {
    from: "device",
    to: "deviceAttributeValue",
    getFilterPath: (value: object): object => ({ deviceAttributeValue: value }),
    getQueryBody: (value: string): object => ({
      deviceAttributeValue: { [value]: "$inner$" },
    }),
    queryPath: ["deviceAttributeValue"],
  },
  {
    from: "device",
    to: "deviceCapability",
    getFilterPath: (value: object): object => ({ deviceCapability: value }),
    getQueryBody: (value: string): object => ({
      deviceCapability: { [value]: "$inner$" },
    }),
    queryPath: ["deviceCapability"],
  },
  {
    from: "device",
    to: "deviceModelAttributeSet",
    getFilterPath: (value: object): object => ({
      deviceType: {
        deviceModel: { some: { deviceModelAttributeSet: { some: value } } },
      },
    }),
    getQueryBody: (value: string): object => ({
      deviceType: {
        deviceModel: { deviceModelAttributeSet: { [value]: "$inner$" } },
      },
    }),
    queryPath: ["deviceType", "deviceModel", "deviceModelAttributeSet"],
  },
  {
    from: "device",
    to: "deviceModelCapability",
    getFilterPath: (value: object): object => ({
      deviceCapability: {
        capability: { deviceModelCapability: { some: value } },
      },
    }),
    getQueryBody: (value: string): object => ({
      deviceCapability: {
        capability: { deviceModelCapability: { [value]: "$inner$" } },
      },
    }),
    queryPath: ["deviceCapability", "capability", "deviceModelCapability"],
  },
  {
    from: "device",
    to: "deviceModel",
    getFilterPath: (value: object): object => ({
      deviceType: { deviceModel: { some: value } },
    }),
    getQueryBody: (value: string): object => ({
      deviceType: { deviceModel: { [value]: "$inner$" } },
    }),
    queryPath: ["deviceType", "deviceModel"],
  },
  {
    from: "device",
    to: "deviceModelVersion",
    getFilterPath: (value: object): object => ({
      deviceType: {
        deviceModel: { some: { deviceModelVersion: { some: value } } },
      },
    }),
    getQueryBody: (value: string): object => ({
      deviceType: {
        deviceModel: { deviceModelVersion: { [value]: "$inner$" } },
      },
    }),
    queryPath: ["deviceType", "deviceModel", "deviceModelVersion"],
  },
  {
    from: "device",
    to: "deviceNotification",
    getFilterPath: (value: object): object => ({ deviceNotification: value }),
    getQueryBody: (value: string): object => ({
      deviceNotification: { [value]: "$inner$" },
    }),
    queryPath: ["deviceNotification"],
  },
  {
    from: "device",
    to: "deviceType",
    getFilterPath: (value: object): object => ({ deviceType: value }),
    getQueryBody: (value: string): object => ({
      deviceType: { [value]: "$inner$" },
    }),
    queryPath: ["deviceType"],
  },
  {
    from: "device",
    to: "gatewayDeviceConnection",
    getFilterPath: (value: object): object => ({
      gatewayDeviceConnection: value,
    }),
    getQueryBody: (value: string): object => ({
      gatewayDeviceConnection: { [value]: "$inner$" },
    }),
    queryPath: ["gatewayDeviceConnection"],
  },
  {
    from: "device",
    to: "manufacturer",
    getFilterPath: (value: object): object => ({
      assetDevice: { asset: { assetType: { manufacturer: value } } },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: {
        asset: { assetType: { manufacturer: { [value]: "$inner$" } } },
      },
    }),
    queryPath: ["assetDevice", "asset", "assetType", "manufacturer"],
  },
  {
    from: "device",
    to: "mappingAttributeAssetTypeAttributeSet",
    getFilterPath: (value: object): object => ({
      assetDevice: {
        asset: {
          assetType: {
            assetTypeAttributeSet: {
              some: { mappingAttributeAssetTypeAttributeSet: { some: value } },
            },
          },
        },
      },
    }),
    getQueryBody: (value: string): object => ({
      assetDevice: {
        asset: {
          assetType: {
            assetTypeAttributeSet: {
              mappingAttributeAssetTypeAttributeSet: { [value]: "$inner$" },
            },
          },
        },
      },
    }),
    queryPath: [
      "assetDevice",
      "asset",
      "assetType",
      "assetTypeAttributeSet",
      "mappingAttributeAssetTypeAttributeSet",
    ],
  },
  {
    from: "device",
    to: "mappingAttributeDeviceModelAttributeSet",
    getFilterPath: (value: object): object => ({
      deviceAttributeValue: {
        deviceAttribute: {
          mappingAttributeDeviceModelAttributeSet: { some: value },
        },
      },
    }),
    getQueryBody: (value: string): object => ({
      deviceAttributeValue: {
        deviceAttribute: {
          mappingAttributeDeviceModelAttributeSet: { [value]: "$inner$" },
        },
      },
    }),
    queryPath: [
      "deviceAttributeValue",
      "deviceAttribute",
      "mappingAttributeDeviceModelAttributeSet",
    ],
  },
  {
    from: "device",
    to: "mappingDeviceNotification",
    getFilterPath: (value: object): object => ({
      mappingDeviceNotification: value,
    }),
    getQueryBody: (value: string): object => ({
      mappingDeviceNotification: { [value]: "$inner$" },
    }),
    queryPath: ["mappingDeviceNotification"],
  },
  {
    from: "device",
    to: "mappingDeviceTag",
    getFilterPath: (value: object): object => ({ mappingDeviceTag: value }),
    getQueryBody: (value: string): object => ({
      mappingDeviceTag: { [value]: "$inner$" },
    }),
    queryPath: ["mappingDeviceTag"],
  },
  {
    from: "device",
    to: "mappingNotificationScopeNotificationOperator",
    getFilterPath: (value: object): object => ({
      mappingDeviceNotification: {
        notification: {
          notificationScope: {
            mappingNotificationScopeNotificationOperator: { some: value },
          },
        },
      },
    }),
    getQueryBody: (value: string): object => ({
      mappingDeviceNotification: {
        notification: {
          notificationScope: {
            mappingNotificationScopeNotificationOperator: {
              [value]: "$inner$",
            },
          },
        },
      },
    }),
    queryPath: [
      "mappingDeviceNotification",
      "notification",
      "notificationScope",
      "mappingNotificationScopeNotificationOperator",
    ],
  },
  {
    from: "device",
    to: "notification",
    getFilterPath: (value: object): object => ({
      mappingDeviceNotification: { some: { notification: value } },
    }),
    getQueryBody: (value: string): object => ({
      mappingDeviceNotification: { notification: { [value]: "$inner$" } },
    }),
    queryPath: ["mappingDeviceNotification", "notification"],
  },
  {
    from: "device",
    to: "notificationDefinition",
    getFilterPath: (value: object): object => ({
      deviceNotification: { some: { notificationDefinition: value } },
    }),
    getQueryBody: (value: string): object => ({
      deviceNotification: { notificationDefinition: { [value]: "$inner$" } },
    }),
    queryPath: ["deviceNotification", "notificationDefinition"],
  },
  {
    from: "device",
    to: "notificationOperator",
    getFilterPath: (value: object): object => ({
      deviceNotification: {
        notificationDefinition: { notificationOperator: value },
      },
    }),
    getQueryBody: (value: string): object => ({
      deviceNotification: {
        notificationDefinition: {
          notificationOperator: { [value]: "$inner$" },
        },
      },
    }),
    queryPath: [
      "deviceNotification",
      "notificationDefinition",
      "notificationOperator",
    ],
  },
  {
    from: "device",
    to: "notificationScope",
    getFilterPath: (value: object): object => ({
      mappingDeviceNotification: { notification: { notificationScope: value } },
    }),
    getQueryBody: (value: string): object => ({
      mappingDeviceNotification: {
        notification: { notificationScope: { [value]: "$inner$" } },
      },
    }),
    queryPath: [
      "mappingDeviceNotification",
      "notification",
      "notificationScope",
    ],
  },
  {
    from: "device",
    to: "sensorMeasurement",
    getFilterPath: (value: object): object => ({ sensorMeasurement: value }),
    getQueryBody: (value: string): object => ({
      sensorMeasurement: { [value]: "$inner$" },
    }),
    queryPath: ["sensorMeasurement"],
  },
  {
    from: "device",
    to: "sensorMeasurementCurrentValue",
    getFilterPath: (value: object): object => ({
      sensorMeasurementCurrentValue: value,
    }),
    getQueryBody: (value: string): object => ({
      sensorMeasurementCurrentValue: { [value]: "$inner$" },
    }),
    queryPath: ["sensorMeasurementCurrentValue"],
  },
  {
    from: "device",
    to: "sensorMeasurementHistory",
    getFilterPath: (value: object): object => ({
      sensorMeasurementHistory: value,
    }),
    getQueryBody: (value: string): object => ({
      sensorMeasurementHistory: { [value]: "$inner$" },
    }),
    queryPath: ["sensorMeasurementHistory"],
  },
  {
    from: "device",
    to: "storageArea",
    getFilterPath: (value: object): object => ({
      deviceNotification: { notificationDefinition: { storageArea: value } },
    }),
    getQueryBody: (value: string): object => ({
      deviceNotification: {
        notificationDefinition: { storageArea: { [value]: "$inner$" } },
      },
    }),
    queryPath: ["deviceNotification", "notificationDefinition", "storageArea"],
  },
  {
    from: "device",
    to: "storageAreaType",
    getFilterPath: (value: object): object => ({
      deviceNotification: {
        notificationDefinition: { storageArea: { storageAreaType: value } },
      },
    }),
    getQueryBody: (value: string): object => ({
      deviceNotification: {
        notificationDefinition: {
          storageArea: { storageAreaType: { [value]: "$inner$" } },
        },
      },
    }),
    queryPath: [
      "deviceNotification",
      "notificationDefinition",
      "storageArea",
      "storageAreaType",
    ],
  },
  {
    from: "device",
    to: "tag",
    getFilterPath: (value: object): object => ({
      mappingDeviceTag: { some: { tag: value } },
    }),
    getQueryBody: (value: string): object => ({
      mappingDeviceTag: { tag: { [value]: "$inner$" } },
    }),
    queryPath: ["mappingDeviceTag", "tag"],
  },
  {
    from: "device",
    to: "unit",
    getFilterPath: (value: object): object => ({
      deviceCapability: { some: { unit: value } },
    }),
    getQueryBody: (value: string): object => ({
      deviceCapability: { unit: { [value]: "$inner$" } },
    }),
    queryPath: ["deviceCapability", "unit"],
  },
];

const firstToLowerCase = (string: string) =>
  string.charAt(0).toLowerCase() + string.slice(1);
const cleanIds = (func: (value: string) => object, entityNameInner: string) => (
  value: string
) => func(value === `${entityNameInner}Id` ? "id" : value);

export const getDynamicQuery = ({
  entityName,
  filterEntity,
}: {
  entityName: string;
  filterEntity: string;
}) => {
  const entityNameInner = firstToLowerCase(entityName);
  const filterEntityInner = firstToLowerCase(filterEntity);
  if (entityNameInner === filterEntityInner) {
    return {
      getFilterPath: (value: object): object => value,
      getQueryBody: cleanIds((value: string): object => {
        return { [value]: "$inner$" };
      }, entityNameInner),
    };
  }
  const { getFilterPath, getQueryBody } = pathArray.find(
    ({ from, to }) => from === entityNameInner && to === filterEntityInner
  ) ?? { getFilterPath: () => ({}), getQueryBody: () => ({}) };
  return {
    getFilterPath,
    getQueryBody: cleanIds(getQueryBody, entityNameInner),
  };
};
