import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useRecoilValue } from "recoil";
import { PositionHistoryMarkerProps } from "./index.d";
import { durationOptionForPositionHistoryAtom } from "./recoilState";
const RemoveLayers: React.FC<Pick<PositionHistoryMarkerProps, "lineDecoratorState">> = ({ lineDecoratorState }) => {
  const map = useMap();
  const buttonToggled = useRecoilValue(durationOptionForPositionHistoryAtom);
  useEffect(() => {
    if (lineDecoratorState) {
      map.removeLayer(lineDecoratorState.decoratorState);
      map.removeLayer(lineDecoratorState.lineState);
    }
  }, [buttonToggled]);
  return <></>;
};

export default RemoveLayers;
