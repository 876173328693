export default {
  name: "rule result type",
  plural: "rules results types",
  columns: {
    id: "ID",
    isDeleted: "is deleted",
    rule: "rule",
    ruleResultTypeDescription: "rule result type description",
    ruleResultTypeName: "rule result type name",
  },
};
