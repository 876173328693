export default {
  name: "mapping attribute device model attribute set",
  plural: "mapping attributes devices models attributes sets",
  columns: {
    deviceAttribute: "device attribute",
    deviceAttributeId: "device attribute ID",
    deviceModelAttributeSet: "device model attribute Set",
    deviceModelAttributeSetId: "device model attribute Set ID",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
  },
};
