import { MappingRoleUserCreateType } from "graphqlBase/types";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { useManageMappingRoleUsersMutation } from "graphqlBase/MappingRoleUsers/__generated__/manageMappingRoleUsers";
import { SelectUser, selectedUsersOnRoleState } from "../RecoilState/mappingUserRoleState";
import { GetUsersForRoleMappingDocument } from "graphqlBase/Settings/__generated__/getUsersForRoleMapping";

export const getCreatesDeletes = (data: SelectUser[]) => {
  const { creates, deletes } = Object.values(data).reduce<{
    creates: MappingRoleUserCreateType[];
    deletes: string[];
  }>(
    ({ creates, deletes }, mapping) => {
      if (!mapping.mappingId && !mapping.toDelete)
        return {
          creates: creates.concat({
            roleId: mapping.roleId,
            userId: mapping.userId,
          }),
          deletes,
        };
      if (mapping.mappingId && mapping.toDelete)
        return {
          creates,
          deletes: deletes.concat(mapping.mappingId),
        };
      return {
        creates,
        deletes,
      };
    },
    { creates: [], deletes: [] }
  );
  const hasChanges = !!(creates.length + deletes.length);
  return { creates, deletes, hasChanges };
};

export const useGetMappingsUsersToSave = () => {
  const [saveMappings] = useManageMappingRoleUsersMutation({
    refetchQueries: [{ query: GetUsersForRoleMappingDocument }],
  });
  const getData = useRecoilCallback(
    ({ snapshot }) => async () => await snapshot.getPromise(selectedUsersOnRoleState),
    []
  );
  return async () => {
    const data = await getData();
    const { creates, deletes } = getCreatesDeletes(data);

    return await saveMappings({ variables: { creates, deletes } });
  };
};

export const useHasSelectedUsersChanges = () => {
  const data = useRecoilValue(selectedUsersOnRoleState);
  const { hasChanges } = getCreatesDeletes(data);
  return hasChanges;
};
