import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import React, { useEffect } from "react";
import Props from "./index.d";
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        "& .MuiTabs-scroller": {
          "& .MuiTabs-indicator": {
            borderBottom: `4px solid ${theme.colors.brandYellow}`,
            display: "inherit",
          },
        },
        margin: (props: { margin?: string }) => props.margin ?? 0,
        width: "100%",
        "& .text": {
          display: "none",
        },
        "& .MuiPaper-root": {
          backgroundColor: "inherit",
        },
      },
    }),
  { name: "TabsBar" }
);

const TabsBar: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    items,
    title,
    disabled,
    centered,
    testId,
    indicatorColor,
    orientation,
    variant,
  } = props;

  const classes = useStyles(props);

  useEffect(() => {
    if (state == null || typeof state != "number") setAndSave(0);
  }, [state]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setAndSave(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        data-testid={testId}
        value={+state || 0}
        onChange={handleChange}
        centered={centered}
        disabled={disabled}
        indicatorColor={indicatorColor ?? "secondary"}
        variant={variant}
        orientation={orientation}
        aria-label="simple tabs example"
      >
        {items.map(({ label }, index) => {
          return <Tab label={label} data-testid={testId ? `${testId}Tab${index}` : undefined} />;
        })}
      </Tabs>
    </div>
  );
};
export default TabsBar;
