import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetMappingDeviceFirmwareDevicesTwoQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.MappingDeviceFirmwareDeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.MappingDeviceFirmwareDeviceSortInput> | Types.MappingDeviceFirmwareDeviceSortInput>;
}>;


export type GetMappingDeviceFirmwareDevicesTwoQuery = { mappingDeviceFirmwareDevices: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, deviceId: string, userId?: Maybe<string>, deviceFirmwareId: string, dateTo: string, dateFrom: string, mappingDeviceFirmwareDeviceStatus: { __typename?: 'MappingDeviceFirmwareDeviceStatus', id: string, statusName: string }, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareVersion: string } }> };


export const GetMappingDeviceFirmwareDevicesTwoDocument = gql`
    query getMappingDeviceFirmwareDevicesTwo($where: MappingDeviceFirmwareDeviceFilterInput, $take: Int, $skip: Int, $order: [MappingDeviceFirmwareDeviceSortInput!]) {
  mappingDeviceFirmwareDevices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    deviceId
    userId
    deviceFirmwareId
    dateTo
    dateFrom
    mappingDeviceFirmwareDeviceStatus {
      id
      statusName
    }
    deviceFirmware {
      id
      firmwareVersion
    }
  }
}
    `;

/**
 * __useGetMappingDeviceFirmwareDevicesTwoQuery__
 *
 * To run a query within a React component, call `useGetMappingDeviceFirmwareDevicesTwoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingDeviceFirmwareDevicesTwoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingDeviceFirmwareDevicesTwoQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetMappingDeviceFirmwareDevicesTwoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMappingDeviceFirmwareDevicesTwoQuery, GetMappingDeviceFirmwareDevicesTwoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMappingDeviceFirmwareDevicesTwoQuery, GetMappingDeviceFirmwareDevicesTwoQueryVariables>(GetMappingDeviceFirmwareDevicesTwoDocument, options);
      }
export function useGetMappingDeviceFirmwareDevicesTwoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMappingDeviceFirmwareDevicesTwoQuery, GetMappingDeviceFirmwareDevicesTwoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMappingDeviceFirmwareDevicesTwoQuery, GetMappingDeviceFirmwareDevicesTwoQueryVariables>(GetMappingDeviceFirmwareDevicesTwoDocument, options);
        }
export type GetMappingDeviceFirmwareDevicesTwoQueryHookResult = ReturnType<typeof useGetMappingDeviceFirmwareDevicesTwoQuery>;
export type GetMappingDeviceFirmwareDevicesTwoLazyQueryHookResult = ReturnType<typeof useGetMappingDeviceFirmwareDevicesTwoLazyQuery>;
export type GetMappingDeviceFirmwareDevicesTwoQueryResult = Apollo.QueryResult<GetMappingDeviceFirmwareDevicesTwoQuery, GetMappingDeviceFirmwareDevicesTwoQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
