import { Box } from "@mui/material";
import Breadcrumb from "components/molecules/Breadcrumb";
import Header from "components/organisms/Header";
import Navigator from "components/organisms/Navigator";
import ErrorBoundary from "globalErrorhandling/ErrorBoundary";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Route } from "routes/routes";
import theme from "themes/theme";
import { routesForBreadcrumbAtom } from "./breadcrumbRecoilState";
import {
  BreadcrumbBoxSC,
  mainContainerStyles,
  NavigationContainerSC,
  HeaderContainerSC,
  RootWrapperSC,
  containerStyles,
} from "./styles";

const sideBarWidth = { open: "300px", closed: "80px" };

export interface MainLayoutProps {
  noElevation?: boolean;
  noPadding?: boolean;
  open?: boolean;
  translatedRoutes: Route[];
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const { translatedRoutes } = props;
  const [open, setOpen] = useState<boolean>(false);
  const breadcrumbRoutes = useRecoilValue(routesForBreadcrumbAtom);

  return (
    <RootWrapperSC theme={theme}>
      <HeaderContainerSC>
        <Header />
      </HeaderContainerSC>
      <NavigationContainerSC>
        <Navigator
          collapsed={!open}
          width={open ? sideBarWidth.open : sideBarWidth.closed}
          lhCollapse={() => setOpen(!open)}
          routes={translatedRoutes}
        />
      </NavigationContainerSC>
      <Box sx={containerStyles({ sideBarWidth, open })}>
        <BreadcrumbBoxSC>
          <Breadcrumb routes={breadcrumbRoutes} />
        </BreadcrumbBoxSC>
        <Box sx={mainContainerStyles}>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </Box>
      </Box>
    </RootWrapperSC>
  );
};

export default MainLayout;
