import * as React from "react";
import { Theme as AugmentedTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Props from "./index.d";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    NoSideBar: {
      width: "100%",
      // minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#fff",
      overflow: "hidden",
      height: "100vh",
      position: "absolute",
      // overflowY: "scroll",
      // overflowX: "hidden"
    },
  })
);

interface GFCFieldSet_NoSideBar extends Props {
  children: React.ReactNode;
}

const FieldSetNoSideBar: React.FC<GFCFieldSet_NoSideBar> = (props) => {
  const classes = useStyles({});
  return <div className={classes.NoSideBar}>{props.children}</div>;
};
export default FieldSetNoSideBar;
