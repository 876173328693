import { PartialDeep } from "graphqlBase/types";
import { Result, Delta } from "../types";

export default function loadMutationVariables<R extends Result>({
  change,
  row,
  delta = {},
}: {
  change: PartialDeep<R>;
  row: R;
  delta?: Delta;
}) {
  Object.entries(change).forEach(([keyP, val]) => {
    const key = keyP as keyof R;
    const r = (row[key] as unknown) as Result;
    if (typeof val === "object" && r.__typename) {
      loadMutationVariables({
        change: change[key] ?? {},
        row: r,
        delta,
      });
    } else {
      const entity = delta[row.__typename] ?? {};
      delta[row.__typename] = {
        ...entity,
        [row.id]: {
          ...entity[row.id],
          [key]: val,
        },
      };
    }
  });
}
