/* eslint-disable react/display-name */
import AlertManagement from "components/pages/AlertManagement";
import AlertEditing from "components/pages/AlertManagement/AlertEditing";
import AssetManagement from "components/pages/AssetManagement";
import AssetDetails from "components/pages/AssetManagement/AssetDetails";
import AssetCreate from "components/pages/AssetManagement/AssetCreate";
import AssetGroupDetails from "components/pages/AssetManagement/AssetGroups/AssetGroupDetails";
import AssetGroupEditing from "components/pages/AssetManagement/AssetGroups/AssetGroupEditing";
import DeviceManagement from "components/pages/DeviceManagement";
import DeviceDetails from "components/pages/DeviceManagement/DeviceDetails";
import DeviceGroupEditing from "components/pages/DeviceManagement/DeviceGroups/DeviceGroupEditing";
import Home from "components/pages/Home";
import MapAndTable from "components/pages/MapAndTable";
import AddGeofence from "components/pages/MapAndTable/manageGeofences/AddGeofence";
import UpdateGeofence from "components/pages/MapAndTable/manageGeofences/UpdateGeofence";
import Settings from "components/pages/Settings";
import UserManagement from "components/pages/Settings/UserManagement";
import MappingRolesUserUpdate from "components/pages/Settings/UserManagement/MappingRolesUserUpdate";
import UpdateRole from "components/pages/Settings/UserManagement/UpdateRole";
import React from "react";
import translations from "translations";
import Configurator from "../raft/configurator";
import UserRolesManager from "components/pages/Settings/UserManagement/UserRolesManager";
import CreateRole from "components/pages/Settings/UserManagement/CreateRole";
import WithTheme from "../themes/UserManagement/WithTheme";
import AssetAssignment from "components/pages/AssetManagement/OpenAssignments/AssetAssignment";
export interface Route {
  addBreadcrumb?: boolean;
  component: React.ComponentType<unknown> | null;
  exact: boolean;
  iconName?: string;
  label: string;
  mainLayout?: boolean;
  menuItem?: boolean;
  noMainLayoutPadding?: boolean;
  path: string;
  scope: string;
  subRoutes?: Route[];
}
const { new: newTerm } = translations.globals.terms;
const { name: role } = translations.entities.role;

const routes = (): Route[] => {
  const routesArray: Route[] = [
    {
      path: "/",
      component: Home,
      exact: true,
      label: "",
      menuItem: false,
      mainLayout: true,
      scope: "Home",
    },
    {
      path: "/assetManagement",
      component: AssetManagement,
      exact: true,
      label: translations.pages.assetManagement.label,
      menuItem: true,
      mainLayout: true,
      iconName: "products",
      scope: "AssetManagement",
      subRoutes: [
        {
          addBreadcrumb: false,
          path: "/createAssetGroup",
          component: AssetGroupEditing,
          exact: true,
          label: translations.pages.groups.createGroup,
          menuItem: false,
          mainLayout: true,
          scope: "CreateAssetGroup",
        },
        {
          addBreadcrumb: false,
          path: "/createAsset",
          component: AssetCreate,
          exact: true,
          label: translations.pages.assetManagement.createAsset,
          menuItem: false,
          mainLayout: true,
          scope: "CreateAssetGroup",
        },
        {
          addBreadcrumb: false,
          path: "/updateAssetGroup/:assetGroupId",
          component: AssetGroupEditing,
          exact: true,
          label: translations.pages.groups.updateGroup,
          menuItem: false,
          mainLayout: true,
          scope: "UpdateAssetGroup",
        },
        {
          addBreadcrumb: false,
          path: "/assetGroupDetail/:assetGroupId",
          component: AssetGroupDetails,
          exact: true,
          label: translations.pages.groups.assetGroup.assetGroupDetails.label,
          menuItem: false,
          mainLayout: true,
          scope: "AssetGroupDetail",
        },
        {
          addBreadcrumb: false,
          path: "/openAssignments/:assetId",
          component: AssetAssignment,
          exact: true,
          label: translations.pages.assetManagement.openAssignments,
          menuItem: false,
          mainLayout: true,
          scope: "AssetAssignmentTableUI",
        },

        {
          addBreadcrumb: false,
          path: "/assetDetails/:assetId",
          component: AssetDetails,
          exact: true,
          label: translations.pages.assetManagement.assetDetails.label,
          menuItem: false,
          mainLayout: true,
          scope: "AssetDetail",
        },
      ],
    },
    {
      path: "/fleetInventory",
      component: MapAndTable,
      exact: true,
      label: translations.pages.fleetInventory.label,
      menuItem: true,
      mainLayout: true,
      iconName: "map",
      scope: "FleetInventory",
      subRoutes: [
        {
          path: "/addGeoFence",
          component: AddGeofence,
          exact: true,
          label: translations.pages.fleetInventory.addGeoFence.label,
          iconName: "plus",
          menuItem: false,
          mainLayout: true,
          noMainLayoutPadding: true,
          scope: "CreateGeofence",
        },
        {
          path: "/editGeoFence/:storageAreaId",
          component: UpdateGeofence,
          exact: true,
          label: translations.pages.fleetInventory.addGeoFence.label,
          menuItem: false,
          mainLayout: true,
          noMainLayoutPadding: true,
          scope: "UpdateGeofence",
        },
      ],
    },
    {
      path: "/deviceManagement",
      component: DeviceManagement,
      exact: true,
      label: translations.pages.deviceManagement.label,
      menuItem: true,
      mainLayout: true,
      iconName: "rss-feed",
      scope: "DeviceManagement",
      subRoutes: [
        {
          path: "/deviceDetails/:deviceId",
          component: DeviceDetails,
          exact: true,
          label: translations.pages.deviceManagement.deviceDetails.label,
          menuItem: false,
          mainLayout: true,
          scope: "DeviceDetail",
        },
        {
          path: "/createDeviceGroup",
          component: DeviceGroupEditing,
          exact: true,
          label: translations.pages.groups.createGroup,
          mainLayout: true,
          menuItem: false,
          scope: "CreateDeviceGroup",
        },
        {
          path: "/updateDeviceGroup/:deviceGroupId",
          component: DeviceGroupEditing,
          exact: true,
          label: translations.pages.groups.updateGroup,
          mainLayout: true,
          menuItem: false,
          scope: "UpdateDeviceGroup",
        },
      ],
    },
    {
      path: "/alertManagement",
      component: AlertManagement,
      exact: true,
      label: translations.pages.alertManagement.label,
      menuItem: true,
      mainLayout: true,
      iconName: "my-notifier",
      scope: "AlertManagement",
      subRoutes: [
        {
          path: "/createAlert",
          component: AlertEditing,
          exact: true,
          label: translations.pages.alertManagement.createAlert,
          mainLayout: true,
          menuItem: false,
          iconName: "plus",
          scope: "CreateAlert",
        },
        {
          path: "/alertEditing/:notificationId",
          component: AlertEditing,
          exact: true,
          label: translations.pages.alertManagement.editAlert,
          mainLayout: true,
          scope: "UpdateAlert",
        },
      ],
    },
    {
      path: "/settings",
      component: Settings,
      exact: true,
      label: translations.pages.settings.label,
      menuItem: true,
      mainLayout: true,
      iconName: "gearwheel",
      scope: "Settings",
      subRoutes: [
        {
          path: "/userManagement",
          component: () => (
            <WithTheme>
              <UserManagement />
            </WithTheme>
          ),
          exact: true,
          label: translations.pages.userManagement.userAuthorization,
          mainLayout: true,
          menuItem: true,
          iconName: "user",
          scope: "UserAuthorization",
          subRoutes: [
            {
              path: "/assignRolesToUser/:userId",
              component: () => (
                <WithTheme>
                  <MappingRolesUserUpdate />
                </WithTheme>
              ),
              exact: true,
              label: translations.pages.settings.userManagement.assinRolesToUser.label,
              mainLayout: true,
              menuItem: false,
              scope: "Home",
            },
            {
              path: "/userRolesManager",
              // eslint-disable-next-line react/display-name
              component: () => (
                <WithTheme>
                  <UserRolesManager />
                </WithTheme>
              ),
              exact: true,
              label: translations.pages.userManagement.userRoleManager.label,
              mainLayout: true,
              menuItem: false,
              scope: "UserRolesManager",
              subRoutes: [
                {
                  path: "/updateRole/:roleId",
                  component: () => (
                    <WithTheme>
                      <UpdateRole />
                    </WithTheme>
                  ),
                  exact: true,
                  label: translations.pages.userManagement.form.label.role,
                  mainLayout: true,
                  menuItem: false,
                  scope: "UpdateRole",
                },
                {
                  path: "/newRole",
                  component: () => (
                    <WithTheme>
                      <CreateRole />
                    </WithTheme>
                  ),
                  exact: true,
                  label: `${newTerm} ${role}`,
                  mainLayout: true,
                  menuItem: false,
                  scope: "CreateRole",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/feedback",
      component: null,
      exact: true,
      label: translations.pages.feedback.placeholder.label,
      menuItem: false,
      mainLayout: true,
      scope: "Home",
    },
  ];
  if (process.env.NODE_ENV === "development")
    routesArray.push({
      path: "/configurator",
      component: Configurator,
      exact: false,
      iconName: "gearwheel",
      label: translations.pages.configurator.label,
      menuItem: true,
      mainLayout: false,
      scope: "Home",
    });

  if (process.env.NODE_ENV === "development")
    routesArray.push({
      path: "/signOut",
      component: Configurator,
      exact: false,
      label: "Sign out",
      iconName: "font-arrow",
      menuItem: true,
      mainLayout: false,
      scope: "Home",
    });
  return routesArray;
};

export default routes;
