import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { languageAtom } from "translations/recoilState";

let res: (value: string | PromiseLike<string>) => void;
let resolved = false;
const languagePromise = new Promise<string>((resolve, reject) => {
  res = resolve;
});

const injectTestLanguage = (val: string) => {
  if (process.env.NODE_ENV === "production") return;
  if (!resolved) {
    res(val);
    resolved = true;
  } else {
    //@ts-ignore
    window.language = Promise.resolve(val);
  }
};

//@ts-ignore
window.language = languagePromise;
type ReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>];
export const useLanguage = (): ReturnType<string | undefined> => {
  const [languageState, setLanguageState] = useRecoilState(languageAtom);

  //@ts-ignore
  window.setLanguage = (lang: string) => setLanguageState(lang);

  useEffect(() => {
    if (languageState) {
      try {
        translations.setLanguage(languageState);
        injectTestLanguage(languageState);
      } catch (err) {
        console.log(err);
      }
    }
  }, [languageState, setLanguageState]);

  return [languageState, setLanguageState];
};
