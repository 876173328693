import { assetDeviceApplicationState, lhbDeviceState } from "components/pages/AssetManagement/utils";
import translations from "translations";
import { formatTimeDistance } from "translations/formatter";
import { Asset } from ".";

export const getCurrentValueByCapabilityName = ({
  asset,
  capabilityName,
  isCranePart,
}: {
  asset: Asset | undefined;
  capabilityName: string;
  isCranePart?: boolean;
}) => {
  const currentValue = asset?.assetDevice?.[0]?.device?.sensorMeasurementCurrentValue?.find(
    (item) => item?.capability?.capabilityName === capabilityName
  );

  if (capabilityName === "Battery Voltage")
    return `${currentValue?.valueString ?? ""} ${
      currentValue?.capability?.deviceModelCapability?.[0]?.unit?.unitSymbol ?? ""
    }`;
  if (capabilityName === "App State") {
    let assetState;
    if (isCranePart) assetState = assetDeviceApplicationState[currentValue?.valueInt ?? -1];
    else assetState = lhbDeviceState[currentValue?.valueInt ?? -1];
    return `${assetState?.label ?? ""}${assetState?.extendedLabel ?? ""}`;
  }
};

export const getLastConnected = (asset: Asset | undefined) => {
  const lastConnected = asset?.assetDevice?.[0]?.device?.lastConnected?.[0]?.utcTimeMeasured;
  return [
    {
      label: translations.entities.capability.capabilityNames.utcTimeMeasured,
      value: lastConnected ? formatTimeDistance(new Date(lastConnected)) : "",
    },
  ];
};

export const getAssetInfo = (asset: Asset | undefined) => {
  const isAttachment = asset?.assetSource?.assetSourceName?.includes("ATTACHMENT");
  const isCranePart = asset?.assetSource?.assetSourceName?.includes("CRANE");

  const typeAttributeValue = asset?.assetAttributeValue?.find((att) => att?.assetAttribute?.attributeName === "Type")
    ?.value;

  const prodYearAttributeValue = asset?.assetAttributeValue?.find(
    (att) => att?.assetAttribute?.attributeName === "Year of Construction"
  )?.value;

  let info = [
    {
      label: translations.entities.assetMasterData.columns.itemManufacturer,
      value: asset?.assetMasterData?.supplier ?? "",
    },
    {
      label: isAttachment
        ? translations.entities.capability.capabilityNames.typeOfAttachment
        : translations.entities.assetMasterData.columns.articleNumber,
      value: isAttachment ? typeAttributeValue ?? "" : asset?.assetMasterData?.articleNumber ?? "",
    },
    {
      label: isAttachment
        ? translations.entities.capability.capabilityNames.model
        : translations.entities.assetMasterData.columns.itemConstructionNumber,
      value: isAttachment
        ? asset?.assetMasterData?.generalItem ?? ""
        : asset?.assetMasterData?.itemConstructionNumber ?? "",
    },
    {
      label: translations.entities.assetMasterData.columns.assetSerialNo,
      value: isAttachment ? asset?.customUniqueIdentifier ?? "" : asset?.assetMasterData?.serialNumber ?? "",
    },
  ];

  if (isAttachment)
    info = info.concat({
      label: translations.entities.capability.capabilityNames.yearOfProduction,
      value: prodYearAttributeValue ?? "",
    });

  return info.concat({
    label: translations.entities.capability.capabilityNames.appState,
    value: getCurrentValueByCapabilityName({ asset, isCranePart, capabilityName: "App State" }) ?? "",
  });
};
