const tableContainerStyles = {
  "&.MuiTableRow-root": {
    border: "1px solid var(--color-steel-300)",
  },
};
export const muiTableContainerOverrides = {
  root: {
    ...tableContainerStyles,
  },
};
