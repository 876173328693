import { styled } from "@mui/material";

export const autocompleteRootStyles = (isStatusError?: boolean) => {
  return {
    width: 280,
    height: 40,
    borderColor: "#000",
    marginTop: "8px",
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#000" },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 15px)",
      "& .MuiButtonBase-root:hover, .MuiButtonBase-root:active": { backgroundColor: "transparent" },
      "& .MuiAutocomplete-clearIndicator": {
        backgroundColor: "transparent",
        height: "fit-content",
        alignSelf: "center",
        "& svg": { height: 20, width: 20 },
      },
      "& .MuiAutocomplete-popupIndicator": {
        fontSize: 24,
        color: "var(--color-black)",
        fontWeight: 100,
        "& svg": { height: 30, width: 30 },
      },
    },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      height: 40,
      alignItems: "center",
      padding: "5px 10px",
      fontFamily: "var(--copytext-font-family)",
      fontSize: "var(--copytext-font-size)",
      borderRadius: 0,
      "& .MuiAutocomplete-input": { padding: 0 },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 0,
        borderColor: isStatusError ? "red" : "black",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderRadius: 0,
        borderColor: isStatusError ? "red" : "black",
      },
    },
  };
};

export const inputStyles = { border: "none", backgroundColor: "#fff" };

export const inputLabelStyles = {
  fontFamily: "var(--form-label-font-family)",
  fontWeight: 800,
  fontSize: "var(--form-size-label-text)",
  paddingBottom: "8px",
  height: "fit-content",
};

export const InputWrapperSC = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: 280,
  alignContent: "center",
});
