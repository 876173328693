import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import PLTextarea, { PLTextareaProps } from "components/patternLib/form/PLTextarea";
import * as React from "react";
import translations from "translations";

export interface TextAreaProps extends PLTextareaProps {
  isOptional?: boolean;
}

const LabelSC = styled(Typography)(
  () =>
    `
  font-size: var(--form-size-label-text);
            font-weight: var(--form-label-font-weight);
            font-family: var(--form-label-font-family);
`
);

const OptionalTextSC = styled(Typography)(
  () =>
    `
  font-family: var(--form-label-font-family);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  color: #51585D;
`
);

const TitleAndOptionalTextBoxSC = styled("div")(
  () =>
    `
display: flex;
justify-content: space-between;
padding-bottom: 5px;
`
);

const TextArea: React.FC<TextAreaProps> = ({ isOptional, maxlength, label, ...rest }) => {
  return (
    <div>
      <TitleAndOptionalTextBoxSC>
        <LabelSC>{label}</LabelSC>
        {isOptional && <OptionalTextSC>{translations.globals.terms.optional}</OptionalTextSC>}
      </TitleAndOptionalTextBoxSC>
      <PLTextarea maxlength={maxlength ?? 1200} {...(rest as PLTextareaProps)} />
    </div>
  );
};

export default TextArea;
