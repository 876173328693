import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAttributesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAttributesQuery = { deviceAttributes: Array<{ __typename?: 'DeviceAttribute', id: string, attributeName: string, attributeDataType: string }>, assetAttributes: Array<{ __typename?: 'AssetAttribute', id: string, attributeName: string, attributeDataType?: Maybe<{ __typename?: 'AssetAttributeDataType', id: string, attributeDataTypeName: string }>, assetAttributeUnit?: Maybe<{ __typename?: 'AssetAttributeUnit', id: string, assetAttributeUnitSymbol?: Maybe<string>, assetAttributeUnitName: string }> }> };


export const GetAttributesDocument = gql`
    query getAttributes {
  deviceAttributes {
    id
    attributeName
    attributeDataType
  }
  assetAttributes {
    id
    attributeName
    attributeDataType: assetAttributeDataType {
      id
      attributeDataTypeName: assetAttributeDataTypeName
    }
    assetAttributeUnit {
      id
      assetAttributeUnitSymbol
      assetAttributeUnitName
    }
  }
}
    `;

/**
 * __useGetAttributesQuery__
 *
 * To run a query within a React component, call `useGetAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttributesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttributesQuery, GetAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAttributesQuery, GetAttributesQueryVariables>(GetAttributesDocument, options);
      }
export function useGetAttributesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttributesQuery, GetAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAttributesQuery, GetAttributesQueryVariables>(GetAttributesDocument, options);
        }
export type GetAttributesQueryHookResult = ReturnType<typeof useGetAttributesQuery>;
export type GetAttributesLazyQueryHookResult = ReturnType<typeof useGetAttributesLazyQuery>;
export type GetAttributesQueryResult = Apollo.QueryResult<GetAttributesQuery, GetAttributesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
