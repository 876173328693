import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetSensorMeasurementHistoriesForDeviceQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SensorMeasurementHistoryFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.SensorMeasurementHistorySortInput> | Types.SensorMeasurementHistorySortInput>;
}>;


export type GetSensorMeasurementHistoriesForDeviceQuery = { sensorMeasurementHistories: Array<{ __typename?: 'SensorMeasurementHistory', id: string, capabilityId: string, utcTimeMeasured: string, valueString?: Maybe<string>, valueFloat?: Maybe<number>, deviceId: string }> };


export const GetSensorMeasurementHistoriesForDeviceDocument = gql`
    query getSensorMeasurementHistoriesForDevice($where: SensorMeasurementHistoryFilterInput, $take: Int, $skip: Int, $order: [SensorMeasurementHistorySortInput!]) {
  sensorMeasurementHistories(where: $where, take: $take, skip: $skip, order: $order) {
    id
    capabilityId
    utcTimeMeasured
    valueString
    valueFloat
    deviceId
  }
}
    `;

/**
 * __useGetSensorMeasurementHistoriesForDeviceQuery__
 *
 * To run a query within a React component, call `useGetSensorMeasurementHistoriesForDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorMeasurementHistoriesForDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorMeasurementHistoriesForDeviceQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetSensorMeasurementHistoriesForDeviceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSensorMeasurementHistoriesForDeviceQuery, GetSensorMeasurementHistoriesForDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSensorMeasurementHistoriesForDeviceQuery, GetSensorMeasurementHistoriesForDeviceQueryVariables>(GetSensorMeasurementHistoriesForDeviceDocument, options);
      }
export function useGetSensorMeasurementHistoriesForDeviceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorMeasurementHistoriesForDeviceQuery, GetSensorMeasurementHistoriesForDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSensorMeasurementHistoriesForDeviceQuery, GetSensorMeasurementHistoriesForDeviceQueryVariables>(GetSensorMeasurementHistoriesForDeviceDocument, options);
        }
export type GetSensorMeasurementHistoriesForDeviceQueryHookResult = ReturnType<typeof useGetSensorMeasurementHistoriesForDeviceQuery>;
export type GetSensorMeasurementHistoriesForDeviceLazyQueryHookResult = ReturnType<typeof useGetSensorMeasurementHistoriesForDeviceLazyQuery>;
export type GetSensorMeasurementHistoriesForDeviceQueryResult = Apollo.QueryResult<GetSensorMeasurementHistoriesForDeviceQuery, GetSensorMeasurementHistoriesForDeviceQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
