import { useIsShowingLoader } from "components/atomics/Loader/useIsShowingLoader";
import throttle from "lodash/throttle";
import React, { useEffect, useState } from "react";
import { Builder, BuilderProps, Config, ImmutableTree, JsonTree, Query, Utils } from "react-awesome-query-builder";
import { useRecoilState } from "recoil";
import { queryBuilderQueryStrings } from "../middleware/recoilState";
import LoadingSpinner from "components/atomics/LoadingSpinner";

const { checkTree, loadTree, uuid } = Utils;

export const emptyInitValue: JsonTree = {
  id: uuid(),
  type: "group",
  properties: { conjunction: "AND" },
  children1: {
    "9bb8999b-0123-4456-b89a-b17ac364c080": {
      type: "rule",
      properties: { field: null, operator: null, value: [], valueSrc: [], valueType: [] },
    },
  },
};

// get init value in JsonTree format:
const initValue: JsonTree = emptyInitValue;

interface QueryComponentState {
  config: Config;
  tree: ImmutableTree;
}
interface QueryComponentProps {
  config: Config;
  loading: boolean;
  queryBuilderId: string;
}

const QueryComponent: React.FC<QueryComponentProps> = ({ config, queryBuilderId, loading }) => {
  const [recoilstate, setReoilState] = useRecoilState(queryBuilderQueryStrings(queryBuilderId));
  const [state, setState] = useState<QueryComponentState>({
    tree: checkTree(loadTree(initValue), config),
    config,
  });

  useEffect(() => {
    if (recoilstate?.issuer === "user") {
      setState({
        tree: checkTree(loadTree(recoilstate.rAWQuery ?? initValue), config),
        config,
      });
    }
  }, [config, recoilstate]);

  const onChange = (tree: ImmutableTree, config: Config) => {
    updateResult({ tree, config });
  };

  const updateResult = throttle(({ tree, config }: QueryComponentState) => {
    setState({ tree, config });
    const rAWQuery = Utils.getTree(tree, true);
    setReoilState({ rAWQuery, jSONQuery: undefined, issuer: "builder" });
  }, 100);

  const renderBuilder = (props: BuilderProps) => (
    <div className="query-builder">
      <Builder {...props} />
    </div>
  );

  return (
    <>
      <LoadingSpinner hideChildrenOnLoad loading={loading}>
        <Query {...config} value={state.tree} onChange={onChange} renderBuilder={renderBuilder} />
      </LoadingSpinner>
    </>
  );
};

export default QueryComponent;
