import { atom } from "recoil";

export const noChartDataState = atom<boolean | undefined>({
  key: "noChartDataState",
  default: undefined,
});

export const chartsLoadingState = atom<boolean | undefined>({
  key: "chartsLoadingState",
  default: undefined,
});
