import React from "react";
const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 2C4.83553 2 2 4.83553 2 8.33333C2 11.8311 4.83553 14.6667 8.33333 14.6667C9.9087 14.6667 11.3497 14.0915 12.4579 13.1397L17.1621 17.4519L17.8379 16.7148L13.1635 12.4299C14.1011 11.3256 14.6667 9.8955 14.6667 8.33333C14.6667 4.83553 11.8311 2 8.33333 2ZM3 8.33333C3 5.38781 5.38781 3 8.33333 3C11.2789 3 13.6667 5.38781 13.6667 8.33333C13.6667 11.2789 11.2789 13.6667 8.33333 13.6667C5.38781 13.6667 3 11.2789 3 8.33333Z"
      fill="black"
    />
  </svg>
);
export default SearchIcon;
