import React from "react";
import { useRecoilState } from "recoil";
import { siteAreaDefinitionModifier } from "../recoilState";
import PLTextinput from "components/patternLib/form/PLTextinput";
import translations from "translations";
import { LhChange } from "@liebherr/patternlib";
const { Latitude, Longitude } = translations.entities.capability.capabilityNames;

const LatLngInput: React.FC<{ label: "lat" | "lon" }> = ({ label }) => {
  const [position, setPositionsCoordinate] = useRecoilState(siteAreaDefinitionModifier);

  const onPositionChange = (e: CustomEvent<LhChange>) => {
    !isNaN(Number(e.detail.value))
      ? setPositionsCoordinate((prev) => {
          return { ...prev, [`${label}`]: e.detail.value };
        })
      : setPositionsCoordinate((prev) => {
          return { ...prev, [`${label}`]: null };
        });
  };

  return (
    <PLTextinput
      value={!position[label] ? "" : position[label].toString()}
      lhChange={onPositionChange}
      label={label === "lat" ? Latitude : Longitude}
    />
  );
};

export default LatLngInput;
