// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { TextField, Typography } from "@mui/material";
import { parseISO, formatISO } from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Props, { RaftValueDateTime } from "./index.d";

export type DatePickerProps = Props;
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      DatePickerRoot: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: 10,
        "& .MuiIconButton-root": {
          color: (props: { disabled: boolean }) => (props.disabled ? theme.colors.grey300 : theme.colors.brandYellow),
        },
        "& .MuiInputBase-root.Mui-disabled": {
          cursor: "not-allowed",
          backgroundColor: theme.colors.tWhite,
        },
        "& :disabled": {
          cursor: "not-allowed",
        },
      },
      datePicker: {
        "&:hover": {
          backgroundColor: theme.colors.grey200,
          borderRadius: "6px",
          "& :disabled": {
            backgroundColor: theme.colors.tWhite,
          },
        },
      },
      titleBox: {
        minWidth: 80,
        marginRight: "5px",
      },
      title: {
        textAlign: "left",
      },
      error: {
        textAlign: "left",
      },
    }),
  { name: "Datepicker" }
);

const ensureInjectValue = (data: RaftValueDateTime[]) =>
  data.reduce((newItems: string[], item) => {
    if (item.state) {
      return newItems.concat(item.state);
    }
    return newItems;
  }, []);

const dateTimeToISO = (date: MaterialUiPickersDate | Date) => {
  let isoString = "";
  if (date) {
    try {
      isoString = `${formatISO((date as unknown) as Date).split("T")[0]}T03:17:16.873`;
    } catch (e) {
      console.log(e, date);
    }
  }
  return isoString;
};

const DatePicker1: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, setAndSave, setstate },
    datesAfter,
    datesBefore,
    minDate,
    maxDate,
    testId,
    ...rest
  } = props;
  const [stateInner, setstateInner] = useState(state ? parseISO(state) : null);
  const [minDateInner, setMinDateInner] = useState<string | undefined>(undefined);
  const [maxDateInner, setMaxDateInner] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (datesBefore && datesBefore.length && !minDate) {
      setMinDateInner(ensureInjectValue(datesBefore).sort((a, b) => b.localeCompare(a))[0]);
    }
  }, [datesBefore]);
  useEffect(() => {
    if (datesAfter && datesAfter.length && !maxDate) {
      setMaxDateInner(ensureInjectValue(datesAfter).sort((a, b) => a.localeCompare(b))[0]);
    }
  }, [datesAfter]);
  // const n = datesAfter.map(ddd => ddd.state.)

  React.useEffect(() => {
    setstateInner(state ? parseISO(state) : null);
    return () => {};
  }, [state]);

  const setAndSaveInner = (date: MaterialUiPickersDate | Date) => {
    setAndSave(dateTimeToISO(date));
  };
  const handleChange = (date: MaterialUiPickersDate) => {
    if (date) setstateInner((date as unknown) as Date);
  };
  const handleAccept = (date: MaterialUiPickersDate) => {
    setAndSaveInner(date);
  };

  const classes = useStyles(props);
  return (
    <div className={classes.DatePickerRoot}>
      <div className={classes.titleBox}>
        <Typography align="right" className={classes.title} variant="body1">
          {title}:
        </Typography>
      </div>
      <DatePicker
        data-testid={testId}
        inputVariant="outlined"
        style={{ width: "15em" }}
        minDate={minDateInner}
        maxDate={maxDateInner}
        className={classes.datePicker}
        KeyboardButtonProps={{ style: { padding: 0 } }}
        maxDateMessage={
          maxDateInner ? (
            <Typography className={classes.error} variant="body1">
              Date should not be after {new Date(maxDateInner).toDateString()}
            </Typography>
          ) : undefined
        }
        minDateMessage={
          minDateInner ? (
            <Typography className={classes.error} variant="body1">
              Date should not be before {new Date(minDateInner).toDateString()}
            </Typography>
          ) : undefined
        }
        autoOk
        value={stateInner}
        disabled={props.disabled}
        onBlur={() => (stateInner ? setAndSaveInner(stateInner) : null)}
        value={stateInner}
        onAccept={handleAccept}
        onChange={handleChange}
        renderInput={TextField}
      />
    </div>
  );
};

export default DatePicker1;
