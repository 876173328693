import React from "react";
import Helmet from "react-helmet";
import json from "../../../metaData.json";
import { version } from "../../../../package.json";

interface MetaDecoratorProps {
  title?: string;
}

const MetaDecorator: React.FC<MetaDecoratorProps> = (props) => {
  const { title = "LiTrack" } = props;
  const { branchName, commitID } = json;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="version" content={version} />
      <meta name="branch-name" content={branchName} />
      <meta name="commit-id" content={commitID} />
    </Helmet>
  );
};

export default MetaDecorator;
