import styled from "@emotion/styled";
import {
  GetDevicesInGeofencesQuery,
  GetDevicesInGeofencesQueryVariables,
  useGetDevicesInGeofencesLazyQuery,
} from "graphqlBase/Devices/__generated__/getDevicesInGeofences";
import {
  GetAllStorageAreasQuery,
  NonMaybe,
  ResultType,
  useGetAllStorageAreasQuery,
} from "graphqlBase/StorageAreas/__generated__/getAllStorageAreas";
import {
  GetStorageAreaTypesQuery,
  useGetStorageAreaTypesLazyQuery,
} from "graphqlBase/StorageAreas/__generated__/getStorageAreaTypes";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import ContentHeader from "components/organisms/ContentHeader";
import PLButton from "components/patternLib/PLButton";
import translations from "translations";
import AddGeofenceLinkButton from "./manageGeofences/AddGeofence/AddGeofenceLinkButton";
import { genericDeleteParams } from "components/templates/table-factory/Table/Cells/actionsCellFactory/recoilStates";
import getTestIDs from "../../../lib/utils/getTestIDs";
import AssetList from "./AssetList";
import GeofenceMapContainer from "./GeofenceMapContainer";
import GeofenceTypesList from "./GeofenceTypesList";
import { isSearchbarUsedAtom, isToggleMapButtonActiveAtom, selectedStorageAreaIdsAtom } from "./recoilBox";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import useGetCalculatedData from "./hooks/useGetCalculatedData";
import PLSearchbox from "components/patternLib/PLSearchbox";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Can from "authorization";
import Toast from "components/atomics/Toast";
import { toastStateAtom } from "components/atomics/Toast/toastState";
export type QueryDeviceWithinGeofence = GetDevicesInGeofencesQuery["queryDeviceWithinGeofence"];

export type DeviceInGeofence = ResultType<NonMaybe<QueryDeviceWithinGeofence>["geofenceDevices"]>;
export type StorageAreaTypes = ResultType<GetStorageAreaTypesQuery["storageAreaTypes"]>;
export type StorageArea = StorageAreaTypes["storageArea"];
type Geofence = ResultType<GetAllStorageAreasQuery["storageAreas"]>["geofence"];
export const testIDs = getTestIDs();

const MapAndTableSC = styled("div")(({ props }: { props: { isActive: boolean; small: boolean } }) => {
  return {
    display: "flex",
    flexDirection: "row",
    columnGap: props.isActive ? "0em" : "10px",
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    "& .leftColumn": {
      width: "393px",
      display: "flex",
      flexDirection: "column",
      rowGap: "24px",
      padding: "48px 0 0 20px",
    },
  };
});

export const ToggleMapSC = styled("div")(({ props }: { props: { isActive: boolean } }) => {
  return {
    display: props.isActive ? "none" : "block",
  };
});
export interface FlattenData {
  geofence?: Geofence;
  id: string;
  parentId?: string;
  storageArea?: StorageArea;
  storageAreaName?: string;
  storageAreaTypeColor?: string | null;
  storageAreaTypeName?: string;
}
export interface IMapAndTable {}
const MapAndTable: React.FC<{}> = () => {
  const small = useMediaQuery("(max-height:920px)");
  const [toastObject, setToastObject] = useRecoilState(toastStateAtom);
  const isToggleMapButtonActive = useRecoilValue(isToggleMapButtonActiveAtom);
  const resetDeleteParams = useResetRecoilState(genericDeleteParams);
  const [open, setDrawerOpen] = useState<boolean>(false);
  const storageAreaIds = useRecoilValue(selectedStorageAreaIdsAtom);
  const setIsSearchBarUsed = useSetRecoilState(isSearchbarUsedAtom);
  const resetIsSearchBarUsed = useResetRecoilState(isSearchbarUsedAtom);
  const [query, { data, loading, refetch }] = useGetStorageAreaTypesLazyQuery({
    variables: {
      storageAreas: {
        isDeleted: { neq: true },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: allStorageAreas, refetch: refetchAllStorageAreas } = useGetAllStorageAreasQuery({
    fetchPolicy: "cache-and-network",
  });
  const [getDevicesInGeofences, { data: devicesInGeofencesPre }] = useGetDevicesInGeofencesLazyQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });

  const { filteredStorageAreas, devicesWithAssets, devicesByStorageAreaId, getFlattenData } = useGetCalculatedData({
    allStorageAreas,
    devicesInGeofencesPre,
  });
  const afterDelete = async () => {
    resetDeleteParams();
    if (refetch) await refetch();
    if (refetchAllStorageAreas) await refetchAllStorageAreas();
  };

  useEffect(() => {
    if (!storageAreaIds.length) return;

    const geofencesCoordinates = (allStorageAreas?.storageAreas ?? []).reduce<
      GetDevicesInGeofencesQueryVariables["geofencesCoordinates"]
    >((geofencesCoordinates, storageArea) => {
      if (!storageAreaIds.includes(storageArea.id)) return geofencesCoordinates;
      const coordinates = (storageArea.geofence?.coordinates ?? []).filter((coordinate) => (coordinate ?? []).length);

      //@ts-ignore
      return (geofencesCoordinates ?? []).concat([coordinates]);
    }, []);

    if (!geofencesCoordinates) return;

    getDevicesInGeofences({
      variables: { geofencesCoordinates: geofencesCoordinates },
    });
  }, [allStorageAreas, storageAreaIds]);

  useEffect(() => {
    resetIsSearchBarUsed();
    query();
  }, []);

  const searchGeofences = (e: CustomEvent<string>) => {
    if (!e.detail) {
      resetIsSearchBarUsed();
      query();
    }
    if (e.detail.length < 3) return;
    setIsSearchBarUsed(true);
    query({
      variables: {
        storageAreaTypes: {
          and: [{ storageArea: { any: true } }, { storageArea: { some: { storageAreaName: { contains: e.detail } } } }],
        },
        storageAreas: {
          and: [{ isDeleted: { neq: true } }, { storageAreaName: { contains: e.detail } }],
        },
      },
    });
  };

  const handleCloseToast = () => {
    setToastObject(undefined);
  };

  return (
    <MapAndTableSC props={{ isActive: isToggleMapButtonActive, small: small }}>
      <ToggleMapSC props={{ isActive: isToggleMapButtonActive }}>
        <Box sx={{ margin: "30px 0 0 20px" }}>
          <Typography variant="h2">{translations.pages.fleetInventory.label}</Typography>
        </Box>
        <div className="leftColumn">
          <Can I="read" a="CreateGeofenceUI">
            <AddGeofenceLinkButton small={small} />
          </Can>
          <PLSearchbox size={small ? "small" : "big"} placeholder="Search Geofences" lhSearchChange={searchGeofences} />
          <LoadingSpinner sx={{ height: loading ? "25vh" : "fit-content" }} loading={loading}>
            {data?.storageAreaTypes && (
              <GeofenceTypesList data={getFlattenData(data.storageAreaTypes ?? [])} afterDelete={afterDelete} />
            )}
          </LoadingSpinner>
          <div style={{ marginTop: "26px" }}>
            <PLButton
              size={small ? "small" : "big"}
              label={translations.pages.fleetInventory.assetListButton}
              onClick={() => setDrawerOpen(true)}
              disabled={!devicesWithAssets.length}
            />
          </div>
        </div>
      </ToggleMapSC>

      {filteredStorageAreas && (
        <>
          <AssetList
            devicesByStorageAreaId={devicesByStorageAreaId}
            open={open}
            setDrawerOpen={setDrawerOpen}
            storageAreas={filteredStorageAreas}
          />
          <GeofenceMapContainer storageAreas={filteredStorageAreas} devicesByStorageAreaId={devicesByStorageAreaId} />
        </>
      )}
      {toastObject && (
        <Toast
          message={toastObject.message}
          open={toastObject.open}
          severity={toastObject.severity}
          handleCloseToast={handleCloseToast}
        />
      )}
    </MapAndTableSC>
  );
};

export default MapAndTable;
