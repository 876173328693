import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMappingAssetTagsMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type DeleteMappingAssetTagsMutation = { deleteMappingAssetTags?: Maybe<Array<Maybe<{ __typename?: 'MappingAssetTag', id: string }>>> };


export const DeleteMappingAssetTagsDocument = gql`
    mutation deleteMappingAssetTags($ids: [UUID!]) {
  deleteMappingAssetTags(ids: $ids) {
    id
  }
}
    `;
export type DeleteMappingAssetTagsMutationFn = Apollo.MutationFunction<DeleteMappingAssetTagsMutation, DeleteMappingAssetTagsMutationVariables>;

/**
 * __useDeleteMappingAssetTagsMutation__
 *
 * To run a mutation, you first call `useDeleteMappingAssetTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMappingAssetTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMappingAssetTagsMutation, { data, loading, error }] = useDeleteMappingAssetTagsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMappingAssetTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMappingAssetTagsMutation, DeleteMappingAssetTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteMappingAssetTagsMutation, DeleteMappingAssetTagsMutationVariables>(DeleteMappingAssetTagsDocument, options);
      }
export type DeleteMappingAssetTagsMutationHookResult = ReturnType<typeof useDeleteMappingAssetTagsMutation>;
export type DeleteMappingAssetTagsMutationResult = Apollo.MutationResult<DeleteMappingAssetTagsMutation>;
export type DeleteMappingAssetTagsMutationOptions = Apollo.BaseMutationOptions<DeleteMappingAssetTagsMutation, DeleteMappingAssetTagsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
