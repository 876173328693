import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMappingDeviceTagsMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type DeleteMappingDeviceTagsMutation = { deleteMappingDeviceTags?: Maybe<Array<Maybe<{ __typename?: 'MappingDeviceTag', id: string }>>> };


export const DeleteMappingDeviceTagsDocument = gql`
    mutation deleteMappingDeviceTags($ids: [UUID!]) {
  deleteMappingDeviceTags(ids: $ids) {
    id
  }
}
    `;
export type DeleteMappingDeviceTagsMutationFn = Apollo.MutationFunction<DeleteMappingDeviceTagsMutation, DeleteMappingDeviceTagsMutationVariables>;

/**
 * __useDeleteMappingDeviceTagsMutation__
 *
 * To run a mutation, you first call `useDeleteMappingDeviceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMappingDeviceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMappingDeviceTagsMutation, { data, loading, error }] = useDeleteMappingDeviceTagsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMappingDeviceTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMappingDeviceTagsMutation, DeleteMappingDeviceTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteMappingDeviceTagsMutation, DeleteMappingDeviceTagsMutationVariables>(DeleteMappingDeviceTagsDocument, options);
      }
export type DeleteMappingDeviceTagsMutationHookResult = ReturnType<typeof useDeleteMappingDeviceTagsMutation>;
export type DeleteMappingDeviceTagsMutationResult = Apollo.MutationResult<DeleteMappingDeviceTagsMutation>;
export type DeleteMappingDeviceTagsMutationOptions = Apollo.BaseMutationOptions<DeleteMappingDeviceTagsMutation, DeleteMappingDeviceTagsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
