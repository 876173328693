import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetRecentAssignmentsForSummaryQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetDeviceSortInput> | Types.AssetDeviceSortInput>;
}>;


export type GetRecentAssignmentsForSummaryQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, createdAt?: Maybe<string>, asset: { __typename?: 'Asset', id: string, assetMasterDataId?: Maybe<string>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string> }> } }> };


export const GetRecentAssignmentsForSummaryDocument = gql`
    query getRecentAssignmentsForSummary($where: AssetDeviceFilterInput, $take: Int, $skip: Int, $order: [AssetDeviceSortInput!]) {
  assetDevices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    createdAt
    asset {
      id
      assetMasterDataId
      assetMasterData {
        id
        generalItem
      }
    }
  }
}
    `;

/**
 * __useGetRecentAssignmentsForSummaryQuery__
 *
 * To run a query within a React component, call `useGetRecentAssignmentsForSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentAssignmentsForSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentAssignmentsForSummaryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetRecentAssignmentsForSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecentAssignmentsForSummaryQuery, GetRecentAssignmentsForSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRecentAssignmentsForSummaryQuery, GetRecentAssignmentsForSummaryQueryVariables>(GetRecentAssignmentsForSummaryDocument, options);
      }
export function useGetRecentAssignmentsForSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecentAssignmentsForSummaryQuery, GetRecentAssignmentsForSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRecentAssignmentsForSummaryQuery, GetRecentAssignmentsForSummaryQueryVariables>(GetRecentAssignmentsForSummaryDocument, options);
        }
export type GetRecentAssignmentsForSummaryQueryHookResult = ReturnType<typeof useGetRecentAssignmentsForSummaryQuery>;
export type GetRecentAssignmentsForSummaryLazyQueryHookResult = ReturnType<typeof useGetRecentAssignmentsForSummaryLazyQuery>;
export type GetRecentAssignmentsForSummaryQueryResult = Apollo.QueryResult<GetRecentAssignmentsForSummaryQuery, GetRecentAssignmentsForSummaryQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
