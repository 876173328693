import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLDatepickerProps = DropOnONLh<JSX.PatternlibDatepicker>;
export type PLDatepickerExtendedProps = PLDatepickerProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedDatepicker = wrapWc<JSX.PatternlibDatepicker>("patternlib-datepicker");
export const useRefPLDatepicker = () => useRef<HTMLPatternlibDatepickerElement>(null);

const PLDatepicker = React.forwardRef<HTMLPatternlibDatepickerElement, PLDatepickerExtendedProps>((props, ref) => {
  const myRef = useRefPLDatepicker();
  return (
    <WrappedDatepicker ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedDatepicker>
  );
});
PLDatepicker.displayName = "PLDatepicker";

export default PLDatepicker;
