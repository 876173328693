import { useCubeQuery } from "@cubejs-client/react";
import { Box } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { getTotalAmountOfHours } from "../../../getTotalAmountOfHours";
import { filtersSelector, RecoilFilterType } from "../../filtersState";
import { AssetNameKey, getColorForAssetState } from "../../getColorForState";
import { RootChartContainerSC } from "../../styles";
import { DoughnutTableSC } from "../styles";
import { round } from "lodash";
import { tooltipCommonStyles } from "../../../styles";
import useMakeMemoizedCharts from "../../hooks/useMakeMemoizedCharts";
interface WorkingData {
  "DimDeviceState.devicestatename": string;
  "FactUtilization.utilizationhoursMeasure": number;
}

const dimensions = ["DimDeviceState.devicestatename"];

const AssetStateDoughnut: React.FC<{}> = () => {
  const [filters, setFilters] = useRecoilState(filtersSelector);
  const chartInstance: MutableRefObject<any> = useRef();

  const { resultSet, isLoading, error } = useCubeQuery<WorkingData>({
    dimensions,
    order: {
      "FactUtilization.utilizationhoursPercentage": "desc",
    },
    measures: ["FactUtilization.utilizationhoursPercentage", "FactUtilization.utilizationhoursMeasure"],
    segments: ["FactUtilization.sfDeviceState"],
    filters: filters.filter((elem) => !dimensions.includes(elem.member as string)),
  });

  const chartLoading = isLoading && !chartInstance?.current;

  const workingData = resultSet?.rawData();

  const totalAmountOfHours = getTotalAmountOfHours(workingData);
  const ownFilterValues = filters
    .filter((elem) => dimensions.includes(elem.member as string))
    .flatMap((filter) => filter.values);

  const getSeriesData = () =>
    (workingData ?? [])?.map((item) => {
      const color =
        !ownFilterValues.length || ownFilterValues.includes(item["DimDeviceState.devicestatename"])
          ? getColorForAssetState(item["DimDeviceState.devicestatename"] as AssetNameKey)
          : item["DimDeviceState.devicestatename"] === "N/A"
          ? "#000"
          : `rgba(0,0,0,0.1)`;

      return {
        value: totalAmountOfHours
          ? round(100 * (item["FactUtilization.utilizationhoursMeasure"] / totalAmountOfHours), 2)
          : 0,
        valueUnit: "%",
        value2: item["FactUtilization.utilizationhoursMeasure"],
        value2Unit: translations.pages.assetManagement.assetDetailGraphs.hours,
        name: item["DimDeviceState.devicestatename"],
        itemStyle: {
          color,
        },
      };
    });

  const options = {
    tooltip: {
      valueFormatter: (value: number) => `${value}%`,
      textStyle: tooltipCommonStyles,
      position: (pos: Array<number>) => {
        return [pos[0] + 20, pos[1] + 25];
      },
    },
    series: [
      {
        type: "pie",
        label: {
          show: false,
        },
        data: getSeriesData(),
        radius: ["63%", "93%"],
      },
    ],
  };

  const handleClick = useCallback(
    (e: any) => {
      const filter: RecoilFilterType = {
        member: "DimDeviceState.devicestatename",
        operator: "equals",
        values: [e.name],
      };
      setFilters([filter]);
    },
    [setFilters]
  );

  const data = getSeriesData();
  const { MemoizedChart, MemoizedChartList } = useMakeMemoizedCharts({
    options,
    isLoading,
    chartInstance,
    chartLoading,
    resultSet,
    handleClick,
    error,
    showHistoryButton: true,
    isDougnut: true,
    optionsObjForSetOption: { series: [{ data }] },
  });

  return (
    <RootChartContainerSC>
      <LoadingSpinner hideChildrenOnLoad loading={chartLoading}>
        <DoughnutTableSC>
          <Box sx={{ width: "200px" }}>{MemoizedChart}</Box>
          {MemoizedChartList}
        </DoughnutTableSC>
      </LoadingSpinner>
    </RootChartContainerSC>
  );
};

export default AssetStateDoughnut;
