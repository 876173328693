export default {
  addGeoFence: {
    label: "Add Location",
  },
  general: "General",
  geofence: "Geofence",
  geofencesTable: {
    label: "Locations Overview",
  },
  assetListButton: "Show Asset List",
  label: "Fleet Inventory",
  map: "Map",
  movement: "Movement",
  movementHistory: "Movement History",
  multiSelectLabel: "Location",
  noLocationMessage: "No location information available",
  noPolygonWarning: "Location must be selected",
  smallGeofenceWarning: "This geofence is too small",
  technicalDetails: "Technical Details",
  noData: "No movement history data is available for the selected period",
  deleteGeofenceTitle: "Delete Geofence",
  deleteGeofenceModalMessage: "Do you really want to delete this geofence. Alerts for this geofence won't be trigered.",
  defineAreaLabel: "Define Area",
  defineAreaText:
    "Draw your geofence on the map. Make sure that the starting and ending point of your geofence are the same",
  toastMessageCreate: {
    success: "Geofence successfully created",
    error: "Geofence could not be created",
  },
  toastMessageUpdate: {
    success: "Geofence successfully updated",
    error: "Geofence could not be updated",
  },
};
