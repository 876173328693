import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetMappingDeviceNotificationsForNotificationQueryVariables = Types.Exact<{
  notificationMappingFilter?: Types.InputMaybe<Types.MappingDeviceNotificationFilterInput>;
}>;


export type GetMappingDeviceNotificationsForNotificationQuery = { mappingDeviceNotifications: Array<{ __typename?: 'MappingDeviceNotification', id: string, notificationId: string, deviceId: string }> };


export const GetMappingDeviceNotificationsForNotificationDocument = gql`
    query getMappingDeviceNotificationsForNotification($notificationMappingFilter: MappingDeviceNotificationFilterInput) {
  mappingDeviceNotifications(where: $notificationMappingFilter) {
    id
    notificationId
    deviceId
  }
}
    `;

/**
 * __useGetMappingDeviceNotificationsForNotificationQuery__
 *
 * To run a query within a React component, call `useGetMappingDeviceNotificationsForNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingDeviceNotificationsForNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingDeviceNotificationsForNotificationQuery({
 *   variables: {
 *      notificationMappingFilter: // value for 'notificationMappingFilter'
 *   },
 * });
 */
export function useGetMappingDeviceNotificationsForNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMappingDeviceNotificationsForNotificationQuery, GetMappingDeviceNotificationsForNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMappingDeviceNotificationsForNotificationQuery, GetMappingDeviceNotificationsForNotificationQueryVariables>(GetMappingDeviceNotificationsForNotificationDocument, options);
      }
export function useGetMappingDeviceNotificationsForNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMappingDeviceNotificationsForNotificationQuery, GetMappingDeviceNotificationsForNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMappingDeviceNotificationsForNotificationQuery, GetMappingDeviceNotificationsForNotificationQueryVariables>(GetMappingDeviceNotificationsForNotificationDocument, options);
        }
export type GetMappingDeviceNotificationsForNotificationQueryHookResult = ReturnType<typeof useGetMappingDeviceNotificationsForNotificationQuery>;
export type GetMappingDeviceNotificationsForNotificationLazyQueryHookResult = ReturnType<typeof useGetMappingDeviceNotificationsForNotificationLazyQuery>;
export type GetMappingDeviceNotificationsForNotificationQueryResult = Apollo.QueryResult<GetMappingDeviceNotificationsForNotificationQuery, GetMappingDeviceNotificationsForNotificationQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
