import * as React from "react";

import Checkbox from "components/lbc-toolkit/atomics/Checkbox";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import Props from "./index.d";
import tableColors from "components/templates/table-factory/theme/tableColors";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      CheckBoxRoot: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
      },
      input: {
        appearance: "none",
        WebkitAppearance: "none",
        borderRadius: "4px",
        border: "0.1em solid black",
        fontSize: "16px",
        height: "18px",
        margin: "0.2em 0.5em 0.5em 0.8em",
        width: "18px",
        userSelect: "none",
        "&:focus": {
          outline: "none",
        },
        "&:checked": {
          appearance: "none",
          backgroundColor: theme.colors.brandYellow,
          backgroundImage: `url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>')`,
          border: "none",
          height: "18px",
          WebkitAppearance: "none",
          width: "18px",
        },
        "&:disabled": {
          border: tableColors.lbcgrey,
          backgroundColor: tableColors.lbcgrey,
          opacity: 0.5,
        },
      },
      label: {
        color: theme.colors.grey500,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        margin: "0.2em 0.4em 0.2em 0.3em",
      },
      title: {
        // minWidth: 160,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: 0,
        marginRight: 10,
        color: theme.colors.grey500,
        textAlign: "left",
        margin: "2em 0.5em 0.5em 0.4em",
      },
      checkBox: {
        margin: "0.2em 0.8em 0.8em 0.8em",
      },
    }),
  { name: "CheckboxBoolean" }
);

const CheckBoxBoolean: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    testId,
    title,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.CheckBoxRoot}>
      <Typography className={classes.title} variant="inherit">
        {title} :
      </Typography>
      <div className={classes.checkBox}>
        <Checkbox
          data-testid={testId}
          icon={<></>}
          checkedIcon={<></>}
          disabled={disabled}
          checked={state || false}
          onChange={(i, val) => setAndSave(val)}
          value="primary"
          inputProps={{ "aria-label": "primary checkbox", className: classes.input }}
        />
      </div>
    </div>
  );
};
export default CheckBoxBoolean;
