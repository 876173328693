import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { getGridStringOperators, GridRenderCellParams, GridRowParams, GridValidRowModel } from "@mui/x-data-grid-pro";
import TagChips from "components/molecules/TagChips";
import { getBatteryHealthInfo } from "components/pages/utils";
import PLStatusPill, { PLStatusPillProps } from "components/patternLib/PLStatusPill";
import PLTextlink from "components/patternLib/PLTextlink";
import useDataGrid, { useFilterTableSelectedRows } from "components/templates/dataGridTable";
import { ActionsCell } from "components/templates/dataGridTable/Cells";
import { isFuture } from "date-fns";
import {
  GetDevicesForDeviceManagementQuery,
  ResultType,
  useGetDevicesForDeviceManagementLazyQuery,
} from "graphqlBase/Devices/__generated__/getDevicesForDeviceManagement";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import { formatTimeDistance } from "translations/formatter";
import { DeviceDetailsAtom } from "../ChartCollection/recoilState";
import { sensorMeasurementHistoryAggregateVariablesAtom, durationOptionAtom } from "../Configuration/recoilState";
import TagModal from "../TagModal";
import { selectedDevicesForTagsAtom } from "../TagModal/tagModalState";
import AssetDeviceUnpairing from "components/organisms/AssetDeviceUnpairing";
import { assetDeviceUnpairingAtom } from "components/organisms/AssetDeviceUnpairing/recoilState";
import useGetHeartbeatCurrentValuesByDeviceId from "../hooks/useGetHeartbeatCurrentValuesByDeviceId";

export const testIDs = getTestIDs();

export type Device = ResultType<GetDevicesForDeviceManagementQuery["devices"]>;
interface SelectedDevice extends Device {
  toDelete: boolean;
}
const BatteryCellSC = styled("div")({
  display: "inline-flex",
  flexWrap: "wrap",
});

const variables = {
  where: {
    parentDeviceId: { eq: null },
  },
};

const DeviceOverview: React.FC<{}> = () => {
  const history = useHistory();
  const { heartbeatCurrentValueByDeviceId } = useGetHeartbeatCurrentValuesByDeviceId();

  const resetSensorMeasurementHistoryAggregateVariables = useResetRecoilState(
    sensorMeasurementHistoryAggregateVariablesAtom
  );
  const setUnpairState = useSetRecoilState(assetDeviceUnpairingAtom);

  const resetDurationOptionAtom = useResetRecoilState(durationOptionAtom);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [selectedDevicesForTags, setSelectedDevicesForTags] = useRecoilState(selectedDevicesForTagsAtom);
  const setDeviceDetails = useSetRecoilState(DeviceDetailsAtom);
  const [queryOuter, { data, loading, error, refetch }] = useGetDevicesForDeviceManagementLazyQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });

  const {
    query,
    selectedIds,
    setSelectedIds,
    setSelectedRowsFilterOn,
    selectedRowsFilterOn,
  } = useFilterTableSelectedRows({
    queryOuter,
    atom: selectedDevicesForTagsAtom,
    getIdsFromAtom: (rows) => rows.map((row) => row.id),
    entity: "device",
  });

  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef, getRowCount } = useDataGrid<Device, "Device">({
    query,
    variables,
    tableId: "DeviceManagement",
    persistance: "runTime",
  });

  const AssetLink = (params: GridRenderCellParams<string, Device>) => {
    const asset = params.row?.assetDevice?.[0]?.asset;
    return isFuture(new Date(params.row?.assetDevice?.[0]?.dateTo ?? "2020-01-01T00:04:52.000Z")) && asset ? (
      asset.assetMasterData ? (
        <PLTextlink
          label={params.row?.assetDevice?.[0]?.asset.assetMasterData?.generalItem ?? ""}
          onClick={() => {
            history.push(`/assetManagement/assetDetails/${params.row.assetDevice?.[0]?.asset.id}`);
          }}
          uppercase={false}
          bold={false}
          size="small"
        />
      ) : (
        <PLTextlink
          label={translations.pages.deviceManagement.goToOpenAssignment}
          onClick={() => {
            history.push(`/assetManagement/openAssignments/${params.row.assetDevice?.[0]?.asset.id}`);
          }}
          uppercase={false}
          bold={false}
          size="small"
        />
      )
    ) : (
      <p style={{ color: "#E52828" }}>{translations.pages.deviceManagement.noAssetText}</p>
    );
  };

  const handleUnpairDevice = (row: Device) => {
    setUnpairState({
      deviceName: row.deviceName,
      assetName: row.assetDevice?.[0]?.asset?.assetMasterData?.generalItem ?? "",
      variables: {
        deviceSerialNumber: row.serialNo,
        assetCustomUniqueIdentifier: row.assetDevice?.[0]?.asset?.customUniqueIdentifier ?? "",
      },
    });
  };

  const columns = useMakeColumns(
    [
      {
        field: "deviceName",
        headerName: `Device name`,
        renderHeader: (params) => `Device name (${getRowCount()})`,
        flex: 1.5,
        valueGetter: (params) => {
          return params.row.deviceName ?? "";
        },
        remoteOrder: (sort) => ({ deviceName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ filterValue, where }) => ({ deviceName: filterValue }),
        }),
        type: "string",
      },
      {
        field: "deviceTypeName",
        headerName: `Device type`,
        flex: 1.5,
        remoteOrder: (sort) => ({ deviceType: { deviceTypeName: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            deviceType: { deviceTypeName: filterValue },
          }),
        }),
        valueGetter: (params) => {
          return params.row.deviceType?.deviceTypeName ?? "";
        },
        type: "string",
      },
      {
        field: "lastMeasurementTime",
        headerName: `Last Connection`,
        flex: 1.5,
        valueGetter: (params) => {
          const lastTimeConnection = params.row.lastMeasurement?.[0]?.utcTimeMeasured;
          const gateWayDeviceConnectionId = params.row.gatewayDeviceConnection?.id;
          if (lastTimeConnection) {
            return formatTimeDistance(new Date(lastTimeConnection));
          } else if (gateWayDeviceConnectionId) {
            return heartbeatCurrentValueByDeviceId[gateWayDeviceConnectionId]
              ? formatTimeDistance(new Date(heartbeatCurrentValueByDeviceId[gateWayDeviceConnectionId]))
              : "";
          } else {
            return "";
          }
        },
        type: "string",
      },
      {
        field: "battery",
        headerName: `Battery`,
        flex: 1,
        renderCell: (params) => {
          const batteryVoltage = params.row?.sensorMeasurementCurrentValue?.[0]?.valueString ?? "";
          const symbol =
            params.row?.sensorMeasurementCurrentValue?.[0]?.capability?.deviceModelCapability?.[0].unit.unitSymbol ??
            "";
          const batteryHealth = getBatteryHealthInfo(Number(batteryVoltage));
          return (
            <BatteryCellSC>
              <PLStatusPill {...(batteryHealth as PLStatusPillProps)} />
            </BatteryCellSC>
          );
        },
        type: "string",
      },
      {
        field: "firmware",
        headerName: `Firmware`,
        flex: 1,
        valueGetter: (params) => {
          return params.row.mappingDeviceFirmwareDevice?.[0]?.deviceFirmware?.firmwareVersion ?? "";
        },
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 1.5,
        valueGetter: (params) => {
          return params.row.mappingDeviceTag?.map((entry) => entry.tag?.tagValue ?? "") ?? null;
        },
        renderCell: (params) => {
          return <TagChips mappingDeviceTag={params.row.mappingDeviceTag} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators().filter(
            (operator) =>
              operator.value !== "isEmpty" &&
              operator.value !== "equals" &&
              operator.value !== "isNotEmpty" &&
              operator.value !== "isAnyOf"
          ),
          filterPath: ({ where, filterValue }) => ({
            mappingDeviceTag: { some: { tag: { tagValue: filterValue } } },
          }),
        }),
        type: "stringArray",
      },
      {
        field: "asset",
        headerName: translations.entities.asset.name,
        flex: 2,
        renderCell: (params) => {
          return AssetLink(params);
        },

        type: "string",
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <ActionsCell
            handleUnpair={params?.row?.assetDevice?.[0] ? () => handleUnpairDevice(params.row) : undefined}
          />
        ),
        type: "ReactElement",
        valueType: "string",
        disableColumnMenu: true,
      },
    ],
    []
  );

  const handleOnRowClick = (params: GridRowParams) => {
    resetDurationOptionAtom();
    resetSensorMeasurementHistoryAggregateVariables();
    setDeviceDetails({
      deviceName: params.row.deviceName,
      deviceTypeName: params.row.deviceTypeName,
      serialNo: params.row.serialNo,
    });
    history.push(`/deviceManagement/deviceDetails/${params.row.id}`);
  };

  const selectedRows = useMemo(
    () =>
      selectedIds?.reduce<string[]>((assetIds, element) => {
        return assetIds.concat(element.id);
      }, []),
    [selectedIds]
  );

  const handleSelectRow = (rows: readonly GridValidRowModel[]) => {
    setSelectedIds((selectedDevices) => {
      const deviceIDsOnUi = apiRef.current.getAllRowIds ? apiRef.current.getAllRowIds() : [];
      const chekedIds = rows.map(({ id }) => id) as string[];
      const SelectedUsersNextPre = selectedDevices.reduce<SelectedDevice[]>((acc, curr) => {
        if (!deviceIDsOnUi.includes(curr.id)) return acc.concat(curr as SelectedDevice);
        if (chekedIds.includes(curr.id)) {
          return acc.concat({ ...curr, toDelete: false });
        } else {
          return acc.concat({ ...curr, toDelete: true });
        }
      }, []);
      return rows
        .reduce<SelectedDevice[]>((acc, curr) => {
          if (acc.find((e) => e.id === curr.id)) return acc;

          return acc.concat(curr as SelectedDevice);
        }, SelectedUsersNextPre)
        .filter((item) => !item.toDelete);
    });
  };

  const afterMutation = useCallback(async () => {
    if (refetch) await refetch();
  }, [refetch]);

  return (
    <>
      <AssetDeviceUnpairing afterMutation={afterMutation} />
      {!!isTagModalOpen && (
        <TagModal
          afterMutation={afterMutation}
          open={isTagModalOpen}
          onClose={() => setIsTagModalOpen(false)}
          loading={loading}
        />
      )}
      {!error && (
        <DataGrid
          noDataMessage={translations.pages.deviceManagement.noDeviceMessage}
          tableTitle={translations.pages.deviceManagement.myDevices}
          tableHeight="65vh"
          handleOnRowClick={handleOnRowClick}
          selectedRowsFilterOn={selectedRowsFilterOn}
          setSelectedRowsFilterOn={setSelectedRowsFilterOn}
          columns={columns}
          rows={data?.devices}
          setSelectedRows={handleSelectRow}
          setIsTagModalOpen={setIsTagModalOpen}
          selectedRows={selectedRows}
          loading={loading}
          keepNonExistentRowsSelected
        />
      )}
    </>
  );
};

export default DeviceOverview;
