import { Slider, styled } from "@mui/material";
import React from "react";
import colors from "themes/colors";

const border = "2px solid #F0F3F6";
const boxShadow = "inherit";

const SliderPLStyles = styled(Slider)(() => ({
  color: colors.tBlack,
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: colors.tBlack,
    border: border,
    boxShadow: boxShadow,
    "&:focus, &:hover, &.Mui-active": {
      border: border,
      backgroundColor: colors.brandYellow,
      boxShadow: boxShadow,
      "@media (hover: none)": {
        boxShadow: boxShadow,
        border: "2px solid #F0F3F6",
      },
      "&.Mui-active": {
        boxShadow: boxShadow,
        backgroundColor: "orange",
        border: border,
      },
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
    height: "1px",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "currentColor",
    height: 4,
    width: 4,
    borderRadius: 5,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

interface SliderProps {
  max?: number;
  min?: number;
  onChange?: (event: Event, value: number | number[], activeThumb: number) => void;
  step?: number | null;
  value?: number | number[];
}
const SliderPLStyle: React.FC<SliderProps> = ({ value, onChange, min, max, step }) => {
  return (
    <SliderPLStyles
      aria-label="Slider"
      value={value}
      valueLabelDisplay="off"
      step={step}
      onChange={onChange}
      min={min}
      max={max}
    />
  );
};

export default SliderPLStyle;
