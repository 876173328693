import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDynamicGroupByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetDynamicGroupByIdQuery = { group?: Maybe<{ __typename?: 'Group', id: string, groupName: string, groupDescription?: Maybe<string>, userId?: Maybe<string>, createdAt?: Maybe<string>, rAWQuery?: Maybe<string>, jSONQuery: string, groupEntityId: string }> };


export const GetDynamicGroupByIdDocument = gql`
    query getDynamicGroupById($id: UUID!) {
  group(id: $id) {
    id
    groupName
    groupDescription
    userId
    createdAt
    rAWQuery
    jSONQuery
    groupEntityId
  }
}
    `;

/**
 * __useGetDynamicGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetDynamicGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDynamicGroupByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDynamicGroupByIdQuery, GetDynamicGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDynamicGroupByIdQuery, GetDynamicGroupByIdQueryVariables>(GetDynamicGroupByIdDocument, options);
      }
export function useGetDynamicGroupByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDynamicGroupByIdQuery, GetDynamicGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDynamicGroupByIdQuery, GetDynamicGroupByIdQueryVariables>(GetDynamicGroupByIdDocument, options);
        }
export type GetDynamicGroupByIdQueryHookResult = ReturnType<typeof useGetDynamicGroupByIdQuery>;
export type GetDynamicGroupByIdLazyQueryHookResult = ReturnType<typeof useGetDynamicGroupByIdLazyQuery>;
export type GetDynamicGroupByIdQueryResult = Apollo.QueryResult<GetDynamicGroupByIdQuery, GetDynamicGroupByIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
