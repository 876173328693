export default {
  name: "MappingDeviceNotification",
  plural: "MappingDeviceNotificationlerts",
  columns: {
    id: "ID",
    notificationId: "Notification ID",
    deviceId: "Device ID",
    createdAt: "Created At",
    modifiedAt: "Modified At",
    tenantId: "",
  },
};
