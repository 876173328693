export default {
  header: "Warning",
  dataloss_1:
    "You are about to leave this page. Your entries or changes during this session will not be saved. Do you really want to leave this page? No (go back) / Yes (leave page)",
  changedata: "Please confirm that you are entitled to change the data manually and that the new data is correct.",
  changeobject:
    "This <name of object> has been last updated by user <mail-address of user having last updated the object> (<timestamp>). Please confirm that you are entitle",
  blockobject:
    "This <name of object> is currently being edited by user <mail-address of user blocking the object> and cannot be changed right now. Please try again in 15 minutes or ask the other user to cancel the editing.",
  dataloss_2: "Do you really want to delete? ",
  dataloss_2_header: "Record will be deleted!",
  confirmDeleteGroup: "Do you really want to delete this group?",
  confirmDeleteRule: "Do you really want to delete this rule?",
  changesSavedTitle: "Changes saved",
  changesSavedMessage: "All changes have been saved.",
};
