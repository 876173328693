import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetRoleByUserQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserFilterInput>;
}>;


export type GetRoleByUserQuery = { users: Array<{ __typename?: 'User', mappingRoleUsers: Array<{ __typename?: 'MappingRoleUser', role: { __typename?: 'Role', name: string } }> }> };


export const GetRoleByUserDocument = gql`
    query getRoleByUser($where: UserFilterInput) {
  users(where: $where) {
    mappingRoleUsers(where: {isDeleted: {neq: true}}) {
      role {
        name
      }
    }
  }
}
    `;

/**
 * __useGetRoleByUserQuery__
 *
 * To run a query within a React component, call `useGetRoleByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRoleByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleByUserQuery, GetRoleByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRoleByUserQuery, GetRoleByUserQueryVariables>(GetRoleByUserDocument, options);
      }
export function useGetRoleByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleByUserQuery, GetRoleByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRoleByUserQuery, GetRoleByUserQueryVariables>(GetRoleByUserDocument, options);
        }
export type GetRoleByUserQueryHookResult = ReturnType<typeof useGetRoleByUserQuery>;
export type GetRoleByUserLazyQueryHookResult = ReturnType<typeof useGetRoleByUserLazyQuery>;
export type GetRoleByUserQueryResult = Apollo.QueryResult<GetRoleByUserQuery, GetRoleByUserQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
