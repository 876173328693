import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetsForMappingDeviceNotificationsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetSortInput> | Types.AssetSortInput>;
}>;


export type GetAssetsForMappingDeviceNotificationsQuery = { assets: Array<{ __typename?: 'Asset', id: string, assetDevice: Array<{ __typename?: 'AssetDevice', deviceId: string, createdAt?: Maybe<string> }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, articleNumber?: Maybe<string>, generalItem?: Maybe<string>, serialNumber?: Maybe<string> }>, mappingAssetTag: Array<{ __typename?: 'MappingAssetTag', id: string, assetTag: { __typename?: 'AssetTag', id: string, tagValue: string } }> }> };


export const GetAssetsForMappingDeviceNotificationsDocument = gql`
    query getAssetsForMappingDeviceNotifications($where: AssetFilterInput, $take: Int, $skip: Int, $order: [AssetSortInput!]) {
  assets(where: $where, take: $take, skip: $skip, order: $order) {
    id
    assetDevice(where: {dateTo: {gte: "9999-12-31T23:59:59.999Z"}}) {
      deviceId
      createdAt
    }
    assetMasterData {
      id
      articleNumber
      generalItem
      serialNumber
    }
    mappingAssetTag(where: {isDeleted: {neq: true}}) {
      id
      assetTag {
        id
        tagValue
      }
    }
  }
}
    `;

/**
 * __useGetAssetsForMappingDeviceNotificationsQuery__
 *
 * To run a query within a React component, call `useGetAssetsForMappingDeviceNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsForMappingDeviceNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsForMappingDeviceNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetsForMappingDeviceNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetsForMappingDeviceNotificationsQuery, GetAssetsForMappingDeviceNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetsForMappingDeviceNotificationsQuery, GetAssetsForMappingDeviceNotificationsQueryVariables>(GetAssetsForMappingDeviceNotificationsDocument, options);
      }
export function useGetAssetsForMappingDeviceNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetsForMappingDeviceNotificationsQuery, GetAssetsForMappingDeviceNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetsForMappingDeviceNotificationsQuery, GetAssetsForMappingDeviceNotificationsQueryVariables>(GetAssetsForMappingDeviceNotificationsDocument, options);
        }
export type GetAssetsForMappingDeviceNotificationsQueryHookResult = ReturnType<typeof useGetAssetsForMappingDeviceNotificationsQuery>;
export type GetAssetsForMappingDeviceNotificationsLazyQueryHookResult = ReturnType<typeof useGetAssetsForMappingDeviceNotificationsLazyQuery>;
export type GetAssetsForMappingDeviceNotificationsQueryResult = Apollo.QueryResult<GetAssetsForMappingDeviceNotificationsQuery, GetAssetsForMappingDeviceNotificationsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
