import GFCForm_Base from "../../components/formElements/forms/form"
import GFCFieldSet_ConfigNode from "../../components/formElements/fieldSets/configNode"
import GFCFieldSet_Div from "../../components/formElements/fieldSets/fieldSetDiv"
import GFCFieldSet_FixedFooter from "../../components/formElements/fieldSets/FieldSetFixedFooter"
import GFCFieldSet_Horizontal from "../../components/formElements/fieldSets/fieldSetHorizontal"
import GFCFieldSet_HorizontalSimple from "../../components/formElements/fieldSets/fieldSetHorizontalSimple"
import GFCFieldSet_LayoutHorizontal from "../../components/formElements/fieldSets/fieldSetLayoutHorizontal"
import GFCFieldSet_Vertical from "../../components/formElements/fieldSets/fieldSetLayoutVertical"
import GFCFieldSet_NoSideBar from "../../components/formElements/fieldSets/fieldSetNoSidebar"
import GFCFieldSet_NoSideBarWithScroll from "../../components/formElements/fieldSets/fieldSetNoSidebarScroll"
import GFCFieldSet_ResponsiveColumns from "../../components/formElements/fieldSets/FieldSetResponsiveColumns"
import GFCFieldSet_Stepper from "../../components/formElements/fieldSets/fieldSetStepper"
import GFCFieldSet_StorageArea from "../../components/formElements/fieldSets/fieldSetStorageArea"
import GFCFieldSet_VerticalSimple from "../../components/formElements/fieldSets/fieldSetVerticalSimple"
import GFCFieldSet_GridContainer from "../../components/formElements/fieldSets/grid/fieldSetGridContainer"
import GFCFieldSet_GridContainerItem from "../../components/formElements/fieldSets/grid/fieldSetGridContainerItem"
import GFCFieldSet_Griditem from "../../components/formElements/fieldSets/grid/fieldSetGridItem"
import GFCField_ActiveRadioGroupForAlerts from "../../components/formElements/fields/ActiveRadioGroupForAlerts"
import GFCField_AlertName from "../../components/formElements/fields/AlertName"
import GFCField_AssetCustomIdentiefier from "../../components/formElements/fields/assetCustomIdentiefier"
import GFCField_BaseTextField from "../../components/formElements/fields/baseTextField"
import GFCField_CancelButton from "../../components/formElements/fields/cancelButton"
import GFCField_CheckBoxNumber from "../../components/formElements/fields/checkBox"
import GFCField_CheckBoxBoolean from "../../components/formElements/fields/checkBoxBoolean"
import GFCField_DatePicker from "../../components/formElements/fields/datePicker1"
import GFCField_DatePicker2 from "../../components/formElements/fields/datePicker2"
import GFCField_DecimalEditor from "../../components/formElements/fields/decimalEditor"
import GFCField_DefineStorageAreaText from "../../components/formElements/fields/defineStorageAreaText"
import GFCField_DisplayField from "../../components/formElements/fields/displayField"
import GFCField_DropDown from "../../components/formElements/fields/dropdown"
import GFCField_FormErrors from "../../components/formElements/fields/errorDisplay"
import GFCField_ForeignKeyRadioGroup from "../../components/formElements/fields/foreignKeyRadioGroup"
import GFCField_ForeignKeySelector from "../../components/formElements/fields/foreignKeySelector"
import GFCField_GeoJSONPolygonType from "../../components/formElements/fields/GeoJSONPolygon"
import GFCField_Notification2Devices from "../../components/formElements/fields/MappingDeviceNotifications"
import GFCField_MappingUserNotifications from "../../components/formElements/fields/MappingUserNotifications"
import GFCField_NotificationResubmissionInterval from "../../components/formElements/fields/NotificationResubmissionInterval"
import GFCField_NotificationScopeEdit from "../../components/formElements/fields/NotificationScopeEdit"
import GFCField_PercentEditor from "../../components/formElements/fields/percentEditor"
import GFCField_Select from "../../components/formElements/fields/selectBox"
import GFCField_StepperButton from "../../components/formElements/fields/StepperButton"
import GFCField_StepperField from "../../components/formElements/fields/StepperField"
import GFCField_SubmitButton from "../../components/formElements/fields/submitButton"
import GFCField_SubmitButtonForAlertManagement from "../../components/formElements/fields/submitButtonForAlertManagement"
import GFCField_SubmitButtonForAssetManagement from "../../components/formElements/fields/submitButtonForAssetManagement"
import GFCField_TabsBar from "../../components/formElements/fields/TabsBar"
import GFCField_TextArea from "../../components/formElements/fields/textArea"
import GFCField_TextField from "../../components/formElements/fields/textField"
import GFCField_Title from "../../components/formElements/fields/title"

export default [
  {
    Component: GFCForm_Base,
    uiType: "GFCForm_Base",
  },
  {
    Component: GFCFieldSet_ConfigNode,
    uiType: "GFCFieldSet_ConfigNode",
  },
  {
    Component: GFCFieldSet_Div,
    uiType: "GFCFieldSet_Div",
  },
  {
    Component: GFCFieldSet_FixedFooter,
    uiType: "GFCFieldSet_FixedFooter",
  },
  {
    Component: GFCFieldSet_Horizontal,
    uiType: "GFCFieldSet_Horizontal",
  },
  {
    Component: GFCFieldSet_HorizontalSimple,
    uiType: "GFCFieldSet_HorizontalSimple",
  },
  {
    Component: GFCFieldSet_LayoutHorizontal,
    uiType: "GFCFieldSet_LayoutHorizontal",
  },
  {
    Component: GFCFieldSet_Vertical,
    uiType: "GFCFieldSet_Vertical",
  },
  {
    Component: GFCFieldSet_NoSideBar,
    uiType: "GFCFieldSet_NoSideBar",
  },
  {
    Component: GFCFieldSet_NoSideBarWithScroll,
    uiType: "GFCFieldSet_NoSideBarWithScroll",
  },
  {
    Component: GFCFieldSet_ResponsiveColumns,
    uiType: "GFCFieldSet_ResponsiveColumns",
  },
  {
    Component: GFCFieldSet_Stepper,
    uiType: "GFCFieldSet_Stepper",
  },
  {
    Component: GFCFieldSet_StorageArea,
    uiType: "GFCFieldSet_StorageArea",
  },
  {
    Component: GFCFieldSet_VerticalSimple,
    uiType: "GFCFieldSet_VerticalSimple",
  },
  {
    Component: GFCFieldSet_GridContainer,
    uiType: "GFCFieldSet_GridContainer",
  },
  {
    Component: GFCFieldSet_GridContainerItem,
    uiType: "GFCFieldSet_GridContainerItem",
  },
  {
    Component: GFCFieldSet_Griditem,
    uiType: "GFCFieldSet_Griditem",
  },
  {
    Component: GFCField_ActiveRadioGroupForAlerts,
    uiType: "GFCField_ActiveRadioGroupForAlerts",
  },
  {
    Component: GFCField_AlertName,
    uiType: "GFCField_AlertName",
  },
  {
    Component: GFCField_AssetCustomIdentiefier,
    uiType: "GFCField_AssetCustomIdentiefier",
  },
  {
    Component: GFCField_BaseTextField,
    uiType: "GFCField_BaseTextField",
  },
  {
    Component: GFCField_CancelButton,
    uiType: "GFCField_CancelButton",
  },
  {
    Component: GFCField_CheckBoxNumber,
    uiType: "GFCField_CheckBoxNumber",
  },
  {
    Component: GFCField_CheckBoxBoolean,
    uiType: "GFCField_CheckBoxBoolean",
  },
  {
    Component: GFCField_DatePicker,
    uiType: "GFCField_DatePicker",
  },
  {
    Component: GFCField_DatePicker2,
    uiType: "GFCField_DatePicker2",
  },
  {
    Component: GFCField_DecimalEditor,
    uiType: "GFCField_DecimalEditor",
  },
  {
    Component: GFCField_DefineStorageAreaText,
    uiType: "GFCField_DefineStorageAreaText",
  },
  {
    Component: GFCField_DisplayField,
    uiType: "GFCField_DisplayField",
  },
  {
    Component: GFCField_DropDown,
    uiType: "GFCField_DropDown",
  },
  {
    Component: GFCField_FormErrors,
    uiType: "GFCField_FormErrors",
  },
  {
    Component: GFCField_ForeignKeyRadioGroup,
    uiType: "GFCField_ForeignKeyRadioGroup",
  },
  {
    Component: GFCField_ForeignKeySelector,
    uiType: "GFCField_ForeignKeySelector",
  },
  {
    Component: GFCField_GeoJSONPolygonType,
    uiType: "GFCField_GeoJSONPolygonType",
  },
  {
    Component: GFCField_Notification2Devices,
    uiType: "GFCField_Notification2Devices",
  },
  {
    Component: GFCField_MappingUserNotifications,
    uiType: "GFCField_MappingUserNotifications",
  },
  {
    Component: GFCField_NotificationResubmissionInterval,
    uiType: "GFCField_NotificationResubmissionInterval",
  },
  {
    Component: GFCField_NotificationScopeEdit,
    uiType: "GFCField_NotificationScopeEdit",
  },
  {
    Component: GFCField_PercentEditor,
    uiType: "GFCField_PercentEditor",
  },
  {
    Component: GFCField_Select,
    uiType: "GFCField_Select",
  },
  {
    Component: GFCField_StepperButton,
    uiType: "GFCField_StepperButton",
  },
  {
    Component: GFCField_StepperField,
    uiType: "GFCField_StepperField",
  },
  {
    Component: GFCField_SubmitButton,
    uiType: "GFCField_SubmitButton",
  },
  {
    Component: GFCField_SubmitButtonForAlertManagement,
    uiType: "GFCField_SubmitButtonForAlertManagement",
  },
  {
    Component: GFCField_SubmitButtonForAssetManagement,
    uiType: "GFCField_SubmitButtonForAssetManagement",
  },
  {
    Component: GFCField_TabsBar,
    uiType: "GFCField_TabsBar",
  },
  {
    Component: GFCField_TextArea,
    uiType: "GFCField_TextArea",
  },
  {
    Component: GFCField_TextField,
    uiType: "GFCField_TextField",
  },
  {
    Component: GFCField_Title,
    uiType: "GFCField_Title",
  },
]
