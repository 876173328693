import { QueryArray, TreeConfig } from "../formGen.model";
import getFieldsToLoad from "./getFieldsToLoad";

interface MutationGeneratorProps {
  entity: string;
  operation: "update" | "create" | "delete";
  fields: string[];
  clientFields: string[];
  externalFields: string[];
  treeConfig: TreeConfig;
  allFields: string[];
  updateAfterCreateQueries?: QueryArray;
  fetchAllFieldsOnUpdate?: boolean;
}
const fieldstoExclude = ["submit", "formErrors", "cancel", "virtualField"];
const checkClientFieldsPre = (clientFields: string[]) => (field: string, path?: string) =>
  clientFields.includes(path ? path : field) ? `${field} @client` : field;
const fieldsToExtend = (field: string) => {
  if (field === "geofence")
    return ` geofence{
      coordinates
      crs
      type
    }`;
  return field;
};

export default ({
  entity,
  operation,
  clientFields,
  treeConfig,
  allFields,
  updateAfterCreateQueries,
  fetchAllFieldsOnUpdate,
  externalFields,
}: MutationGeneratorProps) => {
  const checkClientFields = checkClientFieldsPre(clientFields);
  const entityUpperCase = entity.charAt(0).toUpperCase() + entity.slice(1),
    operationUpperCase = operation.charAt(0).toUpperCase() + operation.slice(1);
  const { fields, fragments } = getFieldsToLoad({
    updateAfterCreateQueries,
    entity,
  });

  let mutation = "";
  if (operation === "delete") {
    mutation = `mutation Raft${operation}${entity}($data:[Int!]!){
      result: ${operation}${entityUpperCase}(id:$data){
         id

       }
     }`;
  } else {
    mutation = `mutation Raft${operation}${entity}($data:[${entityUpperCase}${operationUpperCase}Type!]!){
       result: ${operation}${entityUpperCase}s(${entity}s:$data){
          id
          ${treeConfig.usedFields
            .concat(operation === "create" || fetchAllFieldsOnUpdate ? allFields : [])
            .filter((field: string) => !fieldstoExclude.includes(field) && !externalFields.includes(field))
            .map((field: string) => checkClientFields(field, `${entity}.${field}`))
            .map(fieldsToExtend)
            .join("\n")}
            ${fields.join("\n")}
           
      }
      
    }${fragments.join("\n")}`;
  }
  return {
    mutationString: mutation,
    mutationStringLocal: mutation.replace(":$data){", ":$data) @client {"),
    cacheUpdaters: { fields, fragments },
  };
};
