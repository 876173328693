export default {
  name: "asset image",
  plural: "asset images",
  columns: {
    asset: "asset",
    assetId: "asset ID",
    id: "ID",
    isDeleted: "is deleted",
    pictureBlobStorageUrl: "pictur eBlob Storage Url",
    tenantId: "tenant ID",
    thumbnailBlobStorageUrl: "thumbnail Blob Storage Url",
  },
};
