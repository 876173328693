import {
  assignedTagErrorAtom,
  freeSoloChipsListAtom,
  tagsToCreateAtom,
} from "components/organisms/TagsUpdateSidebar/tagsSidebarState";
import { useCreateAssetTagsMutation } from "graphqlBase/MappingAssetTag/__generated__/createAssetTags";
import { useCreateMappingAssetTagsMutation } from "graphqlBase/MappingAssetTag/__generated__/createMappingAssetTags";
import { GetAssetTagsDocument } from "graphqlBase/MappingAssetTag/__generated__/getAssetTags";
import { GetMappingAssetTagsDocument } from "graphqlBase/MappingAssetTag/__generated__/getMappingAssetTags";
import { MappingAssetTagCreateType } from "graphqlBase/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MappingAssetTag } from "..";
import { selectedAssetsForTagsAtom } from "../tagModalState";

const useCreateAssetTags = () => {
  const selectedAssetIds = useRecoilValue(selectedAssetsForTagsAtom).map((asset) => asset.id);
  const selectedTags = useRecoilValue(freeSoloChipsListAtom);
  const createAssetTags = useRecoilValue(tagsToCreateAtom) ?? [];
  const setAssignedTagError = useSetRecoilState(assignedTagErrorAtom);

  const [
    createMappingAssetTagsMutation,
    { loading: createMappingAssetTagsLoading },
  ] = useCreateMappingAssetTagsMutation({
    refetchQueries: [{ query: GetMappingAssetTagsDocument }],
  });

  const [createAssetTagsMutation, { loading: createAssetTagsLoading }] = useCreateAssetTagsMutation({
    refetchQueries: [{ query: GetAssetTagsDocument }],
  });

  const handleCreateAssetTags = async ({ mappingAssetTags }: { mappingAssetTags: MappingAssetTag[] }) => {
    try {
      const { data } = await createAssetTagsMutation({ variables: { createAssetTags } });

      if (!createAssetTags.length && !selectedTags.length) return;

      const createAssetMappings = selectedAssetIds.reduce((accAsset: MappingAssetTagCreateType[], currAssetId) => {
        const temp = selectedTags.reduce((accChip: MappingAssetTagCreateType[], currTag) => {
          const index = mappingAssetTags.findIndex(
            (entry) => entry.assetId === currAssetId && entry.assetTagId === currTag.value
          );
          if (index === -1) return accChip.concat({ assetTagId: currTag.value, assetId: currAssetId });
          return accChip;
        }, []);

        const newMappings: MappingAssetTagCreateType[] =
          data?.createAssetTags?.map((newTag) => {
            return { assetTagId: newTag?.id ?? "", assetId: currAssetId };
          }) ?? [];

        const allMappings = temp.concat(newMappings);
        return accAsset.concat(allMappings);
      }, []);
      if (createAssetMappings.length > 0) {
        await createMappingAssetTagsMutation({ variables: { createMappingAssetTags: createAssetMappings } });
      } else {
        setAssignedTagError("This tag is already assigned");
        setTimeout(() => {
          setAssignedTagError(undefined);
        }, 3000);
      }
    } catch (error) {
      setAssignedTagError("This tag is already assigned");
      setTimeout(() => {
        setAssignedTagError(undefined);
      }, 3000);
    }
  };

  return {
    createAssetTagsLoading: createMappingAssetTagsLoading || createAssetTagsLoading,
    handleCreateAssetTags,
  };
};

export default useCreateAssetTags;
