import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetCapabilitiesByNameQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CapabilityFilterInput>;
}>;


export type GetCapabilitiesByNameQuery = { capabilities: Array<{ __typename?: 'Capability', id: string, capabilityName: string }> };


export const GetCapabilitiesByNameDocument = gql`
    query getCapabilitiesByName($where: CapabilityFilterInput) {
  capabilities(where: $where) {
    id
    capabilityName
  }
}
    `;

/**
 * __useGetCapabilitiesByNameQuery__
 *
 * To run a query within a React component, call `useGetCapabilitiesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapabilitiesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapabilitiesByNameQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCapabilitiesByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCapabilitiesByNameQuery, GetCapabilitiesByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCapabilitiesByNameQuery, GetCapabilitiesByNameQueryVariables>(GetCapabilitiesByNameDocument, options);
      }
export function useGetCapabilitiesByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCapabilitiesByNameQuery, GetCapabilitiesByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCapabilitiesByNameQuery, GetCapabilitiesByNameQueryVariables>(GetCapabilitiesByNameDocument, options);
        }
export type GetCapabilitiesByNameQueryHookResult = ReturnType<typeof useGetCapabilitiesByNameQuery>;
export type GetCapabilitiesByNameLazyQueryHookResult = ReturnType<typeof useGetCapabilitiesByNameLazyQuery>;
export type GetCapabilitiesByNameQueryResult = Apollo.QueryResult<GetCapabilitiesByNameQuery, GetCapabilitiesByNameQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
