import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTextareaProps = DropOnONLh<JSX.PatternlibTextarea>;
export type PLTextareaExtendedProps = PLTextareaProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTextarea = wrapWc<JSX.PatternlibTextarea>("patternlib-textarea");
export const useRefPLTextarea = () => useRef<HTMLPatternlibTextareaElement>(null);

const PLTextarea = React.forwardRef<HTMLPatternlibTextareaElement, PLTextareaExtendedProps>((props, ref) => {
  const myRef = useRefPLTextarea();
  return (
    <WrappedTextarea ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTextarea>
  );
});
PLTextarea.displayName = "PLTextarea";

export default PLTextarea;
