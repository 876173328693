import styled from "@emotion/styled";
import { GetAllStorageAreasQuery, ResultType } from "graphqlBase/StorageAreas/__generated__/getAllStorageAreas";
import "leaflet.markercluster";
import React from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { DeviceInGeofence } from "..";
import SetBoundsRectangles from "./SetBoundsRectangles";
import StorageAreas from "./StorageAreas";
import ToggleMapButton from "./ToggleMapButton";
export type StorageArea = ResultType<GetAllStorageAreasQuery["storageAreas"]>;

const MapContainerSC = styled(MapContainer)({
  height: "calc(100vh - 150px)",
  "@media (max-height:920px)": {
    height: "100vh",
  },
  width: "100%",
  zIndex: 0,
  [`& .leaflet-control-attribution `]: {
    display: "none",
  },
  [`& .leaflet-popup-content-wrapper`]: {
    borderRadius: 0,
    border: "none",
  },
  [`& .leaflet-popup-close-button`]: {
    display: "none",
  },
  [`& .markerIcon`]: {
    background: "white",
    textAlign: "center",
    fontFamily: "var(--copytext-font-family-bold)",
    padding: "5px 15px",
    whiteSpace: "nowrap",
    fontSize: "12px",
    position: "relative",
    display: "inline-block",
    boxShadow: "0px 3px 4px rgba(58, 64, 70, 0.3)",
    "&::before": {
      content: '""',
      position: "absolute",
      textAlign: "center",
      top: "100%",
      right: "10px",
      borderWidth: "12px",
      borderColor: "white transparent  transparent transparent",
      borderStyle: "solid",
    },
  },
  [`& .zoomedInstorageAreaLabel`]: {
    fontFamily: "var(--copytext-font-family-bold)",
    color: "#FFFFFF",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
  },
});

interface GeofenceMapContainerProps {
  devicesByStorageAreaId: { [key: string]: DeviceInGeofence[] };
  storageAreas: StorageArea[];
}

const GeofenceMapContainer: React.FC<GeofenceMapContainerProps> = ({ storageAreas, devicesByStorageAreaId }) => {
  return (
    <MapContainerSC center={[52.634281158447266, 13.288796424865723]} zoom={5} zoomControl={false}>
      <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <ToggleMapButton />
      <ZoomControl position="bottomright" />
      <StorageAreas storageAreas={storageAreas} devicesByStorageAreaId={devicesByStorageAreaId} />
      <SetBoundsRectangles storageAreas={storageAreas} />
    </MapContainerSC>
  );
};
export default GeofenceMapContainer;
