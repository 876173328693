import React from "react";

import Avatar from "components/lbc-toolkit/atomics/Avatar";
import { useAuth } from "oidc-react";
import { Button, styled, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import DefaultButton from "components/lbc-toolkit/atomics/Button/DefaultButton";
import getTestIDs from "lib/utils/getTestIDs";
import Select, { SelectChangeEv } from "components/lbc-toolkit/molecules/Select";
import translations, { languageNames } from "translations";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useUpdateLanguageMutation } from "graphqlBase/Language/__generated__/updateLanguage";
import colors from "themes/colors";
import {
  GetUserIdAndLanguageDocument,
  useGetUserIdAndLanguageQuery,
} from "graphqlBase/Language/__generated__/getUserIdAndLanguage";
import { useGetRoleByUserQuery } from "graphqlBase/Settings/__generated__/getRolesByUser";
import Can from "authorization";
import PLAvatar from "components/patternLib/PLAvatar";
import ContentHeader from "components/organisms/ContentHeader";
import PLTextlink from "components/patternLib/PLTextlink";
import PLButton from "components/patternLib/PLButton";

export const testIDs = getTestIDs();
const SettingsSC = styled("div")(({ theme }) => {
  return {
    backgroundColor: "#fff",
    "& .root": {
      display: "flex",
      flexDirection: "column",
      rowGap: "2em",
      padding: "2em 0 2em 3em",
    },
    "& .avatarAndName": {
      display: "flex",
      columnGap: `${theme.spacing(4)}`,
    },
    "& .personalData": {
      display: "flex",
      flexDirection: "row",
      columnGap: `${theme.spacing(8)}`,
      marginBottom: `${theme.spacing(4)}`,
    },
    "& .personalDataItem": {
      display: "flex",
      flexDirection: "column",
    },
    "& .buttons": {
      display: "flex",
      flexDirection: "column",
      rowGap: `${theme.spacing(12)}`,
    },
  };
});

const Settings = () => {
  const auth = useAuth();
  const variables = {
    where: { userName: { eq: auth?.userData?.profile.preferred_username } },
  };

  const { data: roles } = useGetRoleByUserQuery({ variables });

  const signOutFunc = () => {
    auth?.signOutRedirect().finally(() => {});
  };
  return (
    <SettingsSC>
      <ContentHeader />
      <div className="root">
        <div className="avatarAndName">
          <PLAvatar
            initials={`${(auth?.userData?.profile.given_name ?? "")[0]}${
              (auth?.userData?.profile.family_name ?? "")[0]
            }`}
          />
          <Typography variant="h6" sx={{ alignSelf: "center" }}>
            {`${auth?.userData?.profile.given_name ?? ""} ${auth?.userData?.profile.family_name ?? ""}`}
          </Typography>
        </div>
        <div className="personalData">
          <div className="personalDataItem">
            <Typography variant="copyBold">E-mail address</Typography>
            <Typography variant="copyBold">Roles</Typography>
            <Typography variant="copyBold">Tenant</Typography>
          </div>
          <div className="personalDataItem">
            <Typography variant="copy">{`${auth?.userData?.profile.email ?? ""}`}</Typography>
            <Typography variant="copy">
              {roles?.users.map((user) => user.mappingRoleUsers.map((role) => role.role.name).join(", "))}
            </Typography>
            <Typography variant="copy">{`${auth?.userData?.profile.company_name ?? ""}`}</Typography>
          </div>
        </div>
        <div className="buttons">
          <Can I="read" a="UpdateRoleUI">
            <Link to="/settings/usermanagement">
              <PLTextlink label={`${translations.globals.terms.user} ${translations.globals.terms.authorization}`} />
            </Link>
          </Can>
          <PLButton label="Log Out" onClick={signOutFunc} width="135px" />
        </div>
      </div>
    </SettingsSC>
  );
};
export default Settings;
