import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMappingRoleUsersMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type DeleteMappingRoleUsersMutation = { deleteMappingRoleUsers?: Maybe<Array<Maybe<{ __typename?: 'MappingRoleUser', id: string, roleId: string, userId: string, isDeleted?: Maybe<boolean>, role: { __typename?: 'Role', id: string }, user: { __typename?: 'User', id: string } }>>> };


export const DeleteMappingRoleUsersDocument = gql`
    mutation deleteMappingRoleUsers($ids: [UUID!]) {
  deleteMappingRoleUsers(ids: $ids) {
    id
    roleId
    userId
    isDeleted
    role {
      id
    }
    user {
      id
    }
  }
}
    `;
export type DeleteMappingRoleUsersMutationFn = Apollo.MutationFunction<DeleteMappingRoleUsersMutation, DeleteMappingRoleUsersMutationVariables>;

/**
 * __useDeleteMappingRoleUsersMutation__
 *
 * To run a mutation, you first call `useDeleteMappingRoleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMappingRoleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMappingRoleUsersMutation, { data, loading, error }] = useDeleteMappingRoleUsersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteMappingRoleUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMappingRoleUsersMutation, DeleteMappingRoleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteMappingRoleUsersMutation, DeleteMappingRoleUsersMutationVariables>(DeleteMappingRoleUsersDocument, options);
      }
export type DeleteMappingRoleUsersMutationHookResult = ReturnType<typeof useDeleteMappingRoleUsersMutation>;
export type DeleteMappingRoleUsersMutationResult = Apollo.MutationResult<DeleteMappingRoleUsersMutation>;
export type DeleteMappingRoleUsersMutationOptions = Apollo.BaseMutationOptions<DeleteMappingRoleUsersMutation, DeleteMappingRoleUsersMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
