import { Box, Divider, styled, Typography } from "@mui/material";
import { useGetDeviceForDeviceDetailsQuery } from "graphqlBase/Devices/__generated__/getDeviceForDeviceDetails";
import React from "react";
import { useParams } from "react-router-dom";
import translations from "translations";
import TrackerLargeSmall from "./TrackerLargeSmall";
import DetailInfo from "components/atomics/DetailInfo";
import M2CPInfoTile from "./M2CPInfoTile";
import AssetInfo from "./AssetInfo";
interface DeviceDataTilesProps {
  gatewayDeviceConnectionId?: string;
  lastTimeConnection: {
    key: string;
    value: string;
  };
}

const BasicsTileWrapperSC = styled("div")(
  ({ theme }) => `
    background-color: ${theme.palette.background.paper};
    margin: 36px 0 36px 36px;
    padding: 30px;
    height: fit-content;
`
);

const titleBoxStyles = { marginBottom: "25px" };

const DeviceDataTiles: React.FC<DeviceDataTilesProps> = ({ lastTimeConnection, gatewayDeviceConnectionId }) => {
  const { deviceId }: { deviceId: string } = useParams();
  const variables = {
    id: deviceId,
    capabilityIds: [
      "5063bcd9-9e7b-42f6-84b4-eeac931a2b1f",
      "e4b14165-5282-4e38-99e4-c90f5b3adc9f",
      "2d68c5ca-5caf-4666-8901-980bf33534ff",
      "80bec84b-2c50-4e12-9a70-56b06e5b92e5",
    ],
  };
  const { data, loading } = useGetDeviceForDeviceDetailsQuery({ variables });

  const inverseParentDeviceLength = data?.device?.inverseParentDevice.length;

  const toolManagementControlUnitInfo = [
    {
      label: translations.entities.device.columns.serialNo,
      value: data?.device?.inverseParentDevice[0]?.serialNo ?? "",
    },
  ];

  const relayModuleInfo = [
    {
      label: translations.entities.device.columns.serialNo,
      value: data?.device?.inverseParentDevice[1]?.serialNo ?? "",
    },
    {
      label: translations.entities.deviceModel.columns.firmwareVersion,
      value:
        data?.device?.inverseParentDevice[1]?.mappingDeviceFirmwareDevice[0]?.deviceFirmware?.firmwareVersion ?? "",
    },
  ];

  return (
    <>
      <BasicsTileWrapperSC>
        <Box sx={titleBoxStyles}>
          <Typography variant="h4">
            {translations.pages.deviceManagement.deviceDetails.deviceDetailsTile.label}
          </Typography>
        </Box>
        {gatewayDeviceConnectionId ? (
          <M2CPInfoTile
            gatewayDeviceConnectionId={gatewayDeviceConnectionId}
            deviceTypeName={data?.device?.deviceType.deviceTypeName ?? ""}
            serialNo={data?.device?.serialNo ?? ""}
          />
        ) : (
          <TrackerLargeSmall lastTimeConnection={lastTimeConnection} deviceDetailsData={data} loading={loading} />
        )}
        <Divider variant="middle" sx={{ margin: "21px 0" }} />
        <AssetInfo deviceDetailsData={data} loading={loading} />
      </BasicsTileWrapperSC>

      {!!inverseParentDeviceLength && inverseParentDeviceLength > 0 && (
        <>
          <BasicsTileWrapperSC>
            <Box sx={titleBoxStyles}>
              <Typography variant="h6">
                {data?.device?.inverseParentDevice[0]?.deviceType.deviceTypeName ?? ""}
              </Typography>
            </Box>
            <DetailInfo noSeparator data={toolManagementControlUnitInfo} itemHeight="0" />
          </BasicsTileWrapperSC>
          <BasicsTileWrapperSC>
            <Box sx={titleBoxStyles}>
              <Typography variant="h6">
                {data?.device?.inverseParentDevice[1]?.deviceType.deviceTypeName ?? ""}
              </Typography>
            </Box>
            <DetailInfo noSeparator data={relayModuleInfo} itemHeight="0" />
          </BasicsTileWrapperSC>
        </>
      )}
    </>
  );
};
export default DeviceDataTiles;
