import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type AddAssetMasterDataToAssetMutationVariables = Types.Exact<{
  assets?: Types.InputMaybe<Array<Types.InputMaybe<Types.AssetUpdateType>> | Types.InputMaybe<Types.AssetUpdateType>>;
}>;


export type AddAssetMasterDataToAssetMutation = { updateAssets?: Maybe<Array<Maybe<{ __typename?: 'Asset', id: string }>>> };


export const AddAssetMasterDataToAssetDocument = gql`
    mutation addAssetMasterDataToAsset($assets: [AssetUpdateType]) {
  updateAssets(assets: $assets) {
    id
  }
}
    `;
export type AddAssetMasterDataToAssetMutationFn = Apollo.MutationFunction<AddAssetMasterDataToAssetMutation, AddAssetMasterDataToAssetMutationVariables>;

/**
 * __useAddAssetMasterDataToAssetMutation__
 *
 * To run a mutation, you first call `useAddAssetMasterDataToAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetMasterDataToAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetMasterDataToAssetMutation, { data, loading, error }] = useAddAssetMasterDataToAssetMutation({
 *   variables: {
 *      assets: // value for 'assets'
 *   },
 * });
 */
export function useAddAssetMasterDataToAssetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAssetMasterDataToAssetMutation, AddAssetMasterDataToAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddAssetMasterDataToAssetMutation, AddAssetMasterDataToAssetMutationVariables>(AddAssetMasterDataToAssetDocument, options);
      }
export type AddAssetMasterDataToAssetMutationHookResult = ReturnType<typeof useAddAssetMasterDataToAssetMutation>;
export type AddAssetMasterDataToAssetMutationResult = Apollo.MutationResult<AddAssetMasterDataToAssetMutation>;
export type AddAssetMasterDataToAssetMutationOptions = Apollo.BaseMutationOptions<AddAssetMasterDataToAssetMutation, AddAssetMasterDataToAssetMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
