export default {
  name: "device attribute value",
  plural: "device attribute values",
  columns: {
    device: "device",
    deviceAttribute: "device attribute",
    deviceAttributeId: "device attribute ID",
    deviceId: "device ID",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
    value: "value",
  },
};
