import { AutocompleteChangeReason, Box } from "@mui/material";
import MultiSelectFreeSolo, { SelectItem } from "components/molecules/MultiSelectFreeSolo";
import {
  SelectedUser,
  hasFormErrorsAtom,
  isTheFormValidSelector,
  selectedUsersForAlertAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import translations from "translations";
import Props from "./index.d";
import useManageMappingUserNotifications from "./useManageMappingUserNotifications";
import MandatoryFieldMessage from "components/pages/AlertManagement/AlertEditing/MandatoryFieldMessage";
import { SortEnumType } from "graphqlBase/types";
import { isEqual, uniqWith } from "lodash";

const MappingUserNotifications: React.FC<Props> = (props) => {
  const [selectedUsers, setSelectedUsers] = useRecoilState(selectedUsersForAlertAtom);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [uiOptions, setUiOptions] = useState<SelectItem[]>([]);
  const { title, disabled, raftVariables } = props;
  const notificationId = raftVariables.id;
  const { userCount } = useRecoilValue(isTheFormValidSelector);
  const formHasErrors = useRecoilValue(hasFormErrorsAtom);
  
  const { getPreSelectedUsers, loading, usersData, getAllUsers } = useManageMappingUserNotifications(notificationId);

  useEffect(() => {
    if (selectedUsers || loading) return;
    getPreSelectedUsers();
  }, [loading]);

  useEffect(() => {
    getAllUsers({ variables: { where: searchText ? { email: { contains: searchText } } : {}, take: 8,order: { email:"ASC" as SortEnumType} }});
  }, [searchText, getAllUsers]);

  useEffect(() => {
    if (!usersData || !usersData?.users?.length) return;

    const newOptions = (usersData?.users ?? []).reduce<SelectItem[]>((acc, current) => {
      return acc.concat({ label: current?.label?.toLowerCase() ?? "", value: current?.value });
    }, []);

    setUiOptions(newOptions);

    setOptions((prevOptions) =>
      prevOptions.concat(
        newOptions.reduce<SelectItem[]>((acc, current) => {
          if (prevOptions.find((prev) => prev.label === current.label)) return acc;
          return acc.concat(current);
        }, [])
      )
    );
  }, [usersData]);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: (string | SelectItem)[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "createOption") return;
  
    if (reason === "removeOption") {
      setSelectedUsers(value as SelectedUser[]);
    } 
    else if (!value?.length) {
      setSelectedUsers([]);
    } else {
      const newSelectedUsers = (value as SelectItem[]).filter((item) => {
        return !selectedUsers?.some((selectedUser) => selectedUser.value === item.value);
      });
  
      setSelectedUsers([...selectedUsers ?? [], ...newSelectedUsers as SelectedUser[]]);
  
      const optionsDeduped = uniqWith([...value as SelectItem[], ...options], isEqual);
      setUiOptions(optionsDeduped);
    }
  };
  

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const isStatusError = !userCount && formHasErrors

  const selectedUsersForUI= useMemo(() => selectedUsers?.reduce<SelectItem[]>((users,curr) =>{return [...users,({label: curr?.label?.toLowerCase() ?? "", value: curr.value})]},[]), [selectedUsers])

  return (
    <Box sx={{ flexDirection: "column" }}>
      <MultiSelectFreeSolo
        isStatusError={isStatusError}
        onChangeInput={handleInputChange}
        label={title}
        disabled={disabled}
        options={uiOptions ?? []}
        filterOptions={(options) => options}
        onChange={handleChange}
        placeholder={translations.globals.placeholders.createTag}
        selected={selectedUsersForUI}
        inputValue={searchText}
        customSx={{
          minWidth: "fit-content",
          width: 280,
        }}
      />
      {isStatusError && <MandatoryFieldMessage />}
    </Box>
  );
};

export default MappingUserNotifications;