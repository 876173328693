import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetGroupEntitiesForQueryBuilderQueryVariables = Types.Exact<{
  entityName: Types.Scalars['String'];
}>;


export type GetGroupEntitiesForQueryBuilderQuery = { groupEntities: Array<{ __typename?: 'GroupEntity', entityName: string, id: string, mappingGroupEntityGroupEntityFilter: Array<{ __typename?: 'MappingGroupEntityGroupEntityFilter', id: string, groupEntityFilter: { __typename?: 'GroupEntityFilter', displayName: string, entityName: string, entityColumnName: string, groupEntityFilterUiType: { __typename?: 'GroupEntityFilterUiType', id: string, groupEntityFilterUiTypeName: string }, groupEntityFilterDataType: { __typename?: 'GroupEntityFilterDataType', id: string, groupEntityFilterDataTypeName: string }, mappingGroupEntityFilterGroupOperator: Array<{ __typename?: 'MappingGroupEntityFilterGroupOperator', id: string, groupOperator: { __typename?: 'GroupOperator', id: string, operator: string } }> } }> }> };


export const GetGroupEntitiesForQueryBuilderDocument = gql`
    query getGroupEntitiesForQueryBuilder($entityName: String!) {
  groupEntities(where: {entityName: {eq: $entityName}}) {
    entityName
    id
    mappingGroupEntityGroupEntityFilter {
      id
      groupEntityFilter {
        displayName
        entityName
        entityColumnName
        groupEntityFilterUiType {
          id
          groupEntityFilterUiTypeName
        }
        groupEntityFilterDataType {
          id
          groupEntityFilterDataTypeName
        }
        mappingGroupEntityFilterGroupOperator {
          id
          groupOperator {
            id
            operator
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGroupEntitiesForQueryBuilderQuery__
 *
 * To run a query within a React component, call `useGetGroupEntitiesForQueryBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupEntitiesForQueryBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupEntitiesForQueryBuilderQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useGetGroupEntitiesForQueryBuilderQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetGroupEntitiesForQueryBuilderQuery, GetGroupEntitiesForQueryBuilderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGroupEntitiesForQueryBuilderQuery, GetGroupEntitiesForQueryBuilderQueryVariables>(GetGroupEntitiesForQueryBuilderDocument, options);
      }
export function useGetGroupEntitiesForQueryBuilderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupEntitiesForQueryBuilderQuery, GetGroupEntitiesForQueryBuilderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGroupEntitiesForQueryBuilderQuery, GetGroupEntitiesForQueryBuilderQueryVariables>(GetGroupEntitiesForQueryBuilderDocument, options);
        }
export type GetGroupEntitiesForQueryBuilderQueryHookResult = ReturnType<typeof useGetGroupEntitiesForQueryBuilderQuery>;
export type GetGroupEntitiesForQueryBuilderLazyQueryHookResult = ReturnType<typeof useGetGroupEntitiesForQueryBuilderLazyQuery>;
export type GetGroupEntitiesForQueryBuilderQueryResult = Apollo.QueryResult<GetGroupEntitiesForQueryBuilderQuery, GetGroupEntitiesForQueryBuilderQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
