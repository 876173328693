import colors from "../colors";

export const muiSelectOverrides = {
  root: {
    display: "flex",
    flexFlow: "row wrap",
  },
  select: {
    "&:focus": {
      backgroundColor: colors.tWhite,
      borderColor: colors.grey400,
      borderRadius: 0,
      boxShadow: `rgb(0 0 0 / 25%) 0 4px 16px 0`,
      "&:hover": {
        backgroundColor: colors.tWhite,
        borderColor: colors.grey400,
      },
    },
    "&:hover": {
      backgroundColor: colors.grey200,
      borderColor: colors.grey200,
    },
  },
  selectMenu: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
  nativeInput: {
    display: "none",
  },
};
