import { GridColDef } from "@mui/x-data-grid-pro";
import SidebarDrawer from "components/organisms/SidebarDrawer";
import Table from "components/templates/dataGridTable/Table";
import {
  GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables,
  useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery,
} from "graphqlBase/SensorMeasurementHistories/__generated__/getDeviceStateSensorMeasurementsForAssetStateHistory";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import translations from "translations";
import { formatDateTime } from "translations/formatter";
import { deviceIdState } from "components/pages/AssetManagement/AssetDetails/assetDetailState";
import { assetDeviceApplicationState } from "components/pages/AssetManagement/utils";
import { dateRangeState } from "../../filtersState";

const TODAY_DATE = new Date().toISOString();

interface AssetStateHistorySidebarProps {
  onClose: () => void;
  open: boolean;
}

interface AssetState {
  assetState: string;
  dateEnd: string;
  dateStart: string;
  id: string;
}

export const AssetStateHistorySidebar = ({ onClose, open }: AssetStateHistorySidebarProps) => {
  const deviceId = useRecoilValue(deviceIdState);
  const selectedDate = useRecoilValue(dateRangeState);
  const [assetStateData, setAssetStateData] = useState<AssetState[] | undefined>();

  const { data, loading } = useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery({
    variables: {
      where: {
        deviceId: { eq: deviceId },
        utcTimeMeasured: { gte: selectedDate?.start.toISOString(), lte: selectedDate?.end.toISOString() },
        capabilityId: { eq: "5063bcd9-9e7b-42f6-84b4-eeac931a2b1f" },
      },
      order: { utcTimeMeasured: "DESC" },
    } as GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables,
  });

  const { terms, pluralTerms, time } = translations.globals;
  const { noAssetStateHistoryMessage } = translations.pages.assetManagement.assetDetailGraphs.assetState;

  useEffect(() => {
    if (data && data.sensorMeasurementHistories) {
      const arrayLength = data.sensorMeasurementHistories.length;
      if (arrayLength > 0) {
        const assetStates = data.sensorMeasurementHistories.reduce<AssetState[]>((assetStates, current, index) => {
          const currentStateName = assetDeviceApplicationState[current?.valueInt ?? 0]?.label;
          const prevState = data.sensorMeasurementHistories[index - 1];

          const sameState =
            prevState && assetDeviceApplicationState[prevState.valueInt ?? -1]?.label === currentStateName;

          if (!sameState) {
            const dateEnd = index === 0 ? "current" : assetStates[assetStates.length - 1]?.dateStart;
            const dateStart = current?.utcTimeMeasured ?? TODAY_DATE;

            const dataItem = {
              id: current.id,
              assetState: currentStateName,
              dateStart,
              dateEnd,
            };
            return assetStates.concat(dataItem);
          }

          return assetStates;
        }, []);
        setAssetStateData(assetStates);
      }
    }
  }, [data]);

  const columns: GridColDef<AssetState>[] = [
    {
      field: `${time.date}${terms.interval}`,
      headerName: pluralTerms.dates,
      flex: 2,
      valueGetter: (params) => {
        const dateStart = formatDateTime(new Date(params.row.dateStart));
        const dateEnd =
          params.row.dateEnd === "current" ? params.row.dateEnd : formatDateTime(new Date(params.row.dateEnd));
        return `${dateStart} - ${dateEnd}`;
      },
    },
    {
      field: "assetState",
      headerName: `${terms.coupling} ${terms.state}`,
      flex: 1,
      valueGetter: (params) => {
        return params.row.assetState ?? "";
      },
    },
  ];

  return (
    <SidebarDrawer
      title={translations.pages.assetManagement.assetDetailGraphs.assetState.stateHistory}
      open={open}
      onClose={onClose}
    >
      <Table
        noDataMessage={noAssetStateHistoryMessage}
        tableHeight="75vh"
        columns={columns}
        rows={assetStateData ?? []}
        loading={loading}
        hideToolbar
        checkboxSelection={false}
      />
    </SidebarDrawer>
  );
};
