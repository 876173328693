import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetSorageAreaCoordinatesOnIdsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type GetSorageAreaCoordinatesOnIdsQuery = { storageAreas: Array<{ __typename?: 'StorageArea', id: string, storageAreaName: string, geofence?: Maybe<{ __typename?: 'GeoJSONPolygonType', coordinates?: Maybe<Array<Maybe<Array<Maybe<[number,number]>>>>>, crs: number, type: Types.GeoJsonGeometryType }> }> };


export const GetSorageAreaCoordinatesOnIdsDocument = gql`
    query getSorageAreaCoordinatesOnIds($ids: [UUID!]) {
  storageAreas(where: {storageAreaId: {in: $ids}}) {
    id
    storageAreaName
    geofence {
      coordinates
      crs
      type
    }
  }
}
    `;

/**
 * __useGetSorageAreaCoordinatesOnIdsQuery__
 *
 * To run a query within a React component, call `useGetSorageAreaCoordinatesOnIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSorageAreaCoordinatesOnIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSorageAreaCoordinatesOnIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetSorageAreaCoordinatesOnIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSorageAreaCoordinatesOnIdsQuery, GetSorageAreaCoordinatesOnIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSorageAreaCoordinatesOnIdsQuery, GetSorageAreaCoordinatesOnIdsQueryVariables>(GetSorageAreaCoordinatesOnIdsDocument, options);
      }
export function useGetSorageAreaCoordinatesOnIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSorageAreaCoordinatesOnIdsQuery, GetSorageAreaCoordinatesOnIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSorageAreaCoordinatesOnIdsQuery, GetSorageAreaCoordinatesOnIdsQueryVariables>(GetSorageAreaCoordinatesOnIdsDocument, options);
        }
export type GetSorageAreaCoordinatesOnIdsQueryHookResult = ReturnType<typeof useGetSorageAreaCoordinatesOnIdsQuery>;
export type GetSorageAreaCoordinatesOnIdsLazyQueryHookResult = ReturnType<typeof useGetSorageAreaCoordinatesOnIdsLazyQuery>;
export type GetSorageAreaCoordinatesOnIdsQueryResult = Apollo.QueryResult<GetSorageAreaCoordinatesOnIdsQuery, GetSorageAreaCoordinatesOnIdsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
