import { Box, Divider } from "@mui/material";
import { SelectItem } from "components/molecules/MultiSelectFreeSolo";
import SidebarDrawer from "components/organisms/SidebarDrawer";
import PLButton from "components/patternLib/PLButton";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { FooterButtonsContainerSC, FooterButtonsSC, footerDividerStyles, TagModalContentSC } from "./styles";
import TagsInput from "./TagsInput";
import TagsList from "./TagsList";
import { assignedTagErrorAtom } from "./tagsSidebarState";
import Slide, { SlideProps } from "@mui/material/Slide";
import Toast from "components/atomics/Toast";

export const testIDs = getTestIDs();
export interface Tag {
  id: string;
  tagValue: string;
}
function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}
const TagsUpdateSidebar: React.FC<{
  handleAdd: () => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  itemTags: Array<Tag>;
  loading?: boolean;
  onCancel?: () => void;
  onClose: () => void;
  onDone?: () => void;
  open: boolean;
  tagOptions: SelectItem[];
  title: string;
}> = ({ open = false, onClose, loading, title, handleDelete, itemTags, handleAdd, tagOptions, onCancel, onDone }) => {
  const [assignedTagError, setAssignedTagError] = useRecoilState(assignedTagErrorAtom);
  const footerButtons = (
    <Box>
      <Divider color="#000" sx={footerDividerStyles} />
      <FooterButtonsContainerSC>
        <FooterButtonsSC>
          <PLButton disabled={loading} type="secondary" label={translations.globals.button.cancel} onClick={onCancel} />
          <PLButton
            disabled={loading}
            style={{ marginLeft: "30px" }}
            type="primary"
            label={translations.globals.button.done}
            onClick={onDone}
          />
        </FooterButtonsSC>
      </FooterButtonsContainerSC>
    </Box>
  );

  const uniqueIds = new Set();

  const uniqueTags = itemTags?.filter((element) => {
    const isDuplicate = uniqueIds.has(element.id);

    uniqueIds.add(element.id);

    if (!isDuplicate) {
      return true;
    }
    return false;
  });

  const handleCloseToast = () => {
    setAssignedTagError(undefined);
  };

  return (
    <SidebarDrawer
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      maxWidth="22vw"
      footerContent={footerButtons}
    >
      <TagModalContentSC>
        <TagsList tags={uniqueTags} handleDelete={handleDelete} loading={loading} />
        <Divider variant="fullWidth" color="#F0F3F6" />
        <TagsInput loading={loading} handleAdd={handleAdd} tagOptions={tagOptions} assetTags={itemTags} />
        {assignedTagError && (
          <Toast
            open={!loading && !!assignedTagError}
            message={assignedTagError}
            handleCloseToast={handleCloseToast}
            severity="error"
          />
        )}
      </TagModalContentSC>
    </SidebarDrawer>
  );
};

export default TagsUpdateSidebar;
