import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMappingDeviceFirmwareDevicesMutationVariables = Types.Exact<{
  mappingDeviceFirmwareDevices?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingDeviceFirmwareDeviceCreateType>> | Types.InputMaybe<Types.MappingDeviceFirmwareDeviceCreateType>>;
}>;


export type CreateMappingDeviceFirmwareDevicesMutation = { createMappingDeviceFirmwareDevices?: Maybe<Array<Maybe<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, deviceId: string, dateFrom: string, dateTo: string }>>> };


export const CreateMappingDeviceFirmwareDevicesDocument = gql`
    mutation createMappingDeviceFirmwareDevices($mappingDeviceFirmwareDevices: [MappingDeviceFirmwareDeviceCreateType]) {
  createMappingDeviceFirmwareDevices(mappingDeviceFirmwareDevices: $mappingDeviceFirmwareDevices) {
    id
    deviceId
    dateFrom
    dateTo
  }
}
    `;
export type CreateMappingDeviceFirmwareDevicesMutationFn = Apollo.MutationFunction<CreateMappingDeviceFirmwareDevicesMutation, CreateMappingDeviceFirmwareDevicesMutationVariables>;

/**
 * __useCreateMappingDeviceFirmwareDevicesMutation__
 *
 * To run a mutation, you first call `useCreateMappingDeviceFirmwareDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingDeviceFirmwareDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingDeviceFirmwareDevicesMutation, { data, loading, error }] = useCreateMappingDeviceFirmwareDevicesMutation({
 *   variables: {
 *      mappingDeviceFirmwareDevices: // value for 'mappingDeviceFirmwareDevices'
 *   },
 * });
 */
export function useCreateMappingDeviceFirmwareDevicesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMappingDeviceFirmwareDevicesMutation, CreateMappingDeviceFirmwareDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMappingDeviceFirmwareDevicesMutation, CreateMappingDeviceFirmwareDevicesMutationVariables>(CreateMappingDeviceFirmwareDevicesDocument, options);
      }
export type CreateMappingDeviceFirmwareDevicesMutationHookResult = ReturnType<typeof useCreateMappingDeviceFirmwareDevicesMutation>;
export type CreateMappingDeviceFirmwareDevicesMutationResult = Apollo.MutationResult<CreateMappingDeviceFirmwareDevicesMutation>;
export type CreateMappingDeviceFirmwareDevicesMutationOptions = Apollo.BaseMutationOptions<CreateMappingDeviceFirmwareDevicesMutation, CreateMappingDeviceFirmwareDevicesMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
