import React from "react";

const CheckIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg height="18" width="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect rx="4" fill="inherit" />
    <path
      d="M6.73047 13.7812C7.00391 14.0547 7.46875 14.0547 7.74219 13.7812L15.7812 5.74219C16.0547 5.46875 16.0547 5.00391 15.7812 4.73047L14.7969 3.74609C14.5234 3.47266 14.0859 3.47266 13.8125 3.74609L7.25 10.3086L4.16016 7.24609C3.88672 6.97266 3.44922 6.97266 3.17578 7.24609L2.19141 8.23047C1.91797 8.50391 1.91797 8.96875 2.19141 9.24219L6.73047 13.7812Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckIcon;
