import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Theme } from "@mui/material";
import Props from "./index.d";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      // backgroundColor: theme.palette.background.paper
    },
    child: {
      display: "inherit",
      marginLeft: 30,
    },
  })
);
interface GFCFieldSet_Horizontal extends Props {
  children: React.ReactNode;
}

const FieldSetHorizontal: React.FC<GFCFieldSet_Horizontal> = (props) => {
  const {} = props;
  const classes = useStyles();
  //  @ts-ignore
  if (props.hidden) return null;
  return <div className={classes.root}>{props.children}</div>;
};
export default FieldSetHorizontal;
