import { LhChangeItem } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { Box, Grid } from "@mui/material";
import Can from "authorization";
import { authorizedTabsArray } from "authorization/AuthorizedTabs/authorizedTabsArray";
import AuthorizedTabsCan from "authorization/AuthorizedTabs/AuthorizedTabsCan";
import ContentHeader from "components/organisms/ContentHeader";
import { contentHeaderTitleAtom } from "components/organisms/ContentHeader/contentHeaderRecoilState";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import PLTabs from "components/patternLib/PLTabs";
import PLTabsItem from "components/patternLib/PLTabsItem";
import useConfigQueryBuilder from "components/templates/QueryBuilder/useConfigQueryBuilder";
import { useGetDynamicGroupByIdQuery } from "graphqlBase/Groups/__generated__/getDynamicGroupById";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import theme from "themes/theme";
import translations from "translations";
import CurrentPositionTile from "./tiles/CurrentPositionTile";
import GroupInfoTile from "./tiles/GroupInfoTile";
import GroupQueryResultsTile from "./tiles/GroupQueryResultsTile";
import GroupResultCountTile from "./tiles/GroupResultCountTile";
import GroupWorkloadOverviewTile from "./tiles/GroupWorkloadOverviewTile";

export const testIDs = getTestIDs();

const rootStyles = {
  margin: "auto",
  backgroundColor: theme.palette.background.default,
};

const AssetGroupDetails: React.FC<{}> = () => {
  const [queryError, setQueryError] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [pageTitle, setPageTitle] = useRecoilState(contentHeaderTitleAtom);
  const { assetGroupId }: { assetGroupId: string } = useParams();

  const { setQueryStrings, queryStrings, executionResult, executeQuery, clearQueryStates } = useConfigQueryBuilder({
    entityName: "AssetDevice",
    queryStringsReturnValue: "deviceId",
  });

  const { data } = useGetDynamicGroupByIdQuery({
    variables: { id: assetGroupId },
    fetchPolicy: "cache-and-network",
  });

  const history = useHistory();

  useEffect(() => {
    return () => clearQueryStates();
  }, []);

  useEffect(() => {
    if (pageTitle !== data?.group?.groupName) setPageTitle(data?.group?.groupName ?? "");
    if (data && data?.group?.rAWQuery) {
      setQueryStrings({
        rAWQuery: data?.group?.rAWQuery,
        jSONQuery: data?.group?.jSONQuery,
      });
    }
  }, [data]);

  useEffect(() => {
    if (queryStrings?.error) {
      setQueryError(true);
    }

    if (!queryStrings?.error && !executionResult.data) {
      setTimeout(() => {
        executeQuery();
        setQueryError(false);
      }, 200);
    }
  }, [queryStrings]);

  const handleHeaderTabChange = (event: CustomEvent<LhChangeItem>) => {
    setCurrentTab(event.detail.itemId as number);
  };

  const handleEditAssetGroup = () => {
    history.push(`/assetManagement/updateAssetGroup/${assetGroupId}`);
  };

  const headerTabLabels = [
    { label: translations.globals.terms.dashboard, scope: "AssetGroupDashboardUI" },
    { label: `${translations.entities.asset.name} ${translations.globals.terms.list}`, scope: "AssetGroupAssetListUI" },
  ];

  const HeaderTabs = (
    <PLTabs lhChangeItem={handleHeaderTabChange} key="asset-group-details-tabs">
      {authorizedTabsArray(headerTabLabels).map(({ label }, index) => {
        return <PLTabsItem key={`${label}-${index}`} active={currentTab === index} label={label} />;
      })}
    </PLTabs>
  );

  const editGroupButton = (
    <PLButton
      onClick={handleEditAssetGroup}
      key="edit-group-button"
      iconPosition="left"
      label={translations.globals.button.edit}
    >
      <PLIcon iconName="edit" size="24px" slot="icon" />
    </PLButton>
  );

  return (
    <Box sx={rootStyles}>
      <ContentHeader tabsStyles={{ marginBottom: 0 }} tabs={[HeaderTabs]} rightItems={editGroupButton} />
      <Grid container>
        <>
          {currentTab === 0 && (
            <Grid item xs={9} sm={6} md={8} lg={9}>
              <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
                <GroupWorkloadOverviewTile queryBuilderResultIds={executionResult?.data} />
                <CurrentPositionTile
                  queryBuilderResultIds={executionResult?.data}
                  queryResultLoading={executionResult?.loading}
                />
              </AuthorizedTabsCan>
            </Grid>
          )}
          {currentTab === 1 && (
            <Grid item xs={9} sm={6} md={8} lg={9}>
              <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
                {!queryError && executionResult?.data && (
                  <GroupQueryResultsTile queryBuilderResultIds={executionResult?.data} />
                )}
              </AuthorizedTabsCan>
            </Grid>
          )}
          <Grid item xs={3} sm={6} md={4} lg={3}>
            {assetGroupId && <GroupInfoTile assetGroupId={assetGroupId} />}
            {!queryError && executionResult?.data && (
              <GroupResultCountTile queryBuilderResultIds={executionResult?.data} />
            )}
          </Grid>
        </>
      </Grid>
    </Box>
  );
};

export default AssetGroupDetails;
