import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetDevicesForAssetGroupsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetDeviceSortInput> | Types.AssetDeviceSortInput>;
}>;


export type GetAssetDevicesForAssetGroupsQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, deviceId: string, asset: { __typename?: 'Asset', id: string, assetMasterDataId?: Maybe<string>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string>, articleNumber?: Maybe<string> }>, mappingAssetTag: Array<{ __typename?: 'MappingAssetTag', id: string, assetId: string, assetTagId: string, assetTag: { __typename?: 'AssetTag', id: string, tagValue: string } }> }, device: { __typename?: 'Device', id: string, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string } } }> };


export const GetAssetDevicesForAssetGroupsDocument = gql`
    query getAssetDevicesForAssetGroups($where: AssetDeviceFilterInput, $take: Int, $skip: Int, $order: [AssetDeviceSortInput!]) {
  assetDevices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    deviceId
    asset {
      id
      assetMasterDataId
      assetMasterData {
        id
        generalItem
        articleNumber
      }
      mappingAssetTag {
        id
        assetId
        assetTagId
        assetTag {
          id
          tagValue
        }
      }
    }
    device {
      id
      deviceType {
        id
        deviceTypeName
      }
    }
  }
}
    `;

/**
 * __useGetAssetDevicesForAssetGroupsQuery__
 *
 * To run a query within a React component, call `useGetAssetDevicesForAssetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDevicesForAssetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDevicesForAssetGroupsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetDevicesForAssetGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetDevicesForAssetGroupsQuery, GetAssetDevicesForAssetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetDevicesForAssetGroupsQuery, GetAssetDevicesForAssetGroupsQueryVariables>(GetAssetDevicesForAssetGroupsDocument, options);
      }
export function useGetAssetDevicesForAssetGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetDevicesForAssetGroupsQuery, GetAssetDevicesForAssetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetDevicesForAssetGroupsQuery, GetAssetDevicesForAssetGroupsQueryVariables>(GetAssetDevicesForAssetGroupsDocument, options);
        }
export type GetAssetDevicesForAssetGroupsQueryHookResult = ReturnType<typeof useGetAssetDevicesForAssetGroupsQuery>;
export type GetAssetDevicesForAssetGroupsLazyQueryHookResult = ReturnType<typeof useGetAssetDevicesForAssetGroupsLazyQuery>;
export type GetAssetDevicesForAssetGroupsQueryResult = Apollo.QueryResult<GetAssetDevicesForAssetGroupsQuery, GetAssetDevicesForAssetGroupsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
