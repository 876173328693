export default {
  name: "rule action",
  plural: "rules actions",
  columns: {
    id: "ID",
    isDeleted: "is deleted",
    ruleActionDescription: "rule action description",
    ruleActionName: "rule action name",
    ruleRuleActionFailure: "rule rule action failure",
    ruleRuleActionSuccess: "rule rule action success",
  },
};
