import React, { useEffect } from "react";
import { Button } from "@mui/material";
import translations from "translations";
import makeStyles from "@mui/styles/makeStyles";
import { useHasSelectedUsersChanges } from "../UpdateRole/useGetMappingsUsersToSave";
interface ButtonProps {
  cancelAction: () => void;
  doMutations: () => void;
  setBlocked: (val: boolean) => void;
}

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    justifyContent: "end",
    paddingBottom: "100px",
    paddingTop: "0px",
    width: "100%",
  },
  secondary: {
    marginLeft: "36px",
  },
});

export const Buttons: React.FC<ButtonProps> = ({ cancelAction, doMutations, setBlocked }) => {
  const classes = useStyles();
  const disabled = !useHasSelectedUsersChanges();

  useEffect(() => {
    setBlocked(!disabled);
  }, [disabled, setBlocked]);

  return (
    <div className={classes.buttons}>
      <Button
        color="secondary"
        disabled={disabled}
        onClick={() => {
          cancelAction();
        }}
      >
        {translations.globals.button.reset}
      </Button>
      <Button
        color="primary"
        disabled={disabled}
        className={classes.secondary}
        onClick={() => {
          doMutations();
        }}
      >
        {translations.globals.button.save}
      </Button>
    </div>
  );
};
