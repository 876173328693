import { GetDevicesInGeofencesQuery } from "graphqlBase/Devices/__generated__/getDevicesInGeofences";
import { GetAllStorageAreasQuery } from "graphqlBase/StorageAreas/__generated__/getAllStorageAreas";
import L, { LatLngExpression } from "leaflet";
import memoize from "lodash/memoize";

import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { DeviceInGeofence, FlattenData, StorageAreaTypes } from "..";
import { selectedStorageAreaIdsAtom } from "../recoilBox";
interface DataProps {
  allStorageAreas: GetAllStorageAreasQuery | undefined;
  devicesInGeofencesPre: GetDevicesInGeofencesQuery | undefined;
}

//allStorageAreas?.storageAreas
const useGetCalculatedData = ({ allStorageAreas, devicesInGeofencesPre }: DataProps) => {
  const storageAreaIds = useRecoilValue(selectedStorageAreaIdsAtom);

  const filteredStorageAreas = useMemo(() => {
    return allStorageAreas?.storageAreas.filter((storageArea) => storageAreaIds.includes(storageArea.id)) ?? [];
  }, [allStorageAreas?.storageAreas, storageAreaIds]);

  const devicesWithAssets = useMemo(() => {
    if (!storageAreaIds.length) return [];
    const devicesInGeofences = (devicesInGeofencesPre?.queryDeviceWithinGeofence?.geofenceDevices ??
      ([] as unknown)) as DeviceInGeofence[];

    return devicesInGeofences.reduce<DeviceInGeofence[]>((acc, current) => {
      if (!current.device.assetDevice || !current.device?.assetDevice?.[0]?.asset.assetMasterData) return acc;

      return acc.concat({ ...current, id: current.device.assetDevice?.[0]?.asset?.id });
    }, []);
  }, [devicesInGeofencesPre, storageAreaIds]);

  const devicesByStorageAreaId = useMemo(() => {
    return devicesWithAssets.reduce<{ [key: string]: DeviceInGeofence[] }>((acc, device) => {
      const storageAreas = (allStorageAreas?.storageAreas ?? []).filter((storageArea) =>
        L.polygon(storageArea?.geofence?.coordinates as L.LatLngExpression[][])
          .getBounds()
          .contains(device.location?.coordinates as LatLngExpression)
      );
      return storageAreas?.reduce((storageAreasPre, area) => {
        return { ...storageAreasPre, [area.id]: (storageAreasPre[area.id] ?? []).concat(device) };
      }, acc);
    }, {});
  }, [allStorageAreas?.storageAreas, devicesWithAssets]);

  const getFlattenData = useCallback(
    memoize((data: StorageAreaTypes[]): FlattenData[] => {
      let storageArea: any = [];
      return data
        .map((m) => {
          if (m.storageArea && m.storageArea.length) {
            storageArea = [...storageArea, ...m.storageArea];
          }

          return m;
        })
        .concat(storageArea.length ? getFlattenData(storageArea) : storageArea);
    }),
    []
  );

  return { filteredStorageAreas, devicesWithAssets, devicesByStorageAreaId, getFlattenData };
};
export default useGetCalculatedData;
