import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetGroupEntityIdByNameQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.GroupEntityFilterInput>;
}>;


export type GetGroupEntityIdByNameQuery = { groupEntities: Array<{ __typename?: 'GroupEntity', id: string, entityName: string }> };


export const GetGroupEntityIdByNameDocument = gql`
    query getGroupEntityIdByName($where: GroupEntityFilterInput) {
  groupEntities(where: $where) {
    id
    entityName
  }
}
    `;

/**
 * __useGetGroupEntityIdByNameQuery__
 *
 * To run a query within a React component, call `useGetGroupEntityIdByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupEntityIdByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupEntityIdByNameQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetGroupEntityIdByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGroupEntityIdByNameQuery, GetGroupEntityIdByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGroupEntityIdByNameQuery, GetGroupEntityIdByNameQueryVariables>(GetGroupEntityIdByNameDocument, options);
      }
export function useGetGroupEntityIdByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGroupEntityIdByNameQuery, GetGroupEntityIdByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGroupEntityIdByNameQuery, GetGroupEntityIdByNameQueryVariables>(GetGroupEntityIdByNameDocument, options);
        }
export type GetGroupEntityIdByNameQueryHookResult = ReturnType<typeof useGetGroupEntityIdByNameQuery>;
export type GetGroupEntityIdByNameLazyQueryHookResult = ReturnType<typeof useGetGroupEntityIdByNameLazyQuery>;
export type GetGroupEntityIdByNameQueryResult = Apollo.QueryResult<GetGroupEntityIdByNameQuery, GetGroupEntityIdByNameQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
