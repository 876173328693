import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLCheckOptionProps = DropOnONLh<JSX.PatternlibCheckOption>;
export type PLCheckOptionExtendedProps = PLCheckOptionProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedCheckOption = wrapWc<JSX.PatternlibCheckOption>("patternlib-check-option");
export const useRefPLCheckOption = () => useRef<HTMLPatternlibCheckOptionElement>(null);

const PLCheckOption = React.forwardRef<HTMLPatternlibCheckOptionElement, PLCheckOptionExtendedProps>((props, ref) => {
  const myRef = useRefPLCheckOption();
  return (
    <WrappedCheckOption ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedCheckOption>
  );
});
PLCheckOption.displayName = "PLCheckOption";

export default PLCheckOption;
