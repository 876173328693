import styled from "@emotion/styled";
import { LhChange } from "@liebherr/patternlib";
import { PatternlibCheckboxCustomEvent } from "@liebherr/patternlib/dist/types/components";
import PLCheckbox from "components/patternLib/form/PLCheckbox";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FlattenData } from "..";
import { newStorageAreaIdAtom, selectedStorageAreaIdsAtom } from "../recoilBox";
import CheckboxList from "./CheckboxList";
import { updateItemStates } from "./updateItemStates";

const SelecteAllCheckBoxSC = styled("div")({
  padding: "11px 24px",
  backgroundColor: "#F0F3F6",
  borderBottom: "1px solid #D3D8DD",
});

const CheckboxListSC = styled("div")({
  overflowY: "auto",
  "@media (min-height:1120px)": {
    maxHeight: "45vh",
  },

  "@media (max-height:920px)": {
    maxHeight: "28vh",
  },
  "@media (min-height:920px) and (max-height:1000px)": {
    maxHeight: "30vh",
  },
  "@media (min-height:1000px) and (max-height:1120px)": {
    maxHeight: "38vh",
  },
});

export enum CheckboxState {
  UNCHECKED,
  CHECKED,
  INDETERMINATE,
}

export interface ItemState {
  id: string;
  state: CheckboxState;
}

interface GeofenceTypesListProps {
  afterDelete: () => void;
  data: FlattenData[];
}
const GeofenceTypesList: React.FC<GeofenceTypesListProps> = ({ data, afterDelete }) => {
  const setSelectedStorageAreaIds = useSetRecoilState(selectedStorageAreaIdsAtom);
  const newStorageAreaId = useRecoilValue(newStorageAreaIdAtom);
  const childrenIds = data.filter((item) => item.parentId).map((child) => child.id);

  const [itemStates, setItemStates] = useState<ItemState[]>([]);

  useEffect(() => {
    setItemStates(
      data.map((i: { id: string }) => ({
        id: i.id,
        state: CheckboxState.CHECKED,
      }))
    );
  }, [data]);

  const selectedData = itemStates.reduce<{
    isAllChecked: number;
    isNoneChecked: number;
    selectedStorageAreas: string[];
  }>(
    (acc, item) => {
      acc.isAllChecked = acc.isAllChecked * item.state;
      acc.isNoneChecked = acc.isNoneChecked + item.state;
      const isStorageArea = childrenIds.find((id) => item.id === id);
      if (isStorageArea && item.state === 1) acc.selectedStorageAreas.push(item.id);
      return acc;
    },
    { selectedStorageAreas: [], isAllChecked: 1, isNoneChecked: 0 }
  );

  const { isAllChecked, selectedStorageAreas, isNoneChecked } = selectedData;

  useEffect(() => {
    setItemStates((prevState: ItemState[]) => {
      return [...prevState, { id: newStorageAreaId, state: 1 }];
    });
  }, [newStorageAreaId]);

  useEffect(() => {
    setSelectedStorageAreaIds(selectedStorageAreas);
  }, [itemStates]);

  const getStateForId = useCallback(
    (id: string) => {
      return itemStates.find((i) => i.id === id)?.state;
    },
    [itemStates]
  );

  const clickHandler = useCallback(
    (id) => {
      return setItemStates(updateItemStates(itemStates, data, id));
    },
    [itemStates]
  );

  return (
    <>
      <SelecteAllCheckBoxSC>
        <PLCheckbox
          label="Geofence Types"
          checked={isAllChecked > 0}
          indeterminate={isAllChecked < 1 && isNoneChecked !== 0}
          lhChange={(event: PatternlibCheckboxCustomEvent<LhChange>) => {
            setItemStates(
              data.map((i: { id: string }) => ({
                id: i.id,
                state:
                  event.detail.value === undefined || event.detail.value === true
                    ? CheckboxState.CHECKED
                    : CheckboxState.UNCHECKED,
              }))
            );
          }}
        />
      </SelecteAllCheckBoxSC>
      <CheckboxListSC>
        <CheckboxList items={data} onClick={clickHandler} getStateForId={getStateForId} afterDelete={afterDelete} />
      </CheckboxListSC>
    </>
  );
};

export default GeofenceTypesList;
