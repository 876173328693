import PLRadiobuttonItem from "components/patternLib/form/PLRadiobuttonItem";
import PLRadiobutton from "components/patternLib/form/PLRadiobutton";
import React from "react";
import { SetterOrUpdater } from "recoil";

interface RadioCheckboxesProps {
  checkedId: string | undefined;
  id: string;
  setCheckedId: SetterOrUpdater<string | undefined>;
}

const RadioCheckboxes: React.FC<RadioCheckboxesProps> = ({ id, checkedId, setCheckedId }) => {
  const isChecked = checkedId === id;

  const handleChange = () => {
    if (isChecked) {
      setCheckedId(undefined);
    } else {
      setCheckedId(id);
    }
  };

  return (
    <div style={{ paddingBottom: "36px" }}>
      <PLRadiobuttonItem value={checkedId} checked={isChecked} onClick={handleChange} />
    </div>
  );
};

export default RadioCheckboxes;
