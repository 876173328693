import {
  GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesDocument,
  GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery,
  GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables,
} from "graphqlBase/sensorMeasurementCurrentValues/__generated__/getDeviceIdsOnSensorMeasurementCurrentValuesByCoordinates";
import {
  GetSorageAreaCoordinatesOnIdsDocument,
  GetSorageAreaCoordinatesOnIdsQuery,
  GetSorageAreaCoordinatesOnIdsQueryVariables,
} from "graphqlBase/StorageAreas/__generated__/getSorageAreaCoordinatesOnIds";
import Qqltypes from "graphqlBase/types.d";
import { fillSelect } from "components/templates/QueryBuilder/middleware/middleWareUtils";
import { FieldMiddleWares, JsonRule, QueryMiddleWares } from "components/templates/QueryBuilder/types";
import {
  GetDeviceTagsForTagsListDocument,
  GetDeviceTagsForTagsListQuery,
  GetDeviceTagsForTagsListQueryVariables,
} from "graphqlBase/DeviceTag/__generated__/getDeviceTagsForTagsList";
import {
  GetDevicesByDeviceNameDocument,
  GetDevicesByDeviceNameQuery,
  GetDevicesByDeviceNameQueryVariables,
} from "graphqlBase/Devices/__generated__/getDevicesByDeviceName";
import {
  GetDevicesBySerialNumberDocument,
  GetDevicesBySerialNumberQuery,
  GetDevicesBySerialNumberQueryVariables,
} from "graphqlBase/Devices/__generated__/getDevicesBySerialNumber";

export const fieldMiddleWares: FieldMiddleWares = [
  {
    key: "storageArea.geofence",
    middleWare: async ({ client, fieldName, field }) => {
      const newfield = await fillSelect({
        client,
        field,
        fieldName,
        table: "storageArea",
        valueColumn: "id",
        labelColumn: "storageAreaName",
      });
      return {
        ...newfield,
        field: {
          ...newfield.field,
          type: "select",
          operators: ["select_any_in", "select_not_any_in"],
          preferWidgets: ["multiselect"],
          valueSources: ["value"],
          widgets: {
            select: {
              widgetProps: {},
            },
            multiselect: {},
            field: {},
            func: {},
          },
          mainWidget: "select",
        },
      };
    },
  },
];

export const queryMiddleWares: QueryMiddleWares = [
  {
    key: "storageArea.geofence",
    middleWare: async ({ client, fieldName, field }) => {
      const result1 = await client.query<
        GetSorageAreaCoordinatesOnIdsQuery,
        GetSorageAreaCoordinatesOnIdsQueryVariables
      >({
        query: GetSorageAreaCoordinatesOnIdsDocument,
        variables: { ids: field.properties.value[0] },
      });

      const geofencesCoordinates = (result1.data.storageAreas ?? []).reduce<Array<Array<Qqltypes.Scalars["Position"]>>>(
        (geofences, { geofence }) => {
          if (geofence) {
            const { coordinates } = geofence;
            if (Array.isArray(coordinates))
              return geofences.concat([(coordinates as unknown) as Array<[number, number]>]);
          }
          return geofences;
        },
        []
      );
      if (!geofencesCoordinates) return { fieldName };
      const result2 = await client.query<
        GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery,
        GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables
      >({
        query: GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesDocument,
        variables: { geofencesCoordinates },
      });
      const devices = (result2.data.queryDeviceWithinGeofence?.geofenceDevices ?? []).reduce<string[]>(
        (devices, geofence) => {
          if (geofence?.deviceId) return devices.concat(geofence.deviceId);
          return devices;
        },
        []
      );

      const nextField: JsonRule = {
        properties: {
          field: "device.deviceId",
          operator: "select_any_in",
          value: [devices],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "tag.tagValue",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const operator = getOperator(field.properties.operator ?? "");

      const variables = {
        where: {
          mappingDeviceTag: {
            [operator === "in" ? "some" : "none"]: {
              tag: { tagValue: { in: field.properties.value[0] } },
            },
          },
        },
      };

      const result = await client.query<GetDeviceTagsForTagsListQuery, GetDeviceTagsForTagsListQueryVariables>({
        query: GetDeviceTagsForTagsListDocument,
        variables,
      });

      const deviceIds = (result.data?.devices ?? []).reduce<string[]>((deviceIds, device) => {
        return deviceIds.concat(device.id);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "device.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "deviceType.deviceTypeName",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const operator = getOperator(field.properties.operator ?? "");

      const variables = {
        where: {
          deviceType: {
            deviceTypeName: {
              [operator]: field.properties.value[0],
            },
          },
        },
      };

      const result = await client.query<GetDeviceTagsForTagsListQuery, GetDeviceTagsForTagsListQueryVariables>({
        query: GetDeviceTagsForTagsListDocument,
        variables,
      });

      const deviceIds = (result.data?.devices ?? []).reduce<string[]>((deviceIds, device) => {
        return deviceIds.concat(device.id);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "device.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "device.deviceName",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const operator = getOperator(field.properties.operator ?? "");

      const variables = {
        where: {
          deviceName: { [operator]: field.properties.value[0] },
        },
      };

      const result = await client.query<GetDevicesByDeviceNameQuery, GetDevicesByDeviceNameQueryVariables>({
        query: GetDevicesByDeviceNameDocument,
        variables,
      });

      const deviceIds = (result.data?.devices ?? []).reduce<string[]>((deviceIds, device) => {
        return deviceIds.concat(device.id);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "device.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "device.serialNo",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const operator = getOperator(field.properties.operator ?? "");

      const variables = {
        where: {
          serialNo: { [operator]: field.properties.value[0] },
        },
      };

      const result = await client.query<GetDevicesBySerialNumberQuery, GetDevicesBySerialNumberQueryVariables>({
        query: GetDevicesBySerialNumberDocument,
        variables,
      });

      const deviceIds = (result.data?.devices ?? []).reduce<string[]>((deviceIds, device) => {
        return deviceIds.concat(device.id);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "device.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
];
