import { Box, Typography, styled } from "@mui/material";
import PLBreadcrumb from "components/patternLib/PLBreadcrumb";
import PLBreadcrumbItem from "components/patternLib/PLBreadcrumbItem";
import React from "react";
import { matchPath, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Route } from "routes/routes";
import translations from "translations";

export const BreadcrumbContainerSC = styled("div")(
  () => `
    display: flex;
    align-self: center;
    align-items: center;
`
);

export const breadcrumbItemStyle = {
  alignItems: "center",
  boxSizing: "border-box",
  display: "inline-flex",
  height: 24,
  width: "fit-content",
  color: "var(--color-black)",
  fontFamily: "var(--font-liebherr-text-bold)",
  fontSize: "var(--copytext-font-size-smaller)",
  letterSpacing: "var(--letter-spacing-basic)",
  textDecoration: "none",
  textTransform: "uppercase",
};

type BreadcrumbRoutes = Omit<Route, "subRoutes">[] | undefined;
interface BreadcrumbProps {
  routes: BreadcrumbRoutes;
}

function useRouteDetails(routes: BreadcrumbRoutes) {
  const location = useLocation();
  const pathname = location.pathname;
  const currentRoute = routes?.find(({ path, exact }) => matchPath(pathname ?? "", { path, exact }));

  const match = useRouteMatch(currentRoute?.path as string);

  const pathnames = match?.path.split("/").filter((x) => x);
  const reconstructedPath = (index: number) => `/${pathnames?.slice(0, index).join("/")}`;
  return { pathnames, reconstructedPath, currentRoute };
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ routes }) => {
  const history = useHistory();

  const { pathnames, reconstructedPath, currentRoute } = useRouteDetails(routes);

  return (
    <BreadcrumbContainerSC>
      <PLBreadcrumb>
        {pathnames?.length ? (
          <PLBreadcrumbItem key="asset manager" onClick={() => history.push("/")}>
            {translations.globals.headlines.asset_manager}
          </PLBreadcrumbItem>
        ) : null}
        {pathnames?.map((name, index) => {
          const isLast = index === pathnames?.length - 1;
          const route = routes?.find(({ path, exact }) =>
            matchPath(reconstructedPath(index + 1) ?? "", { path, exact })
          );

          return route && isLast ? (
            <PLBreadcrumbItem key={`${name}-${index + 10}`}>{route.label}</PLBreadcrumbItem>
          ) : (
            route && (
              <PLBreadcrumbItem key={`${name}-${index}`} onClick={() => history.push(route.path)}>
                {route.label}
              </PLBreadcrumbItem>
            )
          );
        })}
      </PLBreadcrumb>

      {!currentRoute?.addBreadcrumb && !pathnames?.length && (
        <Box sx={{ display: "flex" }}>
          <Typography sx={breadcrumbItemStyle}>{translations.globals.headlines.asset_manager}</Typography>
        </Box>
      )}
    </BreadcrumbContainerSC>
  );
};
export default Breadcrumb;
