import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLContactProps = DropOnONLh<JSX.PatternlibContact>;
export type PLContactExtendedProps = PLContactProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedContact = wrapWc<JSX.PatternlibContact>("patternlib-contact");
export const useRefPLContact = () => useRef<HTMLPatternlibContactElement>(null);

const PLContact = React.forwardRef<HTMLPatternlibContactElement, PLContactExtendedProps>((props, ref) => {
  const myRef = useRefPLContact();
  return (
    <WrappedContact ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedContact>
  );
});
PLContact.displayName = "PLContact";

export default PLContact;
