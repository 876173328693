import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesForHomeSummaryQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
}>;


export type GetDevicesForHomeSummaryQuery = { devices: Array<{ __typename?: 'Device', id: string, deviceName: string, deviceTypeId: string, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareRevision: string } }> }> };


export const GetDevicesForHomeSummaryDocument = gql`
    query getDevicesForHomeSummary($where: DeviceFilterInput) {
  devices(where: $where) {
    id
    deviceName
    deviceTypeId
    mappingDeviceFirmwareDevice {
      id
      deviceFirmware {
        id
        firmwareRevision
      }
    }
  }
}
    `;

/**
 * __useGetDevicesForHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetDevicesForHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesForHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesForHomeSummaryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDevicesForHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesForHomeSummaryQuery, GetDevicesForHomeSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesForHomeSummaryQuery, GetDevicesForHomeSummaryQueryVariables>(GetDevicesForHomeSummaryDocument, options);
      }
export function useGetDevicesForHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesForHomeSummaryQuery, GetDevicesForHomeSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesForHomeSummaryQuery, GetDevicesForHomeSummaryQueryVariables>(GetDevicesForHomeSummaryDocument, options);
        }
export type GetDevicesForHomeSummaryQueryHookResult = ReturnType<typeof useGetDevicesForHomeSummaryQuery>;
export type GetDevicesForHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetDevicesForHomeSummaryLazyQuery>;
export type GetDevicesForHomeSummaryQueryResult = Apollo.QueryResult<GetDevicesForHomeSummaryQuery, GetDevicesForHomeSummaryQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
