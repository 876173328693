const en = {
  name: "Group",
  plural: "Groups",
  columns: {
    id: "ID",
    groupEntityId: "Group Entity ID",
    groupName: "Group Name",
    groupDescription: "Description",
    createdAt: "Creation Date",
    createdBy: "Created by",
    modifiedAt: "Modified At",
    tenantId: "tenant ID",
  },
};
export default en;
