import * as React from "react";
import { Theme as AugmentedTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from "@mui/material/Button";
// import DefaultButton from "../../../atomics/DefaultButton";

import Props from "./index.d";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    SubmitButton: {
      // height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    title: {
      minWidth: 160,
      marginRight: 10,
      align: "right",
    },
  })
);

const ErrorDisplay: React.FC<Props> = (props) => {
  const {
    title,
    testId,
    data: { state },
  } = props;
  const classes = useStyles({});
  return (
    <div className={classes.SubmitButton} data-testid={testId}>
      {state ? JSON.stringify(state, null, 2) : null}
    </div>
  );
};

export default ErrorDisplay;
