/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-max-depth */
import React, { useCallback, useState, useMemo } from "react";
import translations from "translations";
import RoleEditor from "./RoleEditor";
import { useHistory, useParams } from "react-router-dom";
import { useGetRoleByIdQuery } from "graphqlBase/Settings/__generated__/getRoleById";
import MappingRoleScopesTable from "../UserRolesManager/MappingRoleScopesTable";
import { styled, Box, FormControl, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useDeleteRolesMutation } from "graphqlBase/Settings/__generated__/deleteRoles";
import { GetRoleNameAndDescriptionDocument } from "graphqlBase/Role/__generated__/getRoleNameAndDescription";
import { useStyles } from "../UserRolesManager/utils";
import FormModal from "../FormModal";
import { editorData } from "../RecoilState/recoilState";
import { useUpdateRolesMutation } from "graphqlBase/Settings/__generated__/updateRoles";
import { useGetMappingsToSave } from "../UserRolesManager/MappingRoleScopesTable/useGetMappingsToSave";
import { Can } from "authorization/Can";
import UserTable from "./UsersTable";
import { useGetMappingsUsersToSave } from "./useGetMappingsUsersToSave";
import { useRecoilValue } from "recoil";
import { SaveButton } from "../SaveButton";
import useBlockHistory, { UseBlockHistoryCb } from "routes/useBlockHistory";

const DropdownWrapperSC = styled("div")({
  zIndex: 999,
  backgroundColor: "#fff",
  height: "100%",
  minHeight: "40vh",
  padding: "52px 45px 100px 45px",
});
export interface DeleteUserRoleMutation {
  ids: string | string[];
}
const UpdateRole: React.FC<{}> = () => {
  const history = useHistory();
  const { backButton, roleManager, title, saveButtonCreateEditRole, deleteButton } = useStyles({});
  const [roleToDelete, setRoleToDelete] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { setBlockCallBack, continueAfterBlock, setPreventPageClose } = useBlockHistory();

  const editedRoleData = useRecoilValue<{ description: string; id: string; name: string }>(editorData);
  const { roleId }: { roleId: string } = useParams();
  const { data } = useGetRoleByIdQuery({
    variables: { id: roleId },
    fetchPolicy: "cache-and-network",
  });
  const usersLength = data?.role?.mappingRoleUsers.length;
  const [deleteRoles] = useDeleteRolesMutation({
    refetchQueries: [{ query: GetRoleNameAndDescriptionDocument }],
  });
  const [updateMutation] = useUpdateRolesMutation();

  const updateRoles = async () => {
    const { data } = await updateMutation({
      variables: {
        roles: { id: roleId, description: editedRoleData.description, name: editedRoleData.name },
      },
    });
    return data;
  };

  const TriggerBlocker = useCallback((block: boolean) => {
    const blockerCb: UseBlockHistoryCb = () => {
      setOpenModal(true);
      return false;
    };
    setBlockCallBack(() => (block ? blockerCb : undefined));
    setPreventPageClose(block);
  }, []);

  const handleClick = () => {
    history.push(`/settings/userManagement/userRolesManager`);
  };
  const cancelRoleToSave = () => {
    setOpenModal(false);
  };

  const handleDeleteRole = async () => {
    await deleteRoles({ variables: { ids: roleId } });
    handleClick();
  };
  const cancelDeleteRole = () => {
    setRoleToDelete(false);
  };
  const cancelEditeRole = () => {
    setRoleToEdit(false);
  };
  const saveMappingsCb = useGetMappingsToSave({ roleId });
  const saveMappingsUsers = useGetMappingsUsersToSave();

  const saveMappings = useCallback(() => {
    TriggerBlocker(false);
    saveMappingsCb().catch(console.error);
    saveMappingsUsers().catch(console.error);
    updateRoles().catch(console.error);
    setRoleToEdit(true);
  }, [saveMappingsCb, saveMappingsUsers, updateRoles]);
  const message = (
    <>
      <div style={{ paddingRight: "25px" }}>
        <div>{translations.pages.userManagement.form.message.continueDeleteMessage}</div>
        <div style={{ display: "flex" }}>
          {translations.pages.userManagement.form.message.userRoleDeleteMessagePt1}&nbsp;
          <div style={{ fontWeight: "bold", display: "contents" }}>{`${usersLength} user(s).`}&nbsp;</div>
          {translations.pages.userManagement.form.message.userRoleDeleteMessagePt2}
        </div>
      </div>
    </>
  );

  const RoleTables = useMemo(() => {
    return (
      <div style={{ margin: "0px" }}>
        <UserTable roleId={roleId} />
        <MappingRoleScopesTable roleId={roleId} disabled={false} />
      </div>
    );
  }, [roleId]);
  return (
    <>
      <Box sx={{ pb: "81px", pt: "17px" }}>
        <DropdownWrapperSC>
          <div className={roleManager}>
            <div className={title}>
              <div style={{ display: "flex" }}>
                <div>
                  <Button className={backButton} color="inherit" disableTouchRipple onClick={() => handleClick()}>
                    <TrendingFlatIcon />
                  </Button>
                </div>
              </div>
              <Typography className={title} variant="h2">
                {translations.pages.userManagement.form.label.editRole}
              </Typography>
            </div>
          </div>
          <FormControl fullWidth>
            <div className={deleteButton}>
              <Can I="read" a="DeleteRoleUI">
                {data?.role?.isDefaultRole ? (
                  <Button
                    variant="text"
                    color="inherit"
                    disableTouchRipple
                    disabled
                    onClick={() => setRoleToDelete(true)}
                    startIcon={<DeleteIcon />}
                  >
                    {translations.globals.terms.delete}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    color="inherit"
                    disableTouchRipple
                    onClick={() => setRoleToDelete(true)}
                    startIcon={<DeleteIcon />}
                  >
                    {translations.globals.terms.delete}
                  </Button>
                )}
              </Can>
            </div>
            <RoleEditor selectedRole={data} />
            {RoleTables}
            <div className={saveButtonCreateEditRole}>
              <SaveButton doMutations={() => saveMappings()} setBlocked={TriggerBlocker} />
            </div>
          </FormControl>
        </DropdownWrapperSC>
      </Box>
      <FormModal
        handleConfirm={() => handleDeleteRole()}
        handleCancel={cancelDeleteRole}
        isOpen={roleToDelete}
        titles={translations.pages.userManagement.form.label.deleteUserRole}
        message={message}
        confirmLabel={translations.globals.terms.yes}
        cancelLabel={translations.globals.terms.cancel}
      />
      <FormModal
        handleConfirm={() => handleClick()}
        handleCancel={cancelEditeRole}
        isOpen={roleToEdit}
        titles={translations.globals.popupMessages.changesSavedMessage}
        message={<>{translations.globals.popupMessages.changesSavedMessage}</>}
        confirmLabel={translations.pages.userManagement.userRoleManager.label}
        cancelLabel={translations.globals.button.cancel}
      />
      <FormModal
        handleConfirm={() => continueAfterBlock()}
        handleCancel={cancelRoleToSave}
        isOpen={openModal}
        titles={translations.pages.userManagement.userRoleManager.discardChanges}
        message={<>{translations.pages.userManagement.userRoleManager.DiscardMessage}</>}
        confirmLabel={translations.globals.button.yes}
        cancelLabel={translations.globals.button.cancel}
      />{" "}
    </>
  );
};
export default UpdateRole;
