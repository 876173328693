import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import BulletIcon from "icons/components/BulletIcon";
import React, { useEffect } from "react";
import colors from "themes/colors";
import Props from "./index.d";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        color: "green",
        "& .text": {
          display: "none",
        },
      },
      button: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    }),
  { name: "StepperField" }
);

export function isStepCompleted(index: number, currentBullet: number): boolean {
  return index < currentBullet;
}

export function isActive(currentBullet: number, index: number) {
  return currentBullet === index;
}

export function isCompleted(currentBullet: number, index: number) {
  return currentBullet > index;
}

export function iconColor(currentBullet: number, index: number) {
  return isActive(currentBullet, index)
    ? colors.tBlack
    : isCompleted(currentBullet, index)
    ? colors.brandYellow
    : colors.grey100;
}
const icon = (state: number, index: number) => {
  return <div>{<BulletIcon color={iconColor(state, index)} />}</div>;
};

export function iconSize(currentBullet: number, index: number) {
  return isActive(currentBullet, index) ? 24 : 20;
}
//@todo move to lbc stepper
const StepperField: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    items,
    testId,
    title,
    disabled,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (!state) setAndSave(0);
  }, [state]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={+state || 0} data-testId={testId}>
        {items.map(({ label }, index) => {
          return (
            <Step key={index} active={isActive(state, index)} completed={isCompleted(state, index)}>
              <StepButton
                disabled={true}
                icon={icon(state, index)}
                data-testid={testId ? `${testId}${index}` : undefined}
              />
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};
export default StepperField;
