import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLSpinnerProps = DropOnONLh<JSX.PatternlibSpinner>;
export type PLSpinnerExtendedProps = PLSpinnerProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedSpinner = wrapWc<JSX.PatternlibSpinner>("patternlib-spinner");
export const useRefPLSpinner = () => useRef<HTMLPatternlibSpinnerElement>(null);

const PLSpinner = React.forwardRef<HTMLPatternlibSpinnerElement, PLSpinnerExtendedProps>((props, ref) => {
  const myRef = useRefPLSpinner();
  return (
    <WrappedSpinner ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedSpinner>
  );
});
PLSpinner.displayName = "PLSpinner";

export default PLSpinner;
