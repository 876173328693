import PLSidebarItem from "components/patternLib/PLSidebarItem";
import { History } from "history";
import React from "react";
import { Route } from "routes/routes";
export interface SideBarRouteProps {
  history: History;
  parentPath: string;
  routes: Route[];
  width?: string;
}
export const getMenuRoutes = (route: Route) => {
  return route?.subRoutes?.filter((subRoute) => subRoute.menuItem) ?? [];
};

const SideBarRoute: React.FC<SideBarRouteProps> = ({ routes, parentPath, history, width }) => {
  const handleClick = (path: string) => (e: React.MouseEvent<unknown, MouseEvent>) => {
    e.stopPropagation();
    history.push(path);
  };
  return (
    <>
      {routes.map((route) => (
        <PLSidebarItem
          onClick={handleClick(`${parentPath}${route.path}`)}
          active={!!location.pathname.includes(route.path)}
          width="300px"
          key={route.path}
          label={route.label}
          itemID={route.path}
          iconName={route.iconName ?? ""}
        >
          <SideBarRoute
            width={width}
            routes={getMenuRoutes(route)}
            parentPath={`${parentPath}${route.path}`}
            history={history}
          />
        </PLSidebarItem>
      ))}
    </>
  );
};
export default SideBarRoute;
