import {
  LhMultipleOptionSelectEvent,
  LhSingleOptionSelectEvent,
} from "@liebherr/patternlib/dist/types/utils/events/select";
import PLCheckOption from "components/patternLib/form/PLCheckOption";
import PLDropdown from "components/patternLib/form/PLDropdown";
import PLMultiselect from "components/patternLib/form/PLMultiselect";
import PLOption from "components/patternLib/form/PLOption";
import { sub } from "date-fns";
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import { durationOptionAtom, sensorMeasurementHistoryAggregateSelectorFamily } from "../../Configuration/recoilState";

const { time: timeTexts, terms: termTexts } = translations.globals;
const { timeBucket } = translations.globals.time;

const { appState, location, Temperature, Humidity, batteryVoltage } = translations.entities.capability.capabilityNames;

const durationOptions = [
  { value: "custom", label: termTexts.custom },
  { value: "24 hours", label: timeTexts.last24Hours },
  { value: "1 weeks", label: timeTexts.lastWeek },
  { value: "2 weeks", label: timeTexts.last2Weeks },
  { value: "1 months", label: timeTexts.lastMonth },
];

const granularityOptions = [
  { value: "10", label: timeBucket.tenMin },
  { value: "60", label: timeBucket.oneHour },
  { value: "360", label: timeBucket.sixHours },
  { value: "720", label: timeBucket.twelveHours },
  { value: "1440", label: timeBucket.twentyfourHours },
];

const messageOptions = [
  { label: appState, value: "5063bcd9-9e7b-42f6-84b4-eeac931a2b1f" },
  { label: location, value: "4d891dd6-5e4a-4ccc-bc40-83cda080fff0" },
  { label: Temperature, value: "e4b14165-5282-4e38-99e4-c90f5b3adc9f" },
  { label: Humidity, value: "2d68c5ca-5caf-4666-8901-980bf33534ff" },
  { label: batteryVoltage, value: "80bec84b-2c50-4e12-9a70-56b06e5b92e5" },
];
export const GranularityDropDown: React.FC<{}> = () => {
  const [timeBucketInMinutes, setTimeBucketInMinutes] = useRecoilState(
    sensorMeasurementHistoryAggregateSelectorFamily("timeBucketInMinutes")
  );

  const handleChange = (event: CustomEvent<LhSingleOptionSelectEvent>) => {
    if (!event.detail.selected) return;
    setTimeBucketInMinutes(Number(event.detail.selected?.value));
  };

  return (
    <PLDropdown lhSelect={handleChange} showRemoveIcon={false} value={`${timeBucketInMinutes}`}>
      {granularityOptions.map((e) => {
        return <PLOption label={e.label} value={e.value} key={e.value} />;
      })}
    </PLDropdown>
  );
};

export const SelectDateFilter = () => {
  const [selectedValue, setSelectedValue] = useRecoilState(durationOptionAtom);
  const setStartDate = useSetRecoilState(sensorMeasurementHistoryAggregateSelectorFamily("startDate"));
  const setEndDate = useSetRecoilState(sensorMeasurementHistoryAggregateSelectorFamily("endDate"));

  const handleChange = (event: CustomEvent<LhSingleOptionSelectEvent>) => {
    if (!event.detail.selected) return;
    setSelectedValue(event.detail.selected?.value);
    if (event.detail.selected?.value !== "custom") {
      setEndDate(new Date().toISOString());
      const specifiedDurationForSub = (event.detail.selected?.value).split(" ");
      setStartDate(sub(new Date(), { [specifiedDurationForSub[1]]: Number(specifiedDurationForSub[0]) }).toISOString());
    }
  };
  return (
    <PLDropdown lhSelect={handleChange} showRemoveIcon={false} value={selectedValue}>
      {durationOptions.map((e) => {
        return <PLOption label={e.label} value={e.value} key={e.value} />;
      })}
    </PLDropdown>
  );
};

export const MessageTypes = () => {
  const [capabilityIds, setCapabilityIds] = useRecoilState(
    sensorMeasurementHistoryAggregateSelectorFamily("capabilityIds")
  );

  const handleSelect = (event: CustomEvent<LhMultipleOptionSelectEvent>) => {
    if (!event.detail.selected) return;
    const selectedCapabilities = (event.detail.selected ?? []).map((item) => item.value);
    setCapabilityIds(selectedCapabilities);
  };

  return (
    <PLMultiselect placeholder="message types" lhSelect={handleSelect} width="300px" value={capabilityIds as string[]}>
      {messageOptions.map((el) => {
        return <PLCheckOption label={el.label} value={el.value} key={el.value} />;
      })}
    </PLMultiselect>
  );
};
