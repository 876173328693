import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAssetsMutationVariables = Types.Exact<{
  assets?: Types.InputMaybe<Array<Types.InputMaybe<Types.AssetCreateType>> | Types.InputMaybe<Types.AssetCreateType>>;
}>;


export type CreateAssetsMutation = { createAssets?: Maybe<Array<Maybe<{ __typename?: 'Asset', id: string, userId?: Maybe<string>, parentAssetId?: Maybe<string>, assetTypeAttributeSetId?: Maybe<string>, assetSourceId?: Maybe<string>, assetMasterDataId?: Maybe<string>, lMID?: Maybe<string>, customUniqueIdentifier: string }>>> };


export const CreateAssetsDocument = gql`
    mutation createAssets($assets: [AssetCreateType]) {
  createAssets(assets: $assets) {
    id
    id
    userId
    parentAssetId
    assetTypeAttributeSetId
    assetSourceId
    assetMasterDataId
    lMID
    customUniqueIdentifier
  }
}
    `;
export type CreateAssetsMutationFn = Apollo.MutationFunction<CreateAssetsMutation, CreateAssetsMutationVariables>;

/**
 * __useCreateAssetsMutation__
 *
 * To run a mutation, you first call `useCreateAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetsMutation, { data, loading, error }] = useCreateAssetsMutation({
 *   variables: {
 *      assets: // value for 'assets'
 *   },
 * });
 */
export function useCreateAssetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAssetsMutation, CreateAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateAssetsMutation, CreateAssetsMutationVariables>(CreateAssetsDocument, options);
      }
export type CreateAssetsMutationHookResult = ReturnType<typeof useCreateAssetsMutation>;
export type CreateAssetsMutationResult = Apollo.MutationResult<CreateAssetsMutation>;
export type CreateAssetsMutationOptions = Apollo.BaseMutationOptions<CreateAssetsMutation, CreateAssetsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
