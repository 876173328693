import PLButton from "components/patternLib/PLButton";
import React from "react";

interface MapLinkProps {
  latitude: number;
  longitude: number;
}

const MapLink: React.FC<MapLinkProps> = ({ latitude, longitude }) => {
  const handleButtonClick = () => {
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsUrl, "_blank");
  };

  return <PLButton onClick={handleButtonClick} type="secondary" label="Open In Google Maps" />;
};

export default MapLink;
