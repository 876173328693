import React from "react";
import { Box, Typography } from "@mui/material";
import translations from "translations";
import getTestIDs from "lib/utils/getTestIDs";
import PLModal from "components/patternLib/PLModal";
export const testIDs = getTestIDs();

export default function ErrorPage() {
  const handleClose = () => {
    location.reload();
  };

  return (
    <div>
      <PLModal
        hideCancel
        lhClose={handleClose}
        lhConfirm={handleClose}
        confirmLabel={translations.globals.terms.OK}
        show
        modalTitle={translations.globals.helpText.globalErrorMessageHeader}
      >
        <>
          <Box slot="modal-content">
            <Typography variant="copy">{translations.globals.helpText.globalErrorMessage}</Typography>
          </Box>
        </>
      </PLModal>
    </div>
  );
}
