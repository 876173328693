import colors from "../colors";

export const muiTextFieldOverrides = {
  root: {
    color: colors.tWhite,
    margin: 0,

    "& .MuiFilledInput-underline:before": {
      display: "none",
    },
    "& .MuiFilledInput-underline:after": {
      display: "none",
    },
    " & .MuiInputLabel-filled ": {
      transform: "translate(1px, 2px) scale(1)",
    },
    "& .MuiInputBase-multiline": {
      display: "contents",
      overflow: "visible",
    },
    "& .MuiInputLabel-formControl": {
      position: "relative",
    },

    " & .MuiFormLabel-root ": {
      color: colors.grey600,
      WebkitTextFillColor: colors.grey600,
      display: "block",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
      letterSpacing: "0",
      lineHeight: 1.2,
      margin: "0.2em 0.5em 0.5em 0.4em",
      maxWidth: "fit-content",
    },

    "& .MuiInputBase-input": {
      // backgroundColor: colors.lbcGrey,
      appearance: "none",
      WebkitAppearance: "none",
      boxSizing: "border-box",
      width: "auto",
      height: "auto",
      // padding: "5px 5px 65px 4px",
      borderRadius: "4px",
      border: "0",
      fontSize: "16px",
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
      resize: "none",
    },

    "& .MuiInputBase-input:focus": {
      // backgroundColor: colors.tWhite,
      // border: `1px solid ${colors.grey400}`,
      // outline: "none",
      // padding: "5px 5px 65px 4px",
    },

    "& .MuiInputBase-input:hover": {
      // backgroundColor: colors.grey200,
    },

    " & .MuiInputBase-root.Mui-disabled": {
      border: "none",
      display: "contents",
      backgroundColor: colors.tWhite,
    },
    " & .MuiInputLabel-filled.Mui-disabled ": {
      backgroundColor: colors.tTransparent,
      border: "none",
    },

    "& .MuiInputBase-input.Mui-disabled": {
      backgroundColor: colors.tWhite,
      border: `1px solid ${colors.grey400}`,
      cursor: "no-drop",
    },

    " & .Mui-disabled:hover": {
      backgroundColor: colors.tWhite,
    },
    " & .Mui-disabled:focus": {
      padding: "0.5em 0 0 0.5em",
      backgroundColor: colors.tWhite,
      border: `1px solid ${colors.grey400}`,
    },
  },
};
