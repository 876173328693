import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetOpenAssignmentsForBadgeValueQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOpenAssignmentsForBadgeValueQuery = { assets: Array<{ __typename: 'Asset', id: string }> };


export const GetOpenAssignmentsForBadgeValueDocument = gql`
    query getOpenAssignmentsForBadgeValue {
  assets(where: {and: [{assetMasterDataId: {eq: null}}, {assetDevice: {any: true}}, {assetSourceId: {eq: "d2bb33b7-9e89-472c-998c-9f522bad3c07"}}]}) {
    id
    __typename
  }
}
    `;

/**
 * __useGetOpenAssignmentsForBadgeValueQuery__
 *
 * To run a query within a React component, call `useGetOpenAssignmentsForBadgeValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenAssignmentsForBadgeValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenAssignmentsForBadgeValueQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenAssignmentsForBadgeValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpenAssignmentsForBadgeValueQuery, GetOpenAssignmentsForBadgeValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetOpenAssignmentsForBadgeValueQuery, GetOpenAssignmentsForBadgeValueQueryVariables>(GetOpenAssignmentsForBadgeValueDocument, options);
      }
export function useGetOpenAssignmentsForBadgeValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpenAssignmentsForBadgeValueQuery, GetOpenAssignmentsForBadgeValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetOpenAssignmentsForBadgeValueQuery, GetOpenAssignmentsForBadgeValueQueryVariables>(GetOpenAssignmentsForBadgeValueDocument, options);
        }
export type GetOpenAssignmentsForBadgeValueQueryHookResult = ReturnType<typeof useGetOpenAssignmentsForBadgeValueQuery>;
export type GetOpenAssignmentsForBadgeValueLazyQueryHookResult = ReturnType<typeof useGetOpenAssignmentsForBadgeValueLazyQuery>;
export type GetOpenAssignmentsForBadgeValueQueryResult = Apollo.QueryResult<GetOpenAssignmentsForBadgeValueQuery, GetOpenAssignmentsForBadgeValueQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
