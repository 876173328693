import { OptionItem } from "@liebherr/patternlib/dist/types/components/form/patternlib-dropdown/patternlib-dropdown";
import { useGetNotificationScopesForAlertsLazyQuery } from "graphqlBase/NotificationScopes/__generated__/getNotificationScopesForAlerts";
import { useEffect } from "react";

const useGetScopeDefinitions = ({ notificationScopeId }: { notificationScopeId?: string }) => {
  const [fetchScopes, { data: scopesData, loading: scopesLoading }] = useGetNotificationScopesForAlertsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!notificationScopeId) return;
    fetchScopes({ variables: { where: { notificationScopeId: { eq: notificationScopeId } } } });
  }, [notificationScopeId]);

  const operators = scopesData?.notificationScopes?.[0]?.mappingNotificationScopeNotificationOperator?.reduce<
    OptionItem[]
  >((operators, current) => {
    return operators.concat({
      label: current?.notificationOperator?.operator,
      value: current?.notificationOperator?.id,
    });
  }, []);

  return { operators, scopesLoading };
};

export default useGetScopeDefinitions;
