import { hoverCommon, activeCommon, disabledCommon } from "./commonButtonStyles";
import colors from "themes/colors";

export const muiIconButtonOverrides = {
  root: {
    fontSize: 16,
    fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow Sans-Serif",
    height: 32,
    overflow: "hidden",
    borderRadius: 4,
    border: 0,
    outline: "none",
    padding: 0,
    width: "fit-content",
    "&:hover": {
      backgroundColor: colors.tTransparent,
    },
  },
  colorPrimary: {
    backgroundColor: colors.brandYellow,
    color: colors.tBlack,
    borderColor: colors.brandYellow,
    "& svg": { color: colors.tBlack },
    ...disabledCommon,
    ...hoverCommon,
    ...activeCommon,
  },
  colorSecondary: {
    border: `1px solid ${colors.tBlack}`,
    backgroundColor: colors.tWhite,
    color: colors.tBlack,
    "& svg": { color: colors.tBlack },
    "&:hover": {
      backgroundColor: colors.grey200,
      borderColor: colors.grey200,
    },
    "&:active": {
      color: colors.tWhite,
      backgroundColor: colors.grey400,
      "& svg": {
        color: colors.tWhite,
      },
      "&:disabled": { "& svg": { color: colors.grey100 } },
    },
    "&:disabled": {
      color: colors.grey100,
      backgroundColor: colors.tWhite,
      border: `1px solid ${colors.grey200}`,
      "& svg": {
        color: colors.grey100,
      },
    },
  },
};
