import colors from "../colors";
export const muiRadioButtonOverrides = {
  root: {
    fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
    listStyle: "none",
    color: colors.tBlack,
    WebkitAppearance: "none",
    appearance: "none",
    backgroundColor: colors.tWhite,
    border: `2px solid  ${colors.tBlack}`,
    borderRadius: "100%",
    cursor: "pointer",
    height: "18px",
    marginBottom: "0rem",
    marginRight: "0.5rem",
    outline: "none",
    padding: "0",
    width: "18px",

    "& + label": {
      color: colors.grey500,
      fontSize: "16px",
      width: "max-content",
    },
    "&.Mui-checked": {
      color: colors.tBlack,
      padding: "initial",
      borderRadius: "100%",
      border: `4.5px solid ${colors.brandYellow}`,
      background: colors.tBlack,
    },
  },

  disabled: {
    cursor: "not-allowed",
    opacity: 0.8,

    "&.Mui-checked": {
      opacity: 1,
      checked: {
        color: colors.tBlack,
        padding: "initial",
        borderRadius: "100%",
        border: `4.5px solid ${colors.brandYellow}`,
        background: colors.tBlack,
      },
      border: `2px solid ${colors.lbcGrey}`,
      backgroundColor: colors.tBlack,
    },
    "& + label": {
      cursor: "not-allowed",
      opacity: 0.5,
    },
  },
};
