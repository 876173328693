import GroupEditingLayout from "components/templates/QueryBuilder/GroupEditingLayout";
import { getGroupIdAfterSaveGroupAtom } from "components/templates/QueryBuilder/groupEditingState";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentAssetTab } from "../../assetTabsState";

export const testIDs = getTestIDs();

const AssetGroupEditing: React.FC<{}> = () => {
  const history = useHistory();
  const setCurrentTab = useSetRecoilState(currentAssetTab);
  const { assetGroupId }: { assetGroupId: string } = useParams();
  const groupIdAfterSaveGroup = useRecoilValue(getGroupIdAfterSaveGroupAtom);

  const seeAllAssetGroups = () => {
    setCurrentTab(1);
    history.push("/assetManagement");
  };

  const seeAssetGroupDetails = () => {
    history.push(`/assetManagement/assetGroupDetail/${groupIdAfterSaveGroup}`);
  };

  return (
    <GroupEditingLayout
      groupId={assetGroupId}
      entityName="AssetDevice"
      handleGoToAllGroups={seeAllAssetGroups}
      handleGoToGroup={seeAssetGroupDetails}
      queryStringsReturnValue="deviceId"
    />
  );
};

export default AssetGroupEditing;
