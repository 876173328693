import { gql } from "@apollo/client";
import { Delta } from "../types";
import createNonNullables from "graphqlBase/nonNullables.json";

export const firstToLowerCase = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

export default function makeOperation(delta: Delta) {
  const { mutations, variables, variableNames } = Object.entries(delta).reduce<{
    mutations: string[];
    variables: { [key: string]: {}[] };
    variableNames: string[];
  }>(
    ({ mutations, variables, variableNames }, [entity, payload]) => {
      const entityLowerCase = firstToLowerCase(entity);

      const { variablesInner, fields } = Object.entries(payload).reduce<{
        variablesInner: {}[];
        fields: string[];
      }>(
        ({ variablesInner, fields }, [id, changes], index) => {
          const nonNulls = (createNonNullables[entity as keyof typeof createNonNullables] ?? []) as string[];

          const { updates, nulls } = Object.entries(changes).reduce<{
            updates: { [key: string]: unknown };
            nulls: string[];
          }>(
            ({ updates, nulls }, [field, val]) => {
              const isEmpty = val === null || val === undefined;

              if (isEmpty) {
                return { updates, nulls: nulls.concat(field) };
              }

              return { updates: { ...updates, ...changes }, nulls };
            },
            { updates: {}, nulls: [] }
          );

          mutations.concat(`update${entity}s(${entityLowerCase}s:[{id:${id}}],setNulls:{${nulls.map(
            (field) => `${field}:true`
          )}}){
            ${fields.join("\n")}
        }`);
          const newVars = { id, ...updates };
          if (Object.keys(updates).length)
            return {
              variablesInner: variablesInner.concat(newVars),
              fields: fields.concat(Object.keys({ id, ...updates })),
            };
          return {
            variablesInner,
            fields,
          };
        },
        { variablesInner: [], fields: [] }
      );

      if (variablesInner.length) {
        const nextmutations = mutations.concat(`update${entity}s(${entityLowerCase}s:$${entityLowerCase}s){
            ${fields.join("\n")}
        }`);

        return {
          mutations: nextmutations,
          variables: { ...variables, [`${entityLowerCase}s`]: variablesInner },
          variableNames: variableNames.concat(`$${entityLowerCase}s: [${entity}UpdateType]`),
        };
      }
      return {
        mutations,
        variables,
        variableNames,
      };
    },
    { mutations: [], variables: {}, variableNames: [] }
  );

  if (!mutations.length) return { mutation: undefined, variables };

  const mutation = gql`mutation persistMuiTable(${variableNames.join(", ")}){
      ${mutations.join("\n")}
    }`;

  return { mutation, variables };
}
