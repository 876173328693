import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesForMappingDeviceNotificationsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.DeviceSortInput> | Types.DeviceSortInput>;
}>;


export type GetDevicesForMappingDeviceNotificationsQuery = { devices: Array<{ __typename?: 'Device', id: string, serialNo: string, deviceName: string, deviceType: { __typename?: 'DeviceType', deviceTypeName: string }, mappingDeviceTag: Array<{ __typename?: 'MappingDeviceTag', id: string, deviceId: string, tagId: string, isDeleted?: Maybe<boolean>, tag: { __typename?: 'Tag', id: string, tagValue: string } }>, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, dateFrom: string, dateTo: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareVersion: string, deviceFirmwareConfigurationSchema: { __typename?: 'DeviceFirmwareConfigurationSchema', id: string, deviceFirmwareConfigurationJSON: string } } }> }> };


export const GetDevicesForMappingDeviceNotificationsDocument = gql`
    query getDevicesForMappingDeviceNotifications($where: DeviceFilterInput, $take: Int, $skip: Int, $order: [DeviceSortInput!]) {
  devices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    serialNo
    deviceName
    deviceType {
      deviceTypeName
    }
    mappingDeviceTag(where: {isDeleted: {neq: true}}) {
      id
      deviceId
      tagId
      isDeleted
      tag {
        id
        tagValue
      }
    }
    mappingDeviceFirmwareDevice {
      id
      dateFrom
      dateTo
      deviceFirmware {
        id
        firmwareVersion
        deviceFirmwareConfigurationSchema {
          id
          deviceFirmwareConfigurationJSON
        }
      }
    }
  }
}
    `;

/**
 * __useGetDevicesForMappingDeviceNotificationsQuery__
 *
 * To run a query within a React component, call `useGetDevicesForMappingDeviceNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesForMappingDeviceNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesForMappingDeviceNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDevicesForMappingDeviceNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesForMappingDeviceNotificationsQuery, GetDevicesForMappingDeviceNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesForMappingDeviceNotificationsQuery, GetDevicesForMappingDeviceNotificationsQueryVariables>(GetDevicesForMappingDeviceNotificationsDocument, options);
      }
export function useGetDevicesForMappingDeviceNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesForMappingDeviceNotificationsQuery, GetDevicesForMappingDeviceNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesForMappingDeviceNotificationsQuery, GetDevicesForMappingDeviceNotificationsQueryVariables>(GetDevicesForMappingDeviceNotificationsDocument, options);
        }
export type GetDevicesForMappingDeviceNotificationsQueryHookResult = ReturnType<typeof useGetDevicesForMappingDeviceNotificationsQuery>;
export type GetDevicesForMappingDeviceNotificationsLazyQueryHookResult = ReturnType<typeof useGetDevicesForMappingDeviceNotificationsLazyQuery>;
export type GetDevicesForMappingDeviceNotificationsQueryResult = Apollo.QueryResult<GetDevicesForMappingDeviceNotificationsQuery, GetDevicesForMappingDeviceNotificationsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
