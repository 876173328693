import { atom } from "recoil";
import { MappingUserNotification, SelectedUser } from "./types";

export const preMappedUserNotificationsAtom = atom<MappingUserNotification[] | undefined>({
  key: "preMappedUserNotificationsAtom",
  default: undefined,
});

export const selectedUsersForAlertAtom = atom<SelectedUser[] | undefined>({
  key: "selectedUsersForAlertAtom",
  default: undefined,
});
