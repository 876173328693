import {
  assignedTagErrorAtom,
  freeSoloChipsListAtom,
  tagsToCreateAtom,
} from "components/organisms/TagsUpdateSidebar/tagsSidebarState";
import { useCreateMappingDeviceTagsMutation } from "graphqlBase/MappingDeviceTag/__generated__/createMappingDeviceTags";
import { GetMappingDeviceTagsDocument } from "graphqlBase/MappingDeviceTag/__generated__/getMappingDeviceTags";
import { useCreateTagsMutation } from "graphqlBase/Tag/__generated__/createTags";
import { GetTagsDocument } from "graphqlBase/Tag/__generated__/getTags";
import { MappingDeviceTagCreateType } from "graphqlBase/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MappingDeviceTag } from "..";
import { selectedDevicesForTagsAtom } from "../tagModalState";

const useCreateDeviceTags = () => {
  const selectedDeviceIds = useRecoilValue(selectedDevicesForTagsAtom).map((device) => device.id);
  const selectedTags = useRecoilValue(freeSoloChipsListAtom);
  const createTags = useRecoilValue(tagsToCreateAtom) ?? [];
  const setAssignedTagError = useSetRecoilState(assignedTagErrorAtom);

  const [
    createMappingDeviceTagsMutation,
    { loading: createMappingDeviceTagsLoading },
  ] = useCreateMappingDeviceTagsMutation({
    refetchQueries: [{ query: GetMappingDeviceTagsDocument }],
  });

  const [createTagsMutation, { loading: createDeviceTagsLoading }] = useCreateTagsMutation({
    refetchQueries: [{ query: GetTagsDocument }],
  });

  const handleCreateDeviceTags = async ({ mappingDeviceTags }: { mappingDeviceTags: MappingDeviceTag[] }) => {
    try {
      const { data } = await createTagsMutation({ variables: { createTags } });

      if (!createTags.length && !selectedTags.length) return;

      const createDeviceMappings = selectedDeviceIds.reduce((accDevice: MappingDeviceTagCreateType[], currDeviceId) => {
        const temp = selectedTags.reduce((accChip: MappingDeviceTagCreateType[], currTag) => {
          const index = mappingDeviceTags.findIndex(
            (entry) => entry.deviceId === currDeviceId && entry.tagId === currTag.value
          );
          if (index === -1) return accChip.concat({ tagId: currTag.value, deviceId: currDeviceId });
          return accChip;
        }, []);

        const newMappings: MappingDeviceTagCreateType[] =
          data?.createTags?.map((newTag) => {
            return { tagId: newTag?.id ?? "", deviceId: currDeviceId };
          }) ?? [];

        const allMappings = temp.concat(newMappings);
        return accDevice.concat(allMappings);
      }, []);
      if (createDeviceMappings.length > 0) {
        await createMappingDeviceTagsMutation({ variables: { createMappingDeviceTags: createDeviceMappings } });
      } else {
        setAssignedTagError("This tag is already assigned");
        setTimeout(() => {
          setAssignedTagError(undefined);
        }, 3000);
      }
    } catch (error) {
      setAssignedTagError("This tag is already assigned");
      setTimeout(() => {
        setAssignedTagError(undefined);
      }, 3000);
    }
  };

  return {
    createDeviceTagsLoading: createMappingDeviceTagsLoading || createDeviceTagsLoading,
    handleCreateDeviceTags,
  };
};

export default useCreateDeviceTags;
