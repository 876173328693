import { OptionItem } from "@liebherr/patternlib/dist/types/components/form/patternlib-dropdown/patternlib-dropdown";
import Select from "components/lbc-toolkit/molecules/Select";
import React, { Key, useEffect, useState } from "react";
import { Empty, FieldItems } from "react-awesome-query-builder";

interface FieldProps {
  items?: FieldItems | undefined;
  key?: Key | null | undefined;
  placeholder?: string;
  readonly?: boolean;
  selectedKey?: string | Empty;
  selectedKeys?: string[] | Empty;
  setField?: ((path: string) => void) | undefined;
}

export default ({ key, items, setField, selectedKey, placeholder, readonly, selectedKeys }: FieldProps) => {
  const [selected, setSelected] = useState<OptionItem | undefined>();

  const renderOptions = Object.values(items ?? {}).reduce<Array<OptionItem>>((renderOptions, current) => {
    return renderOptions.concat({ label: current?.label ?? "", value: current?.key ?? "" });
  }, []);

  useEffect(() => {
    if (!selected) setSelected(renderOptions.find((item) => item.value === selectedKey));
  }, [selectedKey]);

  const handleSelect = (e: any) => {
    if (e.target.value === undefined) return;
    setSelected(e.target.value);
    setField?.(e.target.value);
  };

  return (
    <Select
      disabled={readonly ?? false}
      placeholder={placeholder}
      selected={selectedKey ?? ""}
      id="query-builder-select"
      options={renderOptions}
      onChange={handleSelect}
    />
  );
};
