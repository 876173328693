import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllCapabilitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCapabilitiesQuery = { capabilities: Array<{ __typename?: 'Capability', id: string, capabilityName: string, capabilityDescription?: Maybe<string>, deviceModelCapability: Array<{ __typename?: 'DeviceModelCapability', unit: { __typename?: 'Unit', unitSymbol: string, unitName: string } }>, deviceCapability: Array<{ __typename?: 'DeviceCapability', unit: { __typename?: 'Unit', id: string, unitName: string, unitSymbol: string } }> }> };


export const GetAllCapabilitiesDocument = gql`
    query getAllCapabilities {
  capabilities {
    id
    capabilityName
    capabilityDescription
    deviceModelCapability {
      unit {
        unitSymbol
        unitName
      }
    }
    deviceCapability {
      unit {
        id
        unitName
        unitSymbol
      }
    }
  }
}
    `;

/**
 * __useGetAllCapabilitiesQuery__
 *
 * To run a query within a React component, call `useGetAllCapabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCapabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCapabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCapabilitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCapabilitiesQuery, GetAllCapabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllCapabilitiesQuery, GetAllCapabilitiesQueryVariables>(GetAllCapabilitiesDocument, options);
      }
export function useGetAllCapabilitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCapabilitiesQuery, GetAllCapabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllCapabilitiesQuery, GetAllCapabilitiesQueryVariables>(GetAllCapabilitiesDocument, options);
        }
export type GetAllCapabilitiesQueryHookResult = ReturnType<typeof useGetAllCapabilitiesQuery>;
export type GetAllCapabilitiesLazyQueryHookResult = ReturnType<typeof useGetAllCapabilitiesLazyQuery>;
export type GetAllCapabilitiesQueryResult = Apollo.QueryResult<GetAllCapabilitiesQuery, GetAllCapabilitiesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
