/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect } from "react";
import { Button } from "@mui/material";
import translations from "translations";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { useHasSelectedUsersChanges } from "./UpdateRole/useGetMappingsUsersToSave";
import { isButtonDisabled } from "./RecoilState/recoilState";
import { useRecoilValue } from "recoil";
import { useHasSelectedRolesChanges } from "./UserRolesManager/MappingRoleScopesTable/useGetMappingsToSave";

interface ButtonProps {
  doMutations: () => void;
  setBlocked: (val: boolean) => void;
}
const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttons: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "30px",
      width: "100%",
    },
  })
);

export const SaveButton: React.FC<ButtonProps> = ({ doMutations, setBlocked }) => {
  const classes = useStyles();
  const isDisabled = useRecoilValue(isButtonDisabled);
  const disabledUsers = !useHasSelectedUsersChanges();
  const disabledRoles = !useHasSelectedRolesChanges().hasChanges;
  const disabled = disabledUsers && disabledRoles && isDisabled;

  useEffect(() => {
    setBlocked(!disabled);
  }, [disabled, setBlocked]);
  return (
    <div className={classes.buttons}>
      <Button
        disabled={disabled}
        onClick={() => {
          doMutations();
        }}
      >
        {translations.globals.button.save}
      </Button>
    </div>
  );
};
