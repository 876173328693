// eslint-disable-next-line filenames/match-exported
import React, { useState } from "react";
import CreateRole from "./CreateRole";
import { GetRolesQuery } from "graphqlBase/Settings/__generated__/getRoles";
import { GetScopesQuery } from "graphqlBase/Settings/__generated__/getScopes";
import { GetUsersQuery } from "graphqlBase/Settings/__generated__/getUsers";

const CreatNeweRole: React.FC<{}> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rolesData] = useState<GetRolesQuery | undefined>();
  const [scopesData] = useState<GetScopesQuery | undefined>();
  const [usersData] = useState<GetUsersQuery | undefined>();

  return (
    <>
      {!isModalOpen && (
        <CreateRole
          rolesData={rolesData}
          scopesData={scopesData}
          setIsModalOpen={() => setIsModalOpen(true)}
          usersData={usersData}
        />
      )}
    </>
  );
};

export default CreatNeweRole;
