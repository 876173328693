import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetDeviceAndTypeByIdQueryVariables = Types.Exact<{
  assetId: Types.Scalars['UUID'];
}>;


export type GetAssetDeviceAndTypeByIdQuery = { asset?: Maybe<{ __typename?: 'Asset', id: string, mappingAssetTag: Array<{ __typename?: 'MappingAssetTag', id: string, assetTag: { __typename?: 'AssetTag', id: string, tagValue: string } }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string>, sellingUnitSerialNumber?: Maybe<string> }>, assetSource?: Maybe<{ __typename?: 'AssetSource', id: string, assetSourceName: string }>, assetDevice: Array<{ __typename?: 'AssetDevice', id: string, device: { __typename?: 'Device', id: string } }> }> };


export const GetAssetDeviceAndTypeByIdDocument = gql`
    query getAssetDeviceAndTypeById($assetId: UUID!) {
  asset(id: $assetId) {
    id
    mappingAssetTag {
      id
      assetTag {
        id
        tagValue
      }
    }
    assetMasterData {
      id
      generalItem
      sellingUnitSerialNumber
    }
    assetSource {
      id
      assetSourceName
    }
    assetDevice {
      id
      device {
        id
      }
    }
  }
}
    `;

/**
 * __useGetAssetDeviceAndTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetAssetDeviceAndTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDeviceAndTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDeviceAndTypeByIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetDeviceAndTypeByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAssetDeviceAndTypeByIdQuery, GetAssetDeviceAndTypeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetDeviceAndTypeByIdQuery, GetAssetDeviceAndTypeByIdQueryVariables>(GetAssetDeviceAndTypeByIdDocument, options);
      }
export function useGetAssetDeviceAndTypeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetDeviceAndTypeByIdQuery, GetAssetDeviceAndTypeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetDeviceAndTypeByIdQuery, GetAssetDeviceAndTypeByIdQueryVariables>(GetAssetDeviceAndTypeByIdDocument, options);
        }
export type GetAssetDeviceAndTypeByIdQueryHookResult = ReturnType<typeof useGetAssetDeviceAndTypeByIdQuery>;
export type GetAssetDeviceAndTypeByIdLazyQueryHookResult = ReturnType<typeof useGetAssetDeviceAndTypeByIdLazyQuery>;
export type GetAssetDeviceAndTypeByIdQueryResult = Apollo.QueryResult<GetAssetDeviceAndTypeByIdQuery, GetAssetDeviceAndTypeByIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
