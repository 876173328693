import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateGroupsMutationVariables = Types.Exact<{
  groups?: Types.InputMaybe<Array<Types.InputMaybe<Types.GroupUpdateType>> | Types.InputMaybe<Types.GroupUpdateType>>;
}>;


export type UpdateGroupsMutation = { updateGroups?: Maybe<Array<Maybe<{ __typename?: 'Group', id: string }>>> };


export const UpdateGroupsDocument = gql`
    mutation updateGroups($groups: [GroupUpdateType]) {
  updateGroups(groups: $groups) {
    id
  }
}
    `;
export type UpdateGroupsMutationFn = Apollo.MutationFunction<UpdateGroupsMutation, UpdateGroupsMutationVariables>;

/**
 * __useUpdateGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupsMutation, { data, loading, error }] = useUpdateGroupsMutation({
 *   variables: {
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useUpdateGroupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGroupsMutation, UpdateGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateGroupsMutation, UpdateGroupsMutationVariables>(UpdateGroupsDocument, options);
      }
export type UpdateGroupsMutationHookResult = ReturnType<typeof useUpdateGroupsMutation>;
export type UpdateGroupsMutationResult = Apollo.MutationResult<UpdateGroupsMutation>;
export type UpdateGroupsMutationOptions = Apollo.BaseMutationOptions<UpdateGroupsMutation, UpdateGroupsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
