import { Box, Typography } from "@mui/material";
import PLButton from "components/patternLib/PLButton";
import PLModal from "components/patternLib/PLModal";
import useDataGrid from "components/templates/dataGridTable";
import { isFuture } from "date-fns";
import { useGetMappingDeviceConfigurationDevicesLazyQuery } from "graphqlBase/Devices/__generated__/getMappingDeviceConfigurationDevices";
import { useGetUsersForPreviousDeviceConfigurationsQuery } from "graphqlBase/Devices/__generated__/getUsersForPreviousDeviceConfigurations";
import { SortEnumType } from "graphqlBase/types";
import React, { useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { formatDate } from "translations/formatter";
import { ConfigurationProps, MappingDeviceConfigurationDevice, ReuseModalInfo } from "./index.d";
import { deviceConfigurationAtom } from "./recoilState";
import useHandleSaveConfig from "./useHandleSaveConfig";

const PreviousConfigurations: React.FC<ConfigurationProps> = ({ selectedDeviceId }) => {
  const handleSaveConfig = useHandleSaveConfig(selectedDeviceId as string);
  const [reuseModalInfo, setReuseModalInfo] = useState<ReuseModalInfo>({
    isModalOpen: false,
    dateFrom: "",
    dateTo: "",
  });
  const setDeviceConfiguration = useSetRecoilState(deviceConfigurationAtom);
  const [query, { data, loading, error }] = useGetMappingDeviceConfigurationDevicesLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const { data: usersData } = useGetUsersForPreviousDeviceConfigurationsQuery();
  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef, getRowCount } = useDataGrid<
    MappingDeviceConfigurationDevice,
    "MappingDeviceConfigurationDevice"
  >({
    query,
    variables: {
      where: { deviceId: { eq: selectedDeviceId } },
      order: [{ dateTo: "DESC" as SortEnumType.Desc }],
    },
    tableId: "PreviousConfigurations",
    persistance: "runTime",
  });

  const length = data?.mappingDeviceConfigurationDevices?.length;
  const idOfDefaultConfig = length ? data?.mappingDeviceConfigurationDevices[length - 1].id : "";

  const mappedUsers: { [key: string]: string } | undefined = useMemo(() => {
    return usersData?.users.reduce((acc, currUser) => {
      return { ...acc, [currUser.id]: currUser.userName };
    }, {});
  }, [usersData?.users]);

  const columns = useMakeColumns(
    [
      {
        field: "date",
        headerName: `Date`,
        renderHeader: (params) => `Date (${getRowCount()})`,
        flex: 2,
        valueGetter: (params) => {
          return `${formatDate(new Date(params.row.dateFrom ?? ""))} - ${
            !isFuture(new Date(params.row.dateTo ?? "")) ? formatDate(new Date(params.row.dateTo ?? "")) : "current"
          }`;
        },
        type: "string",
      },
      {
        field: "setupBy",
        headerName: `Set up by`,
        flex: 2,
        valueGetter: (params) => {
          if (!idOfDefaultConfig ?? !mappedUsers) return;
          if (idOfDefaultConfig === params.row.id) {
            return "default";
          } else {
            return mappedUsers[params.row.userId as string];
          }
        },
        type: "string",
      },
      {
        field: "status",
        headerName: `Status`,
        flex: 2,
        valueGetter: (params) => {
          return params.row.mappingDeviceConfigurationDeviceStatus?.statusName ?? "";
        },
        type: "string",
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => {
          return !isFuture(new Date(params.row.dateTo ?? "")) ? (
            <PLButton
              type="secondary"
              size="small"
              onClick={() => {
                setReuseModalInfo({ dateFrom: params.row.dateFrom, dateTo: params.row.dateTo, isModalOpen: true });
                setDeviceConfiguration(JSON.parse(params.row.deviceConfiguration.configurationJSON));
              }}
              label="Reuse"
            />
          ) : (
            ""
          );
        },
        type: "ReactElement",
        valueType: "string",
        disableColumnMenu: true,
      },
    ],
    []
  );

  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage=""
          tableTitle=""
          withMargin={false}
          tableHeight="30vh"
          checkboxSelection={false}
          hasToolbar={false}
          columns={columns}
          rows={data?.mappingDeviceConfigurationDevices}
          loading={loading}
        />
      )}
      <PLModal
        showIcon
        allowCloseOnBackdrop
        modalTitle={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.reuseConfiguration.modalTitle}
        lhCancel={() => setReuseModalInfo({ dateFrom: "", dateTo: "", isModalOpen: false })}
        lhConfirm={async () => {
          await handleSaveConfig();
          setReuseModalInfo({ dateFrom: "", dateTo: "", isModalOpen: false });
        }}
        lhClose={() => setReuseModalInfo({ dateFrom: "", dateTo: "", isModalOpen: false })}
        show={reuseModalInfo.isModalOpen}
        iconName="filter-reset"
      >
        <>
          <Box slot="modal-content">
            {reuseModalInfo.dateFrom && (
              <Typography variant="copy">
                {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.reuseConfiguration.message}{" "}
                {formatDate(new Date(reuseModalInfo.dateFrom))}-{formatDate(new Date(reuseModalInfo.dateTo))}.{" "}
                {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.cloudMessage}
              </Typography>
            )}
          </Box>
        </>
      </PLModal>
    </>
  );
};

export default PreviousConfigurations;
