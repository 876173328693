import { ability } from "../config/ability";

export interface TabInterface {
  label: string;
  scope: string;
}

export const authorizedTabsArray = (tabs: TabInterface[]) => {
  return tabs.filter(({ scope }) => {
    return ability.can("read", scope);
  }, []);
};
