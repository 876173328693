import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetIsActiveByNotificationIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetIsActiveByNotificationIdQuery = { notification?: Maybe<{ __typename?: 'Notification', id: string, isActive: boolean }> };


export const GetIsActiveByNotificationIdDocument = gql`
    query getIsActiveByNotificationId($id: UUID!) {
  notification(id: $id) {
    id
    isActive
  }
}
    `;

/**
 * __useGetIsActiveByNotificationIdQuery__
 *
 * To run a query within a React component, call `useGetIsActiveByNotificationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsActiveByNotificationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsActiveByNotificationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIsActiveByNotificationIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetIsActiveByNotificationIdQuery, GetIsActiveByNotificationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetIsActiveByNotificationIdQuery, GetIsActiveByNotificationIdQueryVariables>(GetIsActiveByNotificationIdDocument, options);
      }
export function useGetIsActiveByNotificationIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIsActiveByNotificationIdQuery, GetIsActiveByNotificationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetIsActiveByNotificationIdQuery, GetIsActiveByNotificationIdQueryVariables>(GetIsActiveByNotificationIdDocument, options);
        }
export type GetIsActiveByNotificationIdQueryHookResult = ReturnType<typeof useGetIsActiveByNotificationIdQuery>;
export type GetIsActiveByNotificationIdLazyQueryHookResult = ReturnType<typeof useGetIsActiveByNotificationIdLazyQuery>;
export type GetIsActiveByNotificationIdQueryResult = Apollo.QueryResult<GetIsActiveByNotificationIdQuery, GetIsActiveByNotificationIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
