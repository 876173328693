import colors from "../colors";

export const muiTabsOverrides = {
  root: {
    alignItems: "center",
    backgroundColor: colors.lbcGrey,
    display: "flex",
    fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
    fontSize: "16px",
    "& .MuiTabs-scroller": {
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    "& .MuiTab-wrapper": {
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
      textTransform: "capitalize",
    },
  },
};
