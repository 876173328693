import { styled, Typography } from "@mui/material";

export const queryBuilderStyles = {
  display: "flex",
  "& .query-builder": {
    margin: 0,
    marginTop: "1.5em",
    minWidth: "560px",
    "& .group-or-rule-container": { paddingRight: 0 },
    "& .group-or-rule-container.group-container, .group-or-rule-container.rule-container": {
      "& .rule.group-or-rule": {
        padding: "3px 5px",
        backgroundColor: "#E4E4E4",
        borderRadius: 0,
        width: "fit-content",
        alignItems: "center",
        "& .qb-drag-handler.rule--drag-handler": {
          "& svg": { fill: "#000" },
        },
        "& .rule--body--wrapper": {
          "& > .rule--body": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            "& .rule--field": {
              "& #dropdown-item-container": {
                position: "fixed",
              },
            },
            "& .rule--widget.rule--widget--TEXT, .rule--widget.rule--widget--MULTISELECT": {
              display: "flex",
              height: "fit-content",
              "&:focus-visible": { outline: "none" },
            },
          },
        },
      },
      "& .rule.group-or-rule, .group.group-or-rule": {
        "&::before, ::after": {
          borderColor: "#8C8C8C",
          borderLeftWidth: 1.5,
          borderBottomWidth: 1.5,
          borderRadius: 0,
        },
        "&::after": { borderBottom: "none" },
      },
      "& .group.group-or-rule": {
        background: "transparent",
        borderColor: "#E4E4E4",
        borderWidth: "1.5px",
        borderRadius: 0,
        paddingRight: "10px",
        "&::before": {
          left: "-14.5px",
          top: "-5px",
        },
        "&::after": { left: "-14.5px" },
      },
    },
  },
};

export const ContainerSC = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: 3em;
  background-color: ${theme.palette.background.paper}
`
);

export const QueryBuilderContainerSC = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: fit-content;
  min-width: fit-content;
  width: 50%;
`
);

export const TitleBoxSC = styled("div")(
  () => `
  display: flex;
  min-height: 45px;
`
);

export const QueryBuilderContentSC = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: fit-content;
  min-height: 450px;
  margin-top: 10px;
`
);

export const ButtonsContainerSC = styled("div")(
  () => `
  display: flex;
  align-self: flex-end;
  width: 330px;
  justify-content: space-between;
  margin-top: 10px;
`
);

export const QueryBuilderSC = styled("div")(
  () => `
  margin-top: 10px;
  margin-bottom: 30px;
`
);

export const QueryBuilderTitleSC = styled(Typography)(
  () => `
    font-family: var(--form-label-font-family);
    font-weight: var(--form-label-font-weight);
    font-size: var(--copytext-font-size-small);
    margin-top: 10px;
`
);
