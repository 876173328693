import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export interface StyleProps {
  iconSize?: string | number;
  style?: React.CSSProperties;
}

export const useFormStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      selectInput: (props: StyleProps) => ({
        display: "flex",
        minHeight: props?.style?.height ?? "40px",
        height: "min-content",
        backgroundColor: "#fff",
        borderRadius: 0,
        border: `1px solid #000`,
        "&:focus": {
          backgroundColor: theme.colors.tWhite,
          borderRadius: 0,
        },
      }),
      root: (props: StyleProps) => ({
        minWidth: props?.style?.width ?? 260,
        width: props?.style?.width ?? "100%",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-icon": {
          color: "black",
          marginRight: "10px",
          marginLeft: "5px",
          transform: "none",
          top: "calc(50% - 12px)",
        },
        "& .MuiInputBase-input": {
          minHeight: "40px",
          display: "flex",
          alignItems: "center",
          padding: "5px 15px 5px 19px",
          fontFamily: "var(--copytext-font-family)",
          fontSize: "var(--copytext-font-size)",
        },
      }),
    }),
  { name: "SelectInputField" }
);
