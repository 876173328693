import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetEnvironmentalMeasurementsForAssetDetailsQueryVariables = Types.Exact<{
  deviceId?: Types.InputMaybe<Types.Scalars['UUID']>;
  capabilityIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
}>;


export type GetEnvironmentalMeasurementsForAssetDetailsQuery = { sensorMeasurementHistories: Array<{ __typename?: 'SensorMeasurementHistory', id: string, deviceId: string, valueString?: Maybe<string>, utcTimeMeasured: string, capabilityId: string }> };


export const GetEnvironmentalMeasurementsForAssetDetailsDocument = gql`
    query getEnvironmentalMeasurementsForAssetDetails($deviceId: UUID, $capabilityIds: [UUID!], $startDate: DateTime, $endDate: DateTime) {
  sensorMeasurementHistories(where: {deviceId: {eq: $deviceId}, capabilityId: {in: $capabilityIds}, utcTimeMeasured: {gte: $startDate, lte: $endDate}}, order: {utcTimeMeasured: ASC}) {
    id
    deviceId
    valueString
    utcTimeMeasured
    capabilityId
  }
}
    `;

/**
 * __useGetEnvironmentalMeasurementsForAssetDetailsQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentalMeasurementsForAssetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentalMeasurementsForAssetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentalMeasurementsForAssetDetailsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      capabilityIds: // value for 'capabilityIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetEnvironmentalMeasurementsForAssetDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEnvironmentalMeasurementsForAssetDetailsQuery, GetEnvironmentalMeasurementsForAssetDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEnvironmentalMeasurementsForAssetDetailsQuery, GetEnvironmentalMeasurementsForAssetDetailsQueryVariables>(GetEnvironmentalMeasurementsForAssetDetailsDocument, options);
      }
export function useGetEnvironmentalMeasurementsForAssetDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEnvironmentalMeasurementsForAssetDetailsQuery, GetEnvironmentalMeasurementsForAssetDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEnvironmentalMeasurementsForAssetDetailsQuery, GetEnvironmentalMeasurementsForAssetDetailsQueryVariables>(GetEnvironmentalMeasurementsForAssetDetailsDocument, options);
        }
export type GetEnvironmentalMeasurementsForAssetDetailsQueryHookResult = ReturnType<typeof useGetEnvironmentalMeasurementsForAssetDetailsQuery>;
export type GetEnvironmentalMeasurementsForAssetDetailsLazyQueryHookResult = ReturnType<typeof useGetEnvironmentalMeasurementsForAssetDetailsLazyQuery>;
export type GetEnvironmentalMeasurementsForAssetDetailsQueryResult = Apollo.QueryResult<GetEnvironmentalMeasurementsForAssetDetailsQuery, GetEnvironmentalMeasurementsForAssetDetailsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
