export default {
  name: "rule reference unit",
  plural: "rules references units",
  columns: {
    id: "ID",
    isDeleted: "is deleted",
    ruleReference: "rule reference",
    ruleReferenceUnitDescription: "rule reference unit description",
    ruleReferenceUnitName: "rule reference unit name",
  },
};
