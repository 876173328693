import { Box, Typography } from "@mui/material";
import Toast from "components/atomics/Toast";
import PLTextinput from "components/patternLib/form/PLTextinput";
import PLButton from "components/patternLib/PLButton";
import PLDropzone from "components/patternLib/PLDropzone";
import PLImage from "components/patternLib/PLImage";
import PLModal from "components/patternLib/PLModal";
import React, { useState } from "react";
import translations from "translations";
import { useHandleSavePhoto } from "./useHandleSavePhoto";

interface Props {
  afterMutation: () => Promise<void>;
  assetId: string;
  dropzoneModalOpen: boolean;
  setDropzoneModalOpen: (value: boolean) => void;
}
const PhotoUpload: React.FC<Props> = ({ assetId, afterMutation, setDropzoneModalOpen, dropzoneModalOpen }) => {
  const [step, setStep] = useState<0 | 1>(0);
  const {
    file,
    handleUploadedPictures,
    convertImageToBase64,
    saveImage,
    handleDescription,
    clearStates,
    toastObject,
    handleCloseToast,
    image,
    imageDescription,
  } = useHandleSavePhoto({ assetId, afterMutation, setDropzoneModalOpen, setStep });

  return (
    <>
      {" "}
      {dropzoneModalOpen && (
        <PLModal
          modalTitle={translations.pages.assetManagement.assetDetails.dropZoneModalTitle}
          show={dropzoneModalOpen}
          lhClose={clearStates}
          hideSubmit
          hideCancel
          allowCloseOnBackdrop
        >
          <Box slot="modal-content">
            <Box sx={{ display: step === 1 ? "none" : "block" }}>
              <PLDropzone
                label={translations.pages.assetManagement.assetDetails.dropzoneButtonLabel}
                acceptedFiles="PNG, JPG, GIF"
                width="100%"
                dropzoneLabel={translations.pages.assetManagement.assetDetails.uploadImageDropzoneLabel}
                lhChange={handleUploadedPictures}
                showDefaultSubmitButton={false}
              />

              <Box sx={{ display: "flex", flexDirection: "row-reverse", marginTop: "25px" }}>
                <PLButton
                  label={translations.globals.button.continue}
                  disabled={!file}
                  onClick={async () => {
                    await convertImageToBase64(file as File);
                    setStep(1);
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ display: step === 0 ? "none" : "block" }}>
              <Box sx={{ display: "flex", flexDirection: "column", rowGap: "15px", marginBottom: "25px" }}>
                <Typography variant="h6">{translations.pages.assetManagement.assetDetails.describePhoto}</Typography>
                <PLImage src={image} objectFit="contain" alt="image" width="100%" height="250px" />
                <PLTextinput readonly={!image} placeholder="Caption" lhChange={handleDescription} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row-reverse", columnGap: "15px" }}>
                <PLButton
                  type="primary"
                  label={translations.globals.button.save}
                  onClick={saveImage}
                  disabled={!imageDescription}
                />
                <PLButton type="secondary" label={translations.globals.button.back} onClick={() => setStep(0)} />
              </Box>
            </Box>
          </Box>
        </PLModal>
      )}
      {toastObject && (
        <Toast
          message={toastObject.message}
          open={toastObject.open}
          severity={toastObject.severity}
          handleCloseToast={handleCloseToast}
        />
      )}
    </>
  );
};

export default PhotoUpload;
