// Use this function to add data-testid to components to make them accessible for integration tests.

// Import into your component:
// import getTestIDs from '../lib/utils/getTestIDs';

// Call the function and export to make it easily accessible:
// export const testIDs = getTestIDs();

// Add the identifier to each component:
// data-testid={testIDs.component}

function getTestIDs() {
  if (process.env.NODE_ENV !== "test" && process.env.REACT_APP_FOR_TEST !== "true") return {};

  const ids = new Map();
  const handler = {
    get: function (obj: any, prop: string) {
      if (!ids.has(prop)) {
        ids.set(prop, prop);
      }
      return ids.get(prop);
    },
  };

  const proxy = new Proxy({}, handler);
  return proxy;
}

export default getTestIDs;
