import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesBySerialNumberQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
}>;


export type GetDevicesBySerialNumberQuery = { devices: Array<{ __typename?: 'Device', id: string, serialNo: string }> };


export const GetDevicesBySerialNumberDocument = gql`
    query getDevicesBySerialNumber($where: DeviceFilterInput) {
  devices(where: $where) {
    id
    serialNo
  }
}
    `;

/**
 * __useGetDevicesBySerialNumberQuery__
 *
 * To run a query within a React component, call `useGetDevicesBySerialNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesBySerialNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesBySerialNumberQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDevicesBySerialNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesBySerialNumberQuery, GetDevicesBySerialNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesBySerialNumberQuery, GetDevicesBySerialNumberQueryVariables>(GetDevicesBySerialNumberDocument, options);
      }
export function useGetDevicesBySerialNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesBySerialNumberQuery, GetDevicesBySerialNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesBySerialNumberQuery, GetDevicesBySerialNumberQueryVariables>(GetDevicesBySerialNumberDocument, options);
        }
export type GetDevicesBySerialNumberQueryHookResult = ReturnType<typeof useGetDevicesBySerialNumberQuery>;
export type GetDevicesBySerialNumberLazyQueryHookResult = ReturnType<typeof useGetDevicesBySerialNumberLazyQuery>;
export type GetDevicesBySerialNumberQueryResult = Apollo.QueryResult<GetDevicesBySerialNumberQuery, GetDevicesBySerialNumberQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
