import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TableSubcomponentProps } from "../tableTypes";

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      subcomponent: {
        backgroundColor: "inherit !important",
      },
    }),

  { name: `Table-${"Standard"}-TableSubcomponent` }
);

const TableSubcomponent: React.FC<TableSubcomponentProps<object>> = ({
  row,
  tableProps,
  tableInstance,
  renderRowSubComponent,
}) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.subcomponent} {...row.getRowProps()} {...tableProps?.bodySubComponentRow}>
      <TableCell colSpan={tableInstance.visibleColumns.length} {...tableProps?.bodySubComponentCell}>
        {renderRowSubComponent({ row })}
      </TableCell>
    </TableRow>
  );
};

export default TableSubcomponent;
