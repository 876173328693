import { Box, Typography } from "@mui/material";
import PLModal from "components/patternLib/PLModal";
import { genericDeleteParams } from "components/templates/table-factory/Table/Cells/actionsCellFactory/recoilStates";
import genericDelete from "graphqlBase/genericDelete";
import React, { useCallback } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

interface ConfirmAndDeleteProps {
  title: string;
  message?: string;
  show: boolean;
}

const ConfirmAndDelete: React.FC<ConfirmAndDeleteProps> = ({ title, message, show }) => {
  const deleteParams = useRecoilValue(genericDeleteParams);
  const resetDeleteParams = useResetRecoilState(genericDeleteParams);

  const handleDeleteInner = useCallback(() => {
    const { id, entity, afterDelete } = deleteParams;
    entity &&
      genericDelete({ entity, afterDelete })(id ?? "").finally(() => {
        afterDelete?.(id);
        resetDeleteParams();
      });
  }, [deleteParams]);

  return (
    <>
      <PLModal
        allowCloseOnBackdrop
        modalTitle={title}
        lhCancel={resetDeleteParams}
        lhConfirm={handleDeleteInner}
        lhClose={resetDeleteParams}
        show={show}
        iconName="bin"
      >
        <>
          <Box slot="modal-content">
            <Typography variant="copy">{message}</Typography>
          </Box>
        </>
      </PLModal>
    </>
  );
};

export default ConfirmAndDelete;
