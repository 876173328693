import { IconButton, IconButtonProps } from "@mui/material";
import clsx from "clsx";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useOtherStyles } from "./utils";

export const testIDs = getTestIDs();
export interface DefaultButtonProps extends IconButtonProps {
  animation?: "pulse" | "spin";
  buttonType: "primary" | "secondary" | "danger" | "radial";
  icon?: JSX.Element;
  label?: string;
  testId?: string;
  theme?: "light" | "dark";
}

const DefaultButton: React.FC<DefaultButtonProps> = (props) => {
  const {
    label,
    buttonType,
    icon,
    onClick,
    disabled,
    animation,
    theme = "light",
    classes,
    style,
    className,
    testId,
  } = props;

  const otherClasses = useOtherStyles({ style, label, icon: !!icon, animation });
  const color = buttonType === "primary" || buttonType === "secondary" ? buttonType : "default";
  const layoutWrapper = !!icon && !!label ? otherClasses.combinedWrapper : otherClasses.wrapper;

  const otherStylesWrapper = clsx(otherClasses.rootButton, {
    [otherClasses.danger]: buttonType === "danger",
    [otherClasses.radial]: buttonType === "radial",
  });

  const darkTheme =
    theme === "dark" &&
    clsx({
      [otherClasses.darkPrimary]: buttonType === "primary",
      [otherClasses.darkSecondary]: buttonType === "secondary",
      [otherClasses.darkDanger]: buttonType === "danger",
      [otherClasses.darkRadial]: buttonType === "radial",
    });

  return (
    <IconButton
      data-testid={testId ?? testIDs.DefaultButton}
      className={clsx(otherStylesWrapper, darkTheme, className)}
      onClick={onClick}
      disabled={disabled}
      classes={classes}
      color={color}
      size="large"
    >
      <div className={layoutWrapper}>
        {!!icon && icon}
        {!!label && <span className={otherClasses.label}>{label}</span>}
      </div>
    </IconButton>
  );
};

export default DefaultButton;
