import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetLastTimePositionTrackedQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SensorMeasurementCurrentValueFilterInput>;
}>;


export type GetLastTimePositionTrackedQuery = { sensorMeasurementCurrentValues: Array<{ __typename?: 'SensorMeasurementCurrentValue', id: string, utcTimeMeasured: string }> };


export const GetLastTimePositionTrackedDocument = gql`
    query getLastTimePositionTracked($where: SensorMeasurementCurrentValueFilterInput) {
  sensorMeasurementCurrentValues(where: $where) {
    id
    utcTimeMeasured
  }
}
    `;

/**
 * __useGetLastTimePositionTrackedQuery__
 *
 * To run a query within a React component, call `useGetLastTimePositionTrackedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastTimePositionTrackedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastTimePositionTrackedQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLastTimePositionTrackedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLastTimePositionTrackedQuery, GetLastTimePositionTrackedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLastTimePositionTrackedQuery, GetLastTimePositionTrackedQueryVariables>(GetLastTimePositionTrackedDocument, options);
      }
export function useGetLastTimePositionTrackedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastTimePositionTrackedQuery, GetLastTimePositionTrackedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLastTimePositionTrackedQuery, GetLastTimePositionTrackedQueryVariables>(GetLastTimePositionTrackedDocument, options);
        }
export type GetLastTimePositionTrackedQueryHookResult = ReturnType<typeof useGetLastTimePositionTrackedQuery>;
export type GetLastTimePositionTrackedLazyQueryHookResult = ReturnType<typeof useGetLastTimePositionTrackedLazyQuery>;
export type GetLastTimePositionTrackedQueryResult = Apollo.QueryResult<GetLastTimePositionTrackedQuery, GetLastTimePositionTrackedQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
