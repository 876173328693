import React, { useEffect, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import {
  GetSensorMeasurementHistoryAggregateQueryVariables,
  useGetSensorMeasurementHistoryAggregateQuery,
} from "graphqlBase/SensorMeasurementHistories/__generated__/getSensorMeasurementHistoryAggregate";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  sensorMeasurementHistoryAggregateSelectorFamily,
  sensorMeasurementHistoryAggregateVariablesAtom,
  durationOptionAtom,
} from "../../Configuration/recoilState";
import { formatDate } from "translations/formatter";
import ConnectionsMonitoringDatePicker from "./ConnectionsMonitoringDatePicker";
import { GranularityDropDown, MessageTypes, SelectDateFilter } from "./ConnectionsMonitoringFilters";
import { Box, styled, Typography } from "@mui/material";
import { LeftTileWrapperSC } from "../../utils/styles";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import translations from "translations";

const FiltersSC = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const NoDataContainerSC = styled("div")({
  height: "20vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});
interface ConnectionsMonitoringChartProps {
  deviceId?: string;
  gatewayDeviceConnectionId?: string;
}
const ConnectionsMonitoringChart: React.FC<ConnectionsMonitoringChartProps> = ({
  deviceId,
  gatewayDeviceConnectionId,
}) => {
  const variables = useRecoilValue(sensorMeasurementHistoryAggregateVariablesAtom);
  const durationOption = useRecoilValue(durationOptionAtom);
  const setDeviceId = useSetRecoilState(sensorMeasurementHistoryAggregateSelectorFamily("deviceIds"));
  const setGatewayDeviceConnectionId = useSetRecoilState(
    sensorMeasurementHistoryAggregateSelectorFamily("gatewayDeviceConnectionIds")
  );
  const setDimentions = useSetRecoilState(sensorMeasurementHistoryAggregateSelectorFamily("dimensions"));
  const { data, loading, error } = useGetSensorMeasurementHistoryAggregateQuery({
    variables: variables as GetSensorMeasurementHistoryAggregateQueryVariables,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (gatewayDeviceConnectionId) {
      setDeviceId(null);
      setGatewayDeviceConnectionId([gatewayDeviceConnectionId]);
      setDimentions(["GATEWAY_DEVICE_CONNECTION_ID"]);
    }
    if (deviceId) {
      setGatewayDeviceConnectionId(null);
      setDeviceId([deviceId]);
      setDimentions(["DEVICE_ID"]);
    }
  }, [deviceId]);

  const xData = data?.sensorMeasurementHistoryAggregate?.data?.map((el) => el?.timeBucket ?? "");
  const seriesData = data?.sensorMeasurementHistoryAggregate?.data
    ?.map((el) => el?.metrics?.flat())
    .flat()
    .map((el) => el?.value);

  const options = {
    grid: { right: 0, left: 30 },
    xAxis: {
      type: "category",
      data: xData,
      axisLabel: {
        formatter: (value: string) => {
          return formatDate(new Date(value));
        },
      },
    },
    yAxis: {},
    series: [
      {
        data: seriesData,
        type: "line",
        color: ["#43A017"],
      },
    ],
  };

  const MemoizedChart = useMemo(() => {
    return !seriesData?.length ? (
      <NoDataContainerSC>
        <Typography variant="copy">
          {translations.pages.deviceManagement.deviceDetails.connectionsMonitoring.noDataMessage}
        </Typography>
      </NoDataContainerSC>
    ) : (
      <ReactECharts option={options} />
    );
  }, [loading]);

  return (
    <>
      <LeftTileWrapperSC>
        <FiltersSC>
          <MessageTypes />
          <SelectDateFilter />
          {durationOption === "custom" && <ConnectionsMonitoringDatePicker />}
          <GranularityDropDown />
        </FiltersSC>
        <LoadingSpinner hideChildrenOnLoad loading={loading && !data?.sensorMeasurementHistoryAggregate?.data}>
          <Box sx={{ width: "100%" }}>{MemoizedChart}</Box>
        </LoadingSpinner>
      </LeftTileWrapperSC>
    </>
  );
};

export default ConnectionsMonitoringChart;
