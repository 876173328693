import { styled } from "@mui/material";
import { getAssetByDeviceId, getUserById } from "components/pages/utils";
import PLTextlink from "components/patternLib/PLTextlink";
import useDataGrid from "components/templates/dataGridTable";
import { differenceInMinutes, subDays } from "date-fns/esm";
import {
  GetDeviceNotificationsForRecentAlertsQuery,
  useGetDeviceNotificationsForRecentAlertsLazyQuery,
  ResultType,
} from "graphqlBase/DeviceNotification/__generated__/getDeviceNotificationsForRecentAlerts";
import { SortEnumType } from "graphqlBase/types.d";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
import { formatDateTime, formatHoursAndMinutes, getHoursAndMinutes } from "translations/formatter";

export const TimeCellWrapperSC = styled("div")(
  () => `
       display: inline-flex;
       align-items: center;
`
);

export const TimeCellMarkerSC = styled("div")(
  () => `
      background: var(--color-yellow);
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 10px;
`
);

export const testIDs = getTestIDs();

const TODAY_DATE = new Date();

const YESTERDAY_DATE = subDays(new Date(), 1);

export type DeviceNotification = ResultType<GetDeviceNotificationsForRecentAlertsQuery["deviceNotifications"]>;

export interface RecentDeviceNotification extends DeviceNotification {
  asset: { id: string; name: string };
  receiver?: string[];
}

const RecentNotifications: React.FC<{}> = () => {
  const [query, { data, loading, error, refetch }] = useGetDeviceNotificationsForRecentAlertsLazyQuery();
  const [recentAlerts, setRecentAlerts] = useState<RecentDeviceNotification[] | undefined>();
  const [tableLoading, setTableLoading] = useState(loading || !recentAlerts);
  const history = useHistory();
  const { DataGrid, useMakeColumns, makeSeverSideFilter } = useDataGrid<RecentDeviceNotification, "DeviceNotification">(
    {
      query,
      variables: { order: [{ notificationSend: SortEnumType.Desc }] },
      tableId: "RecentNotifications",
      persistance: "runTime",
    }
  );

  useEffect(() => {
    if (data?.deviceNotifications) {
      const alertWithUserEmail = (data.deviceNotifications ?? []).reduce<RecentDeviceNotification[]>(
        (alertWithUserEmail, currentAlert) => {
          const updatedAlert: RecentDeviceNotification = {
            ...currentAlert,
            receiver: [],
            asset: { name: "", id: "" },
          };

          getAssetByDeviceId(currentAlert.deviceId)
            .then((asset) => {
              updatedAlert.asset = { name: asset?.assetMasterData?.generalItem ?? "", id: asset?.id ?? "" };
            })
            .catch((error) => console.error("failed to get notification asset", error.message));
          currentAlert?.notificationDefinition?.notification?.mappingNotificationUser?.map((user) => {
            getUserById(user?.userId ?? "")
              .then((user) => {
                updatedAlert?.receiver?.push(user.email);
              })
              .catch((error) => console.error("failed to get user email", error.message));
          });
          return alertWithUserEmail.concat(updatedAlert);
        },
        []
      );

      setRecentAlerts(alertWithUserEmail);
    }

    if (error) setTableLoading(false);
  }, [data, error]);

  useEffect(() => {
    if (recentAlerts && tableLoading) refetch?.()?.finally(() => setTableLoading(false));
  }, [recentAlerts, tableLoading]);

  const columns = useMakeColumns(
    [
      {
        field: "notificationSend",
        headerName: translations.entities.deviceNotification.columns.notificationSend,
        flex: 2,
        remoteOrder: (sort) => ({ notificationSend: sort }),
        renderCell: (params) => {
          const isoDate = new Date(params.row?.notificationSend ?? TODAY_DATE);

          if (isoDate > YESTERDAY_DATE) {
            const hours = differenceInMinutes(TODAY_DATE, isoDate);
            const timeAgo = getHoursAndMinutes(hours);

            return (
              <TimeCellWrapperSC>
                <TimeCellMarkerSC />
                <p>
                  {`${
                    params.row?.notificationSend
                      ? formatHoursAndMinutes({
                          hours: Math.round(timeAgo?.hours) ?? 0,
                          minutes: Math.round(timeAgo?.minutes) ?? 0,
                          short: true,
                        })
                      : ""
                  }`}
                </p>
              </TimeCellWrapperSC>
            );
          }

          return formatDateTime(isoDate);
        },
        remoteFilter: makeSeverSideFilter("dateTime", {
          filterPath: ({ where, filterValue }) => ({
            notificationSend: filterValue,
          }),
        }),
        type: "dateTime",
      },
      {
        field: "notificationText",
        headerName: translations.entities.notification.columns.notificationText,
        renderHeader: () => `Alert name`,
        flex: 2,
        valueGetter: (params) => {
          return params.row?.notificationDefinition?.notification?.notificationText ?? "";
        },
        remoteOrder: (sort) => ({ notificationDefinition: { notification: { notificationText: sort } } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            notificationDefinition: { notification: { notificationText: filterValue } },
          }),
        }),
        type: "string",
      },
      {
        field: "scopeName",
        headerName: `Warning Area`,
        flex: 1.5,
        remoteOrder: (sort) => ({
          notificationDefinition: { notification: { notificationScope: { scopeName: sort } } },
        }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            notificationDefinition: { notification: { notificationScope: { scopeName: filterValue } } },
          }),
        }),
        valueGetter: (params) =>
          params.row?.notificationDefinition?.notification?.notificationScope?.scopeName?.replace("Scope", "") ?? "",
        type: "string",
      },
      {
        field: "value",
        headerName: "Value",
        flex: 1.5,
        valueType: "string",
        valueGetter: (params) =>
          `${params.row?.actualValue ?? ""} ${
            params.row?.notificationDefinition?.notification?.notificationScope?.unitSymbol ?? ""
          }`,
        type: "ReactElement",
      },
      {
        field: "asset",
        headerName: "Asset",
        flex: 2,
        renderCell: (params) => (
          <PLTextlink
            bold={false}
            size="small"
            uppercase={false}
            label={params.row.asset?.name ?? ""}
            onClick={() => history.push(`/assetManagement/assetDetails/${params.row?.asset.id}`)}
          />
        ),
        type: "string",
      },
      {
        field: "userEmail",
        headerName: "Receiver",
        flex: 2,
        valueGetter: (params) => {
          const receiverCount = params.row?.receiver?.length ?? -1;
          return `${params.row?.receiver?.[0] ?? ""} ${receiverCount > 1 ? ` + ${receiverCount}` : ""}`;
        },
        type: "string",
      },
    ],
    [refetch]
  );

  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage={translations.pages.alertManagement.noAlertsMessage}
          tableTitle={translations.pages.alertManagement.alerts}
          tableHeight="65vh"
          columns={columns}
          rows={tableLoading ? [] : recentAlerts}
          loading={tableLoading}
          checkboxSelection={false}
        />
      )}
    </>
  );
};

export default RecentNotifications;
