import { GridRowParams } from "@mui/x-data-grid-pro";
import { getUserById } from "components/pages/utils";
import useDataGrid from "components/templates/dataGridTable";
import { genericDeleteParams } from "components/templates/table-factory/Table/Cells/actionsCellFactory/recoilStates";
import {
  GetAllDynamicGroupsQuery,
  useGetAllDynamicGroupsLazyQuery,
} from "graphqlBase/Groups/__generated__/getAllDynamicGroups";
import { ResultType } from "graphqlBase/types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { formatDate } from "translations/formatter";

const { myDeviceGroups, noDataMessage } = translations.pages.groups.deviceGroup;
const { deleteGroup, deleteGroupMessage } = translations.pages.groups;

export type Group = ResultType<GetAllDynamicGroupsQuery["groups"]>;

export interface GroupWithUserName extends Group {
  userName?: string;
}

const { group, device } = translations.entities;

const DeviceGroups: React.FC<{}> = () => {
  const history = useHistory();
  const [deviceGroups, setDeviceGroups] = useState<GroupWithUserName[] | undefined>();
  const setDeleteParams = useSetRecoilState(genericDeleteParams);

  const variables = { where: { groupEntity: { entityName: { eq: "Device" } } } };
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

  const [query, { data, loading, error, refetch }] = useGetAllDynamicGroupsLazyQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });
  const [tableLoading, setTableLoading] = useState(loading || !deviceGroups);

  const { DataGrid, useMakeColumns, makeSeverSideFilter, getRowCount } = useDataGrid<GroupWithUserName, "Group">({
    query,
    variables,
    tableId: "DeviceGroups",
    persistance: "runTime",
  });

  useEffect(() => {
    if (data?.groups) {
      const groupsWithUser = (data.groups ?? []).reduce<GroupWithUserName[]>((groupsWithUser, currentGroup) => {
        const updatedGroup: GroupWithUserName = { ...currentGroup, userName: "" };

        if (currentGroup.userId) {
          getUserById(currentGroup.userId ?? "")
            .then((user) => {
              updatedGroup.userName = user?.firstName;
            })
            .catch((error) => {
              console.error("failed to get user name", error.message);
            });
        }

        return groupsWithUser.concat(updatedGroup);
      }, []);

      setDeviceGroups(groupsWithUser);
    }

    if (error) setTableLoading(false);
  }, [data, error]);

  useEffect(() => {
    if (deviceGroups && tableLoading) refetch?.().finally(() => setTableLoading(false));
  }, [deviceGroups, tableLoading]);

  const afterDelete = () => {
    if (refetch)
      refetch().catch(() => {
        return null;
      });
  };

  const deleteDeviceGroups = (ids: string[]) => {
    setDeleteParams({
      entity: "Groups",
      id: ids,
      afterDelete,
      modalTitle: deleteGroup,
      modalMessage: deleteGroupMessage,
    });
  };

  const columns = useMakeColumns(
    [
      {
        field: "groupName",
        headerName: group.columns.groupName,
        renderHeader: () => `${group.columns.groupName} (${getRowCount()})`,
        flex: 2,
        valueGetter: (params) => {
          return params.row.groupName ?? "";
        },
        remoteOrder: (sort) => ({ groupName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            groupName: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "description",
        headerName: group.columns.groupDescription,
        flex: 2,
        valueGetter: (params) => {
          return params.row.groupDescription ?? "";
        },
        remoteOrder: (sort) => ({ groupDescription: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            groupDescription: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "createdAt",
        headerName: group.columns.createdAt,
        flex: 1,
        valueGetter: (params) => {
          const isoDate = new Date(params.row.createdAt ?? new Date().toISOString());
          return `${formatDate(isoDate)}`;
        },
        remoteOrder: (sort) => ({ createdAt: sort }),
        remoteFilter: makeSeverSideFilter("dateTime", {
          filterPath: ({ where, filterValue }) => ({
            createdAt: filterValue,
          }),
        }),
        type: "dateTime",
      },
      {
        field: "createdBy",
        headerName: group.columns.createdBy,
        flex: 2,
        valueGetter: (params) => params.row.userName ?? "",
        type: "string",
      },
      //@TODO AK - confirm implementation of this
      // {
      //   field: "deviceType",
      //   headerName: device.columns.deviceModel,
      //   flex: 2,
      //   valueGetter: (params) => "Tracker Large",
      //   type: "string",
      // },
      //@TODO AK - group result count col
    ],
    []
  );

  const handleOnRowClick = (params: GridRowParams) => {
    //@TODO AK - implement device group view
    // history.push(`/deviceManagement/deviceGroupDetail/${params.row.id}`);
  };

  const handleEditDeviceGroup = (id: string) => {
    history.push(`/deviceManagement/updateDeviceGroup/${id}`);
  };
  const handleEditInfo = {
    handleEditRow: handleEditDeviceGroup,
    editScope: "UpdateDeviceGroupUI",
  };

  const handleDeleteInfo = {
    handleDeleteRows: deleteDeviceGroups,
    deleteScope: "DeleteDeviceGroupUI",
  };

  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage={noDataMessage}
          tableTitle={myDeviceGroups}
          tableHeight="65vh"
          handleOnRowClick={handleOnRowClick}
          columns={columns}
          rows={tableLoading ? [] : deviceGroups ?? []}
          setSelectedRows={(groups) => setSelectedGroups(groups as GroupWithUserName[])}
          selectedRows={selectedGroups.map((group) => group.id)}
          handleDelete={handleDeleteInfo}
          loading={tableLoading}
          handleEdit={handleEditInfo}
        />
      )}
    </>
  );
};

export default DeviceGroups;
