import { format } from "date-fns";
import translations from "translations";

export function getEnvMeasurementsOption({
  dates,
  humidityData,
  temperatureData,
}: {
  dates: Date[];
  humidityData: Array<[Date, number]>;
  temperatureData: Array<[Date, number]>;
}) {
  return {
    color: ["#4C79CF", "#CF5C4C"],
    legend: {
      left: 115,
      bottom: 10,
      textStyle: {
        color: "rgba(0, 0, 0, 0.88)",
        fontWeight: "400",
        fontSize: "12px",
        fontFamily: "var(--copytext-font-family)",
      },
      itemGap: 40,
      data: [
        {
          name: translations.entities.capability.capabilityNames.Humidity,
          icon: "circle",
        },
        { name: translations.entities.capability.capabilityNames.Temperature, icon: "circle" },
      ],
    },
    series: [
      {
        name: translations.entities.capability.capabilityNames.Humidity,
        type: "line",
        data: humidityData,
        showSymbol: false,
      },
      {
        name: translations.entities.capability.capabilityNames.Temperature,
        type: "line",
        yAxisIndex: 1,
        data: temperatureData,
        showSymbol: false,
      },
    ],
    tooltip: {
      formatter(el: any) {
        let humidityInfo;
        let temperatureInfo;
        if (el?.[0]?.data?.[1]) humidityInfo = `${el?.[0]?.seriesName ?? ""} &nbsp;&nbsp;<b>${el?.[0]?.data?.[1]}%</b>`;
        if (el?.[1]?.data?.[1])
          temperatureInfo = `${el?.[1]?.seriesName ?? ""}&nbsp;&nbsp; <b>${el?.[1]?.data?.[1]}°C</b>`;

        return `<div><b>${format(new Date(el[0].data[0]), "dd/M/yyyy, hh:mm")}</b><br /><br />
         ${
           humidityInfo
             ? `<div class="chart-tooltip-item-row"><div class="chart-humidity-legend-dot"></div><div class="chart-tooltip-title">${humidityInfo}</div></div>`
             : ""
         }
         ${
           temperatureInfo
             ? `<div class="chart-tooltip-item-row"><div class="chart-temp-legend-dot"></div><div class="chart-tooltip-title">${temperatureInfo}</div></div>`
             : ""
         }
        </div>`;
      },
      textStyle: { fontFamily: "var(--copytext-font-family)", fontSize: "10px", color: "rgba(0, 0, 0, 0.56)" },
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
    },
    xAxis: [
      {
        type: "time",
        axisTick: {
          show: false,
        },
        data: dates,
        axisLine: { show: false },
        axisLabel: {
          fontFamily: "var(--copytext-font-family)",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.56)",
          margin: 15,
          interval: 2,
          formatter: (value: any) => {
            return format(new Date(value), "d MMM y");
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: `${translations.entities.capability.capabilityNames.Humidity} (%)`,
        nameTextStyle: {
          fontFamily: "var(--copytext-font-family)",
          fontSize: "10px",
          align: "center",
          color: "rgba(0, 0, 0, 0.56)",
        },
        min: 0,
        max: 100,
        position: "left",
        axisLine: {
          show: false,
        },
        splitNumber: 4,
        interval: 25,
        axisLabel: {
          fontFamily: "var(--copytext-font-family)",
          fontSize: "12px",
          color: "rgba(0, 0, 0, 0.56)",
          margin: 30,
          align: "left",
        },
      },
      {
        type: "value",
        name: `${translations.entities.capability.capabilityNames.Temperature} (°C)`,
        nameTextStyle: {
          fontFamily: "var(--copytext-font-family)",
          fontSize: "10px",
          color: "rgba(0, 0, 0, 0.56)",
          align: "center",
        },
        min: 0,
        max: 80,
        position: "right",
        splitNumber: 4,
        axisLabel: {
          align: "right",
          fontFamily: "var(--copytext-font-family)",
          fontSize: "12px",
          color: "rgba(0, 0, 0, 0.56)",
          margin: 35,
        },
        axisLine: {
          show: false,
        },
      },
    ],
  };
}
