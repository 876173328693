import { useEffect, useState } from "react";
import { ability } from "./config/ability";
import client from "apollo/client";
import { parseScopes } from "./parseScopes";
import {
  GetIdentityServerUserScopesDocument,
  GetIdentityServerUserScopesQuery,
} from "graphqlBase/IdentityServerUserScopes/__generated__/getIdentityServerUserScopes";

const getScopes = async () => {
  const result = await client.query<GetIdentityServerUserScopesQuery>({
    query: GetIdentityServerUserScopesDocument,
  });
  return (result.data.identityServerUserScopes?.userScopes ?? []).reduce<string[]>((scopes, scope) => {
    if (!scope?.value) return scopes;
    return scopes.concat(scope?.value);
  }, []);
};

export const useAuthorization = () => {
  const [auth, setAuth] = useState<boolean>(false);
  useEffect(() => {
    getScopes()
      .then((scopesFetched) => {
        const parsedScopes = parseScopes(scopesFetched);
        ability.update([...parsedScopes]);
        setAuth(true);
      })
      .catch(console.error);
  }, []);
  return auth;
};
