import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMappingRoleUsersMutationVariables = Types.Exact<{
  mappingRoleUsers?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingRoleUserCreateType>> | Types.InputMaybe<Types.MappingRoleUserCreateType>>;
}>;


export type CreateMappingRoleUsersMutation = { createMappingRoleUsers?: Maybe<Array<Maybe<{ __typename?: 'MappingRoleUser', id: string, roleId: string, userId: string, isDeleted?: Maybe<boolean> }>>> };


export const CreateMappingRoleUsersDocument = gql`
    mutation createMappingRoleUsers($mappingRoleUsers: [MappingRoleUserCreateType]) {
  createMappingRoleUsers(mappingRoleUsers: $mappingRoleUsers) {
    id
    roleId
    userId
    isDeleted
  }
}
    `;
export type CreateMappingRoleUsersMutationFn = Apollo.MutationFunction<CreateMappingRoleUsersMutation, CreateMappingRoleUsersMutationVariables>;

/**
 * __useCreateMappingRoleUsersMutation__
 *
 * To run a mutation, you first call `useCreateMappingRoleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingRoleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingRoleUsersMutation, { data, loading, error }] = useCreateMappingRoleUsersMutation({
 *   variables: {
 *      mappingRoleUsers: // value for 'mappingRoleUsers'
 *   },
 * });
 */
export function useCreateMappingRoleUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMappingRoleUsersMutation, CreateMappingRoleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMappingRoleUsersMutation, CreateMappingRoleUsersMutationVariables>(CreateMappingRoleUsersDocument, options);
      }
export type CreateMappingRoleUsersMutationHookResult = ReturnType<typeof useCreateMappingRoleUsersMutation>;
export type CreateMappingRoleUsersMutationResult = Apollo.MutationResult<CreateMappingRoleUsersMutation>;
export type CreateMappingRoleUsersMutationOptions = Apollo.BaseMutationOptions<CreateMappingRoleUsersMutation, CreateMappingRoleUsersMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
