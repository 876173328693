const tableRowStyles = {
  "&.MuiTableRow-root": {
    backgroundColor: "var(--color-steel-50)",
    fontFamily: "LiebherrText-Bold,-apple-system,BlinkMacSystemFont,Arial,Helvetica,sans-serif",
  },
};

export const muiTableRowOverrides = {
  root: {
    ...tableRowStyles,
  },
};
