import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Table, TableBody, TableRow, Typography, useMediaQuery } from "@mui/material";
import {
  ItemColorIconBoxSC,
  ItemColorIconTableCellSC,
  ItemNameTableCellSC,
  ItemValueTableCellSC,
  TableAndButtonBoxSC,
  TableSC,
} from "./styles";
import { DoughnutValueType } from "./types";
import React, { useState } from "react";
import translations from "translations";
import PLTextlink from "components/patternLib/PLTextlink";
import { AssetStateHistorySidebar } from "./AssetStateDoughnut/AssetStateHistorySidebar";
export interface DoughnutListValuesProps {
  seriesData: Array<DoughnutValueType>;
  showHistoryButton?: boolean;
}

const DoughnutDataList = ({ seriesData, showHistoryButton = false }: DoughnutListValuesProps) => {
  const [showHistorySidebar, setShowHistorySidebar] = useState(false);

  return (
    <TableAndButtonBoxSC>
      <TableSC>
        <Table aria-label="simple table">
          <TableBody>
            {seriesData?.map(({ name, value, value2, valueUnit, value2Unit, itemStyle }, index) => (
              <TableRow key={`${index}-${name}`}>
                <ItemNameTableCellSC component="th" scope="row">
                  <Typography variant="copy">{name}</Typography>
                </ItemNameTableCellSC>
                <ItemColorIconTableCellSC align="left">
                  <ItemColorIconBoxSC color={itemStyle?.color}>
                    <FiberManualRecordIcon height="fit-content" />
                  </ItemColorIconBoxSC>
                </ItemColorIconTableCellSC>

                <ItemValueTableCellSC align="left">
                  <Typography variant="copy">{`${value} ${valueUnit}`}</Typography>
                </ItemValueTableCellSC>

                {value2 !== undefined && (
                  <ItemValueTableCellSC align="left">
                    <Typography variant="copy">{`${value2} ${value2Unit ?? ""}`}</Typography>
                  </ItemValueTableCellSC>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableSC>
      {showHistoryButton ? (
        <Box sx={{ margin: "20px 0 0 5px" }}>
          <PLTextlink
            onClick={() => setShowHistorySidebar(true)}
            label={translations.pages.assetManagement.assetDetailGraphs.assetState.stateHistory}
            uppercase={false}
            bold={false}
          />
        </Box>
      ) : null}
      <AssetStateHistorySidebar open={showHistorySidebar} onClose={() => setShowHistorySidebar(false)} />
    </TableAndButtonBoxSC>
  );
};

export default DoughnutDataList;
