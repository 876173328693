import translations, { getStringsSave } from "translations";
import L from "leaflet";
import "leaflet.markercluster";
import { Maybe, Scalars } from "graphqlBase/types";
import * as GeoSearch from "leaflet-geosearch";
import ic_pin from "icons/svg/ic_pin.svg";

export const PinIcon = L.icon({
  iconUrl: ic_pin,
  iconSize: [35, 45],
});

export const useSearchControl = ({ map, style }: { map: L.Map; style: "bar" | "button" }) => {
  const control = document.querySelector(".geosearch");
  if (control !== null) return;
  // @ts-ignore
  const search: GeoSearch.Control = new GeoSearch.GeoSearchControl({
    provider: new GeoSearch.OpenStreetMapProvider({
      params: {
        "accept-language": translations.getLanguage(),
      },
    }),
    showMarker: false,
    style,
    updateMap: true,
  });
  map.addControl(search);
  return () => map.removeControl(search);
};

export function filterEmptyCoords(coords: Maybe<Maybe<Maybe<[number, number]>[]>[]>): Array<Scalars["Position"]> {
  return (coords?.filter((coord) => coord !== null) as unknown) as Array<Scalars["Position"]>;
}
