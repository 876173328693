export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: string;
  DateTime: string;
  Position: [number,number];
  Geometry: any;
  Coordinates: any;
  Name: any;
};

export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assetAttribute?: Maybe<AssetAttribute>;
  assetAttributeDataType?: Maybe<AssetAttributeDataType>;
  assetAttributeDataTypes: Array<AssetAttributeDataType>;
  assetAttributes: Array<AssetAttribute>;
  assetAttributeUnit?: Maybe<AssetAttributeUnit>;
  assetAttributeUnits: Array<AssetAttributeUnit>;
  assetAttributeValue?: Maybe<AssetAttributeValue>;
  assetAttributeValues: Array<AssetAttributeValue>;
  assetDevice?: Maybe<AssetDevice>;
  assetDeviceByDeviceBatching?: Maybe<Array<Maybe<AssetDevice>>>;
  assetDevices: Array<AssetDevice>;
  assetImage?: Maybe<AssetImage>;
  assetImages: Array<AssetImage>;
  assetMasterData?: Maybe<AssetMasterData>;
  assetMasterDatas: Array<AssetMasterData>;
  assetMasterDataSource?: Maybe<AssetMasterDataSource>;
  assetMasterDataSources: Array<AssetMasterDataSource>;
  assetNote?: Maybe<AssetNote>;
  assetNotes: Array<AssetNote>;
  assets: Array<Asset>;
  assetSchema_query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  assetSource?: Maybe<AssetSource>;
  assetSources: Array<AssetSource>;
  assetTag?: Maybe<AssetTag>;
  assetTags: Array<AssetTag>;
  assetTypeAttributeSet?: Maybe<AssetTypeAttributeSet>;
  assetTypeAttributeSets: Array<AssetTypeAttributeSet>;
  capabilities: Array<Capability>;
  capability?: Maybe<Capability>;
  couplingStatusAttachment?: Maybe<CouplingStatus>;
  couplingStatusExcavator?: Maybe<CouplingStatus>;
  device?: Maybe<Device>;
  deviceAttribute?: Maybe<DeviceAttribute>;
  deviceAttributes: Array<DeviceAttribute>;
  deviceAttributeValue?: Maybe<DeviceAttributeValue>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  deviceBatching?: Maybe<Device>;
  deviceCapabilities: Array<DeviceCapability>;
  deviceCapability?: Maybe<DeviceCapability>;
  deviceConfiguration?: Maybe<DeviceConfiguration>;
  deviceConfigurations: Array<DeviceConfiguration>;
  deviceFirmware?: Maybe<DeviceFirmware>;
  deviceFirmwareConfigurationSchema?: Maybe<DeviceFirmwareConfigurationSchema>;
  deviceFirmwareConfigurationSchemas: Array<DeviceFirmwareConfigurationSchema>;
  deviceFirmwareReleaseType?: Maybe<DeviceFirmwareReleaseType>;
  deviceFirmwareReleaseTypes: Array<DeviceFirmwareReleaseType>;
  deviceFirmwares: Array<DeviceFirmware>;
  deviceGeofenceMapping?: Maybe<Array<Maybe<DeviceStorageArea>>>;
  deviceHeartbeatCurrentValue?: Maybe<DeviceHeartbeatCurrentValue>;
  deviceHeartbeatCurrentValues: Array<DeviceHeartbeatCurrentValue>;
  deviceHeartbeatHistories: Array<DeviceHeartbeatHistory>;
  deviceHeartbeatHistory?: Maybe<DeviceHeartbeatHistory>;
  deviceLocation?: Maybe<Array<Maybe<SensorMeasurementMovementHistory>>>;
  deviceLocationHistory?: Maybe<Array<Maybe<SensorMeasurementMovementHistory>>>;
  deviceLocationLHB?: Maybe<Array<Maybe<Lhb_Rm_Data>>>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModels: Array<DeviceModel>;
  deviceModelVersion?: Maybe<DeviceModelVersion>;
  deviceModelVersions: Array<DeviceModelVersion>;
  deviceMovementHistory?: Maybe<Array<Maybe<SensorMeasurementMovementHistory>>>;
  deviceNotification?: Maybe<DeviceNotification>;
  deviceNotificationByDevice: Array<DeviceNotification>;
  deviceNotifications: Array<DeviceNotification>;
  devices: Array<Device>;
  deviceSchema_query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  deviceStateHistory?: Maybe<Array<Maybe<DeviceStateHistory>>>;
  deviceType?: Maybe<DeviceType>;
  deviceTypes: Array<DeviceType>;
  gatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  gatewayDeviceConnections: Array<GatewayDeviceConnection>;
  generateToken?: Maybe<GenerateToken>;
  group?: Maybe<Group>;
  groupEntities: Array<GroupEntity>;
  groupEntity?: Maybe<GroupEntity>;
  groupEntityFilter?: Maybe<GroupEntityFilter>;
  groupEntityFilterDataType?: Maybe<GroupEntityFilterDataType>;
  groupEntityFilterDataTypes: Array<GroupEntityFilterDataType>;
  groupEntityFilters: Array<GroupEntityFilter>;
  groupEntityFilterUiType?: Maybe<GroupEntityFilterUiType>;
  groupEntityFilterUiTypes: Array<GroupEntityFilterUiType>;
  groupOperator?: Maybe<GroupOperator>;
  groupOperators: Array<GroupOperator>;
  groups: Array<Group>;
  identityServerUserInformation?: Maybe<UserInformationResult>;
  identityServerUserScopes?: Maybe<UserScopesResult>;
  locationAddresses?: Maybe<Array<Maybe<LocationAddress>>>;
  locationSchema_query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  mappingAssetTag?: Maybe<MappingAssetTag>;
  mappingAssetTags: Array<MappingAssetTag>;
  mappingAttributeAssetTypeAttributeSet?: Maybe<MappingAttributeAssetTypeAttributeSet>;
  mappingAttributeAssetTypeAttributeSets: Array<MappingAttributeAssetTypeAttributeSet>;
  mappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  mappingDeviceConfigurationDevice?: Maybe<MappingDeviceConfigurationDevice>;
  mappingDeviceConfigurationDevices: Array<MappingDeviceConfigurationDevice>;
  mappingDeviceConfigurationDeviceStatus?: Maybe<MappingDeviceConfigurationDeviceStatus>;
  mappingDeviceConfigurationDeviceStatuses: Array<MappingDeviceConfigurationDeviceStatus>;
  mappingDeviceFirmwareDevice?: Maybe<MappingDeviceFirmwareDevice>;
  mappingDeviceFirmwareDevices: Array<MappingDeviceFirmwareDevice>;
  mappingDeviceFirmwareDeviceStatus?: Maybe<MappingDeviceFirmwareDeviceStatus>;
  mappingDeviceFirmwareDeviceStatuses: Array<MappingDeviceFirmwareDeviceStatus>;
  mappingDeviceFirmwareDeviceType?: Maybe<MappingDeviceFirmwareDeviceType>;
  mappingDeviceFirmwareDeviceTypes: Array<MappingDeviceFirmwareDeviceType>;
  mappingDeviceNotification?: Maybe<MappingDeviceNotification>;
  mappingDeviceNotificationByDevice: Array<MappingDeviceNotification>;
  mappingDeviceNotifications: Array<MappingDeviceNotification>;
  mappingDeviceTag?: Maybe<MappingDeviceTag>;
  mappingDeviceTags: Array<MappingDeviceTag>;
  mappingGroupEntityFilterGroupOperator?: Maybe<MappingGroupEntityFilterGroupOperator>;
  mappingGroupEntityFilterGroupOperators: Array<MappingGroupEntityFilterGroupOperator>;
  mappingGroupEntityGroupEntityFilter?: Maybe<MappingGroupEntityGroupEntityFilter>;
  mappingGroupEntityGroupEntityFilters: Array<MappingGroupEntityGroupEntityFilter>;
  mappingNotificationScopeNotificationOperator?: Maybe<MappingNotificationScopeNotificationOperator>;
  mappingNotificationScopeNotificationOperators: Array<MappingNotificationScopeNotificationOperator>;
  mappingNotificationUser?: Maybe<MappingNotificationUser>;
  mappingNotificationUsers: Array<MappingNotificationUser>;
  mappingRoleScope?: Maybe<MappingRoleScope>;
  mappingRoleScopes: Array<MappingRoleScope>;
  mappingRoleUser?: Maybe<MappingRoleUser>;
  mappingRoleUsers: Array<MappingRoleUser>;
  mappingUserScope?: Maybe<MappingUserScope>;
  mappingUserScopes: Array<MappingUserScope>;
  notification?: Maybe<Notification>;
  notificationDefinition?: Maybe<NotificationDefinition>;
  notificationDefinitions: Array<NotificationDefinition>;
  notificationOperator?: Maybe<NotificationOperator>;
  notificationOperators: Array<NotificationOperator>;
  notifications: Array<Notification>;
  notificationSchema_query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  notificationScope?: Maybe<NotificationScope>;
  notificationScopes: Array<NotificationScope>;
  query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  queryDeviceWithinGeofence?: Maybe<GeofenceDevices>;
  readLogEntry?: Maybe<Array<Maybe<LogHistory>>>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  rule?: Maybe<Rule>;
  ruleAction?: Maybe<RuleAction>;
  ruleActions: Array<RuleAction>;
  ruleReference?: Maybe<RuleReference>;
  ruleReferences: Array<RuleReference>;
  ruleReferenceUnit?: Maybe<RuleReferenceUnit>;
  ruleReferenceUnits: Array<RuleReferenceUnit>;
  ruleResultType?: Maybe<RuleResultType>;
  ruleResultTypes: Array<RuleResultType>;
  rules: Array<Rule>;
  ruleSchema_query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
  scope?: Maybe<Scope>;
  scopes: Array<Scope>;
  sensorMeasurementCurrentValue?: Maybe<SensorMeasurementCurrentValue>;
  sensorMeasurementCurrentValueByDevice?: Maybe<Array<SensorMeasurementCurrentValue>>;
  sensorMeasurementCurrentValues: Array<SensorMeasurementCurrentValue>;
  sensorMeasurementHistories: Array<SensorMeasurementHistory>;
  sensorMeasurementHistory?: Maybe<SensorMeasurementHistory>;
  sensorMeasurementHistoryAggregate?: Maybe<SensorMeasurementHistoryAggregate>;
  sensorMeasurementHistoryByDevice?: Maybe<Array<SensorMeasurementHistory>>;
  storageArea?: Maybe<StorageArea>;
  storageAreas: Array<StorageArea>;
  storageAreaSingleNullable?: Maybe<StorageArea>;
  storageAreaType?: Maybe<StorageAreaType>;
  storageAreaTypes: Array<StorageAreaType>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  tenant?: Maybe<Tenant>;
  tenants: Array<Tenant>;
  translation?: Maybe<Translation>;
  translations: Array<Translation>;
  unit?: Maybe<Unit>;
  units: Array<Unit>;
  user?: Maybe<User>;
  userConfiguration?: Maybe<UserConfiguration>;
  userConfigurations: Array<UserConfiguration>;
  users: Array<User>;
  userSchema_query_statistics?: Maybe<Array<Maybe<QueryStatisticsOutputType>>>;
};


export type QueryAssetArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetAttributeArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetAttributeDataTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetAttributeDataTypesArgs = {
  order?: InputMaybe<Array<AssetAttributeDataTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeDataTypeFilterInput>;
};


export type QueryAssetAttributesArgs = {
  order?: InputMaybe<Array<AssetAttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeFilterInput>;
};


export type QueryAssetAttributeUnitArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetAttributeUnitsArgs = {
  order?: InputMaybe<Array<AssetAttributeUnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeUnitFilterInput>;
};


export type QueryAssetAttributeValueArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetAttributeValuesArgs = {
  order?: InputMaybe<Array<AssetAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeValueFilterInput>;
};


export type QueryAssetDeviceArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetDeviceByDeviceBatchingArgs = {
  deviceId: Scalars['UUID'];
  order?: InputMaybe<Array<AssetDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetDeviceFilterInput>;
};


export type QueryAssetDevicesArgs = {
  order?: InputMaybe<Array<AssetDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetDeviceFilterInput>;
};


export type QueryAssetImageArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetImagesArgs = {
  order?: InputMaybe<Array<AssetImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetImageFilterInput>;
};


export type QueryAssetMasterDataArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetMasterDatasArgs = {
  order?: InputMaybe<Array<AssetMasterDataSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetMasterDataFilterInput>;
};


export type QueryAssetMasterDataSourceArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetMasterDataSourcesArgs = {
  order?: InputMaybe<Array<AssetMasterDataSourceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetMasterDataSourceFilterInput>;
};


export type QueryAssetNoteArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetNotesArgs = {
  order?: InputMaybe<Array<AssetNoteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetNoteFilterInput>;
};


export type QueryAssetsArgs = {
  order?: InputMaybe<Array<AssetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilterInput>;
};


export type QueryAssetSourceArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetSourcesArgs = {
  order?: InputMaybe<Array<AssetSourceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetSourceFilterInput>;
};


export type QueryAssetTagArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetTagsArgs = {
  order?: InputMaybe<Array<AssetTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetTagFilterInput>;
};


export type QueryAssetTypeAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryAssetTypeAttributeSetsArgs = {
  order?: InputMaybe<Array<AssetTypeAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetTypeAttributeSetFilterInput>;
};


export type QueryCapabilitiesArgs = {
  order?: InputMaybe<Array<CapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CapabilityFilterInput>;
};


export type QueryCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryCouplingStatusAttachmentArgs = {
  deviceId: Scalars['UUID'];
};


export type QueryCouplingStatusExcavatorArgs = {
  deviceId: Scalars['UUID'];
};


export type QueryDeviceArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributesArgs = {
  order?: InputMaybe<Array<DeviceAttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeFilterInput>;
};


export type QueryDeviceAttributeValueArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type QueryDeviceBatchingArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceCapabilityFilterInput>;
};


export type QueryDeviceCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceConfigurationArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceConfigurationsArgs = {
  order?: InputMaybe<Array<DeviceConfigurationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceConfigurationFilterInput>;
};


export type QueryDeviceFirmwareArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceFirmwareConfigurationSchemaArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceFirmwareConfigurationSchemasArgs = {
  order?: InputMaybe<Array<DeviceFirmwareConfigurationSchemaSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFirmwareConfigurationSchemaFilterInput>;
};


export type QueryDeviceFirmwareReleaseTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceFirmwareReleaseTypesArgs = {
  order?: InputMaybe<Array<DeviceFirmwareReleaseTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFirmwareReleaseTypeFilterInput>;
};


export type QueryDeviceFirmwaresArgs = {
  order?: InputMaybe<Array<DeviceFirmwareSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFirmwareFilterInput>;
};


export type QueryDeviceGeofenceMappingArgs = {
  deviceLocations?: InputMaybe<Array<InputMaybe<DeviceLocationInput>>>;
};


export type QueryDeviceHeartbeatCurrentValueArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceHeartbeatCurrentValuesArgs = {
  order?: InputMaybe<Array<DeviceHeartbeatCurrentValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceHeartbeatCurrentValueFilterInput>;
};


export type QueryDeviceHeartbeatHistoriesArgs = {
  order?: InputMaybe<Array<DeviceHeartbeatHistorySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceHeartbeatHistoryFilterInput>;
};


export type QueryDeviceHeartbeatHistoryArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceLocationArgs = {
  deviceIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type QueryDeviceLocationHistoryArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  deviceIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type QueryDeviceLocationLhbArgs = {
  deviceIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type QueryDeviceModelArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type QueryDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type QueryDeviceModelCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelFilterInput>;
};


export type QueryDeviceModelVersionArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelVersionsArgs = {
  order?: InputMaybe<Array<DeviceModelVersionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelVersionFilterInput>;
};


export type QueryDeviceMovementHistoryArgs = {
  deviceId: Scalars['UUID'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryDeviceNotificationArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceNotificationByDeviceArgs = {
  deviceId: Scalars['UUID'];
  order?: InputMaybe<Array<DeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceNotificationFilterInput>;
};


export type QueryDeviceNotificationsArgs = {
  order?: InputMaybe<Array<DeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceNotificationFilterInput>;
};


export type QueryDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};


export type QueryDeviceStateHistoryArgs = {
  deviceIds?: InputMaybe<Array<Scalars['UUID']>>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceTypesArgs = {
  order?: InputMaybe<Array<DeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTypeFilterInput>;
};


export type QueryGatewayDeviceConnectionArgs = {
  id: Scalars['UUID'];
};


export type QueryGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


export type QueryGenerateTokenArgs = {
  jwtToken?: InputMaybe<Scalars['String']>;
};


export type QueryGroupArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupEntitiesArgs = {
  order?: InputMaybe<Array<GroupEntitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupEntityFilterInput>;
};


export type QueryGroupEntityArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupEntityFilterArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupEntityFilterDataTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupEntityFilterDataTypesArgs = {
  order?: InputMaybe<Array<GroupEntityFilterDataTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupEntityFilterDataTypeFilterInput>;
};


export type QueryGroupEntityFiltersArgs = {
  order?: InputMaybe<Array<GroupEntityFilterSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupEntityFilterFilterInput>;
};


export type QueryGroupEntityFilterUiTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupEntityFilterUiTypesArgs = {
  order?: InputMaybe<Array<GroupEntityFilterUiTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupEntityFilterUiTypeFilterInput>;
};


export type QueryGroupOperatorArgs = {
  id: Scalars['UUID'];
};


export type QueryGroupOperatorsArgs = {
  order?: InputMaybe<Array<GroupOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupOperatorFilterInput>;
};


export type QueryGroupsArgs = {
  order?: InputMaybe<Array<GroupSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupFilterInput>;
};


export type QueryIdentityServerUserInformationArgs = {
  bearerToken?: InputMaybe<Scalars['String']>;
};


export type QueryLocationAddressesArgs = {
  points?: InputMaybe<Array<InputMaybe<GeoJsonPointInput>>>;
};


export type QueryMappingAssetTagArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingAssetTagsArgs = {
  order?: InputMaybe<Array<MappingAssetTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAssetTagFilterInput>;
};


export type QueryMappingAttributeAssetTypeAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingAttributeAssetTypeAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeAssetTypeAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeAssetTypeAttributeSetFilterInput>;
};


export type QueryMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};


export type QueryMappingDeviceConfigurationDeviceArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceConfigurationDevicesArgs = {
  order?: InputMaybe<Array<MappingDeviceConfigurationDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceConfigurationDeviceFilterInput>;
};


export type QueryMappingDeviceConfigurationDeviceStatusArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceConfigurationDeviceStatusesArgs = {
  order?: InputMaybe<Array<MappingDeviceConfigurationDeviceStatusSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceConfigurationDeviceStatusFilterInput>;
};


export type QueryMappingDeviceFirmwareDeviceArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceFirmwareDevicesArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceFilterInput>;
};


export type QueryMappingDeviceFirmwareDeviceStatusArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceFirmwareDeviceStatusesArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceStatusSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceStatusFilterInput>;
};


export type QueryMappingDeviceFirmwareDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceFirmwareDeviceTypesArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceTypeFilterInput>;
};


export type QueryMappingDeviceNotificationArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceNotificationByDeviceArgs = {
  deviceId: Scalars['UUID'];
  order?: InputMaybe<Array<MappingDeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceNotificationFilterInput>;
};


export type QueryMappingDeviceNotificationsArgs = {
  order?: InputMaybe<Array<MappingDeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceNotificationFilterInput>;
};


export type QueryMappingDeviceTagArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceTagsArgs = {
  order?: InputMaybe<Array<MappingDeviceTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceTagFilterInput>;
};


export type QueryMappingGroupEntityFilterGroupOperatorArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingGroupEntityFilterGroupOperatorsArgs = {
  order?: InputMaybe<Array<MappingGroupEntityFilterGroupOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingGroupEntityFilterGroupOperatorFilterInput>;
};


export type QueryMappingGroupEntityGroupEntityFilterArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingGroupEntityGroupEntityFiltersArgs = {
  order?: InputMaybe<Array<MappingGroupEntityGroupEntityFilterSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingGroupEntityGroupEntityFilterFilterInput>;
};


export type QueryMappingNotificationScopeNotificationOperatorArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingNotificationScopeNotificationOperatorsArgs = {
  order?: InputMaybe<Array<MappingNotificationScopeNotificationOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingNotificationScopeNotificationOperatorFilterInput>;
};


export type QueryMappingNotificationUserArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingNotificationUsersArgs = {
  order?: InputMaybe<Array<MappingNotificationUserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingNotificationUserFilterInput>;
};


export type QueryMappingRoleScopeArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingRoleScopesArgs = {
  order?: InputMaybe<Array<MappingRoleScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingRoleScopeFilterInput>;
};


export type QueryMappingRoleUserArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingRoleUsersArgs = {
  order?: InputMaybe<Array<MappingRoleUserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingRoleUserFilterInput>;
};


export type QueryMappingUserScopeArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingUserScopesArgs = {
  order?: InputMaybe<Array<MappingUserScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingUserScopeFilterInput>;
};


export type QueryNotificationArgs = {
  id: Scalars['UUID'];
};


export type QueryNotificationDefinitionArgs = {
  id: Scalars['UUID'];
};


export type QueryNotificationDefinitionsArgs = {
  order?: InputMaybe<Array<NotificationDefinitionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationDefinitionFilterInput>;
};


export type QueryNotificationOperatorArgs = {
  id: Scalars['UUID'];
};


export type QueryNotificationOperatorsArgs = {
  order?: InputMaybe<Array<NotificationOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationOperatorFilterInput>;
};


export type QueryNotificationsArgs = {
  order?: InputMaybe<Array<NotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationFilterInput>;
};


export type QueryNotificationScopeArgs = {
  id: Scalars['UUID'];
};


export type QueryNotificationScopesArgs = {
  order?: InputMaybe<Array<NotificationScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationScopeFilterInput>;
};


export type QueryQueryDeviceWithinGeofenceArgs = {
  geofences?: InputMaybe<GeoJsonMultiPolygonInput>;
};


export type QueryReadLogEntryArgs = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};


export type QueryRoleArgs = {
  id: Scalars['UUID'];
};


export type QueryRolesArgs = {
  order?: InputMaybe<Array<RoleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleFilterInput>;
};


export type QueryRuleArgs = {
  id: Scalars['UUID'];
};


export type QueryRuleActionArgs = {
  id: Scalars['UUID'];
};


export type QueryRuleActionsArgs = {
  order?: InputMaybe<Array<RuleActionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleActionFilterInput>;
};


export type QueryRuleReferenceArgs = {
  id: Scalars['UUID'];
};


export type QueryRuleReferencesArgs = {
  order?: InputMaybe<Array<RuleReferenceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleReferenceFilterInput>;
};


export type QueryRuleReferenceUnitArgs = {
  id: Scalars['UUID'];
};


export type QueryRuleReferenceUnitsArgs = {
  order?: InputMaybe<Array<RuleReferenceUnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleReferenceUnitFilterInput>;
};


export type QueryRuleResultTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryRuleResultTypesArgs = {
  order?: InputMaybe<Array<RuleResultTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleResultTypeFilterInput>;
};


export type QueryRulesArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};


export type QueryScopeArgs = {
  id: Scalars['UUID'];
};


export type QueryScopesArgs = {
  order?: InputMaybe<Array<ScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScopeFilterInput>;
};


export type QuerySensorMeasurementCurrentValueArgs = {
  id: Scalars['UUID'];
};


export type QuerySensorMeasurementCurrentValueByDeviceArgs = {
  deviceId: Scalars['UUID'];
  order?: InputMaybe<Array<SensorMeasurementCurrentValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SensorMeasurementCurrentValueFilterInput>;
};


export type QuerySensorMeasurementCurrentValuesArgs = {
  order?: InputMaybe<Array<SensorMeasurementCurrentValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SensorMeasurementCurrentValueFilterInput>;
};


export type QuerySensorMeasurementHistoriesArgs = {
  order?: InputMaybe<Array<SensorMeasurementHistorySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SensorMeasurementHistoryFilterInput>;
};


export type QuerySensorMeasurementHistoryArgs = {
  id: Scalars['UUID'];
};


export type QuerySensorMeasurementHistoryAggregateArgs = {
  capabilityIds?: InputMaybe<Array<Scalars['UUID']>>;
  deviceIds?: InputMaybe<Array<Scalars['UUID']>>;
  dimensions?: InputMaybe<Array<Dimensions>>;
  endDate: Scalars['DateTime'];
  gatewayDeviceConnectionIds?: InputMaybe<Array<Scalars['UUID']>>;
  metrics?: InputMaybe<Array<Metrics>>;
  startDate: Scalars['DateTime'];
  timeBucketInMinutes: Scalars['Int'];
};


export type QuerySensorMeasurementHistoryByDeviceArgs = {
  deviceId: Scalars['UUID'];
  order?: InputMaybe<Array<SensorMeasurementHistorySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SensorMeasurementHistoryFilterInput>;
};


export type QueryStorageAreaArgs = {
  id: Scalars['UUID'];
};


export type QueryStorageAreasArgs = {
  order?: InputMaybe<Array<StorageAreaSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StorageAreaFilterInput>;
};


export type QueryStorageAreaSingleNullableArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryStorageAreaTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryStorageAreaTypesArgs = {
  order?: InputMaybe<Array<StorageAreaSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StorageAreaTypeFilterInput>;
};


export type QueryTagArgs = {
  id: Scalars['UUID'];
};


export type QueryTagsArgs = {
  order?: InputMaybe<Array<TagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TagFilterInput>;
};


export type QueryTenantArgs = {
  id: Scalars['UUID'];
};


export type QueryTenantsArgs = {
  order?: InputMaybe<Array<TenantSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TenantFilterInput>;
};


export type QueryTranslationArgs = {
  id: Scalars['UUID'];
};


export type QueryTranslationsArgs = {
  order?: InputMaybe<Array<TranslationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TranslationFilterInput>;
};


export type QueryUnitArgs = {
  id: Scalars['UUID'];
};


export type QueryUnitsArgs = {
  order?: InputMaybe<Array<UnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['UUID'];
};


export type QueryUserConfigurationArgs = {
  id: Scalars['UUID'];
};


export type QueryUserConfigurationsArgs = {
  order?: InputMaybe<Array<UserConfigurationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserConfigurationFilterInput>;
};


export type QueryUsersArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFilterInput>;
};

/** I am a generated type! */
export type Asset = {
  __typename?: 'Asset';
  assetAttributeValue: Array<AssetAttributeValue>;
  assetDevice: Array<AssetDevice>;
  assetImage: Array<AssetImage>;
  assetMasterData?: Maybe<AssetMasterData>;
  assetMasterDataId?: Maybe<Scalars['UUID']>;
  assetNote: Array<AssetNote>;
  /** @deprecated AssetOrigin is deprecated and replaced by AssetSourceId and the corresponding Entity. */
  assetOrigin?: Maybe<Scalars['String']>;
  assetSource?: Maybe<AssetSource>;
  assetSourceId?: Maybe<Scalars['UUID']>;
  assetTypeAttributeSet?: Maybe<AssetTypeAttributeSet>;
  assetTypeAttributeSetId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customUniqueIdentifier: Scalars['String'];
  id: Scalars['UUID'];
  inverseParentAsset: Array<Asset>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lMID?: Maybe<Scalars['String']>;
  mappingAssetTag: Array<MappingAssetTag>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  parentAsset?: Maybe<Asset>;
  parentAssetId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type AssetAssetAttributeValueArgs = {
  order?: InputMaybe<Array<AssetAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeValueFilterInput>;
};


/** I am a generated type! */
export type AssetAssetDeviceArgs = {
  order?: InputMaybe<Array<AssetDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetDeviceFilterInput>;
};


/** I am a generated type! */
export type AssetAssetImageArgs = {
  order?: InputMaybe<Array<AssetImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetImageFilterInput>;
};


/** I am a generated type! */
export type AssetAssetNoteArgs = {
  order?: InputMaybe<Array<AssetNoteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetNoteFilterInput>;
};


/** I am a generated type! */
export type AssetInverseParentAssetArgs = {
  order?: InputMaybe<Array<AssetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilterInput>;
};


/** I am a generated type! */
export type AssetMappingAssetTagArgs = {
  order?: InputMaybe<Array<MappingAssetTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAssetTagFilterInput>;
};

export type AssetAttributeValueSortInput = {
  asset?: InputMaybe<AssetSortInput>;
  assetAttribute?: InputMaybe<AssetAttributeSortInput>;
  assetAttributeId?: InputMaybe<SortEnumType>;
  assetAttributeValueId?: InputMaybe<SortEnumType>;
  assetId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
  valueBool?: InputMaybe<SortEnumType>;
  valueDatetime?: InputMaybe<SortEnumType>;
  valueFloat?: InputMaybe<SortEnumType>;
  valueInt?: InputMaybe<SortEnumType>;
};

export type AssetSortInput = {
  assetId?: InputMaybe<SortEnumType>;
  assetMasterData?: InputMaybe<AssetMasterDataSortInput>;
  assetMasterDataId?: InputMaybe<SortEnumType>;
  assetOrigin?: InputMaybe<SortEnumType>;
  assetSource?: InputMaybe<AssetSourceSortInput>;
  assetSourceId?: InputMaybe<SortEnumType>;
  assetTypeAttributeSet?: InputMaybe<AssetTypeAttributeSetSortInput>;
  assetTypeAttributeSetId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customUniqueIdentifier?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lMID?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  parentAsset?: InputMaybe<AssetSortInput>;
  parentAssetId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type AssetMasterDataSortInput = {
  articleNumber?: InputMaybe<SortEnumType>;
  assetMasterDataId?: InputMaybe<SortEnumType>;
  assetMasterDataSource?: InputMaybe<AssetMasterDataSourceSortInput>;
  assetMasterDataSourceId?: InputMaybe<SortEnumType>;
  factoryNumber?: InputMaybe<SortEnumType>;
  generalItem?: InputMaybe<SortEnumType>;
  identificationNumber?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  itemConstructionNumber?: InputMaybe<SortEnumType>;
  manufacturerDate?: InputMaybe<SortEnumType>;
  measurement?: InputMaybe<SortEnumType>;
  projectNumber?: InputMaybe<SortEnumType>;
  sellingUnitSerialNumber?: InputMaybe<SortEnumType>;
  serialNumber?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SortEnumType>;
  supplierNumber?: InputMaybe<SortEnumType>;
  weight?: InputMaybe<SortEnumType>;
};

export type AssetMasterDataSourceSortInput = {
  assetMasterDataSourceDescription?: InputMaybe<SortEnumType>;
  assetMasterDataSourceId?: InputMaybe<SortEnumType>;
  assetMasterDataSourceName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetSourceSortInput = {
  assetSourceDescription?: InputMaybe<SortEnumType>;
  assetSourceId?: InputMaybe<SortEnumType>;
  assetSourceName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetTypeAttributeSetSortInput = {
  assetTypeAttributeSetId?: InputMaybe<SortEnumType>;
  assetTypeAttributeSetName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetAttributeSortInput = {
  assetAttributeDataType?: InputMaybe<AssetAttributeDataTypeSortInput>;
  assetAttributeId?: InputMaybe<SortEnumType>;
  assetAttributeUnit?: InputMaybe<AssetAttributeUnitSortInput>;
  assetAttributeUnitId?: InputMaybe<SortEnumType>;
  attributeDataTypeId?: InputMaybe<SortEnumType>;
  attributeName?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  defaultValue?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  languageISOCode?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  required?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetAttributeDataTypeSortInput = {
  assetAttributeDataTypeId?: InputMaybe<SortEnumType>;
  assetAttributeDataTypeName?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetAttributeUnitSortInput = {
  assetAttributeUnitId?: InputMaybe<SortEnumType>;
  assetAttributeUnitName?: InputMaybe<SortEnumType>;
  assetAttributeUnitSymbol?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetAttributeValueFilterInput = {
  and?: InputMaybe<Array<AssetAttributeValueFilterInput>>;
  asset?: InputMaybe<AssetFilterInput>;
  assetAttribute?: InputMaybe<AssetAttributeFilterInput>;
  assetAttributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetAttributeValueId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AssetAttributeValueFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
  valueBool?: InputMaybe<BooleanOperationFilterInput>;
  valueDatetime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  valueFloat?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  valueInt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type AssetFilterInput = {
  and?: InputMaybe<Array<AssetFilterInput>>;
  assetAttributeValue?: InputMaybe<ListFilterInputTypeOfAssetAttributeValueFilterInput>;
  assetDevice?: InputMaybe<ListFilterInputTypeOfAssetDeviceFilterInput>;
  assetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetImage?: InputMaybe<ListFilterInputTypeOfAssetImageFilterInput>;
  assetMasterData?: InputMaybe<AssetMasterDataFilterInput>;
  assetMasterDataId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  assetNote?: InputMaybe<ListFilterInputTypeOfAssetNoteFilterInput>;
  assetOrigin?: InputMaybe<StringOperationFilterInput>;
  assetSource?: InputMaybe<AssetSourceFilterInput>;
  assetSourceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  assetTypeAttributeSet?: InputMaybe<AssetTypeAttributeSetFilterInput>;
  assetTypeAttributeSetId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  customUniqueIdentifier?: InputMaybe<StringOperationFilterInput>;
  inverseParentAsset?: InputMaybe<ListFilterInputTypeOfAssetFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lMID?: InputMaybe<StringOperationFilterInput>;
  mappingAssetTag?: InputMaybe<ListFilterInputTypeOfMappingAssetTagFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AssetFilterInput>>;
  parentAsset?: InputMaybe<AssetFilterInput>;
  parentAssetId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfAssetAttributeValueFilterInput = {
  all?: InputMaybe<AssetAttributeValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetAttributeValueFilterInput>;
  some?: InputMaybe<AssetAttributeValueFilterInput>;
};

export type ListFilterInputTypeOfAssetDeviceFilterInput = {
  all?: InputMaybe<AssetDeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetDeviceFilterInput>;
  some?: InputMaybe<AssetDeviceFilterInput>;
};

export type AssetDeviceFilterInput = {
  and?: InputMaybe<Array<AssetDeviceFilterInput>>;
  asset?: InputMaybe<AssetFilterInput>;
  assetDeviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  dateFrom?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  dateTo?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  device?: InputMaybe<AssetSchema_DeviceFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AssetDeviceFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<Scalars['UUID']>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<Scalars['UUID']>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  /** This Fiter is Case-Sensitive */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  /** This Fiter is Case-Sensitive */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type AssetSchema_DeviceFilterInput = {
  and?: InputMaybe<Array<AssetSchema_DeviceFilterInput>>;
  assetDevice?: InputMaybe<ListFilterInputTypeOfAssetDeviceFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<AssetSchema_DeviceFilterInput>>;
  parentDeviceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  sensorId?: InputMaybe<StringOperationFilterInput>;
  serialNo?: InputMaybe<StringOperationFilterInput>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAssetImageFilterInput = {
  all?: InputMaybe<AssetImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetImageFilterInput>;
  some?: InputMaybe<AssetImageFilterInput>;
};

export type AssetImageFilterInput = {
  and?: InputMaybe<Array<AssetImageFilterInput>>;
  asset?: InputMaybe<AssetFilterInput>;
  assetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetImageId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AssetImageFilterInput>>;
  pictureBlobStorageUrl?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  thumbnailBlobStorageUrl?: InputMaybe<StringOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AssetMasterDataFilterInput = {
  and?: InputMaybe<Array<AssetMasterDataFilterInput>>;
  articleNumber?: InputMaybe<StringOperationFilterInput>;
  asset?: InputMaybe<ListFilterInputTypeOfAssetFilterInput>;
  assetMasterDataId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetMasterDataSource?: InputMaybe<AssetMasterDataSourceFilterInput>;
  assetMasterDataSourceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  factoryNumber?: InputMaybe<StringOperationFilterInput>;
  generalItem?: InputMaybe<StringOperationFilterInput>;
  identificationNumber?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  itemConstructionNumber?: InputMaybe<StringOperationFilterInput>;
  manufacturerDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  measurement?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AssetMasterDataFilterInput>>;
  projectNumber?: InputMaybe<StringOperationFilterInput>;
  sellingUnitSerialNumber?: InputMaybe<StringOperationFilterInput>;
  serialNumber?: InputMaybe<StringOperationFilterInput>;
  supplier?: InputMaybe<StringOperationFilterInput>;
  supplierNumber?: InputMaybe<StringOperationFilterInput>;
  weight?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
};

export type ListFilterInputTypeOfAssetFilterInput = {
  all?: InputMaybe<AssetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetFilterInput>;
  some?: InputMaybe<AssetFilterInput>;
};

export type AssetMasterDataSourceFilterInput = {
  and?: InputMaybe<Array<AssetMasterDataSourceFilterInput>>;
  assetMasterData?: InputMaybe<ListFilterInputTypeOfAssetMasterDataFilterInput>;
  assetMasterDataSourceDescription?: InputMaybe<StringOperationFilterInput>;
  assetMasterDataSourceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetMasterDataSourceName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AssetMasterDataSourceFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfAssetMasterDataFilterInput = {
  all?: InputMaybe<AssetMasterDataFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetMasterDataFilterInput>;
  some?: InputMaybe<AssetMasterDataFilterInput>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ListFilterInputTypeOfAssetNoteFilterInput = {
  all?: InputMaybe<AssetNoteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetNoteFilterInput>;
  some?: InputMaybe<AssetNoteFilterInput>;
};

export type AssetNoteFilterInput = {
  and?: InputMaybe<Array<AssetNoteFilterInput>>;
  asset?: InputMaybe<AssetFilterInput>;
  assetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetNoteId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  noteContent?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AssetNoteFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AssetSourceFilterInput = {
  and?: InputMaybe<Array<AssetSourceFilterInput>>;
  asset?: InputMaybe<ListFilterInputTypeOfAssetFilterInput>;
  assetSourceDescription?: InputMaybe<StringOperationFilterInput>;
  assetSourceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetSourceName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AssetSourceFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AssetTypeAttributeSetFilterInput = {
  and?: InputMaybe<Array<AssetTypeAttributeSetFilterInput>>;
  asset?: InputMaybe<ListFilterInputTypeOfAssetFilterInput>;
  assetTypeAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetTypeAttributeSetName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeAssetTypeAttributeSet?: InputMaybe<ListFilterInputTypeOfMappingAttributeAssetTypeAttributeSetFilterInput>;
  or?: InputMaybe<Array<AssetTypeAttributeSetFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingAttributeAssetTypeAttributeSetFilterInput = {
  all?: InputMaybe<MappingAttributeAssetTypeAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingAttributeAssetTypeAttributeSetFilterInput>;
  some?: InputMaybe<MappingAttributeAssetTypeAttributeSetFilterInput>;
};

export type MappingAttributeAssetTypeAttributeSetFilterInput = {
  and?: InputMaybe<Array<MappingAttributeAssetTypeAttributeSetFilterInput>>;
  assetAttribute?: InputMaybe<AssetAttributeFilterInput>;
  assetAttributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetTypeAttributeSet?: InputMaybe<AssetTypeAttributeSetFilterInput>;
  assetTypeAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeAssetTypeAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingAttributeAssetTypeAttributeSetFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AssetAttributeFilterInput = {
  and?: InputMaybe<Array<AssetAttributeFilterInput>>;
  assetAttributeDataType?: InputMaybe<AssetAttributeDataTypeFilterInput>;
  assetAttributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetAttributeUnit?: InputMaybe<AssetAttributeUnitFilterInput>;
  assetAttributeUnitId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  assetAttributeValue?: InputMaybe<ListFilterInputTypeOfAssetAttributeValueFilterInput>;
  attributeDataTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  attributeName?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  defaultValue?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  languageISOCode?: InputMaybe<StringOperationFilterInput>;
  mappingAttributeAssetTypeAttributeSet?: InputMaybe<ListFilterInputTypeOfMappingAttributeAssetTypeAttributeSetFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AssetAttributeFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AssetAttributeDataTypeFilterInput = {
  and?: InputMaybe<Array<AssetAttributeDataTypeFilterInput>>;
  assetAttribute?: InputMaybe<ListFilterInputTypeOfAssetAttributeFilterInput>;
  assetAttributeDataTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetAttributeDataTypeName?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AssetAttributeDataTypeFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfAssetAttributeFilterInput = {
  all?: InputMaybe<AssetAttributeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AssetAttributeFilterInput>;
  some?: InputMaybe<AssetAttributeFilterInput>;
};

export type AssetAttributeUnitFilterInput = {
  and?: InputMaybe<Array<AssetAttributeUnitFilterInput>>;
  assetAttribute?: InputMaybe<ListFilterInputTypeOfAssetAttributeFilterInput>;
  assetAttributeUnitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetAttributeUnitName?: InputMaybe<StringOperationFilterInput>;
  assetAttributeUnitSymbol?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AssetAttributeUnitFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingAssetTagFilterInput = {
  all?: InputMaybe<MappingAssetTagFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingAssetTagFilterInput>;
  some?: InputMaybe<MappingAssetTagFilterInput>;
};

export type MappingAssetTagFilterInput = {
  and?: InputMaybe<Array<MappingAssetTagFilterInput>>;
  asset?: InputMaybe<AssetFilterInput>;
  assetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  assetTag?: InputMaybe<AssetTagFilterInput>;
  assetTagId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAssetTagId?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<MappingAssetTagFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type AssetTagFilterInput = {
  and?: InputMaybe<Array<AssetTagFilterInput>>;
  assetTagId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAssetTag?: InputMaybe<ListFilterInputTypeOfMappingAssetTagFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AssetTagFilterInput>>;
  tagValue?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

/** I am a generated type! */
export type AssetAttributeValue = {
  __typename?: 'AssetAttributeValue';
  asset: Asset;
  assetAttribute: AssetAttribute;
  assetAttributeId: Scalars['UUID'];
  assetId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDatetime?: Maybe<Scalars['DateTime']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueInt?: Maybe<Scalars['Int']>;
};

/** I am a generated type! */
export type AssetAttribute = {
  __typename?: 'AssetAttribute';
  assetAttributeDataType?: Maybe<AssetAttributeDataType>;
  assetAttributeUnit?: Maybe<AssetAttributeUnit>;
  assetAttributeUnitId?: Maybe<Scalars['UUID']>;
  assetAttributeValue: Array<AssetAttributeValue>;
  attributeDataTypeId: Scalars['UUID'];
  attributeName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultValue?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  languageISOCode: Scalars['String'];
  mappingAttributeAssetTypeAttributeSet: Array<MappingAttributeAssetTypeAttributeSet>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  required: Scalars['Boolean'];
};


/** I am a generated type! */
export type AssetAttributeAssetAttributeValueArgs = {
  order?: InputMaybe<Array<AssetAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeValueFilterInput>;
};


/** I am a generated type! */
export type AssetAttributeMappingAttributeAssetTypeAttributeSetArgs = {
  order?: InputMaybe<Array<MappingAttributeAssetTypeAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeAssetTypeAttributeSetFilterInput>;
};

/** I am a generated type! */
export type AssetAttributeDataType = {
  __typename?: 'AssetAttributeDataType';
  assetAttribute: Array<AssetAttribute>;
  assetAttributeDataTypeName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};


/** I am a generated type! */
export type AssetAttributeDataTypeAssetAttributeArgs = {
  order?: InputMaybe<Array<AssetAttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeFilterInput>;
};

/** I am a generated type! */
export type AssetAttributeUnit = {
  __typename?: 'AssetAttributeUnit';
  assetAttribute: Array<AssetAttribute>;
  assetAttributeUnitName: Scalars['String'];
  assetAttributeUnitSymbol?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type AssetAttributeUnitAssetAttributeArgs = {
  order?: InputMaybe<Array<AssetAttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetAttributeFilterInput>;
};

export type MappingAttributeAssetTypeAttributeSetSortInput = {
  assetAttribute?: InputMaybe<AssetAttributeSortInput>;
  assetAttributeId?: InputMaybe<SortEnumType>;
  assetTypeAttributeSet?: InputMaybe<AssetTypeAttributeSetSortInput>;
  assetTypeAttributeSetId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingAttributeAssetTypeAttributeSetId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingAttributeAssetTypeAttributeSet = {
  __typename?: 'MappingAttributeAssetTypeAttributeSet';
  assetAttribute: AssetAttribute;
  assetAttributeId: Scalars['UUID'];
  assetTypeAttributeSet: AssetTypeAttributeSet;
  assetTypeAttributeSetId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type AssetTypeAttributeSet = {
  __typename?: 'AssetTypeAttributeSet';
  asset: Array<Asset>;
  assetTypeAttributeSetName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingAttributeAssetTypeAttributeSet: Array<MappingAttributeAssetTypeAttributeSet>;
};


/** I am a generated type! */
export type AssetTypeAttributeSetAssetArgs = {
  order?: InputMaybe<Array<AssetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilterInput>;
};


/** I am a generated type! */
export type AssetTypeAttributeSetMappingAttributeAssetTypeAttributeSetArgs = {
  order?: InputMaybe<Array<MappingAttributeAssetTypeAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeAssetTypeAttributeSetFilterInput>;
};

export type AssetDeviceSortInput = {
  asset?: InputMaybe<AssetSortInput>;
  assetDeviceId?: InputMaybe<SortEnumType>;
  assetId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  dateFrom?: InputMaybe<SortEnumType>;
  dateTo?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  device?: InputMaybe<AssetSchema_DeviceSortInput>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetSchema_DeviceSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  parentDeviceId?: InputMaybe<SortEnumType>;
  sensorId?: InputMaybe<SortEnumType>;
  serialNo?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type AssetDevice = {
  __typename?: 'AssetDevice';
  asset: Asset;
  assetId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

/** I am a generated type! */
export type Device = {
  __typename?: 'Device';
  assetDevice?: Maybe<Array<Maybe<AssetDevice>>>;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValue: Array<DeviceAttributeValue>;
  deviceCapability?: Maybe<DeviceCapability>;
  deviceCapabilityId?: Maybe<Scalars['UUID']>;
  deviceName: Scalars['String'];
  deviceNotification?: Maybe<Array<Maybe<DeviceNotification>>>;
  deviceType: DeviceType;
  deviceTypeId: Scalars['UUID'];
  gatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  id: Scalars['UUID'];
  inverseParentDevice: Array<Device>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDevice: Array<MappingDeviceConfigurationDevice>;
  mappingDeviceFirmwareDevice: Array<MappingDeviceFirmwareDevice>;
  mappingDeviceNotification?: Maybe<Array<Maybe<MappingDeviceNotification>>>;
  mappingDeviceTag: Array<MappingDeviceTag>;
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  sensorId: Scalars['String'];
  sensorMeasurementCurrentValue?: Maybe<Array<Maybe<SensorMeasurementCurrentValue>>>;
  sensorMeasurementHistory?: Maybe<Array<Maybe<SensorMeasurementHistory>>>;
  serialNo: Scalars['String'];
};


/** I am a generated type! */
export type DeviceAssetDeviceArgs = {
  order?: InputMaybe<Array<AssetDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetDeviceFilterInput>;
};


/** I am a generated type! */
export type DeviceDeviceAttributeValueArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


/** I am a generated type! */
export type DeviceDeviceNotificationArgs = {
  order?: InputMaybe<Array<DeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceNotificationFilterInput>;
};


/** I am a generated type! */
export type DeviceInverseParentDeviceArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};


/** I am a generated type! */
export type DeviceMappingDeviceConfigurationDeviceArgs = {
  order?: InputMaybe<Array<MappingDeviceConfigurationDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceConfigurationDeviceFilterInput>;
};


/** I am a generated type! */
export type DeviceMappingDeviceFirmwareDeviceArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceFilterInput>;
};


/** I am a generated type! */
export type DeviceMappingDeviceNotificationArgs = {
  order?: InputMaybe<Array<MappingDeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceNotificationFilterInput>;
};


/** I am a generated type! */
export type DeviceMappingDeviceTagArgs = {
  order?: InputMaybe<Array<MappingDeviceTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceTagFilterInput>;
};


/** I am a generated type! */
export type DeviceSensorMeasurementCurrentValueArgs = {
  order?: InputMaybe<Array<SensorMeasurementCurrentValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SensorMeasurementCurrentValueFilterInput>;
};


/** I am a generated type! */
export type DeviceSensorMeasurementHistoryArgs = {
  order?: InputMaybe<Array<SensorMeasurementHistorySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SensorMeasurementHistoryFilterInput>;
};

export type DeviceAttributeValueSortInput = {
  device?: InputMaybe<DeviceSortInput>;
  deviceAttribute?: InputMaybe<DeviceAttributeSortInput>;
  deviceAttributeId?: InputMaybe<SortEnumType>;
  deviceAttributeValueId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type DeviceSortInput = {
  description?: InputMaybe<SortEnumType>;
  deviceCapability?: InputMaybe<DeviceCapabilitySortInput>;
  deviceCapabilityId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceName?: InputMaybe<SortEnumType>;
  deviceType?: InputMaybe<DeviceTypeSortInput>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  gatewayDeviceConnection?: InputMaybe<GatewayDeviceConnectionSortInput>;
  isDeleted?: InputMaybe<SortEnumType>;
  parentDevice?: InputMaybe<DeviceSortInput>;
  parentDeviceId?: InputMaybe<SortEnumType>;
  sensorId?: InputMaybe<SortEnumType>;
  serialNo?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceCapabilitySortInput = {
  capability?: InputMaybe<CapabilitySortInput>;
  capabilityId?: InputMaybe<SortEnumType>;
  deviceCapabilityId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  technicalMax?: InputMaybe<SortEnumType>;
  technicalMin?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<UnitSortInput>;
  unitId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type CapabilitySortInput = {
  capabilityDescription?: InputMaybe<SortEnumType>;
  capabilityId?: InputMaybe<SortEnumType>;
  capabilityName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type UnitSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitId?: InputMaybe<SortEnumType>;
  unitName?: InputMaybe<SortEnumType>;
  unitSymbol?: InputMaybe<SortEnumType>;
  uri?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceTypeSortInput = {
  deviceTypeId?: InputMaybe<SortEnumType>;
  deviceTypeName?: InputMaybe<SortEnumType>;
  fieldDeviceIdFieldSelector?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type GatewayDeviceConnectionSortInput = {
  device?: InputMaybe<DeviceSortInput>;
  deviceId?: InputMaybe<SortEnumType>;
  gatewayDeviceConnectionId?: InputMaybe<SortEnumType>;
  gatewayDeviceName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  primaryAuthenticationKey?: InputMaybe<SortEnumType>;
  primaryConnectionString?: InputMaybe<SortEnumType>;
  secondaryAuthenticationKey?: InputMaybe<SortEnumType>;
  secondaryConnectionString?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceAttributeSortInput = {
  attributeDataType?: InputMaybe<SortEnumType>;
  attributeName?: InputMaybe<SortEnumType>;
  deviceAttributeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  required?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceAttributeValueFilterInput = {
  and?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceAttribute?: InputMaybe<DeviceAttributeFilterInput>;
  deviceAttributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceAttributeValueId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type DeviceFilterInput = {
  and?: InputMaybe<Array<DeviceFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValue?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  deviceCapability?: InputMaybe<DeviceCapabilityFilterInput>;
  deviceCapabilityId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceName?: InputMaybe<StringOperationFilterInput>;
  deviceType?: InputMaybe<DeviceTypeFilterInput>;
  deviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  gatewayDeviceConnection?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  inverseParentDevice?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceConfigurationDevice?: InputMaybe<ListFilterInputTypeOfMappingDeviceConfigurationDeviceFilterInput>;
  mappingDeviceFirmwareDevice?: InputMaybe<ListFilterInputTypeOfMappingDeviceFirmwareDeviceFilterInput>;
  mappingDeviceTag?: InputMaybe<ListFilterInputTypeOfMappingDeviceTagFilterInput>;
  or?: InputMaybe<Array<DeviceFilterInput>>;
  parentDevice?: InputMaybe<DeviceFilterInput>;
  parentDeviceId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  sensorId?: InputMaybe<StringOperationFilterInput>;
  serialNo?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfDeviceAttributeValueFilterInput = {
  all?: InputMaybe<DeviceAttributeValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceAttributeValueFilterInput>;
  some?: InputMaybe<DeviceAttributeValueFilterInput>;
};

export type DeviceCapabilityFilterInput = {
  and?: InputMaybe<Array<DeviceCapabilityFilterInput>>;
  capability?: InputMaybe<CapabilityFilterInput>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  device?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  deviceCapabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceCapabilityFilterInput>>;
  technicalMax?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  technicalMin?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  unit?: InputMaybe<UnitFilterInput>;
  unitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type CapabilityFilterInput = {
  and?: InputMaybe<Array<CapabilityFilterInput>>;
  capabilityDescription?: InputMaybe<StringOperationFilterInput>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  capabilityName?: InputMaybe<StringOperationFilterInput>;
  deviceCapability?: InputMaybe<ListFilterInputTypeOfDeviceCapabilityFilterInput>;
  deviceModelCapability?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<CapabilityFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfDeviceCapabilityFilterInput = {
  all?: InputMaybe<DeviceCapabilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceCapabilityFilterInput>;
  some?: InputMaybe<DeviceCapabilityFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelCapabilityFilterInput = {
  all?: InputMaybe<DeviceModelCapabilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelCapabilityFilterInput>;
  some?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type DeviceModelCapabilityFilterInput = {
  and?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  capability?: InputMaybe<CapabilityFilterInput>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelCapabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelVersion?: InputMaybe<DeviceModelVersionFilterInput>;
  deviceModelVersionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  fieldSelector?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  technicalMax?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  technicalMin?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  unit?: InputMaybe<UnitFilterInput>;
  unitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceModelVersionFilterInput = {
  and?: InputMaybe<Array<DeviceModelVersionFilterInput>>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelCapability?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  deviceModelId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelVersionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelVersionFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceModelFilterInput = {
  and?: InputMaybe<Array<DeviceModelFilterInput>>;
  deviceModelAttributeSet?: InputMaybe<ListFilterInputTypeOfDeviceModelAttributeSetFilterInput>;
  deviceModelId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelName?: InputMaybe<StringOperationFilterInput>;
  deviceModelVersion?: InputMaybe<ListFilterInputTypeOfDeviceModelVersionFilterInput>;
  deviceType?: InputMaybe<DeviceTypeFilterInput>;
  deviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  oId?: InputMaybe<StringOperationFilterInput>;
  oIdFieldSelector?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  timestampSelector?: InputMaybe<StringOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};

export type DeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelAttributeSetName?: InputMaybe<StringOperationFilterInput>;
  deviceModelId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSet?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  or?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type MappingAttributeDeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  deviceAttribute?: InputMaybe<DeviceAttributeFilterInput>;
  deviceAttributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceAttributeFilterInput = {
  and?: InputMaybe<Array<DeviceAttributeFilterInput>>;
  attributeDataType?: InputMaybe<StringOperationFilterInput>;
  attributeName?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceAttributeValue?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSet?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  or?: InputMaybe<Array<DeviceAttributeFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelVersionFilterInput = {
  all?: InputMaybe<DeviceModelVersionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelVersionFilterInput>;
  some?: InputMaybe<DeviceModelVersionFilterInput>;
};

export type DeviceTypeFilterInput = {
  and?: InputMaybe<Array<DeviceTypeFilterInput>>;
  device?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  deviceModel?: InputMaybe<ListFilterInputTypeOfDeviceModelFilterInput>;
  deviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceTypeName?: InputMaybe<StringOperationFilterInput>;
  fieldDeviceIdFieldSelector?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceFirmwareDeviceType?: InputMaybe<ListFilterInputTypeOfMappingDeviceFirmwareDeviceTypeFilterInput>;
  or?: InputMaybe<Array<DeviceTypeFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfDeviceFilterInput = {
  all?: InputMaybe<DeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceFilterInput>;
  some?: InputMaybe<DeviceFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelFilterInput = {
  all?: InputMaybe<DeviceModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelFilterInput>;
  some?: InputMaybe<DeviceModelFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceFirmwareDeviceTypeFilterInput = {
  all?: InputMaybe<MappingDeviceFirmwareDeviceTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceFirmwareDeviceTypeFilterInput>;
  some?: InputMaybe<MappingDeviceFirmwareDeviceTypeFilterInput>;
};

export type MappingDeviceFirmwareDeviceTypeFilterInput = {
  and?: InputMaybe<Array<MappingDeviceFirmwareDeviceTypeFilterInput>>;
  deviceFirmware?: InputMaybe<DeviceFirmwareFilterInput>;
  deviceFirmwareId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceType?: InputMaybe<DeviceTypeFilterInput>;
  deviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceFirmwareDeviceTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceFirmwareDeviceTypeFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceFirmwareFilterInput = {
  and?: InputMaybe<Array<DeviceFirmwareFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceFirmwareConfigurationSchema?: InputMaybe<DeviceFirmwareConfigurationSchemaFilterInput>;
  deviceFirmwareConfigurationSchemaId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceFirmwareId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceFirmwareReleaseType?: InputMaybe<DeviceFirmwareReleaseTypeFilterInput>;
  deviceFirmwareReleaseTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  firmwareRevision?: InputMaybe<StringOperationFilterInput>;
  firmwareVersion?: InputMaybe<StringOperationFilterInput>;
  hardwareRevisionSupport?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceFirmwareDevice?: InputMaybe<ListFilterInputTypeOfMappingDeviceFirmwareDeviceFilterInput>;
  mappingDeviceFirmwareDeviceType?: InputMaybe<ListFilterInputTypeOfMappingDeviceFirmwareDeviceTypeFilterInput>;
  or?: InputMaybe<Array<DeviceFirmwareFilterInput>>;
  releaseDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  unlockRelease?: InputMaybe<BooleanOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceFirmwareConfigurationSchemaFilterInput = {
  and?: InputMaybe<Array<DeviceFirmwareConfigurationSchemaFilterInput>>;
  deviceFirmware?: InputMaybe<ListFilterInputTypeOfDeviceFirmwareFilterInput>;
  deviceFirmwareConfigurationJSON?: InputMaybe<StringOperationFilterInput>;
  deviceFirmwareConfigurationSchemaId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceFirmwareConfigurationSchemaFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfDeviceFirmwareFilterInput = {
  all?: InputMaybe<DeviceFirmwareFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceFirmwareFilterInput>;
  some?: InputMaybe<DeviceFirmwareFilterInput>;
};

export type DeviceFirmwareReleaseTypeFilterInput = {
  and?: InputMaybe<Array<DeviceFirmwareReleaseTypeFilterInput>>;
  deviceFirmware?: InputMaybe<ListFilterInputTypeOfDeviceFirmwareFilterInput>;
  deviceFirmwareReleaseTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceFirmwareReleaseTypeName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceFirmwareReleaseTypeFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceFirmwareDeviceFilterInput = {
  all?: InputMaybe<MappingDeviceFirmwareDeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceFirmwareDeviceFilterInput>;
  some?: InputMaybe<MappingDeviceFirmwareDeviceFilterInput>;
};

export type MappingDeviceFirmwareDeviceFilterInput = {
  and?: InputMaybe<Array<MappingDeviceFirmwareDeviceFilterInput>>;
  dateFrom?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  dateTo?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceFirmware?: InputMaybe<DeviceFirmwareFilterInput>;
  deviceFirmwareId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceFirmwareDeviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  mappingDeviceFirmwareDeviceStatus?: InputMaybe<MappingDeviceFirmwareDeviceStatusFilterInput>;
  mappingDeviceFirmwareDeviceStatusId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceFirmwareDeviceFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type MappingDeviceFirmwareDeviceStatusFilterInput = {
  and?: InputMaybe<Array<MappingDeviceFirmwareDeviceStatusFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceFirmwareDevice?: InputMaybe<ListFilterInputTypeOfMappingDeviceFirmwareDeviceFilterInput>;
  mappingDeviceFirmwareDeviceStatusId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceFirmwareDeviceStatusFilterInput>>;
  statusName?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type UnitFilterInput = {
  and?: InputMaybe<Array<UnitFilterInput>>;
  deviceCapability?: InputMaybe<ListFilterInputTypeOfDeviceCapabilityFilterInput>;
  deviceModelCapability?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<UnitFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  unitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  unitName?: InputMaybe<StringOperationFilterInput>;
  unitSymbol?: InputMaybe<StringOperationFilterInput>;
  uri?: InputMaybe<StringOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type GatewayDeviceConnectionFilterInput = {
  and?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  gatewayDeviceConnectionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  gatewayDeviceName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  primaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  primaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  secondaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  secondaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceConfigurationDeviceFilterInput = {
  all?: InputMaybe<MappingDeviceConfigurationDeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceConfigurationDeviceFilterInput>;
  some?: InputMaybe<MappingDeviceConfigurationDeviceFilterInput>;
};

export type MappingDeviceConfigurationDeviceFilterInput = {
  and?: InputMaybe<Array<MappingDeviceConfigurationDeviceFilterInput>>;
  dateFrom?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  dateTo?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceConfiguration?: InputMaybe<DeviceConfigurationFilterInput>;
  deviceConfigurationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceConfigurationDeviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  mappingDeviceConfigurationDeviceStatus?: InputMaybe<MappingDeviceConfigurationDeviceStatusFilterInput>;
  mappingDeviceConfigurationDeviceStatusId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceConfigurationDeviceFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type DeviceConfigurationFilterInput = {
  and?: InputMaybe<Array<DeviceConfigurationFilterInput>>;
  configurationJSON?: InputMaybe<StringOperationFilterInput>;
  deviceConfigurationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceConfigurationDevice?: InputMaybe<ListFilterInputTypeOfMappingDeviceConfigurationDeviceFilterInput>;
  or?: InputMaybe<Array<DeviceConfigurationFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type MappingDeviceConfigurationDeviceStatusFilterInput = {
  and?: InputMaybe<Array<MappingDeviceConfigurationDeviceStatusFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceConfigurationDevice?: InputMaybe<ListFilterInputTypeOfMappingDeviceConfigurationDeviceFilterInput>;
  mappingDeviceConfigurationDeviceStatusId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceConfigurationDeviceStatusFilterInput>>;
  statusName?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceTagFilterInput = {
  all?: InputMaybe<MappingDeviceTagFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceTagFilterInput>;
  some?: InputMaybe<MappingDeviceTagFilterInput>;
};

export type MappingDeviceTagFilterInput = {
  and?: InputMaybe<Array<MappingDeviceTagFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceTagId?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceTagFilterInput>>;
  tag?: InputMaybe<TagFilterInput>;
  tagId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type TagFilterInput = {
  and?: InputMaybe<Array<TagFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceTag?: InputMaybe<ListFilterInputTypeOfMappingDeviceTagFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TagFilterInput>>;
  tagId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tagValue?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

/** I am a generated type! */
export type DeviceAttributeValue = {
  __typename?: 'DeviceAttributeValue';
  device: Device;
  deviceAttribute: DeviceAttribute;
  deviceAttributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

/** I am a generated type! */
export type DeviceAttribute = {
  __typename?: 'DeviceAttribute';
  attributeDataType: Scalars['String'];
  attributeName: Scalars['String'];
  deviceAttributeValue: Array<DeviceAttributeValue>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSet: Array<MappingAttributeDeviceModelAttributeSet>;
  required: Scalars['Boolean'];
};


/** I am a generated type! */
export type DeviceAttributeDeviceAttributeValueArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


/** I am a generated type! */
export type DeviceAttributeMappingAttributeDeviceModelAttributeSetArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type MappingAttributeDeviceModelAttributeSetSortInput = {
  deviceAttribute?: InputMaybe<DeviceAttributeSortInput>;
  deviceAttributeId?: InputMaybe<SortEnumType>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceModelAttributeSetSortInput = {
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  deviceModelAttributeSetName?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceModelSortInput = {
  deviceModelId?: InputMaybe<SortEnumType>;
  deviceModelName?: InputMaybe<SortEnumType>;
  deviceType?: InputMaybe<DeviceTypeSortInput>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  oId?: InputMaybe<SortEnumType>;
  oIdFieldSelector?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  timestampSelector?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingAttributeDeviceModelAttributeSet = {
  __typename?: 'MappingAttributeDeviceModelAttributeSet';
  deviceAttribute: DeviceAttribute;
  deviceAttributeId: Scalars['UUID'];
  deviceModelAttributeSet: DeviceModelAttributeSet;
  deviceModelAttributeSetId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type DeviceModelAttributeSet = {
  __typename?: 'DeviceModelAttributeSet';
  deviceModel: DeviceModel;
  deviceModelAttributeSetName: Scalars['String'];
  deviceModelId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSet: Array<MappingAttributeDeviceModelAttributeSet>;
};


/** I am a generated type! */
export type DeviceModelAttributeSetMappingAttributeDeviceModelAttributeSetArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

/** I am a generated type! */
export type DeviceModel = {
  __typename?: 'DeviceModel';
  deviceModelAttributeSet: Array<DeviceModelAttributeSet>;
  deviceModelName: Scalars['String'];
  deviceModelVersion: Array<DeviceModelVersion>;
  deviceType: DeviceType;
  deviceTypeId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  oId?: Maybe<Scalars['String']>;
  oIdFieldSelector?: Maybe<Scalars['String']>;
  timestampSelector?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type DeviceModelDeviceModelAttributeSetArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


/** I am a generated type! */
export type DeviceModelDeviceModelVersionArgs = {
  order?: InputMaybe<Array<DeviceModelVersionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelVersionFilterInput>;
};

export type DeviceModelVersionSortInput = {
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelId?: InputMaybe<SortEnumType>;
  deviceModelVersionId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type DeviceModelVersion = {
  __typename?: 'DeviceModelVersion';
  deviceModel: DeviceModel;
  deviceModelCapability: Array<DeviceModelCapability>;
  deviceModelId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type DeviceModelVersionDeviceModelCapabilityArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type DeviceModelCapabilitySortInput = {
  capability?: InputMaybe<CapabilitySortInput>;
  capabilityId?: InputMaybe<SortEnumType>;
  deviceModelCapabilityId?: InputMaybe<SortEnumType>;
  deviceModelVersion?: InputMaybe<DeviceModelVersionSortInput>;
  deviceModelVersionId?: InputMaybe<SortEnumType>;
  fieldSelector?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  technicalMax?: InputMaybe<SortEnumType>;
  technicalMin?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<UnitSortInput>;
  unitId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type DeviceModelCapability = {
  __typename?: 'DeviceModelCapability';
  capability: Capability;
  capabilityId: Scalars['UUID'];
  deviceModelVersion: DeviceModelVersion;
  deviceModelVersionId: Scalars['UUID'];
  fieldSelector: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  technicalMax?: Maybe<Scalars['Float']>;
  technicalMin?: Maybe<Scalars['Float']>;
  unit: Unit;
  unitId: Scalars['UUID'];
};

/** I am a generated type! */
export type Capability = {
  __typename?: 'Capability';
  capabilityDescription?: Maybe<Scalars['String']>;
  capabilityName: Scalars['String'];
  deviceCapability: Array<DeviceCapability>;
  deviceModelCapability: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type CapabilityDeviceCapabilityArgs = {
  order?: InputMaybe<Array<DeviceCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceCapabilityFilterInput>;
};


/** I am a generated type! */
export type CapabilityDeviceModelCapabilityArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

/** I am a generated type! */
export type DeviceCapability = {
  __typename?: 'DeviceCapability';
  capability: Capability;
  capabilityId: Scalars['UUID'];
  device: Array<Device>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  technicalMax?: Maybe<Scalars['Float']>;
  technicalMin?: Maybe<Scalars['Float']>;
  unit: Unit;
  unitId: Scalars['UUID'];
};


/** I am a generated type! */
export type DeviceCapabilityDeviceArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};

/** I am a generated type! */
export type Unit = {
  __typename?: 'Unit';
  deviceCapability: Array<DeviceCapability>;
  deviceModelCapability: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  unitName: Scalars['String'];
  unitSymbol: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type UnitDeviceCapabilityArgs = {
  order?: InputMaybe<Array<DeviceCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceCapabilityFilterInput>;
};


/** I am a generated type! */
export type UnitDeviceModelCapabilityArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

/** I am a generated type! */
export type DeviceType = {
  __typename?: 'DeviceType';
  device: Array<Device>;
  deviceModel: Array<DeviceModel>;
  deviceTypeName: Scalars['String'];
  fieldDeviceIdFieldSelector?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDeviceType: Array<MappingDeviceFirmwareDeviceType>;
};


/** I am a generated type! */
export type DeviceTypeDeviceArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};


/** I am a generated type! */
export type DeviceTypeDeviceModelArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelFilterInput>;
};


/** I am a generated type! */
export type DeviceTypeMappingDeviceFirmwareDeviceTypeArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceTypeFilterInput>;
};

export type MappingDeviceFirmwareDeviceTypeSortInput = {
  deviceFirmware?: InputMaybe<DeviceFirmwareSortInput>;
  deviceFirmwareId?: InputMaybe<SortEnumType>;
  deviceType?: InputMaybe<DeviceTypeSortInput>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceFirmwareDeviceTypeId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceFirmwareSortInput = {
  description?: InputMaybe<SortEnumType>;
  deviceFirmwareConfigurationSchema?: InputMaybe<DeviceFirmwareConfigurationSchemaSortInput>;
  deviceFirmwareConfigurationSchemaId?: InputMaybe<SortEnumType>;
  deviceFirmwareId?: InputMaybe<SortEnumType>;
  deviceFirmwareReleaseType?: InputMaybe<DeviceFirmwareReleaseTypeSortInput>;
  deviceFirmwareReleaseTypeId?: InputMaybe<SortEnumType>;
  firmwareRevision?: InputMaybe<SortEnumType>;
  firmwareVersion?: InputMaybe<SortEnumType>;
  hardwareRevisionSupport?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  releaseDate?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unlockRelease?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceFirmwareConfigurationSchemaSortInput = {
  deviceFirmwareConfigurationJSON?: InputMaybe<SortEnumType>;
  deviceFirmwareConfigurationSchemaId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceFirmwareReleaseTypeSortInput = {
  deviceFirmwareReleaseTypeId?: InputMaybe<SortEnumType>;
  deviceFirmwareReleaseTypeName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingDeviceFirmwareDeviceType = {
  __typename?: 'MappingDeviceFirmwareDeviceType';
  deviceFirmware: DeviceFirmware;
  deviceFirmwareId: Scalars['UUID'];
  deviceType: DeviceType;
  deviceTypeId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type DeviceFirmware = {
  __typename?: 'DeviceFirmware';
  description?: Maybe<Scalars['String']>;
  deviceFirmwareConfigurationSchema: DeviceFirmwareConfigurationSchema;
  deviceFirmwareConfigurationSchemaId: Scalars['UUID'];
  deviceFirmwareReleaseType: DeviceFirmwareReleaseType;
  deviceFirmwareReleaseTypeId: Scalars['UUID'];
  firmwareRevision: Scalars['String'];
  firmwareVersion: Scalars['String'];
  hardwareRevisionSupport: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDevice: Array<MappingDeviceFirmwareDevice>;
  mappingDeviceFirmwareDeviceType: Array<MappingDeviceFirmwareDeviceType>;
  releaseDate: Scalars['DateTime'];
  unlockRelease?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type DeviceFirmwareMappingDeviceFirmwareDeviceArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceFilterInput>;
};


/** I am a generated type! */
export type DeviceFirmwareMappingDeviceFirmwareDeviceTypeArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceTypeFilterInput>;
};

/** I am a generated type! */
export type DeviceFirmwareConfigurationSchema = {
  __typename?: 'DeviceFirmwareConfigurationSchema';
  deviceFirmware: Array<DeviceFirmware>;
  deviceFirmwareConfigurationJSON: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type DeviceFirmwareConfigurationSchemaDeviceFirmwareArgs = {
  order?: InputMaybe<Array<DeviceFirmwareSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFirmwareFilterInput>;
};

/** I am a generated type! */
export type DeviceFirmwareReleaseType = {
  __typename?: 'DeviceFirmwareReleaseType';
  deviceFirmware: Array<DeviceFirmware>;
  deviceFirmwareReleaseTypeName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type DeviceFirmwareReleaseTypeDeviceFirmwareArgs = {
  order?: InputMaybe<Array<DeviceFirmwareSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFirmwareFilterInput>;
};

export type MappingDeviceFirmwareDeviceSortInput = {
  dateFrom?: InputMaybe<SortEnumType>;
  dateTo?: InputMaybe<SortEnumType>;
  device?: InputMaybe<DeviceSortInput>;
  deviceFirmware?: InputMaybe<DeviceFirmwareSortInput>;
  deviceFirmwareId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceFirmwareDeviceId?: InputMaybe<SortEnumType>;
  mappingDeviceFirmwareDeviceStatus?: InputMaybe<MappingDeviceFirmwareDeviceStatusSortInput>;
  mappingDeviceFirmwareDeviceStatusId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceFirmwareDeviceStatusSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceFirmwareDeviceStatusId?: InputMaybe<SortEnumType>;
  statusName?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingDeviceFirmwareDevice = {
  __typename?: 'MappingDeviceFirmwareDevice';
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  device: Device;
  deviceFirmware: DeviceFirmware;
  deviceFirmwareId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDeviceStatus: MappingDeviceFirmwareDeviceStatus;
  mappingDeviceFirmwareDeviceStatusId: Scalars['UUID'];
  userId?: Maybe<Scalars['UUID']>;
};

/** I am a generated type! */
export type MappingDeviceFirmwareDeviceStatus = {
  __typename?: 'MappingDeviceFirmwareDeviceStatus';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDevice: Array<MappingDeviceFirmwareDevice>;
  statusName: Scalars['String'];
};


/** I am a generated type! */
export type MappingDeviceFirmwareDeviceStatusMappingDeviceFirmwareDeviceArgs = {
  order?: InputMaybe<Array<MappingDeviceFirmwareDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceFirmwareDeviceFilterInput>;
};

export type DeviceNotificationSortInput = {
  actualValue?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceNotificationId?: InputMaybe<SortEnumType>;
  eventOccurredAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  notificationDefinition?: InputMaybe<NotificationDefinitionSortInput>;
  notificationDefinitionId?: InputMaybe<SortEnumType>;
  notificationRead?: InputMaybe<SortEnumType>;
  notificationSend?: InputMaybe<SortEnumType>;
};

export type NotificationDefinitionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isRemoved?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  notification?: InputMaybe<NotificationSortInput>;
  notificationDefinitionId?: InputMaybe<SortEnumType>;
  notificationId?: InputMaybe<SortEnumType>;
  notificationOperator?: InputMaybe<NotificationOperatorSortInput>;
  notificationOperatorId?: InputMaybe<SortEnumType>;
  storageAreaId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type NotificationSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isRemoved?: InputMaybe<SortEnumType>;
  liMailPersonGroupId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  notificationId?: InputMaybe<SortEnumType>;
  notificationScope?: InputMaybe<NotificationScopeSortInput>;
  notificationScopeId?: InputMaybe<SortEnumType>;
  notificationText?: InputMaybe<SortEnumType>;
  recipientEmail?: InputMaybe<SortEnumType>;
  resubmissionInterval?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type NotificationScopeSortInput = {
  capabilityId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  notificationScopeId?: InputMaybe<SortEnumType>;
  scopeName?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitName?: InputMaybe<SortEnumType>;
  unitSymbol?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type NotificationOperatorSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  notificationOperatorId?: InputMaybe<SortEnumType>;
  operator?: InputMaybe<SortEnumType>;
  operatorDescription?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceNotificationFilterInput = {
  actualValue?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<DeviceNotificationFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceNotificationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  eventOccurredAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  notificationDefinition?: InputMaybe<NotificationDefinitionFilterInput>;
  notificationDefinitionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  notificationRead?: InputMaybe<BooleanOperationFilterInput>;
  notificationSend?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DeviceNotificationFilterInput>>;
};

export type NotificationDefinitionFilterInput = {
  and?: InputMaybe<Array<NotificationDefinitionFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deviceNotification?: InputMaybe<ListFilterInputTypeOfDeviceNotificationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isRemoved?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  notification?: InputMaybe<NotificationFilterInput>;
  notificationDefinitionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  notificationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  notificationOperator?: InputMaybe<NotificationOperatorFilterInput>;
  notificationOperatorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<NotificationDefinitionFilterInput>>;
  storageAreaId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type ListFilterInputTypeOfDeviceNotificationFilterInput = {
  all?: InputMaybe<DeviceNotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceNotificationFilterInput>;
  some?: InputMaybe<DeviceNotificationFilterInput>;
};

export type NotificationFilterInput = {
  and?: InputMaybe<Array<NotificationFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  createdBy?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isRemoved?: InputMaybe<BooleanOperationFilterInput>;
  liMailPersonGroupId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  mappingDeviceNotification?: InputMaybe<ListFilterInputTypeOfMappingDeviceNotificationFilterInput>;
  mappingNotificationUser?: InputMaybe<ListFilterInputTypeOfMappingNotificationUserFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  notificationDefinition?: InputMaybe<ListFilterInputTypeOfNotificationDefinitionFilterInput>;
  notificationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  notificationScope?: InputMaybe<NotificationScopeFilterInput>;
  notificationScopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  notificationText?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NotificationFilterInput>>;
  recipientEmail?: InputMaybe<StringOperationFilterInput>;
  resubmissionInterval?: InputMaybe<ComparableInt32OperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceNotificationFilterInput = {
  all?: InputMaybe<MappingDeviceNotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceNotificationFilterInput>;
  some?: InputMaybe<MappingDeviceNotificationFilterInput>;
};

export type MappingDeviceNotificationFilterInput = {
  and?: InputMaybe<Array<MappingDeviceNotificationFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceNotificationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  notification?: InputMaybe<NotificationFilterInput>;
  notificationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceNotificationFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingNotificationUserFilterInput = {
  all?: InputMaybe<MappingNotificationUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingNotificationUserFilterInput>;
  some?: InputMaybe<MappingNotificationUserFilterInput>;
};

export type MappingNotificationUserFilterInput = {
  and?: InputMaybe<Array<MappingNotificationUserFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingNotificationUserId?: InputMaybe<ComparableGuidOperationFilterInput>;
  notification?: InputMaybe<NotificationFilterInput>;
  notificationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingNotificationUserFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfNotificationDefinitionFilterInput = {
  all?: InputMaybe<NotificationDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NotificationDefinitionFilterInput>;
  some?: InputMaybe<NotificationDefinitionFilterInput>;
};

export type NotificationScopeFilterInput = {
  and?: InputMaybe<Array<NotificationScopeFilterInput>>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  dataType?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingNotificationScopeNotificationOperator?: InputMaybe<ListFilterInputTypeOfMappingNotificationScopeNotificationOperatorFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  notification?: InputMaybe<ListFilterInputTypeOfNotificationFilterInput>;
  notificationScopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<NotificationScopeFilterInput>>;
  scopeName?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  unitName?: InputMaybe<StringOperationFilterInput>;
  unitSymbol?: InputMaybe<StringOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingNotificationScopeNotificationOperatorFilterInput = {
  all?: InputMaybe<MappingNotificationScopeNotificationOperatorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingNotificationScopeNotificationOperatorFilterInput>;
  some?: InputMaybe<MappingNotificationScopeNotificationOperatorFilterInput>;
};

export type MappingNotificationScopeNotificationOperatorFilterInput = {
  and?: InputMaybe<Array<MappingNotificationScopeNotificationOperatorFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingNotificationScopeNotificationOperatorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  notificationOperator?: InputMaybe<NotificationOperatorFilterInput>;
  notificationOperatorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  notificationScope?: InputMaybe<NotificationScopeFilterInput>;
  notificationScopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingNotificationScopeNotificationOperatorFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type NotificationOperatorFilterInput = {
  and?: InputMaybe<Array<NotificationOperatorFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingNotificationScopeNotificationOperator?: InputMaybe<ListFilterInputTypeOfMappingNotificationScopeNotificationOperatorFilterInput>;
  notificationDefinition?: InputMaybe<ListFilterInputTypeOfNotificationDefinitionFilterInput>;
  notificationOperatorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  operator?: InputMaybe<StringOperationFilterInput>;
  operatorDescription?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NotificationOperatorFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfNotificationFilterInput = {
  all?: InputMaybe<NotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NotificationFilterInput>;
  some?: InputMaybe<NotificationFilterInput>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

/** I am a generated type! */
export type DeviceNotification = {
  __typename?: 'DeviceNotification';
  actualValue: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  device: Device;
  deviceId: Scalars['UUID'];
  eventOccurredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  notificationDefinition: NotificationDefinition;
  notificationDefinitionId: Scalars['UUID'];
  notificationRead?: Maybe<Scalars['Boolean']>;
  notificationSend: Scalars['DateTime'];
};

/** I am a generated type! */
export type NotificationDefinition = {
  __typename?: 'NotificationDefinition';
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceNotification: Array<DeviceNotification>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  notification: Notification;
  notificationId: Scalars['UUID'];
  notificationOperator: NotificationOperator;
  notificationOperatorId: Scalars['UUID'];
  storageArea?: Maybe<StorageArea>;
  storageAreaId?: Maybe<Scalars['UUID']>;
  value?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type NotificationDefinitionDeviceNotificationArgs = {
  order?: InputMaybe<Array<DeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceNotificationFilterInput>;
};

/** I am a generated type! */
export type Notification = {
  __typename?: 'Notification';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: Scalars['UUID'];
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  mappingDeviceNotification: Array<MappingDeviceNotification>;
  mappingNotificationUser: Array<MappingNotificationUser>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  notificationDefinition: Array<NotificationDefinition>;
  notificationScope: NotificationScope;
  notificationScopeId: Scalars['UUID'];
  notificationText?: Maybe<Scalars['String']>;
  /** @deprecated RecipientEmail will be removed in favour of MappingNotificationUser Entity. */
  recipientEmail?: Maybe<Scalars['String']>;
  resubmissionInterval: Scalars['Int'];
};


/** I am a generated type! */
export type NotificationMappingDeviceNotificationArgs = {
  order?: InputMaybe<Array<MappingDeviceNotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceNotificationFilterInput>;
};


/** I am a generated type! */
export type NotificationMappingNotificationUserArgs = {
  order?: InputMaybe<Array<MappingNotificationUserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingNotificationUserFilterInput>;
};


/** I am a generated type! */
export type NotificationNotificationDefinitionArgs = {
  order?: InputMaybe<Array<NotificationDefinitionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationDefinitionFilterInput>;
};

export type MappingDeviceNotificationSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceNotificationId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  notification?: InputMaybe<NotificationSortInput>;
  notificationId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingDeviceNotification = {
  __typename?: 'MappingDeviceNotification';
  createdAt?: Maybe<Scalars['DateTime']>;
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  notification: Notification;
  notificationId: Scalars['UUID'];
};

export type MappingNotificationUserSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  mappingNotificationUserId?: InputMaybe<SortEnumType>;
  notification?: InputMaybe<NotificationSortInput>;
  notificationId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingNotificationUser = {
  __typename?: 'MappingNotificationUser';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  notification: Notification;
  notificationId: Scalars['UUID'];
  tenantId?: Maybe<Scalars['Int']>;
  user: User;
  userId: Scalars['UUID'];
};

/** I am a generated type! */
export type User = {
  __typename?: 'User';
  authenticationProvider?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  mappingRoleUsers: Array<MappingRoleUser>;
  mappingUserScopes: Array<MappingUserScope>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  tenant: Tenant;
  userConfigurations: Array<UserConfiguration>;
  userDescription?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};


/** I am a generated type! */
export type UserMappingRoleUsersArgs = {
  order?: InputMaybe<Array<MappingRoleUserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingRoleUserFilterInput>;
};


/** I am a generated type! */
export type UserMappingUserScopesArgs = {
  order?: InputMaybe<Array<MappingUserScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingUserScopeFilterInput>;
};


/** I am a generated type! */
export type UserUserConfigurationsArgs = {
  order?: InputMaybe<Array<UserConfigurationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserConfigurationFilterInput>;
};

export type MappingRoleUserSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  mappingRoleUserId?: InputMaybe<SortEnumType>;
  role?: InputMaybe<RoleSortInput>;
  roleId?: InputMaybe<SortEnumType>;
  user?: InputMaybe<UserSortInput>;
  userId?: InputMaybe<SortEnumType>;
};

export type RoleSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDefaultRole?: InputMaybe<SortEnumType>;
  isDeletable?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  roleId?: InputMaybe<SortEnumType>;
};

export type UserSortInput = {
  authenticationProvider?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  language?: InputMaybe<SortEnumType>;
  lastLogin?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tenant?: InputMaybe<TenantSortInput>;
  tenantId?: InputMaybe<SortEnumType>;
  userDescription?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export type TenantSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  tenantIdentifier?: InputMaybe<SortEnumType>;
  tenantName?: InputMaybe<SortEnumType>;
};

export type MappingRoleUserFilterInput = {
  and?: InputMaybe<Array<MappingRoleUserFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingRoleUserId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingRoleUserFilterInput>>;
  role?: InputMaybe<RoleFilterInput>;
  roleId?: InputMaybe<ComparableGuidOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type RoleFilterInput = {
  and?: InputMaybe<Array<RoleFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  isDefaultRole?: InputMaybe<BooleanOperationFilterInput>;
  isDeletable?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingRoleScopes?: InputMaybe<ListFilterInputTypeOfMappingRoleScopeFilterInput>;
  mappingRoleUsers?: InputMaybe<ListFilterInputTypeOfMappingRoleUserFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RoleFilterInput>>;
  roleId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingRoleScopeFilterInput = {
  all?: InputMaybe<MappingRoleScopeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingRoleScopeFilterInput>;
  some?: InputMaybe<MappingRoleScopeFilterInput>;
};

export type MappingRoleScopeFilterInput = {
  and?: InputMaybe<Array<MappingRoleScopeFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingRoleScopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingRoleScopeFilterInput>>;
  role?: InputMaybe<RoleFilterInput>;
  roleId?: InputMaybe<ComparableGuidOperationFilterInput>;
  scope?: InputMaybe<ScopeFilterInput>;
  scopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ScopeFilterInput = {
  and?: InputMaybe<Array<ScopeFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingRoleScopes?: InputMaybe<ListFilterInputTypeOfMappingRoleScopeFilterInput>;
  mappingUserScopes?: InputMaybe<ListFilterInputTypeOfMappingUserScopeFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ScopeFilterInput>>;
  scopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingUserScopeFilterInput = {
  all?: InputMaybe<MappingUserScopeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingUserScopeFilterInput>;
  some?: InputMaybe<MappingUserScopeFilterInput>;
};

export type MappingUserScopeFilterInput = {
  and?: InputMaybe<Array<MappingUserScopeFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingUserScopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingUserScopeFilterInput>>;
  scope?: InputMaybe<ScopeFilterInput>;
  scopeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  authenticationProvider?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  language?: InputMaybe<StringOperationFilterInput>;
  lastLogin?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  mappingRoleUsers?: InputMaybe<ListFilterInputTypeOfMappingRoleUserFilterInput>;
  mappingUserScopes?: InputMaybe<ListFilterInputTypeOfMappingUserScopeFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  tenant?: InputMaybe<TenantFilterInput>;
  tenantId?: InputMaybe<ComparableGuidOperationFilterInput>;
  userConfigurations?: InputMaybe<ListFilterInputTypeOfUserConfigurationFilterInput>;
  userDescription?: InputMaybe<StringOperationFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingRoleUserFilterInput = {
  all?: InputMaybe<MappingRoleUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingRoleUserFilterInput>;
  some?: InputMaybe<MappingRoleUserFilterInput>;
};

export type TenantFilterInput = {
  and?: InputMaybe<Array<TenantFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TenantFilterInput>>;
  tenantId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantIdentifier?: InputMaybe<StringOperationFilterInput>;
  tenantName?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

export type ListFilterInputTypeOfUserConfigurationFilterInput = {
  all?: InputMaybe<UserConfigurationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserConfigurationFilterInput>;
  some?: InputMaybe<UserConfigurationFilterInput>;
};

export type UserConfigurationFilterInput = {
  and?: InputMaybe<Array<UserConfigurationFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  key?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<UserConfigurationFilterInput>>;
  tenantId?: InputMaybe<ComparableGuidOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userConfigurationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableGuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type MappingRoleUser = {
  __typename?: 'MappingRoleUser';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  role: Role;
  roleId: Scalars['UUID'];
  user: User;
  userId: Scalars['UUID'];
};

/** I am a generated type! */
export type Role = {
  __typename?: 'Role';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDefaultRole?: Maybe<Scalars['Boolean']>;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingRoleScopes: Array<MappingRoleScope>;
  mappingRoleUsers: Array<MappingRoleUser>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};


/** I am a generated type! */
export type RoleMappingRoleScopesArgs = {
  order?: InputMaybe<Array<MappingRoleScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingRoleScopeFilterInput>;
};


/** I am a generated type! */
export type RoleMappingRoleUsersArgs = {
  order?: InputMaybe<Array<MappingRoleUserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingRoleUserFilterInput>;
};

export type MappingRoleScopeSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  mappingRoleScopeId?: InputMaybe<SortEnumType>;
  role?: InputMaybe<RoleSortInput>;
  roleId?: InputMaybe<SortEnumType>;
  scope?: InputMaybe<ScopeSortInput>;
  scopeId?: InputMaybe<SortEnumType>;
};

export type ScopeSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  scopeId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingRoleScope = {
  __typename?: 'MappingRoleScope';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  role: Role;
  roleId: Scalars['UUID'];
  scope: Scope;
  scopeId: Scalars['UUID'];
};

/** I am a generated type! */
export type Scope = {
  __typename?: 'Scope';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingRoleScopes: Array<MappingRoleScope>;
  mappingUserScopes: Array<MappingUserScope>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  value: Scalars['String'];
};


/** I am a generated type! */
export type ScopeMappingRoleScopesArgs = {
  order?: InputMaybe<Array<MappingRoleScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingRoleScopeFilterInput>;
};


/** I am a generated type! */
export type ScopeMappingUserScopesArgs = {
  order?: InputMaybe<Array<MappingUserScopeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingUserScopeFilterInput>;
};

export type MappingUserScopeSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  mappingUserScopeId?: InputMaybe<SortEnumType>;
  scope?: InputMaybe<ScopeSortInput>;
  scopeId?: InputMaybe<SortEnumType>;
  user?: InputMaybe<UserSortInput>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingUserScope = {
  __typename?: 'MappingUserScope';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  scope: Scope;
  scopeId: Scalars['UUID'];
  user: User;
  userId: Scalars['UUID'];
};

/** I am a generated type! */
export type Tenant = {
  __typename?: 'Tenant';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  tenantIdentifier: Scalars['String'];
  tenantName: Scalars['String'];
  users: Array<User>;
};


/** I am a generated type! */
export type TenantUsersArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFilterInput>;
};

export type UserConfigurationSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  key?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  user?: InputMaybe<UserSortInput>;
  userConfigurationId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type UserConfiguration = {
  __typename?: 'UserConfiguration';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['UUID'];
  value: Scalars['String'];
};

/** I am a generated type! */
export type NotificationScope = {
  __typename?: 'NotificationScope';
  capability?: Maybe<Capability>;
  capabilityId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingNotificationScopeNotificationOperator: Array<MappingNotificationScopeNotificationOperator>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  notification: Array<Notification>;
  scopeName?: Maybe<Scalars['String']>;
  unitName: Scalars['String'];
  unitSymbol: Scalars['String'];
};


/** I am a generated type! */
export type NotificationScopeMappingNotificationScopeNotificationOperatorArgs = {
  order?: InputMaybe<Array<MappingNotificationScopeNotificationOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingNotificationScopeNotificationOperatorFilterInput>;
};


/** I am a generated type! */
export type NotificationScopeNotificationArgs = {
  order?: InputMaybe<Array<NotificationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationFilterInput>;
};

export type MappingNotificationScopeNotificationOperatorSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingNotificationScopeNotificationOperatorId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  notificationOperator?: InputMaybe<NotificationOperatorSortInput>;
  notificationOperatorId?: InputMaybe<SortEnumType>;
  notificationScope?: InputMaybe<NotificationScopeSortInput>;
  notificationScopeId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingNotificationScopeNotificationOperator = {
  __typename?: 'MappingNotificationScopeNotificationOperator';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  notificationOperator: NotificationOperator;
  notificationOperatorId: Scalars['UUID'];
  notificationScope: NotificationScope;
  notificationScopeId: Scalars['UUID'];
};

/** I am a generated type! */
export type NotificationOperator = {
  __typename?: 'NotificationOperator';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingNotificationScopeNotificationOperator: Array<MappingNotificationScopeNotificationOperator>;
  notificationDefinition: Array<NotificationDefinition>;
  operator: Scalars['String'];
  operatorDescription?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type NotificationOperatorMappingNotificationScopeNotificationOperatorArgs = {
  order?: InputMaybe<Array<MappingNotificationScopeNotificationOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingNotificationScopeNotificationOperatorFilterInput>;
};


/** I am a generated type! */
export type NotificationOperatorNotificationDefinitionArgs = {
  order?: InputMaybe<Array<NotificationDefinitionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationDefinitionFilterInput>;
};

/** I am a generated type! */
export type StorageArea = {
  __typename?: 'StorageArea';
  createdAt?: Maybe<Scalars['DateTime']>;
  geofence?: Maybe<GeoJsonPolygonType>;
  id: Scalars['UUID'];
  inverseParentStorageArea: Array<StorageArea>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  parentStorageArea?: Maybe<StorageArea>;
  parentStorageAreaId?: Maybe<Scalars['UUID']>;
  storageAreaDescription?: Maybe<Scalars['String']>;
  storageAreaName: Scalars['String'];
  storageAreaType: StorageAreaType;
  storageAreaTypeId: Scalars['UUID'];
};


/** I am a generated type! */
export type StorageAreaInverseParentStorageAreaArgs = {
  order?: InputMaybe<Array<StorageAreaSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StorageAreaFilterInput>;
};

export type GeoJsonPolygonType = GeoJsonInterface & {
  __typename?: 'GeoJSONPolygonType';
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']>;
  /** The "coordinates" field MUST be an array of linear ring coordinate arrays. For Polygons with more than one of these rings, the first MUST be the exterior ring, and any others MUST be interior rings. The exterior ring bounds the surface, and the interior rings (if present) bound holes within the surface. */
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Position']>>>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonInterface = {
  /** The minimum bounding box around the geometry object */
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** The coordinate reference system integer identifier */
  crs?: Maybe<Scalars['Int']>;
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export enum GeoJsonGeometryType {
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon'
}

export type StorageAreaSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  geofence?: InputMaybe<PolygonSortInput>;
  isDefault?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  parentStorageArea?: InputMaybe<StorageAreaSortInput>;
  parentStorageAreaId?: InputMaybe<SortEnumType>;
  storageAreaDescription?: InputMaybe<SortEnumType>;
  storageAreaId?: InputMaybe<SortEnumType>;
  storageAreaName?: InputMaybe<SortEnumType>;
  storageAreaType?: InputMaybe<StorageAreaTypeSortInput>;
  storageAreaTypeId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type PolygonSortInput = {
  area?: InputMaybe<SortEnumType>;
  boundary?: InputMaybe<GeometrySortInput>;
  boundaryDimension?: InputMaybe<SortEnumType>;
  centroid?: InputMaybe<PointSortInput>;
  coordinate?: InputMaybe<CoordinateSortInput>;
  dimension?: InputMaybe<SortEnumType>;
  envelope?: InputMaybe<GeometrySortInput>;
  envelopeInternal?: InputMaybe<EnvelopeSortInput>;
  exteriorRing?: InputMaybe<LineStringSortInput>;
  geometryType?: InputMaybe<SortEnumType>;
  interiorPoint?: InputMaybe<PointSortInput>;
  isEmpty?: InputMaybe<SortEnumType>;
  isRectangle?: InputMaybe<SortEnumType>;
  isSimple?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
  length?: InputMaybe<SortEnumType>;
  numGeometries?: InputMaybe<SortEnumType>;
  numInteriorRings?: InputMaybe<SortEnumType>;
  numPoints?: InputMaybe<SortEnumType>;
  ogcGeometryType?: InputMaybe<SortEnumType>;
  pointOnSurface?: InputMaybe<PointSortInput>;
  precisionModel?: InputMaybe<PrecisionModelSortInput>;
  sRID?: InputMaybe<SortEnumType>;
};

export type GeometrySortInput = {
  area?: InputMaybe<SortEnumType>;
  boundary?: InputMaybe<GeometrySortInput>;
  boundaryDimension?: InputMaybe<SortEnumType>;
  centroid?: InputMaybe<PointSortInput>;
  coordinate?: InputMaybe<CoordinateSortInput>;
  dimension?: InputMaybe<SortEnumType>;
  envelope?: InputMaybe<GeometrySortInput>;
  envelopeInternal?: InputMaybe<EnvelopeSortInput>;
  geometryType?: InputMaybe<SortEnumType>;
  interiorPoint?: InputMaybe<PointSortInput>;
  isEmpty?: InputMaybe<SortEnumType>;
  isRectangle?: InputMaybe<SortEnumType>;
  isSimple?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
  length?: InputMaybe<SortEnumType>;
  numGeometries?: InputMaybe<SortEnumType>;
  numPoints?: InputMaybe<SortEnumType>;
  ogcGeometryType?: InputMaybe<SortEnumType>;
  pointOnSurface?: InputMaybe<PointSortInput>;
  precisionModel?: InputMaybe<PrecisionModelSortInput>;
  sRID?: InputMaybe<SortEnumType>;
};

export type PointSortInput = {
  area?: InputMaybe<SortEnumType>;
  boundary?: InputMaybe<GeometrySortInput>;
  boundaryDimension?: InputMaybe<SortEnumType>;
  centroid?: InputMaybe<PointSortInput>;
  coordinate?: InputMaybe<CoordinateSortInput>;
  coordinateSequence?: InputMaybe<CoordinateSequenceSortInput>;
  dimension?: InputMaybe<SortEnumType>;
  envelope?: InputMaybe<GeometrySortInput>;
  envelopeInternal?: InputMaybe<EnvelopeSortInput>;
  geometryType?: InputMaybe<SortEnumType>;
  interiorPoint?: InputMaybe<PointSortInput>;
  isEmpty?: InputMaybe<SortEnumType>;
  isRectangle?: InputMaybe<SortEnumType>;
  isSimple?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
  length?: InputMaybe<SortEnumType>;
  m?: InputMaybe<SortEnumType>;
  numGeometries?: InputMaybe<SortEnumType>;
  numPoints?: InputMaybe<SortEnumType>;
  ogcGeometryType?: InputMaybe<SortEnumType>;
  pointOnSurface?: InputMaybe<PointSortInput>;
  precisionModel?: InputMaybe<PrecisionModelSortInput>;
  sRID?: InputMaybe<SortEnumType>;
  x?: InputMaybe<SortEnumType>;
  y?: InputMaybe<SortEnumType>;
  z?: InputMaybe<SortEnumType>;
};

export type CoordinateSortInput = {
  coordinateValue?: InputMaybe<CoordinateSortInput>;
  m?: InputMaybe<SortEnumType>;
  x?: InputMaybe<SortEnumType>;
  y?: InputMaybe<SortEnumType>;
  z?: InputMaybe<SortEnumType>;
};

export type CoordinateSequenceSortInput = {
  count?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  hasM?: InputMaybe<SortEnumType>;
  hasZ?: InputMaybe<SortEnumType>;
  measures?: InputMaybe<SortEnumType>;
  ordinates?: InputMaybe<SortEnumType>;
  spatial?: InputMaybe<SortEnumType>;
  zOrdinateIndex?: InputMaybe<SortEnumType>;
};

export type EnvelopeSortInput = {
  area?: InputMaybe<SortEnumType>;
  centre?: InputMaybe<CoordinateSortInput>;
  diameter?: InputMaybe<SortEnumType>;
  height?: InputMaybe<SortEnumType>;
  isNull?: InputMaybe<SortEnumType>;
  maxExtent?: InputMaybe<SortEnumType>;
  maxX?: InputMaybe<SortEnumType>;
  maxY?: InputMaybe<SortEnumType>;
  minExtent?: InputMaybe<SortEnumType>;
  minX?: InputMaybe<SortEnumType>;
  minY?: InputMaybe<SortEnumType>;
  width?: InputMaybe<SortEnumType>;
};

export type PrecisionModelSortInput = {
  isFloating?: InputMaybe<SortEnumType>;
  maximumSignificantDigits?: InputMaybe<SortEnumType>;
  precisionModelType?: InputMaybe<SortEnumType>;
  scale?: InputMaybe<SortEnumType>;
};

export type LineStringSortInput = {
  area?: InputMaybe<SortEnumType>;
  boundary?: InputMaybe<GeometrySortInput>;
  boundaryDimension?: InputMaybe<SortEnumType>;
  centroid?: InputMaybe<PointSortInput>;
  coordinate?: InputMaybe<CoordinateSortInput>;
  coordinateSequence?: InputMaybe<CoordinateSequenceSortInput>;
  count?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  endPoint?: InputMaybe<PointSortInput>;
  envelope?: InputMaybe<GeometrySortInput>;
  envelopeInternal?: InputMaybe<EnvelopeSortInput>;
  geometryType?: InputMaybe<SortEnumType>;
  interiorPoint?: InputMaybe<PointSortInput>;
  isEmpty?: InputMaybe<SortEnumType>;
  isRectangle?: InputMaybe<SortEnumType>;
  isRing?: InputMaybe<SortEnumType>;
  isSimple?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
  length?: InputMaybe<SortEnumType>;
  numGeometries?: InputMaybe<SortEnumType>;
  numPoints?: InputMaybe<SortEnumType>;
  ogcGeometryType?: InputMaybe<SortEnumType>;
  pointOnSurface?: InputMaybe<PointSortInput>;
  precisionModel?: InputMaybe<PrecisionModelSortInput>;
  sRID?: InputMaybe<SortEnumType>;
  startPoint?: InputMaybe<PointSortInput>;
};

export type StorageAreaTypeSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  storageAreaTypeColor?: InputMaybe<SortEnumType>;
  storageAreaTypeId?: InputMaybe<SortEnumType>;
  storageAreaTypeName?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type StorageAreaFilterInput = {
  and?: InputMaybe<Array<StorageAreaFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  geofence?: InputMaybe<PolygonFilterInput>;
  inverseParentStorageArea?: InputMaybe<ListFilterInputTypeOfStorageAreaFilterInput>;
  isDefault?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<StorageAreaFilterInput>>;
  parentStorageArea?: InputMaybe<StorageAreaFilterInput>;
  parentStorageAreaId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  storageAreaDescription?: InputMaybe<StringOperationFilterInput>;
  storageAreaId?: InputMaybe<ComparableGuidOperationFilterInput>;
  storageAreaName?: InputMaybe<StringOperationFilterInput>;
  storageAreaType?: InputMaybe<StorageAreaTypeFilterInput>;
  storageAreaTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type PolygonFilterInput = {
  and?: InputMaybe<Array<PolygonFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<LocationSchema_GeometryFilterInput>;
  boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  centroid?: InputMaybe<LocationSchema_PointFilterInput>;
  coordinate?: InputMaybe<CoordinateFilterInput>;
  coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  envelope?: InputMaybe<LocationSchema_GeometryFilterInput>;
  envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  exteriorRing?: InputMaybe<LineStringFilterInput>;
  factory?: InputMaybe<GeometryFactoryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  holes?: InputMaybe<ListFilterInputTypeOfLinearRingFilterInput>;
  interiorPoint?: InputMaybe<LocationSchema_PointFilterInput>;
  interiorRings?: InputMaybe<ListFilterInputTypeOfLineStringFilterInput>;
  isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  numGeometries?: InputMaybe<ComparableInt32OperationFilterInput>;
  numInteriorRings?: InputMaybe<ComparableInt32OperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<PolygonFilterInput>>;
  pointOnSurface?: InputMaybe<LocationSchema_PointFilterInput>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  shell?: InputMaybe<LinearRingFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type LocationSchema_GeometryFilterInput = {
  and?: InputMaybe<Array<LocationSchema_GeometryFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<LocationSchema_GeometryFilterInput>;
  boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  centroid?: InputMaybe<LocationSchema_PointFilterInput>;
  coordinate?: InputMaybe<CoordinateFilterInput>;
  coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  envelope?: InputMaybe<LocationSchema_GeometryFilterInput>;
  envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  factory?: InputMaybe<GeometryFactoryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<LocationSchema_PointFilterInput>;
  isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  numGeometries?: InputMaybe<ComparableInt32OperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<LocationSchema_GeometryFilterInput>>;
  pointOnSurface?: InputMaybe<LocationSchema_PointFilterInput>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type DimensionOperationFilterInput = {
  eq?: InputMaybe<Dimension>;
  in?: InputMaybe<Array<Dimension>>;
  neq?: InputMaybe<Dimension>;
  nin?: InputMaybe<Array<Dimension>>;
};

export enum Dimension {
  Collapse = 'COLLAPSE',
  Curve = 'CURVE',
  Dontcare = 'DONTCARE',
  P = 'P',
  Surface = 'SURFACE',
  True = 'TRUE',
  Unknown = 'UNKNOWN'
}

export type LocationSchema_PointFilterInput = {
  and?: InputMaybe<Array<LocationSchema_PointFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<LocationSchema_GeometryFilterInput>;
  boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  centroid?: InputMaybe<LocationSchema_PointFilterInput>;
  coordinate?: InputMaybe<CoordinateFilterInput>;
  coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  coordinateSequence?: InputMaybe<CoordinateSequenceFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  envelope?: InputMaybe<LocationSchema_GeometryFilterInput>;
  envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  factory?: InputMaybe<GeometryFactoryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<LocationSchema_PointFilterInput>;
  isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  m?: InputMaybe<ComparableDoubleOperationFilterInput>;
  numGeometries?: InputMaybe<ComparableInt32OperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<LocationSchema_PointFilterInput>>;
  pointOnSurface?: InputMaybe<LocationSchema_PointFilterInput>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
  x?: InputMaybe<ComparableDoubleOperationFilterInput>;
  y?: InputMaybe<ComparableDoubleOperationFilterInput>;
  z?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type CoordinateFilterInput = {
  and?: InputMaybe<Array<CoordinateFilterInput>>;
  coordinateValue?: InputMaybe<CoordinateFilterInput>;
  m?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<CoordinateFilterInput>>;
  x?: InputMaybe<ComparableDoubleOperationFilterInput>;
  y?: InputMaybe<ComparableDoubleOperationFilterInput>;
  z?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type ListFilterInputTypeOfCoordinateFilterInput = {
  all?: InputMaybe<CoordinateFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CoordinateFilterInput>;
  some?: InputMaybe<CoordinateFilterInput>;
};

export type CoordinateSequenceFilterInput = {
  and?: InputMaybe<Array<CoordinateSequenceFilterInput>>;
  count?: InputMaybe<ComparableInt32OperationFilterInput>;
  dimension?: InputMaybe<ComparableInt32OperationFilterInput>;
  hasM?: InputMaybe<BooleanOperationFilterInput>;
  hasZ?: InputMaybe<BooleanOperationFilterInput>;
  measures?: InputMaybe<ComparableInt32OperationFilterInput>;
  mOrdinateIndex?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CoordinateSequenceFilterInput>>;
  ordinates?: InputMaybe<OrdinatesOperationFilterInput>;
  spatial?: InputMaybe<ComparableInt32OperationFilterInput>;
  zOrdinateIndex?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type OrdinatesOperationFilterInput = {
  eq?: InputMaybe<Ordinates>;
  in?: InputMaybe<Array<Ordinates>>;
  neq?: InputMaybe<Ordinates>;
  nin?: InputMaybe<Array<Ordinates>>;
};

export enum Ordinates {
  AllMeasureOrdinates = 'ALL_MEASURE_ORDINATES',
  AllOrdinates = 'ALL_ORDINATES',
  AllSpatialOrdinates = 'ALL_SPATIAL_ORDINATES',
  M = 'M',
  Measure10 = 'MEASURE10',
  Measure11 = 'MEASURE11',
  Measure12 = 'MEASURE12',
  Measure13 = 'MEASURE13',
  Measure14 = 'MEASURE14',
  Measure15 = 'MEASURE15',
  Measure16 = 'MEASURE16',
  Measure2 = 'MEASURE2',
  Measure3 = 'MEASURE3',
  Measure4 = 'MEASURE4',
  Measure5 = 'MEASURE5',
  Measure6 = 'MEASURE6',
  Measure7 = 'MEASURE7',
  Measure8 = 'MEASURE8',
  Measure9 = 'MEASURE9',
  None = 'NONE',
  Spatial1 = 'SPATIAL1',
  Spatial10 = 'SPATIAL10',
  Spatial11 = 'SPATIAL11',
  Spatial12 = 'SPATIAL12',
  Spatial13 = 'SPATIAL13',
  Spatial14 = 'SPATIAL14',
  Spatial15 = 'SPATIAL15',
  Spatial16 = 'SPATIAL16',
  Spatial2 = 'SPATIAL2',
  Spatial4 = 'SPATIAL4',
  Spatial5 = 'SPATIAL5',
  Spatial6 = 'SPATIAL6',
  Spatial7 = 'SPATIAL7',
  Spatial8 = 'SPATIAL8',
  Spatial9 = 'SPATIAL9',
  Xy = 'XY',
  Xym = 'XYM',
  Xyz = 'XYZ',
  Xyzm = 'XYZM',
  Z = 'Z'
}

export type EnvelopeFilterInput = {
  and?: InputMaybe<Array<EnvelopeFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  centre?: InputMaybe<CoordinateFilterInput>;
  diameter?: InputMaybe<ComparableDoubleOperationFilterInput>;
  height?: InputMaybe<ComparableDoubleOperationFilterInput>;
  isNull?: InputMaybe<BooleanOperationFilterInput>;
  maxExtent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  maxX?: InputMaybe<ComparableDoubleOperationFilterInput>;
  maxY?: InputMaybe<ComparableDoubleOperationFilterInput>;
  minExtent?: InputMaybe<ComparableDoubleOperationFilterInput>;
  minX?: InputMaybe<ComparableDoubleOperationFilterInput>;
  minY?: InputMaybe<ComparableDoubleOperationFilterInput>;
  or?: InputMaybe<Array<EnvelopeFilterInput>>;
  width?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type GeometryFactoryFilterInput = {
  and?: InputMaybe<Array<GeometryFactoryFilterInput>>;
  coordinateSequenceFactory?: InputMaybe<CoordinateSequenceFactoryFilterInput>;
  geometryServices?: InputMaybe<NtsGeometryServicesFilterInput>;
  or?: InputMaybe<Array<GeometryFactoryFilterInput>>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type CoordinateSequenceFactoryFilterInput = {
  and?: InputMaybe<Array<CoordinateSequenceFactoryFilterInput>>;
  or?: InputMaybe<Array<CoordinateSequenceFactoryFilterInput>>;
  ordinates?: InputMaybe<OrdinatesOperationFilterInput>;
};

export type NtsGeometryServicesFilterInput = {
  and?: InputMaybe<Array<NtsGeometryServicesFilterInput>>;
  coordinateEqualityComparer?: InputMaybe<CoordinateEqualityComparerFilterInput>;
  defaultCoordinateSequenceFactory?: InputMaybe<CoordinateSequenceFactoryFilterInput>;
  defaultPrecisionModel?: InputMaybe<PrecisionModelFilterInput>;
  defaultSRID?: InputMaybe<ComparableInt32OperationFilterInput>;
  geometryOverlay?: InputMaybe<GeometryOverlayFilterInput>;
  or?: InputMaybe<Array<NtsGeometryServicesFilterInput>>;
};

export type CoordinateEqualityComparerFilterInput = {
  and?: InputMaybe<Array<CoordinateEqualityComparerFilterInput>>;
  or?: InputMaybe<Array<CoordinateEqualityComparerFilterInput>>;
};

export type PrecisionModelFilterInput = {
  and?: InputMaybe<Array<PrecisionModelFilterInput>>;
  isFloating?: InputMaybe<BooleanOperationFilterInput>;
  maximumSignificantDigits?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<PrecisionModelFilterInput>>;
  precisionModelType?: InputMaybe<PrecisionModelsOperationFilterInput>;
  scale?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type PrecisionModelsOperationFilterInput = {
  eq?: InputMaybe<PrecisionModels>;
  in?: InputMaybe<Array<PrecisionModels>>;
  neq?: InputMaybe<PrecisionModels>;
  nin?: InputMaybe<Array<PrecisionModels>>;
};

export enum PrecisionModels {
  Fixed = 'FIXED',
  Floating = 'FLOATING',
  FloatingSingle = 'FLOATING_SINGLE'
}

export type GeometryOverlayFilterInput = {
  and?: InputMaybe<Array<GeometryOverlayFilterInput>>;
  or?: InputMaybe<Array<GeometryOverlayFilterInput>>;
};

export type OgcGeometryTypeOperationFilterInput = {
  eq?: InputMaybe<OgcGeometryType>;
  in?: InputMaybe<Array<OgcGeometryType>>;
  neq?: InputMaybe<OgcGeometryType>;
  nin?: InputMaybe<Array<OgcGeometryType>>;
};

export enum OgcGeometryType {
  CircularString = 'CIRCULAR_STRING',
  CompoundCurve = 'COMPOUND_CURVE',
  Curve = 'CURVE',
  CurvePolygon = 'CURVE_POLYGON',
  GeometryCollection = 'GEOMETRY_COLLECTION',
  LineString = 'LINE_STRING',
  MultiCurve = 'MULTI_CURVE',
  MultiLineString = 'MULTI_LINE_STRING',
  MultiPoint = 'MULTI_POINT',
  MultiPolygon = 'MULTI_POLYGON',
  MultiSurface = 'MULTI_SURFACE',
  Point = 'POINT',
  Polygon = 'POLYGON',
  PolyhedralSurface = 'POLYHEDRAL_SURFACE',
  Surface = 'SURFACE',
  Tin = 'TIN'
}

export type LineStringFilterInput = {
  and?: InputMaybe<Array<LineStringFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<LocationSchema_GeometryFilterInput>;
  boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  centroid?: InputMaybe<LocationSchema_PointFilterInput>;
  coordinate?: InputMaybe<CoordinateFilterInput>;
  coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  coordinateSequence?: InputMaybe<CoordinateSequenceFilterInput>;
  count?: InputMaybe<ComparableInt32OperationFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  endPoint?: InputMaybe<LocationSchema_PointFilterInput>;
  envelope?: InputMaybe<LocationSchema_GeometryFilterInput>;
  envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  factory?: InputMaybe<GeometryFactoryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<LocationSchema_PointFilterInput>;
  isClosed?: InputMaybe<BooleanOperationFilterInput>;
  isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  isRing?: InputMaybe<BooleanOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  numGeometries?: InputMaybe<ComparableInt32OperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<LineStringFilterInput>>;
  pointOnSurface?: InputMaybe<LocationSchema_PointFilterInput>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
  startPoint?: InputMaybe<LocationSchema_PointFilterInput>;
};

export type ListFilterInputTypeOfLinearRingFilterInput = {
  all?: InputMaybe<LinearRingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LinearRingFilterInput>;
  some?: InputMaybe<LinearRingFilterInput>;
};

export type LinearRingFilterInput = {
  and?: InputMaybe<Array<LinearRingFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<LocationSchema_GeometryFilterInput>;
  boundaryDimension?: InputMaybe<DimensionOperationFilterInput>;
  centroid?: InputMaybe<LocationSchema_PointFilterInput>;
  coordinate?: InputMaybe<CoordinateFilterInput>;
  coordinates?: InputMaybe<ListFilterInputTypeOfCoordinateFilterInput>;
  coordinateSequence?: InputMaybe<CoordinateSequenceFilterInput>;
  count?: InputMaybe<ComparableInt32OperationFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  endPoint?: InputMaybe<LocationSchema_PointFilterInput>;
  envelope?: InputMaybe<LocationSchema_GeometryFilterInput>;
  envelopeInternal?: InputMaybe<EnvelopeFilterInput>;
  factory?: InputMaybe<GeometryFactoryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<LocationSchema_PointFilterInput>;
  isCCW?: InputMaybe<BooleanOperationFilterInput>;
  isClosed?: InputMaybe<BooleanOperationFilterInput>;
  isEmpty?: InputMaybe<BooleanOperationFilterInput>;
  isRectangle?: InputMaybe<BooleanOperationFilterInput>;
  isRing?: InputMaybe<BooleanOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  numGeometries?: InputMaybe<ComparableInt32OperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<LinearRingFilterInput>>;
  pointOnSurface?: InputMaybe<LocationSchema_PointFilterInput>;
  precisionModel?: InputMaybe<PrecisionModelFilterInput>;
  sRID?: InputMaybe<ComparableInt32OperationFilterInput>;
  startPoint?: InputMaybe<LocationSchema_PointFilterInput>;
};

export type ListFilterInputTypeOfLineStringFilterInput = {
  all?: InputMaybe<LineStringFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LineStringFilterInput>;
  some?: InputMaybe<LineStringFilterInput>;
};

export type ListFilterInputTypeOfStorageAreaFilterInput = {
  all?: InputMaybe<StorageAreaFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StorageAreaFilterInput>;
  some?: InputMaybe<StorageAreaFilterInput>;
};

export type StorageAreaTypeFilterInput = {
  and?: InputMaybe<Array<StorageAreaTypeFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<StorageAreaTypeFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  storageArea?: InputMaybe<ListFilterInputTypeOfStorageAreaFilterInput>;
  storageAreaTypeColor?: InputMaybe<StringOperationFilterInput>;
  storageAreaTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  storageAreaTypeName?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

/** I am a generated type! */
export type StorageAreaType = {
  __typename?: 'StorageAreaType';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
  storageArea: Array<StorageArea>;
  storageAreaTypeColor?: Maybe<Scalars['String']>;
  storageAreaTypeName: Scalars['String'];
};


/** I am a generated type! */
export type StorageAreaTypeStorageAreaArgs = {
  order?: InputMaybe<Array<StorageAreaSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StorageAreaFilterInput>;
};

/** I am a generated type! */
export type GatewayDeviceConnection = {
  __typename?: 'GatewayDeviceConnection';
  device: Device;
  deviceId: Scalars['UUID'];
  gatewayDeviceName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  primaryAuthenticationKey?: Maybe<Scalars['String']>;
  primaryConnectionString?: Maybe<Scalars['String']>;
  secondaryAuthenticationKey?: Maybe<Scalars['String']>;
  secondaryConnectionString?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

export type MappingDeviceConfigurationDeviceSortInput = {
  dateFrom?: InputMaybe<SortEnumType>;
  dateTo?: InputMaybe<SortEnumType>;
  device?: InputMaybe<DeviceSortInput>;
  deviceConfiguration?: InputMaybe<DeviceConfigurationSortInput>;
  deviceConfigurationId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceConfigurationDeviceId?: InputMaybe<SortEnumType>;
  mappingDeviceConfigurationDeviceStatus?: InputMaybe<MappingDeviceConfigurationDeviceStatusSortInput>;
  mappingDeviceConfigurationDeviceStatusId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type DeviceConfigurationSortInput = {
  configurationJSON?: InputMaybe<SortEnumType>;
  deviceConfigurationId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceConfigurationDeviceStatusSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceConfigurationDeviceStatusId?: InputMaybe<SortEnumType>;
  statusName?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingDeviceConfigurationDevice = {
  __typename?: 'MappingDeviceConfigurationDevice';
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  device: Device;
  deviceConfiguration: DeviceConfiguration;
  deviceConfigurationId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDeviceStatus: MappingDeviceConfigurationDeviceStatus;
  mappingDeviceConfigurationDeviceStatusId: Scalars['UUID'];
  userId?: Maybe<Scalars['UUID']>;
};

/** I am a generated type! */
export type DeviceConfiguration = {
  __typename?: 'DeviceConfiguration';
  configurationJSON: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDevice: Array<MappingDeviceConfigurationDevice>;
};


/** I am a generated type! */
export type DeviceConfigurationMappingDeviceConfigurationDeviceArgs = {
  order?: InputMaybe<Array<MappingDeviceConfigurationDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceConfigurationDeviceFilterInput>;
};

/** I am a generated type! */
export type MappingDeviceConfigurationDeviceStatus = {
  __typename?: 'MappingDeviceConfigurationDeviceStatus';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDevice: Array<MappingDeviceConfigurationDevice>;
  statusName: Scalars['String'];
};


/** I am a generated type! */
export type MappingDeviceConfigurationDeviceStatusMappingDeviceConfigurationDeviceArgs = {
  order?: InputMaybe<Array<MappingDeviceConfigurationDeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceConfigurationDeviceFilterInput>;
};

export type MappingDeviceTagSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  device?: InputMaybe<DeviceSortInput>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceTagId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tag?: InputMaybe<TagSortInput>;
  tagId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type TagSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tagId?: InputMaybe<SortEnumType>;
  tagValue?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingDeviceTag = {
  __typename?: 'MappingDeviceTag';
  createdAt?: Maybe<Scalars['DateTime']>;
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  tag: Tag;
  tagId: Scalars['UUID'];
};

/** I am a generated type! */
export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingDeviceTag: Array<MappingDeviceTag>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  tagValue: Scalars['String'];
};


/** I am a generated type! */
export type TagMappingDeviceTagArgs = {
  order?: InputMaybe<Array<MappingDeviceTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceTagFilterInput>;
};

export type SensorMeasurementCurrentValueSortInput = {
  utcTimeMeasured?: InputMaybe<SortEnumType>;
};

export type SensorMeasurementCurrentValueFilterInput = {
  and?: InputMaybe<Array<SensorMeasurementCurrentValueFilterInput>>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  gatewayDeviceConnectionId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  insertedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<SensorMeasurementCurrentValueFilterInput>>;
  sensorMeasurementCurrentValueId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  utcTimeMeasured?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  valueBool?: InputMaybe<BooleanOperationFilterInput>;
  valueDatetime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  valueFloat?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  valueGuid?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  valueInt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  valuePoint?: InputMaybe<PointFilterInput>;
  valuePosition?: InputMaybe<ComparableInt32OperationFilterInput>;
  valueString?: InputMaybe<StringOperationFilterInput>;
};

export type PointFilterInput = {
  and?: InputMaybe<Array<PointFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<GeometryFilterInput>;
  centroid?: InputMaybe<PointFilterInput>;
  contains?: InputMaybe<GeometryContainsOperationFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  distance?: InputMaybe<GeometryDistanceOperationFilterInput>;
  envelope?: InputMaybe<GeometryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<PointFilterInput>;
  intersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  m?: InputMaybe<ComparableDoubleOperationFilterInput>;
  ncontains?: InputMaybe<GeometryContainsOperationFilterInput>;
  nintersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  noverlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  ntouches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  nwithin?: InputMaybe<GeometryWithinOperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<PointFilterInput>>;
  overlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  pointOnSurface?: InputMaybe<PointFilterInput>;
  srid?: InputMaybe<ComparableInt32OperationFilterInput>;
  touches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  within?: InputMaybe<GeometryWithinOperationFilterInput>;
  x?: InputMaybe<ComparableDoubleOperationFilterInput>;
  y?: InputMaybe<ComparableDoubleOperationFilterInput>;
  z?: InputMaybe<ComparableDoubleOperationFilterInput>;
};

export type GeometryFilterInput = {
  and?: InputMaybe<Array<GeometryFilterInput>>;
  area?: InputMaybe<ComparableDoubleOperationFilterInput>;
  boundary?: InputMaybe<GeometryFilterInput>;
  centroid?: InputMaybe<PointFilterInput>;
  contains?: InputMaybe<GeometryContainsOperationFilterInput>;
  dimension?: InputMaybe<DimensionOperationFilterInput>;
  distance?: InputMaybe<GeometryDistanceOperationFilterInput>;
  envelope?: InputMaybe<GeometryFilterInput>;
  geometryType?: InputMaybe<StringOperationFilterInput>;
  interiorPoint?: InputMaybe<PointFilterInput>;
  intersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  isSimple?: InputMaybe<BooleanOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  length?: InputMaybe<ComparableDoubleOperationFilterInput>;
  ncontains?: InputMaybe<GeometryContainsOperationFilterInput>;
  nintersects?: InputMaybe<GeometryIntersectsOperationFilterInput>;
  noverlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  ntouches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  numPoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  nwithin?: InputMaybe<GeometryWithinOperationFilterInput>;
  ogcGeometryType?: InputMaybe<OgcGeometryTypeOperationFilterInput>;
  or?: InputMaybe<Array<GeometryFilterInput>>;
  overlaps?: InputMaybe<GeometryOverlapsOperationFilterInput>;
  pointOnSurface?: InputMaybe<PointFilterInput>;
  srid?: InputMaybe<ComparableInt32OperationFilterInput>;
  touches?: InputMaybe<GeometryTouchesOperationFilterInput>;
  within?: InputMaybe<GeometryWithinOperationFilterInput>;
};

export type GeometryContainsOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']>;
  geometry: Scalars['Geometry'];
};

export type GeometryDistanceOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  geometry: Scalars['Geometry'];
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type GeometryIntersectsOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']>;
  geometry: Scalars['Geometry'];
};

export type GeometryOverlapsOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']>;
  geometry: Scalars['Geometry'];
};

export type GeometryTouchesOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']>;
  geometry: Scalars['Geometry'];
};

export type GeometryWithinOperationFilterInput = {
  buffer?: InputMaybe<Scalars['Float']>;
  geometry: Scalars['Geometry'];
};

/** I am a generated type! */
export type SensorMeasurementCurrentValue = {
  __typename?: 'SensorMeasurementCurrentValue';
  capability?: Maybe<Capability>;
  capabilityId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  gatewayDeviceConnectionId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  insertedAt: Scalars['DateTime'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  utcTimeMeasured: Scalars['DateTime'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDatetime?: Maybe<Scalars['DateTime']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueGuid?: Maybe<Scalars['UUID']>;
  valueInt?: Maybe<Scalars['Int']>;
  valuePoint?: Maybe<GeoJsonPointType>;
  valuePosition: Scalars['Int'];
  valueString?: Maybe<Scalars['String']>;
};

export type GeoJsonPointType = GeoJsonInterface & {
  __typename?: 'GeoJSONPointType';
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']>;
  /** The "coordinates" field is a single position. */
  coordinates?: Maybe<Scalars['Position']>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type SensorMeasurementHistorySortInput = {
  utcTimeMeasured?: InputMaybe<SortEnumType>;
};

export type SensorMeasurementHistoryFilterInput = {
  and?: InputMaybe<Array<SensorMeasurementHistoryFilterInput>>;
  capabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  gatewayDeviceConnectionId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  insertedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<SensorMeasurementHistoryFilterInput>>;
  sensorMeasurementHistoryId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  utcTimeMeasured?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  valueBool?: InputMaybe<BooleanOperationFilterInput>;
  valueDatetime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  valueFloat?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  valueGuid?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  valueInt?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  valuePoint?: InputMaybe<PointFilterInput>;
  valuePosition?: InputMaybe<ComparableInt32OperationFilterInput>;
  valueString?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type SensorMeasurementHistory = {
  __typename?: 'SensorMeasurementHistory';
  capability?: Maybe<Capability>;
  capabilityId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  gatewayDeviceConnectionId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  insertedAt: Scalars['DateTime'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  utcTimeMeasured: Scalars['DateTime'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDatetime?: Maybe<Scalars['DateTime']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueGuid?: Maybe<Scalars['UUID']>;
  valueInt?: Maybe<Scalars['Int']>;
  valuePoint?: Maybe<GeoJsonPointType>;
  valuePosition: Scalars['Int'];
  valueString?: Maybe<Scalars['String']>;
};

export type AssetImageSortInput = {
  asset?: InputMaybe<AssetSortInput>;
  assetId?: InputMaybe<SortEnumType>;
  assetImageId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  pictureBlobStorageUrl?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  thumbnailBlobStorageUrl?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type AssetImage = {
  __typename?: 'AssetImage';
  asset: Asset;
  assetId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt: Scalars['DateTime'];
  pictureBlobStorageUrl: Scalars['String'];
  thumbnailBlobStorageUrl?: Maybe<Scalars['String']>;
};

/** I am a generated type! */
export type AssetMasterData = {
  __typename?: 'AssetMasterData';
  articleNumber?: Maybe<Scalars['String']>;
  asset: Array<Asset>;
  assetMasterDataSource?: Maybe<AssetMasterDataSource>;
  assetMasterDataSourceId?: Maybe<Scalars['UUID']>;
  factoryNumber?: Maybe<Scalars['String']>;
  generalItem?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  identificationNumber?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemConstructionNumber?: Maybe<Scalars['String']>;
  manufacturerDate?: Maybe<Scalars['DateTime']>;
  measurement?: Maybe<Scalars['String']>;
  projectNumber?: Maybe<Scalars['String']>;
  sellingUnitSerialNumber?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  supplierNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};


/** I am a generated type! */
export type AssetMasterDataAssetArgs = {
  order?: InputMaybe<Array<AssetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilterInput>;
};

/** I am a generated type! */
export type AssetMasterDataSource = {
  __typename?: 'AssetMasterDataSource';
  assetMasterData: Array<AssetMasterData>;
  assetMasterDataSourceDescription?: Maybe<Scalars['String']>;
  assetMasterDataSourceName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type AssetMasterDataSourceAssetMasterDataArgs = {
  order?: InputMaybe<Array<AssetMasterDataSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetMasterDataFilterInput>;
};

export type AssetNoteSortInput = {
  asset?: InputMaybe<AssetSortInput>;
  assetId?: InputMaybe<SortEnumType>;
  assetNoteId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  noteContent?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type AssetNote = {
  __typename?: 'AssetNote';
  asset: Asset;
  assetId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt: Scalars['DateTime'];
  noteContent: Scalars['String'];
};

/** I am a generated type! */
export type AssetSource = {
  __typename?: 'AssetSource';
  asset: Array<Asset>;
  assetSourceDescription?: Maybe<Scalars['String']>;
  assetSourceName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type AssetSourceAssetArgs = {
  order?: InputMaybe<Array<AssetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetFilterInput>;
};

export type MappingAssetTagSortInput = {
  asset?: InputMaybe<AssetSortInput>;
  assetId?: InputMaybe<SortEnumType>;
  assetTag?: InputMaybe<AssetTagSortInput>;
  assetTagId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingAssetTagId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type AssetTagSortInput = {
  assetTagId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  tagValue?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingAssetTag = {
  __typename?: 'MappingAssetTag';
  asset: Asset;
  assetId: Scalars['UUID'];
  assetTag: AssetTag;
  assetTagId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

/** I am a generated type! */
export type AssetTag = {
  __typename?: 'AssetTag';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingAssetTag: Array<MappingAssetTag>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  tagValue: Scalars['String'];
};


/** I am a generated type! */
export type AssetTagMappingAssetTagArgs = {
  order?: InputMaybe<Array<MappingAssetTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAssetTagFilterInput>;
};

export type QueryStatisticsOutputType = {
  __typename?: 'QueryStatisticsOutputType';
  filtered_item_count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  returned_item_count?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  total_item_count?: Maybe<Scalars['Int']>;
};

export type CouplingStatus = {
  __typename?: 'CouplingStatus';
  couplingState: Scalars['Int'];
  couplingStateDatetime: Scalars['DateTime'];
  couplingStateDescriptive?: Maybe<Scalars['String']>;
  couplingStateOperatingMinutes: Scalars['Float'];
  relatedCoupledDevices?: Maybe<Array<Maybe<CoupledDevice>>>;
};

export type CoupledDevice = {
  __typename?: 'CoupledDevice';
  device: Device;
  deviceId: Scalars['UUID'];
};

export type DeviceLocationInput = {
  deviceId: Scalars['UUID'];
  point?: InputMaybe<GeoJsonPointInput>;
};

export type GeoJsonPointInput = {
  /** The "coordinates" field is a single position. */
  coordinates?: InputMaybe<Scalars['Position']>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type DeviceStorageArea = {
  __typename?: 'DeviceStorageArea';
  deviceId: Scalars['UUID'];
  storageAreas?: Maybe<Array<Maybe<StorageArea>>>;
};

/** I am a generated type! */
export type DeviceHeartbeatCurrentValue = {
  __typename?: 'DeviceHeartbeatCurrentValue';
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  insertedAt: Scalars['DateTime'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  utcTimeMeasured: Scalars['DateTime'];
};

export type DeviceHeartbeatCurrentValueSortInput = {
  deviceHeartbeatCurrentValueId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  insertedAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  utcTimeMeasured?: InputMaybe<SortEnumType>;
};

export type DeviceHeartbeatCurrentValueFilterInput = {
  and?: InputMaybe<Array<DeviceHeartbeatCurrentValueFilterInput>>;
  deviceHeartbeatCurrentValueId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  insertedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceHeartbeatCurrentValueFilterInput>>;
  tenantId?: InputMaybe<ComparableGuidOperationFilterInput>;
  utcTimeMeasured?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type DeviceHeartbeatHistorySortInput = {
  deviceHeartbeatHistoryId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  insertedAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  uTCIngestionTime?: InputMaybe<SortEnumType>;
  uTCIoTHubEnqueuedTime?: InputMaybe<SortEnumType>;
  uTCPersistenceTime?: InputMaybe<SortEnumType>;
  utcTimeMeasured?: InputMaybe<SortEnumType>;
};

export type DeviceHeartbeatHistoryFilterInput = {
  and?: InputMaybe<Array<DeviceHeartbeatHistoryFilterInput>>;
  deviceHeartbeatHistoryId?: InputMaybe<ComparableGuidOperationFilterInput>;
  deviceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  insertedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceHeartbeatHistoryFilterInput>>;
  tenantId?: InputMaybe<ComparableGuidOperationFilterInput>;
  uTCIngestionTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  uTCIoTHubEnqueuedTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  uTCPersistenceTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  utcTimeMeasured?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

/** I am a generated type! */
export type DeviceHeartbeatHistory = {
  __typename?: 'DeviceHeartbeatHistory';
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  insertedAt: Scalars['DateTime'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  uTCIngestionTime?: Maybe<Scalars['DateTime']>;
  uTCIoTHubEnqueuedTime?: Maybe<Scalars['DateTime']>;
  uTCPersistenceTime?: Maybe<Scalars['DateTime']>;
  utcTimeMeasured: Scalars['DateTime'];
};

export type SensorMeasurementMovementHistory = {
  __typename?: 'SensorMeasurementMovementHistory';
  azureMapsLocations?: Maybe<Array<Maybe<AzureMapReverseBatchResponseItemsContentAddresses>>>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  deviceId: Scalars['UUID'];
  deviceState?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Float']>;
  isLast?: Maybe<Scalars['Boolean']>;
  location?: Maybe<GeoJsonPointType>;
  sensorMeasurementMovementHistoryId: Scalars['UUID'];
  utcTimeMeasured: Scalars['DateTime'];
};

export type AzureMapReverseBatchResponseItemsContentAddresses = {
  __typename?: 'AzureMapReverseBatchResponseItemsContentAddresses';
  address?: Maybe<AzureMapsReverseBatchResponseItemsContentAddress>;
  position?: Maybe<Scalars['String']>;
};

export type AzureMapsReverseBatchResponseItemsContentAddress = {
  __typename?: 'AzureMapsReverseBatchResponseItemsContentAddress';
  buildingNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCodeISO?: Maybe<Scalars['String']>;
  countrySecondarySubdivision?: Maybe<Scalars['String']>;
  countrySubdivision?: Maybe<Scalars['String']>;
  freeFormAddress?: Maybe<Scalars['String']>;
  localName?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  municipalitySubdivision?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNameAndNumber?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type Lhb_Rm_Data = {
  __typename?: 'LHB_RM_Data';
  deviceId: Scalars['UUID'];
  humidity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<GeoJsonPointType>;
  measurementTimeUTC: Scalars['DateTime'];
  rowNumer?: Maybe<Scalars['Int']>;
  sN?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  toolId?: Maybe<Scalars['String']>;
  toolState?: Maybe<Scalars['Boolean']>;
};

export type DeviceStateHistory = {
  __typename?: 'DeviceStateHistory';
  deviceId: Scalars['UUID'];
  states?: Maybe<Array<Maybe<DeviceState>>>;
};

export type DeviceState = {
  __typename?: 'DeviceState';
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  state: Scalars['Int'];
};

export type GenerateToken = {
  __typename?: 'GenerateToken';
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** I am a generated type! */
export type Group = {
  __typename?: 'Group';
  createdAt?: Maybe<Scalars['DateTime']>;
  groupDescription?: Maybe<Scalars['String']>;
  groupEntity: GroupEntity;
  groupEntityId: Scalars['UUID'];
  groupName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  jSONQuery: Scalars['String'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  rAWQuery?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
};

/** I am a generated type! */
export type GroupEntity = {
  __typename?: 'GroupEntity';
  entityName: Scalars['String'];
  group: Array<Group>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingGroupEntityGroupEntityFilter: Array<MappingGroupEntityGroupEntityFilter>;
};


/** I am a generated type! */
export type GroupEntityGroupArgs = {
  order?: InputMaybe<Array<GroupSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupFilterInput>;
};


/** I am a generated type! */
export type GroupEntityMappingGroupEntityGroupEntityFilterArgs = {
  order?: InputMaybe<Array<MappingGroupEntityGroupEntityFilterSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingGroupEntityGroupEntityFilterFilterInput>;
};

export type GroupSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  groupDescription?: InputMaybe<SortEnumType>;
  groupEntity?: InputMaybe<GroupEntitySortInput>;
  groupEntityId?: InputMaybe<SortEnumType>;
  groupId?: InputMaybe<SortEnumType>;
  groupName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  jSONQuery?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  rAWQuery?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type GroupEntitySortInput = {
  entityName?: InputMaybe<SortEnumType>;
  groupEntityId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type GroupFilterInput = {
  and?: InputMaybe<Array<GroupFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  groupDescription?: InputMaybe<StringOperationFilterInput>;
  groupEntity?: InputMaybe<GroupEntityFilterInput>;
  groupEntityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  jSONQuery?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GroupFilterInput>>;
  rAWQuery?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type GroupEntityFilterInput = {
  and?: InputMaybe<Array<GroupEntityFilterInput>>;
  entityName?: InputMaybe<StringOperationFilterInput>;
  group?: InputMaybe<ListFilterInputTypeOfGroupFilterInput>;
  groupEntityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingGroupEntityGroupEntityFilter?: InputMaybe<ListFilterInputTypeOfMappingGroupEntityGroupEntityFilterFilterInput>;
  or?: InputMaybe<Array<GroupEntityFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfGroupFilterInput = {
  all?: InputMaybe<GroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GroupFilterInput>;
  some?: InputMaybe<GroupFilterInput>;
};

export type ListFilterInputTypeOfMappingGroupEntityGroupEntityFilterFilterInput = {
  all?: InputMaybe<MappingGroupEntityGroupEntityFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingGroupEntityGroupEntityFilterFilterInput>;
  some?: InputMaybe<MappingGroupEntityGroupEntityFilterFilterInput>;
};

export type MappingGroupEntityGroupEntityFilterFilterInput = {
  and?: InputMaybe<Array<MappingGroupEntityGroupEntityFilterFilterInput>>;
  groupEntity?: InputMaybe<GroupEntityFilterInput>;
  groupEntityFilter?: InputMaybe<GroupEntityFilterFilterInput>;
  groupEntityFilterId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupEntityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingGroupEntityGroupEntityFilterId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingGroupEntityGroupEntityFilterFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type GroupEntityFilterFilterInput = {
  and?: InputMaybe<Array<GroupEntityFilterFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  entityColumnName?: InputMaybe<StringOperationFilterInput>;
  entityName?: InputMaybe<StringOperationFilterInput>;
  groupEntityFilterDataType?: InputMaybe<GroupEntityFilterDataTypeFilterInput>;
  groupEntityFilterDataTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupEntityFilterId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupEntityFilterUiType?: InputMaybe<GroupEntityFilterUiTypeFilterInput>;
  groupEntityFilterUiTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingGroupEntityFilterGroupOperator?: InputMaybe<ListFilterInputTypeOfMappingGroupEntityFilterGroupOperatorFilterInput>;
  mappingGroupEntityGroupEntityFilter?: InputMaybe<ListFilterInputTypeOfMappingGroupEntityGroupEntityFilterFilterInput>;
  or?: InputMaybe<Array<GroupEntityFilterFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type GroupEntityFilterDataTypeFilterInput = {
  and?: InputMaybe<Array<GroupEntityFilterDataTypeFilterInput>>;
  groupEntityFilter?: InputMaybe<ListFilterInputTypeOfGroupEntityFilterFilterInput>;
  groupEntityFilterDataTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupEntityFilterDataTypeName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<GroupEntityFilterDataTypeFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfGroupEntityFilterFilterInput = {
  all?: InputMaybe<GroupEntityFilterFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GroupEntityFilterFilterInput>;
  some?: InputMaybe<GroupEntityFilterFilterInput>;
};

export type GroupEntityFilterUiTypeFilterInput = {
  and?: InputMaybe<Array<GroupEntityFilterUiTypeFilterInput>>;
  groupEntityFilter?: InputMaybe<ListFilterInputTypeOfGroupEntityFilterFilterInput>;
  groupEntityFilterUiTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupEntityFilterUiTypeName?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<GroupEntityFilterUiTypeFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ListFilterInputTypeOfMappingGroupEntityFilterGroupOperatorFilterInput = {
  all?: InputMaybe<MappingGroupEntityFilterGroupOperatorFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingGroupEntityFilterGroupOperatorFilterInput>;
  some?: InputMaybe<MappingGroupEntityFilterGroupOperatorFilterInput>;
};

export type MappingGroupEntityFilterGroupOperatorFilterInput = {
  and?: InputMaybe<Array<MappingGroupEntityFilterGroupOperatorFilterInput>>;
  groupEntityFilter?: InputMaybe<GroupEntityFilterFilterInput>;
  groupEntityFilterId?: InputMaybe<ComparableGuidOperationFilterInput>;
  groupOperator?: InputMaybe<GroupOperatorFilterInput>;
  groupOperatorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingGroupEntityFilterGroupOperatorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingGroupEntityFilterGroupOperatorFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type GroupOperatorFilterInput = {
  and?: InputMaybe<Array<GroupOperatorFilterInput>>;
  groupOperatorId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingGroupEntityFilterGroupOperator?: InputMaybe<ListFilterInputTypeOfMappingGroupEntityFilterGroupOperatorFilterInput>;
  operator?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GroupOperatorFilterInput>>;
  tenantId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  userId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type MappingGroupEntityGroupEntityFilterSortInput = {
  groupEntity?: InputMaybe<GroupEntitySortInput>;
  groupEntityFilter?: InputMaybe<GroupEntityFilterSortInput>;
  groupEntityFilterId?: InputMaybe<SortEnumType>;
  groupEntityId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingGroupEntityGroupEntityFilterId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type GroupEntityFilterSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  entityColumnName?: InputMaybe<SortEnumType>;
  entityName?: InputMaybe<SortEnumType>;
  groupEntityFilterDataType?: InputMaybe<GroupEntityFilterDataTypeSortInput>;
  groupEntityFilterDataTypeId?: InputMaybe<SortEnumType>;
  groupEntityFilterId?: InputMaybe<SortEnumType>;
  groupEntityFilterUiType?: InputMaybe<GroupEntityFilterUiTypeSortInput>;
  groupEntityFilterUiTypeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type GroupEntityFilterDataTypeSortInput = {
  groupEntityFilterDataTypeId?: InputMaybe<SortEnumType>;
  groupEntityFilterDataTypeName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type GroupEntityFilterUiTypeSortInput = {
  groupEntityFilterUiTypeId?: InputMaybe<SortEnumType>;
  groupEntityFilterUiTypeName?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingGroupEntityGroupEntityFilter = {
  __typename?: 'MappingGroupEntityGroupEntityFilter';
  groupEntity: GroupEntity;
  groupEntityFilter: GroupEntityFilter;
  groupEntityFilterId: Scalars['UUID'];
  groupEntityId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type GroupEntityFilter = {
  __typename?: 'GroupEntityFilter';
  displayName: Scalars['String'];
  entityColumnName: Scalars['String'];
  entityName: Scalars['String'];
  groupEntityFilterDataType: GroupEntityFilterDataType;
  groupEntityFilterDataTypeId: Scalars['UUID'];
  groupEntityFilterUiType: GroupEntityFilterUiType;
  groupEntityFilterUiTypeId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingGroupEntityFilterGroupOperator: Array<MappingGroupEntityFilterGroupOperator>;
  mappingGroupEntityGroupEntityFilter: Array<MappingGroupEntityGroupEntityFilter>;
};


/** I am a generated type! */
export type GroupEntityFilterMappingGroupEntityFilterGroupOperatorArgs = {
  order?: InputMaybe<Array<MappingGroupEntityFilterGroupOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingGroupEntityFilterGroupOperatorFilterInput>;
};


/** I am a generated type! */
export type GroupEntityFilterMappingGroupEntityGroupEntityFilterArgs = {
  order?: InputMaybe<Array<MappingGroupEntityGroupEntityFilterSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingGroupEntityGroupEntityFilterFilterInput>;
};

/** I am a generated type! */
export type GroupEntityFilterDataType = {
  __typename?: 'GroupEntityFilterDataType';
  groupEntityFilter: Array<GroupEntityFilter>;
  groupEntityFilterDataTypeName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type GroupEntityFilterDataTypeGroupEntityFilterArgs = {
  order?: InputMaybe<Array<GroupEntityFilterSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupEntityFilterFilterInput>;
};

/** I am a generated type! */
export type GroupEntityFilterUiType = {
  __typename?: 'GroupEntityFilterUiType';
  groupEntityFilter: Array<GroupEntityFilter>;
  groupEntityFilterUiTypeName: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};


/** I am a generated type! */
export type GroupEntityFilterUiTypeGroupEntityFilterArgs = {
  order?: InputMaybe<Array<GroupEntityFilterSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupEntityFilterFilterInput>;
};

export type MappingGroupEntityFilterGroupOperatorSortInput = {
  groupEntityFilter?: InputMaybe<GroupEntityFilterSortInput>;
  groupEntityFilterId?: InputMaybe<SortEnumType>;
  groupOperator?: InputMaybe<GroupOperatorSortInput>;
  groupOperatorId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingGroupEntityFilterGroupOperatorId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

export type GroupOperatorSortInput = {
  groupOperatorId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  operator?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type MappingGroupEntityFilterGroupOperator = {
  __typename?: 'MappingGroupEntityFilterGroupOperator';
  groupEntityFilter: GroupEntityFilter;
  groupEntityFilterId: Scalars['UUID'];
  groupOperator: GroupOperator;
  groupOperatorId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type GroupOperator = {
  __typename?: 'GroupOperator';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  mappingGroupEntityFilterGroupOperator: Array<MappingGroupEntityFilterGroupOperator>;
  operator: Scalars['String'];
};


/** I am a generated type! */
export type GroupOperatorMappingGroupEntityFilterGroupOperatorArgs = {
  order?: InputMaybe<Array<MappingGroupEntityFilterGroupOperatorSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingGroupEntityFilterGroupOperatorFilterInput>;
};

export type UserInformationResult = {
  __typename?: 'UserInformationResult';
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
  userInformation?: Maybe<UserInformation>;
};

export type UserInformation = {
  __typename?: 'UserInformation';
  company?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type UserScopesResult = {
  __typename?: 'UserScopesResult';
  cubeJsJwtToken?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
  userScopes?: Maybe<Array<Maybe<UserScope>>>;
};

export type UserScope = {
  __typename?: 'UserScope';
  value?: Maybe<Scalars['String']>;
};

export type LocationAddress = {
  __typename?: 'LocationAddress';
  azureMapsLocations?: Maybe<Array<Maybe<AzureMapReverseBatchResponseItemsContentAddresses>>>;
  location?: Maybe<GeoJsonPointType>;
};

export type GeoJsonMultiPolygonInput = {
  /** The "coordinates" field is an array of Polygon coordinate arrays. */
  coordinates?: InputMaybe<Scalars['Coordinates']>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeofenceDevices = {
  __typename?: 'GeofenceDevices';
  geofenceDevices?: Maybe<Array<Maybe<GeofenceDevice>>>;
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type GeofenceDevice = {
  __typename?: 'GeofenceDevice';
  device: Device;
  deviceId: Scalars['UUID'];
  humidity?: Maybe<Scalars['Float']>;
  location?: Maybe<GeoJsonPointType>;
  measurementTimeUTC: Scalars['DateTime'];
  temperature?: Maybe<Scalars['Float']>;
};

export type LogHistory = {
  __typename?: 'LogHistory';
  isDeleted?: Maybe<Scalars['Boolean']>;
  logHistoryId: Scalars['UUID'];
  logMessage: Scalars['String'];
  logTime: Scalars['DateTime'];
  tenantId?: Maybe<Scalars['UUID']>;
};

/** I am a generated type! */
export type Rule = {
  __typename?: 'Rule';
  deviceCapability?: Maybe<DeviceCapability>;
  deviceCapabilityId: Scalars['UUID'];
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  ruleActionFailure: RuleAction;
  ruleActionFailureId: Scalars['UUID'];
  ruleActionSuccess: RuleAction;
  ruleActionSuccessId: Scalars['UUID'];
  ruleDescription?: Maybe<Scalars['String']>;
  ruleMessageFailure?: Maybe<Scalars['String']>;
  ruleMessageSuccess?: Maybe<Scalars['String']>;
  ruleName?: Maybe<Scalars['String']>;
  ruleOperator?: Maybe<Scalars['String']>;
  ruleReference: RuleReference;
  ruleReferenceId: Scalars['UUID'];
  ruleResult?: Maybe<Scalars['String']>;
  ruleResultType: RuleResultType;
  ruleResultTypeId: Scalars['UUID'];
  tenantId?: Maybe<Scalars['Int']>;
  unitId: Scalars['UUID'];
};

/** I am a generated type! */
export type RuleAction = {
  __typename?: 'RuleAction';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  ruleActionDescription?: Maybe<Scalars['String']>;
  ruleActionName?: Maybe<Scalars['String']>;
  ruleRuleActionFailure: Array<Rule>;
  ruleRuleActionSuccess: Array<Rule>;
};


/** I am a generated type! */
export type RuleActionRuleRuleActionFailureArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};


/** I am a generated type! */
export type RuleActionRuleRuleActionSuccessArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};

export type RuleSortInput = {
  deviceCapabilityId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isEnabled?: InputMaybe<SortEnumType>;
  ruleActionFailure?: InputMaybe<RuleActionSortInput>;
  ruleActionFailureId?: InputMaybe<SortEnumType>;
  ruleActionSuccess?: InputMaybe<RuleActionSortInput>;
  ruleActionSuccessId?: InputMaybe<SortEnumType>;
  ruleDescription?: InputMaybe<SortEnumType>;
  ruleId?: InputMaybe<SortEnumType>;
  ruleMessageFailure?: InputMaybe<SortEnumType>;
  ruleMessageSuccess?: InputMaybe<SortEnumType>;
  ruleName?: InputMaybe<SortEnumType>;
  ruleOperator?: InputMaybe<SortEnumType>;
  ruleReference?: InputMaybe<RuleReferenceSortInput>;
  ruleReferenceId?: InputMaybe<SortEnumType>;
  ruleResult?: InputMaybe<SortEnumType>;
  ruleResultType?: InputMaybe<RuleResultTypeSortInput>;
  ruleResultTypeId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitId?: InputMaybe<SortEnumType>;
};

export type RuleActionSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  ruleActionDescription?: InputMaybe<SortEnumType>;
  ruleActionId?: InputMaybe<SortEnumType>;
  ruleActionName?: InputMaybe<SortEnumType>;
};

export type RuleReferenceSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  referenceNumber?: InputMaybe<SortEnumType>;
  referenceUnitId?: InputMaybe<SortEnumType>;
  ruleReferenceDescription?: InputMaybe<SortEnumType>;
  ruleReferenceId?: InputMaybe<SortEnumType>;
  ruleReferenceName?: InputMaybe<SortEnumType>;
  ruleReferenceUnit?: InputMaybe<RuleReferenceUnitSortInput>;
};

export type RuleReferenceUnitSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  ruleReference?: InputMaybe<RuleReferenceSortInput>;
  ruleReferenceUnitDescription?: InputMaybe<SortEnumType>;
  ruleReferenceUnitId?: InputMaybe<SortEnumType>;
  ruleReferenceUnitName?: InputMaybe<SortEnumType>;
};

export type RuleResultTypeSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  ruleResultTypeDescription?: InputMaybe<SortEnumType>;
  ruleResultTypeId?: InputMaybe<SortEnumType>;
  ruleResultTypeName?: InputMaybe<SortEnumType>;
};

export type RuleFilterInput = {
  and?: InputMaybe<Array<RuleFilterInput>>;
  deviceCapabilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isEnabled?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RuleFilterInput>>;
  ruleActionFailure?: InputMaybe<RuleActionFilterInput>;
  ruleActionFailureId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleActionSuccess?: InputMaybe<RuleActionFilterInput>;
  ruleActionSuccessId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleDescription?: InputMaybe<StringOperationFilterInput>;
  ruleId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleMessageFailure?: InputMaybe<StringOperationFilterInput>;
  ruleMessageSuccess?: InputMaybe<StringOperationFilterInput>;
  ruleName?: InputMaybe<StringOperationFilterInput>;
  ruleOperator?: InputMaybe<StringOperationFilterInput>;
  ruleReference?: InputMaybe<RuleReferenceFilterInput>;
  ruleReferenceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleResult?: InputMaybe<StringOperationFilterInput>;
  ruleResultType?: InputMaybe<RuleResultTypeFilterInput>;
  ruleResultTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  tenantId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  unitId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type RuleActionFilterInput = {
  and?: InputMaybe<Array<RuleActionFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RuleActionFilterInput>>;
  ruleActionDescription?: InputMaybe<StringOperationFilterInput>;
  ruleActionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleActionName?: InputMaybe<StringOperationFilterInput>;
  ruleRuleActionFailure?: InputMaybe<ListFilterInputTypeOfRuleFilterInput>;
  ruleRuleActionSuccess?: InputMaybe<ListFilterInputTypeOfRuleFilterInput>;
};

export type ListFilterInputTypeOfRuleFilterInput = {
  all?: InputMaybe<RuleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RuleFilterInput>;
  some?: InputMaybe<RuleFilterInput>;
};

export type RuleReferenceFilterInput = {
  and?: InputMaybe<Array<RuleReferenceFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RuleReferenceFilterInput>>;
  referenceNumber?: InputMaybe<ComparableInt32OperationFilterInput>;
  referenceUnitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  rule?: InputMaybe<ListFilterInputTypeOfRuleFilterInput>;
  ruleReferenceDescription?: InputMaybe<StringOperationFilterInput>;
  ruleReferenceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleReferenceName?: InputMaybe<StringOperationFilterInput>;
  ruleReferenceUnit?: InputMaybe<RuleReferenceUnitFilterInput>;
};

export type RuleReferenceUnitFilterInput = {
  and?: InputMaybe<Array<RuleReferenceUnitFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RuleReferenceUnitFilterInput>>;
  ruleReference?: InputMaybe<RuleReferenceFilterInput>;
  ruleReferenceUnitDescription?: InputMaybe<StringOperationFilterInput>;
  ruleReferenceUnitId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleReferenceUnitName?: InputMaybe<StringOperationFilterInput>;
};

export type RuleResultTypeFilterInput = {
  and?: InputMaybe<Array<RuleResultTypeFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RuleResultTypeFilterInput>>;
  rule?: InputMaybe<ListFilterInputTypeOfRuleFilterInput>;
  ruleResultTypeDescription?: InputMaybe<StringOperationFilterInput>;
  ruleResultTypeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  ruleResultTypeName?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type RuleReference = {
  __typename?: 'RuleReference';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  referenceNumber: Scalars['Int'];
  referenceUnitId: Scalars['UUID'];
  rule: Array<Rule>;
  ruleReferenceDescription?: Maybe<Scalars['String']>;
  ruleReferenceName?: Maybe<Scalars['String']>;
  ruleReferenceUnit?: Maybe<RuleReferenceUnit>;
};


/** I am a generated type! */
export type RuleReferenceRuleArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};

/** I am a generated type! */
export type RuleReferenceUnit = {
  __typename?: 'RuleReferenceUnit';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  ruleReference?: Maybe<RuleReference>;
  ruleReferenceUnitDescription?: Maybe<Scalars['String']>;
  ruleReferenceUnitName?: Maybe<Scalars['String']>;
};

/** I am a generated type! */
export type RuleResultType = {
  __typename?: 'RuleResultType';
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  rule: Array<Rule>;
  ruleResultTypeDescription?: Maybe<Scalars['String']>;
  ruleResultTypeName?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type RuleResultTypeRuleArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};

export enum Dimensions {
  CapabilityId = 'CAPABILITY_ID',
  DeviceId = 'DEVICE_ID',
  GatewayDeviceConnectionId = 'GATEWAY_DEVICE_CONNECTION_ID'
}

export enum Metrics {
  Avg = 'AVG',
  Count = 'COUNT',
  Max = 'MAX',
  Min = 'MIN',
  Std = 'STD'
}

export type SensorMeasurementHistoryAggregate = {
  __typename?: 'SensorMeasurementHistoryAggregate';
  data?: Maybe<Array<Maybe<AggregateData>>>;
};

export type AggregateData = {
  __typename?: 'AggregateData';
  dimensions?: Maybe<Array<Maybe<Array<KeyValuePairOfStringAndGuid>>>>;
  metrics?: Maybe<Array<Maybe<Array<KeyValuePairOfStringAndNullableOfSingle>>>>;
  timeBucket: Scalars['DateTime'];
};

export type KeyValuePairOfStringAndGuid = {
  __typename?: 'KeyValuePairOfStringAndGuid';
  key: Scalars['String'];
  value: Scalars['UUID'];
};

export type KeyValuePairOfStringAndNullableOfSingle = {
  __typename?: 'KeyValuePairOfStringAndNullableOfSingle';
  key: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

/** I am a generated type! */
export type Translation = {
  __typename?: 'Translation';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  language: Scalars['String'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type TranslationSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  key?: InputMaybe<SortEnumType>;
  language?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  translationId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type TranslationFilterInput = {
  and?: InputMaybe<Array<TranslationFilterInput>>;
  createdAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  key?: InputMaybe<StringOperationFilterInput>;
  language?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TranslationFilterInput>>;
  translationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAssetAttributeDataTypes?: Maybe<Array<Maybe<AssetAttributeDataType>>>;
  createAssetAttributes?: Maybe<Array<Maybe<AssetAttribute>>>;
  createAssetAttributeUnits?: Maybe<Array<Maybe<AssetAttributeUnit>>>;
  createAssetAttributeValues?: Maybe<Array<Maybe<AssetAttributeValue>>>;
  createAssetDevicePairing?: Maybe<AssetDevicePairingBatch>;
  createAssetDevices?: Maybe<Array<Maybe<AssetDevice>>>;
  createAssetImages?: Maybe<Array<Maybe<AssetImage>>>;
  createAssetMasterDatas?: Maybe<Array<Maybe<AssetMasterData>>>;
  createAssetMasterDataSources?: Maybe<Array<Maybe<AssetMasterDataSource>>>;
  createAssetNotes?: Maybe<Array<Maybe<AssetNote>>>;
  createAssets?: Maybe<Array<Maybe<Asset>>>;
  createAssetSources?: Maybe<Array<Maybe<AssetSource>>>;
  createAssetTags?: Maybe<Array<Maybe<AssetTag>>>;
  createAssetTypeAttributeSets?: Maybe<Array<Maybe<AssetTypeAttributeSet>>>;
  createCapabilities?: Maybe<Array<Maybe<Capability>>>;
  createDeviceAttributes?: Maybe<Array<Maybe<DeviceAttribute>>>;
  createDeviceAttributeValues?: Maybe<Array<Maybe<DeviceAttributeValue>>>;
  createDeviceCapabilities?: Maybe<Array<Maybe<DeviceCapability>>>;
  createDeviceConfigurations?: Maybe<Array<Maybe<DeviceConfiguration>>>;
  createDeviceFirmwareConfigurationSchemas?: Maybe<Array<Maybe<DeviceFirmwareConfigurationSchema>>>;
  createDeviceFirmwareReleaseTypes?: Maybe<Array<Maybe<DeviceFirmwareReleaseType>>>;
  createDeviceFirmwares?: Maybe<Array<Maybe<DeviceFirmware>>>;
  createDeviceHeartbeatCurrentValues?: Maybe<Array<Maybe<DeviceHeartbeatCurrentValue>>>;
  createDeviceHeartbeatHistories?: Maybe<Array<Maybe<DeviceHeartbeatHistory>>>;
  createDeviceModelAttributeSets?: Maybe<Array<Maybe<DeviceModelAttributeSet>>>;
  createDeviceModelCapabilities?: Maybe<Array<Maybe<DeviceModelCapability>>>;
  createDeviceModels?: Maybe<Array<Maybe<DeviceModel>>>;
  createDeviceModelVersions?: Maybe<Array<Maybe<DeviceModelVersion>>>;
  createDeviceNotifications?: Maybe<Array<Maybe<DeviceNotification>>>;
  createDevices?: Maybe<Array<Maybe<Device>>>;
  createDeviceTypes?: Maybe<Array<Maybe<DeviceType>>>;
  createExcavatorSetup?: Maybe<AssetDevicePairing>;
  createGatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  createGroupEntities?: Maybe<Array<Maybe<GroupEntity>>>;
  createGroupEntityFilterDataTypes?: Maybe<Array<Maybe<GroupEntityFilterDataType>>>;
  createGroupEntityFilters?: Maybe<Array<Maybe<GroupEntityFilter>>>;
  createGroupEntityFilterUiTypes?: Maybe<Array<Maybe<GroupEntityFilterUiType>>>;
  createGroupOperators?: Maybe<Array<Maybe<GroupOperator>>>;
  createGroups?: Maybe<Array<Maybe<Group>>>;
  createImageUpload?: Maybe<AssetImage>;
  createLogEntry?: Maybe<Array<Maybe<LogHistory>>>;
  createMappingAssetTags?: Maybe<Array<Maybe<MappingAssetTag>>>;
  createMappingAttributeAssetTypeAttributeSets?: Maybe<Array<Maybe<MappingAttributeAssetTypeAttributeSet>>>;
  createMappingAttributeDeviceModelAttributeSets?: Maybe<Array<Maybe<MappingAttributeDeviceModelAttributeSet>>>;
  createMappingDeviceConfigurationDevices?: Maybe<Array<Maybe<MappingDeviceConfigurationDevice>>>;
  createMappingDeviceConfigurationDeviceStatuses?: Maybe<Array<Maybe<MappingDeviceConfigurationDeviceStatus>>>;
  createMappingDeviceFirmwareDevices?: Maybe<Array<Maybe<MappingDeviceFirmwareDevice>>>;
  createMappingDeviceFirmwareDeviceStatuses?: Maybe<Array<Maybe<MappingDeviceFirmwareDeviceStatus>>>;
  createMappingDeviceFirmwareDeviceTypes?: Maybe<Array<Maybe<MappingDeviceFirmwareDeviceType>>>;
  createMappingDeviceNotifications?: Maybe<Array<Maybe<MappingDeviceNotification>>>;
  createMappingDeviceTags?: Maybe<Array<Maybe<MappingDeviceTag>>>;
  createMappingGroupEntityFilterGroupOperators?: Maybe<Array<Maybe<MappingGroupEntityFilterGroupOperator>>>;
  createMappingGroupEntityGroupEntityFilters?: Maybe<Array<Maybe<MappingGroupEntityGroupEntityFilter>>>;
  createMappingNotificationScopeNotificationOperators?: Maybe<Array<Maybe<MappingNotificationScopeNotificationOperator>>>;
  createMappingNotificationUsers?: Maybe<Array<Maybe<MappingNotificationUser>>>;
  createMappingRoleScopes?: Maybe<Array<Maybe<MappingRoleScope>>>;
  createMappingRoleUsers?: Maybe<Array<Maybe<MappingRoleUser>>>;
  createMappingUserScopes?: Maybe<Array<Maybe<MappingUserScope>>>;
  createNotificationDefinitions?: Maybe<Array<Maybe<NotificationDefinition>>>;
  createNotificationOperators?: Maybe<Array<Maybe<NotificationOperator>>>;
  createNotifications?: Maybe<Array<Maybe<Notification>>>;
  createNotificationScopes?: Maybe<Array<Maybe<NotificationScope>>>;
  createRoles?: Maybe<Array<Maybe<Role>>>;
  createRuleActions?: Maybe<Array<Maybe<RuleAction>>>;
  createRuleReferences?: Maybe<Array<Maybe<RuleReference>>>;
  createRuleReferenceUnits?: Maybe<Array<Maybe<RuleReferenceUnit>>>;
  createRuleResultTypes?: Maybe<Array<Maybe<RuleResultType>>>;
  createRules?: Maybe<Array<Maybe<Rule>>>;
  createScopes?: Maybe<Array<Maybe<Scope>>>;
  createSensorMeasurementCurrentValues?: Maybe<Array<Maybe<SensorMeasurementCurrentValue>>>;
  createSensorMeasurementHistories?: Maybe<Array<Maybe<SensorMeasurementHistory>>>;
  createStorageAreas?: Maybe<Array<Maybe<StorageArea>>>;
  createStorageAreaTypes?: Maybe<Array<Maybe<StorageAreaType>>>;
  createTags?: Maybe<Array<Maybe<Tag>>>;
  createTenants?: Maybe<Array<Maybe<Tenant>>>;
  createTranslations?: Maybe<Array<Maybe<Translation>>>;
  createUnits?: Maybe<Array<Maybe<Unit>>>;
  createUserConfigurations?: Maybe<Array<Maybe<UserConfiguration>>>;
  createUsers?: Maybe<Array<Maybe<User>>>;
  deleteAssetAttributeDataTypes?: Maybe<Array<Maybe<AssetAttributeDataType>>>;
  deleteAssetAttributes?: Maybe<Array<Maybe<AssetAttribute>>>;
  deleteAssetAttributeUnits?: Maybe<Array<Maybe<AssetAttributeUnit>>>;
  deleteAssetAttributeValues?: Maybe<Array<Maybe<AssetAttributeValue>>>;
  deleteAssetDevices?: Maybe<Array<Maybe<AssetDevice>>>;
  deleteAssetImages?: Maybe<Array<Maybe<AssetImage>>>;
  deleteAssetMasterDatas?: Maybe<Array<Maybe<AssetMasterData>>>;
  deleteAssetMasterDataSources?: Maybe<Array<Maybe<AssetMasterDataSource>>>;
  deleteAssetNotes?: Maybe<Array<Maybe<AssetNote>>>;
  deleteAssets?: Maybe<Array<Maybe<Asset>>>;
  deleteAssetSources?: Maybe<Array<Maybe<AssetSource>>>;
  deleteAssetTags?: Maybe<Array<Maybe<AssetTag>>>;
  deleteAssetTypeAttributeSets?: Maybe<Array<Maybe<AssetTypeAttributeSet>>>;
  deleteCapabilities?: Maybe<Array<Maybe<Capability>>>;
  deleteDeviceAttributes?: Maybe<Array<Maybe<DeviceAttribute>>>;
  deleteDeviceAttributeValues?: Maybe<Array<Maybe<DeviceAttributeValue>>>;
  deleteDeviceCapabilities?: Maybe<Array<Maybe<DeviceCapability>>>;
  deleteDeviceConfigurations?: Maybe<Array<Maybe<DeviceConfiguration>>>;
  deleteDeviceFirmwareConfigurationSchemas?: Maybe<Array<Maybe<DeviceFirmwareConfigurationSchema>>>;
  deleteDeviceFirmwareReleaseTypes?: Maybe<Array<Maybe<DeviceFirmwareReleaseType>>>;
  deleteDeviceFirmwares?: Maybe<Array<Maybe<DeviceFirmware>>>;
  deleteDeviceHeartbeatCurrentValues?: Maybe<Array<Maybe<DeviceHeartbeatCurrentValue>>>;
  deleteDeviceHeartbeatHistories?: Maybe<Array<Maybe<DeviceHeartbeatHistory>>>;
  deleteDeviceModelAttributeSets?: Maybe<Array<Maybe<DeviceModelAttributeSet>>>;
  deleteDeviceModelCapabilities?: Maybe<Array<Maybe<DeviceModelCapability>>>;
  deleteDeviceModels?: Maybe<Array<Maybe<DeviceModel>>>;
  deleteDeviceModelVersions?: Maybe<Array<Maybe<DeviceModelVersion>>>;
  deleteDeviceNotifications?: Maybe<Array<Maybe<DeviceNotification>>>;
  deleteDevices?: Maybe<Array<Maybe<Device>>>;
  deleteDeviceTypes?: Maybe<Array<Maybe<DeviceType>>>;
  deleteGatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  deleteGroupEntities?: Maybe<Array<Maybe<GroupEntity>>>;
  deleteGroupEntityFilterDataTypes?: Maybe<Array<Maybe<GroupEntityFilterDataType>>>;
  deleteGroupEntityFilters?: Maybe<Array<Maybe<GroupEntityFilter>>>;
  deleteGroupEntityFilterUiTypes?: Maybe<Array<Maybe<GroupEntityFilterUiType>>>;
  deleteGroupOperators?: Maybe<Array<Maybe<GroupOperator>>>;
  deleteGroups?: Maybe<Array<Maybe<Group>>>;
  deleteMappingAssetTags?: Maybe<Array<Maybe<MappingAssetTag>>>;
  deleteMappingAttributeAssetTypeAttributeSets?: Maybe<Array<Maybe<MappingAttributeAssetTypeAttributeSet>>>;
  deleteMappingAttributeDeviceModelAttributeSets?: Maybe<Array<Maybe<MappingAttributeDeviceModelAttributeSet>>>;
  deleteMappingDeviceConfigurationDevices?: Maybe<Array<Maybe<MappingDeviceConfigurationDevice>>>;
  deleteMappingDeviceConfigurationDeviceStatuses?: Maybe<Array<Maybe<MappingDeviceConfigurationDeviceStatus>>>;
  deleteMappingDeviceFirmwareDevices?: Maybe<Array<Maybe<MappingDeviceFirmwareDevice>>>;
  deleteMappingDeviceFirmwareDeviceStatuses?: Maybe<Array<Maybe<MappingDeviceFirmwareDeviceStatus>>>;
  deleteMappingDeviceFirmwareDeviceTypes?: Maybe<Array<Maybe<MappingDeviceFirmwareDeviceType>>>;
  deleteMappingDeviceNotifications?: Maybe<Array<Maybe<MappingDeviceNotification>>>;
  deleteMappingDeviceTags?: Maybe<Array<Maybe<MappingDeviceTag>>>;
  deleteMappingGroupEntityFilterGroupOperators?: Maybe<Array<Maybe<MappingGroupEntityFilterGroupOperator>>>;
  deleteMappingGroupEntityGroupEntityFilters?: Maybe<Array<Maybe<MappingGroupEntityGroupEntityFilter>>>;
  deleteMappingNotificationScopeNotificationOperators?: Maybe<Array<Maybe<MappingNotificationScopeNotificationOperator>>>;
  deleteMappingNotificationUsers?: Maybe<Array<Maybe<MappingNotificationUser>>>;
  deleteMappingRoleScopes?: Maybe<Array<Maybe<MappingRoleScope>>>;
  deleteMappingRoleUsers?: Maybe<Array<Maybe<MappingRoleUser>>>;
  deleteMappingUserScopes?: Maybe<Array<Maybe<MappingUserScope>>>;
  deleteNotificationDefinitions?: Maybe<Array<Maybe<NotificationDefinition>>>;
  deleteNotificationOperators?: Maybe<Array<Maybe<NotificationOperator>>>;
  deleteNotifications?: Maybe<Array<Maybe<Notification>>>;
  deleteNotificationScopes?: Maybe<Array<Maybe<NotificationScope>>>;
  deleteRoles?: Maybe<Array<Maybe<Role>>>;
  deleteRuleActions?: Maybe<Array<Maybe<RuleAction>>>;
  deleteRuleReferences?: Maybe<Array<Maybe<RuleReference>>>;
  deleteRuleReferenceUnits?: Maybe<Array<Maybe<RuleReferenceUnit>>>;
  deleteRuleResultTypes?: Maybe<Array<Maybe<RuleResultType>>>;
  deleteRules?: Maybe<Array<Maybe<Rule>>>;
  deleteScopes?: Maybe<Array<Maybe<Scope>>>;
  deleteSensorMeasurementCurrentValues?: Maybe<Array<Maybe<SensorMeasurementCurrentValue>>>;
  deleteSensorMeasurementHistories?: Maybe<Array<Maybe<SensorMeasurementHistory>>>;
  deleteStorageAreas?: Maybe<Array<Maybe<StorageArea>>>;
  deleteStorageAreaTypes?: Maybe<Array<Maybe<StorageAreaType>>>;
  deleteTags?: Maybe<Array<Maybe<Tag>>>;
  deleteTenants?: Maybe<Array<Maybe<Tenant>>>;
  deleteTranslations?: Maybe<Array<Maybe<Translation>>>;
  deleteUnits?: Maybe<Array<Maybe<Unit>>>;
  deleteUserConfigurations?: Maybe<Array<Maybe<UserConfiguration>>>;
  deleteUsers?: Maybe<Array<Maybe<User>>>;
  sendNewsletter?: Maybe<Scalars['String']>;
  unpairingAssetDevice?: Maybe<AssetDevicePairing>;
  updateAssetAttributeDataTypes?: Maybe<Array<Maybe<AssetAttributeDataType>>>;
  updateAssetAttributes?: Maybe<Array<Maybe<AssetAttribute>>>;
  updateAssetAttributeUnits?: Maybe<Array<Maybe<AssetAttributeUnit>>>;
  updateAssetAttributeValues?: Maybe<Array<Maybe<AssetAttributeValue>>>;
  updateAssetDevicePairing?: Maybe<AssetDevicePairing>;
  updateAssetDevices?: Maybe<Array<Maybe<AssetDevice>>>;
  updateAssetImages?: Maybe<Array<Maybe<AssetImage>>>;
  updateAssetMasterDatas?: Maybe<Array<Maybe<AssetMasterData>>>;
  updateAssetMasterDataSources?: Maybe<Array<Maybe<AssetMasterDataSource>>>;
  updateAssetNotes?: Maybe<Array<Maybe<AssetNote>>>;
  updateAssets?: Maybe<Array<Maybe<Asset>>>;
  updateAssetSources?: Maybe<Array<Maybe<AssetSource>>>;
  updateAssetTags?: Maybe<Array<Maybe<AssetTag>>>;
  updateAssetTypeAttributeSets?: Maybe<Array<Maybe<AssetTypeAttributeSet>>>;
  updateCapabilities?: Maybe<Array<Maybe<Capability>>>;
  updateDeviceAttributes?: Maybe<Array<Maybe<DeviceAttribute>>>;
  updateDeviceAttributeValues?: Maybe<Array<Maybe<DeviceAttributeValue>>>;
  updateDeviceCapabilities?: Maybe<Array<Maybe<DeviceCapability>>>;
  updateDeviceConfigurations?: Maybe<Array<Maybe<DeviceConfiguration>>>;
  updateDeviceFirmwareConfigurationSchemas?: Maybe<Array<Maybe<DeviceFirmwareConfigurationSchema>>>;
  updateDeviceFirmwareReleaseTypes?: Maybe<Array<Maybe<DeviceFirmwareReleaseType>>>;
  updateDeviceFirmwares?: Maybe<Array<Maybe<DeviceFirmware>>>;
  updateDeviceHeartbeatCurrentValues?: Maybe<Array<Maybe<DeviceHeartbeatCurrentValue>>>;
  updateDeviceHeartbeatHistories?: Maybe<Array<Maybe<DeviceHeartbeatHistory>>>;
  updateDeviceModelAttributeSets?: Maybe<Array<Maybe<DeviceModelAttributeSet>>>;
  updateDeviceModelCapabilities?: Maybe<Array<Maybe<DeviceModelCapability>>>;
  updateDeviceModels?: Maybe<Array<Maybe<DeviceModel>>>;
  updateDeviceModelVersions?: Maybe<Array<Maybe<DeviceModelVersion>>>;
  updateDeviceNotifications?: Maybe<Array<Maybe<DeviceNotification>>>;
  updateDevices?: Maybe<Array<Maybe<Device>>>;
  updateDeviceTypes?: Maybe<Array<Maybe<DeviceType>>>;
  updateGatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  updateGroupEntities?: Maybe<Array<Maybe<GroupEntity>>>;
  updateGroupEntityFilterDataTypes?: Maybe<Array<Maybe<GroupEntityFilterDataType>>>;
  updateGroupEntityFilters?: Maybe<Array<Maybe<GroupEntityFilter>>>;
  updateGroupEntityFilterUiTypes?: Maybe<Array<Maybe<GroupEntityFilterUiType>>>;
  updateGroupOperators?: Maybe<Array<Maybe<GroupOperator>>>;
  updateGroups?: Maybe<Array<Maybe<Group>>>;
  updateMappingAssetTags?: Maybe<Array<Maybe<MappingAssetTag>>>;
  updateMappingAttributeAssetTypeAttributeSets?: Maybe<Array<MappingAttributeAssetTypeAttributeSet>>;
  updateMappingAttributeDeviceModelAttributeSets?: Maybe<Array<Maybe<MappingAttributeDeviceModelAttributeSet>>>;
  updateMappingDeviceConfigurationDevices?: Maybe<Array<Maybe<MappingDeviceConfigurationDevice>>>;
  updateMappingDeviceConfigurationDeviceStatuses?: Maybe<Array<Maybe<MappingDeviceConfigurationDeviceStatus>>>;
  updateMappingDeviceFirmwareDevices?: Maybe<Array<Maybe<MappingDeviceFirmwareDevice>>>;
  updateMappingDeviceFirmwareDeviceStatuses?: Maybe<Array<Maybe<MappingDeviceFirmwareDeviceStatus>>>;
  updateMappingDeviceFirmwareDeviceTypes?: Maybe<Array<Maybe<MappingDeviceFirmwareDeviceType>>>;
  updateMappingDeviceNotifications?: Maybe<Array<Maybe<MappingDeviceNotification>>>;
  updateMappingDeviceTags?: Maybe<Array<Maybe<MappingDeviceTag>>>;
  updateMappingGroupEntityFilterGroupOperators?: Maybe<Array<Maybe<MappingGroupEntityFilterGroupOperator>>>;
  updateMappingGroupEntityGroupEntityFilters?: Maybe<Array<Maybe<MappingGroupEntityGroupEntityFilter>>>;
  updateMappingNotificationScopeNotificationOperators?: Maybe<Array<Maybe<MappingNotificationScopeNotificationOperator>>>;
  updateMappingNotificationUsers?: Maybe<Array<Maybe<MappingNotificationUser>>>;
  updateMappingRoleScopes?: Maybe<Array<Maybe<MappingRoleScope>>>;
  updateMappingRoleUsers?: Maybe<Array<Maybe<MappingRoleUser>>>;
  updateMappingUserScopes?: Maybe<Array<Maybe<MappingUserScope>>>;
  updateNotificationDefinitions?: Maybe<Array<Maybe<NotificationDefinition>>>;
  updateNotificationOperators?: Maybe<Array<Maybe<NotificationOperator>>>;
  updateNotifications?: Maybe<Array<Maybe<Notification>>>;
  updateNotificationScopes?: Maybe<Array<NotificationScope>>;
  updateRoles?: Maybe<Array<Maybe<Role>>>;
  updateRuleActions?: Maybe<Array<Maybe<RuleAction>>>;
  updateRuleReferences?: Maybe<Array<Maybe<RuleReference>>>;
  updateRuleReferenceUnits?: Maybe<Array<Maybe<RuleReferenceUnit>>>;
  updateRuleResultTypes?: Maybe<Array<RuleResultType>>;
  updateRules?: Maybe<Array<Maybe<Rule>>>;
  updateScopes?: Maybe<Array<Maybe<Scope>>>;
  updateSensorConfigurations?: Maybe<CommandStatus>;
  updateSensorMeasurementCurrentValues?: Maybe<Array<Maybe<SensorMeasurementCurrentValue>>>;
  updateSensorMeasurementHistories?: Maybe<Array<SensorMeasurementHistory>>;
  updateStorageAreas?: Maybe<Array<Maybe<StorageArea>>>;
  updateStorageAreaTypes?: Maybe<Array<StorageAreaType>>;
  updateTags?: Maybe<Array<Maybe<Tag>>>;
  updateTenants?: Maybe<Array<Maybe<Tenant>>>;
  updateTranslations?: Maybe<Array<Maybe<Translation>>>;
  updateUnits?: Maybe<Array<Unit>>;
  updateUserConfigurations?: Maybe<Array<UserConfiguration>>;
  updateUsers?: Maybe<Array<Maybe<User>>>;
};


export type MutationCreateAssetAttributeDataTypesArgs = {
  assetAttributeDataTypes?: InputMaybe<Array<InputMaybe<AssetAttributeDataTypeCreateType>>>;
};


export type MutationCreateAssetAttributesArgs = {
  assetAttributes?: InputMaybe<Array<InputMaybe<AssetAttributeCreateType>>>;
};


export type MutationCreateAssetAttributeUnitsArgs = {
  assetAttributeUnits?: InputMaybe<Array<InputMaybe<AssetAttributeUnitCreateType>>>;
};


export type MutationCreateAssetAttributeValuesArgs = {
  assetAttributeValues?: InputMaybe<Array<InputMaybe<AssetAttributeValueCreateType>>>;
};


export type MutationCreateAssetDevicePairingArgs = {
  pairings?: InputMaybe<Array<InputMaybe<PairingInput>>>;
};


export type MutationCreateAssetDevicesArgs = {
  assetDevices?: InputMaybe<Array<InputMaybe<AssetDeviceCreateType>>>;
};


export type MutationCreateAssetImagesArgs = {
  assetImages?: InputMaybe<Array<InputMaybe<AssetImageCreateType>>>;
};


export type MutationCreateAssetMasterDatasArgs = {
  assetMasterDatas?: InputMaybe<Array<InputMaybe<AssetMasterDataCreateType>>>;
};


export type MutationCreateAssetMasterDataSourcesArgs = {
  assetMasterDataSources?: InputMaybe<Array<InputMaybe<AssetMasterDataSourceCreateType>>>;
};


export type MutationCreateAssetNotesArgs = {
  assetNotes?: InputMaybe<Array<InputMaybe<AssetNoteCreateType>>>;
};


export type MutationCreateAssetsArgs = {
  assets?: InputMaybe<Array<InputMaybe<AssetCreateType>>>;
};


export type MutationCreateAssetSourcesArgs = {
  assetSources?: InputMaybe<Array<InputMaybe<AssetSourceCreateType>>>;
};


export type MutationCreateAssetTagsArgs = {
  assetTags?: InputMaybe<Array<InputMaybe<AssetTagCreateType>>>;
};


export type MutationCreateAssetTypeAttributeSetsArgs = {
  assetTypeAttributeSets?: InputMaybe<Array<InputMaybe<AssetTypeAttributeSetCreateType>>>;
};


export type MutationCreateCapabilitiesArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CapabilityCreateType>>>;
};


export type MutationCreateDeviceAttributesArgs = {
  deviceAttributes?: InputMaybe<Array<InputMaybe<DeviceAttributeCreateType>>>;
};


export type MutationCreateDeviceAttributeValuesArgs = {
  deviceAttributeValues?: InputMaybe<Array<InputMaybe<DeviceAttributeValueCreateType>>>;
};


export type MutationCreateDeviceCapabilitiesArgs = {
  deviceCapabilities?: InputMaybe<Array<InputMaybe<DeviceCapabilityCreateType>>>;
};


export type MutationCreateDeviceConfigurationsArgs = {
  deviceConfigurations?: InputMaybe<Array<InputMaybe<DeviceConfigurationCreateType>>>;
};


export type MutationCreateDeviceFirmwareConfigurationSchemasArgs = {
  deviceFirmwareConfigurationSchemas?: InputMaybe<Array<InputMaybe<DeviceFirmwareConfigurationSchemaCreateType>>>;
};


export type MutationCreateDeviceFirmwareReleaseTypesArgs = {
  deviceFirmwareReleaseTypes?: InputMaybe<Array<InputMaybe<DeviceFirmwareReleaseTypeCreateType>>>;
};


export type MutationCreateDeviceFirmwaresArgs = {
  deviceFirmwares?: InputMaybe<Array<InputMaybe<DeviceFirmwareCreateType>>>;
};


export type MutationCreateDeviceHeartbeatCurrentValuesArgs = {
  deviceHeartbeatCurrentValues?: InputMaybe<Array<InputMaybe<DeviceHeartbeatCurrentValueInput>>>;
};


export type MutationCreateDeviceHeartbeatHistoriesArgs = {
  deviceHeartbeatHistories?: InputMaybe<Array<InputMaybe<DeviceHeartbeatHistoryInput>>>;
};


export type MutationCreateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets?: InputMaybe<Array<InputMaybe<DeviceModelAttributeSetCreateType>>>;
};


export type MutationCreateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityCreateType>>>;
};


export type MutationCreateDeviceModelsArgs = {
  deviceModels?: InputMaybe<Array<InputMaybe<DeviceModelCreateType>>>;
};


export type MutationCreateDeviceModelVersionsArgs = {
  deviceModelVersions?: InputMaybe<Array<InputMaybe<DeviceModelVersionCreateType>>>;
};


export type MutationCreateDeviceNotificationsArgs = {
  deviceNotifications?: InputMaybe<Array<InputMaybe<DeviceNotificationCreateType>>>;
};


export type MutationCreateDevicesArgs = {
  devices?: InputMaybe<Array<InputMaybe<DeviceCreateType>>>;
};


export type MutationCreateDeviceTypesArgs = {
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceTypeCreateType>>>;
};


export type MutationCreateExcavatorSetupArgs = {
  excavatorSetup?: InputMaybe<ExcavatorSetupInput>;
};


export type MutationCreateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections?: InputMaybe<Array<InputMaybe<GatewayDeviceConnectionCreateType>>>;
};


export type MutationCreateGroupEntitiesArgs = {
  groupEntities?: InputMaybe<Array<InputMaybe<GroupEntityCreateType>>>;
};


export type MutationCreateGroupEntityFilterDataTypesArgs = {
  groupEntityFilterDataTypes?: InputMaybe<Array<InputMaybe<GroupEntityFilterDataTypeCreateType>>>;
};


export type MutationCreateGroupEntityFiltersArgs = {
  groupEntityFilters?: InputMaybe<Array<InputMaybe<GroupEntityFilterCreateType>>>;
};


export type MutationCreateGroupEntityFilterUiTypesArgs = {
  groupEntityFilterUiTypes?: InputMaybe<Array<InputMaybe<GroupEntityFilterUiTypeCreateType>>>;
};


export type MutationCreateGroupOperatorsArgs = {
  groupOperators?: InputMaybe<Array<InputMaybe<GroupOperatorCreateType>>>;
};


export type MutationCreateGroupsArgs = {
  groups?: InputMaybe<Array<InputMaybe<GroupCreateType>>>;
};


export type MutationCreateImageUploadArgs = {
  assetId: Scalars['UUID'];
  image?: InputMaybe<Scalars['String']>;
};


export type MutationCreateLogEntryArgs = {
  logEntries?: InputMaybe<Array<InputMaybe<LogInput>>>;
};


export type MutationCreateMappingAssetTagsArgs = {
  mappingAssetTags?: InputMaybe<Array<InputMaybe<MappingAssetTagCreateType>>>;
};


export type MutationCreateMappingAttributeAssetTypeAttributeSetsArgs = {
  mappingAttributeAssetTypeAttributeSets: Array<MappingAttributeAssetTypeAttributeSetCreateType>;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Array<InputMaybe<MappingAttributeDeviceModelAttributeSetCreateType>>>;
};


export type MutationCreateMappingDeviceConfigurationDevicesArgs = {
  mappingDeviceConfigurationDevices?: InputMaybe<Array<InputMaybe<MappingDeviceConfigurationDeviceCreateType>>>;
};


export type MutationCreateMappingDeviceConfigurationDeviceStatusesArgs = {
  mappingDeviceConfigurationDeviceStatuses?: InputMaybe<Array<InputMaybe<MappingDeviceConfigurationDeviceStatusCreateType>>>;
};


export type MutationCreateMappingDeviceFirmwareDevicesArgs = {
  mappingDeviceFirmwareDevices?: InputMaybe<Array<InputMaybe<MappingDeviceFirmwareDeviceCreateType>>>;
};


export type MutationCreateMappingDeviceFirmwareDeviceStatusesArgs = {
  mappingDeviceFirmwareDeviceStatuses?: InputMaybe<Array<InputMaybe<MappingDeviceFirmwareDeviceStatusCreateType>>>;
};


export type MutationCreateMappingDeviceFirmwareDeviceTypesArgs = {
  mappingDeviceFirmwareDeviceTypes?: InputMaybe<Array<InputMaybe<MappingDeviceFirmwareDeviceTypeCreateType>>>;
};


export type MutationCreateMappingDeviceNotificationsArgs = {
  mappingDeviceNotifications?: InputMaybe<Array<InputMaybe<MappingDeviceNotificationCreateType>>>;
};


export type MutationCreateMappingDeviceTagsArgs = {
  mappingDeviceTags?: InputMaybe<Array<InputMaybe<MappingDeviceTagCreateType>>>;
};


export type MutationCreateMappingGroupEntityFilterGroupOperatorsArgs = {
  mappingGroupEntityFilterGroupOperators?: InputMaybe<Array<InputMaybe<MappingGroupEntityFilterGroupOperatorCreateType>>>;
};


export type MutationCreateMappingGroupEntityGroupEntityFiltersArgs = {
  mappingGroupEntityGroupEntityFilters?: InputMaybe<Array<InputMaybe<MappingGroupEntityGroupEntityFilterCreateType>>>;
};


export type MutationCreateMappingNotificationScopeNotificationOperatorsArgs = {
  mappingNotificationScopeNotificationOperators?: InputMaybe<Array<InputMaybe<MappingNotificationScopeNotificationOperatorCreateType>>>;
};


export type MutationCreateMappingNotificationUsersArgs = {
  mappingNotificationUsers?: InputMaybe<Array<InputMaybe<MappingNotificationUserCreateType>>>;
};


export type MutationCreateMappingRoleScopesArgs = {
  mappingRoleScopes?: InputMaybe<Array<InputMaybe<MappingRoleScopeCreateType>>>;
};


export type MutationCreateMappingRoleUsersArgs = {
  mappingRoleUsers?: InputMaybe<Array<InputMaybe<MappingRoleUserCreateType>>>;
};


export type MutationCreateMappingUserScopesArgs = {
  mappingUserScopes?: InputMaybe<Array<InputMaybe<MappingUserScopeCreateType>>>;
};


export type MutationCreateNotificationDefinitionsArgs = {
  notificationDefinitions?: InputMaybe<Array<InputMaybe<NotificationDefinitionCreateType>>>;
};


export type MutationCreateNotificationOperatorsArgs = {
  notificationOperators?: InputMaybe<Array<InputMaybe<NotificationOperatorCreateType>>>;
};


export type MutationCreateNotificationsArgs = {
  notifications?: InputMaybe<Array<InputMaybe<NotificationCreateType>>>;
};


export type MutationCreateNotificationScopesArgs = {
  notificationScopes: Array<NotificationScopeCreateType>;
};


export type MutationCreateRolesArgs = {
  roles?: InputMaybe<Array<InputMaybe<RoleCreateType>>>;
};


export type MutationCreateRuleActionsArgs = {
  ruleActions?: InputMaybe<Array<InputMaybe<RuleActionCreateType>>>;
};


export type MutationCreateRuleReferencesArgs = {
  ruleReferences?: InputMaybe<Array<InputMaybe<RuleReferenceCreateType>>>;
};


export type MutationCreateRuleReferenceUnitsArgs = {
  ruleReferenceUnits?: InputMaybe<Array<InputMaybe<RuleReferenceUnitCreateType>>>;
};


export type MutationCreateRuleResultTypesArgs = {
  ruleResultTypes: Array<RuleResultTypeCreateType>;
};


export type MutationCreateRulesArgs = {
  rules?: InputMaybe<Array<InputMaybe<RuleCreateType>>>;
};


export type MutationCreateScopesArgs = {
  scopes?: InputMaybe<Array<InputMaybe<ScopeCreateType>>>;
};


export type MutationCreateSensorMeasurementCurrentValuesArgs = {
  sensorMeasurementCurrentValues?: InputMaybe<Array<InputMaybe<SensorMeasurementCurrentValueCreateType>>>;
};


export type MutationCreateSensorMeasurementHistoriesArgs = {
  sensorMeasurementHistories: Array<SensorMeasurementHistoryCreateType>;
};


export type MutationCreateStorageAreasArgs = {
  storageAreas?: InputMaybe<Array<InputMaybe<StorageAreaCreateType>>>;
};


export type MutationCreateStorageAreaTypesArgs = {
  storageAreaTypes: Array<StorageAreaTypeCreateType>;
};


export type MutationCreateTagsArgs = {
  tags?: InputMaybe<Array<InputMaybe<TagCreateType>>>;
};


export type MutationCreateTenantsArgs = {
  tenants?: InputMaybe<Array<InputMaybe<TenantCreateType>>>;
};


export type MutationCreateTranslationsArgs = {
  translations?: InputMaybe<Array<InputMaybe<TranslationCreateType>>>;
};


export type MutationCreateUnitsArgs = {
  units: Array<UnitCreateType>;
};


export type MutationCreateUserConfigurationsArgs = {
  userConfigurations: Array<UserConfigurationCreateType>;
};


export type MutationCreateUsersArgs = {
  users?: InputMaybe<Array<InputMaybe<UserCreateType>>>;
};


export type MutationDeleteAssetAttributeDataTypesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetAttributesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetAttributeUnitsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetAttributeValuesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetDevicesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetImagesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetMasterDatasArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetMasterDataSourcesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetNotesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetSourcesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetTagsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteAssetTypeAttributeSetsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteCapabilitiesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceAttributesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceAttributeValuesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceCapabilitiesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceConfigurationsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceFirmwareConfigurationSchemasArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceFirmwareReleaseTypesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceFirmwaresArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceHeartbeatCurrentValuesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceHeartbeatHistoriesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceModelAttributeSetsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceModelCapabilitiesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceModelsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceModelVersionsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceNotificationsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDevicesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceTypesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteGatewayDeviceConnectionsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteGroupEntitiesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteGroupEntityFilterDataTypesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteGroupEntityFiltersArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteGroupEntityFilterUiTypesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteGroupOperatorsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteGroupsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingAssetTagsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingAttributeAssetTypeAttributeSetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingDeviceConfigurationDevicesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingDeviceConfigurationDeviceStatusesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingDeviceFirmwareDevicesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingDeviceFirmwareDeviceStatusesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingDeviceFirmwareDeviceTypesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingDeviceNotificationsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingDeviceTagsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingGroupEntityFilterGroupOperatorsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingGroupEntityGroupEntityFiltersArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingNotificationScopeNotificationOperatorsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingNotificationUsersArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingRoleScopesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingRoleUsersArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteMappingUserScopesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteNotificationDefinitionsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteNotificationOperatorsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteNotificationsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteNotificationScopesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteRolesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteRuleActionsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteRuleReferencesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteRuleReferenceUnitsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteRuleResultTypesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteRulesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteScopesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteSensorMeasurementCurrentValuesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteSensorMeasurementHistoriesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteStorageAreasArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteStorageAreaTypesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteTagsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteTenantsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteTranslationsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteUnitsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteUserConfigurationsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteUsersArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationSendNewsletterArgs = {
  actualValue?: InputMaybe<Scalars['String']>;
  alertDatetime: Scalars['DateTime'];
  deviceId: Scalars['UUID'];
  eventOccurred: Scalars['DateTime'];
  notificationDefinitionId: Scalars['UUID'];
};


export type MutationUnpairingAssetDeviceArgs = {
  assetCustomUniqueIdentifier?: InputMaybe<Scalars['String']>;
  deviceSerialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAssetAttributeDataTypesArgs = {
  assetAttributeDataTypes?: InputMaybe<Array<InputMaybe<AssetAttributeDataTypeUpdateType>>>;
  setNull?: InputMaybe<AssetAttributeDataTypeSetNullInputType>;
};


export type MutationUpdateAssetAttributesArgs = {
  assetAttributes?: InputMaybe<Array<InputMaybe<AssetAttributeUpdateType>>>;
  setNull?: InputMaybe<AssetAttributeSetNullInputType>;
};


export type MutationUpdateAssetAttributeUnitsArgs = {
  assetAttributeUnits?: InputMaybe<Array<InputMaybe<AssetAttributeUnitUpdateType>>>;
  setNull?: InputMaybe<AssetAttributeUnitSetNullInputType>;
};


export type MutationUpdateAssetAttributeValuesArgs = {
  assetAttributeValues?: InputMaybe<Array<InputMaybe<AssetAttributeValueUpdateType>>>;
  setNull?: InputMaybe<AssetAttributeValueSetNullInputType>;
};


export type MutationUpdateAssetDevicePairingArgs = {
  existingDeviceSerialNumber?: InputMaybe<Scalars['String']>;
  replacementDeviceSerialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAssetDevicesArgs = {
  assetDevices?: InputMaybe<Array<InputMaybe<AssetDeviceUpdateType>>>;
  setNull?: InputMaybe<AssetDeviceSetNullInputType>;
};


export type MutationUpdateAssetImagesArgs = {
  assetImages?: InputMaybe<Array<InputMaybe<AssetImageUpdateType>>>;
  setNull?: InputMaybe<AssetImageSetNullInputType>;
};


export type MutationUpdateAssetMasterDatasArgs = {
  assetMasterDatas?: InputMaybe<Array<InputMaybe<AssetMasterDataUpdateType>>>;
  setNull?: InputMaybe<AssetMasterDataSetNullInputType>;
};


export type MutationUpdateAssetMasterDataSourcesArgs = {
  assetMasterDataSources?: InputMaybe<Array<InputMaybe<AssetMasterDataSourceUpdateType>>>;
  setNull?: InputMaybe<AssetMasterDataSourceSetNullInputType>;
};


export type MutationUpdateAssetNotesArgs = {
  assetNotes?: InputMaybe<Array<InputMaybe<AssetNoteUpdateType>>>;
  setNull?: InputMaybe<AssetNoteSetNullInputType>;
};


export type MutationUpdateAssetsArgs = {
  assets?: InputMaybe<Array<InputMaybe<AssetUpdateType>>>;
  setNull?: InputMaybe<AssetSetNullInputType>;
};


export type MutationUpdateAssetSourcesArgs = {
  assetSources?: InputMaybe<Array<InputMaybe<AssetSourceUpdateType>>>;
  setNull?: InputMaybe<AssetSourceSetNullInputType>;
};


export type MutationUpdateAssetTagsArgs = {
  assetTags?: InputMaybe<Array<InputMaybe<AssetTagUpdateType>>>;
  setNull?: InputMaybe<AssetTagSetNullInputType>;
};


export type MutationUpdateAssetTypeAttributeSetsArgs = {
  assetTypeAttributeSets?: InputMaybe<Array<InputMaybe<AssetTypeAttributeSetUpdateType>>>;
  setNull?: InputMaybe<AssetTypeAttributeSetSetNullInputType>;
};


export type MutationUpdateCapabilitiesArgs = {
  capabilities?: InputMaybe<Array<InputMaybe<CapabilityUpdateType>>>;
  setNull?: InputMaybe<CapabilitySetNullInputType>;
};


export type MutationUpdateDeviceAttributesArgs = {
  deviceAttributes?: InputMaybe<Array<InputMaybe<DeviceAttributeUpdateType>>>;
  setNull?: InputMaybe<DeviceAttributeSetNullInputType>;
};


export type MutationUpdateDeviceAttributeValuesArgs = {
  deviceAttributeValues?: InputMaybe<Array<InputMaybe<DeviceAttributeValueUpdateType>>>;
  setNull?: InputMaybe<DeviceAttributeValueSetNullInputType>;
};


export type MutationUpdateDeviceCapabilitiesArgs = {
  deviceCapabilities?: InputMaybe<Array<InputMaybe<DeviceCapabilityUpdateType>>>;
  setNull?: InputMaybe<DeviceCapabilitySetNullInputType>;
};


export type MutationUpdateDeviceConfigurationsArgs = {
  deviceConfigurations?: InputMaybe<Array<InputMaybe<DeviceConfigurationUpdateType>>>;
  setNull?: InputMaybe<DeviceConfigurationSetNullInputType>;
};


export type MutationUpdateDeviceFirmwareConfigurationSchemasArgs = {
  deviceFirmwareConfigurationSchemas?: InputMaybe<Array<InputMaybe<DeviceFirmwareConfigurationSchemaUpdateType>>>;
  setNull?: InputMaybe<DeviceFirmwareConfigurationSchemaSetNullInputType>;
};


export type MutationUpdateDeviceFirmwareReleaseTypesArgs = {
  deviceFirmwareReleaseTypes?: InputMaybe<Array<InputMaybe<DeviceFirmwareReleaseTypeUpdateType>>>;
  setNull?: InputMaybe<DeviceFirmwareReleaseTypeSetNullInputType>;
};


export type MutationUpdateDeviceFirmwaresArgs = {
  deviceFirmwares?: InputMaybe<Array<InputMaybe<DeviceFirmwareUpdateType>>>;
  setNull?: InputMaybe<DeviceFirmwareSetNullInputType>;
};


export type MutationUpdateDeviceHeartbeatCurrentValuesArgs = {
  deviceHeartbeatCurrentValues?: InputMaybe<Array<InputMaybe<DeviceHeartbeatCurrentValueUpdateInput>>>;
  setNull?: InputMaybe<DeviceHeartbeatCurrentValueSetNullInput>;
};


export type MutationUpdateDeviceHeartbeatHistoriesArgs = {
  deviceHeartbeatHistories?: InputMaybe<Array<InputMaybe<DeviceHeartbeatHistoryUpdateInput>>>;
  setNull?: InputMaybe<DeviceHeartbeatHistorySetNullInput>;
};


export type MutationUpdateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets?: InputMaybe<Array<InputMaybe<DeviceModelAttributeSetUpdateType>>>;
  setNull?: InputMaybe<DeviceModelAttributeSetSetNullInputType>;
};


export type MutationUpdateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityUpdateType>>>;
  setNull?: InputMaybe<DeviceModelCapabilitySetNullInputType>;
};


export type MutationUpdateDeviceModelsArgs = {
  deviceModels?: InputMaybe<Array<InputMaybe<DeviceModelUpdateType>>>;
  setNull?: InputMaybe<DeviceModelSetNullInputType>;
};


export type MutationUpdateDeviceModelVersionsArgs = {
  deviceModelVersions?: InputMaybe<Array<InputMaybe<DeviceModelVersionUpdateType>>>;
  setNull?: InputMaybe<DeviceModelVersionSetNullInputType>;
};


export type MutationUpdateDeviceNotificationsArgs = {
  deviceNotifications?: InputMaybe<Array<InputMaybe<DeviceNotificationUpdateType>>>;
  setNull?: InputMaybe<DeviceNotificationSetNullInputType>;
};


export type MutationUpdateDevicesArgs = {
  devices?: InputMaybe<Array<InputMaybe<DeviceUpdateType>>>;
  setNull?: InputMaybe<DeviceSetNullInputType>;
};


export type MutationUpdateDeviceTypesArgs = {
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceTypeUpdateType>>>;
  setNull?: InputMaybe<DeviceTypeSetNullInputType>;
};


export type MutationUpdateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections?: InputMaybe<Array<InputMaybe<GatewayDeviceConnectionUpdateType>>>;
  setNull?: InputMaybe<GatewayDeviceConnectionSetNullInputType>;
};


export type MutationUpdateGroupEntitiesArgs = {
  groupEntities?: InputMaybe<Array<InputMaybe<GroupEntityUpdateType>>>;
  setNull?: InputMaybe<GroupEntitySetNullInputType>;
};


export type MutationUpdateGroupEntityFilterDataTypesArgs = {
  groupEntityFilterDataTypes?: InputMaybe<Array<InputMaybe<GroupEntityFilterDataTypeUpdateType>>>;
  setNull?: InputMaybe<GroupEntityFilterDataTypeSetNullInputType>;
};


export type MutationUpdateGroupEntityFiltersArgs = {
  groupEntityFilters?: InputMaybe<Array<InputMaybe<GroupEntityFilterUpdateType>>>;
  setNull?: InputMaybe<GroupEntityFilterSetNullInputType>;
};


export type MutationUpdateGroupEntityFilterUiTypesArgs = {
  groupEntityFilterUiTypes?: InputMaybe<Array<InputMaybe<GroupEntityFilterUiTypeUpdateType>>>;
  setNull?: InputMaybe<GroupEntityFilterUiTypeSetNullInputType>;
};


export type MutationUpdateGroupOperatorsArgs = {
  groupOperators?: InputMaybe<Array<InputMaybe<GroupOperatorUpdateType>>>;
  setNull?: InputMaybe<GroupOperatorSetNullInputType>;
};


export type MutationUpdateGroupsArgs = {
  groups?: InputMaybe<Array<InputMaybe<GroupUpdateType>>>;
  setNull?: InputMaybe<GroupSetNullInputType>;
};


export type MutationUpdateMappingAssetTagsArgs = {
  mappingAssetTags?: InputMaybe<Array<InputMaybe<MappingAssetTagUpdateType>>>;
  setNull?: InputMaybe<MappingAssetTagSetNullInputType>;
};


export type MutationUpdateMappingAttributeAssetTypeAttributeSetsArgs = {
  mappingAttributeAssetTypeAttributeSets: Array<MappingAttributeAssetTypeAttributeSetUpdateType>;
  setNull?: InputMaybe<MappingAttributeAssetTypeAttributeSetSetNullInputType>;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Array<InputMaybe<MappingAttributeDeviceModelAttributeSetUpdateType>>>;
  setNull?: InputMaybe<MappingAttributeDeviceModelAttributeSetSetNullInputType>;
};


export type MutationUpdateMappingDeviceConfigurationDevicesArgs = {
  mappingDeviceConfigurationDevices?: InputMaybe<Array<InputMaybe<MappingDeviceConfigurationDeviceUpdateType>>>;
  setNull?: InputMaybe<MappingDeviceConfigurationDeviceSetNullInputType>;
};


export type MutationUpdateMappingDeviceConfigurationDeviceStatusesArgs = {
  mappingDeviceConfigurationDeviceStatuses?: InputMaybe<Array<InputMaybe<MappingDeviceConfigurationDeviceStatusUpdateType>>>;
  setNull?: InputMaybe<MappingDeviceConfigurationDeviceStatusSetNullInputType>;
};


export type MutationUpdateMappingDeviceFirmwareDevicesArgs = {
  mappingDeviceFirmwareDevices?: InputMaybe<Array<InputMaybe<MappingDeviceFirmwareDeviceUpdateType>>>;
  setNull?: InputMaybe<MappingDeviceFirmwareDeviceSetNullInputType>;
};


export type MutationUpdateMappingDeviceFirmwareDeviceStatusesArgs = {
  mappingDeviceFirmwareDeviceStatuses?: InputMaybe<Array<InputMaybe<MappingDeviceFirmwareDeviceStatusUpdateType>>>;
  setNull?: InputMaybe<MappingDeviceFirmwareDeviceStatusSetNullInputType>;
};


export type MutationUpdateMappingDeviceFirmwareDeviceTypesArgs = {
  mappingDeviceFirmwareDeviceTypes?: InputMaybe<Array<InputMaybe<MappingDeviceFirmwareDeviceTypeUpdateType>>>;
  setNull?: InputMaybe<MappingDeviceFirmwareDeviceTypeSetNullInputType>;
};


export type MutationUpdateMappingDeviceNotificationsArgs = {
  mappingDeviceNotifications?: InputMaybe<Array<InputMaybe<MappingDeviceNotificationUpdateType>>>;
  setNull?: InputMaybe<MappingDeviceNotificationSetNullInputType>;
};


export type MutationUpdateMappingDeviceTagsArgs = {
  mappingDeviceTags?: InputMaybe<Array<InputMaybe<MappingDeviceTagUpdateType>>>;
  setNull?: InputMaybe<MappingDeviceTagSetNullInputType>;
};


export type MutationUpdateMappingGroupEntityFilterGroupOperatorsArgs = {
  mappingGroupEntityFilterGroupOperators?: InputMaybe<Array<InputMaybe<MappingGroupEntityFilterGroupOperatorUpdateType>>>;
  setNull?: InputMaybe<MappingGroupEntityFilterGroupOperatorSetNullInputType>;
};


export type MutationUpdateMappingGroupEntityGroupEntityFiltersArgs = {
  mappingGroupEntityGroupEntityFilters?: InputMaybe<Array<InputMaybe<MappingGroupEntityGroupEntityFilterUpdateType>>>;
  setNull?: InputMaybe<MappingGroupEntityGroupEntityFilterSetNullInputType>;
};


export type MutationUpdateMappingNotificationScopeNotificationOperatorsArgs = {
  mappingNotificationScopeNotificationOperators?: InputMaybe<Array<InputMaybe<MappingNotificationScopeNotificationOperatorUpdateType>>>;
  setNull?: InputMaybe<MappingNotificationScopeNotificationOperatorSetNullInputType>;
};


export type MutationUpdateMappingNotificationUsersArgs = {
  mappingNotificationUsers?: InputMaybe<Array<InputMaybe<MappingNotificationUserUpdateType>>>;
  setNull?: InputMaybe<MappingNotificationUserSetNullInputType>;
};


export type MutationUpdateMappingRoleScopesArgs = {
  mappingRoleScopes?: InputMaybe<Array<InputMaybe<MappingRoleScopeUpdateType>>>;
  setNull?: InputMaybe<MappingRoleScopeSetNullInputType>;
};


export type MutationUpdateMappingRoleUsersArgs = {
  mappingRoleUsers?: InputMaybe<Array<InputMaybe<MappingRoleUserUpdateType>>>;
  setNull?: InputMaybe<MappingRoleUserSetNullInputType>;
};


export type MutationUpdateMappingUserScopesArgs = {
  mappingUserScopes?: InputMaybe<Array<InputMaybe<MappingUserScopeUpdateType>>>;
  setNull?: InputMaybe<MappingUserScopeSetNullInputType>;
};


export type MutationUpdateNotificationDefinitionsArgs = {
  notificationDefinitions?: InputMaybe<Array<InputMaybe<NotificationDefinitionUpdateType>>>;
  setNull?: InputMaybe<NotificationDefinitionSetNullInputType>;
};


export type MutationUpdateNotificationOperatorsArgs = {
  notificationOperators?: InputMaybe<Array<InputMaybe<NotificationOperatorUpdateType>>>;
  setNull?: InputMaybe<NotificationOperatorSetNullInputType>;
};


export type MutationUpdateNotificationsArgs = {
  notifications?: InputMaybe<Array<InputMaybe<NotificationUpdateType>>>;
  setNull?: InputMaybe<NotificationSetNullInputType>;
};


export type MutationUpdateNotificationScopesArgs = {
  notificationScopes: Array<NotificationScopeUpdateType>;
  setNull?: InputMaybe<NotificationScopeSetNullInputType>;
};


export type MutationUpdateRolesArgs = {
  roles?: InputMaybe<Array<InputMaybe<RoleUpdateType>>>;
  setNull?: InputMaybe<RoleSetNullInputType>;
};


export type MutationUpdateRuleActionsArgs = {
  ruleActions?: InputMaybe<Array<InputMaybe<RuleActionUpdateType>>>;
  setNull?: InputMaybe<RuleActionSetNullInputType>;
};


export type MutationUpdateRuleReferencesArgs = {
  ruleReferences?: InputMaybe<Array<InputMaybe<RuleReferenceUpdateType>>>;
  setNull?: InputMaybe<RuleReferenceSetNullInputType>;
};


export type MutationUpdateRuleReferenceUnitsArgs = {
  ruleReferenceUnits?: InputMaybe<Array<InputMaybe<RuleReferenceUnitUpdateType>>>;
  setNull?: InputMaybe<RuleReferenceUnitSetNullInputType>;
};


export type MutationUpdateRuleResultTypesArgs = {
  ruleResultTypes: Array<RuleResultTypeUpdateType>;
  setNull?: InputMaybe<RuleResultTypeSetNullInputType>;
};


export type MutationUpdateRulesArgs = {
  rules?: InputMaybe<Array<InputMaybe<RuleUpdateType>>>;
  setNull?: InputMaybe<RuleSetNullInputType>;
};


export type MutationUpdateScopesArgs = {
  scopes?: InputMaybe<Array<InputMaybe<ScopeUpdateType>>>;
  setNull?: InputMaybe<ScopeSetNullInputType>;
};


export type MutationUpdateSensorConfigurationsArgs = {
  deviceIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationUpdateSensorMeasurementCurrentValuesArgs = {
  sensorMeasurementCurrentValues?: InputMaybe<Array<InputMaybe<SensorMeasurementCurrentValueUpdateType>>>;
  setNull?: InputMaybe<SensorMeasurementCurrentValueSetNullInputType>;
};


export type MutationUpdateSensorMeasurementHistoriesArgs = {
  sensorMeasurementHistories: Array<SensorMeasurementHistoryUpdateType>;
  setNull?: InputMaybe<SensorMeasurementHistorySetNullInputType>;
};


export type MutationUpdateStorageAreasArgs = {
  setNull?: InputMaybe<StorageAreaSetNullInputType>;
  storageAreas?: InputMaybe<Array<InputMaybe<StorageAreaUpdateType>>>;
};


export type MutationUpdateStorageAreaTypesArgs = {
  setNull?: InputMaybe<StorageAreaTypeSetNullInputType>;
  storageAreaTypes: Array<StorageAreaTypeUpdateType>;
};


export type MutationUpdateTagsArgs = {
  setNull?: InputMaybe<TagSetNullInputType>;
  tags?: InputMaybe<Array<InputMaybe<TagUpdateType>>>;
};


export type MutationUpdateTenantsArgs = {
  setNull?: InputMaybe<TenantSetNullInputType>;
  tenants?: InputMaybe<Array<InputMaybe<TenantUpdateType>>>;
};


export type MutationUpdateTranslationsArgs = {
  setNull?: InputMaybe<TranslationSetNullInputType>;
  translations?: InputMaybe<Array<InputMaybe<TranslationUpdateType>>>;
};


export type MutationUpdateUnitsArgs = {
  setNull?: InputMaybe<UnitSetNullInputType>;
  units: Array<UnitUpdateType>;
};


export type MutationUpdateUserConfigurationsArgs = {
  setNull?: InputMaybe<UserConfigurationSetNullInputType>;
  userConfigurations: Array<UserConfigurationUpdateType>;
};


export type MutationUpdateUsersArgs = {
  setNull?: InputMaybe<UserSetNullInputType>;
  users?: InputMaybe<Array<InputMaybe<UserUpdateType>>>;
};

export type AssetAttributeDataTypeCreateType = {
  /** MinLength=0, MaxLength=100 */
  assetAttributeDataTypeName: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssetAttributeCreateType = {
  assetAttributeDataType?: InputMaybe<AssetAttributeDataTypeCreateType>;
  assetAttributeUnit?: InputMaybe<AssetAttributeUnitCreateType>;
  assetAttributeUnitId?: InputMaybe<Scalars['UUID']>;
  attributeDataTypeId: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  attributeName: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=500 */
  defaultValue?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  languageISOCode: Scalars['String'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  required: Scalars['Boolean'];
};

export type AssetAttributeUnitCreateType = {
  /** MinLength=0, MaxLength=100 */
  assetAttributeUnitName: Scalars['String'];
  /** MinLength=0, MaxLength=50 */
  assetAttributeUnitSymbol?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetAttributeValueCreateType = {
  assetAttributeId: Scalars['UUID'];
  assetId: Scalars['UUID'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=500 */
  value: Scalars['String'];
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['DateTime']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueInt?: InputMaybe<Scalars['Int']>;
};

export type PairingInput = {
  assetItemNumber?: InputMaybe<Scalars['String']>;
  assetManufacturerCode?: InputMaybe<Scalars['String']>;
  assetSerialNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  deviceSerialNumber?: InputMaybe<Scalars['String']>;
  pairingImages?: InputMaybe<Array<InputMaybe<PairingImageInput>>>;
};

export type PairingImageInput = {
  base64Image?: InputMaybe<Scalars['String']>;
  imageDescription?: InputMaybe<Scalars['String']>;
};

export type AssetDevicePairingBatch = {
  __typename?: 'AssetDevicePairingBatch';
  assetDevicePairing?: Maybe<Array<Maybe<AssetDevicePairingBatchDetail>>>;
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type AssetDevicePairingBatchDetail = {
  __typename?: 'AssetDevicePairingBatchDetail';
  deviceSerialNumber: Scalars['String'];
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type AssetDeviceCreateType = {
  assetId: Scalars['UUID'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssetImageCreateType = {
  assetId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt: Scalars['DateTime'];
  /** MinLength=0, MaxLength=2000 */
  pictureBlobStorageUrl: Scalars['String'];
  /** MinLength=0, MaxLength=2000 */
  thumbnailBlobStorageUrl?: InputMaybe<Scalars['String']>;
};

export type AssetMasterDataCreateType = {
  /** MinLength=0, MaxLength=16 */
  articleNumber?: InputMaybe<Scalars['String']>;
  assetMasterDataSourceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=100 */
  factoryNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  generalItem?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=24 */
  identificationNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=24 */
  itemConstructionNumber?: InputMaybe<Scalars['String']>;
  manufacturerDate?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=18 */
  measurement?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  projectNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  sellingUnitSerialNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=20 */
  serialNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=12 */
  supplier?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=6 */
  supplierNumber?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type AssetMasterDataSourceCreateType = {
  /** MinLength=0, MaxLength=1000 */
  assetMasterDataSourceDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  assetMasterDataSourceName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetNoteCreateType = {
  assetId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt: Scalars['DateTime'];
  /** MinLength=0, MaxLength=4000 */
  noteContent: Scalars['String'];
};

export type AssetCreateType = {
  assetMasterData?: InputMaybe<AssetMasterDataCreateType>;
  assetMasterDataId?: InputMaybe<Scalars['UUID']>;
  assetOrigin?: InputMaybe<Scalars['String']>;
  assetSource?: InputMaybe<AssetSourceCreateType>;
  assetSourceId?: InputMaybe<Scalars['UUID']>;
  assetTypeAttributeSet?: InputMaybe<AssetTypeAttributeSetCreateType>;
  assetTypeAttributeSetId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  customUniqueIdentifier: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  lMID?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  parentAsset?: InputMaybe<AssetCreateType>;
  parentAssetId?: InputMaybe<Scalars['UUID']>;
};

export type AssetSourceCreateType = {
  /** MinLength=0, MaxLength=1000 */
  assetSourceDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  assetSourceName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetTypeAttributeSetCreateType = {
  /** MinLength=0, MaxLength=100 */
  assetTypeAttributeSetName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetTagCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  tagValue: Scalars['String'];
};

export type CapabilityCreateType = {
  /** MinLength=0, MaxLength=2000 */
  capabilityDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  capabilityName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeCreateType = {
  /** MinLength=0, MaxLength=50 */
  attributeDataType: Scalars['String'];
  /** MinLength=0, MaxLength=100 */
  attributeName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  required: Scalars['Boolean'];
};

export type DeviceAttributeValueCreateType = {
  deviceAttributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=50 */
  value: Scalars['String'];
};

export type DeviceCapabilityCreateType = {
  capabilityId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['UUID'];
};

export type DeviceConfigurationCreateType = {
  configurationJSON: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareConfigurationSchemaCreateType = {
  deviceFirmwareConfigurationJSON: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareReleaseTypeCreateType = {
  /** MinLength=0, MaxLength=100 */
  deviceFirmwareReleaseTypeName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareCreateType = {
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  deviceFirmwareConfigurationSchemaId: Scalars['UUID'];
  deviceFirmwareReleaseTypeId: Scalars['UUID'];
  /** MinLength=0, MaxLength=10 */
  firmwareRevision: Scalars['String'];
  /** MinLength=0, MaxLength=10 */
  firmwareVersion: Scalars['String'];
  /** MinLength=0, MaxLength=200 */
  hardwareRevisionSupport: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  releaseDate: Scalars['DateTime'];
  unlockRelease?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceHeartbeatCurrentValueInput = {
  deviceHeartbeatCurrentValueId?: InputMaybe<Scalars['UUID']>;
  deviceId: Scalars['UUID'];
  insertedAt: Scalars['DateTime'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId: Scalars['UUID'];
  utcTimeMeasured: Scalars['DateTime'];
};

export type DeviceHeartbeatHistoryInput = {
  deviceHeartbeatHistoryId?: InputMaybe<Scalars['UUID']>;
  deviceId: Scalars['UUID'];
  insertedAt: Scalars['DateTime'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId: Scalars['UUID'];
  uTCIngestionTime?: InputMaybe<Scalars['DateTime']>;
  uTCIoTHubEnqueuedTime?: InputMaybe<Scalars['DateTime']>;
  uTCPersistenceTime?: InputMaybe<Scalars['DateTime']>;
  utcTimeMeasured: Scalars['DateTime'];
};

export type DeviceModelAttributeSetCreateType = {
  /** MinLength=0, MaxLength=100 */
  deviceModelAttributeSetName: Scalars['String'];
  deviceModelId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelCapabilityCreateType = {
  capabilityId: Scalars['UUID'];
  deviceModelVersionId: Scalars['UUID'];
  /** MinLength=0, MaxLength=40 */
  fieldSelector: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['UUID'];
};

export type DeviceModelCreateType = {
  /** MinLength=0, MaxLength=100 */
  deviceModelName: Scalars['String'];
  deviceTypeId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  oId?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  oIdFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  timestampSelector?: InputMaybe<Scalars['String']>;
};

export type DeviceModelVersionCreateType = {
  deviceModelId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceNotificationCreateType = {
  /** MinLength=0, MaxLength=500 */
  actualValue: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationDefinitionId: Scalars['UUID'];
  notificationRead?: InputMaybe<Scalars['Boolean']>;
  notificationSend: Scalars['DateTime'];
};

export type DeviceCreateType = {
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  deviceCapability?: InputMaybe<DeviceCapabilityCreateType>;
  deviceCapabilityId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=100 */
  deviceName: Scalars['String'];
  deviceTypeId: Scalars['UUID'];
  gatewayDeviceConnection?: InputMaybe<GatewayDeviceConnectionCreateType>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  parentDevice?: InputMaybe<DeviceCreateType>;
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  sensorId: Scalars['String'];
  /** MinLength=0, MaxLength=50 */
  serialNo: Scalars['String'];
};

export type GatewayDeviceConnectionCreateType = {
  deviceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  gatewayDeviceName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=500 */
  primaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  secondaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

export type DeviceTypeCreateType = {
  /** MinLength=0, MaxLength=100 */
  deviceTypeName: Scalars['String'];
  /** MinLength=0, MaxLength=10 */
  fieldDeviceIdFieldSelector?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type ExcavatorSetupInput = {
  assetIdentificationNumber?: InputMaybe<Scalars['String']>;
  excavatorAttributes?: InputMaybe<Array<InputMaybe<ExcavatorAttributeInput>>>;
  gatewayDeviceSerialNumber?: InputMaybe<Scalars['String']>;
  relayDeviceSerialNumber?: InputMaybe<Scalars['String']>;
};

export type ExcavatorAttributeInput = {
  key: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};

export type AssetDevicePairing = {
  __typename?: 'AssetDevicePairing';
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type GroupEntityCreateType = {
  /** MinLength=0, MaxLength=100 */
  entityName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterDataTypeCreateType = {
  /** MinLength=0, MaxLength=100 */
  groupEntityFilterDataTypeName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterCreateType = {
  /** MinLength=0, MaxLength=100 */
  displayName: Scalars['String'];
  /** MinLength=0, MaxLength=100 */
  entityColumnName: Scalars['String'];
  /** MinLength=0, MaxLength=100 */
  entityName: Scalars['String'];
  groupEntityFilterDataTypeId: Scalars['UUID'];
  groupEntityFilterUiTypeId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterUiTypeCreateType = {
  /** MinLength=0, MaxLength=100 */
  groupEntityFilterUiTypeName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupOperatorCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  operator: Scalars['String'];
};

export type GroupCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  groupDescription?: InputMaybe<Scalars['String']>;
  groupEntityId: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  groupName: Scalars['String'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  jSONQuery: Scalars['String'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  rAWQuery?: InputMaybe<Scalars['String']>;
};

export type LogInput = {
  logMessage?: InputMaybe<Scalars['String']>;
  logTime: Scalars['DateTime'];
};

export type MappingAssetTagCreateType = {
  assetId: Scalars['UUID'];
  assetTagId: Scalars['UUID'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MappingAttributeAssetTypeAttributeSetCreateType = {
  assetAttributeId: Scalars['UUID'];
  assetTypeAttributeSetId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeDeviceModelAttributeSetCreateType = {
  deviceAttributeId: Scalars['UUID'];
  deviceModelAttributeSetId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceConfigurationDeviceCreateType = {
  deviceConfigurationId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDeviceStatusId: Scalars['UUID'];
};

export type MappingDeviceConfigurationDeviceStatusCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  statusName: Scalars['String'];
};

export type MappingDeviceFirmwareDeviceCreateType = {
  deviceFirmwareId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDeviceStatusId: Scalars['UUID'];
};

export type MappingDeviceFirmwareDeviceStatusCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  statusName: Scalars['String'];
};

export type MappingDeviceFirmwareDeviceTypeCreateType = {
  deviceFirmwareId: Scalars['UUID'];
  deviceTypeId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceNotificationCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationId: Scalars['UUID'];
};

export type MappingDeviceTagCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  tagId: Scalars['UUID'];
};

export type MappingGroupEntityFilterGroupOperatorCreateType = {
  groupEntityFilterId: Scalars['UUID'];
  groupOperatorId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingGroupEntityGroupEntityFilterCreateType = {
  groupEntityFilterId: Scalars['UUID'];
  groupEntityId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingNotificationScopeNotificationOperatorCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationOperatorId: Scalars['UUID'];
  notificationScopeId: Scalars['UUID'];
};

export type MappingNotificationUserCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  notificationId: Scalars['UUID'];
  tenantId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['UUID'];
};

export type MappingRoleScopeCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  roleId: Scalars['UUID'];
  scopeId: Scalars['UUID'];
};

export type MappingRoleUserCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  roleId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

export type MappingUserScopeCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  scopeId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

export type NotificationDefinitionCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isRemoved?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationId: Scalars['UUID'];
  notificationOperatorId: Scalars['UUID'];
  storageAreaId?: InputMaybe<Scalars['UUID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type NotificationOperatorCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  operator: Scalars['String'];
  /** MinLength=0, MaxLength=1000 */
  operatorDescription?: InputMaybe<Scalars['String']>;
};

export type NotificationCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isRemoved?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationScopeId: Scalars['UUID'];
  notificationText?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  recipientEmail?: InputMaybe<Scalars['String']>;
  resubmissionInterval: Scalars['Int'];
};

export type NotificationScopeCreateType = {
  capabilityId: Scalars['UUID'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dataType?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  scopeName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  unitName: Scalars['String'];
  /** MinLength=0, MaxLength=100 */
  unitSymbol: Scalars['String'];
};

export type RoleCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  isDefaultRole?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type RuleActionCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=500 */
  ruleActionDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleActionName?: InputMaybe<Scalars['String']>;
};

export type RuleReferenceCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  referenceNumber: Scalars['Int'];
  referenceUnitId: Scalars['UUID'];
  /** MinLength=0, MaxLength=500 */
  ruleReferenceDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleReferenceName?: InputMaybe<Scalars['String']>;
  ruleReferenceUnit?: InputMaybe<RuleReferenceUnitCreateType>;
};

export type RuleReferenceUnitCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  ruleReference?: InputMaybe<RuleReferenceCreateType>;
  /** MinLength=0, MaxLength=500 */
  ruleReferenceUnitDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleReferenceUnitName?: InputMaybe<Scalars['String']>;
};

export type RuleResultTypeCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=500 */
  ruleResultTypeDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleResultTypeName?: InputMaybe<Scalars['String']>;
};

export type RuleCreateType = {
  deviceCapabilityId: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  ruleActionFailureId: Scalars['UUID'];
  ruleActionSuccessId: Scalars['UUID'];
  /** MinLength=0, MaxLength=500 */
  ruleDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  ruleMessageFailure?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  ruleMessageSuccess?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  ruleOperator?: InputMaybe<Scalars['String']>;
  ruleReferenceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=10 */
  ruleResult?: InputMaybe<Scalars['String']>;
  ruleResultTypeId: Scalars['UUID'];
  tenantId?: InputMaybe<Scalars['Int']>;
  unitId: Scalars['UUID'];
};

export type ScopeCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=200 */
  value: Scalars['String'];
};

export type SensorMeasurementCurrentValueCreateType = {
  capabilityId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  gatewayDeviceConnectionId?: InputMaybe<Scalars['UUID']>;
  insertedAt: Scalars['DateTime'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  utcTimeMeasured: Scalars['DateTime'];
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['DateTime']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueGuid?: InputMaybe<Scalars['UUID']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valuePoint?: InputMaybe<GeoJsonPointInput>;
  valuePosition: Scalars['Int'];
  /** MinLength=0, MaxLength=500 */
  valueString?: InputMaybe<Scalars['String']>;
};

export type SensorMeasurementHistoryCreateType = {
  capabilityId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  gatewayDeviceConnectionId?: InputMaybe<Scalars['UUID']>;
  insertedAt: Scalars['DateTime'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  utcTimeMeasured: Scalars['DateTime'];
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['DateTime']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueGuid?: InputMaybe<Scalars['UUID']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valuePoint?: InputMaybe<GeoJsonPointInput>;
  valuePosition: Scalars['Int'];
  /** MinLength=0, MaxLength=500 */
  valueString?: InputMaybe<Scalars['String']>;
};

export type StorageAreaCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  geofence?: InputMaybe<GeoJsonPolygonInput>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  parentStorageArea?: InputMaybe<StorageAreaCreateType>;
  parentStorageAreaId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=2000 */
  storageAreaDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  storageAreaName: Scalars['String'];
  storageAreaTypeId: Scalars['UUID'];
};

export type GeoJsonPolygonInput = {
  /** The "coordinates" field MUST be an array of linear ring coordinate arrays. For Polygons with more than one of these rings, the first MUST be the exterior ring, and any others MUST be interior rings. The exterior ring bounds the surface, and the interior rings (if present) bound holes within the surface. */
  coordinates?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Position']>>>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type StorageAreaTypeCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  order?: InputMaybe<Scalars['Int']>;
  /** MinLength=0, MaxLength=100 */
  storageAreaTypeColor?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  storageAreaTypeName: Scalars['String'];
};

export type TagCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  tagValue: Scalars['String'];
};

export type TenantCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  tenantIdentifier: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  tenantName: Scalars['String'];
};

export type TranslationCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=200 */
  key: Scalars['String'];
  /** MinLength=0, MaxLength=20 */
  language: Scalars['String'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=500 */
  value: Scalars['String'];
};

export type UnitCreateType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  unitName: Scalars['String'];
  /** MinLength=0, MaxLength=100 */
  unitSymbol: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  uri?: InputMaybe<Scalars['String']>;
};

export type UserConfigurationCreateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=200 */
  key: Scalars['String'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['UUID'];
  value: Scalars['String'];
};

export type UserCreateType = {
  /** MinLength=0, MaxLength=100 */
  authenticationProvider?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=250 */
  email?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  firstName?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  language?: InputMaybe<Scalars['String']>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  lastName?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=1000 */
  userDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  userName: Scalars['String'];
};

export type AssetAttributeDataTypeUpdateType = {
  /** MinLength=0, MaxLength=100 */
  assetAttributeDataTypeName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssetAttributeDataTypeSetNullInputType = {
  assetAttribute?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AssetAttributeUpdateType = {
  assetAttributeDataType?: InputMaybe<AssetAttributeDataTypeUpdateType>;
  assetAttributeUnitId?: InputMaybe<Scalars['UUID']>;
  attributeDataTypeId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=100 */
  attributeName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=500 */
  defaultValue?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  languageISOCode?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AssetAttributeSetNullInputType = {
  assetAttributeDataType?: InputMaybe<Scalars['Boolean']>;
  assetAttributeUnit?: InputMaybe<Scalars['Boolean']>;
  assetAttributeUnitId?: InputMaybe<Scalars['Boolean']>;
  assetAttributeValue?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  defaultValue?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeAssetTypeAttributeSet?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AssetAttributeUnitUpdateType = {
  /** MinLength=0, MaxLength=100 */
  assetAttributeUnitName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  assetAttributeUnitSymbol?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetAttributeUnitSetNullInputType = {
  assetAttribute?: InputMaybe<Scalars['Boolean']>;
  assetAttributeUnitSymbol?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetAttributeValueUpdateType = {
  assetAttributeId?: InputMaybe<Scalars['UUID']>;
  assetId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=500 */
  value?: InputMaybe<Scalars['String']>;
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['DateTime']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueInt?: InputMaybe<Scalars['Int']>;
};

export type AssetAttributeValueSetNullInputType = {
  asset?: InputMaybe<Scalars['Boolean']>;
  assetAttribute?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['Boolean']>;
  valueFloat?: InputMaybe<Scalars['Boolean']>;
  valueInt?: InputMaybe<Scalars['Boolean']>;
};

export type AssetDeviceUpdateType = {
  assetId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssetDeviceSetNullInputType = {
  asset?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AssetImageUpdateType = {
  assetId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  pictureBlobStorageUrl?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=2000 */
  thumbnailBlobStorageUrl?: InputMaybe<Scalars['String']>;
};

export type AssetImageSetNullInputType = {
  asset?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  thumbnailBlobStorageUrl?: InputMaybe<Scalars['Boolean']>;
};

export type AssetMasterDataUpdateType = {
  /** MinLength=0, MaxLength=16 */
  articleNumber?: InputMaybe<Scalars['String']>;
  assetMasterDataSourceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=100 */
  factoryNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  generalItem?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=24 */
  identificationNumber?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=24 */
  itemConstructionNumber?: InputMaybe<Scalars['String']>;
  manufacturerDate?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=18 */
  measurement?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  projectNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  sellingUnitSerialNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=20 */
  serialNumber?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=12 */
  supplier?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=6 */
  supplierNumber?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type AssetMasterDataSetNullInputType = {
  articleNumber?: InputMaybe<Scalars['Boolean']>;
  asset?: InputMaybe<Scalars['Boolean']>;
  assetMasterDataSource?: InputMaybe<Scalars['Boolean']>;
  assetMeasurement?: InputMaybe<Scalars['Boolean']>;
  factoryNumber?: InputMaybe<Scalars['Boolean']>;
  generalItem?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  itemConstructionNumber?: InputMaybe<Scalars['Boolean']>;
  itemName?: InputMaybe<Scalars['Boolean']>;
  manufacturerDate?: InputMaybe<Scalars['Boolean']>;
  measurement?: InputMaybe<Scalars['Boolean']>;
  projectNumber?: InputMaybe<Scalars['Boolean']>;
  sellingUnitSerialNumber?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['Boolean']>;
  supplier?: InputMaybe<Scalars['Boolean']>;
  supplierNumber?: InputMaybe<Scalars['Boolean']>;
  weight?: InputMaybe<Scalars['Boolean']>;
};

export type AssetMasterDataSourceUpdateType = {
  /** MinLength=0, MaxLength=1000 */
  assetMasterDataSourceDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  assetMasterDataSourceName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetMasterDataSourceSetNullInputType = {
  assetMasterData?: InputMaybe<Scalars['Boolean']>;
  assetMasterDataSourceDescription?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetNoteUpdateType = {
  assetId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=4000 */
  noteContent?: InputMaybe<Scalars['String']>;
};

export type AssetNoteSetNullInputType = {
  asset?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetUpdateType = {
  assetMasterDataId?: InputMaybe<Scalars['UUID']>;
  assetOrigin?: InputMaybe<Scalars['String']>;
  assetSourceId?: InputMaybe<Scalars['UUID']>;
  assetTypeAttributeSetId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  customUniqueIdentifier?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  lMID?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  parentAssetId?: InputMaybe<Scalars['UUID']>;
};

export type AssetSetNullInputType = {
  assetAttributeValue?: InputMaybe<Scalars['Boolean']>;
  assetDevice?: InputMaybe<Scalars['Boolean']>;
  assetImage?: InputMaybe<Scalars['Boolean']>;
  assetMasterData?: InputMaybe<Scalars['Boolean']>;
  assetMasterDataId?: InputMaybe<Scalars['Boolean']>;
  assetNote?: InputMaybe<Scalars['Boolean']>;
  assetOrigin?: InputMaybe<Scalars['Boolean']>;
  assetSource?: InputMaybe<Scalars['Boolean']>;
  assetTypeAttributeSet?: InputMaybe<Scalars['Boolean']>;
  assetTypeAttributeSetId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  inverseParentAsset?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lMID?: InputMaybe<Scalars['Boolean']>;
  mappingAssetTag?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  parentAsset?: InputMaybe<Scalars['Boolean']>;
  parentAssetId?: InputMaybe<Scalars['Boolean']>;
};

export type AssetSourceUpdateType = {
  /** MinLength=0, MaxLength=1000 */
  assetSourceDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  assetSourceName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetSourceSetNullInputType = {
  asset?: InputMaybe<Scalars['Boolean']>;
  assetSourceDescription?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetTagUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  tagValue?: InputMaybe<Scalars['String']>;
};

export type AssetTagSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  mappingAssetTag?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AssetTypeAttributeSetUpdateType = {
  /** MinLength=0, MaxLength=100 */
  assetTypeAttributeSetName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type AssetTypeAttributeSetSetNullInputType = {
  asset?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeAssetTypeAttributeSet?: InputMaybe<Scalars['Boolean']>;
};

export type CapabilityUpdateType = {
  /** MinLength=0, MaxLength=2000 */
  capabilityDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  capabilityName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type CapabilitySetNullInputType = {
  capabilityDescription?: InputMaybe<Scalars['Boolean']>;
  deviceCapability?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapability?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeUpdateType = {
  /** MinLength=0, MaxLength=50 */
  attributeDataType?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  attributeName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeSetNullInputType = {
  deviceAttributeValue?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeValueUpdateType = {
  deviceAttributeId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=50 */
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceAttributeValueSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  deviceAttribute?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceCapabilityUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceCapabilitySetNullInputType = {
  capability?: InputMaybe<Scalars['Boolean']>;
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Boolean']>;
  technicalMin?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceConfigurationUpdateType = {
  configurationJSON?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceConfigurationSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDevice?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareConfigurationSchemaUpdateType = {
  deviceFirmwareConfigurationJSON?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareConfigurationSchemaSetNullInputType = {
  deviceFirmware?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareReleaseTypeUpdateType = {
  /** MinLength=0, MaxLength=100 */
  deviceFirmwareReleaseTypeName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareReleaseTypeSetNullInputType = {
  deviceFirmware?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareUpdateType = {
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  deviceFirmwareConfigurationSchemaId?: InputMaybe<Scalars['UUID']>;
  deviceFirmwareReleaseTypeId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=10 */
  firmwareRevision?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  firmwareVersion?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=200 */
  hardwareRevisionSupport?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  unlockRelease?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceFirmwareSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  deviceFirmwareConfigurationSchema?: InputMaybe<Scalars['Boolean']>;
  deviceFirmwareReleaseType?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDevice?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDeviceType?: InputMaybe<Scalars['Boolean']>;
  unlockRelease?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceHeartbeatCurrentValueUpdateInput = {
  deviceHeartbeatCurrentValueId: Scalars['UUID'];
  deviceId?: InputMaybe<Scalars['UUID']>;
  insertedAt: Scalars['DateTime'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  utcTimeMeasured: Scalars['DateTime'];
};

export type DeviceHeartbeatCurrentValueSetNullInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceHeartbeatHistoryUpdateInput = {
  deviceHeartbeatHistoryId: Scalars['UUID'];
  deviceId?: InputMaybe<Scalars['UUID']>;
  insertedAt: Scalars['DateTime'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  uTCIngestionTime?: InputMaybe<Scalars['DateTime']>;
  uTCIoTHubEnqueuedTime?: InputMaybe<Scalars['DateTime']>;
  uTCPersistenceTime?: InputMaybe<Scalars['DateTime']>;
  utcTimeMeasured: Scalars['DateTime'];
};

export type DeviceHeartbeatHistorySetNullInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  uTCIngestionTime?: InputMaybe<Scalars['Boolean']>;
  uTCIoTHubEnqueuedTime?: InputMaybe<Scalars['Boolean']>;
  uTCPersistenceTime?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelAttributeSetUpdateType = {
  /** MinLength=0, MaxLength=100 */
  deviceModelAttributeSetName?: InputMaybe<Scalars['String']>;
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelAttributeSetSetNullInputType = {
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelCapabilityUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  deviceModelVersionId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=40 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceModelCapabilitySetNullInputType = {
  capability?: InputMaybe<Scalars['Boolean']>;
  deviceModelVersion?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Boolean']>;
  technicalMin?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelUpdateType = {
  /** MinLength=0, MaxLength=100 */
  deviceModelName?: InputMaybe<Scalars['String']>;
  deviceTypeId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  oId?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  oIdFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  timestampSelector?: InputMaybe<Scalars['String']>;
};

export type DeviceModelSetNullInputType = {
  deviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
  deviceModelVersion?: InputMaybe<Scalars['Boolean']>;
  deviceType?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  oId?: InputMaybe<Scalars['Boolean']>;
  oIdFieldSelector?: InputMaybe<Scalars['Boolean']>;
  timestampSelector?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelVersionUpdateType = {
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelVersionSetNullInputType = {
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapability?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceNotificationUpdateType = {
  /** MinLength=0, MaxLength=500 */
  actualValue?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationDefinitionId?: InputMaybe<Scalars['UUID']>;
  notificationRead?: InputMaybe<Scalars['Boolean']>;
  notificationSend?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceNotificationSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  notificationDefinition?: InputMaybe<Scalars['Boolean']>;
  notificationRead?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceUpdateType = {
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  deviceCapabilityId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=100 */
  deviceName?: InputMaybe<Scalars['String']>;
  deviceTypeId?: InputMaybe<Scalars['UUID']>;
  gatewayDeviceConnection?: InputMaybe<GatewayDeviceConnectionUpdateType>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  sensorId?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type GatewayDeviceConnectionUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=100 */
  gatewayDeviceName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=500 */
  primaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  secondaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

export type DeviceSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValue?: InputMaybe<Scalars['Boolean']>;
  deviceCapability?: InputMaybe<Scalars['Boolean']>;
  deviceCapabilityId?: InputMaybe<Scalars['Boolean']>;
  deviceType?: InputMaybe<Scalars['Boolean']>;
  gatewayDeviceConnection?: InputMaybe<Scalars['Boolean']>;
  inverseParentDevice?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDevice?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDevice?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceTag?: InputMaybe<Scalars['Boolean']>;
  parentDevice?: InputMaybe<Scalars['Boolean']>;
  parentDeviceId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTypeUpdateType = {
  /** MinLength=0, MaxLength=100 */
  deviceTypeName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  fieldDeviceIdFieldSelector?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTypeSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  fieldDeviceIdFieldSelector?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDeviceType?: InputMaybe<Scalars['Boolean']>;
};

export type GatewayDeviceConnectionSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  primaryAuthenticationKey?: InputMaybe<Scalars['Boolean']>;
  primaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  secondaryAuthenticationKey?: InputMaybe<Scalars['Boolean']>;
  secondaryConnectionString?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityUpdateType = {
  /** MinLength=0, MaxLength=100 */
  entityName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntitySetNullInputType = {
  group?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingGroupEntityGroupEntityFilter?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterDataTypeUpdateType = {
  /** MinLength=0, MaxLength=100 */
  groupEntityFilterDataTypeName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterDataTypeSetNullInputType = {
  groupEntityFilter?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterUpdateType = {
  /** MinLength=0, MaxLength=100 */
  displayName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  entityColumnName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  entityName?: InputMaybe<Scalars['String']>;
  groupEntityFilterDataTypeId?: InputMaybe<Scalars['UUID']>;
  groupEntityFilterUiTypeId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterSetNullInputType = {
  groupEntityFilterDataType?: InputMaybe<Scalars['Boolean']>;
  groupEntityFilterUiType?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingGroupEntityFilterGroupOperator?: InputMaybe<Scalars['Boolean']>;
  mappingGroupEntityGroupEntityFilter?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterUiTypeUpdateType = {
  /** MinLength=0, MaxLength=100 */
  groupEntityFilterUiTypeName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupEntityFilterUiTypeSetNullInputType = {
  groupEntityFilter?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GroupOperatorUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  operator?: InputMaybe<Scalars['String']>;
};

export type GroupOperatorSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingGroupEntityFilterGroupOperator?: InputMaybe<Scalars['Boolean']>;
};

export type GroupUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  groupDescription?: InputMaybe<Scalars['String']>;
  groupEntityId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=100 */
  groupName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  jSONQuery?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  rAWQuery?: InputMaybe<Scalars['String']>;
};

export type GroupSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  groupDescription?: InputMaybe<Scalars['Boolean']>;
  groupEntity?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  rAWQuery?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAssetTagUpdateType = {
  assetId?: InputMaybe<Scalars['UUID']>;
  assetTagId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MappingAssetTagSetNullInputType = {
  asset?: InputMaybe<Scalars['Boolean']>;
  assetTag?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeAssetTypeAttributeSetUpdateType = {
  assetAttributeId?: InputMaybe<Scalars['UUID']>;
  assetTypeAttributeSetId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeAssetTypeAttributeSetSetNullInputType = {
  assetAttribute?: InputMaybe<Scalars['Boolean']>;
  assetTypeAttributeSet?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeDeviceModelAttributeSetUpdateType = {
  deviceAttributeId?: InputMaybe<Scalars['UUID']>;
  deviceModelAttributeSetId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeDeviceModelAttributeSetSetNullInputType = {
  deviceAttribute?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceConfigurationDeviceUpdateType = {
  deviceConfigurationId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDeviceStatusId?: InputMaybe<Scalars['UUID']>;
};

export type MappingDeviceConfigurationDeviceSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  deviceConfiguration?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDeviceStatus?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceConfigurationDeviceStatusUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  statusName?: InputMaybe<Scalars['String']>;
};

export type MappingDeviceConfigurationDeviceStatusSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceConfigurationDevice?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceFirmwareDeviceUpdateType = {
  deviceFirmwareId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDeviceStatusId?: InputMaybe<Scalars['UUID']>;
};

export type MappingDeviceFirmwareDeviceSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  deviceFirmware?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDeviceStatus?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceFirmwareDeviceStatusUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  statusName?: InputMaybe<Scalars['String']>;
};

export type MappingDeviceFirmwareDeviceStatusSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceFirmwareDevice?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceFirmwareDeviceTypeUpdateType = {
  deviceFirmwareId?: InputMaybe<Scalars['UUID']>;
  deviceTypeId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceFirmwareDeviceTypeSetNullInputType = {
  deviceFirmware?: InputMaybe<Scalars['Boolean']>;
  deviceType?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceNotificationUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationId?: InputMaybe<Scalars['UUID']>;
};

export type MappingDeviceNotificationSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceTagUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  tagId?: InputMaybe<Scalars['UUID']>;
};

export type MappingDeviceTagSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  tag?: InputMaybe<Scalars['Boolean']>;
};

export type MappingGroupEntityFilterGroupOperatorUpdateType = {
  groupEntityFilterId?: InputMaybe<Scalars['UUID']>;
  groupOperatorId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingGroupEntityFilterGroupOperatorSetNullInputType = {
  groupEntityFilter?: InputMaybe<Scalars['Boolean']>;
  groupOperator?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingGroupEntityGroupEntityFilterUpdateType = {
  groupEntityFilterId?: InputMaybe<Scalars['UUID']>;
  groupEntityId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingGroupEntityGroupEntityFilterSetNullInputType = {
  groupEntity?: InputMaybe<Scalars['Boolean']>;
  groupEntityFilter?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type MappingNotificationScopeNotificationOperatorUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationOperatorId?: InputMaybe<Scalars['UUID']>;
  notificationScopeId?: InputMaybe<Scalars['UUID']>;
};

export type MappingNotificationScopeNotificationOperatorSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  notificationOperator?: InputMaybe<Scalars['Boolean']>;
  notificationScope?: InputMaybe<Scalars['Boolean']>;
};

export type MappingNotificationUserUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  notificationId?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export type MappingNotificationUserSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingRoleScopeUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<Scalars['UUID']>;
  scopeId?: InputMaybe<Scalars['UUID']>;
};

export type MappingRoleScopeSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['Boolean']>;
  scope?: InputMaybe<Scalars['Boolean']>;
};

export type MappingRoleUserUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export type MappingRoleUserSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Boolean']>;
};

export type MappingUserScopeUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export type MappingUserScopeSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  scope?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationDefinitionUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isRemoved?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationId?: InputMaybe<Scalars['UUID']>;
  notificationOperatorId?: InputMaybe<Scalars['UUID']>;
  storageAreaId?: InputMaybe<Scalars['UUID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type NotificationDefinitionSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deviceNotification?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isRemoved?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<Scalars['Boolean']>;
  notificationOperator?: InputMaybe<Scalars['Boolean']>;
  storageAreaId?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationOperatorUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  operator?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=1000 */
  operatorDescription?: InputMaybe<Scalars['String']>;
};

export type NotificationOperatorSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingNotificationScopeNotificationOperator?: InputMaybe<Scalars['Boolean']>;
  notificationDefinition?: InputMaybe<Scalars['Boolean']>;
  operatorDescription?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isRemoved?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  notificationScopeId?: InputMaybe<Scalars['UUID']>;
  notificationText?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  recipientEmail?: InputMaybe<Scalars['String']>;
  resubmissionInterval?: InputMaybe<Scalars['Int']>;
};

export type NotificationSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isRemoved?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceNotification?: InputMaybe<Scalars['Boolean']>;
  mappingNotificationUser?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  notificationDefinition?: InputMaybe<Scalars['Boolean']>;
  notificationScope?: InputMaybe<Scalars['Boolean']>;
  notificationText?: InputMaybe<Scalars['Boolean']>;
  recipientEmail?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationScopeUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dataType?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  scopeName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  unitName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  unitSymbol?: InputMaybe<Scalars['String']>;
};

export type NotificationScopeSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  dataType?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingNotificationScopeNotificationOperator?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<Scalars['Boolean']>;
  scopeName?: InputMaybe<Scalars['Boolean']>;
};

export type RoleUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDefaultRole?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type RoleSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  isDefaultRole?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingRoleScopes?: InputMaybe<Scalars['Boolean']>;
  mappingRoleUsers?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RuleActionUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=500 */
  ruleActionDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleActionName?: InputMaybe<Scalars['String']>;
};

export type RuleActionSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  ruleActionDescription?: InputMaybe<Scalars['Boolean']>;
  ruleActionName?: InputMaybe<Scalars['Boolean']>;
  ruleRuleActionFailure?: InputMaybe<Scalars['Boolean']>;
  ruleRuleActionSuccess?: InputMaybe<Scalars['Boolean']>;
};

export type RuleReferenceUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  referenceNumber?: InputMaybe<Scalars['Int']>;
  referenceUnitId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=500 */
  ruleReferenceDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleReferenceName?: InputMaybe<Scalars['String']>;
  ruleReferenceUnit?: InputMaybe<RuleReferenceUnitUpdateType>;
};

export type RuleReferenceUnitUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  ruleReference?: InputMaybe<RuleReferenceUpdateType>;
  /** MinLength=0, MaxLength=500 */
  ruleReferenceUnitDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleReferenceUnitName?: InputMaybe<Scalars['String']>;
};

export type RuleReferenceSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['Boolean']>;
  ruleReferenceDescription?: InputMaybe<Scalars['Boolean']>;
  ruleReferenceName?: InputMaybe<Scalars['Boolean']>;
  ruleReferenceUnit?: InputMaybe<Scalars['Boolean']>;
};

export type RuleReferenceUnitSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  ruleReference?: InputMaybe<Scalars['Boolean']>;
  ruleReferenceUnitDescription?: InputMaybe<Scalars['Boolean']>;
  ruleReferenceUnitName?: InputMaybe<Scalars['Boolean']>;
};

export type RuleResultTypeUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=500 */
  ruleResultTypeDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleResultTypeName?: InputMaybe<Scalars['String']>;
};

export type RuleResultTypeSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['Boolean']>;
  ruleResultTypeDescription?: InputMaybe<Scalars['Boolean']>;
  ruleResultTypeName?: InputMaybe<Scalars['Boolean']>;
};

export type RuleUpdateType = {
  deviceCapabilityId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  ruleActionFailureId?: InputMaybe<Scalars['UUID']>;
  ruleActionSuccessId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=500 */
  ruleDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  ruleMessageFailure?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  ruleMessageSuccess?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  ruleName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=10 */
  ruleOperator?: InputMaybe<Scalars['String']>;
  ruleReferenceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=10 */
  ruleResult?: InputMaybe<Scalars['String']>;
  ruleResultTypeId?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['Int']>;
  unitId?: InputMaybe<Scalars['UUID']>;
};

export type RuleSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  ruleActionFailure?: InputMaybe<Scalars['Boolean']>;
  ruleActionSuccess?: InputMaybe<Scalars['Boolean']>;
  ruleDescription?: InputMaybe<Scalars['Boolean']>;
  ruleMessageFailure?: InputMaybe<Scalars['Boolean']>;
  ruleMessageSuccess?: InputMaybe<Scalars['Boolean']>;
  ruleName?: InputMaybe<Scalars['Boolean']>;
  ruleOperator?: InputMaybe<Scalars['Boolean']>;
  ruleReference?: InputMaybe<Scalars['Boolean']>;
  ruleResult?: InputMaybe<Scalars['Boolean']>;
  ruleResultType?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type ScopeUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=2000 */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=200 */
  value?: InputMaybe<Scalars['String']>;
};

export type ScopeSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingRoleScopes?: InputMaybe<Scalars['Boolean']>;
  mappingUserScopes?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CommandStatus = {
  __typename?: 'CommandStatus';
  sensorCommandStatus?: Maybe<Array<Maybe<SensorCommandStatus>>>;
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type SensorCommandStatus = {
  __typename?: 'SensorCommandStatus';
  deviceId: Scalars['UUID'];
  status: Scalars['Boolean'];
  statusMessage?: Maybe<Scalars['String']>;
};

export type SensorMeasurementCurrentValueUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  gatewayDeviceConnectionId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  insertedAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  utcTimeMeasured?: InputMaybe<Scalars['DateTime']>;
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['DateTime']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueGuid?: InputMaybe<Scalars['UUID']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valuePoint?: InputMaybe<GeoJsonPointInput>;
  valuePosition?: InputMaybe<Scalars['Int']>;
  /** MinLength=0, MaxLength=500 */
  valueString?: InputMaybe<Scalars['String']>;
};

export type SensorMeasurementCurrentValueSetNullInputType = {
  gatewayDeviceConnectionId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['Boolean']>;
  valueFloat?: InputMaybe<Scalars['Boolean']>;
  valueGuid?: InputMaybe<Scalars['Boolean']>;
  valueInt?: InputMaybe<Scalars['Boolean']>;
  valuePoint?: InputMaybe<GeoJsonPointInput>;
  valueString?: InputMaybe<Scalars['Boolean']>;
};

export type SensorMeasurementHistoryUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  gatewayDeviceConnectionId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  insertedAt?: InputMaybe<Scalars['DateTime']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  utcTimeMeasured?: InputMaybe<Scalars['DateTime']>;
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['DateTime']>;
  valueFloat?: InputMaybe<Scalars['Float']>;
  valueGuid?: InputMaybe<Scalars['UUID']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valuePoint?: InputMaybe<GeoJsonPointInput>;
  valuePosition?: InputMaybe<Scalars['Int']>;
  /** MinLength=0, MaxLength=500 */
  valueString?: InputMaybe<Scalars['String']>;
};

export type SensorMeasurementHistorySetNullInputType = {
  gatewayDeviceConnectionId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDatetime?: InputMaybe<Scalars['Boolean']>;
  valueFloat?: InputMaybe<Scalars['Boolean']>;
  valueGuid?: InputMaybe<Scalars['Boolean']>;
  valueInt?: InputMaybe<Scalars['Boolean']>;
  valuePoint?: InputMaybe<GeoJsonPointInput>;
  valueString?: InputMaybe<Scalars['Boolean']>;
};

export type StorageAreaSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  inverseParentStorageArea?: InputMaybe<Scalars['Boolean']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  parentStorageArea?: InputMaybe<Scalars['Boolean']>;
  parentStorageAreaId?: InputMaybe<Scalars['Boolean']>;
  storageAreaDescription?: InputMaybe<Scalars['Boolean']>;
  storageAreaType?: InputMaybe<Scalars['Boolean']>;
};

export type StorageAreaUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  geofence?: InputMaybe<GeoJsonPolygonInput>;
  id: Scalars['UUID'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  parentStorageAreaId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=2000 */
  storageAreaDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  storageAreaName?: InputMaybe<Scalars['String']>;
  storageAreaTypeId?: InputMaybe<Scalars['UUID']>;
};

export type StorageAreaTypeSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  storageArea?: InputMaybe<Scalars['Boolean']>;
  storageAreaTypeColor?: InputMaybe<Scalars['Boolean']>;
};

export type StorageAreaTypeUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  order?: InputMaybe<Scalars['Int']>;
  /** MinLength=0, MaxLength=100 */
  storageAreaTypeColor?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  storageAreaTypeName?: InputMaybe<Scalars['String']>;
};

export type TagSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceTag?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TagUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  tagValue?: InputMaybe<Scalars['String']>;
};

export type TenantSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<Scalars['Boolean']>;
};

export type TenantUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=250 */
  tenantIdentifier?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  tenantName?: InputMaybe<Scalars['String']>;
};

export type TranslationSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=200 */
  key?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=20 */
  language?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=500 */
  value?: InputMaybe<Scalars['String']>;
};

export type UnitSetNullInputType = {
  deviceCapability?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapability?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  uri?: InputMaybe<Scalars['Boolean']>;
};

export type UnitUpdateType = {
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=100 */
  unitName?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  uri?: InputMaybe<Scalars['String']>;
};

export type UserConfigurationSetNullInputType = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['Boolean']>;
};

export type UserConfigurationUpdateType = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=200 */
  key?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserSetNullInputType = {
  authenticationProvider?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  mappingRoleUsers?: InputMaybe<Scalars['Boolean']>;
  mappingUserScopes?: InputMaybe<Scalars['Boolean']>;
  modifiedAt?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Scalars['Boolean']>;
  userConfigurations?: InputMaybe<Scalars['Boolean']>;
  userDescription?: InputMaybe<Scalars['Boolean']>;
};

export type UserUpdateType = {
  /** MinLength=0, MaxLength=100 */
  authenticationProvider?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=250 */
  email?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=10 */
  language?: InputMaybe<Scalars['String']>;
  lastLogin?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=100 */
  lastName?: InputMaybe<Scalars['String']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** MinLength=0, MaxLength=1000 */
  userDescription?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  userName?: InputMaybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type GeoJsonLineStringInput = {
  /** The "coordinates" field is an array of two or more positions. */
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Position']>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonLineStringType = GeoJsonInterface & {
  __typename?: 'GeoJSONLineStringType';
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']>;
  /** The "coordinates" field is an array of two or more positions. */
  coordinates?: Maybe<Array<Maybe<Scalars['Position']>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonMultiLineStringInput = {
  /** The "coordinates" field is an array of LineString coordinate arrays. */
  coordinates?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Position']>>>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiLineStringType = GeoJsonInterface & {
  __typename?: 'GeoJSONMultiLineStringType';
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']>;
  /** The "coordinates" field is an array of LineString coordinate arrays. */
  coordinates?: Maybe<Array<Maybe<Scalars['Position']>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonMultiPointInput = {
  /** The "coordinates" field is an array of positions. */
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Position']>>>;
  /** The coordinate reference system integer identifier */
  crs?: InputMaybe<Scalars['Int']>;
  /** The geometry type of the GeoJson object */
  type?: InputMaybe<GeoJsonGeometryType>;
};

export type GeoJsonMultiPointType = GeoJsonInterface & {
  __typename?: 'GeoJSONMultiPointType';
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']>;
  /** The "coordinates" field is an array of positions. */
  coordinates?: Maybe<Array<Maybe<Scalars['Position']>>>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type GeoJsonMultiPolygonType = GeoJsonInterface & {
  __typename?: 'GeoJSONMultiPolygonType';
  /** The minimum bounding box around the geometry object */
  bbox: Array<Scalars['Float']>;
  /** The "coordinates" field is an array of Polygon coordinate arrays. */
  coordinates?: Maybe<Scalars['Coordinates']>;
  /** The coordinate reference system integer identifier */
  crs: Scalars['Int'];
  /** The geometry type of the GeoJson object */
  type: GeoJsonGeometryType;
};

export type LinearRingSortInput = {
  area?: InputMaybe<SortEnumType>;
  boundary?: InputMaybe<GeometrySortInput>;
  boundaryDimension?: InputMaybe<SortEnumType>;
  centroid?: InputMaybe<PointSortInput>;
  coordinate?: InputMaybe<CoordinateSortInput>;
  coordinateSequence?: InputMaybe<CoordinateSequenceSortInput>;
  count?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  endPoint?: InputMaybe<PointSortInput>;
  envelope?: InputMaybe<GeometrySortInput>;
  envelopeInternal?: InputMaybe<EnvelopeSortInput>;
  geometryType?: InputMaybe<SortEnumType>;
  interiorPoint?: InputMaybe<PointSortInput>;
  isCCW?: InputMaybe<SortEnumType>;
  isEmpty?: InputMaybe<SortEnumType>;
  isRectangle?: InputMaybe<SortEnumType>;
  isRing?: InputMaybe<SortEnumType>;
  isSimple?: InputMaybe<SortEnumType>;
  isValid?: InputMaybe<SortEnumType>;
  length?: InputMaybe<SortEnumType>;
  numGeometries?: InputMaybe<SortEnumType>;
  numPoints?: InputMaybe<SortEnumType>;
  ogcGeometryType?: InputMaybe<SortEnumType>;
  pointOnSurface?: InputMaybe<PointSortInput>;
  precisionModel?: InputMaybe<PrecisionModelSortInput>;
  sRID?: InputMaybe<SortEnumType>;
  startPoint?: InputMaybe<PointSortInput>;
};

type PartialDeep<T> = {
  [P in keyof T]?: PartialDeep<T[P]>;
  };
  type PartialRestoreArrays<K> = {
  [P in keyof K]?: K[P];
  };


  type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
  type ArrayElementExplicit<A> = A extends readonly (infer T)[]
  ? Exclude<T, null>
  : never;

  export type PartialResultType<T> = ArrayElement<PartialDeep<T>> & {
  id: number|string;
  };
  export type ResultType<T> = ArrayElementExplicit<T>;
  export type NonMaybe<T> = Exclude<Exclude<T,null>,undefined>;
  declare global {
  type Decimal =  number;
  type Date = string;
  type DateTime = string;
  }
