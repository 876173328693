import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { Circle, MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import SiteAreas from "./SiteAreas";
import { useRecoilState, useRecoilValue } from "recoil";
import { deviceConfigurationModifier, siteAreaDefinitionModifier, SiteObjectType } from "../recoilState";
import { LatLngExpression } from "leaflet";
import LatLngInput from "./LatLngInput";
import SliderPLStyle from "components/atomics/SliderPLStyle";

const MapContainerSC = styled(MapContainer)({
  height: "29vh",
  width: "100%",
  zIndex: 0,
  [`& .leaflet-control-attribution `]: {
    display: "none",
  },
  [`& .leaflet-popup-content-wrapper`]: {
    borderRadius: 0,
    border: "none",
  },
  [`& .leaflet-popup-close-button`]: {
    display: "none",
  },
  [`& .leaflet-div-icon`]: {
    background: "none",
    border: "none",
  },
});

const TargetAndStorageSiteDefinition = () => {
  const [sitePosition, setReachDistance] = useRecoilState(siteAreaDefinitionModifier);

  const onSliderChange = (event: Event, value: number | number[]) => {
    setReachDistance((prev) => {
      return { ...prev, rad: value as number };
    });
  };
  const { rad, lat, lon } = sitePosition;
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "35px" }}>
      <Box sx={{ width: "40%", display: "flex", flexDirection: "column", gap: "18px" }}>
        <LatLngInput label="lat" />
        <LatLngInput label="lon" />
        <SliderPLStyle aria-label="Radius" value={rad ?? 0} step={100} onChange={onSliderChange} min={0} max={5000} />
        <Typography variant="copy">{rad}</Typography>
      </Box>
      <MapContainerSC center={[52.634281158447266, 13.288796424865723]} zoom={10} zoomControl={false}>
        <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <ZoomControl position="bottomright" />
        {!!lat && !!lon && !!rad ? (
          <Circle
            fillColor="#FFD000"
            fillOpacity={0.4}
            stroke={false}
            center={[lat, lon] as LatLngExpression}
            radius={rad}
          />
        ) : null}
        <SiteAreas />
      </MapContainerSC>
    </Box>
  );
};

export default TargetAndStorageSiteDefinition;
