import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import React from "react";
import Table from "components/templates/dataGridTable/Table";
import translations from "translations";
import { PositionHistoryTableProps } from "./index.d";
import { useSetRecoilState } from "recoil";
import { tablesRowIdHoverAtom } from "./recoilState";

const PositionHistoryTable: React.FC<PositionHistoryTableProps> = ({ movementHistoryLocations, loading }) => {
  const setMarkerIndexOnHoverRow = useSetRecoilState(tablesRowIdHoverAtom);
  const columns: GridColDef<{
    id: string;
    label: string;
    value: string;
  }>[] = [
    {
      field: "date",
      headerName: `Date`,
      flex: 1,
      valueGetter: (params) => {
        return params.row.label ?? "";
      },
      type: "string",
    },

    {
      field: "address",
      headerName: `Address`,
      flex: 1,

      valueGetter: (params) => {
        return params.row.value ?? "";
      },
      type: "string",
    },
  ];

  const handleTableRowHover = (event: any) => {
    const id = event.currentTarget.dataset.id;
    setMarkerIndexOnHoverRow(id);
  };

  const handleTableRowLeave = (event: any) => {
    setMarkerIndexOnHoverRow(undefined);
  };
  const row = {
    onMouseEnter: handleTableRowHover,
    onMouseLeave: handleTableRowLeave,
  };
  return (
    <Table
      noDataMessage={translations.pages.fleetInventory.noData}
      columns={columns}
      rows={(movementHistoryLocations ?? []) as readonly GridValidRowModel[]}
      checkboxSelection={false}
      tableHeight="75vh"
      hasToolbar={false}
      rowEvents={row}
      loading={loading}
    />
  );
};

export default PositionHistoryTable;
