import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLGalleryCarouselProps = DropOnONLh<JSX.PatternlibGalleryCarousel>;
export type PLGalleryCarouselExtendedProps = PLGalleryCarouselProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedGalleryCarousel = wrapWc<JSX.PatternlibGalleryCarousel>("patternlib-gallery-carousel");
export const useRefPLGalleryCarousel = () => useRef<HTMLPatternlibGalleryCarouselElement>(null);

const PLGalleryCarousel = React.forwardRef<HTMLPatternlibGalleryCarouselElement, PLGalleryCarouselExtendedProps>((props, ref) => {
  const myRef = useRefPLGalleryCarousel();
  return (
    <WrappedGalleryCarousel ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedGalleryCarousel>
  );
});
PLGalleryCarousel.displayName = "PLGalleryCarousel";

export default PLGalleryCarousel;
