import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetMappingUserNotificationsForNotificationQueryVariables = Types.Exact<{
  notificationMappingFilter?: Types.InputMaybe<Types.MappingNotificationUserFilterInput>;
}>;


export type GetMappingUserNotificationsForNotificationQuery = { mappingNotificationUsers: Array<{ __typename?: 'MappingNotificationUser', id: string, user: { __typename?: 'User', label?: Maybe<string>, value: string } }> };


export const GetMappingUserNotificationsForNotificationDocument = gql`
    query getMappingUserNotificationsForNotification($notificationMappingFilter: MappingNotificationUserFilterInput) {
  mappingNotificationUsers(where: $notificationMappingFilter) {
    id
    user {
      label: email
      value: id
    }
  }
}
    `;

/**
 * __useGetMappingUserNotificationsForNotificationQuery__
 *
 * To run a query within a React component, call `useGetMappingUserNotificationsForNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingUserNotificationsForNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingUserNotificationsForNotificationQuery({
 *   variables: {
 *      notificationMappingFilter: // value for 'notificationMappingFilter'
 *   },
 * });
 */
export function useGetMappingUserNotificationsForNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMappingUserNotificationsForNotificationQuery, GetMappingUserNotificationsForNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMappingUserNotificationsForNotificationQuery, GetMappingUserNotificationsForNotificationQueryVariables>(GetMappingUserNotificationsForNotificationDocument, options);
      }
export function useGetMappingUserNotificationsForNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMappingUserNotificationsForNotificationQuery, GetMappingUserNotificationsForNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMappingUserNotificationsForNotificationQuery, GetMappingUserNotificationsForNotificationQueryVariables>(GetMappingUserNotificationsForNotificationDocument, options);
        }
export type GetMappingUserNotificationsForNotificationQueryHookResult = ReturnType<typeof useGetMappingUserNotificationsForNotificationQuery>;
export type GetMappingUserNotificationsForNotificationLazyQueryHookResult = ReturnType<typeof useGetMappingUserNotificationsForNotificationLazyQuery>;
export type GetMappingUserNotificationsForNotificationQueryResult = Apollo.QueryResult<GetMappingUserNotificationsForNotificationQuery, GetMappingUserNotificationsForNotificationQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
