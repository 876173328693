import { fillSelect } from "components/templates/QueryBuilder/middleware/middleWareUtils";
import { FieldMiddleWares, JsonRule, QueryMiddleWares } from "components/templates/QueryBuilder/types";
import {
  GetDeviceIdsOnArticleNumberValueDocument,
  GetDeviceIdsOnArticleNumberValueQuery,
  GetDeviceIdsOnArticleNumberValueQueryVariables,
} from "graphqlBase/AssetDevices/__generated__/getDeviceIdsOnArticleNumberValue";
import {
  GetDeviceIdsOnTagValueDocument,
  GetDeviceIdsOnTagValueQuery,
  GetDeviceIdsOnTagValueQueryVariables,
} from "graphqlBase/AssetDevices/__generated__/getDeviceIdsOnAssetTagValue";
import {
  GetDeviceIdsOnGeneralItemValueDocument,
  GetDeviceIdsOnGeneralItemValueQuery,
  GetDeviceIdsOnGeneralItemValueQueryVariables,
} from "graphqlBase/AssetDevices/__generated__/getDeviceIdsOnGeneralItemValue";
import {
  GetDeviceIdsOnSupplierValueDocument,
  GetDeviceIdsOnSupplierValueQuery,
  GetDeviceIdsOnSupplierValueQueryVariables,
} from "graphqlBase/AssetDevices/__generated__/getDeviceIdsOnSupplierValue";
import {
  GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesDocument,
  GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery,
  GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables,
} from "graphqlBase/sensorMeasurementCurrentValues/__generated__/getDeviceIdsOnSensorMeasurementCurrentValuesByCoordinates";
import {
  GetSorageAreaCoordinatesOnIdsDocument,
  GetSorageAreaCoordinatesOnIdsQuery,
  GetSorageAreaCoordinatesOnIdsQueryVariables,
} from "graphqlBase/StorageAreas/__generated__/getSorageAreaCoordinatesOnIds";
import Qqltypes from "graphqlBase/types.d";

export const fieldMiddleWares: FieldMiddleWares = [
  {
    key: "storageArea.geofence",
    middleWare: async ({ client, fieldName, field }) => {
      const newfield = await fillSelect({
        client,
        field,
        fieldName,
        table: "storageArea",
        valueColumn: "id",
        labelColumn: "storageAreaName",
      });
      return {
        ...newfield,
        field: {
          ...newfield.field,
          type: "select",
          operators: ["select_any_in", "select_not_any_in"],
          preferWidgets: ["multiselect"],
          valueSources: ["value"],
          widgets: {
            select: {
              widgetProps: {},
            },
            multiselect: {},
            field: {},
            func: {},
          },
          mainWidget: "select",
        },
      };
    },
  },
];

export const queryMiddleWares: QueryMiddleWares = [
  {
    key: "storageArea.geofence",
    middleWare: async ({ client, fieldName, field }) => {
      const result1 = await client.query<
        GetSorageAreaCoordinatesOnIdsQuery,
        GetSorageAreaCoordinatesOnIdsQueryVariables
      >({
        query: GetSorageAreaCoordinatesOnIdsDocument,
        variables: { ids: field.properties.value[0] },
      });

      const geofencesCoordinates = (result1.data.storageAreas ?? []).reduce<Array<Array<Qqltypes.Scalars["Position"]>>>(
        (geofences, { geofence }) => {
          if (geofence) {
            const { coordinates } = geofence;
            if (Array.isArray(coordinates))
              return geofences.concat([(coordinates as unknown) as Array<[number, number]>]);
          }
          return geofences;
        },
        []
      );
      if (!geofencesCoordinates) return { fieldName };

      const result2 = await client.query<
        GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQuery,
        GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesQueryVariables
      >({
        query: GetDeviceIdsOnSensorMeasurementCurrentValuesByCoordinatesDocument,
        variables: { geofencesCoordinates },
      });

      const devices = (result2.data.queryDeviceWithinGeofence?.geofenceDevices ?? []).reduce<string[]>(
        (devices, geofence) => {
          if (geofence?.deviceId) return devices.concat(geofence.deviceId);
          return devices;
        },
        []
      );

      const nextField: JsonRule = {
        properties: {
          field: "assetDevice.deviceId",
          operator: "select_any_in",
          value: [devices],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "assetTag.tagValue",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const operator = getOperator(field.properties.operator ?? "");

      const variables = {
        where: {
          asset: {
            mappingAssetTag: {
              [operator === "in" ? "some" : "none"]: {
                assetTag: { tagValue: { in: field.properties.value[0] } },
              },
            },
          },
        },
      };

      const result = await client.query<GetDeviceIdsOnTagValueQuery, GetDeviceIdsOnTagValueQueryVariables>({
        query: GetDeviceIdsOnTagValueDocument,
        variables,
      });

      const deviceIds = (result.data?.assetDevices ?? []).reduce<string[]>((deviceIds, assetDevice) => {
        return deviceIds.concat(assetDevice.deviceId);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "assetDevice.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "assetMasterData.generalItem",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const variables = {
        where: {
          asset: {
            assetMasterData: {
              generalItem: { [getOperator(field.properties.operator ?? "")]: field.properties.value[0] },
            },
          },
        },
      };

      const result = await client.query<
        GetDeviceIdsOnGeneralItemValueQuery,
        GetDeviceIdsOnGeneralItemValueQueryVariables
      >({
        query: GetDeviceIdsOnGeneralItemValueDocument,
        variables,
      });

      const deviceIds = (result.data?.assetDevices ?? []).reduce<string[]>((deviceIds, assetDevice) => {
        return deviceIds.concat(assetDevice.deviceId);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "assetDevice.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "assetMasterData.articleNumber",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const variables = {
        where: {
          asset: {
            assetMasterData: {
              articleNumber: { [getOperator(field.properties.operator ?? "")]: field.properties.value[0] },
            },
          },
        },
      };

      const result = await client.query<
        GetDeviceIdsOnArticleNumberValueQuery,
        GetDeviceIdsOnArticleNumberValueQueryVariables
      >({
        query: GetDeviceIdsOnArticleNumberValueDocument,
        variables,
      });

      const deviceIds = (result.data?.assetDevices ?? []).reduce<string[]>((deviceIds, assetDevice) => {
        return deviceIds.concat(assetDevice.deviceId);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "assetDevice.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
  {
    key: "assetMasterData.supplier",
    middleWare: async ({ client, fieldName, field, getOperator }) => {
      const variables = {
        where: {
          asset: {
            assetMasterData: {
              supplier: { [getOperator(field.properties.operator ?? "")]: field.properties.value[0] },
            },
          },
        },
      };

      const result = await client.query<GetDeviceIdsOnSupplierValueQuery, GetDeviceIdsOnSupplierValueQueryVariables>({
        query: GetDeviceIdsOnSupplierValueDocument,
        variables,
      });

      const deviceIds = (result.data?.assetDevices ?? []).reduce<string[]>((deviceIds, assetDevice) => {
        return deviceIds.concat(assetDevice.deviceId);
      }, []);

      const nextField: JsonRule = {
        properties: {
          field: "assetDevice.deviceId",
          operator: "select_any_in",
          value: [deviceIds],
          valueSrc: ["value"],
          valueType: ["multiselect"],
        },
        type: "rule",
      };
      return { field: nextField, fieldName };
    },
  },
];
