import React, { useState } from "react";
import { StorageAreaUpdateForm } from "raft/StorageAreaForm";

import { useHistory, useParams } from "react-router-dom";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { styled } from "@mui/material/styles";
import translations from "translations";
import getTestIDs from "lib/utils/getTestIDs";
import Toast from "components/atomics/Toast";
import L from "leaflet";
import { useRecoilState } from "recoil";
import { toastStateAtom } from "components/atomics/Toast/toastState";

export const testIDs = getTestIDs();

export const UpdateStorageAreaFormSC = styled("div")({
  height: "calc(100vh - 108px)",
  display: "flex",
});

const UpdateGeofence: React.FC<{}> = () => {
  const { storageAreaId }: { storageAreaId: string } = useParams();
  const [openToast, setOpenToast] = useState(false);
  const [toastObject, setToastObject] = useRecoilState(toastStateAtom);
  const history = useHistory();
  const [state, setstate] = useState(false);
  const close = () => {
    try {
      setToastObject({
        open: true,
        message: translations.pages.fleetInventory.toastMessageUpdate.success,
        severity: "success",
      });
      history.push("/fleetInventory");
    } catch (error) {
      console.log(error);
      setToastObject({
        open: true,
        message: translations.pages.fleetInventory.toastMessageUpdate.error,
        severity: "error",
      });
      history.push("/fleetInventory");
    }
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };
  return (
    <UpdateStorageAreaFormSC>
      <LoadingSpinner loading={state} testId={testIDs.addGeofencesLoader}>
        <div>
          <StorageAreaUpdateForm
            id={storageAreaId}
            onCancel={close}
            afterUpdate={close}
            beforeRemoteMuatation={(p) => {
              return {
                ...p.data,
              };
            }}
            onUpdateDataMiddleWare={(p) => {
              if (!p.data.storageAreaName?.length ?? false) {
                return {
                  ...p.data,
                  disableStep: 1,
                  noPolygon: 1,
                };
              }

              if (!p.data.geofence?.coordinates) {
                return {
                  ...p.data,
                  disableStep: 1,
                  noPolygonWarning: translations.pages.fleetInventory.noPolygonWarning,
                  noPolygon: 0,
                };
              }
              if (p.data.geofence) {
                const alowedPolygonSize = 455;
                const polygon = L.rectangle(p.data.geofence?.coordinates);
                const polygonSize = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0] as L.LatLngLiteral[]) * 0.005;
                if (polygonSize < alowedPolygonSize) {
                  setOpenToast(true);
                  return {
                    ...p.data,
                    disableStep: 1,
                    noPolygonWarning: translations.pages.fleetInventory.noPolygonWarning,
                    noPolygon: 0,
                  };
                }
              }

              //@ts-ignore
              if (!!p.data.formHasErrors && p.data.submit) {
                return {
                  ...p.data,
                  //@ts-ignore
                  submit: !p.data.formHasErrors,
                };
              }
              return {
                ...p.data,
                disableStep: 0,
                noPolygonWarning: "",
                noPolygon: 1,
                formHasErrors:
                  !p.data.storageAreaTypeId || !p.data.storageAreaName || !p.data.geofence?.coordinates ? 1 : 0,
              };
            }}
          />
        </div>
      </LoadingSpinner>
      <Toast
        open={openToast}
        message={translations.pages.fleetInventory.smallGeofenceWarning}
        handleCloseToast={handleCloseToast}
        severity="error"
      />
    </UpdateStorageAreaFormSC>
  );
};

export default UpdateGeofence;
