import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetMasterDataForAssetDetailsViewQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetAssetMasterDataForAssetDetailsViewQuery = { asset?: Maybe<{ __typename?: 'Asset', customUniqueIdentifier: string, assetAttributeValue: Array<{ __typename?: 'AssetAttributeValue', id: string, assetAttributeId: string, value: string, assetAttribute: { __typename?: 'AssetAttribute', id: string, attributeName: string } }>, assetImage: Array<{ __typename?: 'AssetImage', id: string, pictureBlobStorageUrl: string, thumbnailBlobStorageUrl?: Maybe<string>, createdAt: string, description?: Maybe<string> }>, assetSource?: Maybe<{ __typename?: 'AssetSource', id: string, assetSourceName: string }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, articleNumber?: Maybe<string>, supplier?: Maybe<string>, itemConstructionNumber?: Maybe<string>, serialNumber?: Maybe<string>, generalItem?: Maybe<string> }>, assetDevice: Array<{ __typename?: 'AssetDevice', id: string, device: { __typename?: 'Device', id: string, deviceName: string, lastConnected?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, utcTimeMeasured: string }>>>, sensorMeasurementCurrentValue?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, valueString?: Maybe<string>, valueInt?: Maybe<number>, capability?: Maybe<{ __typename?: 'Capability', id: string, capabilityName: string, deviceModelCapability: Array<{ __typename?: 'DeviceModelCapability', id: string, unit: { __typename?: 'Unit', id: string, unitSymbol: string } }> }> }>>> } }> }> };


export const GetAssetMasterDataForAssetDetailsViewDocument = gql`
    query getAssetMasterDataForAssetDetailsView($id: UUID!) {
  asset(id: $id) {
    customUniqueIdentifier
    assetAttributeValue(where: {assetAttributeId: {in: ["de591b1e-16e7-4509-a5b3-449bda2892b9", "89f8ef48-cf7e-48c9-a93e-8ba58B85d91e", "f6b88b64-0c55-4e10-8394-eb72173b0b07"]}}) {
      id
      assetAttributeId
      assetAttribute {
        id
        attributeName
      }
      value
    }
    assetImage {
      id
      pictureBlobStorageUrl
      thumbnailBlobStorageUrl
      createdAt
      description
    }
    assetSource {
      id
      assetSourceName
    }
    assetMasterData {
      id
      articleNumber
      supplier
      itemConstructionNumber
      serialNumber
      generalItem
    }
    assetDevice(where: {dateTo: {gte: "9999-12-31T23:59:59.999Z"}}) {
      id
      device {
        id
        deviceName
        lastConnected: sensorMeasurementCurrentValue(order: {utcTimeMeasured: DESC}, take: 1) {
          id
          utcTimeMeasured
        }
        sensorMeasurementCurrentValue(where: {capabilityId: {in: ["5063bcd9-9e7b-42f6-84b4-eeac931a2b1f", "80bec84b-2c50-4e12-9a70-56b06e5b92e5"]}}) {
          id
          valueString
          valueInt
          capability {
            id
            capabilityName
            deviceModelCapability {
              id
              unit {
                id
                unitSymbol
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssetMasterDataForAssetDetailsViewQuery__
 *
 * To run a query within a React component, call `useGetAssetMasterDataForAssetDetailsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetMasterDataForAssetDetailsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetMasterDataForAssetDetailsViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetMasterDataForAssetDetailsViewQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAssetMasterDataForAssetDetailsViewQuery, GetAssetMasterDataForAssetDetailsViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetMasterDataForAssetDetailsViewQuery, GetAssetMasterDataForAssetDetailsViewQueryVariables>(GetAssetMasterDataForAssetDetailsViewDocument, options);
      }
export function useGetAssetMasterDataForAssetDetailsViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetMasterDataForAssetDetailsViewQuery, GetAssetMasterDataForAssetDetailsViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetMasterDataForAssetDetailsViewQuery, GetAssetMasterDataForAssetDetailsViewQueryVariables>(GetAssetMasterDataForAssetDetailsViewDocument, options);
        }
export type GetAssetMasterDataForAssetDetailsViewQueryHookResult = ReturnType<typeof useGetAssetMasterDataForAssetDetailsViewQuery>;
export type GetAssetMasterDataForAssetDetailsViewLazyQueryHookResult = ReturnType<typeof useGetAssetMasterDataForAssetDetailsViewLazyQuery>;
export type GetAssetMasterDataForAssetDetailsViewQueryResult = Apollo.QueryResult<GetAssetMasterDataForAssetDetailsViewQuery, GetAssetMasterDataForAssetDetailsViewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
