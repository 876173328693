import React, { useEffect, useState } from "react";
import { DateRange, Query, TimeDimension } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { GridColDef, GridRowParams, GridValidRowModel } from "@mui/x-data-grid-pro";
import Table from "components/templates/dataGridTable/Table";
import translations from "translations";
import { LeftTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import { Box, styled, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { dateRangeState } from "../WorkloadOverviewTile/charts/filtersState";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

interface WorkingData {
  "DimAssetChild.childGeneralitemname": string;
  "FactUtilization.count": number;
  "FactUtilization.dimassetchildid": string;
  "FactUtilization.utilizationhoursMeasure": number;
}

const LabelSC = styled("div")({
  marginBottom: "16px",
});

const dimensions = ["DimAssetChild.childGeneralitemname", "FactUtilization.dimassetchildid"];

const CouplingOverviewTile: React.FC<{ assetId: string }> = ({ assetId }) => {
  const history = useHistory();
  //  const selectedDate = useRecoilValue(dateRangeState);
  const query: Query = {
    dimensions,
    measures: ["FactUtilization.utilizationhoursMeasure", "FactUtilization.count"],
    filters: [
      {
        member: "FactUtilization.dimassetid",
        operator: "equals",
        values: [assetId],
      },
    ],
    // timeDimensions: [
    //   {
    //     dimension: "DimDate.date",
    //     granularity: "day",
    //     dateRange: selectedDate,
    //   },
    // ],
  };

  const { resultSet, isLoading, progress, error } = useCubeQuery(query);

  const columns: GridColDef<WorkingData>[] = [
    {
      field: "DimAssetChild.childGeneralitemname",
      headerName: `Assets`,
      flex: 3,
      valueGetter: (params) => {
        return params.row["DimAssetChild.childGeneralitemname"] ?? "";
      },
    },

    {
      field: "FactUtilization.utilizationhoursMeasure",
      headerName: `Total coupling time (Hrs)`,
      flex: 2,
      valueGetter: (params) => {
        return params.row["FactUtilization.utilizationhoursMeasure"] ?? "";
      },
    },
    {
      field: "FactUtilization.count",
      headerName: `Coupled`,
      flex: 2,
      valueGetter: (params) => {
        return `${params.row["FactUtilization.count"]}x` ?? "";
      },
    },
  ];

  const workingData = resultSet?.rawData() as WorkingData[];
  const { label } = translations.pages.assetManagement.assetDetails.couplingOverviewTile;

  const handleRowClick = (params: GridRowParams) => {
    history.push(`/assetManagement/assetDetails/${params.id}`);
  };

  return (
    <LeftTileWrapperSC>
      <LabelSC>
        <Typography variant="h4">{label}</Typography>
      </LabelSC>
      {!error && !isLoading && (
        <Table
          noDataMessage={translations.pages.assetManagement.noAssetsMessage}
          columns={columns}
          rows={workingData ?? ([] as readonly GridValidRowModel[])}
          getRowId={(rows: WorkingData) => rows["FactUtilization.dimassetchildid"]}
          checkboxSelection={false}
          tableHeight="30vh"
          hasToolbar={false}
          tableContainerMargin="0"
          handleOnRowClick={handleRowClick}
        />
      )}
    </LeftTileWrapperSC>
  );
};

export default CouplingOverviewTile;
