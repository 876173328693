import { GridRowId, GridValidRowModel } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { isFormSubmittedAtom, SelectedAsset, selectedAssetsForAlertAtom } from "components/pages/AlertManagement/AlertEditing/recoilState";

import {
  preMappedDeviceNotificationsForGateWayAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState/selectedDevicesForAlertState";
import { useGetDevicesForMappingDeviceNotificationsQuery } from "graphqlBase/Devices/__generated__/getDevicesForMappingDeviceNotifications";
import { useGetMappingDeviceNotificationsForNotificationLazyQuery } from "graphqlBase/MappingDeviceNotifications/__generated__/getMappingDeviceNotificationsForNotification";
import { useGetNotificationScopeByNotificationIdLazyQuery } from "graphqlBase/Notifications/__generated__/getNotificationScopeByNotificationId";
import { useEffect } from "react";
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SelectedDevice } from ".";

export default ({
  notificationId,
  apiRef,
  selectedIds,
  setSelectedIds
}: {
  notificationId?: string;
  apiRef?: React.MutableRefObject<GridApiPro>;
  selectedIds:SelectedDevice[];
  setSelectedIds: SetterOrUpdater<SelectedDevice[]>;
}) => {
  const setSelectedAssets = useSetRecoilState(selectedAssetsForAlertAtom);
  const isFormSubmitted = useRecoilValue(isFormSubmittedAtom);
  const [preMappedDeviceNotifications, setPreMappedDeviceNotifications] = useRecoilState(
    preMappedDeviceNotificationsForGateWayAtom
  );
  const { data: devicesData, loading: devicesLoading } = useGetDevicesForMappingDeviceNotificationsQuery();

  const [query, { data, loading }] = useGetMappingDeviceNotificationsForNotificationLazyQuery({
    fetchPolicy: "network-only",
  });
  const [fetchNotificationScope, { data: scopesData }] = useGetNotificationScopeByNotificationIdLazyQuery({
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (!notificationId) return;
    fetchNotificationScope({ variables: { id: notificationId } });
  }, [notificationId]);

  useEffect(() => {
    if (scopesData && scopesData.notification?.notificationScope.id === "2c920815-0262-4125-932c-3a9be981d8ca") {
      query({
        variables: {
          notificationMappingFilter: { notificationId: { eq: notificationId } },
        },
      });
    }
  }, [scopesData]);
  useEffect(() => {
    if (!selectedIds) return;

    setSelectedAssets((prevState) => {
      const deletedAssets = (prevState ?? [])?.reduce<SelectedAsset[]>((acc, curr) => {
        const isDeviceSelectedInAssetsTable = selectedIds?.find((item) => item.id === curr.assetDevice[0].deviceId);
        if (!isDeviceSelectedInAssetsTable) return acc.concat({ ...curr, toDelete: true });
        else return acc;
      }, []);
      return deletedAssets;
    });
  }, [selectedIds]);
  // set selected devices
  useEffect(() => {
    if (!data?.mappingDeviceNotifications || !devicesData || !apiRef?.current || isFormSubmitted) return;
    let selectedOnUi = [...(selectedIds ?? [])];

    const preSelected = (data?.mappingDeviceNotifications ?? []).reduce<SelectedDevice[]>((acc, current) => {
      const selectedAsset = devicesData?.devices?.find((device) => device?.id === current.deviceId);
      if (!selectedAsset) return acc;
      const toDelete = selectedIds?.find((selected) => selected?.id === current.deviceId)?.toDelete ?? false;
      selectedOnUi = selectedOnUi.filter((item) => item?.id !== current.deviceId);
      return acc.concat({ ...selectedAsset, toDelete });
    }, []);

    setSelectedIds(preSelected.concat(selectedOnUi));

    if (!preMappedDeviceNotifications) setPreMappedDeviceNotifications(data?.mappingDeviceNotifications);
  }, [data, devicesData]);

  const setSelectedRows = (rows: readonly GridValidRowModel[]) => {
    const deviceIDsOnUi = apiRef?.current?.getAllRowIds ? apiRef?.current?.getAllRowIds() : [];
    const checkedIds = rows.map(({ id }) => id) as GridRowId[];
    setSelectedIds((preSelectedDevices) => {
      const selectedDevicesNextPre = (preSelectedDevices ?? []).reduce<SelectedDevice[]>((acc, curr) => {
        if (!deviceIDsOnUi.includes(curr.id)) return acc.concat(curr as SelectedDevice);

        if (checkedIds.includes(curr.id)) return acc.concat({ ...curr, toDelete: false });

        return acc.concat({ ...curr, toDelete: true });
      }, []);

      return rows.reduce<SelectedDevice[]>((acc, curr) => {
        if (acc.find((e) => e.id === curr.id)) return acc;

        return acc.concat(curr as SelectedDevice);
      }, selectedDevicesNextPre).filter((item) => !item.toDelete);
    });
  };

  return { loading: loading || devicesLoading, setSelectedRows };
};
