import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMappingDeviceConfigurationDevicesMutationVariables = Types.Exact<{
  mappingDeviceConfigurationDevices?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingDeviceConfigurationDeviceUpdateType>> | Types.InputMaybe<Types.MappingDeviceConfigurationDeviceUpdateType>>;
}>;


export type UpdateMappingDeviceConfigurationDevicesMutation = { updateMappingDeviceConfigurationDevices?: Maybe<Array<Maybe<{ __typename?: 'MappingDeviceConfigurationDevice', id: string, deviceId: string, deviceConfigurationId: string, dateFrom: string, dateTo: string, deviceConfiguration: { __typename?: 'DeviceConfiguration', id: string, configurationJSON: string } }>>> };


export const UpdateMappingDeviceConfigurationDevicesDocument = gql`
    mutation updateMappingDeviceConfigurationDevices($mappingDeviceConfigurationDevices: [MappingDeviceConfigurationDeviceUpdateType]) {
  updateMappingDeviceConfigurationDevices(mappingDeviceConfigurationDevices: $mappingDeviceConfigurationDevices) {
    id
    deviceId
    deviceConfigurationId
    dateFrom
    dateTo
    deviceConfiguration {
      id
      configurationJSON
    }
  }
}
    `;
export type UpdateMappingDeviceConfigurationDevicesMutationFn = Apollo.MutationFunction<UpdateMappingDeviceConfigurationDevicesMutation, UpdateMappingDeviceConfigurationDevicesMutationVariables>;

/**
 * __useUpdateMappingDeviceConfigurationDevicesMutation__
 *
 * To run a mutation, you first call `useUpdateMappingDeviceConfigurationDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMappingDeviceConfigurationDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMappingDeviceConfigurationDevicesMutation, { data, loading, error }] = useUpdateMappingDeviceConfigurationDevicesMutation({
 *   variables: {
 *      mappingDeviceConfigurationDevices: // value for 'mappingDeviceConfigurationDevices'
 *   },
 * });
 */
export function useUpdateMappingDeviceConfigurationDevicesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMappingDeviceConfigurationDevicesMutation, UpdateMappingDeviceConfigurationDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMappingDeviceConfigurationDevicesMutation, UpdateMappingDeviceConfigurationDevicesMutationVariables>(UpdateMappingDeviceConfigurationDevicesDocument, options);
      }
export type UpdateMappingDeviceConfigurationDevicesMutationHookResult = ReturnType<typeof useUpdateMappingDeviceConfigurationDevicesMutation>;
export type UpdateMappingDeviceConfigurationDevicesMutationResult = Apollo.MutationResult<UpdateMappingDeviceConfigurationDevicesMutation>;
export type UpdateMappingDeviceConfigurationDevicesMutationOptions = Apollo.BaseMutationOptions<UpdateMappingDeviceConfigurationDevicesMutation, UpdateMappingDeviceConfigurationDevicesMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
