
import {
  GetDevicesForMappingDeviceNotificationsQuery,
  ResultType,
  useGetDevicesForMappingDeviceNotificationsLazyQuery,
} from "graphqlBase/Devices/__generated__/getDevicesForMappingDeviceNotifications";
import React, { useMemo } from "react";
import translations from "translations";
import useDataGrid, { useFilterTableSelectedRows } from "components/templates/dataGridTable";
import {  selectedDevicesForAlertAtom } from "components/pages/AlertManagement/AlertEditing/recoilState/selectedDevicesForAlertState";
import { getGridStringOperators, GridRowId } from "@mui/x-data-grid-pro";
import useManageMappingDeviceNotificationsForGateway from "./useManageMappingDeviceNotificationsGateway";
import TagChips from "components/molecules/TagChips";
export type Device = ResultType<GetDevicesForMappingDeviceNotificationsQuery["devices"]>;

const variables = {};

interface MappingDeviceNotificationsProps {
  notificationId: string;
}
export interface SelectedDevice extends Device {
  toDelete: boolean;
}
const MappingDeviceNotificationsTable: React.FC<MappingDeviceNotificationsProps> = ({ notificationId }) => {
  const [queryOuter, { data, loading:tableLoading,error }] = useGetDevicesForMappingDeviceNotificationsLazyQuery();
  const {
    query,
    selectedIds,
    setSelectedIds,
    setSelectedRowsFilterOn,
    selectedRowsFilterOn,
  } = useFilterTableSelectedRows({
    queryOuter,
    atom: selectedDevicesForAlertAtom,
    getIdsFromAtom: (rows) => rows.map((row) => row.id),
    entity: "device",
  });
  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef,getRowCount } = useDataGrid<Device, "Device">({
    query,
    variables:variables,
    tableId: `MappingAssetDeviceNotifications_${notificationId}`,
    persistance: "client",
  });
  const { loading, setSelectedRows } = useManageMappingDeviceNotificationsForGateway({
    notificationId,
    apiRef,
    selectedIds,
    setSelectedIds
  });
  
  const selectedRows = useMemo(
    () =>
      (selectedIds ?? [])?.reduce<GridRowId[]>((deviceIds, element) => {
         return deviceIds.concat(element.id);
      }, []),
    [selectedIds]
  );
  const columns = useMakeColumns(
    [
      {
        field: "deviceName",
        headerName: `Device name`,
        renderHeader: (params) => `Device name (${getRowCount()})`,
        flex: 1.5,
        valueGetter: (params) => {
          return params.row.deviceName ?? "";
        },
        remoteOrder: (sort) => ({ deviceName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ filterValue, where }) => ({ deviceName: filterValue }),
        }),
        type: "string",
      },
      {
        field: "deviceTypeName",
        headerName: translations.entities.deviceType.columns.deviceTypeName,
        renderHeader: (params) => `Device Type`,
        flex: 1.5,
        valueGetter: ({ row }) => {
          return row.deviceType?.deviceTypeName ?? "";
        },
        remoteOrder: (sort) => ({ deviceType: { deviceTypeName: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            deviceType: { deviceTypeName: { and: [{ neq: "M2CP" }, filterValue] } },
          }),
        }),
        type:"string"
      },
      {
        field:"serialNo",
        headerName: translations.entities.device.columns.serialNo,
        flex: 1.5,
        valueGetter: ({ row }) => {
          return row.serialNo ?? "";
        },
        remoteFilter: makeSeverSideFilter("string",{
          filterPath: ({ filterValue, where }) =>( {serialNo:  filterValue  }),
        }),
        remoteOrder: (sort ) => ({ serialNo: sort }),
        type:"string"
      },
      {
        field: "firmware",
        headerName: `Firmware`,
        flex: 1,
        valueGetter: (params) => {
          return params.row.mappingDeviceFirmwareDevice?.[0]?.deviceFirmware?.firmwareVersion ?? "";
        },
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 1.5,
        valueGetter: (params) => {
          return params.row.mappingDeviceTag?.map((entry) => entry.tag?.tagValue ?? "") ?? null;
        },
        renderCell: (params) => {
          return <TagChips mappingDeviceTag={params.row.mappingDeviceTag} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators().filter(
            (operator) =>
              operator.value !== "isEmpty" &&
              operator.value !== "equals" &&
              operator.value !== "isNotEmpty" &&
              operator.value !== "isAnyOf"
          ),
          filterPath: ({ where, filterValue }) => ({
            mappingDeviceTag: { some: { tag: { tagValue: filterValue } } },
          }),
        }),
        type: "stringArray",
      },
    ],
    []
  );

  return (
    <>
    {!error && (
        <DataGrid
          noDataMessage={translations.pages.deviceManagement.noDeviceMessage}
          tableTitle={translations.pages.deviceManagement.myDevices}
          selectedRowsFilterOn={selectedRowsFilterOn}
          setSelectedRowsFilterOn={setSelectedRowsFilterOn}
          tableHeight="40vh"
          columns={columns}
          rows={data?.devices}
          hasOnlyDefaultToolbar
          loading={tableLoading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          keepNonExistentRowsSelected
          withMargin={false}
        />
        
      )}
      
      </>
  )
  }

export default MappingDeviceNotificationsTable;
