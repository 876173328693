import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { atom, AtomEffect, RecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { PersistTypes } from "./types";

type Persisister = {
  [key in PersistTypes]: AtomEffect<any>[];
};

interface TableStateExtended extends GridInitialStatePro {}
interface TablesStates {
  [key: string]: RecoilState<TableStateExtended | undefined>;
}

const tableStates: TablesStates = {};

const { persistAtom: sessionStoragePersist } = recoilPersist({ storage: sessionStorage });
const { persistAtom } = recoilPersist();

const persister: Persisister = {
  runTime: [],
  session: [sessionStoragePersist],
  client: [persistAtom],
};

export function makeGridState(id: string, persistType?: PersistTypes) {
  if (!persistType) return tableStates.DummyTableState;

  if (tableStates[id]) {
    return tableStates[id];
  }

  const state = atom<TableStateExtended | undefined>({
    key: `TableState${id}`,
    effects_UNSTABLE: [...persister[persistType]],
    default: undefined,
  });
  tableStates[id] = state;
  return state;
}

export const dummyTableState = atom<TableStateExtended | undefined>({
  key: `DummyTableState`,
  default: undefined,
});
dummyTableState.__cTag = () => {};
tableStates.DummyTableState = dummyTableState;
