import { styled } from "@mui/material";

export const paperStyles = (maxWidth: number | string | undefined, minWidth: number | string | undefined) => {
  return {
    width: maxWidth ?? "30vw",
    height: "calc(100% - 90px)",
    top: 90,
    boxShadow: "0px 10px 50px 10px rgba(0, 0, 0, 0.17)",
    borderLeft: "none",
    maxWidth,
    minWidth,
    display: "flex",
    zIndex: 49,
  };
};

export const CloseIconBoxSC = styled("div")(
  () =>
    `
position: absolute;
top: 20;
left: 20;
cursor: pointer;
`
);

export const DrawerContentSC = styled("div")(
  () =>
    `
display: flex;
 justify-content: space-between;
  flex-direction: column;
   height: 100%;
`
);

export const DrawerMainContentSC = styled("div")(
  () =>
    `
display: flex;
  flex-direction: column;
`
);

export const DrawerHeaderSC = styled("div")(
  () =>
    `
margin: 30px 20px;
`
);

export const DrawerTitleBoxSC = styled("div")(
  () =>
    `
text-align: center;
`
);
