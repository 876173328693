import { getGridStringOperators, GridRowParams, GridValidRowModel } from "@mui/x-data-grid-pro";
import TagChips from "components/molecules/TagChips";
import AssetDeviceUnpairing from "components/organisms/AssetDeviceUnpairing";
import { assetDeviceUnpairingAtom } from "components/organisms/AssetDeviceUnpairing/recoilState";
import { DeviceDetailsAtom } from "components/pages/DeviceManagement/ChartCollection/recoilState";
import PLTextlink from "components/patternLib/PLTextlink";
import useDataGrid, { useFilterTableSelectedRows } from "components/templates/dataGridTable";
import { ActionsCell } from "components/templates/dataGridTable/Cells";
import {
  GetAssetsForAssetOverviewQuery,
  ResultType,
  useGetAssetsForAssetOverviewLazyQuery,
} from "graphqlBase/Assets/__generated__/getAssetsForAssetOverview";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { formatTimeDistance } from "translations/formatter";
import TagModal from "../TagModal";
import { selectedAssetsForTagsAtom } from "../TagModal/tagModalState";
import { assetDeviceApplicationState, lhbDeviceState } from "../utils";

export const testIDs = getTestIDs();

interface SelectedAsset extends Asset {
  toDelete: boolean;
}

export type Asset = ResultType<GetAssetsForAssetOverviewQuery["assets"]>;
const allValidAssets = {
  where: {
    assetMasterDataId: { neq: null },
  },
};

const AssetOverview: React.FC<{}> = () => {
  const history = useHistory();
  const [isTagModalOpen, setIsTagModalOpen] = useState<boolean>(false);

  const setUnpairState = useSetRecoilState(assetDeviceUnpairingAtom);
  const setSelectedDevice = useSetRecoilState(DeviceDetailsAtom);

  const [queryOuter, { data, loading, error, refetch }] = useGetAssetsForAssetOverviewLazyQuery({
    fetchPolicy: "network-only",
  });

  const {
    query,
    selectedIds,
    setSelectedIds,
    setSelectedRowsFilterOn,
    selectedRowsFilterOn,
  } = useFilterTableSelectedRows({
    queryOuter,
    atom: selectedAssetsForTagsAtom,
    getIdsFromAtom: (rows) => rows.map((row) => row.id),
    entity: "asset",
  });

  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef, getRowCount } = useDataGrid<Asset, "Asset">({
    query,
    variables: allValidAssets,
    tableId: "AssetOverview",
    persistance: "runTime",
  });

  const afterMutation = useCallback(async () => {
    if (refetch) await refetch();
  }, [refetch]);

  const handleUnpairAsset = (row: Asset) => {
    setUnpairState({
      deviceName: row?.assetDevice?.[0]?.device?.deviceName ?? "",
      assetName: row?.assetMasterData?.generalItem ?? "",
      variables: {
        deviceSerialNumber: row?.assetDevice?.[0]?.device?.serialNo ?? "",
        assetCustomUniqueIdentifier: row?.customUniqueIdentifier ?? "",
      },
    });
  };

  const columns = useMakeColumns(
    [
      {
        field: "assetItem",
        headerName: `Article name`,
        renderHeader: () => `Article name (${getRowCount()})`,
        flex: 2,
        valueGetter: (params) => {
          return params.row.assetMasterData?.generalItem ?? "";
        },
        remoteOrder: (sort) => ({ assetMasterData: { generalItem: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            assetMasterData: { generalItem: filterValue },
          }),
        }),
        type: "string",
      },
      {
        field: "articleNumber",
        headerName: `Article`,
        flex: 1,
        remoteOrder: (sort) => ({ assetMasterData: { articleNumber: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            assetMasterData: { articleNumber: filterValue },
          }),
        }),
        valueGetter: (params) => {
          return params.row.assetMasterData?.articleNumber ?? "";
        },
        type: "string",
      },
      {
        field: "serialNumber",
        headerName: `Serial Number`,
        flex: 1.5,
        valueGetter: (params) => {
          return params.row.assetMasterData?.serialNumber ?? "";
        },
        remoteOrder: (sort) => ({ assetMasterData: { serialNumber: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            assetMasterData: { serialNumber: filterValue },
          }),
        }),
        type: "string",
      },
      {
        field: "supplier",
        headerName: `Supplier`,
        flex: 1,
        remoteOrder: (sort) => ({ assetMasterData: { supplier: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            assetMasterData: { supplier: filterValue },
          }),
        }),
        valueGetter: (params) => {
          return params.row.assetMasterData?.supplier ?? "";
        },
        type: "string",
      },
      {
        field: "lastMeasurementTime",
        headerName: `Last Connection`,
        flex: 2,
        valueGetter: (params) => {
          const lastTimeConnection = params.row.assetDevice?.[0]?.device?.lastMeasurement?.[0]?.utcTimeMeasured;
          return lastTimeConnection ? formatTimeDistance(new Date(lastTimeConnection)) : "";
        },
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 3,
        valueGetter: (params) => {
          return params.row.mappingAssetTag?.map((entry) => entry.assetTag?.tagValue ?? "") ?? null;
        },
        renderCell: (params) => {
          return <TagChips mappingAssetTag={params.row.mappingAssetTag} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators().filter(
            (operator) =>
              operator.value !== "isEmpty" &&
              operator.value !== "equals" &&
              operator.value !== "isNotEmpty" &&
              operator.value !== "isAnyOf"
          ),
          filterPath: ({ where, filterValue }) => ({
            mappingAssetTag: { some: { assetTag: { tagValue: filterValue } } },
          }),
        }),
        type: "stringArray",
      },
      {
        field: "device",
        headerName: translations.entities.device.name,
        flex: 1.5,
        renderCell: (params) =>
          params.row?.assetDevice?.[0]?.device ? (
            <PLTextlink
              size="small"
              bold={false}
              label={params.row?.assetDevice?.[0]?.device?.serialNo}
              onClick={() => {
                setSelectedDevice({
                  deviceName: params.row?.assetDevice?.[0]?.device?.deviceName ?? "",
                  deviceTypeName: params.row?.assetDevice?.[0]?.device?.deviceType?.deviceTypeName ?? "",
                  serialNo: params.row?.assetDevice?.[0]?.device?.serialNo ?? "",
                });
                history.push(`/deviceManagement/deviceDetails/${params.row.assetDevice[0].deviceId}`);
              }}
            />
          ) : (
            <p style={{ color: "#E52828" }}>{translations.pages.assetManagement.noDeviceText}</p>
          ),
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators().filter(
            (operator) =>
              operator.value !== "isEmpty" &&
              operator.value !== "equals" &&
              operator.value !== "isNotEmpty" &&
              operator.value !== "isAnyOf"
          ),
          filterPath: ({ where, filterValue }) => ({
            assetDevice: { some: { device: { serialNo: filterValue } } },
          }),
        }),
        type: "string",
      },
      {
        field: "sensorMeasurementCurrentValue",
        headerName: `Status & State`,
        flex: 1.5,
        type: "string",
        renderCell: (params) => {
          if (params.row?.assetDevice?.[0]?.device) {
            const isCranePart = params.row?.assetSource?.assetSourceName.includes("CRANE");
            return params.row.assetDevice?.[0]?.device?.sensorMeasurementCurrentValue
              ?.slice(0, 1)
              ?.map((value) => {
                if (isCranePart) return assetDeviceApplicationState[value?.valueInt ?? 0].label;
                else {
                  const state = lhbDeviceState[value?.valueInt ?? 0];
                  return `${state.label}${state.extendedLabel}`;
                }
              })
              ?.join(", ");
          }
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <ActionsCell handleUnpair={params?.row?.assetDevice?.[0] ? () => handleUnpairAsset(params.row) : undefined} />
        ),
        type: "ReactElement",
        valueType: "string",
        disableColumnMenu: true,
      },
    ],
    []
  );

  const handleOnRowClick = (params: GridRowParams) => {
    history.push(`/assetManagement/assetDetails/${params.row.id}`);
  };

  const selectedRows = useMemo(
    () =>
      selectedIds?.reduce<string[]>((assetIds, element) => {
        return assetIds.concat(element.id);
      }, []),
    [selectedIds]
  );

  const handleSelectRow = (rows: readonly GridValidRowModel[]) => {
    setSelectedIds((selectedAssets) => {
      const assetIDsOnUi = apiRef.current.getAllRowIds ? apiRef.current.getAllRowIds() : [];
      const chekedIds = rows.map(({ id }) => id) as string[];
      const SelectedUsersNextPre = selectedAssets.reduce<SelectedAsset[]>((acc, curr) => {
        if (!assetIDsOnUi.includes(curr.id)) return acc.concat(curr as SelectedAsset);
        if (chekedIds.includes(curr.id)) {
          return acc.concat({ ...curr, toDelete: false });
        } else {
          return acc.concat({ ...curr, toDelete: true });
        }
      }, []);
      return rows
        .reduce<SelectedAsset[]>((acc, curr) => {
          if (acc.find((e) => e.id === curr.id)) return acc;

          return acc.concat(curr as SelectedAsset);
        }, SelectedUsersNextPre)
        .filter((item) => !item.toDelete);
    });
  };

  return (
    <>
      <AssetDeviceUnpairing afterMutation={afterMutation} />
      <TagModal
        afterMutation={afterMutation}
        open={isTagModalOpen}
        onClose={() => setIsTagModalOpen(false)}
        loading={loading}
      />
      {!error && (
        <DataGrid
          noDataMessage={translations.pages.assetManagement.noAssetsMessage}
          tableTitle={translations.pages.assetManagement.myAssets}
          tableHeight="65vh"
          handleOnRowClick={handleOnRowClick}
          selectedRowsFilterOn={selectedRowsFilterOn}
          setSelectedRowsFilterOn={setSelectedRowsFilterOn}
          columns={columns}
          rows={data?.assets}
          setSelectedRows={handleSelectRow}
          setIsTagModalOpen={setIsTagModalOpen}
          selectedRows={selectedRows}
          loading={loading}
          keepNonExistentRowsSelected
        />
      )}
    </>
  );
};

export default AssetOverview;
