import { Typography } from "@mui/material";
import React from "react";
import translations from "translations";

const DefineStorageAreaText = () => {
  return (
    <div>
      <Typography sx={{ marginBottom: "10px" }} variant="h6">
      {translations.pages.fleetInventory.defineAreaLabel}
      </Typography>
      <Typography variant="copy">
       {translations.pages.fleetInventory.defineAreaText}
      </Typography>
    </div>
  );
};

export default DefineStorageAreaText;
