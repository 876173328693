import { Typography } from "@mui/material";
import PLIcon from "components/patternLib/PLIcon";
import { NoDataSummaryWrapperSC } from "./styles";
import React from "react";

interface NoDataSummaryTileProps {
  iconName?: string;
  message: string;
}

export const NoDataSummaryTile = ({ message, iconName }: NoDataSummaryTileProps) => {
  return (
    <NoDataSummaryWrapperSC>
      {iconName ? <PLIcon size="64px" iconName={iconName} /> : null}
      <Typography sx={{ display: "flex", textAlign: "center", width: "60%" }} variant="copy">
        {message}
      </Typography>
    </NoDataSummaryWrapperSC>
  );
};
