import { ChartPivotRow, Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { Box } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { round } from "lodash";
import React, { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { tooltipCommonStyles } from "../../../styles";
import { filtersSelector, RecoilFilterType } from "../../filtersState";
import { AssetNameKey, getColorForAssetState } from "../../getColorForState";
import { RootChartContainerSC } from "../../styles";
import { getMonthName } from "./getMonthNames";
import useMakeMemoizedCharts from "../../hooks/useMakeMemoizedCharts";

const dimensions = ["DimDate.Month", "DimDeviceState.devicestatename"];

const AssetUtilizationBarChart = () => {
  const [filters, setFilters] = useRecoilState(filtersSelector);
  const chartInstance: MutableRefObject<any> = useRef();

  const query: Query = {
    measures: ["FactUtilization.utilizationhoursMeasure"],
    order: {
      "FactUtilization.utilizationhoursMeasure": "desc",
    },
    dimensions,
    segments: ["FactUtilization.sfDeviceState"],
    filters: filters.filter((elem) => elem.member !== dimensions[0]),
  };

  const pivotConfig = {
    x: ["measures"],
    y: ["DimDate.Month"],
    fillMissingDates: true,
  };
  const xValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const ownFilterValues = filters
    .filter((elem) => [dimensions[0]].includes(elem.member as string))
    .flatMap((filter) => filter.values);

  const { resultSet, isLoading, error } = useCubeQuery(query);

  const chartLoading = isLoading && !chartInstance?.current;

  const handleClick = useCallback(
    (e: any) => {
      const filter: RecoilFilterType = {
        member: query.dimensions?.[0] ?? "",
        operator: "equals",
        values: [xValues[e.dataIndex].toString()],
      };
      setFilters([filter]);
    },
    [setFilters]
  );

  const totalAmountOfHours = resultSet
    ?.chartPivot(pivotConfig)
    .reduce<{ [key: string]: number }>((acc, item: Partial<ChartPivotRow>) => {
      delete item.x;
      delete item.xValues;
      for (const el of Object.keys(item)) {
        if (el in acc) {
          acc[el] += Number(item[el]);
        } else {
          acc[el] = Number(item[el]);
        }
      }

      return acc;
    }, {});
  const xAxisData = xValues.map(getMonthName);

  const getSeriesData = () =>
    (resultSet?.chartPivot(pivotConfig) ?? []).map((pd) => {
      return {
        type: "bar",
        barWidth: "30px",
        barHeight: "70%",
        stack: "total",
        name: pd.xValues[0] ?? "",
        data: Object.values(xValues).map((item) => {
          const color =
            !ownFilterValues.length || ownFilterValues.includes(`${item}`)
              ? getColorForAssetState((pd.xValues[0] ?? "") as AssetNameKey)
              : `rgba(0,0,0,0.1)`;
          return {
            value: totalAmountOfHours ? round(100 * (pd[item] / totalAmountOfHours[item]), 2) : 0,
            itemStyle: {
              color,
              borderWidth: 3,
            },
          };
        }),
      };
    });

  useEffect(() => {
    if (!resultSet || chartLoading) return;
    const series = getSeriesData();
    chartInstance.current?.setOption({ series }, { replaceMerge: "series" });
  }, [resultSet, filters, chartLoading]);

  const getOption = () => {
    return {
      grid: { left: 20, right: 25, top: 50, bottom: 150 },
      maintainAspectRatio: false,
      tooltip: {
        trigger: "item",
        valueFormatter: (value: number) => `${value}%`,
        textStyle: tooltipCommonStyles,
        position: (pos: Array<number>) => {
          return [pos[0] + 20, pos[1] + 25];
        },
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        showGrid: false,
        splitLine: {
          show: false,
        },
        axisLine: {
          onZero: false,
          offset: [0, 10],
          lineStyle: {
            color: "#6B7278",
          },
        },
        axisTick: {
          lineStyle: {
            color: "#6B7278",
          },
        },
        axisLabel: {
          color: "#51585D",
          rotate: 90,
          fontWeight: 400,
          fontSize: "12px",
        },
      },
      yAxis: { show: false },
      series: getSeriesData(),
    };
  };

  const { MemoizedChart } = useMakeMemoizedCharts({
    options: getOption(),
    isLoading,
    chartInstance,
    chartLoading,
    resultSet,
    handleClick,
    error,
    optionsObjForSetOption: { series: getSeriesData() },
  });

  return (
    <RootChartContainerSC sx={{ minHeight: "400px" }}>
      <LoadingSpinner hideChildrenOnLoad loading={chartLoading}>
        <Box sx={{ width: "100%", height: "100%" }}>{MemoizedChart}</Box>
      </LoadingSpinner>
    </RootChartContainerSC>
  );
};

export default AssetUtilizationBarChart;
