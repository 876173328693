import { Box, Typography, styled } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { LeftTileWrapperSC, NoDataContainerSC } from "components/pages/AssetManagement/tileStyles";
import PLTextlink from "components/patternLib/PLTextlink";
import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import translations from "translations";
import { deviceIdState } from "../../../assetDetailState";
import AssetPositionMap from "./AssetPositionMap";
import PositionHistory from "./PositionHistory";
import { useGetCurrentPosition } from "./useGetCurrentPosition";
import { formatDate, formatTimeDistance } from "translations/formatter";
import { useGetLastTimePositionTrackedLazyQuery } from "graphqlBase/sensorMeasurementCurrentValues/__generated__/getLastTimePositionTracked";
import MapLink from "./MapLink";

const PositionBoxSC = styled("div")(
  () => `
  display: inline-flex;
  margin-top: 1em;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
);

const LocationDotSC = styled("div")(
  () => `
  width: 10px;
  height: 10px;
  background: #5789A4;
  border-radius: 50%;
  margin-right: 5px;
`
);

const CurrentPositionTile: React.FC<{}> = () => {
  const deviceId = useRecoilValue(deviceIdState);
  const [open, setOpenPositionHistory] = useState(false);
  const { coordinates, loading, address, dateFrom } = useGetCurrentPosition(deviceId);
  const [query, { data: timeMeasuredFromSensorMeasurements }] = useGetLastTimePositionTrackedLazyQuery();
  const {
    label,
    noDataMessage,
    firstLocatedDateMessage,
    lastUpdateLocationDateMessage,
  } = translations.pages.assetManagement.assetDetails.currentPositionTile;
  const openPositionHistory = () => {
    setOpenPositionHistory(true);
  };
  useEffect(() => {
    if (!deviceId) return;
    query({
      variables: {
        where: {
          and: [{ deviceId: { eq: deviceId } }, { capabilityId: { eq: "4d891dd6-5e4a-4ccc-bc40-83cda080fff0" } }],
        },
      },
    });
  }, [deviceId]);

  const lastDateTimePositionTracked = timeMeasuredFromSensorMeasurements?.sensorMeasurementCurrentValues[0]
    ?.utcTimeMeasured
    ? timeMeasuredFromSensorMeasurements.sensorMeasurementCurrentValues[0].utcTimeMeasured
    : "";

  return (
    <LeftTileWrapperSC>
      <Typography variant="h4">{label}</Typography>
      <LoadingSpinner sx={{ height: loading ? "45vh" : "fit-content" }} loading={loading ?? false}>
        {coordinates ? (
          <>
            <AssetPositionMap coordinates={coordinates} />
            {address && (
              <>
                <PositionBoxSC>
                  <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                    <LocationDotSC />
                    <Typography variant="copy">
                      {`Coordinates: Latitude ${coordinates[0]}, Longitude ${coordinates[1]}`}
                    </Typography>
                  </Box>
                  <Box>
                    <PLTextlink
                      onClick={openPositionHistory}
                      label="Position history"
                      size="small"
                      bold={false}
                      uppercase={false}
                    />
                  </Box>
                </PositionBoxSC>
                <Box sx={{ marginTop: "0.5%" }}>
                  <Typography variant="copy">
                    {label}: {address}
                  </Typography>
                </Box>
              </>
            )}
            {dateFrom ? (
              <Box sx={{ margin: "0.5% 0" }}>
                <>
                  <Typography variant="copy">{firstLocatedDateMessage} </Typography>
                  <Typography variant="copyBold">{formatDate(new Date(dateFrom))}. </Typography>
                </>
                {lastDateTimePositionTracked && (
                  <>
                    <Typography variant="copy">{lastUpdateLocationDateMessage} </Typography>
                    <Typography variant="copyBold">
                      {formatTimeDistance(new Date(lastDateTimePositionTracked))}
                    </Typography>
                  </>
                )}
              </Box>
            ) : (
              ""
            )}
            <MapLink latitude={coordinates[0]} longitude={coordinates[1]} />
          </>
        ) : (
          !loading && (
            <Box>
              <NoDataContainerSC height="45vh">
                <Typography variant="copy">{noDataMessage}</Typography>
              </NoDataContainerSC>
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <PLTextlink
                  onClick={openPositionHistory}
                  label="Position history"
                  size="small"
                  bold={false}
                  uppercase={false}
                  disabled={!deviceId || !timeMeasuredFromSensorMeasurements?.sensorMeasurementCurrentValues.length}
                />
              </Box>
            </Box>
          )
        )}
        {!!deviceId && (
          <PositionHistory deviceId={deviceId} open={open} setOpenPositionHistory={setOpenPositionHistory} />
        )}
      </LoadingSpinner>
    </LeftTileWrapperSC>
  );
};
export default CurrentPositionTile;
