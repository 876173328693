import { atom } from "recoil";
export interface CreateRole {
  description: string;
  name: string;
}
export interface UpdateRole extends CreateRole {
  id: string;
}

export const initialEditorData = atom<UpdateRole>({
  key: "initialEditorData",
  default: {
    id: "",
    description: "",
    name: "",
  },
});

export const editorData = atom<UpdateRole>({
  key: "editorData",
  default: initialEditorData,
});

export const createRoleData = atom<CreateRole>({
  key: "createRoleData",
  default: {
    description: "",
    name: "",
  },
});

export const isButtonDisabled = atom<boolean>({
  key: "isDisabled",
  default: false,
});
