import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLBreadcrumbProps = DropOnONLh<JSX.PatternlibBreadcrumb>;
export type PLBreadcrumbExtendedProps = PLBreadcrumbProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedBreadcrumb = wrapWc<JSX.PatternlibBreadcrumb>("patternlib-breadcrumb");
export const useRefPLBreadcrumb = () => useRef<HTMLPatternlibBreadcrumbElement>(null);

const PLBreadcrumb = React.forwardRef<HTMLPatternlibBreadcrumbElement, PLBreadcrumbExtendedProps>((props, ref) => {
  const myRef = useRefPLBreadcrumb();
  return (
    <WrappedBreadcrumb ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedBreadcrumb>
  );
});
PLBreadcrumb.displayName = "PLBreadcrumb";

export default PLBreadcrumb;
