import { Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import wip from "../../../images/wip.png";
import React from "react";
import translations from "translations";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        height: 'calc("100vh - 60px")',
        paddingTop: "264px",
        width: 'calc("100vw" - 60px)',
      },
      image: {
        alignSelf: "center",
        height: "180px",
        paddingBottom: "43px",
        width: "180px",
      },
      text: {
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "48px",
        paddingTop: "43px",
        textAlign: "center",
        whiteSpace: "nowrap",
      },
      label: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "48px",
        textAlign: "center",
        whiteSpace: "nowrap",
      },
    }),
  { name: "Development" }
);

const Development: React.FC<{ label: string }> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <img src={wip} alt="WorkInProgress" />
      </div>
      <Typography className={classes.text}>{translations.pages.development.label}</Typography>
      <Typography className={classes.label}>
        {`${props.label} ${translations.pages.development.notAvailable}`}
      </Typography>
    </div>
  );
};

export default Development;
