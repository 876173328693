import ContentHeader from "components/organisms/ContentHeader";
import React from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
import UserAndRolesTable from "./UserAndRolesTable";
import { Button, styled, Box } from "@mui/material";

const DropdownWrapperSC = styled("div")({
  zIndex: 999,
  backgroundColor: "#fff",
  height: "135px",
  padding: "4px 0px 0px 12px",
});
const UserManagement: React.FC<{}> = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/settings/userManagement/userRolesManager`);
  };

  return (
    <Box sx={{ pt: "17px" }}>
      <DropdownWrapperSC>
        <ContentHeader
          rightItems={
            <Button color="primary" onClick={() => handleClick()} key="user-roles-manager-button">
              {translations.pages.userManagement.userRoleManager.label}
            </Button>
          }
        />
      </DropdownWrapperSC>
      <UserAndRolesTable />
    </Box>
  );
};

export default UserManagement;
