import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAssetImagesMutationVariables = Types.Exact<{
  assetImages?: Types.InputMaybe<Array<Types.InputMaybe<Types.AssetImageUpdateType>> | Types.InputMaybe<Types.AssetImageUpdateType>>;
}>;


export type UpdateAssetImagesMutation = { updateAssetImages?: Maybe<Array<Maybe<{ __typename?: 'AssetImage', id: string }>>> };


export const UpdateAssetImagesDocument = gql`
    mutation updateAssetImages($assetImages: [AssetImageUpdateType]) {
  updateAssetImages(assetImages: $assetImages) {
    id
  }
}
    `;
export type UpdateAssetImagesMutationFn = Apollo.MutationFunction<UpdateAssetImagesMutation, UpdateAssetImagesMutationVariables>;

/**
 * __useUpdateAssetImagesMutation__
 *
 * To run a mutation, you first call `useUpdateAssetImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetImagesMutation, { data, loading, error }] = useUpdateAssetImagesMutation({
 *   variables: {
 *      assetImages: // value for 'assetImages'
 *   },
 * });
 */
export function useUpdateAssetImagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssetImagesMutation, UpdateAssetImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAssetImagesMutation, UpdateAssetImagesMutationVariables>(UpdateAssetImagesDocument, options);
      }
export type UpdateAssetImagesMutationHookResult = ReturnType<typeof useUpdateAssetImagesMutation>;
export type UpdateAssetImagesMutationResult = Apollo.MutationResult<UpdateAssetImagesMutation>;
export type UpdateAssetImagesMutationOptions = Apollo.BaseMutationOptions<UpdateAssetImagesMutation, UpdateAssetImagesMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
