import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceMovementHistoryQueryVariables = Types.Exact<{
  deviceIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
  startDate: Types.Scalars['DateTime'];
  endDate: Types.Scalars['DateTime'];
}>;


export type GetDeviceMovementHistoryQuery = { deviceLocationHistory?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementMovementHistory', sensorMeasurementMovementHistoryId: string, deviceId: string, utcTimeMeasured: string, dateFrom: string, dateTo: string, isLast?: Maybe<boolean>, location?: Maybe<{ __typename?: 'GeoJSONPointType', coordinates?: Maybe<[number,number]>, crs: number }>, azureMapsLocations?: Maybe<Array<Maybe<{ __typename?: 'AzureMapReverseBatchResponseItemsContentAddresses', position?: Maybe<string>, address?: Maybe<{ __typename?: 'AzureMapsReverseBatchResponseItemsContentAddress', street?: Maybe<string>, streetNumber?: Maybe<string>, streetNameAndNumber?: Maybe<string>, countrySubdivision?: Maybe<string>, countrySecondarySubdivision?: Maybe<string>, municipality?: Maybe<string>, municipalitySubdivision?: Maybe<string>, country?: Maybe<string>, countryCode?: Maybe<string>, postalCode?: Maybe<string>, freeFormAddress?: Maybe<string> }> }>>> }>>> };


export const GetDeviceMovementHistoryDocument = gql`
    query getDeviceMovementHistory($deviceIds: [UUID!], $startDate: DateTime!, $endDate: DateTime!) {
  deviceLocationHistory(deviceIds: $deviceIds, dateFrom: $startDate, dateTo: $endDate) {
    sensorMeasurementMovementHistoryId
    deviceId
    utcTimeMeasured
    dateFrom
    dateTo
    isLast
    location {
      coordinates
      crs
    }
    azureMapsLocations {
      address {
        street
        streetNumber
        streetNameAndNumber
        countrySubdivision
        countrySecondarySubdivision
        municipality
        municipalitySubdivision
        country
        countryCode
        postalCode
        freeFormAddress
      }
      position
    }
  }
}
    `;

/**
 * __useGetDeviceMovementHistoryQuery__
 *
 * To run a query within a React component, call `useGetDeviceMovementHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceMovementHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceMovementHistoryQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDeviceMovementHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDeviceMovementHistoryQuery, GetDeviceMovementHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceMovementHistoryQuery, GetDeviceMovementHistoryQueryVariables>(GetDeviceMovementHistoryDocument, options);
      }
export function useGetDeviceMovementHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceMovementHistoryQuery, GetDeviceMovementHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceMovementHistoryQuery, GetDeviceMovementHistoryQueryVariables>(GetDeviceMovementHistoryDocument, options);
        }
export type GetDeviceMovementHistoryQueryHookResult = ReturnType<typeof useGetDeviceMovementHistoryQuery>;
export type GetDeviceMovementHistoryLazyQueryHookResult = ReturnType<typeof useGetDeviceMovementHistoryLazyQuery>;
export type GetDeviceMovementHistoryQueryResult = Apollo.QueryResult<GetDeviceMovementHistoryQuery, GetDeviceMovementHistoryQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
