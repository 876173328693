import { ApolloClient } from "@apollo/client";
import { FieldMiddleWares, Fields } from "../types";
import operatorMatches, { getOperator } from "./operatorMatches";
import { operators } from "../config";
import { fillSelect, runFieldMiddleWare } from ".././middleware/middleWareUtils";
import { Field } from "react-awesome-query-builder";

export const firstToLowerCase = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

export const getToInclude = (allowed: string[]) => {
  const existing: string[] = [];
  return Object.entries(operators).reduce<string[]>((toInclude, [label, conf]) => {
    const operatorMatchFound = operatorMatches.find((operatorMatch) => {
      return allowed.some((comp) => {
        return comp === operatorMatch[1] && !existing.includes(operatorMatch[1]);
      });
    });
    if (operatorMatchFound) {
      existing.push(operatorMatchFound[1]);
      return toInclude.concat(operatorMatchFound[0]);
    }
    return toInclude;
  }, []);
};

const DataTypeMatches: Array<[string, string]> = [["String", "text"]];

export const getDatatype = ({ groupEntityFilterDataTypeName }: { groupEntityFilterDataTypeName: string }) =>
  (DataTypeMatches.find(([dataTypeMatch]) => dataTypeMatch === groupEntityFilterDataTypeName) ?? [
    ["String", "text"],
  ])[1] ?? "text";

export const getListValuesToFill = async ({
  setFields,
  fieldsPre,
  client,
  fieldMiddleWares,
}: {
  client: ApolloClient<object>;
  fieldMiddleWares?: FieldMiddleWares;
  fieldsPre: Fields;
  setFields: React.Dispatch<React.SetStateAction<Fields>>;
}) => {
  const listValuesToFillPre = Object.entries(fieldsPre).reduce<Array<Promise<{ field?: Field; fieldName: string }>>>(
    (listValuesToFill, [fieldName, field]) => {
      const middleWare = (fieldMiddleWares ?? []).find((middleWare) => {
        return middleWare.key === fieldName;
      });

      if (middleWare) {
        const result = runFieldMiddleWare<Field>({
          middleWare: middleWare.middleWare,
          field,
          fieldName,
          client,
          getOperator,
        });

        return listValuesToFill.concat(result);
      }
      if (field.type === "select") {
        return listValuesToFill.concat(fillSelect({ client, field, fieldName }));
      }
      return listValuesToFill;
    },
    []
  );

  const listValuesToFill = await Promise.all(listValuesToFillPre);
  if (!listValuesToFill.length) {
    setFields(fieldsPre);
    return;
  }

  const nextFields = Object.entries(fieldsPre).reduce<{ [fieldName: string]: Field }>(
    (nextFields, [fieldName, field]) => {
      const nextField = listValuesToFill.find((nextField) => nextField.fieldName === fieldName);
      if (!nextField) return { ...nextFields, [fieldName]: field };
      if (nextField.field) return { ...nextFields, [fieldName]: nextField.field };
      return nextFields;
    },
    {}
  );

  setFields(nextFields);
};
