export default {
  name: "device attribute",
  plural: "device attributes",
  columns: {
    attributeDataType: "attribute data type",
    attributeName: "attribute name",
    deviceAttributeValue: "device attribute value",
    id: "ID",
    isDeleted: "is deleted",
    mappingAttributeDeviceModelAttributeSet: "mapping attribute device model attribute Set",
    tenantId: "tenant ID",
  },
};
