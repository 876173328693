import { GridColDef, GridFilterInputValue, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";
import { filterdefs } from "graphqlBase/filterTypes";
import translations from "translations";
import { PartialFilterOverrides } from "../types";

const makeFilters = (filterOverrides: PartialFilterOverrides) => {
  return Object.entries(filterdefs).reduce<{
    [key: string]: GridFilterOperator[];
  }>((filterOperators, [key, def]) => {
    if (filterOverrides[key]) {
      const filterEntries = filterOverrides[key]?.(def) ?? [];
      return { ...filterOperators, [key]: filterEntries };
    }
    const filterEntries: GridFilterOperator[] = def.map((def) => {
      return {
        label: def,
        value: def,
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => null,
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: key },
      };
    });
    return { ...filterOperators, [key]: filterEntries };
  }, {});
};

export default makeFilters;
