import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLListItemProps = DropOnONLh<JSX.PatternlibListItem>;
export type PLListItemExtendedProps = PLListItemProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedListItem = wrapWc<JSX.PatternlibListItem>("patternlib-list-item");
export const useRefPLListItem = () => useRef<HTMLPatternlibListItemElement>(null);

const PLListItem = React.forwardRef<HTMLPatternlibListItemElement, PLListItemExtendedProps>((props, ref) => {
  const myRef = useRefPLListItem();
  return (
    <WrappedListItem ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedListItem>
  );
});
PLListItem.displayName = "PLListItem";

export default PLListItem;
