import { GridFilterOperator } from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/entityMappings";
import { FilterOverrides, FiltertypesWrapped, PartialFilterOverrides } from "./types";
import makeFilters from "./utils/makeFilters";

export function makeSeverSideFilterPre<D extends object, E extends keyof EntityMappings>(
  filterOverrides: PartialFilterOverrides
) {
  const filterOperatorMap = makeFilters(filterOverrides);
  return function <T extends keyof FiltertypesWrapped>(
    filterType: T,
    {
      filterOperators,
      filterPath,
    }: {
      filterOperators?: GridFilterOperator[];
      filterPath: ({
        where,
        filterValue,
      }: {
        filterValue: FiltertypesWrapped[T];
        where: EntityMappings[E]["filter"];
      }) => EntityMappings[E]["filter"] | undefined | null;
    }
  ) {
    return {
      filterPath,
      filterOperators: filterOperators ?? filterOperatorMap[filterType],
    };
  };
}
