import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { TableBodyRowComponentProps } from "../tableTypes";
import getTestIDs from "lib/utils/getTestIDs";

export const testIDs = getTestIDs();

const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      darkRow: {
        backgroundColor: "#f3f3f1",
      },
      lightRow: {
        backgroundColor: theme.colors.tWhite,
      },
    }),

  { name: `Table-${"Standard"}-BodyRow` }
);
const TableBodyRowComponent: React.FC<TableBodyRowComponentProps<object>> = ({
  row,
  rowIndex,
  tableProps,
  config,
  tableId,
}) => {
  const classes = useStyles();
  const handleOnClick = () => {
    if (!config.getRowOnClick) return;
    config.getRowOnClick(row);
  };
  return (
    <TableRow
      onClick={handleOnClick}
      {...row.getRowProps()}
      {...tableProps?.bodyRow}
      className={config.striped ? (rowIndex % 2 ? classes.darkRow : classes.lightRow) : classes.lightRow}
      data-testid={testIDs.TableRow}
    >
      {row.cells.map((cell, cellIndex) => {
        const { width, minWidth, maxWidth } = cell.column;
        return (
          <TableCell
            {...cell.getCellProps()}
            {...tableProps?.bodyCell}
            style={
              config.dense
                ? { width, minWidth, maxWidth, fontSize: "14px", padding: "8px 12.5px" }
                : { width, minWidth, maxWidth }
            }
            key={`table-${tableId}-br-${rowIndex}-c${cellIndex}`}
            data-testid={testIDs.TableCell}
          >
            {cell.render("Cell")}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TableBodyRowComponent;
