const commonCheckboxStyles = {
  "&.MuiCheckbox-root": {
    border: "1px solid var(--color-black)",
    borderRadius: "0px",
    height: 24,
    width: 24,
    color: "transparent",
    ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
      height: 30,
      width: 30,
    },
  },
  "&.Mui-disabled": {
    border: "0px",
    borderColor: "var(--color-black)",
    "&:hover": {
      borderColor: "var(--color-black)",
    },
    "&.MuiCheckbox-root": {
      border: "1px solid var(--color-steel-400)",
      borderRadius: "0px",
      height: 24,
      width: 24,
      color: "transparent",
      ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
        height: 30,
        width: 30,
      },
      "&.Mui-checked": {
        height: "0px",
        width: 0,
        border: "5px solid var(--color-steel-100)",
        ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
          height: 30,
          width: 30,
          color: "var(--color-steel-100)",
        },
      },
    },
    "&.Mui-checked": {
      color: "var(--color-steel-400)",
      backgroundColor: "var(--color-steel-400)",
      ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
        height: 33,
        width: 33,
      },
    },
    "&.MuiCheckbox-indeterminate": {
      height: 0,
      width: 0,
      border: "5px solid var(--color-steel-100)",
      backgroundColor: "var(--color-steel-400)",
      ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
        height: 30,
        width: 30,
        color: "var(--color-steel-100)",
      },
    },
  },
  "&.Mui-checked": {
    borderRadius: 0,
    color: "var(--primary-color)",
    backgroundColor: "var(--color-black)",
    borderColor: "var(--primary-color)",
    ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
      height: 32,
      width: 32,
    },
  },
  "&.MuiCheckbox-indeterminate": {
    borderRadius: 0,
    color: "var(--primary-color)",
    backgroundColor: "var(--color-black)",
    borderColor: "var(--primary-color)",
    "&.Mui-disabled": {
      backgroundColor: "var(--color-steel-400)",
    },
    ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
      height: 32,
      width: 32,
    },
  },
};
export const muiCheckboxOverrides = {
  root: {
    ...commonCheckboxStyles,
  },
};
