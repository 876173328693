import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import ArrowDownIcon from "icons/components/ArrowDownIcon";
import React, { useState } from "react";
import translations from "translations";
import { SelectItem } from "../Select";

export const useStyles = makeStyles((theme) =>
  createStyles({
    dropdownOption: {
      backgroundColor: "white",
      boxSizing: "border-box",
      color: "black",
      fontSize: 16,
      height: "2.5em",
      listStyle: "none",
      maxWidth: "inherit",
      padding: "11.5px 9px",
      position: "relative",
      textAlign: "left",
      width: "100%",
      zIndex: 1,
      "&:hover": {
        backgroundColor: "#e6e5e3",
      },
    },
    dropdownListOpen: {
      boxShadow: "rgba(0, 0, 0, 0.25) 0 4px 16px 0",
      display: "flex",
      flexFlow: "row wrap",
      fontSize: 16,
      marginTop: 8,
      marginBottom: 0,
      maxHeight: 340,
      overflowY: "auto",
      padding: 0,
      position: "absolute",
      width: "100%",
    },
    dropdownListClosed: {
      display: "none",
    },
    dropdownButton: (props: { iconSize?: string | number; transform: string }) => ({
      display: "flex",
      flexWrap: "wrap",
      background: "transparent",
      fontSize: 18,
      float: "right",
      outline: "none",
      border: "none",
      width: "max-content",
      "& .Mui-disabled": {
        cursor: "not-allowed",
        opacity: 0.5,
      },
      "& svg": {
        height: props.iconSize ?? "2em",
        width: props.iconSize ?? "2em",
        color: "black",
        transform: props.transform,
      },
    }),
    dropdownContent: {
      backgroundColor: "transparent",
      border: "none",
      color: "inherit",
      fontSize: 16,
      marginTop: 0,
      outline: "none",
    },
    dropdownPlaceholder: {
      color: "#9a978f",
    },
    dropdownLabel: {
      WebkitTextFillColor: "#787673",
      color: "#787673",
      display: "block",
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow sans-serif",
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.2,
      margin: "0.2em 0.5em 0.5em 0.4em",
      maxWidth: "fit-content",
    },
    dropdownOpen: {
      backgroundColor: "white",
      border: "1px solid #9a978f",
      borderRadius: 4,
      boxShadow: "rgba(0, 0, 0, 0.25) 0 4px 16px 0",
      boxSizing: "border-box",
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow sans-serif",
      fontSize: 16,
      height: "2.5em",
      minWidth: 200,
      overflow: "hidden",
      width: "100%",
    },
    dropdownClosed: {
      backgroundColor: "#e6e5e3",
      border: "1px solid #e6e5e3",
      borderRadius: 4,
      boxSizing: "border-box",
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow sans-serif",
      fontSize: 16,
      height: "2.5em",
      minWidth: 200,
      overflow: "hidden",
      width: "100%",
      "&:hover": {
        backgroundColor: "#dbdad6",
      },
    },
    dropdownDisabled: {
      background: "white",
      border: "1px solid #e6e5e3",
      borderRadius: 4,
      boxSizing: "border-box",
      color: "#787673",
      cursor: "not-allowed",
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow sans-serif",
      fontSize: 16,
      height: "2.5em",
      minWidth: "max-content",
      overflow: "hidden",
      width: "100%",
    },
    dropdownHeader: {
      display: "flex",
      height: "100%",
      alignContent: "center",
      alignItems: "center",
      backgroundColor: "inherit",
      boxSizing: "border-box",
      color: "black",
      fontSize: 16,
      justifyContent: "space-between",
      lineHeight: 1.2,
      minWidth: "max-content",
      outline: "none",
      overflow: "hidden",
      padding: "0 0 0 0.6em",
      textAlign: "left",
      width: "100%",
      "& .Mui-disabled": {
        color: "#9a978f",
        opacity: 0.5,
      },
    },
  })
);

export interface DropdownProps {
  disabled?: boolean;
  icon?: JSX.Element;
  iconSize?: string | number; // this prop is needed depending on which SVG icon is used
  id: string;
  label?: string;
  name?: string;
  onSelect?: (value: string) => void;
  options?: SelectItem[];
  placeholder?: string;
  selected: string | string[] | undefined;
  testId?: string;
  width?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  icon,
  iconSize,
  disabled,
  label,
  name,
  options,
  placeholder = translations.globals.placeholders.Dropdownfield,
  selected,
  width,

  onSelect = () => {},
  testId,
}) => {
  const [open, setOpen] = useState(false);
  const transform = open ? "rotate(180deg)" : "none";
  const styles = useStyles({ iconSize, transform });

  const handleSelect = (value: string) => {
    setOpen(false);
    onSelect(value);
  };

  const Option: React.FC<{ label: string; value: string }> = (props) => {
    const handleClick = () => handleSelect(props.value);

    return (
      <li className={styles.dropdownOption} onClick={handleClick}>
        {props.label}
      </li>
    );
  };

  const handleOutsideClick = () => {
    setOpen(false);
    document.removeEventListener("click", handleOutsideClick);
  };

  const toggleDropdown = () => {
    if (!open && !disabled) {
      setOpen(true);
      document.addEventListener("click", handleOutsideClick);
    } else {
      setOpen(false);
      document.removeEventListener("click", handleOutsideClick);
    }
  };

  const listClass = clsx({
    [styles.dropdownListOpen]: open,
    [styles.dropdownListClosed]: !open,
  });

  const items = (
    <ul className={listClass}>
      {options?.map((option, index) => (
        <Option key={index} label={option.label} value={option.value} />
      ))}
    </ul>
  );

  const selectedValue = selected ? options?.find((o) => o.value === selected)?.label ?? placeholder : placeholder;

  const valueClass = clsx({
    [styles.dropdownContent]: true,
    [styles.dropdownPlaceholder]: selectedValue === placeholder,
  });

  const value = <span className={valueClass}>{selectedValue}</span>;

  const labelComponent = <label className={styles.dropdownLabel}>{label}</label>;

  const wrapperClass = clsx({
    [styles.dropdownOpen]: open && !disabled,
    [styles.dropdownClosed]: !open && !disabled,
    [styles.dropdownDisabled]: disabled,
  });

  return (
    <div
      style={{
        height: "max-content",
        minWidth: "max-content",
        position: "relative",
        width,
      }}
      data-testid={testId}
    >
      {label && labelComponent}
      <div id="1" className={wrapperClass} onClick={toggleDropdown}>
        <div className={styles.dropdownHeader}>
          {value}
          <button className={styles.dropdownButton} disabled={disabled}>
            {icon ?? <ArrowDownIcon height="2em" width="2em" />}
          </button>
        </div>
        {items}
      </div>
    </div>
  );
};

export default Dropdown;
