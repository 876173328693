import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesForDeviceConfigQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.DeviceSortInput> | Types.DeviceSortInput>;
}>;


export type GetDevicesForDeviceConfigQuery = { devices: Array<{ __typename?: 'Device', id: string, deviceName: string, gatewayDeviceConnection?: Maybe<{ __typename?: 'GatewayDeviceConnection', id: string }>, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string }, mappingDeviceConfigurationDevice: Array<{ __typename?: 'MappingDeviceConfigurationDevice', id: string, userId?: Maybe<string>, deviceId: string, deviceConfigurationId: string, dateTo: string, dateFrom: string, mappingDeviceConfigurationDeviceStatus: { __typename?: 'MappingDeviceConfigurationDeviceStatus', statusName: string }, deviceConfiguration: { __typename?: 'DeviceConfiguration', id: string, configurationJSON: string } }>, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, dateFrom: string, dateTo: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareRevision: string, deviceFirmwareConfigurationSchema: { __typename?: 'DeviceFirmwareConfigurationSchema', id: string, deviceFirmwareConfigurationJSON: string } } }> }> };


export const GetDevicesForDeviceConfigDocument = gql`
    query getDevicesForDeviceConfig($where: DeviceFilterInput, $take: Int, $skip: Int, $order: [DeviceSortInput!]) {
  devices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    deviceName
    gatewayDeviceConnection {
      id
    }
    deviceType {
      id
      deviceTypeName
    }
    mappingDeviceConfigurationDevice {
      id
      userId
      deviceId
      deviceConfigurationId
      dateTo
      dateFrom
      mappingDeviceConfigurationDeviceStatus {
        statusName
      }
      deviceConfiguration {
        id
        configurationJSON
      }
    }
    mappingDeviceFirmwareDevice {
      id
      dateFrom
      dateTo
      deviceFirmware {
        id
        firmwareRevision
        deviceFirmwareConfigurationSchema {
          id
          deviceFirmwareConfigurationJSON
        }
      }
    }
  }
}
    `;

/**
 * __useGetDevicesForDeviceConfigQuery__
 *
 * To run a query within a React component, call `useGetDevicesForDeviceConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesForDeviceConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesForDeviceConfigQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDevicesForDeviceConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesForDeviceConfigQuery, GetDevicesForDeviceConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesForDeviceConfigQuery, GetDevicesForDeviceConfigQueryVariables>(GetDevicesForDeviceConfigDocument, options);
      }
export function useGetDevicesForDeviceConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesForDeviceConfigQuery, GetDevicesForDeviceConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesForDeviceConfigQuery, GetDevicesForDeviceConfigQueryVariables>(GetDevicesForDeviceConfigDocument, options);
        }
export type GetDevicesForDeviceConfigQueryHookResult = ReturnType<typeof useGetDevicesForDeviceConfigQuery>;
export type GetDevicesForDeviceConfigLazyQueryHookResult = ReturnType<typeof useGetDevicesForDeviceConfigLazyQuery>;
export type GetDevicesForDeviceConfigQueryResult = Apollo.QueryResult<GetDevicesForDeviceConfigQuery, GetDevicesForDeviceConfigQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
