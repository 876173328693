export default {
  name: "Asset Type",
  plural: "Asset Type",
  columns: {
    articleNo: "Article No",
    articleNumber: "Article number",
    asset: "Asset",
    assetTypeAttributeSet: "Asset Type Attribute Set",
    assetTypeName: "Asset Type Name",
    height: "Height",
    heightInMeter: "Height",
    id: "ID",
    isDeleted: "is deleted",
    length: "Length",
    lengthInMeter: "Length",
    manufacturer: "Manufacturer",
    manufacturerId: "Manufacturer ID",
    serialNumber: "Serial number",
    tenantId: "tenant ID",
    weight: "Weight",
    weightInKilogram: "Weight",
    width: "Width",
    widthInMeter: "Width",
  },
};
