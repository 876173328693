import { createTheme, Theme, adaptV4Theme } from "@mui/material/styles";
import tableColors from "./tableColors";
import overrides from "./overrides";

declare module "@mui/material/styles" {
  interface Theme {
    tableColors: typeof tableColors;
  }

  // allow configuration using `createMuiTheme`
  interface DeprecatedThemeOptions {
    tableColors?: typeof tableColors;
  }
}

const tableTheme: Theme = createTheme(overrides, {
  palette: {
    primary: {
      main: tableColors.lbcgrey,
    },
    secondary: { main: tableColors.lbcyellow },
    success: {
      main: tableColors.green,
    },
    warning: { main: tableColors.orange },
    error: {
      main: tableColors.red,
    },
    text: {
      primary: tableColors.black,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "Arial",
    // fontFamily: "'Open Sans',Arial,sans-serif",
    // fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});

export default tableTheme;
