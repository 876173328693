import useDataGrid from "components/templates/dataGridTable";
import { GetUsersQuery, ResultType, useGetUsersLazyQuery } from "graphqlBase/Settings/__generated__/getUsers";
import React, { useEffect } from "react";
import translations from "translations";
import { TableTitle } from "./TableTitle";

export type User = ResultType<GetUsersQuery["users"]>;

const { firstName, lastName, email } = translations.entities.user.columns;
const { plural } = translations.entities.user;
const { assignedTo, noUsersAssignedMessage } = translations.pages.userManagement.userRoleManager;

const MappingRoleUsersTable: React.FC<{ roleId: string }> = ({ roleId }) => {
  const variables = {
    where: {
      and: [
        { mappingUserScopes: { all: { isDeleted: { neq: true } } } },
        { mappingRoleUsers: { some: { roleId: { eq: roleId } } } },
      ],
    },
  };

  const [query, { data, loading, error }] = useGetUsersLazyQuery({ fetchPolicy: "cache-and-network" });

  const { DataGrid, useMakeColumns, makeSeverSideFilter } = useDataGrid<User, "User">({
    query,
    variables,
    tableId: "MappingRoleUsersTable",
    persistance: "runTime",
  });

  useEffect(() => {
    query({ variables });
  }, [roleId]);

  const columns = useMakeColumns(
    [
      {
        field: "firstName",
        headerName: firstName,
        flex: 1,
        valueGetter: (params) => {
          return params.row.firstName ?? "";
        },
        remoteOrder: (sort) => ({ firstName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            firstName: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "lastName",
        headerName: lastName,
        flex: 1,
        remoteOrder: (sort) => ({ lastName: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            lastName: filterValue,
          }),
        }),
        valueGetter: (params) => {
          return params.row?.lastName ?? "";
        },
        type: "string",
      },
      {
        field: "email",
        headerName: email,
        flex: 1,
        valueGetter: (params) => {
          return params.row?.email ?? "";
        },
        remoteOrder: (sort) => ({ email: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            email: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "tenant",
        headerName: translations.entities.tenant.name,
        flex: 1,
        remoteOrder: (sort) => ({ tenant: { tenantName: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            tenant: { tenantName: filterValue },
          }),
        }),
        valueGetter: (params) => {
          return params.row.tenant?.tenantName ?? "";
        },
        type: "string",
      },
    ],
    []
  );

  return (
    <>
      {TableTitle(assignedTo)}
      {!error && (
        <DataGrid
          noDataMessage={noUsersAssignedMessage}
          tableTitle={plural}
          tableHeight="40vh"
          columns={columns}
          rows={loading ? [] : data?.users}
          checkboxSelection={false}
          loading={loading}
          withMargin={false}
        />
      )}
    </>
  );
};

export default MappingRoleUsersTable;
