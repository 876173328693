import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetStorageAreaTypeColorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStorageAreaTypeColorsQuery = { storageAreaTypes: Array<{ __typename?: 'StorageAreaType', id: string, storageAreaTypeName: string, storageAreaTypeColor?: Maybe<string> }> };


export const GetStorageAreaTypeColorsDocument = gql`
    query getStorageAreaTypeColors {
  storageAreaTypes {
    id
    storageAreaTypeName
    storageAreaTypeColor
  }
}
    `;

/**
 * __useGetStorageAreaTypeColorsQuery__
 *
 * To run a query within a React component, call `useGetStorageAreaTypeColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageAreaTypeColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageAreaTypeColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStorageAreaTypeColorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStorageAreaTypeColorsQuery, GetStorageAreaTypeColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStorageAreaTypeColorsQuery, GetStorageAreaTypeColorsQueryVariables>(GetStorageAreaTypeColorsDocument, options);
      }
export function useGetStorageAreaTypeColorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStorageAreaTypeColorsQuery, GetStorageAreaTypeColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStorageAreaTypeColorsQuery, GetStorageAreaTypeColorsQueryVariables>(GetStorageAreaTypeColorsDocument, options);
        }
export type GetStorageAreaTypeColorsQueryHookResult = ReturnType<typeof useGetStorageAreaTypeColorsQuery>;
export type GetStorageAreaTypeColorsLazyQueryHookResult = ReturnType<typeof useGetStorageAreaTypeColorsLazyQuery>;
export type GetStorageAreaTypeColorsQueryResult = Apollo.QueryResult<GetStorageAreaTypeColorsQuery, GetStorageAreaTypeColorsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
