import { ApolloClient, from, gql, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import authentication from "authentication";
import cache from "./cache";
import initialCacheData from "./cache/initialCacheData";
import errorLink from "./errorLink";
import resolvers from "./resolvers";
import typeDefs from "./typeDefs";
const authLink = setContext(
  (_, { headers }) =>
    new Promise((resolve, reject) => {
      authentication
        .getUser()
        .then((user) => {
          if (!user?.access_token) resolve({});
          resolve({
            headers: {
              ...headers,
              authorization: user?.access_token ? `Bearer ${user?.access_token}` : "",
            },
          });
        })
        .catch((e) => {
          authentication.removeUser().finally(console.log);
          reject(e);
        });
    })
);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const link = process.env.REACT_APP_DISABLE_AUTH === "true" ? httpLink : from([authLink, httpLink]);

const client = new ApolloClient({
  cache: cache,
  // link,
  link: from([errorLink, link]),
  resolvers,
  typeDefs,
  connectToDevTools: true,
});

client.writeQuery({
  query: gql`
    query Query {
      ${Object.keys(initialCacheData).join("\n")}
    }
  `,
  data: {
    initialCacheData,
  },
});

export default client;
