import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLPillProps = DropOnONLh<JSX.PatternlibPill>;
export type PLPillExtendedProps = PLPillProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedPill = wrapWc<JSX.PatternlibPill>("patternlib-pill");
export const useRefPLPill = () => useRef<HTMLPatternlibPillElement>(null);

const PLPill = React.forwardRef<HTMLPatternlibPillElement, PLPillExtendedProps>((props, ref) => {
  const myRef = useRefPLPill();
  return (
    <WrappedPill ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedPill>
  );
});
PLPill.displayName = "PLPill";

export default PLPill;
