import { useAuthorizedRoutes } from "authorization";
import Breadcrumb from "components/molecules/Breadcrumb";
import { contentHeaderTitleAtom } from "components/organisms/ContentHeader/contentHeaderRecoilState";
import Development from "components/pages/Development";
import NoAccessMessage from "components/pages/NoAccessMessage";
import { routesForBreadcrumbAtom } from "components/templates/MainLayout/breadcrumbRecoilState";
import React, { useEffect, useState } from "react";
import { BrowserRouter, matchPath, Route, Switch, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import translations from "translations";
import { languageAtom } from "translations/recoilState";
import MainLayout from "../components/templates/MainLayout";
import routes, { Route as RouteInterface } from "./routes";

type CleanRoute = Omit<RouteInterface, "subRoutes">;

const renderRoutePre = (routes: RouteInterface[]) => (route: RouteInterface) => {
  return (
    <Route key={route.path} path={route.path} exact={route.exact}>
      {route.addBreadcrumb && <Breadcrumb routes={routes} />}
      {route.component !== null ? <route.component /> : <Development label={route.label} />}
    </Route>
  );
};

export const flattenRoutes = (routes: RouteInterface[]): CleanRoute[] => {
  return routes.reduce<CleanRoute[]>((result, el) => {
    const { subRoutes, ...route } = el;
    const innerRoutes = (subRoutes ?? []).map((subRoute) => ({ ...subRoute, path: `${route.path}${subRoute.path}` }));
    return result.concat(route, ...flattenRoutes(innerRoutes));
  }, []);
};

const RouterSwitch = () => {
  const [translatedRoutes, setTranslatedRoutes] = useState<RouteInterface[]>(routes());
  const location = useLocation();
  const [currentPageTitle, setCurrentPageTitle] = useRecoilState(contentHeaderTitleAtom);
  const [noMainLayoutPadding, setNoMainLayoutPadding] = useState<boolean>(false);
  const routesAuthorized = useAuthorizedRoutes(translatedRoutes);
  const renderRoute = renderRoutePre(routesAuthorized);
  const withSubRoutes = flattenRoutes(routesAuthorized);
  const translationLang = translations.getLanguage();
  const language = useRecoilValue(languageAtom);
  const setRoutesForBreadcrumb = useSetRecoilState(routesForBreadcrumbAtom);

  useEffect(() => {
    setRoutesForBreadcrumb(withSubRoutes);
  }, [setRoutesForBreadcrumb, withSubRoutes]);

  useEffect(() => {
    const currentRoute = withSubRoutes.find(({ path, exact }) => matchPath(location.pathname ?? "", { path, exact }));
    if (currentRoute?.mainLayout !== undefined && currentRoute.mainLayout) {
      if (!currentRoute?.path?.includes(":")) setCurrentPageTitle(currentRoute.label);
    }

    if (currentRoute?.mainLayout !== undefined && currentRoute.noMainLayoutPadding === true) {
      setNoMainLayoutPadding(true);
    } else setNoMainLayoutPadding(false);
  }, [location, withSubRoutes]);

  useEffect(() => {
    setTranslatedRoutes(routes());
  }, [translationLang, language]);

  return (
    <Switch>
      {withSubRoutes.filter((route) => !route.mainLayout).map(renderRoute)}
      <MainLayout translatedRoutes={translatedRoutes} noPadding={noMainLayoutPadding}>
        {withSubRoutes.filter((route) => route.mainLayout).map(renderRoute)}
      </MainLayout>
      {routesAuthorized.length > 1 && (
        <Route path="*">
          <NoAccessMessage />
        </Route>
      )}
    </Switch>
  );
};

const Router = () => (
  <BrowserRouter>
    <RouterSwitch />
  </BrowserRouter>
);

export default Router;
