import { LhChange, Status } from "@liebherr/patternlib/dist/types/utils/interfaces";
import PLTextinput from "components/patternLib/form/PLTextinput";
import * as React from "react";
import { useEffect, useState } from "react";
import Props from "./index.d";

export type TextFieldComponentProps = Props;

const TextFieldComponent: React.FC<Props> = (props) => {
  const [status, setStatus] = useState<Status["status"]>("default");
  const [statusErrors, setStatusErrors] = useState<Status["status"]>("default");
  const {
    title,
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    placeholder = "placeholder",
    disabled,
    testId,
    errors,
    isPartOfSummary,
    isRequiredField,
    width,
    ...rest
  } = props;

  const handleChange = (event: CustomEvent<LhChange>) => {
    const { value } = event.detail;
    const inputLength = ((value as string) ?? "").trim().length;
    setAndSave(value as string);
    if (isRequiredField && (!value || inputLength < 1)) {
      setStatus("error");
      return;
    }
    setStatus("success");
  };
  useEffect(() => {
    if (errors?.length) {
      setStatusErrors("error");
    } else {
      setStatusErrors("default");
    }
  }, [errors, setStatus]);

  return (
    <>
      {
        <>
          <PLTextinput
            showRemoveIcon={false}
            status={statusErrors}
            lhBlur={onBlur}
            value={state}
            lhChange={handleChange}
            readonly={disabled}
            label={title}
            width={width}
            errorText={errors?.join(", ")}
          />
        </>
      }
    </>
  );
};

export default TextFieldComponent;
