import { styled } from "@mui/material/styles";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { toastStateAtom } from "components/atomics/Toast/toastState";
import { GetAllStorageAreasDocument } from "graphqlBase/StorageAreas/__generated__/getAllStorageAreas";
import { GetStorageAreasForListDocument } from "graphqlBase/StorageAreas/__generated__/getStorageAreasForList";
import L from "leaflet";
import getTestIDs from "lib/utils/getTestIDs";
import { StorageAreaCreateForm } from "raft/StorageAreaForm";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { newStorageAreaIdAtom } from "../../recoilBox";

export const testIDs = getTestIDs();

const CreateStorageAreaSC = styled("div")({
  height: "80vh",
});

const AddGeofence: React.FC<{}> = () => {
  const history = useHistory();
  const setToastObject = useSetRecoilState(toastStateAtom);
  const [state, setstate] = useState(false);
  const setNewStorageAreaId = useSetRecoilState(newStorageAreaIdAtom);
  const close = () => {
    history.push("/fleetInventory");
  };

  const afterSave = (data: { id: string }) => {
    try {
      setNewStorageAreaId(data.id);
      setToastObject({
        open: true,
        message: translations.pages.fleetInventory.toastMessageCreate.success,
        severity: "success",
      });
      close();
    } catch (error) {
      console.log(error);
      setToastObject({
        open: true,
        message: translations.pages.fleetInventory.toastMessageCreate.error,
        severity: "error",
      });
      close();
    }
  };
  return (
    <CreateStorageAreaSC>
      <LoadingSpinner hideChildrenOnLoad loading={state}>
        <StorageAreaCreateForm
          onCancel={close}
          afterCreate={afterSave}
          beforeRemoteMuatation={(p) => {
            setstate(true);
            return {
              ...p.data,
            };
          }}
          updateAfterCreateQueries={[
            {
              query: GetAllStorageAreasDocument,
              variables: {},
            },
            {
              query: GetStorageAreasForListDocument,
              variables: {
                skip: 0,
                take: 10,
                order: [],
              },
              refetch: true,
            },
          ]}
          //@ts-ignore
          onUpdateDataMiddleWare={(p) => {
            if (!p.data.storageAreaName?.length ?? false) {
              return {
                ...p.data,
                disableStep: 1,
                noPolygon: 1,
              };
            }

            if (!p.data.geofence?.coordinates) {
              return {
                ...p.data,
                disableStep: 1,
                noPolygonWarning: translations.pages.fleetInventory.noPolygonWarning,
                noPolygon: 0,
              };
            }

            //@ts-ignore
            if (!!p.data.formHasErrors && p.data.submit) {
              return {
                ...p.data,
                //@ts-ignore
                submit: !p.data.formHasErrors,
              };
            }
            if (p.data.geofence) {
              const alowedPolygonSize = 455;
              const polygon = L.rectangle(p.data.geofence?.coordinates);
              const polygonSize = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0] as L.LatLngLiteral[]) * 0.005;
              if (polygonSize < alowedPolygonSize) {
                return {
                  ...p.data,
                  disableStep: 1,
                  noPolygonWarning: translations.pages.fleetInventory.noPolygonWarning,
                  noPolygon: 0,
                };
              }
            }

            return {
              ...p.data,
              disableStep: 0,
              noPolygonWarning: "",
              noPolygon: 1,
              formHasErrors:
                !p.data.storageAreaTypeId || !p.data.storageAreaName || !p.data.geofence?.coordinates ? 1 : 0,
            };
          }}
        />
      </LoadingSpinner>
    </CreateStorageAreaSC>
  );
};

export default AddGeofence;
