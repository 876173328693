import { Box, Typography } from "@mui/material";
import PLImage from "components/patternLib/PLImage";
import PLModal from "components/patternLib/PLModal";
import React, { useMemo, useState } from "react";
import { formatDateTime } from "translations/formatter";
import { AssetImageCarousel } from "..";
import ImageGalleryCarousel from "./ImageGalleryCarousel";
const commonStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
const ImageGallery: React.FC<{ images: AssetImageCarousel[] }> = ({ images }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentPageId, setCurrentPageId] = useState<string>("");
  const mapImages: { [key: string]: AssetImageCarousel } = useMemo(() => {
    return images.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
  }, [images]);

  return (
    <>
      <Box sx={{ display: "flex", columnGap: "12px", rowGap: "12px", marginTop: "30px", flexWrap: "wrap" }}>
        {images.map((image) => (
          <Box key={image.id}>
            <PLImage
              objectFit="contain"
              alt={image.alt}
              src={image.src}
              height="47px"
              width="64px"
              onClick={() => {
                setCurrentPageId(image.id);
                setOpenModal(true);
              }}
            />
          </Box>
        ))}
      </Box>
      <PLModal
        allowCloseOnBackdrop
        modalTitle="Image Gallery"
        lhClose={() => setOpenModal(false)}
        show={openModal}
        hideCancel
        hideSubmit
      >
        <>
          <Box slot="modal-content">
            {currentPageId && (
              <Box
                sx={{
                  ...commonStyles,
                  rowGap: "2rem",
                }}
              >
                <PLImage
                  objectFit="contain"
                  alt={mapImages[currentPageId].alt}
                  src={mapImages[currentPageId].src}
                  width="100%"
                  height="350px"
                />
                <Box sx={{ ...commonStyles, marginBottom: "2rem" }}>
                  <Typography variant="copyBold">{mapImages[currentPageId].alt}</Typography>
                  <Typography variant="copy">{formatDateTime(new Date(mapImages[currentPageId].createdAt))}</Typography>
                </Box>
              </Box>
            )}
            <ImageGalleryCarousel images={images} currentPageId={currentPageId} setCurrentPageId={setCurrentPageId} />
          </Box>
        </>
      </PLModal>
    </>
  );
};

export default ImageGallery;
