import { formatDistanceToNow } from "date-fns";
import { de, enGB } from "date-fns/locale";
import translations from ".";

export default () => {};

const {
  hours: hoursTransl,
  minutes: minutesTransl,
  hour: hourTransl,
  minute: minuteTransl,
  now,
} = translations.globals.time;
const { hrs, mins, hr, min } = translations.globals.shortTerms;

export const getCurrency = (value: number, currency: string = "EUR") => {
  const currentLanguage = translations.getLanguage();
  return new Intl.NumberFormat(currentLanguage, { style: "currency", currency }).format(value);
};

export const formatTimeDistance = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const locale = currentLanguage === "en" ? enGB : de;

  return formatDistanceToNow(date, {
    addSuffix: true,
    locale: locale,
  });
};

export const formatDate = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  // @ts-ignore
  return Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatTime = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  // @ts-ignore
  return new Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatDateTime = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  // @ts-ignore
  return Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatDateForRange = (startDate: Date, endDate: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return [
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(startDate),
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(endDate),
  ];
};

export const formatDateTimeForRange = (startDate: Date, endDate: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return [
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(startDate),
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(endDate),
  ];
};

export const getNumber = (number: number, minimumFractionDigits: number = 2) => {
  const currentLanguage = translations.getLanguage();
  return new Intl.NumberFormat(currentLanguage, { minimumFractionDigits }).format(number);
};

export const getDistance = (distance: number) => {
  const numericString = getNumber(distance);
  return `${numericString} km`;
};

export const getHoursAndMinutes = (timeInMinsIn: number) => {
  const timeInMins = Math.round(timeInMinsIn);
  const hours = Math.floor(timeInMins / 60);
  const minutes = timeInMins % 60;
  return { hours, minutes };
};

export const formatHoursAndMinutes = ({
  hours,
  minutes,
  short = false,
}: {
  hours: number;
  minutes: number;
  short?: boolean;
}) => {
  const hoursUnit = hours > 1 ? (short ? hrs : hoursTransl) : short ? hr : hourTransl;
  const minutesUnit = minutes > 1 ? (short ? mins : minutesTransl) : short ? min : minuteTransl;
  const hoursString = `${hours} ${hoursUnit}`;
  const minutesString = `${minutes} ${minutesUnit}`;

  if (hours > 0 || minutes > 0) return `${hoursString} ${minutesString} ago`;
  return now;
};
