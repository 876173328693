import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLIconProps = DropOnONLh<JSX.PatternlibIcon>;
export type PLIconExtendedProps = PLIconProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedIcon = wrapWc<JSX.PatternlibIcon>("patternlib-icon");
export const useRefPLIcon = () => useRef<HTMLPatternlibIconElement>(null);

const PLIcon = React.forwardRef<HTMLPatternlibIconElement, PLIconExtendedProps>((props, ref) => {
  const myRef = useRefPLIcon();
  return (
    <WrappedIcon ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedIcon>
  );
});
PLIcon.displayName = "PLIcon";

export default PLIcon;
