import { useGetCurrentPositionOfAssetLazyQuery } from "graphqlBase/Assets/__generated__/getCurrentPositionOfAsset";
import { useEffect } from "react";
import translations from "translations";

export const useGetCurrentPosition = (deviceId: string | undefined) => {
  const [
    getCurrentPosition,
    { data: currentPositionData, loading: currentPositionLoading },
  ] = useGetCurrentPositionOfAssetLazyQuery({
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });
  useEffect(() => {
    if (deviceId) {
      getCurrentPosition({ variables: { deviceIds: [deviceId] } });
    }
  }, [deviceId]);

  const currentPosition = currentPositionData?.deviceLocation?.find((item) => !!item?.isLast);

  const azureMapLocations = currentPosition?.azureMapsLocations;
  return {
    coordinates: currentPosition?.location?.coordinates,
    address: !azureMapLocations
      ? translations.pages.fleetInventory.noLocationMessage
      : `${azureMapLocations[0]?.address?.streetNameAndNumber?.concat(",") ?? ""}
            ${
              azureMapLocations[0]?.address?.municipality
                ? azureMapLocations[0]?.address?.municipalitySubdivision
                  ? azureMapLocations[0]?.address?.municipality?.concat(",")
                  : azureMapLocations[0]?.address?.municipality
                : ""
            } ${azureMapLocations[0]?.address?.municipalitySubdivision ?? ""}`,

    loading: currentPositionLoading,
    utcMeasured: currentPosition?.utcTimeMeasured,
    dateFrom: currentPosition?.dateFrom,
  };
};
