import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetTagsForTagsListQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetSortInput> | Types.AssetSortInput>;
}>;


export type GetAssetTagsForTagsListQuery = { assets: Array<{ __typename?: 'Asset', mappingAssetTag: Array<{ __typename?: 'MappingAssetTag', id: string, assetTag: { __typename?: 'AssetTag', id: string, tagValue: string } }> }> };


export const GetAssetTagsForTagsListDocument = gql`
    query getAssetTagsForTagsList($where: AssetFilterInput, $take: Int, $skip: Int, $order: [AssetSortInput!]) {
  assets(where: $where, take: $take, skip: $skip, order: $order) {
    mappingAssetTag(where: {isDeleted: {neq: true}}) {
      id
      assetTag {
        id
        tagValue
      }
    }
  }
}
    `;

/**
 * __useGetAssetTagsForTagsListQuery__
 *
 * To run a query within a React component, call `useGetAssetTagsForTagsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetTagsForTagsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetTagsForTagsListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetTagsForTagsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetTagsForTagsListQuery, GetAssetTagsForTagsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetTagsForTagsListQuery, GetAssetTagsForTagsListQueryVariables>(GetAssetTagsForTagsListDocument, options);
      }
export function useGetAssetTagsForTagsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetTagsForTagsListQuery, GetAssetTagsForTagsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetTagsForTagsListQuery, GetAssetTagsForTagsListQueryVariables>(GetAssetTagsForTagsListDocument, options);
        }
export type GetAssetTagsForTagsListQueryHookResult = ReturnType<typeof useGetAssetTagsForTagsListQuery>;
export type GetAssetTagsForTagsListLazyQueryHookResult = ReturnType<typeof useGetAssetTagsForTagsListLazyQuery>;
export type GetAssetTagsForTagsListQueryResult = Apollo.QueryResult<GetAssetTagsForTagsListQuery, GetAssetTagsForTagsListQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
