import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDeviceConfigurationsMutationVariables = Types.Exact<{
  deviceConfigurations?: Types.InputMaybe<Array<Types.InputMaybe<Types.DeviceConfigurationCreateType>> | Types.InputMaybe<Types.DeviceConfigurationCreateType>>;
}>;


export type CreateDeviceConfigurationsMutation = { createDeviceConfigurations?: Maybe<Array<Maybe<{ __typename?: 'DeviceConfiguration', id: string, configurationJSON: string }>>> };


export const CreateDeviceConfigurationsDocument = gql`
    mutation createDeviceConfigurations($deviceConfigurations: [DeviceConfigurationCreateType]) {
  createDeviceConfigurations(deviceConfigurations: $deviceConfigurations) {
    id
    configurationJSON
  }
}
    `;
export type CreateDeviceConfigurationsMutationFn = Apollo.MutationFunction<CreateDeviceConfigurationsMutation, CreateDeviceConfigurationsMutationVariables>;

/**
 * __useCreateDeviceConfigurationsMutation__
 *
 * To run a mutation, you first call `useCreateDeviceConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceConfigurationsMutation, { data, loading, error }] = useCreateDeviceConfigurationsMutation({
 *   variables: {
 *      deviceConfigurations: // value for 'deviceConfigurations'
 *   },
 * });
 */
export function useCreateDeviceConfigurationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeviceConfigurationsMutation, CreateDeviceConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateDeviceConfigurationsMutation, CreateDeviceConfigurationsMutationVariables>(CreateDeviceConfigurationsDocument, options);
      }
export type CreateDeviceConfigurationsMutationHookResult = ReturnType<typeof useCreateDeviceConfigurationsMutation>;
export type CreateDeviceConfigurationsMutationResult = Apollo.MutationResult<CreateDeviceConfigurationsMutation>;
export type CreateDeviceConfigurationsMutationOptions = Apollo.BaseMutationOptions<CreateDeviceConfigurationsMutation, CreateDeviceConfigurationsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
