import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMappingDeviceTagsMutationVariables = Types.Exact<{
  createMappingDeviceTags?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingDeviceTagCreateType>> | Types.InputMaybe<Types.MappingDeviceTagCreateType>>;
}>;


export type CreateMappingDeviceTagsMutation = { createMappingDeviceTags?: Maybe<Array<Maybe<{ __typename?: 'MappingDeviceTag', id: string, tagId: string, deviceId: string }>>> };


export const CreateMappingDeviceTagsDocument = gql`
    mutation createMappingDeviceTags($createMappingDeviceTags: [MappingDeviceTagCreateType]) {
  createMappingDeviceTags(mappingDeviceTags: $createMappingDeviceTags) {
    id
    tagId
    deviceId
  }
}
    `;
export type CreateMappingDeviceTagsMutationFn = Apollo.MutationFunction<CreateMappingDeviceTagsMutation, CreateMappingDeviceTagsMutationVariables>;

/**
 * __useCreateMappingDeviceTagsMutation__
 *
 * To run a mutation, you first call `useCreateMappingDeviceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingDeviceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingDeviceTagsMutation, { data, loading, error }] = useCreateMappingDeviceTagsMutation({
 *   variables: {
 *      createMappingDeviceTags: // value for 'createMappingDeviceTags'
 *   },
 * });
 */
export function useCreateMappingDeviceTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMappingDeviceTagsMutation, CreateMappingDeviceTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMappingDeviceTagsMutation, CreateMappingDeviceTagsMutationVariables>(CreateMappingDeviceTagsDocument, options);
      }
export type CreateMappingDeviceTagsMutationHookResult = ReturnType<typeof useCreateMappingDeviceTagsMutation>;
export type CreateMappingDeviceTagsMutationResult = Apollo.MutationResult<CreateMappingDeviceTagsMutation>;
export type CreateMappingDeviceTagsMutationOptions = Apollo.BaseMutationOptions<CreateMappingDeviceTagsMutation, CreateMappingDeviceTagsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
