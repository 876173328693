import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetIdentityServerUserScopesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIdentityServerUserScopesQuery = { identityServerUserScopes?: Maybe<{ __typename?: 'UserScopesResult', status: boolean, statusMessage?: Maybe<string>, userScopes?: Maybe<Array<Maybe<{ __typename?: 'UserScope', value?: Maybe<string> }>>> }> };


export const GetIdentityServerUserScopesDocument = gql`
    query getIdentityServerUserScopes {
  identityServerUserScopes {
    status
    statusMessage
    userScopes {
      value
    }
  }
}
    `;

/**
 * __useGetIdentityServerUserScopesQuery__
 *
 * To run a query within a React component, call `useGetIdentityServerUserScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityServerUserScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityServerUserScopesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityServerUserScopesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIdentityServerUserScopesQuery, GetIdentityServerUserScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetIdentityServerUserScopesQuery, GetIdentityServerUserScopesQueryVariables>(GetIdentityServerUserScopesDocument, options);
      }
export function useGetIdentityServerUserScopesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIdentityServerUserScopesQuery, GetIdentityServerUserScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetIdentityServerUserScopesQuery, GetIdentityServerUserScopesQueryVariables>(GetIdentityServerUserScopesDocument, options);
        }
export type GetIdentityServerUserScopesQueryHookResult = ReturnType<typeof useGetIdentityServerUserScopesQuery>;
export type GetIdentityServerUserScopesLazyQueryHookResult = ReturnType<typeof useGetIdentityServerUserScopesLazyQuery>;
export type GetIdentityServerUserScopesQueryResult = Apollo.QueryResult<GetIdentityServerUserScopesQuery, GetIdentityServerUserScopesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
