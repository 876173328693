import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLOptionProps = DropOnONLh<JSX.PatternlibOption>;
export type PLOptionExtendedProps = PLOptionProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedOption = wrapWc<JSX.PatternlibOption>("patternlib-option");
export const useRefPLOption = () => useRef<HTMLPatternlibOptionElement>(null);

const PLOption = React.forwardRef<HTMLPatternlibOptionElement, PLOptionExtendedProps>((props, ref) => {
  const myRef = useRefPLOption();
  return (
    <WrappedOption ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedOption>
  );
});
PLOption.displayName = "PLOption";

export default PLOption;
