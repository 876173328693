import tableColors from "./tableColors";
import colors from "themes/colors";
import { createTheme, Theme } from "@mui/material/styles";
import theme from "./tableTheme";

const overrides: Theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        sx: {
          body1: {
            fontWeight: "inherit",
            fontSize: "14px",
          },
          body2: {
            fontWeight: "inherit",
            fontSize: "14px",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        sx: {
          root: {
            color: "inherit",
          },
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        sx: {
          root: { color: "inherit" },
          colorSecondary: {
            "&$checked": {
              color: "inherit",
            },
          },
        },
      },
    },

    MuiTableSortLabel: {
      defaultProps: {
        sx: {
          root: {
            display: "flex",
            flexDirection: "row",
            // eslint-disable-next-line sonarjs/no-duplicate-string
            alignItems: "flex-start",
          },
          icon: {
            opacity: 0.2,
            color: "black !important",
            marginRight: "0px",
            fontSize: "3em",
            display: "flex",
            alignItems: "flex-start",
            alignSelf: "flex-start",
            marginTop: "-0.2em",
            "&:hover": {
              opacity: 1,
            },
          },
        },
      },
    },

    MuiList: {
      defaultProps: {
        sx: {
          root: { color: tableColors.black },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          root: {
            fontWeight: "inherit",
            fontSize: "13px",
          },
          icon: { top: "inherit" },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        sx: {
          root: { color: "inherit" },
        },
      },
    },
    MuiButtonBase: {},
    MuiButton: {
      defaultProps: {
        sx: {
          root: {
            color: "#000000",
          },
        },
      },
    },

    MuiTablePagination: {
      defaultProps: {
        sx: {
          // caption: {
          //   fontSize: 12,
          //   color: tableColors.grey700,
          //   opacity: 0.5,
          // },
          toolbar: {
            borderBottom: `1px solid ${tableColors.grey300}`,
            marginBottom: "2px",
            borderTop: `1px solid ${tableColors.grey300}`,
            marginTop: "3px",
          },
          root: {
            backgroundColor: tableColors.white,
          },
          menuItem: {
            fontSize: 12,
          },
          select: {
            backgroundColor: colors.grey100,
            borderRadius: 4,
            padding: "0.2em",
            textAlignLast: "center",
          },
          selectIcon: {
            color: tableColors.grey700,
            opacity: 0.54,
            height: "1.2em",
            width: "1.2em",
            marginRight: 0,
            marginLeft: "0.5em",
          },
        },
      },
    },
    MuiTable: {
      defaultProps: {
        sx: {
          stickyHeader: {
            borderCollapse: "collapse",
          },
        },
      },
    },

    MuiTableCell: {
      defaultProps: {
        sx: {
          head: {
            fontWeight: "bold",
          },
          stickyHeader: {
            backgroundColor: tableColors.lbcgrey,
          },
          root: {
            fontSize: "14px",
            padding: "12.5px",
            height: "auto",
            width: "unset",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
          },
          body: {
            border: `1px solid ${tableColors.lbcgrey}`,
            borderLeft: 0,
            borderRight: 0,
            width: "unset",
          },
        },
      },
    },

    MuiTableRow: {
      defaultProps: {
        sx: {
          root: {
            display: "flex",
            "&:hover": {
              backgroundColor: tableColors.lbcyellow,
            },
          },
        },
      },
    },
  },
});
export default overrides;
