import Checkbox from "components/lbc-toolkit/atomics/Checkbox";
import React from "react";
import { TableToggleRowsSelectedProps } from "react-table";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import getTestIDs from "lib/utils/getTestIDs";

export const testIDs = getTestIDs();
const useStyles = makeStyles<Theme>(
  (theme) =>
    createStyles({
      checkboxRow: {
        color: theme.colors.grey300,
        "&$checked": {
          color: theme.colors.tBlack,
        },
      },
      checked: {},
    }),

  { name: `Table-Cells` }
);

export const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps>((props, ref) => {
  const { indeterminate, ...rest } = props;

  const classes = useStyles();
  // @ts-ignore
  return (
    <Checkbox
      classes={{ checked: classes.checked, root: classes.checkboxRow }}
      size="small"
      {...rest}
      testId={testIDs.tableCellsSmallCheckbox}
    />
  );
});

export const IndeterminateCheckboxRow = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps>(
  (props, ref) => {
    const { indeterminate, ...rest } = props;
    // const defaultRef = React.useRef<HTMLInputElement>();
    // const resolvedRef = ref ?? defaultRef;
    const classes = useStyles();

    // React.useEffect(() => {
    //   // @ts-ignore
    //   resolvedRef.current.indeterminate = indeterminate;
    // }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        size="small"
        classes={{ checked: classes.checked, root: classes.checkboxRow }}
        // @ts-ignore
        // ref={resolvedRef}
        {...rest}
        testId={testIDs.tableCellsCheckbox}
      />
    );
  }
);
