import { MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import translations from "translations";
import { sub } from "date-fns";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import { useRecoilState } from "recoil";
import { selectedOptionForDateFilterAtom } from "./recoilState";
const { time: timeTexts } = translations.globals;
const durationOptions = [
  // { value: "24 hours", label: "All" },
  { value: "24 hours", label: timeTexts.last24Hours },
  { value: "72 hours", label: timeTexts.last3Days },
  { value: "1 weeks", label: timeTexts.lastWeek },
  { value: "1 months", label: timeTexts.lastMonth },
  { value: "1 years", label: timeTexts.lastYear },
];

const DateTimeFilterInput = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;
  const [value, setValue] = useRecoilState(selectedOptionForDateFilterAtom(item.id as number));
  const dateForSub = useMemo(() => {
    if (!value) {
      applyValue({
        ...item,
        value: undefined,
      });
    } else {
      const specifiedDurationForSub = value.split(" ");
      applyValue({
        ...item,
        value: sub(new Date(), { [specifiedDurationForSub[1]]: Number(specifiedDurationForSub[0]) }).toISOString(),
      });
    }
  }, [value]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    return dateForSub;
  };

  return (
    <Select labelId="date-time-select-label" id="date-time-select" value={value} onChange={handleChange} displayEmpty>
      <MenuItem disabled value="">
        {translations.entities.dateRangeSelector.selectDateRange}
      </MenuItem>
      {durationOptions.map((e) => {
        return (
          <MenuItem value={e.value} key={e.value}>
            {e.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default DateTimeFilterInput;
