import { Box, Divider, TableRow, Typography } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import PLIcon from "components/patternLib/PLIcon";
import PLTextlink from "components/patternLib/PLTextlink";
import React from "react";
import {
  seeMoreButtonWrapper,
  iconStyles,
  subTitleWrapper,
  SummaryTileBadgeSC,
  SummaryTileSC,
  TileAndBadgeWrapperSC,
  TableAndContentFooterWrapperSC,
  SummaryTableSC,
  ContentFooterWrapperSC,
  SummaryTableWrapperSC,
  dividerStyles,
} from "./styles";
import translations from "translations";
import WarningMessage from "components/atomics/WarningMessage";
interface SummaryTileProps {
  NoTableDataComponent: JSX.Element;
  contentFooter?: JSX.Element;
  contentFooterTitle?: string;
  handleSeeMore: () => void;
  iconName: string;
  loading: boolean;
  tableElements: JSX.Element[];
  tableTitle: string;
  title: string;
  warningMessage?: string;
}

const SummaryTile: React.FC<SummaryTileProps> = ({
  title,
  iconName,
  tableElements,
  tableTitle,
  loading,
  handleSeeMore,
  contentFooter,
  contentFooterTitle,
  warningMessage,
  NoTableDataComponent,
}) => {
  const SummaryDivider = (margin?: string) => <Divider variant="fullWidth" sx={dividerStyles(margin)} />;

  return (
    <TileAndBadgeWrapperSC>
      <SummaryTileBadgeSC>
        <Box sx={iconStyles}>
          <PLIcon size="48px" iconName={iconName} />
        </Box>
      </SummaryTileBadgeSC>
      <SummaryTileSC>
        <Typography sx={{ marginTop: "30px" }} variant="h5">
          {title}
        </Typography>
        {SummaryDivider()}
        <LoadingSpinner hideChildrenOnLoad loading={loading}>
          <TableAndContentFooterWrapperSC>
            <SummaryTableWrapperSC hasContentFooter={!!contentFooter}>
              <Typography variant="copyBold" color="var(--color-steel-600)">
                {tableTitle}
              </Typography>
              <SummaryTableSC hasContentFooter={!!contentFooter}>
                {tableElements?.length
                  ? tableElements?.map((element, index) => {
                      return <TableRow key={`summary-table-row-${index}`}>{element}</TableRow>;
                    })
                  : NoTableDataComponent}
              </SummaryTableSC>
              {warningMessage ? <WarningMessage text={warningMessage} /> : null}
            </SummaryTableWrapperSC>
            {contentFooter ? (
              <ContentFooterWrapperSC>
                <Box sx={subTitleWrapper}>
                  <Typography variant="copyBold" color="var(--color-steel-600)">
                    {contentFooterTitle}
                  </Typography>
                </Box>
                {contentFooter}
              </ContentFooterWrapperSC>
            ) : null}
          </TableAndContentFooterWrapperSC>
        </LoadingSpinner>
        {tableElements?.length === 0 || contentFooter ? SummaryDivider("20px 0") : null}
        <Box sx={seeMoreButtonWrapper}>
          <PLTextlink
            width="fit-content"
            onClick={handleSeeMore}
            label={translations.globals.button.seeMore}
            size="small"
            bold={false}
            uppercase={false}
          />
        </Box>
      </SummaryTileSC>
    </TileAndBadgeWrapperSC>
  );
};

export default SummaryTile;
