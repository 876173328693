import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserEmailsForCreateUpdateNotificationsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.UserSortInput> | Types.UserSortInput>;
}>;


export type GetUserEmailsForCreateUpdateNotificationsQuery = { users: Array<{ __typename?: 'User', label?: Maybe<string>, value: string }> };


export const GetUserEmailsForCreateUpdateNotificationsDocument = gql`
    query getUserEmailsForCreateUpdateNotifications($where: UserFilterInput, $take: Int, $order: [UserSortInput!]) {
  users(where: $where, take: $take, order: $order) {
    label: email
    value: id
  }
}
    `;

/**
 * __useGetUserEmailsForCreateUpdateNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUserEmailsForCreateUpdateNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEmailsForCreateUpdateNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEmailsForCreateUpdateNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUserEmailsForCreateUpdateNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserEmailsForCreateUpdateNotificationsQuery, GetUserEmailsForCreateUpdateNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserEmailsForCreateUpdateNotificationsQuery, GetUserEmailsForCreateUpdateNotificationsQueryVariables>(GetUserEmailsForCreateUpdateNotificationsDocument, options);
      }
export function useGetUserEmailsForCreateUpdateNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserEmailsForCreateUpdateNotificationsQuery, GetUserEmailsForCreateUpdateNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserEmailsForCreateUpdateNotificationsQuery, GetUserEmailsForCreateUpdateNotificationsQueryVariables>(GetUserEmailsForCreateUpdateNotificationsDocument, options);
        }
export type GetUserEmailsForCreateUpdateNotificationsQueryHookResult = ReturnType<typeof useGetUserEmailsForCreateUpdateNotificationsQuery>;
export type GetUserEmailsForCreateUpdateNotificationsLazyQueryHookResult = ReturnType<typeof useGetUserEmailsForCreateUpdateNotificationsLazyQuery>;
export type GetUserEmailsForCreateUpdateNotificationsQueryResult = Apollo.QueryResult<GetUserEmailsForCreateUpdateNotificationsQuery, GetUserEmailsForCreateUpdateNotificationsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
