import { ApolloClient } from "@apollo/client";
import singleMultiNameMappings from "graphqlBase/singleMultiNameMappings";
import jp from "jsonpath";
import { getDynamicQuery } from "lib/utils/getEntityToEntityPaths/pathDescriptions";
import merge from "lodash/merge";
import { JsonRule, JsonTree } from "react-awesome-query-builder";
import { runFieldMiddleWare } from "./middleWareUtils";
import { getOperator } from "../utils/operatorMatches";
import { QueryMiddleWares, Tree } from "../types";

interface Updater {
  field?: JsonRule;
  fieldId: string;
  fieldName: string;
}

const firstToLowerCase = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

const queryfy = (obj: object): string => {
  // Make sure we don't alter integers.
  if (typeof obj === "number") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const props = obj.map((value) => `${queryfy(value)}`).join(" ");
    return `[${props}]`;
  }

  if (typeof obj === "object") {
    const props = Object.keys(obj)
      //@ts-ignore
      .map((key) => `${key}:${queryfy(obj[key])}`)
      .join(" ");
    return `{${props}}`;
  }

  return JSON.stringify(obj);
};

const walkTree = ({
  tree,
  body,
  variables,
  entityName,
  updaters,
}: {
  body: object;
  entityName: string;
  tree: Tree;
  updaters: Updater[];
  variables: object[];
}): { body: object; variables: object[] } => {
  switch (tree.type) {
    case "group": {
      const { variablesInner, bodyInner } = Object.entries(tree.children1 ?? {}).reduce<{
        bodyInner: object;
        variablesInner: object[];
      }>(
        ({ variablesInner, bodyInner }, [fieldId, node]) => {
          const updater = updaters.find((updater) => !!updater.field && updater.fieldId === fieldId);
          const newTree = updater?.field ?? node;
          const { variables: variablesRet, body: bodyRet } = walkTree({
            tree: newTree,
            variables: variablesInner,
            body: bodyInner,
            entityName,
            updaters,
          });
          return { variablesInner: variablesRet, bodyInner: bodyRet };
        },
        { variablesInner: [], bodyInner: body }
      );

      if (tree.properties?.conjunction)
        return {
          variables: variables.concat({ [tree.properties?.conjunction.toLowerCase()]: variablesInner }),
          body: bodyInner,
        };
      return {
        variables: variablesInner,
        body: bodyInner,
      };
    }
    case "rule": {
      const ruleTree = tree;

      const [filterEntity, field] = (ruleTree.properties.field ?? "").split(".");

      if (!field || !tree.properties.value.length) return { variables, body };

      const { getFilterPath, getQueryBody } = getDynamicQuery({ entityName, filterEntity });

      return {
        variables: variables.concat(
          getFilterPath({
            [field]: { [getOperator(ruleTree.properties.operator ?? "")]: ruleTree.properties.value[0] },
          })
        ),
        body: merge(body, getQueryBody(field)),
      };
    }
    default:
      return { variables: [], body: {} };
  }
};

const getPaths = (tree: JsonTree) => {
  return jp.nodes(tree, `$..${"field"}`).map((elem) => ({ path: elem.path, fieldName: elem.value }));
};

const rawQuerytoGqlQuery = async ({
  tree,
  entityName,
  queryMiddleWares,
  client,
  queryStringsReturnValue,
}: {
  client: ApolloClient<object>;
  entityName: string;
  queryMiddleWares?: QueryMiddleWares;
  tree: JsonTree;
  queryStringsReturnValue: string;
}) => {
  const names = getPaths(tree);

  const middleWareUpdates = names.reduce<Array<Promise<Updater>>>((middleWareUpdates, { fieldName, path }) => {
    const middleWare = (queryMiddleWares ?? []).find((middleWare) => {
      return middleWare.key === fieldName;
    });
    if (middleWare) {
      const properties = jp.parent(tree, jp.stringify(path)) as JsonRule["properties"];

      const result = runFieldMiddleWare<JsonRule>({
        middleWare: middleWare.middleWare,
        field: { properties, type: "rule" },
        fieldName,
        client,
        getOperator,
      });

      const fullResult = result.then((result) => ({
        ...result,
        fieldId: `${path[path.length - 3]}` ?? "",
      }));

      return middleWareUpdates.concat(fullResult);
    }
    return middleWareUpdates;
  }, []);

  const updaters = await Promise.all(middleWareUpdates);

  const { variables } = walkTree({ tree, variables: [], body: {}, entityName, updaters });
  const entityNamePlural = singleMultiNameMappings[firstToLowerCase(entityName)];

  const queryString = `query QueryBuilder_ {
    result:${entityNamePlural}(where:${queryfy({ and: variables })}){
      ${queryStringsReturnValue}
    }
  }`;

  return queryString;
};

export default rawQuerytoGqlQuery;
