// @ts-ignore
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { DataProxy } from "@apollo/client";
import gql from "graphql-tag";
import { Client, ConfigFiles, EntityNames, Operation } from "../formGen.model";

type Indentifier = string[] | string;

interface FetchConfigVars {
  client: Client;
  keys: Indentifier;
}

export const joiner = "/";

interface SaveConfVars extends FetchConfigVars {
  data: any;
}

export const getIdentfier = (keys: Indentifier) => {
  if (Array.isArray(keys)) {
    return keys.join("/");
  }
  if (typeof keys === "string") {
    return keys;
  }
  return "";
};

export const updateTree = (cache: DataProxy, id: string, operation: "add" | "remove") => {
  const {
    readConfigs,
  }: {
    readConfigs: Array<{ id: string; __typename: string }>;
  } = cache.readQuery({
    query: getTree,
  }) || { readConfigs: [] };

  cache.writeQuery({
    query: getTree,
    data: {
      readConfigs:
        operation === "add"
          ? readConfigs.concat([{ id, __typename: "RAFGConfig" }])
          : readConfigs.filter((conf) => conf.id !== id),
    },
  });
};

export const useDeleteItem = (key: string) => {
  const [deleteItemFuc] = useMutation(deleteConfigs, {
    update(cache: DataProxy) {
      updateTree(cache, key, "remove"); // TBD
    },
  });
  deleteItemFuc({ variables: { key } });
};

const getConfifQuery = gql`
  query getConfig($key: [String]!) {
    configurator(configuratorKey: $key) {
      id: configuratorKey
      data: configuratorValue
    }
  }
`;

export const getTree = gql`
  query getConfigList {
    readConfigs: configurators {
      id: configuratorKey
    }
    # actives: configurators(filter:{configuratorKey:{contains:"actives"}}) {
    #  id:configuratorKey
    #   data:configuratorValue
    # }
  }
`;
export const deleteConfigs = gql`
  mutation deleteConfig($key: [String!]!) {
    deleteConfigurator(configuratorKey: $key) {
      configuratorKey
    }
  }
`;
export const listConfigs = gql`
  query getConfigList($search: [String]!) {
    readConfigs: configurators(configuratorKey: $search) {
      id: configuratorKey
    }
    # actives: configurators(filter:{configuratorKey:{contains:"actives"}}) {
    #  id:configuratorKey
    #   data:configuratorValue
    # }
  }
`;

export const saveConfigMutation = gql`
  mutation saveConfigs($key: String!, $data: String!) {
    createConfigurator(configurator: { configuratorKey: $key, configuratorValue: $data }) {
      id: configuratorKey
      data: configuratorValue
    }
  }
`;

export const useGetTree = () => {
  return useQuery(listConfigs, {
    variables: { search: "tree" },
  });
};

export const getConfig = ({ client, keys }: FetchConfigVars) => {
  return client.query({
    query: getConfifQuery,
    variables: { key: getIdentfier(keys) },
  });
};

export const getConfigList = ({ client, keys }: FetchConfigVars) => {
  return client.query({
    query: listConfigs,
    variables: { search: getIdentfier(keys) },
  });
};

export const createConfig = ({ client, keys }: FetchConfigVars) => {
  return client.mutate({
    mutation: saveConfigMutation,
    variables: { key: getIdentfier(keys), data: {} },
  });
};

export const saveConfig = ({ client, keys, data }: SaveConfVars) => {
  return client.mutate({
    mutation: saveConfigMutation,
    variables: { key: getIdentfier(keys), data },
  });
};
