import { Box, Theme, ToggleButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  GridRowId,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilterProps,
} from "@mui/x-data-grid-pro";
import ConfirmAndDelete from "components/molecules/ConfirmAndDelete";
import PLIcon from "components/patternLib/PLIcon";
import { genericDeleteParams } from "components/templates/table-factory/Table/Cells/actionsCellFactory/recoilStates";
import React from "react";
import { useRecoilValue } from "recoil";
import Can from "authorization";
import translations from "translations";
import {
  filterButtonStyles,
  StyledActionButton,
  StyledGridSelectedRowCount,
  StyledGridToolbarOptions,
  StyledGridToolbarColumnsButton,
  StyledGridToolbarContainer,
  StyledGridToolbarContainerInner,
  StyledGridToolbarQuickFilter,
  tableTitleStyles,
  toolbarItemsBoxStylesWithRowSelection,
  toolbarOnlyDefaultOptions,
  StyledToolbarButton,
  StyledGridToolbarSelectedRowsButton,
} from "../styles";

export interface HandleEditProps {
  editScope: string;
  handleEditRow: (id: string) => void;
}

export interface HandleDeleteProps {
  deleteScope: string;
  handleDeleteRows: (ids: string[]) => void;
}

export interface ExtendedToolbarProps extends GridToolbarProps {
  deleteScope?: string;
  handleDelete?: HandleDeleteProps;
  handleEdit?: HandleEditProps;
  selectedRows?: GridRowId[] | undefined;
  selectedRowsFilterOn?: boolean;
  setIsTagModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRowsFilterOn?: React.Dispatch<React.SetStateAction<boolean>>;
  tableTitle: string;
}

const TableTitle = (title: string) => (
  <Typography sx={tableTitleStyles} variant="h6">
    {title}
  </Typography>
);

const SearchField = (props: GridToolbarQuickFilterProps) => (
  <StyledGridToolbarQuickFilter
    variant="outlined"
    placeholder={translations.globals.placeholders.searchTable}
    {...props}
  />
);
const SettingsButton = (
  <StyledGridToolbarColumnsButton size="medium" startIcon={<PLIcon size="18px" iconName="gearwheel" />} />
);

export const Toolbar = React.forwardRef<HTMLDivElement, ExtendedToolbarProps>(function GridToolbar(
  {
    showQuickFilter,
    quickFilterProps = {},
    selectedRows,
    tableTitle,
    handleDelete,
    handleEdit,
    hasOnlyDefaultToolbar,
    setIsTagModalOpen,
    setSelectedRowsFilterOn,
    selectedRowsFilterOn,
  },
  ref
) {
  const deleteParams = useRecoilValue(genericDeleteParams);

  const selectedIds = selectedRows?.reduce<string[]>((selectedIds, id) => {
    if (id) return selectedIds.concat(id.toString());
    return selectedIds;
  }, []);

  return (
    <>
      <StyledGridToolbarContainer ref={ref}>
        <StyledGridToolbarContainerInner>
          <StyledGridToolbarOptions>
            {TableTitle(tableTitle)}
            {hasOnlyDefaultToolbar && (
              <Box sx={toolbarOnlyDefaultOptions}>
                <GridToolbarFilterButton sx={filterButtonStyles} />
                {SettingsButton}
              </Box>
            )}
            {selectedRows && (
              <Box sx={toolbarItemsBoxStylesWithRowSelection}>
                <StyledGridToolbarSelectedRowsButton
                  value="check"
                  disabled={selectedRows?.length < 1}
                  selected={selectedRowsFilterOn}
                  onChange={() => {
                    setSelectedRowsFilterOn?.(!selectedRowsFilterOn);
                  }}
                >
                  <StyledGridSelectedRowCount selectedRowCount={selectedRows?.length} />
                </StyledGridToolbarSelectedRowsButton>
                <GridToolbarFilterButton sx={filterButtonStyles} />
                {setIsTagModalOpen && (
                  <StyledToolbarButton
                    onClick={() => {
                      setIsTagModalOpen?.(true);
                    }}
                    disabled={selectedRows?.length < 1}
                  >
                    <PLIcon size="18px" iconName="favourites" color={selectedRows?.length < 1 ? "#888E94" : "#000"} />
                    <span style={{ marginLeft: "2px" }}>{translations.entities.tag.plural}</span>
                  </StyledToolbarButton>
                )}
                {handleEdit && (
                  // eslint-disable-next-line react/jsx-handler-names
                  <Can I="read" a={handleEdit.editScope}>
                    <StyledActionButton
                      onClick={() => handleEdit.handleEditRow?.(selectedIds?.[0] ?? "")}
                      style={{
                        cursor: selectedRows.length !== 1 ? "not-allowed" : "pointer",
                      }}
                      disabled={selectedRows.length !== 1}
                    >
                      <PLIcon iconName="edit" size="18px" />
                      <Typography
                        sx={{ fontFamily: "var(--copytext-font-family)", fontSize: "15px", textTransform: "none" }}
                      >
                        {translations.globals.terms.edit}
                      </Typography>
                    </StyledActionButton>
                  </Can>
                )}
                {handleDelete && (
                  // eslint-disable-next-line react/jsx-handler-names
                  <Can I="read" a={handleDelete.deleteScope}>
                    <StyledActionButton
                      onClick={() => handleDelete.handleDeleteRows?.(selectedIds ?? [])}
                      style={{
                        cursor: selectedRows.length < 1 ? "not-allowed" : "pointer",
                      }}
                      disabled={selectedRows.length < 1}
                    >
                      <PLIcon iconName="bin" size="18px" />
                      <Typography
                        sx={{ fontFamily: "var(--copytext-font-family)", fontSize: "15px", textTransform: "none" }}
                      >
                        {translations.globals.terms.delete}
                      </Typography>
                    </StyledActionButton>
                  </Can>
                )}
                {SettingsButton}
              </Box>
            )}
          </StyledGridToolbarOptions>
          {showQuickFilter && SearchField(quickFilterProps)}
        </StyledGridToolbarContainerInner>
      </StyledGridToolbarContainer>
      <ConfirmAndDelete
        title={deleteParams.modalTitle ?? ""}
        message={deleteParams?.modalMessage}
        show={!!deleteParams?.id && !!deleteParams?.entity}
      />
    </>
  );
});
