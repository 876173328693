import { CheckboxState, ItemState } from ".";
import { FlattenData } from "..";

export const updateItemStates = (oldState: ItemState[], items: FlattenData[], clickedId: string) => {
  const newState = oldState.map((i) => ({ ...i }));

  const getItemState = (id: string) => {
    return newState.find((i) => i.id === id)?.state;
  };

  const updateParent = (id: string) => {
    const item = items.find((i) => i.id === id);
    const parent = items.find((i) => i.id === item?.parentId ?? "");
    if (!parent) return;
    const childIds = items.filter((i) => i.parentId === parent.id).map((i) => i.id);
    const childStates = childIds.map((childId) => getItemState(childId));
    const newStateByParentId = newState.find((i) => i.id === parent.id);
    if (!newStateByParentId) return;
    if (childStates.length === childStates.filter((s) => s === CheckboxState.CHECKED).length) {
      newStateByParentId.state = CheckboxState.CHECKED;
    } else if (childStates.length === childStates.filter((s) => s === CheckboxState.UNCHECKED).length) {
      newStateByParentId.state = CheckboxState.UNCHECKED;
    } else {
      newStateByParentId.state = CheckboxState.INDETERMINATE;
    }
    updateParent(parent.id);
  };
  const setUnchecked = (id: string) => {
    const newStateId = newState.find((i) => i.id === id);
    if (newStateId) newStateId.state = CheckboxState.UNCHECKED;
    items
      .filter((i) => i.parentId === id)
      .map((i) => i.id)
      .forEach((childId) => setUnchecked(childId));
    updateParent(id);
  };
  const setChecked = (id: string) => {
    const newStateId = newState.find((i) => i.id === id);
    if (newStateId) newStateId.state = CheckboxState.CHECKED;
    items
      .filter((i) => i.parentId === id)
      .map((i) => i.id)
      .forEach((childId) => setChecked(childId));
    updateParent(id);
  };

  const itemState = getItemState(clickedId);
  if (itemState === CheckboxState.CHECKED) {
    setUnchecked(clickedId);
  } else {
    setChecked(clickedId);
  }
  return newState;
};
