import { createTheme } from "@mui/material/styles";
import colors from "./colors";

const fontFamily = "var(--copytext-font-family)";
const fontFamilyHeading = "var(--headline-font-family)";
const fontWeightBlack = 800;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main: colors.brandYellow,
    },
    secondary: { main: colors.steelBlue },
    success: {
      main: colors.green,
    },
    warning: { main: colors.orange },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.tBlack,
    },
    background: {
      default: "#F1F3F6",
      paper: "#FFFFFF",
    },
  },
  colors,
  typography: {
    htmlFontSize: 10,
    fontFamily,
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});

const h1 = {
  fontFamily: fontFamilyHeading,
  fontWeight: fontWeightBlack,
  lineHeight: 1,
  textTransform: "capitalize",
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px",
    lineHeight: 1.125,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "34px",
    lineHeight: 1.12,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "36px",
    lineHeight: 1.1,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px",
    lineHeight: 1.1,
  },
};

const h2 = {
  fontFamily: fontFamilyHeading,
  fontWeight: fontWeightBlack,
  lineHeight: 1,
  textTransform: "capitalize",
  [theme.breakpoints.up("xs")]: {
    fontSize: "28px",
    lineHeight: 1.14,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
    lineHeight: 1.125,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "40px",
    lineHeight: 1.1,
  },
};

const h3 = {
  fontFamily: fontFamilyHeading,
  fontWeight: fontWeightBlack,
  lineHeight: 1,
  [theme.breakpoints.up("xs")]: {
    fontSize: "26px",
    lineHeight: 1.154,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "32px",
    lineHeight: 1.125,
  },
};

const h4 = {
  fontFamily: fontFamilyHeading,
  fontWeight: fontWeightBlack,
  lineHeight: 1,
  [theme.breakpoints.up("xs")]: {
    fontSize: "24px",
    lineHeight: 1.167,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "28px",
    lineHeight: 1.142,
  },
};

const h5 = {
  fontFamily: fontFamilyHeading,
  fontWeight: fontWeightBlack,
  lineHeight: 1,
  [theme.breakpoints.up("xs")]: {
    fontSize: "22px",
    lineHeight: 1.18,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
    lineHeight: 1.167,
  },
};

const h6 = {
  fontWeight: fontWeightBlack,
  fontFamily: fontFamilyHeading,
  fontSize: "20px",
  lineHeight: 1.2,
};

const copy = {
  fontWeight: 400,
  fontFamily,
  lineHeight: 1,
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    lineHeight: 2,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: 1.5,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
    lineHeight: 1.56,
  },
};
const copyBold = {
  fontWeight: 600,
  fontFamily,
  lineHeight: 1,
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    lineHeight: 1.4,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    lineHeight: 1.5,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
    lineHeight: 1.56,
  },
};

theme.typography.body1 = {
  lineHeight: 1,
  "& .MuiTypography-h1": {
    ...h1,
  },
  "& .MuiTypography-h2": {
    ...h2,
  },
  "& .MuiTypography-h3": {
    ...h3,
  },
  "& .MuiTypography-h4": {
    ...h4,
  },
  "& .MuiTypography-h5": {
    ...h5,
  },
  "& .MuiTypography-h6": {
    ...h6,
  },
  "& .MuiTypography-copy": {
    ...copy,
  },
  "& .MuiTypography-copyBold": {
    ...copyBold,
  },
};

theme.typography.body2 = {
  lineHeight: 2.8,
  "& .MuiTypography-h1": {
    ...h1,
  },
  "& .MuiTypography-h2": {
    ...h2,
  },
  "& .MuiTypography-h3": {
    ...h3,
  },
  "& .MuiTypography-h4": {
    ...h4,
  },
  "& .MuiTypography-h5": {
    ...h5,
  },
  "& .MuiTypography-h6": {
    ...h6,
  },
  "& .MuiTypography-copy": {
    ...copy,
  },
  "& .MuiTypography-copyBold": {
    ...copyBold,
  },
};

export default theme;
