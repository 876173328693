export default {
  recentAssignments: "Recent Assignments",
  countOpenAssets: (count: number) => `${count} Assets to be identified`,
  countFirmwareUpdateDevices: (count: number) => `Available for ${count} devices`,
  countDeviceCriticalBattery: (count: number) =>
    `${count} of your devices ${count > 1 ? "have" : "has"} critical battery condition`,
  firmwareUpdate: "Firmware Update",
  noDataAssetSummary: "Use the LiTrack Mobile App to start assigning devices to your assets.",
  noDataDeviceSummary: "Start scanning your devices using the LiTrack Mobile App.",
  noDataAlertSummary: "No alerts have been triggered yet. Go here to manage your alerting system.",
  noLowBatteryDevicesSummary: "All your devices have a better battery condition than 20 %.",
};
