import { useSearchControl } from "components/pages/MapAndTable/utils";
import React, { useEffect } from "react";
import { Marker, useMapEvents } from "react-leaflet";
import { deviceConfigurationModifier, siteAreaDefinitionModifier, SiteObjectType } from "../recoilState";
import { useRecoilState } from "recoil";
import deviceConfigMarker from "components/atomics/markers/deviceConfigMarker";
import { LatLngExpression } from "leaflet";

const SiteAreas = () => {
  const [positionLatLng, setPositionsLatLng] = useRecoilState(siteAreaDefinitionModifier);
  const { lat, lon } = positionLatLng;
  const map = useMapEvents({
    click(e) {
      setPositionsLatLng((prev) => {
        return { ...prev, lat: e.latlng.lat, lon: e.latlng.lng } as SiteObjectType;
      });
    },
  });
  const isPositionNull = !lat || !lon;
  useEffect(() => {
    if (isPositionNull) {
      map.panTo([52.634281158447266, 13.288796424865723]);
    } else {
      map.panTo([lat, lon] as LatLngExpression);
    }
  }, [map, isPositionNull, lat, lon]);

  useSearchControl({ map, style: "bar" });
  return isPositionNull ? null : <Marker icon={deviceConfigMarker()} position={[lat, lon] as LatLngExpression} />;
};

export default SiteAreas;
