import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetMappingDeviceTagsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.MappingDeviceTagFilterInput>;
}>;


export type GetMappingDeviceTagsQuery = { mappingDeviceTags: Array<{ __typename?: 'MappingDeviceTag', id: string, deviceId: string, tagId: string, tag: { __typename?: 'Tag', id: string, tagValue: string } }> };


export const GetMappingDeviceTagsDocument = gql`
    query getMappingDeviceTags($where: MappingDeviceTagFilterInput) {
  mappingDeviceTags(where: $where) {
    id
    deviceId
    tagId
    tag {
      id
      tagValue
    }
  }
}
    `;

/**
 * __useGetMappingDeviceTagsQuery__
 *
 * To run a query within a React component, call `useGetMappingDeviceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingDeviceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingDeviceTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMappingDeviceTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMappingDeviceTagsQuery, GetMappingDeviceTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMappingDeviceTagsQuery, GetMappingDeviceTagsQueryVariables>(GetMappingDeviceTagsDocument, options);
      }
export function useGetMappingDeviceTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMappingDeviceTagsQuery, GetMappingDeviceTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMappingDeviceTagsQuery, GetMappingDeviceTagsQueryVariables>(GetMappingDeviceTagsDocument, options);
        }
export type GetMappingDeviceTagsQueryHookResult = ReturnType<typeof useGetMappingDeviceTagsQuery>;
export type GetMappingDeviceTagsLazyQueryHookResult = ReturnType<typeof useGetMappingDeviceTagsLazyQuery>;
export type GetMappingDeviceTagsQueryResult = Apollo.QueryResult<GetMappingDeviceTagsQuery, GetMappingDeviceTagsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
