import styled from "@emotion/styled";

export const TagListContainerSC = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "4vh 0 8vh 0",
});

export const TagListItemSC = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  "&:hover .deleteIcons": {
    display: "flex",
  },
  [`& .deleteIcons`]: { display: "none" },
});

export const InputAndButtonWrapperSC = styled("div")({
  display: "flex",
  flexDirection: "row",
  columnGap: "12px",
});

export const TagCreateContainerSC = styled("div")({
  marginTop: "8vh",
});

export const FooterButtonsContainerSC = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  padding: "4vh",
  width: "100%",
});

export const FooterButtonsSC = styled("div")({ display: "flex", gap: "35px" });

export const footerDividerStyles = { height: "0.5px", padding: 0, marginTop: "20px" };

export const TagModalContentSC = styled("div")({ padding: "0 4vh", alignItems: "center" });
