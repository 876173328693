const tableCellStyles = {
  "&.MuiTableRow-root": {
    fontFamily: "LiebherrText-Bold,-apple-system,BlinkMacSystemFont,Arial,Helvetica,sans-serif",
    paddingLeft: 25,
  },
};
export const muiTableCellOverrides = {
  root: {
    ...tableCellStyles,
  },
};
