import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginLeft: "-12px",
    },
    roleManager: {
      display: "flex",
      paddingBottom: "30px",
      marginLeft: "-15px",
    },
    selectRoot: {
      paddingBottom: "30px",
    },
    select: {
      width: "280px",
      height: "45px",
    },
    title: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    buttons: {
      display: "flex",
      justifyContent: "end",
      marginTop: "-13px",
    },
    newRoleButton: {
      justifyContent: "end",
      display: "flex",
    },
    deleteButton: {
      justifyContent: "end",
      display: "flex",
      marginBottom: "-40px",
      paddingRight: "10px",
    },
    backButton: {
      height: "40px",
      padding: 0,
      width: "40px",
      transform: "rotate(-180deg)",
      "&:hover": {
        background: "inherit",
      },
    },
    saveButton: {
      display: "flex",
      justifyContent: "end",
      paddingBottom: "20px",
      paddingTop: "60px",
      width: "100%",
    },
    saveButtonCreateEditRole: {
      marginLeft: "90%",
      marginTop: "30px",
    },
    titleCreateRole: {
      display: "flex",
      marginLeft: "-14px",
    },
  })
);
