import { atom, atomFamily } from "recoil";
import { QueryResults } from "..";
import { QueryBuilderQueryStrings } from "../types";

export const queryBuilderResultIds = atomFamily<string[], string>({
  key: "ElementPosition",
  default: [],
});

export const queryBuilderQueryStrings = atomFamily<QueryBuilderQueryStrings | undefined, string>({
  key: "queryBuilderQueryStrings",
  default: undefined,
});

export const queryExecutionResult = atom<QueryResults>({
  key: "queryExecutionResult",
  default: { data: undefined, error: undefined, loading: undefined },
});
