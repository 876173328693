export const muiBadgeOverrides = {
  root: {
    borderRadius: "100%",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
    display: "inline-flex",
    boxSizing: "border-box",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      margin: "auto",
      paddingTop: "2px",
    },
  },
};
