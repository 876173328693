import { assetAttributeIcon } from "components/pages/AssetManagement/utils";
import { useGetCouplingsForAttachmentLazyQuery } from "graphqlBase/Couplings/__generated__/getCouplingsForAttachment";
import { useGetCouplingsForExcavatorLazyQuery } from "graphqlBase/Couplings/__generated__/getCouplingsForExcavator";
import { useEffect, useState } from "react";
import { formatHoursAndMinutes, getHoursAndMinutes } from "translations/formatter";
import { CoupledAsset, CurrentCouplingsTileProps } from ".";

interface UseGetCouplingsReturn {
  coupledAssets: CoupledAsset[];
  resetCoupledAsset: () => void;
}

const formattedDuration = (value: number) => {
  return formatHoursAndMinutes(getHoursAndMinutes(value));
};

export default ({ deviceId, isExcavator }: CurrentCouplingsTileProps): UseGetCouplingsReturn => {
  const [coupledAssets, setCoupledAsset] = useState<CoupledAsset[]>([]);
  // const resetCoupledAsset = () => setCoupledAsset([]);
  const resetCoupledAsset = () => {
    // setCoupledAsset([]);
  };

  const [getExcavatorCoupledItems, excavatorState] = useGetCouplingsForExcavatorLazyQuery();
  const [getAttachmentCoupledItems, attachmentState] = useGetCouplingsForAttachmentLazyQuery();

  const query = isExcavator ? getExcavatorCoupledItems : getAttachmentCoupledItems;

  useEffect(() => {
    setCoupledAsset([]);
    query({ variables: { deviceId } });
    const queryRefetch = setTimeout(() => {
      query({ variables: { deviceId } });
    }, 10000);
    return () => {
      setCoupledAsset([]);
      clearTimeout(queryRefetch);
    };
  }, [deviceId, query]);

  useEffect(() => {
    const foundAsssets = isExcavator
      ? excavatorState.data?.couplingStatusExcavator
      : attachmentState.data?.couplingStatusAttachment;

    setCoupledAsset(
      (foundAsssets?.relatedCoupledDevices ?? []).reduce<CoupledAsset[]>((couplings, elem) => {
        const assetName = elem?.device?.assetDevice?.[0]?.asset?.assetMasterData?.generalItem ?? "";
        if (!assetName) return couplings;

        const coupling: CoupledAsset = {
          assetName,

          iconName: isExcavator
            ? assetAttributeIcon[
                (elem?.device?.assetDevice?.[0]?.asset?.assetAttributeValue?.[0]?.value ?? "")
                  .toLowerCase()
                  .replace(/\s/g, "")
              ]
            : "mining-excavator",
          couplingDuration: formattedDuration(foundAsssets?.couplingStateOperatingMinutes ?? 0),
          assetId: elem?.device?.assetDevice?.[0]?.asset?.id ?? "",
        };
        return couplings.concat(coupling);
      }, [])
    );
  }, [excavatorState.data, attachmentState.data]);

  return { coupledAssets, resetCoupledAsset };
};
