import { QueryLazyOptions } from "@apollo/client";
import { styled, Typography } from "@mui/material";

import LoadingSpinner from "components/atomics/LoadingSpinner";
import Tooltip from "components/molecules/Tooltip/Tooltip";
import useDataGrid from "components/templates/dataGridTable";
import { QueryVariables } from "components/templates/table-factory/Table/useMakeServerSideTable";
import { Exact, Maybe, SensorMeasurementHistoryFilterInput } from "graphqlBase/types";
import React from "react";
import translations from "translations";
import { SensorMeasurement } from "../hooks/useGetSensorMeasurements";
import getCapabilityUnitSymbol from "../utils/getCapabilityUnitSymbol";
import { LeftTileWrapperSC } from "../utils/styles";

interface SensorMeasurementsProps {
  data: SensorMeasurement[];
  loading: boolean;
  query: (
    options?:
      | QueryLazyOptions<
          Exact<{
            skip?: Maybe<number> | undefined;
            take?: Maybe<number> | undefined;
            where?: Maybe<SensorMeasurementHistoryFilterInput> | undefined;
          }>
        >
      | undefined
  ) => void;
  variables: Exact<{
    skip?: Maybe<number> | undefined;
    take?: Maybe<number> | undefined;
    where?: Maybe<SensorMeasurementHistoryFilterInput> | undefined;
  }>;
}

const SensorMeasurements: React.FC<SensorMeasurementsProps> = ({ data, loading, query, variables }) => {
  const { DataGrid, useMakeColumns, getRowCount } = useDataGrid<SensorMeasurement, "SensorMeasurementHistory">({
    query,
    variables,
    tableId: "SensorMeasuremntsTable",
    persistance: "runTime",
  });

  const getCriticalStatus = (capabilityName: string, valueFloat: Maybe<number> | undefined) => {
    const batteryCapability = capabilityName.includes("Battery");
    const tempCapability = capabilityName.includes("Temperature");
    const value = valueFloat ?? 0;

    const batteryHealthCritical = !!(batteryCapability && value < 3);
    const tempHealthCritical = !!(tempCapability && value > 90);
    return { batteryHealthCritical, tempHealthCritical };
  };

  const columns = useMakeColumns(
    [
      {
        field: "localeTimeMeasured",
        headerName: translations.globals.time.measurementDate,
        renderHeader: (params) => `${translations.globals.time.measurementDate} (${getRowCount()})`,
        remoteOrder: (sort) => ({
          utcTimeMeasured: sort,
        }),
        flex: 2,
        type: "string",
      },
      {
        field: "capabilityName",
        headerName: translations.globals.terms.field,
        flex: 2,
        renderCell: (params) => {
          const { capabilityName, valueFloat } = params.row;
          const { batteryHealthCritical, tempHealthCritical } = getCriticalStatus(capabilityName, valueFloat);
          return batteryHealthCritical || tempHealthCritical ? (
            batteryHealthCritical ? (
              <p style={{ color: "#E52828" }}>{translations.pages.deviceManagement.deviceDetails.lowBattery}</p>
            ) : (
              <p style={{ color: "#E52828" }}>{capabilityName}</p>
            )
          ) : (
            <p>{capabilityName}</p>
          );
        },
        type: "string",
      },
      {
        field: "valueString",
        headerName: translations.globals.terms.value,
        flex: 2,
        renderCell: (params) => {
          const { capabilityName, valueFloat, valueString } = params.row;
          const { batteryHealthCritical, tempHealthCritical } = getCriticalStatus(capabilityName, valueFloat);
          const unitSymbol = getCapabilityUnitSymbol(capabilityName);
          return (
            <p style={{ color: batteryHealthCritical || tempHealthCritical ? "#E52828" : "#000" }}>
              {`${valueFloat ? `${valueFloat.toFixed(2)} ${unitSymbol}` : valueString}`}
            </p>
          );
        },
        type: "string",
      },
    ],
    []
  );

  return (
    <LeftTileWrapperSC>
      <DataGrid
        noDataMessage={translations.pages.deviceManagement.deviceDetails.noSensorMeasurements}
        tableTitle={translations.pages.deviceManagement.myDevices}
        tableHeight="45vh"
        columns={columns}
        rows={data}
        checkboxSelection={false}
        hasToolbar={false}
        loading={loading}
        withMargin={false}
      />
    </LeftTileWrapperSC>
  );
};

export default SensorMeasurements;
