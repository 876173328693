import { getGridStringOperators, GridRowParams } from "@mui/x-data-grid-pro";
import TagChips from "components/molecules/TagChips";
import { LeftTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import useDataGrid from "components/templates/dataGridTable";
import {
  GetAssetDevicesForAssetGroupsQuery,
  useGetAssetDevicesForAssetGroupsLazyQuery,
  ResultType,
} from "graphqlBase/AssetDevices/__generated__/getAssetDevicesForAssetGroups";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";

export const testIDs = getTestIDs();

export type AssetDevice = ResultType<GetAssetDevicesForAssetGroupsQuery["assetDevices"]>;

interface GroupMemberTableProps {
  queryBuilderResultIds?: string[];
}

const GroupQueryResultsTile: React.FC<GroupMemberTableProps> = ({ queryBuilderResultIds }) => {
  const history = useHistory();
  const variables = {
    where: { and: [{ deviceId: { in: queryBuilderResultIds }, asset: { assetMasterDataId: { neq: null } } }] },
  };

  const [query, { data, loading, error }] = useGetAssetDevicesForAssetGroupsLazyQuery({
    variables,
  });

  const { DataGrid, useMakeColumns, makeSeverSideFilter } = useDataGrid<AssetDevice, "AssetDevice">({
    query,
    variables,
    tableId: "AssetGroupQueryResultsTable",
    persistance: "runTime",
  });

  const columns = useMakeColumns(
    [
      {
        field: "generalItem",
        headerName: `Article name (${data?.assetDevices?.length ?? 0})`,
        flex: 3,
        valueGetter: (params) => {
          return params.row.asset?.assetMasterData?.generalItem ?? "";
        },
        remoteOrder: (sort) => ({ asset: { assetMasterData: { generalItem: sort } } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            asset: { assetMasterData: { generalItem: filterValue } },
          }),
        }),
        type: "string",
      },
      {
        field: "articleNumber",
        headerName: `Article`,
        flex: 2,
        valueGetter: (params) => {
          return params.row.asset?.assetMasterData?.articleNumber ?? "";
        },
        remoteOrder: (sort) => ({ asset: { assetMasterData: { articleNumber: sort } } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            asset: { assetMasterData: { articleNumber: filterValue } },
          }),
        }),
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 3,
        valueGetter: (params) => {
          return params.row.asset?.mappingAssetTag?.map((entry) => entry.assetTag?.tagValue ?? "") ?? null;
        },
        renderCell: (params) => {
          return <TagChips mappingAssetTag={params.row.asset?.mappingAssetTag} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators(),
          filterPath: ({ where, filterValue }) => ({
            asset: { mappingAssetTag: { some: { assetTag: { tagValue: filterValue } } } },
          }),
        }),
        type: "stringArray",
      },
    ],
    []
  );

  const handleOnRowClick = (params: GridRowParams) => {
    history.push(`/assetManagement/assetDetails/${params.row.asset.id}`);
  };

  return (
    <LeftTileWrapperSC>
      {!error && (
        <DataGrid
          noDataMessage={translations.pages.groups.query.noResultsQuery}
          tableTitle={translations.pages.assetManagement.myAssets}
          tableHeight="65vh"
          handleOnRowClick={handleOnRowClick}
          columns={columns}
          rows={data?.assetDevices}
          checkboxSelection={false}
          loading={loading}
          withMargin={false}
        />
      )}
    </LeftTileWrapperSC>
  );
};

export default GroupQueryResultsTile;
