import TextArea from "components/atomics/TextArea";
import * as React from "react";
import translations from "translations";
import Props from "./index.d";

const Textarea: React.FC<Props> = (props) => {
  const {
    title,
    testId,
    data: { state, setstate, onBlur, updateValue },
    isPartOfSummary,
    isItOptional,
    width,
  } = props;

  return (
    <>
      {props.disabled && isPartOfSummary ? (
        <div>
          <p>{translations.entities.device.columns.description}</p>
          <span>{state}</span>
        </div>
      ) : (
        <TextArea
          data-testid={testId}
          maxlength={1200}
          placeholder=""
          value={state}
          lhChange={updateValue}
          lhBlur={onBlur}
          readonly={props.disabled}
          isOptional={isItOptional}
          label={title}
          width={width}
        />
      )}
    </>
  );
};

export default Textarea;
