import RadioGroup from "components/molecules/RadioGroup";
import useGetNotificationIdFromRoute from "components/pages/AlertManagement/AlertEditing/hooks/useGetNotificationIdFromRoute";
import { useGetIsActiveByNotificationIdLazyQuery } from "graphqlBase/Notifications/__generated__/getIsActiveByNotificationId";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Props from "./index.d";
import { hasResubmissionAndActiveValuesAtom } from "components/pages/AlertManagement/AlertEditing/recoilState";

const ActiveRadioGroupForAlerts: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    title,
    horizontal,
    pickDefaultFirst,
  } = props;
  const notificationId = useGetNotificationIdFromRoute();
  const setIsValueUndefined = useSetRecoilState(hasResubmissionAndActiveValuesAtom);
  const [query, { data }] = useGetIsActiveByNotificationIdLazyQuery({
    variables: { id: notificationId ?? "" },
    fetchPolicy: "cache-and-network",
  });
  const [value, setValue] = useState<string | undefined>();

  const options = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  const handleChange = (item: string) => {
    setValue(item);
    item === "false" ? setAndSave(false) : setAndSave(true);
  };

  useEffect(() => {
    !value
      ? setIsValueUndefined((prevState) => ({ ...prevState, isActive: 0 }))
      : setIsValueUndefined((prevState) => ({ ...prevState, isActive: 1 }));
  }, [value]);

  useEffect(() => {
    if (!notificationId) return;
    query();
    setValue(data?.notification?.isActive.toString() ?? "");
  }, [data]);

  useEffect(() => {
    if (pickDefaultFirst && !value && options?.length) {
      setValue(options[0].value);
      setAndSave(Boolean(options[0].value));
    }
  }, [options, value, pickDefaultFirst]);

  return (
    <>
      {value && (
        <RadioGroup
          gap="140px"
          selected={value}
          horizontal={horizontal}
          onSelectItem={handleChange}
          title={title}
          items={options}
        />
      )}
    </>
  );
};

export default ActiveRadioGroupForAlerts;
