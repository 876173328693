import prepareServerSideTableIn, {
  ServerSideMakeTableConfig as SSMakeTableConfig,
  useCreateServerSideColumns as useCreateServerSideColumnsInner,
  Filterbuilder as SSFilterbuilder,
} from "./Table/useMakeServerSideTable";
import useMakeTableIn from "./Table/useMakeTable";
import useHideColumnsPre from "./Table/useHideColumns";
import tableQueryVariables from "./Table/tableQueryVariables";
export * from "./Table/useMakeTable";
export const currentQueryVariables = tableQueryVariables;
export const prepareServerSideTable = prepareServerSideTableIn;
export const useHideColumns = useHideColumnsPre;
export const useCreateServerSideColumns = useCreateServerSideColumnsInner;
export type ServerSideMakeTableConfig<T, F, O, S> = SSMakeTableConfig<T, F, O, S>;
export type FilterBuilder<T, F> = SSFilterbuilder<T, F>;
export default useMakeTableIn;
