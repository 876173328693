import { Box } from "@mui/material";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { configurationsStateFieldAtom, movementStateArrayAtom } from "../recoilState";
import StateButtons from "./StateButtons";
import TargetAndStorageSiteDefinition from "../TargetAndStorageSiteDefinition";
const siteStatesArray = ["STORAGE_SITE", "TARGET_SITE", "TRANSPORT"];
const DeviceConfigByStateHandler = () => {
  const configurationsStateField = useRecoilValue(configurationsStateFieldAtom);
  const movementStatesArray = useRecoilValue(movementStateArrayAtom);
  const isItTranport = configurationsStateField.includes("TRANSPORT");

  return (
    <>
      <StateButtons statesArray={siteStatesArray} statePart={0} />
      {!isItTranport && (
        <Box sx={{ margin: "42px 0 47px 0" }}>
          <TargetAndStorageSiteDefinition />
        </Box>
      )}
      <StateButtons statesArray={movementStatesArray} statePart={1} />
    </>
  );
};

export default DeviceConfigByStateHandler;
