import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Theme } from "@mui/material";
import Props from "./index.d";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      // backgroundColor: theme.palette.background.paper
    },
  })
);

interface GFCFieldSet_LayoutHorizontal extends Props {
  children: React.ReactNode;
}

const FieldSetLayoutHorizontal: React.FC<GFCFieldSet_LayoutHorizontal> = (props) => {
  const { children } = props;
  const classes = useStyles();
  // @ts-ignore
  if (props.hidden) return null;
  return <div className={classes.root}>{children}</div>;
};
export default FieldSetLayoutHorizontal;
