import { alertFormLoadingAtom, isFormSubmittedAtom } from "components/pages/AlertManagement/AlertEditing/recoilState";
import PLButton from "components/patternLib/PLButton";
import * as React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Props from "./index.d";
import {
  hasFormErrorsAtom,
  isTheFormValidSelector,
} from "components/pages/AlertManagement/AlertEditing/recoilState/formValidationState";

const SubmitButtonForAlertManagement: React.FC<Props> = (props) => {
  const { testId } = props;
  const [formLoading, setFormLoading] = useRecoilState(alertFormLoadingAtom);

  const { hasAlertName,validDefinitionCount, resubmissionValue, isActive, deviceCountFromAssets,deviceCountFromDevices, userCount } = useRecoilValue(
    isTheFormValidSelector
  );
  
  const { setAndSave } = props.data;

  const setHasFormErrors = useSetRecoilState(hasFormErrorsAtom);
  const setIsFormSubmitted = useSetRecoilState(isFormSubmittedAtom);
const formHasErrors = !(validDefinitionCount * resubmissionValue * isActive * ((deviceCountFromAssets || deviceCountFromDevices) ?? 0)  *(userCount ?? 0) * hasAlertName)

const handleSubmit = () => {
    if (formHasErrors) {
      setHasFormErrors(
       true
     );
      return
    }
    setHasFormErrors(false)
    setFormLoading(true);
    setIsFormSubmitted(true);
    setAndSave(true);
  };

  return (
    <PLButton
      data-testid={testId}
      label={props.title}
      type="primary"
      onClick={handleSubmit}
      width="auto"
    />
  );
};

export default SubmitButtonForAlertManagement;
