export default {
  save: "Save",
  saveChanges: "Save changes",
  create: "Create",
  add: "Add",
  new: "New",
  assign: "Assign",
  change: "Change",
  next: "Next",
  back: "Back",
  continue: "Continue",
  confirm: "Confirm",
  submit: "Submit",
  send: "Send",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  OK: "OK",
  done: "Done",
  reserve: "Reserve",
  reset: "Reset",
  settings: "Settings",
  delete: "Delete",
  run: "Run",
  unselect: "Unselect",
  edit: "Edit",
  seeMore: "See More",
  unpair: "Unpair",
};
