import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceIdsOnTagValueQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetDeviceIdsOnTagValueQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, deviceId: string, asset: { __typename?: 'Asset', id: string, mappingAssetTag: Array<{ __typename?: 'MappingAssetTag', id: string, assetTag: { __typename?: 'AssetTag', id: string, tagValue: string } }> } }> };


export const GetDeviceIdsOnTagValueDocument = gql`
    query getDeviceIdsOnTagValue($where: AssetDeviceFilterInput) {
  assetDevices(where: $where) {
    id
    deviceId
    asset {
      id
      mappingAssetTag {
        id
        assetTag {
          id
          tagValue
        }
      }
    }
  }
}
    `;

/**
 * __useGetDeviceIdsOnTagValueQuery__
 *
 * To run a query within a React component, call `useGetDeviceIdsOnTagValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceIdsOnTagValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceIdsOnTagValueQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDeviceIdsOnTagValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceIdsOnTagValueQuery, GetDeviceIdsOnTagValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceIdsOnTagValueQuery, GetDeviceIdsOnTagValueQueryVariables>(GetDeviceIdsOnTagValueDocument, options);
      }
export function useGetDeviceIdsOnTagValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceIdsOnTagValueQuery, GetDeviceIdsOnTagValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceIdsOnTagValueQuery, GetDeviceIdsOnTagValueQueryVariables>(GetDeviceIdsOnTagValueDocument, options);
        }
export type GetDeviceIdsOnTagValueQueryHookResult = ReturnType<typeof useGetDeviceIdsOnTagValueQuery>;
export type GetDeviceIdsOnTagValueLazyQueryHookResult = ReturnType<typeof useGetDeviceIdsOnTagValueLazyQuery>;
export type GetDeviceIdsOnTagValueQueryResult = Apollo.QueryResult<GetDeviceIdsOnTagValueQuery, GetDeviceIdsOnTagValueQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
