import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceForDeviceDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  capabilityIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type GetDeviceForDeviceDetailsQuery = { device?: Maybe<{ __typename?: 'Device', id: string, serialNo: string, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string }, sensorMeasurementCurrentValue?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, valueFloat?: Maybe<number>, valueInt?: Maybe<number>, capability?: Maybe<{ __typename?: 'Capability', id: string, capabilityName: string }> }>>>, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareVersion: string } }>, mappingDeviceTag: Array<{ __typename?: 'MappingDeviceTag', id: string, tag: { __typename?: 'Tag', id: string, tagValue: string } }>, assetDevice?: Maybe<Array<Maybe<{ __typename?: 'AssetDevice', id: string, dateTo: string, asset: { __typename?: 'Asset', id: string, assetSource?: Maybe<{ __typename?: 'AssetSource', id: string, assetSourceName: string }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string> }> } }>>>, inverseParentDevice: Array<{ __typename?: 'Device', id: string, serialNo: string, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string }, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareVersion: string } }> }> }> };


export const GetDeviceForDeviceDetailsDocument = gql`
    query getDeviceForDeviceDetails($id: UUID!, $capabilityIds: [UUID!]) {
  device(id: $id) {
    id
    deviceType {
      id
      deviceTypeName
    }
    sensorMeasurementCurrentValue(where: {capabilityId: {in: $capabilityIds}}) {
      id
      valueFloat
      valueInt
      capability {
        id
        capabilityName
      }
    }
    mappingDeviceFirmwareDevice {
      id
      deviceFirmware {
        id
        firmwareVersion
      }
    }
    serialNo
    mappingDeviceTag {
      id
      tag {
        id
        tagValue
      }
    }
    assetDevice {
      id
      dateTo
      asset {
        id
        assetSource {
          id
          assetSourceName
        }
        assetMasterData {
          id
          generalItem
        }
      }
    }
    inverseParentDevice(order: {deviceType: {deviceTypeName: DESC}}) {
      id
      serialNo
      deviceType {
        id
        deviceTypeName
      }
      mappingDeviceFirmwareDevice {
        id
        deviceFirmware {
          id
          firmwareVersion
        }
      }
    }
  }
}
    `;

/**
 * __useGetDeviceForDeviceDetailsQuery__
 *
 * To run a query within a React component, call `useGetDeviceForDeviceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceForDeviceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceForDeviceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      capabilityIds: // value for 'capabilityIds'
 *   },
 * });
 */
export function useGetDeviceForDeviceDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDeviceForDeviceDetailsQuery, GetDeviceForDeviceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceForDeviceDetailsQuery, GetDeviceForDeviceDetailsQueryVariables>(GetDeviceForDeviceDetailsDocument, options);
      }
export function useGetDeviceForDeviceDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceForDeviceDetailsQuery, GetDeviceForDeviceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceForDeviceDetailsQuery, GetDeviceForDeviceDetailsQueryVariables>(GetDeviceForDeviceDetailsDocument, options);
        }
export type GetDeviceForDeviceDetailsQueryHookResult = ReturnType<typeof useGetDeviceForDeviceDetailsQuery>;
export type GetDeviceForDeviceDetailsLazyQueryHookResult = ReturnType<typeof useGetDeviceForDeviceDetailsLazyQuery>;
export type GetDeviceForDeviceDetailsQueryResult = Apollo.QueryResult<GetDeviceForDeviceDetailsQuery, GetDeviceForDeviceDetailsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
