import { SelectedDevice } from "components/formElements/fields/MappingDeviceNotifications/MappingDeviceNotificationsTable";
import { atom } from "recoil";
import { MappingDeviceNotification } from "./types";

export const preMappedDeviceNotificationsForGateWayAtom = atom<MappingDeviceNotification[] | undefined>({
  key: "preMappedDeviceNotificationsForGateWayAtom",
  default: undefined,
});

export const selectedDevicesForAlertAtom = atom<SelectedDevice[]>({
  key: "selectedDevicesForAlertAtom",
  default: [],
});
