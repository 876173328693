import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLDropzoneProps = DropOnONLh<JSX.PatternlibDropzone>;
export type PLDropzoneExtendedProps = PLDropzoneProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedDropzone = wrapWc<JSX.PatternlibDropzone>("patternlib-dropzone");
export const useRefPLDropzone = () => useRef<HTMLPatternlibDropzoneElement>(null);

const PLDropzone = React.forwardRef<HTMLPatternlibDropzoneElement, PLDropzoneExtendedProps>((props, ref) => {
  const myRef = useRefPLDropzone();
  return (
    <WrappedDropzone ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedDropzone>
  );
});
PLDropzone.displayName = "PLDropzone";

export default PLDropzone;
