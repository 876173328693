import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Location, Action } from "history";
const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  return (event.returnValue = "MLPA");
};

export type UseBlockHistoryCb = (args: {
  action: Action;
  continueAfterBlock: () => void;
  location: Location;
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
}) => string | false | void;

const useBlockHistory = () => {
  const history = useHistory();
  const [callBack, setCallBack] = useState<UseBlockHistoryCb | undefined>();
  const [continueAfterBlock, setContinueAfterBlock] = useState<() => void>(() => {});
  const [preventPageClose, setPreventPageClose] = useState(false);

  useEffect(() => {
    if (preventPageClose) {
      window.addEventListener("beforeunload", beforeUnloadListener);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, [preventPageClose]);
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (callBack) {
        const continueFn = () => {
          unblock();
          history.push(location.pathname);
        };
        setContinueAfterBlock(() => continueFn);
        return callBack({ location, action, continueAfterBlock: continueFn });
      }
    });

    return () => {
      unblock();
    };
  }, [callBack, history]);
  return { setBlockCallBack: setCallBack, continueAfterBlock, setPreventPageClose };
};
export default useBlockHistory;
