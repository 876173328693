import colors from "./colors";
import { muiIconButtonOverrides } from "themes/Button/IconButton";
import { muiAvatarOverrides } from "./Avatar";
import { muiBadgeOverrides } from "./Badge";
import { muiToggleButtonOverrides } from "./ToggleButton";
import { muiCardOverrides } from "./Card";
import {
  muiStepperOverrides,
  muiStepConnectorOverrides,
  muiStepOverrides,
  muiStepButtonOverrides,
  muiStepContentOverrides,
  muiStepLabelOverrides,
} from "./Stepper";
import { muiCheckboxOverrides } from "./Checkbox";
import { muiSelectOverrides } from "./Select";
import { muiInputBaseOverrides } from "themes/Input/InputBase";
import { muiListOverrides } from "./List";
import { muiButtonBaseOverrides } from "themes/Button/ButtonBase";
import { muiRadioButtonOverrides } from "./RadioButton";
import { muiTextFieldOverrides } from "./TextArea";
import { muiTabsOverrides } from "./Tabs";
import { muiChipOverrides } from "./Chip";
import { createTheme, Theme } from "@mui/material/styles";

const overrides: Theme = createTheme({
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: {
          backgroundColor: colors.tWhite,
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        sx: {
          ...muiAvatarOverrides?.colorDefault,
          ...muiAvatarOverrides?.root,
        },
      },
    },

    MuiBadge: {
      defaultProps: {
        sx: {
          ...muiBadgeOverrides?.root,
        },
      },
    },
    MuiIconButton: {
      // styleOverrides: { ...muiIconButtonOverrides },
      defaultProps: {
        sx: {
          ...muiIconButtonOverrides?.root,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: { ...muiButtonBaseOverrides?.root },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        sx: {
          // ...muiCheckboxOverrides?.checked,
          ...muiCheckboxOverrides?.root,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        sx: {
          ...muiChipOverrides?.deletable,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        sx: {
          ...muiCardOverrides?.root,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          ...muiInputBaseOverrides?.root,
        },
      },
    },

    MuiList: {
      defaultProps: {
        sx: {
          ...muiListOverrides?.root,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          ...muiRadioButtonOverrides?.disabled,
          ...muiRadioButtonOverrides?.root,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          ...muiTextFieldOverrides?.root,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        sx: {
          ...muiToggleButtonOverrides?.label,
          ...muiToggleButtonOverrides?.root,
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        sx: {
          ...muiSelectOverrides?.select,
          // ...muiSelectOverrides?.selectMenu,
          ...muiSelectOverrides?.nativeInput,
          ...muiSelectOverrides?.root,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        sx: {
          ...muiTabsOverrides?.root,
        },
      },
    },
    MuiStepper: {
      defaultProps: {
        sx: {
          ...muiStepperOverrides?.vertical,
          ...muiStepperOverrides?.horizontal,
          ...muiStepperOverrides?.root,
        },
      },
    },
    MuiStep: {
      defaultProps: {
        sx: {
          ...muiStepOverrides?.vertical,
          ...muiStepOverrides?.horizontal,
          ...muiStepOverrides?.root,
        },
      },
    },
    MuiStepButton: {
      defaultProps: {
        sx: {
          ...muiStepButtonOverrides?.vertical,
          ...muiStepButtonOverrides?.root,
        },
      },
    },
    MuiStepContent: {
      defaultProps: {
        sx: {
          ...muiStepContentOverrides?.root,
        },
      },
    },
    MuiStepLabel: {
      defaultProps: {
        sx: {
          ...muiStepLabelOverrides?.labelContainer,
          ...muiStepLabelOverrides?.iconContainer,
          ...muiStepLabelOverrides?.label,
          ...muiStepLabelOverrides?.vertical,
          ...muiStepLabelOverrides?.root,
        },
      },
    },

    MuiTab: {
      defaultProps: {
        sx: {
          textTransform: "none",
          margin: "0 16px",
          fontSize: "1.6em",
          minWidth: 0,
          padding: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          color: colors.tBlack,
        },
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiTooltip: {
      defaultProps: {
        sx: {
          borderRadius: 4,
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        sx: {
          borderRadius: "2px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: colors.brandYellow,
        },
      },
    },
    MuiListItemIcon: {
      defaultProps: {
        sx: {
          color: colors.grey600,
          marginRight: 0,
          "& svg": {
            fontSize: "24px",
          },
        },
      },
    },
    MuiDivider: {},
    MuiListItemText: {
      defaultProps: {
        sx: {
          // fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },

    MuiTableCell: {
      defaultProps: {
        sx: {
          fontWeight: 700,
        },
      },
    },
  },
});

export default overrides;
