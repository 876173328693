import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetMappingRoleScopesForRoleQueryVariables = Types.Exact<{
  roleId: Types.Scalars['UUID'];
}>;


export type GetMappingRoleScopesForRoleQuery = { mappingRoleScopes: Array<{ __typename?: 'MappingRoleScope', id: string, roleId: string, scopeId: string, isDeleted?: Maybe<boolean> }> };


export const GetMappingRoleScopesForRoleDocument = gql`
    query getMappingRoleScopesForRole($roleId: UUID!) {
  mappingRoleScopes(where: {roleId: {eq: $roleId}}) {
    id
    roleId
    scopeId
    isDeleted
  }
}
    `;

/**
 * __useGetMappingRoleScopesForRoleQuery__
 *
 * To run a query within a React component, call `useGetMappingRoleScopesForRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappingRoleScopesForRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappingRoleScopesForRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useGetMappingRoleScopesForRoleQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetMappingRoleScopesForRoleQuery, GetMappingRoleScopesForRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMappingRoleScopesForRoleQuery, GetMappingRoleScopesForRoleQueryVariables>(GetMappingRoleScopesForRoleDocument, options);
      }
export function useGetMappingRoleScopesForRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMappingRoleScopesForRoleQuery, GetMappingRoleScopesForRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMappingRoleScopesForRoleQuery, GetMappingRoleScopesForRoleQueryVariables>(GetMappingRoleScopesForRoleDocument, options);
        }
export type GetMappingRoleScopesForRoleQueryHookResult = ReturnType<typeof useGetMappingRoleScopesForRoleQuery>;
export type GetMappingRoleScopesForRoleLazyQueryHookResult = ReturnType<typeof useGetMappingRoleScopesForRoleLazyQuery>;
export type GetMappingRoleScopesForRoleQueryResult = Apollo.QueryResult<GetMappingRoleScopesForRoleQuery, GetMappingRoleScopesForRoleQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
