import { DeletableEntity } from "graphqlBase/genericDelete";
import { atom } from "recoil";

export const genericDeleteParams = atom<{
  id: string | string[] | undefined;
  entity: DeletableEntity | undefined;
  afterDelete?: (data: any) => void;
  modalTitle: string | undefined;
  modalMessage?: string | undefined;
}>({
  key: "genericDeleteParams",
  default: { id: undefined, entity: undefined, afterDelete: undefined, modalTitle: undefined },
});

export const currentOpenPopper = atom<string>({
  key: "currentOpenPopper",
  default: "",
});
