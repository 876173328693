import React from "react";
import PLRadioButton, { PLRadiobuttonProps } from "components/patternLib/form/PLRadiobutton";
import PLRadioButtonItem from "components/patternLib/form/PLRadiobuttonItem";
import { LhChange } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { Typography, styled, Box } from "@mui/material";

export const RadioGroupContainerSC = styled("div")(
  () => `
   display: flex;
    flex-direction: column;
     width: 100%;
`
);

interface RadioGroupProps extends PLRadiobuttonProps {
  items: Array<{ label: string; value: string }>;
  onSelectItem?: (item: string) => void;
  selected: string;
  title?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  items,
  name,
  gap,
  horizontal,
  width,
  itemsMinWidth,
  onSelectItem,
  title,
  selected,
}) => {
  const handleSelection = (event: CustomEvent<LhChange>) => {
    onSelectItem?.(event?.detail?.value as string);
  };

  return (
    <RadioGroupContainerSC>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography variant="copyBold">{title}</Typography>
      </Box>
      <PLRadioButton
        lhChange={handleSelection}
        horizontal={horizontal}
        name={name}
        width={width}
        itemsMinWidth={itemsMinWidth}
        gap={gap ?? "10%"}
        value={selected}
      >
        {items.map((item) => (
          <PLRadioButtonItem checked={selected === item.value} key={item.value} value={item.value} label={item.label} />
        ))}
      </PLRadioButton>
    </RadioGroupContainerSC>
  );
};

export default RadioGroup;
