import React from "react";
import { useRecoilValue } from "recoil";
import { deviceConfigurationAtom, deviceConfigurationSchema } from "./recoilState";
import ConfigurationEditor from "./ConfigurationEditor";
import { LeftTileWrapperSC } from "../utils/styles";
import { Typography, useTheme } from "@mui/material";

import translations from "translations";
import { ConfigurationProps } from "./index.d";
import PreviousConfigurations from "./PreviousConfigurations";
import de from "translations/entities/asset/de";

const Configuration: React.FC<ConfigurationProps> = ({ selectedDeviceId, deviceTypeName }) => {
  const currentDeviceConfiguration = useRecoilValue(deviceConfigurationAtom);
  const currentDeviceConfigurationSchema = useRecoilValue(deviceConfigurationSchema);
  const theme = useTheme();
  return (
    <>
      <LeftTileWrapperSC>
        <Typography variant="h4">
          {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.label}
        </Typography>
        {currentDeviceConfiguration && currentDeviceConfigurationSchema && (
          <ConfigurationEditor
            deviceId={selectedDeviceId ?? ""}
            deviceTypeName={deviceTypeName}
            jsonSchema={JSON.parse(currentDeviceConfigurationSchema)}
          />
        )}
      </LeftTileWrapperSC>
      <LeftTileWrapperSC>
        <Typography variant="h4" sx={{ marginBottom: theme.spacing(6) }}>
          {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.previousConfig}
        </Typography>
        <PreviousConfigurations selectedDeviceId={selectedDeviceId} />
      </LeftTileWrapperSC>
    </>
  );
};

export default Configuration;
