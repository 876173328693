export default {
  name: "asset type attribut set",
  plural: "asset type attribut sets",
  columns: {
    assetType: "Asset Type",
    assetTypeAttributeSetName: "asset type attribute Set name",
    assetTypeId: "asset type ID",
    id: "ID",
    isDeleted: "is deleted",
    mappingAttributeAssetTypeAttributeSet: "mapping attribute asset type attribute Set",
    tenantId: "tenant ID",
  },
};
