import { Select as MuiSelect, Autocomplete, SelectChangeEvent } from "@mui/material";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEv } from "components/lbc-toolkit/molecules/Select";
import sub from "date-fns/sub";
import SearchIcon from "icons/components/SearchIcon";
import React from "react";
import { FilterProps, Row } from "react-table";
import translations from "translations";
import { getSelectBooleanOptions, getSelectDateOptions, useGetRandomId } from "../utils/utils";
import { TypedColumnFilter } from "./useMakeServerSideTable";
import { makeSeverSideFilterPre } from "components/templates/table-factory/Table/useMakeServerSideTable";
import MultiSelect from "components/lbc-toolkit/molecules/MultiSelect";
import { useGetTagsQuery } from "graphqlBase/Tag/__generated__/getTags";
import getTestIDs from "lib/utils/getTestIDs";
import colors from "themes/colors";
import { useGetAssetTagsQuery } from "graphqlBase/MappingAssetTag/__generated__/getAssetTags";

export const testIDs = getTestIDs();
const TODAY_DATE = new Date();

export const DefaultColumnFilter = ({ column: { filterValue, setFilter, filter } }: FilterProps<object>) => {
  const [currentValue, setCurrentValue] = React.useState(filterValue);

  React.useEffect(() => {
    setCurrentValue(filterValue);
  }, [filterValue]);

  React.useEffect(() => {
    if (!currentValue) {
      return setFilter(currentValue);
    }
    const timer = setTimeout(() => {
      setFilter(currentValue);
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentValue(e.target.value || undefined);
  };

  return (
    <Input
      value={currentValue || ""}
      data-testid-filterType={testIDs.DefaultColumnFilter}
      onChange={handleChange}
      style={{ width: "100%" }}
      placeholder={translations.globals.table.search}
      inputProps={{ style: { color: "black", fontSize: "12px" } }}
      endAdornment={
        <InputAdornment style={{ pointerEvents: "none" }} position="end">
          {/* <SearchIcon fontSize="large" /> */}
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export const NumericFilter = ({
  column: { filterValue, setFilter },
}: {
  column: {
    filterValue: number | undefined | null;
    setFilter: (val: number | undefined | null) => void;
  };
}) => {
  const [currentValue, setCurrentValue] = React.useState(filterValue);

  React.useEffect(() => {
    setCurrentValue(filterValue);
  }, [filterValue]);

  React.useEffect(() => {
    if (!currentValue) {
      return setFilter(currentValue);
    }
    const timer = setTimeout(() => {
      setFilter(currentValue);
    }, 500);
    return () => clearTimeout(timer);
  }, [currentValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentValue(+e.target.value || undefined);
  };

  return (
    <Input
      value={`${currentValue ?? ""}`}
      data-testid-filterType={testIDs.NumericFilter}
      onChange={handleChange}
      style={{ width: "100%" }}
      placeholder={`Search ${""}...`}
      endAdornment={
        <InputAdornment style={{ pointerEvents: "none" }} position="end">
          <SearchIcon fontSize="large" />
        </InputAdornment>
      }
    />
  );
};

interface SelectItem {
  label: string;
  value: string;
}

function preFilteredRowsToSelectOptions(preFilteredRows: Array<Row<object>>, id: string): SelectItem[] {
  const resultSet = new Set<string>();
  preFilteredRows.forEach((row) => {
    const value = row.values[id];
    if (value !== undefined) resultSet.add(value);
  });
  return Array.from(resultSet)
    .sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .map((el) => ({ label: el, value: el }));
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: FilterProps<object>) {
  const options = React.useMemo(() => preFilteredRowsToSelectOptions(preFilteredRows, id), [id, preFilteredRows]);

  const componentId = useGetRandomId();

  return (
    <MuiSelect
      value={filterValue ?? ""}
      data-testid-filterType={testIDs.SelectColumnFilter}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={{ width: "100%" }}
      displayEmpty
    >
      <MenuItem value="" disabled={filterValue == null}>
        {filterValue == null ? <span style={{ color: "#959FC5" }}>Select ...</span> : <em>All</em>}
      </MenuItem>

      {options.map((option, i) => (
        <MenuItem key={`${componentId}-${i}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

// see https://material-ui.com/components/autocomplete/#combo-box
const useComboBoxFilterStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      input: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: "2px 1rem",
        },
      },
      popper: {
        color: "black",
      },
    }),
  { name: `ComboBoxFilter` }
);
export function ComboBoxFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: FilterProps<object>) {
  const classes = useComboBoxFilterStyles();
  const options = React.useMemo(
    () => [{ label: "All", value: "" }, ...preFilteredRowsToSelectOptions(preFilteredRows, id)],
    [id, preFilteredRows]
  );

  const componentId = useGetRandomId();
  return (
    <Autocomplete
      id={componentId}
      data-testid-filterType={testIDs.ComboBoxFilter}
      classes={{
        inputRoot: classes.input,
        listbox: classes.popper,
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      className={classes.root}
      value={filterValue}
      onChange={(event, value) => {
        setFilter(value?.value || undefined);
      }}
      renderInput={(params) => <TextField placeholder="Search ..." {...params} />}
    />
  );
}

export function SelectDateFilter({ column: { filterValue, setFilter } }: TypedColumnFilter<any, string>) {
  const [currentValue, setCurrentValue] = React.useState(filterValue ?? "0");
  const componentId = useGetRandomId();

  const options = React.useMemo(() => getSelectDateOptions(), []);

  React.useEffect(() => {
    const numValue: number = Number(currentValue);
    if (currentValue && numValue > 0) setFilter(sub(TODAY_DATE, { hours: numValue }).toISOString());
    else setFilter(undefined);
  }, [currentValue]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    setCurrentValue(value ?? undefined);
  };

  return (
    <Select
      id={componentId}
      data-testid-filterType={testIDs.SelectDateFilter}
      name={`DateTimeSelectFilter-${componentId}`}
      options={options}
      selected={currentValue}
      onChange={handleChange}
      paperMargin={2.5}
      testId={testIDs.dateTimeSelect}
      style={{
        height: "2em",
        fontSize: 13,
        width: 140,
        backgroundColor: colors.tWhite,
        borderColor: colors.grey400,
      }}
    />
  );
}

export function SelectBooleanFilter({ column: { filterValue, setFilter } }: TypedColumnFilter<any, string>) {
  const [currentValue, setCurrentValue] = React.useState(filterValue ?? undefined);
  const componentId = useGetRandomId();
  const options = React.useMemo(() => getSelectBooleanOptions(), []);

  React.useEffect(() => {
    if (currentValue && currentValue?.length > 0) setFilter(currentValue);
    else setFilter(undefined);
  }, [currentValue]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setCurrentValue((event?.target?.value as string) ?? undefined);
  };

  return (
    <div style={{ marginRight: -10 }}>
      <Select
        id={componentId}
        data-testid-filterType={testIDs.SelectBooleanFilter}
        name={`BooleanSelectFilter-${componentId}`}
        options={options}
        selected={currentValue}
        onChange={handleChange}
        iconSize="1.5em"
        testId={testIDs.booleanFilterSelect}
        paperMargin={2.5}
        style={{
          height: "2em",
          fontSize: 14,
          width: 140,
          backgroundColor: colors.tWhite,
          borderColor: colors.grey400,
        }}
      />
    </div>
  );
}
export const NoFilter = <div data-testid={testIDs.TableFiltersNoFilter} style={{ height: 25 }} />;

export function MultiSelectFilterTags({ column: { filterValue, setFilter } }: TypedColumnFilter<any, string[]>) {
  const [options, setOptions] = React.useState<SelectItem[]>([]);
  const { data } = useGetTagsQuery();
  React.useEffect(() => {
    if (!data) return;
    const tags: SelectItem[] = data.tags.map(({ tagValue, id }) => {
      return { label: tagValue, value: id };
    });
    setOptions(tags);
  }, [data]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    let value: string[] | undefined = event.target.value as string[];
    if (value.length < 1) value = undefined;
    setFilter(value);
  };
  const handleDelete = (toDelete: string) => {
    let currFilterValue: string[] | undefined = (filterValue ?? []).filter((val) => val !== toDelete);
    if (currFilterValue.length < 1) currFilterValue = undefined;
    setFilter(currFilterValue);
  };

  const handleDeleteAll = () => {
    setFilter(undefined);
  };

  return (
    <MultiSelect
      data-testid="select-tags-filter"
      data-testid-filterType={testIDs.MultiSelectFilterTags}
      handleDeleteAll={handleDeleteAll}
      handleDelete={handleDelete}
      id="select-tags-filter"
      onChange={handleChange}
      selected={filterValue ?? undefined}
      options={options.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
      paperMargin={1}
      testId={testIDs.tagsMultiselect}
      style={{
        height: "2em",
        fontSize: 14,
        width: 140,
        backgroundColor: colors.tWhite,
        borderColor: colors.grey400,
      }}
    />
  );
}

export function MultiSelectFilterAssetTags({ column: { filterValue, setFilter } }: TypedColumnFilter<any, string[]>) {
  const [options, setOptions] = React.useState<SelectItem[]>([]);
  const { data } = useGetAssetTagsQuery();
  React.useEffect(() => {
    if (!data) return;
    const tags: SelectItem[] = data.assetTags.map(({ tagValue, id }) => {
      return { label: tagValue, value: id };
    });
    setOptions(tags);
  }, [data]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    let value: string[] | undefined = event.target.value as string[];
    if (value.length < 1) value = undefined;
    setFilter(value);
  };
  const handleDelete = (toDelete: string) => {
    let currFilterValue: string[] | undefined = (filterValue ?? []).filter((val) => val !== toDelete);
    if (currFilterValue.length < 1) currFilterValue = undefined;
    setFilter(currFilterValue);
  };

  const handleDeleteAll = () => {
    setFilter(undefined);
  };

  return (
    <MultiSelect
      data-testid="select-tags-filter"
      data-testid-filterType={testIDs.MultiSelectFilterTags}
      handleDeleteAll={handleDeleteAll}
      handleDelete={handleDelete}
      id="select-tags-filter"
      onChange={handleChange}
      selected={filterValue ?? undefined}
      options={options.sort((a, b) => a.label.localeCompare(b.label)) ?? []}
      paperMargin={1}
      testId={testIDs.tagsMultiselect}
      style={{
        height: "2em",
        fontSize: 14,
        width: 140,
        backgroundColor: colors.tWhite,
        borderColor: colors.grey400,
      }}
    />
  );
}

export const CheckboxFilter = ({ column: { filterValue, setFilter } }: TypedColumnFilter<any, string>) => {
  const [currentValue, setCurrentValue] = React.useState(filterValue ?? "not paired");

  const componentId = useGetRandomId();

  const options = [
    { value: "all", label: translations.globals.terms.all },
    { value: "paired", label: translations.globals.terms.paired },
    { value: "not paired", label: translations.globals.terms.notPaired },
  ];
  React.useEffect(() => {
    if (currentValue && currentValue?.length > 0) setFilter(currentValue);
    else setFilter(undefined);
  }, [currentValue]);

  const handleChange = (event: SelectChangeEv) => {
    setCurrentValue((event?.target?.value as string) ?? undefined);
  };

  return (
    <div style={{ marginRight: -10 }}>
      <Select
        id={componentId}
        name={`CheckBoxFilter-${componentId}`}
        options={options}
        selected={currentValue}
        onChange={handleChange}
        iconSize="1.5em"
        testId={testIDs.checkBoxFilter}
        paperMargin={2.5}
        style={{
          height: "2em",
          fontSize: 14,
          width: 140,
          backgroundColor: colors.tWhite,
          borderColor: colors.grey400,
        }}
      />
    </div>
  );
};
