import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { StyleProps } from "../Select/utils";

export const useOtherStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      alignContent: "center",
      marginRight: "15px",
      width: "100%",
      height: "min-content",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "center",
    },
  })
);
