import { Box, Typography } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import TagChips from "components/molecules/TagChips";
import { LeftTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import useDataGrid from "components/templates/dataGridTable";
import {
  GetAssociatedAssetDevicesForAssetQuery,
  ResultType,
  useGetAssociatedAssetDevicesForAssetLazyQuery,
} from "graphqlBase/AssetDevices/__generated__/getAssociatedAssetDevicesForAsset";
import { MappingAssetTag } from "graphqlBase/types";
import React from "react";
import translations from "translations";

export type AssetDevice = ResultType<GetAssociatedAssetDevicesForAssetQuery["assetDevices"]>;

const AssociatedAssetsTile: React.FC<{ assetId: string; sellingUnitSerialNumber: string | undefined }> = ({
  sellingUnitSerialNumber,
  assetId,
}) => {
  const [query, { data, loading, error }] = useGetAssociatedAssetDevicesForAssetLazyQuery();

  const { DataGrid, useMakeColumns, makeSeverSideFilter } = useDataGrid<AssetDevice, "AssetDevice">({
    query,
    variables: {
      where: {
        and: [
          { asset: { assetMasterData: { sellingUnitSerialNumber: { eq: sellingUnitSerialNumber ?? "" } } } },
          { assetId: { neq: assetId } },
        ],
      },
    },
    tableId: "AssetDetailAssociatedAssets",
    persistance: "runTime",
  });

  const columns = useMakeColumns(
    [
      {
        field: "articleName",
        headerName: translations.entities.assetMasterData.columns.itemName,
        flex: 1,
        valueGetter: (params) => {
          return params?.row?.asset?.assetMasterData?.generalItem ?? "";
        },
        remoteOrder: (sort) => ({ asset: { assetMasterData: { generalItem: sort } } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            asset: { assetMasterData: { generalItem: filterValue } },
          }),
        }),
        type: "string",
      },
      {
        field: "articleNumber",
        headerName: translations.entities.assetMasterData.columns.articleNumber,
        flex: 1,
        valueGetter: (params) => {
          return params?.row?.asset?.assetMasterData?.articleNumber ?? "";
        },
        remoteOrder: (sort) => ({ asset: { assetMasterData: { articleNumber: sort } } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            asset: { assetMasterData: { articleNumber: filterValue } },
          }),
        }),
        type: "string",
      },
      {
        field: "serialNumber",
        headerName: translations.entities.assetMasterData.columns.assetSerialNo,
        flex: 1,
        valueGetter: (params) => {
          return params?.row?.asset?.assetMasterData?.serialNumber ?? "";
        },
        remoteOrder: (sort) => ({ asset: { assetMasterData: { serialNumber: sort } } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            asset: { assetMasterData: { serialNumber: filterValue } },
          }),
        }),
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 2,
        valueGetter: (params) => {
          return params?.row?.asset?.mappingAssetTag?.map((entry) => entry.assetTag?.tagValue ?? "") ?? null;
        },
        renderCell: (params) => {
          return <TagChips mappingAssetTag={params.row.asset.mappingAssetTag as MappingAssetTag[]} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators(),
          filterPath: ({ where, filterValue }) => ({
            asset: { mappingAssetTag: { some: { assetTag: { tagValue: filterValue } } } },
          }),
        }),
        type: "stringArray",
      },
    ],
    []
  );

  const { label, noDataMessage } = translations.pages.assetManagement.assetDetails.associatedAssetsTile;

  return (
    <LeftTileWrapperSC>
      <Box sx={{ marginBottom: "25px" }}>
        <Typography variant="h4">{label}</Typography>
      </Box>
      <DataGrid
        hasToolbar={false}
        checkboxSelection={false}
        tableTitle=""
        rows={data?.assetDevices ?? []}
        tableHeight="30vh"
        columns={columns}
        loading={loading}
        noDataMessage={noDataMessage}
        withMargin={false}
      />
    </LeftTileWrapperSC>
  );
};
export default AssociatedAssetsTile;
