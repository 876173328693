import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import translations from "translations";
import TextField from "@mui/material/TextField";
import { editorData, initialEditorData, isButtonDisabled } from "../../RecoilState/recoilState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { GetRoleByIdQuery } from "graphqlBase/Settings/__generated__/getRoleById";

const useStyles = makeStyles({
  roleEditor: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  roleName: {
    marginBottom: "15px",
  },
  roleDescription: {
    marginTop: "30px",
    marginBottom: "15px",
  },
  roleDescriptionInput: {
    width: "100%",
    marginBottom: "33px",
  },
});
interface RoleEditorProps {
  selectedRole: GetRoleByIdQuery | undefined;
}

const RoleEditor: React.FC<RoleEditorProps> = ({ selectedRole }) => {
  const classes = useStyles();
  const initialRoleData = useRecoilValue<{ description: string; id: string; name: string }>(initialEditorData);
  const [editedRoleData, setEditedRoleData] = useRecoilState<{ description: string; id: string; name: string }>(
    editorData
  );
  const setIsDisabled = useSetRecoilState(isButtonDisabled);
  useEffect(() => {
    setEditedRoleData({
      id: initialRoleData.id,
      description: selectedRole?.role?.description ?? "",
      name: selectedRole?.role?.name ?? "",
    });
  }, [initialRoleData.id, selectedRole?.role?.description, selectedRole?.role?.name, setEditedRoleData]);

  if (
    selectedRole?.role?.name !== editedRoleData.name ||
    selectedRole?.role?.description !== editedRoleData.description
  ) {
    setIsDisabled(false);
  } else {
    setIsDisabled(true);
  }

  return (
    <div className={classes.roleEditor}>
      <Typography className={classes.roleName} variant="h6">
        {translations.pages.userManagement.table.column.userRole}
      </Typography>
      <div>
        <TextField
          value={editedRoleData.name}
          placeholder="Provide Input ..."
          required
          onChange={(e: React.ChangeEvent) => {
            const input = Object(e).target.value ?? "";
            setEditedRoleData({
              id: editedRoleData.id,
              description: editedRoleData.description,
              name: input,
            });
          }}
        />
      </div>
      <Typography variant="h6" className={classes.roleDescription}>
        {translations.pages.userManagement.table.column.roleDescription}
      </Typography>
      <div>
        <TextField
          className={classes.roleDescriptionInput}
          value={editedRoleData.description}
          placeholder="Provide Input ..."
          required
          onChange={(e: React.ChangeEvent) => {
            const input = Object(e).target.value ?? "";
            setEditedRoleData({
              id: editedRoleData.id,
              description: input,
              name: editedRoleData.name,
            });
          }}
        />
      </div>
    </div>
  );
};

export default RoleEditor;
