import PLIcon from "components/patternLib/PLIcon";
import PLPill from "components/patternLib/PLPill";
import React from "react";
import { Tag } from ".";
import { TagListContainerSC, TagListItemSC } from "./styles";

interface TagsListProps {
  handleDelete: (id: string) => Promise<void>;
  tags: Array<Tag>;
  loading?: boolean;
}
const TagsList: React.FC<TagsListProps> = ({ handleDelete, tags,loading }) => {
  return (
    <TagListContainerSC>
      {tags.map((item, index) => (
        <TagListItemSC key={index}>
          <PLPill key={index} label={item.tagValue} noStates />
          <div
            className="deleteIcons"
            onClick={async () => !loading && await handleDelete(item.id)}
            style={{
              cursor: loading ? "initial" : "pointer",
            }}
          >
            <PLIcon iconName="bin" size="25px" />
          </div>
        </TagListItemSC>
      ))}
    </TagListContainerSC>
  );
};

export default TagsList;
