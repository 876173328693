import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import SidebarDrawer from "components/organisms/SidebarDrawer";
import PLTextlink from "components/patternLib/PLTextlink";
import Table from "components/templates/dataGridTable/Table";
import { Asset, AssetMasterData } from "graphqlBase/types";
import React from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
import { DeviceInGeofence } from "..";
import { StorageArea } from "../GeofenceMapContainer";

const StorangeAreNameSC = styled("div")({
  margin: "11px",
});

interface AssetListProps {
  devicesByStorageAreaId: { [key: string]: DeviceInGeofence[] };
  open: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  storageAreas: StorageArea[];
}

const AssetList: React.FC<AssetListProps> = ({ devicesByStorageAreaId, open, setDrawerOpen, storageAreas }) => {
  const history = useHistory();
  const columns: GridColDef<Asset>[] = [
    {
      field: "articleName",
      headerName: `Article name`,
      flex: 3,
      renderCell: (params) => {
        return (
          <PLTextlink
            bold={false}
            size="small"
            label={params.row.assetMasterData?.generalItem ?? ""}
            onClick={() => {
              history.push(`/assetManagement/assetDetails/${params.row.id}`);
            }}
          />
        );
      },
      type: "string",
    },

    {
      field: "articleNumber",
      headerName: `Article`,
      flex: 1.5,

      valueGetter: (params) => {
        return params.row.assetMasterData?.articleNumber ?? "";
      },
      type: "string",
    },
    {
      field: "serialNumber",
      headerName: `Serial Number`,
      flex: 1.5,
      valueGetter: (params) => {
        return params.row.assetMasterData?.serialNumber ?? "";
      },
    },
  ];
  return (
    <SidebarDrawer title="Asset List" open={open} onClose={() => setDrawerOpen(false)}>
      {Object.entries(devicesByStorageAreaId).map((entry) => {
        const storageAreaName = storageAreas.find((item) => item.id === entry[0]);
        const rows = entry[1]
          .map((el) => {
            return el?.device?.assetDevice && el?.device?.assetDevice[0]?.asset.assetMasterData
              ? el?.device?.assetDevice[0]?.asset
              : null;
          })
          .filter(Boolean);

        return (
          <React.Fragment key={entry[0]}>
            {rows.length && storageAreaName && (
              <div style={{ marginBottom: "27px" }}>
                <StorangeAreNameSC>
                  <Typography sx={{ paddingLeft: "20px" }} key={entry[0]} variant="copy">
                    {translations.pages.fleetInventory.geofence}:
                  </Typography>
                  <Typography sx={{ paddingLeft: "5px" }} key={entry[0]} variant="copyBold">
                    {storageAreaName?.storageAreaName}
                  </Typography>
                </StorangeAreNameSC>
                <Table
                  noDataMessage={translations.pages.assetManagement.noAssetsMessage}
                  columns={columns}
                  rows={rows as readonly GridValidRowModel[]}
                  checkboxSelection={false}
                  tableHeight="28vh"
                  hasToolbar={false}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </SidebarDrawer>
  );
};

export default AssetList;
