import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLButtonProps = DropOnONLh<JSX.PatternlibButton>;
export type PLButtonExtendedProps = PLButtonProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedButton = wrapWc<JSX.PatternlibButton>("patternlib-button");
export const useRefPLButton = () => useRef<HTMLPatternlibButtonElement>(null);

const PLButton = React.forwardRef<HTMLPatternlibButtonElement, PLButtonExtendedProps>((props, ref) => {
  const myRef = useRefPLButton();
  return (
    <WrappedButton ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedButton>
  );
});
PLButton.displayName = "PLButton";

export default PLButton;
