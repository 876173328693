import React, { MutableRefObject, useEffect, useState } from "react";
import { DoughnutValueType } from "../doughnuts/types";
import { ResultSet } from "@cubejs-client/core";
import { useRecoilValue } from "recoil";
import { filtersSelector } from "../filtersState";
import { MakeChartsProps } from "./useMakeMemoizedCharts";

type SetChatOptionsType<T, Y, Z> = Pick<
  MakeChartsProps<T, Y, Z>,
  "chartInstance" | "chartLoading" | "isDougnut" | "optionsObjForSetOption" | "resultSet"
>;

const useSetChatOptions = <T, Y, Z extends { series: any }>({
  isDougnut,
  chartInstance,
  optionsObjForSetOption,
  resultSet,
  chartLoading,
}: SetChatOptionsType<T, Y, Z>) => {
  const [data, setData] = useState<Array<DoughnutValueType> | undefined>();
  const filters = useRecoilValue(filtersSelector);

  useEffect(() => {
    if (!resultSet || chartLoading) return;

    chartInstance.current?.setOption(optionsObjForSetOption);
    if (isDougnut) setData(optionsObjForSetOption.series[0].data);
  }, [resultSet, filters, chartLoading]);

  return { data };
};

export default useSetChatOptions;
