import { Typography } from "@mui/material";
import { prepareServerSideTable } from "components/templates/table-factory";
import { useCreateMappingRoleUsersMutation } from "graphqlBase/Settings/__generated__/createMappingRoleUsers";
import { useDeleteMappingRoleUsersMutation } from "graphqlBase/Settings/__generated__/deleteMappingRoleUsers";
import { GetRolesDocument, useGetRolesLazyQuery } from "graphqlBase/Settings/__generated__/getRoles";
import { GetUserByIdQuery } from "graphqlBase/Settings/__generated__/getUserById";
import { GetUsersDocument } from "graphqlBase/Settings/__generated__/getUsers";
import { MappingRoleUser, RoleFilterInput, RoleSortInput, SortEnumType } from "graphqlBase/types";
import React from "react";
import translations from "translations";
import { Role } from "../index.d";
import { SaveButton } from "../SaveButton";
import { preSelectedEntity, removedEntities } from "../helpers";
import { useDisableButton } from "../useDisablebutton";
interface RolesTableRpops {
  userData: GetUserByIdQuery;
}
export interface CreateUserRoleMutation {
  roleId: string;
  userId: string;
}
const variables = {};
const RolesTable: React.FC<RolesTableRpops> = ({ userData }) => {
  const [queryRoles, { data: rolesData, loading: loadingRoles }] = useGetRolesLazyQuery();
  const [addRolesToUserMutation] = useCreateMappingRoleUsersMutation({
    refetchQueries: [{ query: GetUsersDocument }, { query: GetRolesDocument }],
  });
  const [removeRolesFromUserMutation] = useDeleteMappingRoleUsersMutation({
    refetchQueries: [{ query: GetUsersDocument }, { query: GetRolesDocument }],
  });

  const addRolesToUser = async () => {
    const { data } = await addRolesToUserMutation({
      variables: {
        mappingRoleUsers: addedRoles as CreateUserRoleMutation[],
      },
    });

    return data;
  };
  const removeRolesFromUser = async () => {
    const { data: removedRolesData } = await removeRolesFromUserMutation({
      variables: {
        ids: removedEntities(
          userData?.user?.mappingRoleUsers ?? [],
          tableInstance.state.selectedRowIds,
          ({ role }) => role.id
        ),
      },
    });

    return removedRolesData;
  };

  const {
    useCreateServerSideColumns: useCreateServerSideRoleColumns,
    useMakeServerSideTable: useMakeServerSideRoleTable,
    makeSeverSideFilter: makeSeverSideRoleFilter,
  } = prepareServerSideTable<Role, RoleFilterInput, RoleSortInput, SortEnumType>();
  const { columns: roleColumns, serversideQueryConfig: roleConfig } = useCreateServerSideRoleColumns([
    {
      Header: translations.pages.userManagement.table.column.name,
      accessor: (row: Role) => row?.name,
      id: "name",
      remoteOrder: ({ order, sort }) => ({ name: sort }),
      remoteFilter: makeSeverSideRoleFilter<string>({
        filterPath: ({ filterValue, where, merge }) => merge(where, { name: { contains: filterValue } }),
      }),
    },
    {
      Header: translations.pages.userManagement.table.column.description,
      accessor: (row: Role) => row?.description,
      id: "description",
      remoteOrder: ({ order, sort }) => ({ description: sort }),
      remoteFilter: makeSeverSideRoleFilter<string>({
        filterPath: ({ filterValue, where, merge }) => merge(where, { description: { contains: filterValue } }),
      }),
    },
  ]);

  const { TableComponent: RoleTable, tableInstance } = useMakeServerSideRoleTable(
    {
      data: rolesData?.roles ?? [],
      columns: roleColumns,
      initialState: {
        selectedRowIds: preSelectedEntity(
          userData?.user?.mappingRoleUsers ?? ([] as MappingRoleUser[]),
          ({ role }) => role.id
        ),
      },
    },
    {
      query: queryRoles,
      variables,
      serversideQueryConfig: roleConfig,
      tableId: "rolesTable",
      rowSelection: true,
    }
  );
  const addedRoles = Object.keys(tableInstance.state.selectedRowIds)
    .map((roleId) => {
      const isRoleSelected = userData?.user?.mappingRoleUsers.find((selectedRole) => selectedRole.role.id === roleId);
      if (!isRoleSelected) {
        return { roleId: roleId, userId: userData?.user?.id };
      }
    })
    .filter(Boolean);
  const addedRolesLength = addedRoles.length;
  const removedEntitiesLength = removedEntities(
    userData?.user?.mappingRoleUsers ?? [],
    tableInstance.state.selectedRowIds,
    ({ role }) => role.id
  ).length;

  const doMutations = () => {
    addedRolesLength && addRolesToUser();
    removedEntitiesLength && removeRolesFromUser();
  };
  const isDisabled = useDisableButton(addedRolesLength, removedEntitiesLength);
  return (
    <>
      <Typography variant="h2">{userData?.user?.userName}</Typography>
      <RoleTable
        tableProps={{ headCell: { style: { verticalAlign: "top" } }, container: { style: { maxHeight: "50vh" } } }}
      />
      <SaveButton doMutations={doMutations} setBlocked={() => {}} />
    </>
  );
};
export default RolesTable;
