import { Box } from "@mui/material";
import RadioGroup from "components/molecules/RadioGroup";
import MandatoryFieldMessage from "components/pages/AlertManagement/AlertEditing/MandatoryFieldMessage";
import { hasFormErrorsAtom,isScopeConnectivityAtom,isTheFormValidSelector } from "components/pages/AlertManagement/AlertEditing/recoilState";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import MappingAssetGroupsNotifications from "./MappingAssetGroupsNotifications";
import MappingAssetNotifications from "./MappingAssetNotifications";
import { mappingAssetsToAlertState } from "./alertMappingAssetsState";
import Props from "./index.d";
import MappingDeviceNotificationsTable from "./MappingDeviceNotificationsTable";
import { useParams } from "react-router-dom";

const assetOptions = [
  { value: "0", label: "Assets" },
  { value: "1", label: "Asset Groups" },
];
const deviceOptions = [
  { value: "0", label: "Devices" },
  { value: "1", label: "Device Groups" },
];

const Notification2Devices: React.FC<Props> = ({ data: { state } }) => {
  const { notificationId }: { notificationId: string } = useParams();
  const [mappingAssetsRadio, setMappingAssetsRadio] = useRecoilState(mappingAssetsToAlertState);
  const formHasErrors = useRecoilValue(hasFormErrorsAtom);
  const { deviceCountFromAssets,deviceCountFromDevices } = useRecoilValue(isTheFormValidSelector);
  const isScopeConnectivity=useRecoilValue(isScopeConnectivityAtom)
  
  const handleChange = (item: string) => {
    setMappingAssetsRadio(Number(item));
  };

  return (
    <>
      <Box sx={{ marginBottom: "20px" }}>
        <RadioGroup
          selected={mappingAssetsRadio.toString()}
          horizontal
          onSelectItem={handleChange}
          title="Choose assets"
          items={isScopeConnectivity? deviceOptions : assetOptions}
          gap="118px"
        />
      </Box>
      {mappingAssetsRadio === 0 && (isScopeConnectivity ? <MappingDeviceNotificationsTable notificationId={notificationId} />:<MappingAssetNotifications notificationId={notificationId} />)}
      {mappingAssetsRadio === 1 && <MappingAssetGroupsNotifications notificationId={notificationId} />} 
      {!deviceCountFromAssets && !deviceCountFromDevices && formHasErrors && <MandatoryFieldMessage />}
    </>
  );
};

export default Notification2Devices;
