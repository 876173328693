import { Box, Typography } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { LeftTileWrapperSC, NoDataContainerSC } from "components/pages/AssetManagement/tileStyles";
import React, { useEffect } from "react";
import translations from "translations";
import { deviceIdState } from "../../../assetDetailState";
import ReactECharts from "echarts-for-react";
import { useGetCapabilitiesByNameQuery } from "graphqlBase/Capabilities/__generated__/getCapabilitiesByName";
import { getEnvMeasurementsOption } from "./utils";
import {
  GetEnvironmentalMeasurementsForAssetDetailsQuery,
  useGetEnvironmentalMeasurementsForAssetDetailsLazyQuery,
  ResultType,
} from "graphqlBase/SensorMeasurementHistories/__generated__/getEnvironmentalMeasurementsForAssetDetails";
import { formatISO, parseISO, subHours } from "date-fns";
import { useRecoilValue } from "recoil";

const DATE_TODAY = parseISO(new Date().toISOString());
const DATE_DAY_AGO = parseISO(subHours(new Date(), 24).toISOString());

type CapabilityValueType = "temperatureData" | "humidityData" | "";
interface CapabilityIdsType {
  [key: string]: CapabilityValueType;
}

type ResultForGraphs = ResultType<GetEnvironmentalMeasurementsForAssetDetailsQuery["sensorMeasurementHistories"]>;

const stringToNumber = ({ valueString }: ResultForGraphs) =>
  valueString ? parseFloat(valueString.slice(0, -2).replace(",", ".")) : 0;

const EnvironmentalMeasurementsTile: React.FC<{}> = () => {
  const deviceId = useRecoilValue(deviceIdState);

  const { noDataMessage, label } = translations.pages.assetManagement.assetDetails.envMeasurementsTile;

  const { data: capabilityData } = useGetCapabilitiesByNameQuery({
    variables: { where: { capabilityName: { in: ["humidity", "temperature"] } } },
  });

  const keys = ["temperatureData", "humidityData"];

  const capabilityIds = capabilityData?.capabilities?.reduce<CapabilityIdsType>((capabilityIds, current) => {
    const currentKey = keys?.find((key) => {
      if (key.includes(current.capabilityName.toLowerCase())) {
        return key;
      }
      return undefined;
    });
    if (currentKey && current?.id) capabilityIds[current?.id] = (currentKey ?? "") as CapabilityValueType;

    return capabilityIds;
  }, {});

  const [
    getChartData,
    { data: chartData, loading: loadingChartData, error },
  ] = useGetEnvironmentalMeasurementsForAssetDetailsLazyQuery();

  useEffect(() => {
    if (!deviceId || !capabilityIds || chartData) return;
    getChartData({
      variables: {
        capabilityIds: Object.keys(capabilityIds ?? {}),
        deviceId,
        startDate: formatISO(DATE_DAY_AGO),
        endDate: formatISO(DATE_TODAY),
      },
    });
  }, [deviceId, capabilityIds, chartData]);

  const { humidityData, temperatureData, dates } = (chartData?.sensorMeasurementHistories ?? []).reduce<{
    dates: Date[];
    humidityData: Array<[Date, number]>;
    temperatureData: Array<[Date, number]>;
  }>(
    (acc, sensorMeasurement) => {
      const value = stringToNumber(sensorMeasurement);
      const time = new Date(sensorMeasurement.utcTimeMeasured);
      const capability = capabilityIds?.[sensorMeasurement.capabilityId ?? ""];

      if (!capability || !time || !value) return acc;

      return {
        ...acc,
        [capability]: acc[capability].concat([[time, value]]),
        dates: acc.dates.concat(time).reverse(),
      };
    },
    {
      humidityData: [],
      temperatureData: [],
      dates: [],
    }
  );

  return (
    <LeftTileWrapperSC>
      <Typography variant="h4">{label}</Typography>
      <LoadingSpinner
        sx={{ height: loadingChartData ? "30vh" : "fit-content" }}
        hideChildrenOnLoad
        loading={loadingChartData}
      >
        {(!!error || !chartData?.sensorMeasurementHistories?.length) && !loadingChartData ? (
          <NoDataContainerSC>
            <Typography variant="copy">{noDataMessage}</Typography>
          </NoDataContainerSC>
        ) : (
          <Box sx={{ width: "115%", marginLeft: "-8%", marginRight: "-200px" }}>
            <ReactECharts option={getEnvMeasurementsOption({ dates, humidityData, temperatureData })} />
          </Box>
        )}
      </LoadingSpinner>
    </LeftTileWrapperSC>
  );
};

export default EnvironmentalMeasurementsTile;
