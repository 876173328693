import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import PLIcon from "components/patternLib/PLIcon";
import React from "react";
interface ToastProps {
  handleCloseToast: () => void;
  message: string;
  open: boolean;
  severity: "success" | "error";
}
function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}
const Toast: React.FC<ToastProps> = ({ message, open, severity, handleCloseToast }) => {
  return (
    <Snackbar
      open={open}
      onClose={handleCloseToast}
      TransitionComponent={SlideTransition}
      message="Toast"
      key="snackKey"
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Alert
        sx={{
          height: "48px",
          width: "400px",
          backgroundColor: severity === "success" ? "#58A649" : "#E52828",
          "& .MuiAlert-message": { paddingLeft: "8px", overflow: "hidden", alignSelf: "center" },
        }}
        square
        variant="filled"
        icon={<PLIcon iconName={severity === "success" ? "checkmark" : "warning"} size="24px" />}
        severity={severity}
        action={
          <button style={{ alignSelf: "center", paddingRight: "12px" }} onClick={handleCloseToast}>
            <PLIcon iconName="x" size="24px" />
          </button>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
