import { DateRange } from "@material-ui/pickers";
import { atom, atomFamily, selectorFamily } from "recoil";

type SelectedId = string | null;

export const selectedId = atom<SelectedId>({
  key: "selectedId",
  default: null,
});

export const selectedIds = atom<string[]>({
  key: "selectedIdArray",
  default: [],
});

export const isToggleMapButtonActiveAtom = atom<boolean>({
  key: "currentView",
  default: false,
});

export const selectionIssuer = atom<"table" | "map" | null>({
  key: "selectionIssuer",
  default: null,
});

export const isPointSelected = selectorFamily({
  key: "cursorOnMarker",
  get: (id) => ({ get }) => {
    return get(selectedId) === id;
  },
});

export const preSelectedDateForDatePicker = atom<DateRange<Date | null>>({
  key: "preSelectedDateForDatePicker",
  default: [null, null],
});

export const dateRange = atom<DateRange<Date | null>>({
  key: "dateRange",
  default: preSelectedDateForDatePicker,
});

export const selectedStorageAreaIdsAtom = atom<string[]>({
  key: "selectedStorageAreaIdsAtom",
  default: [""],
});

export const newStorageAreaIdAtom = atom<string>({
  key: "newStaorageAreaIdAtom",
  default: "",
});

export const isSearchbarUsedAtom = atom<boolean>({
  key: "isSeacrbarUsedAtom",
  default: false,
});
