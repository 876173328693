import { OptionItem } from "@liebherr/patternlib/dist/types/components/form/patternlib-dropdown/patternlib-dropdown";
import { LhSingleOptionSelectEvent } from "@liebherr/patternlib/dist/types/utils/events/select";
import { Box } from "@mui/material";
import useGetNotificationIdFromRoute from "components/pages/AlertManagement/AlertEditing/hooks/useGetNotificationIdFromRoute";
import MandatoryFieldMessage from "components/pages/AlertManagement/AlertEditing/MandatoryFieldMessage";
import {
  hasFormErrorsAtom,
  hasResubmissionAndActiveValuesAtom,
  isTheFormValidSelector,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import PLDropdown from "components/patternLib/form/PLDropdown";
import PLOption from "components/patternLib/form/PLOption";
import { useGetResubmissionValueByNotificationIdLazyQuery } from "graphqlBase/Notifications/__generated__/getResubmissionIntervalByNotificationId";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import translations from "translations";
import Props from "./index.d";

const options: OptionItem[] = [
  { value: "1", label: translations.pages.alertManagement.NotificationResubmissionInterval.h1 },
  { value: "6", label: translations.pages.alertManagement.NotificationResubmissionInterval.h6 },
  { value: "12", label: translations.pages.alertManagement.NotificationResubmissionInterval.h12 },
  { value: "24", label: translations.pages.alertManagement.NotificationResubmissionInterval.h24 },
  { value: "9999", label: translations.pages.alertManagement.NotificationResubmissionInterval.never },
];

const NotificationResubmissionInterval: React.FC<Props> = (props) => {
  const {
    data: { setAndSave },
    testId,
    title,
    disabled,
  } = props;
  const [selectedInterval, setSelectedInterval] = useState<string | undefined>();
  const setIsCurrStateUndefined = useSetRecoilState(hasResubmissionAndActiveValuesAtom);
  const memoizedSelectValues: number[] = useMemo(() => options.map((option) => +option.value), [translations]);
  const notificationId = useGetNotificationIdFromRoute();
  const { resubmissionValue } = useRecoilValue(isTheFormValidSelector);
  const formHasErrors = useRecoilValue(hasFormErrorsAtom);
  const [query, { data, loading }] = useGetResubmissionValueByNotificationIdLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (!notificationId || data) return;
    query({ variables: { id: notificationId } });
  }, [notificationId, data]);

  useEffect(() => {
    if (!data) return;
    setSelectedInterval(
      options.find((option) => Number(option.value) === data?.notification?.resubmissionInterval)?.value ?? undefined
    );
  }, [data]);

  const resubmissionInterval = data?.notification?.resubmissionInterval;

  useEffect(() => {
    !selectedInterval
      ? setIsCurrStateUndefined((prevState) => ({ ...prevState, resubmissionValue: 0 }))
      : setIsCurrStateUndefined((prevState) => ({ ...prevState, resubmissionValue: 1 }));
  }, [selectedInterval]);

  useEffect(() => {
    if (Number(selectedInterval) === resubmissionInterval) return;
    if (memoizedSelectValues.includes(resubmissionInterval ?? 0)) {
      setAndSave(resubmissionInterval ?? 0);
    }
  }, [resubmissionInterval, data]);

  const handleChange = (event: CustomEvent<LhSingleOptionSelectEvent>) => {
    const { selected } = event.detail;
    setSelectedInterval(selected?.value ?? undefined);
    if (!selected) return;
    const val = Number(selected.value);
    setAndSave(val);
  };

  return (
    <Box sx={{ flexDirection: "column" }}>
      <PLDropdown
        showRemoveIcon={false}
        width="280px"
        data-testid={testId}
        lhSelect={handleChange}
        value={selectedInterval}
        disabled={disabled}
        label={title}
        status={!resubmissionValue && formHasErrors ? "error" : "default"}
        preventInput
      >
        {options.map((e) => {
          return <PLOption key={e.value} label={e.label} value={e.value} />;
        })}
      </PLDropdown>
      {!resubmissionValue && formHasErrors && <MandatoryFieldMessage />}
    </Box>
  );
};

export default NotificationResubmissionInterval;
