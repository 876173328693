import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLBadgeProps = DropOnONLh<JSX.PatternlibBadge>;
export type PLBadgeExtendedProps = PLBadgeProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedBadge = wrapWc<JSX.PatternlibBadge>("patternlib-badge");
export const useRefPLBadge = () => useRef<HTMLPatternlibBadgeElement>(null);

const PLBadge = React.forwardRef<HTMLPatternlibBadgeElement, PLBadgeExtendedProps>((props, ref) => {
  const myRef = useRefPLBadge();
  return (
    <WrappedBadge ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedBadge>
  );
});
PLBadge.displayName = "PLBadge";

export default PLBadge;
