import React, { useEffect, useState } from "react";
import { useGetDeviceFirmwaresQuery } from "graphqlBase/DeviceFirmware/__generated__/getDeviceFirmwares";
import { DeviceFirmware } from ".";
import translations from "translations";
import PLDropdown from "components/patternLib/form/PLDropdown";
import { LhInternalOptionSelect } from "@liebherr/patternlib";
import { SelectItem } from "components/lbc-toolkit/molecules/Select";
import PLOption from "components/patternLib/form/PLOption";
import { PatternlibOptionCustomEvent } from "@liebherr/patternlib/dist/types/components";

interface FirmwareSelectProps {
  currentFirmwareId: string | undefined;
  setCurrentFirmware: React.Dispatch<React.SetStateAction<DeviceFirmware | null>>;
  setCurrentFirmwareId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FirmwareSelect: React.FC<FirmwareSelectProps> = ({
  setCurrentFirmware,
  currentFirmwareId,
  setCurrentFirmwareId,
}) => {
  const { data } = useGetDeviceFirmwaresQuery();
  const [firmwareSelectOptions, setFirmwareSelectOptions] = useState<SelectItem[] | null>(null);

  useEffect(() => {
    if (!data) return;
    const firmwareOptions = data.deviceFirmwares.reduce((acc: SelectItem[], curr) => {
      if (curr.id && curr.firmwareRevision) {
        return acc.concat({ label: curr.firmwareRevision, value: curr.id });
      } else return acc;
    }, []);

    setFirmwareSelectOptions(firmwareOptions);
  }, [data]);

  useEffect(() => {
    if (!currentFirmwareId || !data) return;
    const firmware = data.deviceFirmwares.find((firmware) => firmware.id === currentFirmwareId);
    if (firmware) {
      setCurrentFirmware(firmware);
      setCurrentFirmwareId(firmware.id);
    }
  }, [currentFirmwareId, data, setCurrentFirmware]);

  const handleOnChange = (event: PatternlibOptionCustomEvent<LhInternalOptionSelect>) => {
    setCurrentFirmwareId(event.detail.value);
  };

  return (
    <div>
      {firmwareSelectOptions && (
        <div>
          <PLDropdown value={currentFirmwareId} preventInput showRemoveIcon={false}>
            {[...firmwareSelectOptions]
              .sort((a, b) => Number(b.label) - Number(a.label))
              .map((el) => {
                return <PLOption key={el.value} value={el.value} label={el.label} lhOptionSelect={handleOnChange} />;
              })}
          </PLDropdown>
        </div>
      )}
    </div>
  );
};

export default FirmwareSelect;
