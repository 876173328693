import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { Theme } from "@mui/material";
import Props from "./index.d";

const useStyles = makeStyles<Theme, Props>(
  (theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        flexWrap: ({ flexWrap }) => flexWrap,
        justifyContent: ({ justify }) => justify ?? "center",
        flexDirection: ({ direction }) => direction ?? "row",
        width: ({ width }) => width ?? "100%",
        height: ({ height }) => height ?? "100%",
        background: ({ background }) => (background ? theme.colors[background] : undefined),
        alignContent: ({ alignContent }) => alignContent,
        alignItems: ({ alignItems }) => alignItems,
        margin: ({ margin }) => margin ?? "unset",
        maxHeight: ({ maxHeight }) => maxHeight ?? "unset",
        columnGap: ({ columnGap }) => columnGap,
        rowGap: ({ rowGap }) => rowGap,
        padding: ({ padding }) => padding ?? "hidden",
        // backgroundColor: theme.palette.background.paper
      },
      child: {
        display: "inherit",
        marginLeft: 30,
      },
    }),
  { name: `FieldSetDiv` }
);
interface GFCFieldSet_FieldSetDiv extends Props {
  children: React.ReactNode;
}

const FieldSetDiv: React.FC<GFCFieldSet_FieldSetDiv> = (props) => {
  const classes = useStyles(props);
  //  @ts-ignore
  if (props.hidden) return null;
  return <div className={classes.root}>{props.children}</div>;
};
export default FieldSetDiv;
