import { GridRowParams, GridValidRowModel } from "@mui/x-data-grid-pro";
import { getUserById } from "components/pages/utils";
import useDataGrid, { useFilterTableSelectedRows } from "components/templates/dataGridTable";
import { genericDeleteParams } from "components/templates/table-factory/Table/Cells/actionsCellFactory/recoilStates";
import {
  GetAllDynamicGroupsQuery,
  useGetAllDynamicGroupsLazyQuery,
} from "graphqlBase/Groups/__generated__/getAllDynamicGroups";
import { ResultType } from "graphqlBase/types";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { formatDate } from "translations/formatter";
import { selectedAssetGroupAtom } from "./RecoilState";
interface SelectedGroup extends Group {
  toDelete: boolean;
}
export type Group = ResultType<GetAllDynamicGroupsQuery["groups"]>;
export interface GroupWithUserName extends Group {
  userNameAndLastName?: string;
}

const AssetGroups: React.FC<{}> = () => {
  const history = useHistory();
  const [assetGroups, setAssetGroups] = useState<GroupWithUserName[] | undefined>();
  const setDeleteParams = useSetRecoilState(genericDeleteParams);

  const variables = { where: { groupEntity: { entityName: { eq: "AssetDevice" } } } };

  const [queryOuter, { data, loading, error, refetch }] = useGetAllDynamicGroupsLazyQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });
  const [tableLoading, setTableLoading] = useState(loading || !assetGroups);
  const {
    query,
    selectedIds,
    setSelectedIds,
    setSelectedRowsFilterOn,
    selectedRowsFilterOn,
  } = useFilterTableSelectedRows({
    queryOuter,
    atom: selectedAssetGroupAtom,
    getIdsFromAtom: (rows) => rows.map((row) => row.id),
    entity: "group",
  });
  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef } = useDataGrid<GroupWithUserName, "Group">({
    query,
    variables,
    tableId: "AssetGroups",
    persistance: "runTime",
  });

  const { myAssetGroups, noDataMessage } = translations.pages.groups.assetGroup;
  const { deleteGroup, deleteGroupMessage } = translations.pages.groups;
  const { group } = translations.entities;

  useEffect(() => {
    if (data?.groups) {
      const groupsWithUser = (data.groups ?? []).reduce<GroupWithUserName[]>((groupsWithUser, currentGroup) => {
        const updatedGroup: GroupWithUserName = { ...currentGroup, userNameAndLastName: "" };

        getUserById(currentGroup.userId ?? "")
          .then((user) => {
            updatedGroup.userNameAndLastName = `${user?.firstName} ${user?.lastName}`;
          })
          .catch((error) => {
            console.error("failed to get user name", error.message);
          });

        return groupsWithUser.concat(updatedGroup);
      }, []);

      setAssetGroups(groupsWithUser);
    }

    if (error) setTableLoading(false);
  }, [data, error]);

  useEffect(() => {
    if (assetGroups && tableLoading) refetch?.().finally(() => setTableLoading(false));
  }, [assetGroups, tableLoading]);

  const afterDelete = () => {
    if (refetch)
      refetch().catch(() => {
        return null;
      });
  };

  const deleteAssetGroups = (ids: string[]) => {
    setDeleteParams({
      entity: "Groups",
      id: ids,
      afterDelete,
      modalTitle: deleteGroup,
      modalMessage: deleteGroupMessage,
    });
  };

  const columns = useMakeColumns([
    {
      field: "groupName",
      headerName: group.columns.groupName,
      flex: 2,
      valueGetter: (params) => {
        return params.row.groupName ?? "";
      },
      remoteOrder: (sort) => ({ groupName: sort }),
      remoteFilter: makeSeverSideFilter("string", {
        filterPath: ({ filterValue }) => ({
          groupName: filterValue,
        }),
      }),
      type: "string",
    },
    {
      field: "description",
      headerName: group.columns.groupDescription,
      flex: 2,
      valueGetter: (params) => {
        return params.row.groupDescription ?? "";
      },
      remoteOrder: (sort) => ({ groupDescription: sort }),
      remoteFilter: makeSeverSideFilter("string", {
        filterPath: ({ filterValue }) => ({
          groupDescription: filterValue,
        }),
      }),
      type: "string",
    },
    {
      field: "createdAt",
      headerName: group.columns.createdAt,
      flex: 3,
      valueGetter: (params) => {
        const isoDate = new Date(params.row.createdAt ?? new Date().toISOString());
        return `${formatDate(isoDate)}`;
      },
      remoteFilter: makeSeverSideFilter("dateTime", {
        filterPath: ({ where, filterValue }) => ({
          createdAt: filterValue,
        }),
      }),
      remoteOrder: (sort) => ({ createdAt: sort }),
      type: "dateTime",
    },
    {
      field: "createdBy",
      headerName: group.columns.createdBy,
      flex: 2,
      valueGetter: (params) => params.row.userNameAndLastName ?? "",
      type: "string",
    },
  ]);

  const handleOnRowClick = (params: GridRowParams) => {
    history.push(`/assetManagement/assetGroupDetail/${params.row.id}`);
  };

  const handleEditAssetGroup = (id: string) => {
    history.push(`/assetManagement/updateAssetGroup/${id}`);
  };
  const handleEditInfo = {
    handleEditRow: handleEditAssetGroup,
    editScope: "UpdateAssetGroupUI",
  };

  const handleDeleteInfo = {
    handleDeleteRows: deleteAssetGroups,
    deleteScope: "DeleteAlertUI",
  };
  const selectedRows = useMemo(
    () =>
      selectedIds?.reduce<string[]>((assetIds, element) => {
        return assetIds.concat(element.id);
      }, []),
    [selectedIds]
  );

  const handleSelectRow = (rows: readonly GridValidRowModel[]) => {
    setSelectedIds((selectedGroups) => {
      const groupIDsOnUi = apiRef.current.getAllRowIds ? apiRef.current.getAllRowIds() : [];
      const chekedIds = rows.map(({ id }) => id) as string[];
      const SelectedUsersNextPre = selectedGroups.reduce<SelectedGroup[]>((acc, curr) => {
        if (!groupIDsOnUi.includes(curr.id)) return acc.concat(curr as SelectedGroup);
        if (chekedIds.includes(curr.id)) {
          return acc.concat({ ...curr, toDelete: false });
        } else {
          return acc.concat({ ...curr, toDelete: true });
        }
      }, []);
      return rows
        .reduce<SelectedGroup[]>((acc, curr) => {
          if (acc.find((e) => e.id === curr.id)) return acc;

          return acc.concat(curr as SelectedGroup);
        }, SelectedUsersNextPre)
        .filter((item) => !item.toDelete);
    });
  };
  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage={noDataMessage}
          tableTitle={myAssetGroups}
          tableHeight="65vh"
          handleOnRowClick={handleOnRowClick}
          selectedRowsFilterOn={selectedRowsFilterOn}
          setSelectedRowsFilterOn={setSelectedRowsFilterOn}
          columns={columns}
          rows={tableLoading ? [] : assetGroups ?? []}
          setSelectedRows={handleSelectRow}
          selectedRows={selectedRows}
          handleDelete={handleDeleteInfo}
          loading={tableLoading}
          handleEdit={handleEditInfo}
        />
      )}
    </>
  );
};

export default AssetGroups;
