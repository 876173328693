import React, { useState } from "react";
import { Box } from "@mui/material";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import translations from "translations";
import PhotoUpload from "./PhotoUpload";

interface PhotoUploadButtonProps {
  afterMutation: () => Promise<void>;
  assetId: string;
}

const PhotoUploadButton: React.FC<PhotoUploadButtonProps> = ({ afterMutation, assetId }) => {
  const [dropzoneModalOpen, setDropzoneModalOpen] = useState(false);

  return (
    <>
      <Box sx={{ marginTop: "15px" }}>
        <PLButton
          label={translations.pages.assetManagement.assetDetails.uploadImageButton}
          type="secondary"
          iconPosition="left"
          onClick={() => setDropzoneModalOpen(true)}
          width="100%"
        >
          <PLIcon iconName="camera" size="24px" slot="icon" />
        </PLButton>
      </Box>
      <PhotoUpload
        assetId={assetId}
        afterMutation={afterMutation}
        dropzoneModalOpen={dropzoneModalOpen}
        setDropzoneModalOpen={setDropzoneModalOpen}
      />
    </>
  );
};

export default PhotoUploadButton;
