import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLRadiobuttonItemProps = DropOnONLh<JSX.PatternlibRadiobuttonItem>;
export type PLRadiobuttonItemExtendedProps = PLRadiobuttonItemProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedRadiobuttonItem = wrapWc<JSX.PatternlibRadiobuttonItem>("patternlib-radiobutton-item");
export const useRefPLRadiobuttonItem = () => useRef<HTMLPatternlibRadiobuttonItemElement>(null);

const PLRadiobuttonItem = React.forwardRef<HTMLPatternlibRadiobuttonItemElement, PLRadiobuttonItemExtendedProps>((props, ref) => {
  const myRef = useRefPLRadiobuttonItem();
  return (
    <WrappedRadiobuttonItem ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedRadiobuttonItem>
  );
});
PLRadiobuttonItem.displayName = "PLRadiobuttonItem";

export default PLRadiobuttonItem;
