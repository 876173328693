import { GridRowProps, GridValidRowModel } from "@mui/x-data-grid-pro";
import client from "apollo/client";
import {
  MappingDeviceNotification,
  alertFormLoadingAtom,
  selectedAssetsForAlertAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import { GroupWithUserName } from "components/pages/AssetManagement/AssetGroups";
import { getUserById } from "components/pages/utils";
import useDataGrid from "components/templates/dataGridTable";
import gql from "graphql-tag";
import {
  GetAllDynamicGroupsQuery,
  ResultType,
  useGetAllDynamicGroupsLazyQuery,
} from "graphqlBase/Groups/__generated__/getAllDynamicGroups";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import translations from "translations";
import { formatDate } from "translations/formatter";
import { mappingAssetsToAlertState } from "../alertMappingAssetsState";

const { group } = translations.entities;
const { myAssetGroups, noDataMessage } = translations.pages.groups.assetGroup;

export type AssetGroup = ResultType<GetAllDynamicGroupsQuery["groups"]>;
interface AssetGroupWithDeviceIds extends GroupWithUserName {
  deviceIds?: MappingDeviceNotification[];
}

interface MappingAssetGroupsNotificationsProps {
  notificationId?: string;
}

const MappingAssetGroupsNotifications: React.FC<MappingAssetGroupsNotificationsProps> = ({ notificationId }) => {
  const [query, { data, error, loading }] = useGetAllDynamicGroupsLazyQuery();
  const resetMappingAssetsRadio = useResetRecoilState(mappingAssetsToAlertState);
  const [selectedAssets, setSelectedAssets] = useRecoilState(selectedAssetsForAlertAtom);

  const [assetGroups, setAssetGroups] = useState<AssetGroupWithDeviceIds[]>([]);
  const [selectedAssetGroups, setSelectedAssetGroups] = useState<AssetGroupWithDeviceIds[]>([]);
  const [tableLoading, setTableLoading] = useState(loading || !assetGroups);
  const formLoading = useRecoilValue(alertFormLoadingAtom);

  useEffect(() => {
    return () => {
      setSelectedAssetGroups([]);
      resetMappingAssetsRadio();
    };
  }, []);

  useEffect(() => {
    if (data?.groups) {
      const groupsWithUser = (data.groups ?? []).reduce<AssetGroupWithDeviceIds[]>((groupsWithUser, currentGroup) => {
        const updatedGroup: AssetGroupWithDeviceIds = { ...currentGroup, userNameAndLastName: "" };

        getUserById(currentGroup.userId ?? "")
          .then((user) => {
            updatedGroup.userNameAndLastName = `${user?.firstName} ${user?.lastName}`;
          })
          .catch((error) => {
            console.error("failed to get user name", error.message);
          });

        return groupsWithUser.concat(updatedGroup);
      }, []);

      setAssetGroups(groupsWithUser);
    }

    if (error) setTableLoading(false);
  }, [data, error]);

  const { DataGrid, useMakeColumns, makeSeverSideFilter } = useDataGrid<AssetGroupWithDeviceIds, "Group">({
    query,
    variables: { where: { groupEntity: { entityName: { eq: "AssetDevice" } } } },
    tableId: "MappingAssetGroupsNotifications",
    persistance: "runTime",
  });

  const columns = useMakeColumns([
    {
      field: "groupName",
      headerName: group.columns.groupName,
      flex: 2,
      valueGetter: (params) => {
        return params.row.groupName ?? "";
      },
      remoteOrder: (sort) => ({ groupName: sort }),
      remoteFilter: makeSeverSideFilter("string", {
        filterPath: ({ where, filterValue }) => ({
          groupName: filterValue,
        }),
      }),
      type: "string",
    },
    {
      field: "description",
      headerName: group.columns.groupDescription,
      flex: 2,
      valueGetter: (params) => {
        return params.row.groupDescription ?? "";
      },
      remoteOrder: (sort) => ({ groupDescription: sort }),
      remoteFilter: makeSeverSideFilter("string", {
        filterPath: ({ where, filterValue }) => ({
          groupDescription: filterValue,
        }),
      }),
      type: "string",
    },
    {
      field: "createdAt",
      headerName: group.columns.createdAt,
      flex: 3,
      valueGetter: (params) => {
        const isoDate = new Date(params.row.createdAt ?? new Date().toISOString());
        return `${formatDate(isoDate)}`;
      },
      remoteOrder: (sort) => ({ createdAt: sort }),
      remoteFilter: makeSeverSideFilter("dateTime", {
        filterPath: ({ where, filterValue }) => ({
          createdAt: filterValue,
        }),
      }),
      type: "string",
    },
    {
      field: "createdBy",
      headerName: group.columns.createdBy,
      flex: 2,
      valueGetter: (params) => params.row.userNameAndLastName ?? "",
      type: "string",
    },
  ]);

  const handleRowHover = (props: GridRowProps) => {
    if (!props.row.jSONQuery || !!props.row.deviceIds) return;
    client
      .query({
        query: gql`
          ${props.row.jSONQuery}
        `,
      })
      .then((groupResult) => {
        const assetGroup = assetGroups.find((group) => {
          return group.id === props.rowId;
        });
        if (assetGroup && groupResult) {
          const deviceIds = groupResult?.data?.result.map((item: { deviceId: string }) => item.deviceId);
          setAssetGroups((prevState) => {
            const prevAssetGroups = [...prevState];
            const index = prevAssetGroups.indexOf(assetGroup);
            prevAssetGroups.splice(index, 1, {
              ...assetGroup,
              deviceIds,
            });
            return prevAssetGroups;
          });
        }
      })
      .catch((error) => console.error(error.message));
  };

  const handleSelectRow = (rows: readonly GridValidRowModel[]) => {
    // setSelectedAssetGroups(rows as AssetGroup[]);
    // const assets = rows.reduce<SelectedAsset[]>((assets, current) => {
    //   if (current?.deviceIds) {
    //     current.deviceIds.forEach((deviceId: string) => {
    //       const assetAlreadyMapped =
    //         !!selectedAssets?.find((asset) => asset?.assetDevice?.[0]?.deviceId === deviceId) ||
    //         !!assets.find((existingItem) => existingItem?.assetDevice?.[0]?.deviceId === deviceId);
    //       // if (!assetAlreadyMapped) assets.push({ id: current.id, assetDevice: current?.assetDevice });
    //     });
    //   }
    //   return assets;
    // }, []);
    // setSelectedAssets(assets);
  };

  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage={noDataMessage}
          tableTitle={myAssetGroups}
          tableHeight="65vh"
          columns={columns}
          rows={assetGroups ?? []}
          loading={loading || formLoading || tableLoading}
          withMargin={false}
          setSelectedRows={handleSelectRow}
          selectedRows={selectedAssetGroups.map((row) => row.id)}
          onRowHover={handleRowHover}
        />
      )}
    </>
  );
};

export default MappingAssetGroupsNotifications;
