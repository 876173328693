import React from "react";
import { styled, Typography } from "@mui/material";

const NoDataSC = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const noItemsTextStyles = { height: "fit-content" };

const NoDataOverlay: React.FC<{
  message: string;
}> = ({ message }) => {
  return (
    <NoDataSC>
      <Typography variant="copy" sx={noItemsTextStyles}>
        {message}
      </Typography>
    </NoDataSC>
  );
};
export default NoDataOverlay;
