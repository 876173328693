import { styled } from "@mui/material";
import PLList, { PLListProps } from "components/patternLib/PLList";
import PLListItem from "components/patternLib/PLListItem";
import React from "react";

export interface ArrayValue {
  label: string;
  value?: string | JSX.Element;
}

interface DetailListProps extends PLListProps {
  data: ArrayValue[];
}

const ItemDataSC = styled("span")(
  () => `
    display: inline-block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: visible;
    width: 100%;
    line-height: 1.5;
    `
);

const DetailInfo: React.FC<DetailListProps> = ({ data, itemHeight }) => {
  return (
    <PLList noSeparator>
      {data.map(({ label, value }) => (
        <PLListItem noSeparator itemHeight={itemHeight} key={label} labelWidth="50%">
          <ItemDataSC>{label}</ItemDataSC>
          <ItemDataSC slot="value">{value}</ItemDataSC>
        </PLListItem>
      ))}
    </PLList>
  );
};

export default DetailInfo;
