import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesForConfigurationOvertakeQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.DeviceSortInput> | Types.DeviceSortInput>;
}>;


export type GetDevicesForConfigurationOvertakeQuery = { devices: Array<{ __typename?: 'Device', id: string, deviceName: string, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareVersion: string } }>, mappingDeviceConfigurationDevice: Array<{ __typename?: 'MappingDeviceConfigurationDevice', id: string, dateFrom: string, dateTo: string, deviceConfiguration: { __typename?: 'DeviceConfiguration', id: string, configurationJSON: string } }>, mappingDeviceTag: Array<{ __typename?: 'MappingDeviceTag', id: string, tag: { __typename?: 'Tag', id: string, tagValue: string } }> }> };


export const GetDevicesForConfigurationOvertakeDocument = gql`
    query getDevicesForConfigurationOvertake($where: DeviceFilterInput, $take: Int, $skip: Int, $order: [DeviceSortInput!]) {
  devices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    deviceName
    mappingDeviceFirmwareDevice {
      id
      deviceFirmware {
        id
        firmwareVersion
      }
    }
    mappingDeviceConfigurationDevice(order: {dateTo: DESC}) {
      id
      dateFrom
      dateTo
      deviceConfiguration {
        id
        configurationJSON
      }
    }
    mappingDeviceTag {
      id
      tag {
        id
        tagValue
      }
    }
  }
}
    `;

/**
 * __useGetDevicesForConfigurationOvertakeQuery__
 *
 * To run a query within a React component, call `useGetDevicesForConfigurationOvertakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesForConfigurationOvertakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesForConfigurationOvertakeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDevicesForConfigurationOvertakeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesForConfigurationOvertakeQuery, GetDevicesForConfigurationOvertakeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesForConfigurationOvertakeQuery, GetDevicesForConfigurationOvertakeQueryVariables>(GetDevicesForConfigurationOvertakeDocument, options);
      }
export function useGetDevicesForConfigurationOvertakeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesForConfigurationOvertakeQuery, GetDevicesForConfigurationOvertakeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesForConfigurationOvertakeQuery, GetDevicesForConfigurationOvertakeQueryVariables>(GetDevicesForConfigurationOvertakeDocument, options);
        }
export type GetDevicesForConfigurationOvertakeQueryHookResult = ReturnType<typeof useGetDevicesForConfigurationOvertakeQuery>;
export type GetDevicesForConfigurationOvertakeLazyQueryHookResult = ReturnType<typeof useGetDevicesForConfigurationOvertakeLazyQuery>;
export type GetDevicesForConfigurationOvertakeQueryResult = Apollo.QueryResult<GetDevicesForConfigurationOvertakeQuery, GetDevicesForConfigurationOvertakeQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
