import { styled, Typography } from "@mui/material";
import clusterPinMarker from "components/atomics/markers/clusterPinMarker";
import pinMarker from "components/atomics/markers/pinMarker";
import MapWrapper from "components/molecules/MapWrapper";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

const MarkerTooltipSC = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
`
);

const zoom: number = 10;

export interface Coordinates {
  position: [number, number];
  tooltipItems?: { title: string; value?: string } | undefined;
}

const GroupAssetsMap: React.FC<{ currentPositionsData: Coordinates[] }> = ({ currentPositionsData }) => {
  return (
    <MapWrapper
      height="40vh"
      sx={{ marginTop: "2vh" }}
      coordinates={currentPositionsData?.[0].position}
      scrollWheelZoom
      zoomControl
      zoom={zoom}
    >
      <MarkerClusterGroup iconCreateFunction={(cluster: any) => clusterPinMarker(cluster)}>
        {currentPositionsData?.map((positionItem) => {
          return (
            <>
              <Marker icon={pinMarker} position={positionItem.position}>
                {positionItem.tooltipItems && (
                  <Popup>
                    <MarkerTooltipSC>
                      <Typography key={positionItem.tooltipItems?.title} variant="copyBold">
                        {positionItem.tooltipItems?.title}
                      </Typography>
                      {positionItem.tooltipItems?.value && (
                        <Typography key={positionItem.tooltipItems?.value} variant="copy">
                          {positionItem.tooltipItems?.value}
                        </Typography>
                      )}
                    </MarkerTooltipSC>
                  </Popup>
                )}
              </Marker>
            </>
          );
        })}
      </MarkerClusterGroup>
    </MapWrapper>
  );
};

export default GroupAssetsMap;
