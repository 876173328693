import useQueryBuilder from "components/templates/QueryBuilder";
import { middleWareMappings } from "./middleware";

const useConfigQueryBuilder = ({
  entityName,
  queryStringsReturnValue,
}: {
  entityName: string;
  queryStringsReturnValue?: string;
}) => {
  const queryBuilderId = `${entityName}GroupsQueryBuilder`;

  const {
    executeQuery,
    queryStrings,
    setQueryStrings,
    clearQueryStates,
    QueryBuilderComponent,
    executionResult,
  } = useQueryBuilder({
    entityName,
    queryBuilderId,
    fieldMiddleWares: middleWareMappings[entityName].fieldMiddleWares,
    queryMiddleWares: middleWareMappings[entityName].queryMiddleWares,
    queryStringsReturnValue: queryStringsReturnValue ?? "id",
  });
  return {
    executeQuery,
    queryStrings,
    setQueryStrings,
    QueryBuilderComponent,
    clearQueryStates,
    queryBuilderId,
    executionResult,
  };
};

export default useConfigQueryBuilder;
