import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceIdsOnSupplierValueQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetDeviceIdsOnSupplierValueQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, deviceId: string, asset: { __typename?: 'Asset', id: string, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, supplier?: Maybe<string> }> } }> };


export const GetDeviceIdsOnSupplierValueDocument = gql`
    query getDeviceIdsOnSupplierValue($where: AssetDeviceFilterInput) {
  assetDevices(where: $where) {
    id
    deviceId
    asset {
      id
      assetMasterData {
        id
        supplier
      }
    }
  }
}
    `;

/**
 * __useGetDeviceIdsOnSupplierValueQuery__
 *
 * To run a query within a React component, call `useGetDeviceIdsOnSupplierValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceIdsOnSupplierValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceIdsOnSupplierValueQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDeviceIdsOnSupplierValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceIdsOnSupplierValueQuery, GetDeviceIdsOnSupplierValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceIdsOnSupplierValueQuery, GetDeviceIdsOnSupplierValueQueryVariables>(GetDeviceIdsOnSupplierValueDocument, options);
      }
export function useGetDeviceIdsOnSupplierValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceIdsOnSupplierValueQuery, GetDeviceIdsOnSupplierValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceIdsOnSupplierValueQuery, GetDeviceIdsOnSupplierValueQueryVariables>(GetDeviceIdsOnSupplierValueDocument, options);
        }
export type GetDeviceIdsOnSupplierValueQueryHookResult = ReturnType<typeof useGetDeviceIdsOnSupplierValueQuery>;
export type GetDeviceIdsOnSupplierValueLazyQueryHookResult = ReturnType<typeof useGetDeviceIdsOnSupplierValueLazyQuery>;
export type GetDeviceIdsOnSupplierValueQueryResult = Apollo.QueryResult<GetDeviceIdsOnSupplierValueQuery, GetDeviceIdsOnSupplierValueQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
