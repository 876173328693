import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLSearchboxProps = DropOnONLh<JSX.PatternlibSearchbox>;
export type PLSearchboxExtendedProps = PLSearchboxProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedSearchbox = wrapWc<JSX.PatternlibSearchbox>("patternlib-searchbox");
export const useRefPLSearchbox = () => useRef<HTMLPatternlibSearchboxElement>(null);

const PLSearchbox = React.forwardRef<HTMLPatternlibSearchboxElement, PLSearchboxExtendedProps>((props, ref) => {
  const myRef = useRefPLSearchbox();
  return (
    <WrappedSearchbox ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedSearchbox>
  );
});
PLSearchbox.displayName = "PLSearchbox";

export default PLSearchbox;
