import { Checkbox, styled, Typography, IconButton } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid-pro";
import { Column } from "components/templates/dataGridTable/serverSide/types";
import { useGetRoleNameAndDescriptionQuery } from "graphqlBase/Role/__generated__/getRoleNameAndDescription";
import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { mappingRoleUsersLoading, drawerAtom, mappingRoleUserSelector } from "../RecoilState/mappingUserRoleState";
import { GetUsersForRoleMappingQuery, ResultType } from "graphqlBase/Settings/__generated__/getUsersForRoleMapping";

export type User = ResultType<GetUsersForRoleMappingQuery["users"]>;

const iconButton = {
  backgroundColor: "transparent",
  "&:hover": { backgroundColor: "transparent" },
  "& svg": {
    height: "1em",
    width: "1em",
    "&:active": { backgroundColor: "transparent" },
  },
  width: "2em",
  height: "2em",
  right: 0,
  top: 0,
};

const roleTitleStyles = { display: "block", fontWeight: "700", fontSize: 14 };

const RoleCheckbox = ({ roleId, params }: { params: GridRenderCellParams<string, User>; roleId: string }) => {
  const userId = params.row.id;
  const state = mappingRoleUserSelector({ roleId, userId });
  const [checkedState, setCheckedState] = useRecoilState(state);
  const handleChange = () => {
    setCheckedState((prev) => !prev);
  };

  return <Checkbox checked={checkedState} onChange={handleChange} />;
};

const useGetUserRoleColumns = (users: User[] | undefined) => {
  const setDrawerState = useSetRecoilState(drawerAtom);
  const { data: rolesData, loading: loadingRoles } = useGetRoleNameAndDescriptionQuery({
    fetchPolicy: "cache-and-network",
  });
  const setRoleUsersLoading = useSetRecoilState(mappingRoleUsersLoading);
  const [roleColumns, setRoleColumns] = useState<Column<User, "User">[] | undefined>();

  useEffect(() => {
    setRoleUsersLoading(loadingRoles || !roleColumns);
  }, [loadingRoles, roleColumns, setRoleUsersLoading]);

  useEffect(() => {
    if (!rolesData || !users) return;
    setRoleColumns(
      rolesData?.roles?.reduce<Column<User, "User">[]>((columns, current) => {
        const column = {
          field: current?.name ?? "",
          minWidth: 150,
          maxWidth: 350,
          resizable: true,
          headerAlign: "center",
          align: "center",
          // eslint-disable-next-line react/display-name
          renderHeader: () => (
            <Typography sx={roleTitleStyles}>
              {current?.name}
              <IconButton
                sx={iconButton}
                size="small"
                onClick={() => setDrawerState({ id: current.id, name: current.name, description: current.description })}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Typography>
          ),
          type: "ReactElement",
          valueType: "ReactElement",
          // eslint-disable-next-line react/display-name
          renderCell: (params: GridRenderCellParams<string, User>) => (
            <RoleCheckbox roleId={current.id} params={params} />
          ),
        };
        //@ts-ignore
        return columns.concat(column);
      }, [])
    );
  }, [rolesData, setDrawerState, users]);

  return { roles: roleColumns ?? [] };
};

export default useGetUserRoleColumns;
