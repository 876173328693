export default {
  name: "device model",
  plural: "device models",
  columns: {
    device: "Device",
    deviceModelAttributeSet: "Device Model Attribute Set",
    deviceModelName: "Device Model Name",
    deviceModelVersion: "Device Model Version",
    fieldDeviceIdFieldSelector: "field device ID field selector",
    firmwareVersion: "Firmware Version",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
  },
};
