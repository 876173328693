import client from "apollo/client";
import {
  PersistNotificationNestedsDocument,
  PersistNotificationNestedsMutation,
  PersistNotificationNestedsMutationVariables,
} from "graphqlBase/Mixed/__generated__/saveNotificationNesteds";
import { useGetNotificationsCallback } from "./useGetNotificationsCallback";

const usePersistNestedData = () => {
  const getNotifcationNestedData = useGetNotificationsCallback();

  const getData = async (notificationId: string): Promise<PersistNotificationNestedsMutationVariables> => {
    const { mappingDeviceNotificationsList, mappingUserNotificationsList } = await getNotifcationNestedData();
    const deleteMappingDeviceNotifications = mappingDeviceNotificationsList.reduce<
      PersistNotificationNestedsMutationVariables["deleteMappingDeviceNotifications"]
    >((deleteMappingDeviceNotifications, { toDelete, id }) => {
      if (!toDelete || !id) return deleteMappingDeviceNotifications;
      return deleteMappingDeviceNotifications.concat(id);
    }, []);

    const deleteMappingNotificationUsers = mappingUserNotificationsList.reduce<
      PersistNotificationNestedsMutationVariables["deleteMappingNotificationUsers"]
    >((deleteMappingNotificationUsers, { toDelete, id }) => {
      if (!toDelete || !id) return deleteMappingNotificationUsers;
      return deleteMappingNotificationUsers.concat(id);
    }, []);

    const mappingNotificationUsers = mappingUserNotificationsList.reduce<
      PersistNotificationNestedsMutationVariables["mappingNotificationUsers"]
    >((mappingNotificationUsers, { id, ...mappingNotificationUser }) => {
      if (id) return mappingNotificationUsers;
      if (Array.isArray(mappingNotificationUsers))
        return (mappingNotificationUsers ?? [])?.concat({ userId: mappingNotificationUser.userId, notificationId });
    }, []);

    const mappingDeviceNotifications = (mappingDeviceNotificationsList ?? []).reduce<
      PersistNotificationNestedsMutationVariables["mappingDeviceNotifications"]
    >((mappingDeviceNotifications, { id, ...mappingDeviceNotification }) => {
      if (id) return mappingDeviceNotifications;
      if (Array.isArray(mappingDeviceNotifications))
        return mappingDeviceNotifications.concat({ deviceId: mappingDeviceNotification.deviceId, notificationId });
    }, []);
    return {
      deleteMappingDeviceNotifications,
      mappingDeviceNotifications,
      mappingNotificationUsers,
      deleteMappingNotificationUsers,
    };
  };

  return async (notificationId: string) => {
    const variables: PersistNotificationNestedsMutationVariables = await getData(notificationId);
    return await client.mutate<PersistNotificationNestedsMutation, PersistNotificationNestedsMutationVariables>({
      mutation: PersistNotificationNestedsDocument,
      variables,
    });
  };
};
export default usePersistNestedData;
