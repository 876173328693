import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toast from "components/atomics/Toast";
import { toastStateAtom } from "components/atomics/Toast/toastState";
import PLModal from "components/patternLib/PLModal";
import { useAssetDeviceUnassignmentMutation } from "graphqlBase/AssetDevices/__generated__/assetDeviceUnassignment";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import translations from "translations";
import { assetDeviceUnpairingAtom, assetDeviceUnpairingModalStateAtom } from "./recoilState";

const AssetDeviceUnpairing: React.FC<{
  afterMutation: () => Promise<void>;
}> = ({ afterMutation }) => {
  const assetdeviceUnpairState = useRecoilValue(assetDeviceUnpairingAtom);
  const [toastObject, setToastObject] = useRecoilState(toastStateAtom);
  const [isModalOpen, setIsModalOpen] = useRecoilState(assetDeviceUnpairingModalStateAtom);
  const [assetDeviceUnassignment] = useAssetDeviceUnassignmentMutation();

  const unpairAssetDevice = async () => {
    if (!assetdeviceUnpairState) return;

    const { variables } = assetdeviceUnpairState;
    const { data } = await assetDeviceUnassignment({
      variables,
    });

    if (data?.unpairingAssetDevice?.status === false) {
      setToastObject({
        open: true,
        message: translations.pages.assetManagement.assetDeviceUAssignmentConfirmationModal.errorMessage,
        severity: "error",
      });
    }
    setToastObject({
      open: true,
      message: translations.pages.assetManagement.assetDeviceUAssignmentConfirmationModal.toastMessage,
      severity: "success",
    });
    await afterMutation();

    return data;
  };
  const handleCloseToast = () => {
    setToastObject(undefined);
  };
  return (
    <>
      <PLModal
        showIcon
        allowCloseOnBackdrop
        modalTitle={translations.pages.assetManagement.assetDeviceUAssignmentConfirmationModal.title}
        lhCancel={() => setIsModalOpen(false)}
        lhConfirm={async () => {
          !!assetdeviceUnpairState && (await unpairAssetDevice());
          setToastObject(undefined);
          setIsModalOpen(false);
        }}
        lhClose={() => setIsModalOpen(false)}
        show={isModalOpen}
        iconName="compare"
      >
        <>
          <Box slot="modal-content">
            {!!assetdeviceUnpairState && (
              <Typography variant="copy">
                {
                  translations.pages.assetManagement.assetDeviceUAssignmentConfirmationModal
                    .assignmentConfirmationMessageBeforeDeviceName
                }{" "}
                {assetdeviceUnpairState.deviceName}
                {" from "} {assetdeviceUnpairState.assetName} {"? "}
                {
                  translations.pages.assetManagement.assetDeviceUAssignmentConfirmationModal
                    .assignmentConfirmationMessageAfterDeviceName
                }
              </Typography>
            )}
          </Box>
        </>
      </PLModal>

      {toastObject && (
        <Toast
          message={toastObject.message}
          open={toastObject.open}
          severity={toastObject.severity}
          handleCloseToast={handleCloseToast}
        />
      )}
    </>
  );
};

export default AssetDeviceUnpairing;
