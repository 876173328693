import validate from "validate.js";

type Validator = (value: any, options: {}, key: string, attribute: any) => string | void;

const isEmpty: Validator = (value, options, key, attributes) => {
  // console.log("isNot2", value, options, value !== options);
  // console.log(value, "is not empty");
  if (typeof value === "undefined" || value === null) return;

  return "is not empty";
};

const valueIs: Validator = (value, options, key, attributes) => {
  if (value == options) return;
  return `is not equal to ${options}`;
};

const valueIsNot: Validator = (value, options, key, attributes) => {
  if (value != options) return;
  return `is not unequal to ${options}`;
};
// typeof(value) === "undefined" || value === null
// return
// return 'is not empty'
validate.validators.isEmpty = isEmpty;
validate.validators.valueIs = valueIs;
validate.validators.valueIsNot = valueIsNot;
//@ts-ignore
//validate.options = { format: "flat", cleanAttributes: false };
// const isEmpty2: Validator = (value, options, key, attributes) => {
//   typeof value === "undefined" || value === null;
//   return;
//   return "is not empty";
// };

// Generated by https://quicktype.io

export interface HideType {
  Hide: Hide;
}

export interface Hide {
  Hide: "Yes" | "No" | "By Rules";
  Constraints_Hide: ConstraintsHide;
}
export interface Validate {
  Validate: "Yes" | "No" | "By Rules";
  Constraints_Validate: ConstraintsHide;
}

export interface Disabled {
  disabled: "Inherit" | "Yes" | "No" | "By Rules";
  Constraints_disabled: ConstraintsHide;
}

export interface Rules {
  [key: string]: any;
}

export interface AllSingle {
  not?: Not;
  rules?: Rules;
}

type All = Array<Not | Rules[]>;

export interface Not {
  not: {
    all?: All;
    rules?: Rules[];
  };
}

type ConstraintsHide = { all: All } | { or: All } | Rules[] | Not;

const transFormRules = (rules: Rules[]) => {
  const constraints: any = {};
  rules.forEach((rule) => {
    const { field, ...rest } = rule;
    constraints[field] = { ...constraints[field], ...rest };
  });
  // console.log(constraints);
  return constraints;
};

const checkRules = (constraints: Rules[], data: any, getErrorsFull: boolean) => {
  const transformedRules = transFormRules(constraints);
  const checks = getErrorsFull ? validate(data, transformedRules) : !!!validate(data, transformedRules);

  // console.log(transformedRules, checks, validate(data, transformedRules), data);
  return checks;
};

const walkConstraints = (constraints: ConstraintsHide, data: any, getErrorsFull: boolean = false): any => {
  if (Array.isArray(constraints)) {
    return checkRules(constraints, data, getErrorsFull);
  }

  if (constraints.hasOwnProperty("all")) {
    return (constraints as { all: All }).all.every((entry) => walkConstraints(entry, data, getErrorsFull));
  }

  if (constraints.hasOwnProperty("or")) {
    return (constraints as { or: All }).or.some((entry) => {
      return walkConstraints(entry, data, getErrorsFull);
    });
  }

  if (constraints.hasOwnProperty("not")) {
    const constraintsNot = (constraints as Not).not;
    const res = Object.entries(constraintsNot).map(([key, valueIn]) => {
      const value = valueIn as All | Rules[];
      if (!value) {
        return undefined;
      }

      if (key === "rules") {
        const rr = checkRules(value, data, getErrorsFull);
        return rr;
      }
      return walkConstraints({ all: value as All }, data, getErrorsFull);
    });
    return res;
  }
};

export default (constraintsPre: Hide, data: any) => {
  //  console.log(constraintsPre.Constraints_Hide, data);
  if (constraintsPre.Hide !== "By Rules") {
    return constraintsPre.Hide === "Yes";
  }
  // return false;
  // console.log(constraintsPre.Constraints_Hide);
  const res = walkConstraints(constraintsPre.Constraints_Hide, data);

  return res;
};
export const validateForm = (constraintsPre: Validate, data: any) => {
  // console.log(constraintsPre.Constraints_Validate, data);
  if (constraintsPre.Validate !== "By Rules") {
    return constraintsPre.Validate === "Yes";
  }
  // return false;
  // console.log(constraintsPre.Constraints_Hide);
  const res = walkConstraints(constraintsPre.Constraints_Validate, data, true);

  return res;
};

export const isDisabled = (constraintsPre: Disabled, data: any) => {
  if (constraintsPre.disabled === "Inherit") {
    return undefined;
  }

  if (constraintsPre.disabled !== "By Rules") {
    return constraintsPre.disabled === "Yes";
  }
  // console.log(constraintsPre.Constraints_disabled);
  const res = walkConstraints(constraintsPre.Constraints_disabled, data);

  return res;
};
