import { assetDeviceApplicationState, lhbDeviceState } from "components/pages/AssetManagement/utils";
import { GetDeviceForDeviceDetailsQuery } from "graphqlBase/Devices/__generated__/getDeviceForDeviceDetails";
import React from "react";
import translations from "translations";
import { formatTimeDistance } from "translations/formatter";
import DetailInfo from "components/atomics/DetailInfo";
import { Divider, Grid, Link, styled, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import PLTextlink from "components/patternLib/PLTextlink";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { isFuture } from "date-fns";

interface TrackerProps {
  deviceDetailsData: GetDeviceForDeviceDetailsQuery | undefined;
  lastTimeConnection: {
    key: string;
    value: string;
  };
  loading: boolean;
}
export interface ArrayValue {
  label: string;
  value: string;
}

const { batteryVoltage, Temperature, Humidity, appState } = translations.entities.capability.capabilityNames;
const TrackerLargeSmall: React.FC<TrackerProps> = ({ lastTimeConnection, deviceDetailsData, loading }) => {
  const deviceFirmware = deviceDetailsData?.device?.mappingDeviceFirmwareDevice.map(
    (e) => e.deviceFirmware.firmwareVersion
  );

  const getCurrentValue = (capabilityIdentifier: string, numberToFixed?: number) => {
    return deviceDetailsData?.device?.sensorMeasurementCurrentValue
      ?.find((val) => val?.capability?.capabilityName.includes(capabilityIdentifier))
      ?.valueFloat?.toFixed(numberToFixed);
  };

  const getCurrentState = () => {
    const currentValue = parseInt(getCurrentValue("State") ?? "") ?? 0;

    const hasAsset = deviceDetailsData?.device?.assetDevice;

    const isCrane =
      hasAsset && deviceDetailsData?.device?.assetDevice?.[0]?.asset.assetSource?.assetSourceName.includes("CRANE");

    const assetState = isCrane ? assetDeviceApplicationState[currentValue] : lhbDeviceState[currentValue];

    return !hasAsset ? "" : `${assetState?.label ?? ""}${assetState?.extendedLabel ?? ""}`;
  };

  const trackerInfo: ArrayValue[] = [
    {
      label: translations.entities.deviceType.columns.deviceTypeName,
      value: deviceDetailsData?.device?.deviceType.deviceTypeName ?? "",
    },
    {
      label: "Firmware",
      value: deviceFirmware?.length ? deviceFirmware[0] : "",
    },
    {
      label: batteryVoltage,
      value: getCurrentValue("Battery", 3) ? `${getCurrentValue("Battery", 3)} V` : "",
    },
    {
      label: Temperature,
      value: getCurrentValue("Temperature", 1) ? `${getCurrentValue("Temperature", 1)} °C` : "",
    },
    {
      label: Humidity,
      value: getCurrentValue("Humidity", 1) ? `${getCurrentValue("Humidity", 1)} %` : "",
    },
    {
      label: appState,
      value: getCurrentState(),
    },
    {
      label: "Last Connection",
      value: lastTimeConnection ? formatTimeDistance(new Date(lastTimeConnection.value)) : "",
    },
  ];

  return (
    <LoadingSpinner sx={{ height: "fit-content" }} loading={loading}>
      <DetailInfo data={trackerInfo} itemHeight="30px" />
    </LoadingSpinner>
  );
};

export default TrackerLargeSmall;
