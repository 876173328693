import {
  MappingUserNotification,
  SelectedUser,
  preMappedUserNotificationsAtom,
  selectedUsersForAlertAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import {
  useGetMappingUserNotificationsForNotificationLazyQuery,
  useGetMappingUserNotificationsForNotificationQuery,
} from "graphqlBase/MappingUserNotifications/__generated__/getMappingUserNotificationsForNotification";
import { useGetUserEmailsForCreateUpdateNotificationsLazyQuery } from "graphqlBase/Notifications/__generated__/getUserEmailsForCreateUpdateNotifications";
import { SortEnumType } from "graphqlBase/types";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

export default (notificationId?: string) => {
  const [selectedUsers, setSelectedUsers] = useRecoilState(selectedUsersForAlertAtom);
  const setPreMappedUserNotifications = useSetRecoilState(preMappedUserNotificationsAtom);

  const [getPreMappedUsers, { data, loading }] = useGetMappingUserNotificationsForNotificationLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [
    getAllUsers,
    { data: usersData, loading: usersLoading },
  ] = useGetUserEmailsForCreateUpdateNotificationsLazyQuery({
    variables: { take: 8, order:{email:"ASC" as SortEnumType} },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!notificationId) return;
    getPreMappedUsers({
      variables: {
        notificationMappingFilter: { notificationId: { eq: notificationId } },
      },
    });
  }, [notificationId]);

  useEffect(() => {
    if (usersData || usersLoading) return;
    getAllUsers();
  }, [usersLoading]);

  // set pre-selected users on component mount
  const getPreSelectedUsers = () => {
    if (!data?.mappingNotificationUsers || selectedUsers) return;
    const preSelected = (data?.mappingNotificationUsers ?? []).reduce<SelectedUser[]>((acc, current) => {
      const { label, value } = current.user;
      return acc.concat({ label: label ?? "", value, toDelete: false });
    }, []);

    const preMappedUserNotifications = data?.mappingNotificationUsers?.reduce<MappingUserNotification[]>(
      (acc, current) => {
        return acc.concat({ id: current.id, userId: current.user.value, notificationId });
      },
      []
    );
    setPreMappedUserNotifications(preMappedUserNotifications);
    if (preSelected?.length) setSelectedUsers(preSelected);
  };

  return { loading, getPreSelectedUsers, usersData, getAllUsers };
};
