import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllStorageAreasQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllStorageAreasQuery = { storageAreas: Array<{ __typename?: 'StorageArea', id: string, storageAreaName: string, storageAreaType: { __typename?: 'StorageAreaType', id: string, storageAreaTypeName: string, storageAreaTypeColor?: Maybe<string> }, geofence?: Maybe<{ __typename?: 'GeoJSONPolygonType', coordinates?: Maybe<Array<Maybe<Array<Maybe<[number,number]>>>>>, type: Types.GeoJsonGeometryType, bbox: Array<number> }> }> };


export const GetAllStorageAreasDocument = gql`
    query getAllStorageAreas {
  storageAreas {
    id
    storageAreaName
    storageAreaType {
      id
      storageAreaTypeName
      storageAreaTypeColor
    }
    geofence {
      coordinates
      type
      bbox
    }
  }
}
    `;

/**
 * __useGetAllStorageAreasQuery__
 *
 * To run a query within a React component, call `useGetAllStorageAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStorageAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStorageAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStorageAreasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllStorageAreasQuery, GetAllStorageAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllStorageAreasQuery, GetAllStorageAreasQueryVariables>(GetAllStorageAreasDocument, options);
      }
export function useGetAllStorageAreasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllStorageAreasQuery, GetAllStorageAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllStorageAreasQuery, GetAllStorageAreasQueryVariables>(GetAllStorageAreasDocument, options);
        }
export type GetAllStorageAreasQueryHookResult = ReturnType<typeof useGetAllStorageAreasQuery>;
export type GetAllStorageAreasLazyQueryHookResult = ReturnType<typeof useGetAllStorageAreasLazyQuery>;
export type GetAllStorageAreasQueryResult = Apollo.QueryResult<GetAllStorageAreasQuery, GetAllStorageAreasQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
