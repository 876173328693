import { Box, Divider, styled, Typography } from "@mui/material";
import { RightTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import PLIcon from "components/patternLib/PLIcon";
import React from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
import useGetCouplings from "./useGetCouplings";
export interface CoupledAsset {
  assetName: string;
  couplingDuration: string;
  iconName: string;
  assetId: string;
}

const CoupledItemSC = styled("div")(
  () => `
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  align-items: center;
  cursor: pointer;
`
);

const CoupledItemRowSC = styled("div")(
  () => `
  display: flex;
   flex-direction: column;
    margin-left: 3em;
`
);

const CoupledTimeBoxSC = styled("div")(
  () => `
display: flex;
 flex-direction: row;
  align-items: center;
`
);

const CouplingTimeDotSC = styled("div")(
  () => `
  margin-right: 1em;
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: var(--color-leaf-dark);
`
);

const titleBoxStyles = { marginBottom: "40px" };
const dividerStyles = { marginBottom: "20px" };

export interface CurrentCouplingsTileProps {
  deviceId: string;
  isExcavator: boolean;
}

const CurrentCouplingsTile: React.FC<CurrentCouplingsTileProps> = ({ deviceId, isExcavator }) => {
  const history = useHistory();

  const { coupledAssets, resetCoupledAsset } = useGetCouplings({ deviceId, isExcavator });

  const goToCoupledAsset = (assetId: string) => {
    setTimeout(() => {
      history.push(`/assetManagement/assetDetails/${assetId}`);
    }, 1);
    resetCoupledAsset();
  };

  return !coupledAssets.length ? null : (
    <RightTileWrapperSC>
      <Box sx={titleBoxStyles}>
        <Typography variant="h4">
          {translations.pages.assetManagement.assetDetails.currentlyCoupledTile.label}
        </Typography>
      </Box>
      {coupledAssets.map((coupledItem) => {
        return (
          <>
            <CoupledItemSC key={coupledItem?.assetName} onClick={() => goToCoupledAsset(coupledItem?.assetId ?? "")}>
              <PLIcon size="45px" iconName={coupledItem?.iconName} />
              <CoupledItemRowSC>
                <Typography variant="copyBold">{coupledItem?.assetName}</Typography>
                <CoupledTimeBoxSC>
                  <CouplingTimeDotSC />
                  <Typography color="var(--color-leaf-dark)" variant="copy">
                    {coupledItem?.couplingDuration}
                  </Typography>
                </CoupledTimeBoxSC>
              </CoupledItemRowSC>
            </CoupledItemSC>
            <Divider sx={dividerStyles} />
          </>
        );
      })}
    </RightTileWrapperSC>
  );
};
export default CurrentCouplingsTile;
