export default {
  name: "capability",
  plural: " capabilities",
  columns: {
    capabilityName: "capability name",
    deviceCapability: "device capability",
    deviceModelCapability: "device model capability",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
  },
  capabilityNames: {
    accX: "Acceleration X-axis",
    accY: "Acceleration Y-axis",
    accZ: "Acceleration Z-axis",
    Altitude: "Altitude",
    "Device Zustand": "Device State",
    Direction: "Direction",
    Humidity: "Humidity",
    Latitude: "Latitude",
    Level: "Level",
    Longitude: "Longitude",
    Message: "Message",
    n_sats: "Number of Satellites",
    Temperature: "Temperature",
    Timestamp: "Timestamp",
    utcTimeMeasured: "Last Connection",
    v: "Version",
    batteryVoltage: "Battery Voltage",
    typeOfAttachment: "Type",
    model: "Model",
    yearOfProduction: "Year of Production",
    appState: "Current State",
    location: "GPS Position",
  },
};
