import { assetCreateFormErrorsAtom } from "components/pages/AssetManagement/AssetCreate/assetCreateFormState";
import PLButton from "components/patternLib/PLButton";
import * as React from "react";
import { useRecoilState } from "recoil";
import Props from "./index.d";

const SubmitButtonForAssetManagement: React.FC<Props> = (props) => {
  const { testId, errors } = props;

  const [formHasErrors, setFormHasErrors] = useRecoilState(assetCreateFormErrorsAtom);
  const { setAndSave } = props.data;

  const handleSubmit = () => {
    if (formHasErrors) return;
    setAndSave(true);
  };

  return (
    <PLButton
      data-testid={testId}
      label={props.title}
      type="primary"
      onClick={handleSubmit}
      disabled={formHasErrors || !!errors?.length}
      width="auto"
    />
  );
};

export default SubmitButtonForAssetManagement;
