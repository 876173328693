import colors from "../colors";

export const muiAvatarOverrides = {
  root: {
    width: 32,
    height: 32,
  },
  colorDefault: {
    backgroundColor: colors.tBlack,
    color: colors.tWhite,
    borderRadius: "100%",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
    boxSizing: "border-box",
    display: "inline-flex",
    fontSize: 16,
    fontWeight: "bolder",
    textAlign: "center",
    height: 40,
    lineHeight: 1.2,
    width: 40,
  },
};
