import colors from "themes/colors";

export const muiListOverrides = {
  root: {
    fontFamily: "var(--copytext-font-family)",
    background: "transparent",
    "& .MuiMenuItem-root": {
      fontSize: 16,
      height: "3em",
      padding: "11.5px 9px",
      color: colors.tBlack,
      cursor: "default",
      background: "transparent",
      "&.Mui-selected": {
        background: "var(--color-steel-100)",
        "&:hover": { backgroundColor: "var(--color-steel-100)" },
      },
      "&:hover": {
        backgroundColor: "var(--color-steel-100)",
      },
    },
  },
};
