import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { dateRangeState, filtersSelector, RecoilFilterType } from "./charts/filtersState";
import { addYears, formatISO, parseISO } from "date-fns";
import translations from "translations";
import PLDatepicker from "components/patternLib/form/PLDatepicker";

const DATE_TODAY = parseISO(new Date().toISOString());
const DATE_YEAR_AGO = addYears(DATE_TODAY, -1);

export const DateRangeForCube = () => {
  const [selectedDate, setSelectedDate] = useRecoilState(dateRangeState);

  const setFilter = useSetRecoilState(filtersSelector);

  const onChangeListener = (event: any) => {
    if (event.detail.value && event.detail.value.start !== event.detail.value.end) {
      const newStartDate = new Date(event.detail.value.start);
      const newEndDate = new Date(event.detail.value.end);

      const existingStartDate = selectedDate ? new Date(selectedDate?.start) : new Date();
      const existingEndDate = selectedDate ? new Date(selectedDate?.end) : new Date();

      if ((newStartDate !== existingStartDate || newEndDate !== existingEndDate) && newStartDate !== newEndDate) {
        setSelectedDate({
          start: newStartDate,
          end: newEndDate,
        });
      }
    }
  };

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate({ start: DATE_YEAR_AGO, end: DATE_TODAY });
    }
    if (selectedDate) {
      const dateForFiltering = [formatISO(selectedDate.start), formatISO(selectedDate?.end)];

      const filter: RecoilFilterType = {
        member: "DimDate.date",
        operator: "inDateRange",
        values: dateForFiltering,
      };
      setFilter([filter]);
    }
  }, [selectedDate]);

  return (
    <PLDatepicker dateTimeFormat="de-DE" dateRange lhChange={onChangeListener} width="300px" date={selectedDate} />
  );
};
