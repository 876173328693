import { styled } from "@mui/material";

export const CarouselButtons = styled("div")(
  () => `
      display: flex;
      margin-bottom: 20px;
  `
);

export const NoImagesBoxSC = styled("div")(
  () => `
   height: 300px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #fff;
  `
);
