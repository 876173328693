import { styled, Typography } from "@mui/material";
import React from "react";

const TableTitleSC = styled(Typography)({
  marginBottom: "20px",
});

export const TableTitle = (title: string) => {
  return <TableTitleSC variant="h6">{title}</TableTitleSC>;
};
