import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationScopesForAlertsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.NotificationScopeFilterInput>;
}>;


export type GetNotificationScopesForAlertsQuery = { notificationScopes: Array<{ __typename?: 'NotificationScope', id: string, scopeName?: Maybe<string>, unitSymbol: string, mappingNotificationScopeNotificationOperator: Array<{ __typename?: 'MappingNotificationScopeNotificationOperator', id: string, notificationOperator: { __typename?: 'NotificationOperator', id: string, operator: string } }> }> };


export const GetNotificationScopesForAlertsDocument = gql`
    query getNotificationScopesForAlerts($where: NotificationScopeFilterInput) {
  notificationScopes(where: $where) {
    id
    scopeName
    unitSymbol
    mappingNotificationScopeNotificationOperator {
      id
      notificationOperator {
        id
        operator
      }
    }
  }
}
    `;

/**
 * __useGetNotificationScopesForAlertsQuery__
 *
 * To run a query within a React component, call `useGetNotificationScopesForAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationScopesForAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationScopesForAlertsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNotificationScopesForAlertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationScopesForAlertsQuery, GetNotificationScopesForAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNotificationScopesForAlertsQuery, GetNotificationScopesForAlertsQueryVariables>(GetNotificationScopesForAlertsDocument, options);
      }
export function useGetNotificationScopesForAlertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationScopesForAlertsQuery, GetNotificationScopesForAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNotificationScopesForAlertsQuery, GetNotificationScopesForAlertsQueryVariables>(GetNotificationScopesForAlertsDocument, options);
        }
export type GetNotificationScopesForAlertsQueryHookResult = ReturnType<typeof useGetNotificationScopesForAlertsQuery>;
export type GetNotificationScopesForAlertsLazyQueryHookResult = ReturnType<typeof useGetNotificationScopesForAlertsLazyQuery>;
export type GetNotificationScopesForAlertsQueryResult = Apollo.QueryResult<GetNotificationScopesForAlertsQuery, GetNotificationScopesForAlertsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
