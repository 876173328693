import { SelectItem } from "components/molecules/SelectFreeSolo";
import { useGetStorageAreasForListLazyQuery } from "graphqlBase/StorageAreas/__generated__/getStorageAreasForList";
import { useEffect, useState } from "react";

const useManageLocationScopes = () => {
  const [options, setOptions] = useState<SelectItem[] | undefined>();
  const [uiOptions, setUiOptions] = useState<SelectItem[] | undefined>();

  const [fetchStorageAreas, { data, loading }] = useGetStorageAreasForListLazyQuery({
    variables: { take: 8 },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (loading) return;
    if (!data) fetchStorageAreas();
    else {
      setOptions((prevOptions) => {
        const newOptions = data?.storageAreas.reduce<SelectItem[]>((acc, current) => {
          if (prevOptions?.find((option) => option.label === current.storageAreaName)) return acc;
          return acc.concat({
            label: current?.storageAreaName,
            value: current?.id,
          });
        }, []);
        const combinedOptions = (prevOptions ?? [])?.concat(newOptions);
        return combinedOptions?.length > 8 ? combinedOptions.splice(0, 7) : combinedOptions;
      });
    }
  }, [data, loading]);

  return { fetchStorageAreas, options, uiOptions, setUiOptions };
};

export default useManageLocationScopes;
