import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Input, InputProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import Props from "./index.d";
import DefaultInput, { DefaultInputProps } from "components/atomics/DefaultInput";
import tableColors from "components/templates/table-factory/theme/tableColors";

interface DecimalEditorPropsInner {
  inputProps?: InputProps;
}

// interface DefaultInputProps extends InputProps {}

const useStyles = makeStyles<Theme, DefaultInputProps>(
  (theme) =>
    createStyles({
      decimalEditorRoot: {
        borderRadius: "4px",
        background: tableColors.lbcgrey,
        color: theme.colors.grey500,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        height: "40px",
        lineHeight: 1.2,
        letterSpacing: 0,
        width: "180px",
        "& :focus": {
          background: theme.colors.tWhite,
          border: `1px solid ${theme.colors.grey500}`,
          borderRadius: "4px",
          height: "40px",
        },
        "&:hover": {
          backgroundColor: theme.colors.grey200,
        },
        "& :disabled": {
          color: theme.colors.grey500,
          cursor: "not-allowed",
          background: theme.colors.tWhite,
          border: "1px solid rgba(154, 151, 143, 0.5)",
          borderRadius: "4px",
          height: "40px",
          width: "180px",
          "&:hover": {
            backgroundColor: theme.colors.tWhite,
          },
        },
        "&:before": {
          borderBottomStyle: "none",
          borderBottom: 0,
          content: "none",
          transition: "none",
        },
        "&:after": {
          border: "none",
        },
      },
      root: {
        display: "flex",
        flexDirection: "column",
        color: theme.colors.grey500,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.2,
        letterSpacing: 0,
        "& .MuiInputBase-input": {
          padding: 0,
          paddingLeft: "8px",
        },
      },
      titleBox: {
        margin: "0.2em 0.5em 0.5em 0.4em",
      },
    }),
  { name: "DecimalEditor" }
);

const DecimalEditorInner: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, onBlur },
    title,
    testId,
    ...rest
  } = props;

  const classes = useStyles(props);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value
      .replace(",", ".")
      .split(".")
      .map((v, i) => {
        if (v === "" || v === "undefined") return "";
        const num = v !== "" ? v.slice(0, i ? 5 : 15) : "0";
        if (isNaN(+num)) return "0";
        return num;
      })
      .slice(0, 2)
      .join(".");

    setstate((value as unknown) as number);
  };

  return (
    <DefaultInput
      testId={testId}
      className={classes.decimalEditorRoot}
      onBlur={onBlur}
      value={state ?? ""}
      onChange={handleChange}
      {...rest}
    />
  );
};

const DecimalEditor: React.FC<Props> = (props) => {
  const { title, testId } = props;
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.titleBox}>
        <Typography align="left" variant="inherit">
          {title}:
        </Typography>
      </div>
      <DecimalEditorInner {...props} />
      {(props.errors ?? []).map((e, index) => (
        <div className={classes.inputTitle}>
          <Typography key={index} align="left" variant="inherit">
            {e}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default DecimalEditor;
