import DetailList, { ArrayValue } from "components/atomics/DetailList";
import { GetDeviceFirmwaresQuery, ResultType } from "graphqlBase/DeviceFirmware/__generated__/getDeviceFirmwares";
import React, { useEffect, useState } from "react";
import DetailInfo from "components/atomics/DetailInfo";
import { LeftTileWrapperSC } from "../../utils/styles";
import FirmwareSelect from "./FirmwareSelect";
import { formatDate } from "translations/formatter";
import PLButton from "components/patternLib/PLButton";
import { useCreateMappingDeviceFirmwareDevicesMutation } from "graphqlBase/MappingDeviceFirmwareDevice/__generated__/createMappingDeviceConfigurationDevices";
import PLModal from "components/patternLib/PLModal";
import translations from "translations";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GetDevicesForDeviceConfigDocument } from "graphqlBase/Devices/__generated__/getDevicesForDeviceConfig";
import FirmwareOverview from "./FirmwareOverview";
import { styled } from "@mui/material";

export type DeviceFirmware = ResultType<GetDeviceFirmwaresQuery["deviceFirmwares"]>;

interface FirmwareUpdateProps {
  deviceId: string | undefined;
  initialFirmwareId: string | undefined;
}

const RevisionsDescriptionSC = styled("div")({ overflowY: "auto" });

const FirmwareSelectSC = styled("div")({});
const RevisionsInfoSC = styled("div")({});
const AvailableActualisationsSC = styled("div")(({ theme }) => ({
  marginTop: "30px",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "1200px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    maxHeight: "250px",
    gridAutoRows: "250px",
    gap: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    rowGap: "30px",
  },
}));

const PullbuttonSC = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "30px",
});

const FirmwareUpdate: React.FC<FirmwareUpdateProps> = ({ deviceId, initialFirmwareId }) => {
  const [currentFirmware, setCurrentFirmware] = useState<DeviceFirmware | null>(null);
  const [currentFirmwareId, setCurrentFirmwareId] = useState<string | undefined>(undefined);
  const [updateFirmwareMutation] = useCreateMappingDeviceFirmwareDevicesMutation({
    refetchQueries: [
      {
        query: GetDevicesForDeviceConfigDocument,
        variables: { where: { deviceId: { eq: deviceId } } },
      },
    ],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setCurrentFirmwareId(initialFirmwareId);
  }, [initialFirmwareId]);

  const selectedFirmwareInfo: ArrayValue[] = [
    {
      label: translations.pages.deviceManagement.firmwareUpdate.firmwareSelect.firmwareRevision,
      value: currentFirmware?.firmwareRevision ?? "",
    },
    {
      label: translations.pages.deviceManagement.firmwareUpdate.releaseInfo.firmwareVersion,
      value: currentFirmware?.firmwareVersion ?? "",
    },
    {
      label: translations.pages.deviceManagement.firmwareUpdate.releaseInfo.releaseData,
      value: currentFirmware?.releaseDate ? formatDate(new Date(currentFirmware?.releaseDate)) : "",
    },
    {
      label: translations.pages.deviceManagement.firmwareUpdate.releaseInfo.releaseType,
      value: currentFirmware?.deviceFirmwareReleaseType.deviceFirmwareReleaseTypeName ?? "",
    },
  ];

  const updateFirmwareHandler = async () => {
    const { data } = await updateFirmwareMutation({
      variables: {
        mappingDeviceFirmwareDevices: {
          deviceFirmwareId: currentFirmware?.id as string,
          deviceId: deviceId as string,
          mappingDeviceFirmwareDeviceStatusId: "3667145e-41a3-41ca-83f5-2df24cb84171",
        },
      },
    });
    return data;
  };

  return (
    <>
      <LeftTileWrapperSC>
        <Typography variant="h4">
          {translations.pages.deviceManagement.firmwareUpdate.availableActualisations}
        </Typography>
        <AvailableActualisationsSC>
          <FirmwareSelectSC>
            <div style={{ marginBottom: "16px" }}>
              <Typography variant="copyBold">
                {translations.pages.deviceManagement.firmwareUpdate.firmwareSelect.firmwareRevision}
              </Typography>
            </div>
            <FirmwareSelect
              setCurrentFirmware={setCurrentFirmware}
              currentFirmwareId={currentFirmwareId}
              setCurrentFirmwareId={setCurrentFirmwareId}
            />
          </FirmwareSelectSC>
          <RevisionsInfoSC>
            <div>
              <Typography variant="copyBold">
                {" "}
                {translations.pages.deviceManagement.firmwareUpdate.firmwareSelect.revisionInfo}
              </Typography>
            </div>
            <DetailInfo noSeparator={false} data={selectedFirmwareInfo} />
          </RevisionsInfoSC>
          <RevisionsDescriptionSC>
            <div>
              <Typography variant="copyBold">{translations.globals.terms.description}</Typography>
            </div>
            <div>
              <Typography variant="copy">{currentFirmware?.description}</Typography>
            </div>
          </RevisionsDescriptionSC>
        </AvailableActualisationsSC>
        <PullbuttonSC>
          <PLButton
            label="PULL"
            disabled={currentFirmware?.id === initialFirmwareId}
            onClick={() => setIsModalOpen(true)}
          />
        </PullbuttonSC>
        <PLModal
          showIcon
          allowCloseOnBackdrop
          modalTitle={translations.pages.deviceManagement.firmwareUpdate.modalTitle}
          lhCancel={() => {
            setIsModalOpen(false);
          }}
          lhConfirm={async () => {
            await updateFirmwareHandler();
            setIsModalOpen(false);
          }}
          lhClose={() => setIsModalOpen(false)}
          show={isModalOpen}
          iconName="licensing-software"
        >
          <>
            <Box slot="modal-content">
              <Typography variant="copy">{translations.pages.deviceManagement.firmwareUpdate.message}</Typography>
            </Box>
          </>
        </PLModal>
      </LeftTileWrapperSC>
      <LeftTileWrapperSC>
        <FirmwareOverview deviceId={deviceId ?? ""} />
      </LeftTileWrapperSC>
    </>
  );
};

export default FirmwareUpdate;
