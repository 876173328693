import { Box, Grid } from "@mui/material";
import theme from "themes/theme";
import React, { useState, useEffect, useMemo } from "react";
import { LhChangeItem } from "@liebherr/patternlib/dist/types/utils/interfaces";
import ContentHeader from "components/organisms/ContentHeader";
import PLTabs from "components/patternLib/PLTabs";
import PLTabsItem from "components/patternLib/PLTabsItem";
import translations from "translations";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { contentHeaderTitleAtom } from "components/organisms/ContentHeader/contentHeaderRecoilState";
import { useGetMappingDeviceTagsQuery } from "graphqlBase/MappingDeviceTag/__generated__/getMappingDeviceTags";
import PLStatusPill from "components/patternLib/PLStatusPill";
import { useParams } from "react-router-dom";
import SensorMeasurements from "./SensorMeasurements";
import useGetSensorMeasurementHistory from "../hooks/useGetSensorMeasurementHistory";
import DeviceDataTiles from "./DeviceDataTiles";
import Configuration from "../Configuration";
import { useGetDevicesForDeviceConfigQuery } from "graphqlBase/Devices/__generated__/getDevicesForDeviceConfig";
import {
  configurationsStateFieldAtom,
  defaultConfigurationJsonAtom,
  deviceConfigurationAtom,
  deviceConfigurationSchema,
  initialDeviceConfigurationAtom,
  mappingDeviceConfigurationDeviceId,
} from "../Configuration/recoilState";
import { addDays, isFuture, isPast } from "date-fns";
import ConnectionsMonitoringChart from "./ConnectionsMonitoringChart";
import FirmwareUpdate from "./FirmwareUpdate";
import { authorizedTabsArray } from "authorization/AuthorizedTabs/authorizedTabsArray";
import AuthorizedTabsCan from "authorization/AuthorizedTabs/AuthorizedTabsCan";
import ConnectedDevices from "./ConnectedDevices";
import { useGetDeviceIdsForConnectedDevicesToGatewayLazyQuery } from "graphqlBase/Devices/__generated__/getDeviceIdsForConnectedDevicesToGateway";
import { SortEnumType } from "graphqlBase/types";

const rootStyles = {
  margin: "auto",
  backgroundColor: theme.palette.background.default,
  minHeight: "fit-content",
};

const tagsContainerStyles = {
  display: "flex",
  height: "50%",
  alignItems: "center",
  width: "calc(100% + 10px)",
  justifyContent: "space-between",
  flexDirection: "row",
  columnGap: "8px",
};

const DeviceDetails: React.FC<{}> = () => {
  const { deviceId }: { deviceId: string } = useParams();
  const { data } = useGetMappingDeviceTagsQuery({ variables: { where: { deviceId: { eq: deviceId ?? "" } } } });

  const { data: deviceData } = useGetDevicesForDeviceConfigQuery({
    variables: {
      where: { deviceId: { eq: deviceId } },
    },
    skip: typeof deviceId === "undefined",
  });

  const { latestData, refetch, loading, ...sensorMeasurementsProps } = useGetSensorMeasurementHistory(deviceId ?? "");
  const setInitialDeviceConfigurationForComparisson = useSetRecoilState(initialDeviceConfigurationAtom);
  const [initialFirmwareId, setInitialFirmwareId] = useState<string | undefined>(undefined);
  const setDeviceConfiguration = useSetRecoilState(deviceConfigurationAtom);
  const setDefaultConfiguration = useSetRecoilState(defaultConfigurationJsonAtom);
  const setMappingDeviceConfigurationDeviceId = useSetRecoilState(mappingDeviceConfigurationDeviceId);
  const setDeviceConfigurationSchema = useSetRecoilState(deviceConfigurationSchema);
  const resetButtonStateAtom = useResetRecoilState(configurationsStateFieldAtom);
  const [query, { data: connectedDevices }] = useGetDeviceIdsForConnectedDevicesToGatewayLazyQuery();
  const [currentTab, setCurrentTab] = useState(0);
  const setPageTitle = useSetRecoilState(contentHeaderTitleAtom);
  const gatewayDeviceConnectionId = deviceData?.devices && deviceData?.devices[0].gatewayDeviceConnection?.id;
  useEffect(() => {
    resetButtonStateAtom();
  }, []);

  useEffect(() => {
    if (!gatewayDeviceConnectionId) return;
    query({
      variables: {
        order: { utcTimeMeasured: "DESC" as SortEnumType.Desc },
        where: {
          gatewayDeviceConnectionId: { eq: gatewayDeviceConnectionId },
          utcTimeMeasured: { gte: addDays(new Date(), -1).toISOString() },
        },
      },
    });
  }, [gatewayDeviceConnectionId]);

  useEffect(() => {
    if (!deviceData?.devices) return;
    setPageTitle(deviceData?.devices[0]?.deviceName);
  }, [deviceData, setPageTitle]);

  useEffect(() => {
    if (!deviceData || deviceData.devices.length < 1) return;

    const currentDevice = deviceData.devices[0];
    //initialFirmwareId
    if (currentDevice.mappingDeviceFirmwareDevice.length > 0) {
      const initialFirmware = currentDevice.mappingDeviceFirmwareDevice.find(
        (firmware) => isPast(new Date(firmware.dateFrom)) && isFuture(new Date(firmware.dateTo))
      );

      if (initialFirmware) setInitialFirmwareId(initialFirmware.deviceFirmware.id);
    }

    const defaultConfigurationJson = currentDevice.mappingDeviceConfigurationDevice.slice().pop()?.deviceConfiguration
      .configurationJSON;
    if (defaultConfigurationJson) setDefaultConfiguration(defaultConfigurationJson);
    if (currentDevice.mappingDeviceConfigurationDevice.length > 0) {
      const currentDeviceConfiguration = currentDevice.mappingDeviceConfigurationDevice.find(
        (config) => isPast(new Date(config.dateFrom)) && isFuture(new Date(config.dateTo))
      );

      if (currentDeviceConfiguration) {
        // config exists
        setDeviceConfiguration(JSON.parse(currentDeviceConfiguration.deviceConfiguration.configurationJSON));
        setInitialDeviceConfigurationForComparisson(
          JSON.parse(currentDeviceConfiguration.deviceConfiguration.configurationJSON)
        );
        setMappingDeviceConfigurationDeviceId(currentDeviceConfiguration.id);
      } else {
        setInitialDeviceConfigurationForComparisson(null);
        setMappingDeviceConfigurationDeviceId(null);
      }
    } else {
      setDeviceConfiguration(null);
    }
    if (currentDevice.mappingDeviceFirmwareDevice.length > 0) {
      const currentDeviceFirmwareConfigurationSchema = currentDevice.mappingDeviceFirmwareDevice.find(
        (entry) => isPast(new Date(entry.dateFrom)) && isFuture(new Date(entry.dateTo))
      );
      if (currentDeviceFirmwareConfigurationSchema) {
        // schema exists
        setDeviceConfigurationSchema(
          currentDeviceFirmwareConfigurationSchema.deviceFirmware.deviceFirmwareConfigurationSchema
            .deviceFirmwareConfigurationJSON
        );
      } else {
        setDeviceConfigurationSchema(null);
      }
    } else {
      // no schema
      setDeviceConfigurationSchema(null);
    }
  }, [deviceData]);

  const headerTabLabels = [
    { label: translations.globals.terms.dashboard, scope: "DeviceDashboardUI" },
    {
      label: gatewayDeviceConnectionId ? "Connected Devices" : `${translations.pages.deviceManagement.config}`,
      scope: "DeviceConfiguarationUI",
    },
    { label: !gatewayDeviceConnectionId ? "Firmware" : "", scope: "DeviceFirmwareUI" },
  ];

  const handleHeaderTabChange = (event: CustomEvent<LhChangeItem>) => {
    setCurrentTab(event.detail.itemId as number);
  };

  const HeaderTabs = (
    <PLTabs lhChangeItem={handleHeaderTabChange} key="device-details-tabs">
      {authorizedTabsArray(headerTabLabels).map(({ label }, index) => {
        return <PLTabsItem key={`${label}-${index}`} active={currentTab === index} label={label} />;
      })}
    </PLTabs>
  );

  const Tags = (
    <Box sx={tagsContainerStyles}>
      {data?.mappingDeviceTags.map((item) => {
        return <PLStatusPill key={item.tagId} label={item.tag.tagValue} />;
      })}
    </Box>
  );

  return (
    <Box sx={rootStyles}>
      <ContentHeader tabsStyles={{ marginBottom: 0 }} tabs={[HeaderTabs]} rightItems={Tags} />
      <Grid container>
        <>
          {currentTab === 0 && (
            <Grid item xs={9} sm={6} md={8} lg={9}>
              <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
                <ConnectionsMonitoringChart
                  deviceId={!gatewayDeviceConnectionId ? deviceId : undefined}
                  gatewayDeviceConnectionId={gatewayDeviceConnectionId}
                />
                <SensorMeasurements key="SensorMeasurements" {...sensorMeasurementsProps} loading={loading} />
              </AuthorizedTabsCan>
            </Grid>
          )}
          {currentTab === 1 && (
            <Grid item xs={9} sm={6} md={8} lg={9}>
              <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
                {gatewayDeviceConnectionId ? (
                  <ConnectedDevices
                    deviceIds={
                      Array.from(
                        new Set(connectedDevices?.sensorMeasurementCurrentValues.map((item) => item.deviceId))
                      ) ?? []
                    }
                    setCurrentTab={setCurrentTab}
                  />
                ) : (
                  <Configuration
                    key="Configuration"
                    selectedDeviceId={deviceId}
                    deviceTypeName={deviceData?.devices[0]?.deviceType.deviceTypeName}
                  />
                )}
              </AuthorizedTabsCan>
            </Grid>
          )}
          {currentTab === 2 && (
            <Grid item xs={9} sm={6} md={8} lg={9}>
              <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
                {!gatewayDeviceConnectionId && (
                  <FirmwareUpdate deviceId={deviceId} initialFirmwareId={initialFirmwareId} />
                )}
              </AuthorizedTabsCan>
            </Grid>
          )}

          <Grid item xs={3} sm={6} md={4} lg={3}>
            <DeviceDataTiles lastTimeConnection={latestData[0]} gatewayDeviceConnectionId={gatewayDeviceConnectionId} />
          </Grid>
        </>
      </Grid>
    </Box>
  );
};

export default DeviceDetails;
