export default {
  label: "User Management",
  userAuthorization: "User Authorization",
  userRoleManager: {
    label: "User Role Manager",
    selectRole: "Select Role",
    assignedTo: "Assigned to",
    noUsersAssignedMessage: "There are no users assigned to this role yet.",
    permissions: "Permissions",
    createdUserRole: "Your user role has been succesfully created.",
    useRoleCreate: "User role created",
    discardChanges: "Discard changes",
    DiscardMessage:
      "You've commited changes that are not saved. Are you sure you want to quit this page and leave those changes unsaved? ",
  },
  button: {
    create: {
      role: "New role",
    },
  },
  form: {
    label: {
      role: "Edit Role",
      user: "Edit User",
      deleteUserRole: "Delete user role",
      editRole: "edit role",
    },
    message: {
      continueDeleteMessage: "Are you sure you want to continue?",
      userRoleDeleteMessagePt1: "This user role is currently assignet to",
      userRoleDeleteMessagePt2: "They will no longer have access to certain app areas.",
    },
    edit: {
      tabLabel: {
        editRole: "Change Role",
        assignScope: "Assign Permissions",
        assignUser: "Assign Users",
      },
      listLabel: {
        assigned: "Assigned",
        unassigned: "Unassigned",
      },
    },
  },
  table: {
    column: {
      description: "Description",
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      name: "Name",
      role: "Roles of the User",
      tenant: "Tenant / Company",
      value: "Value",
      userRole: "User role name",
      roleDescription: "Role description",
    },
  },
};
