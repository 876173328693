import { Typography, styled, Box, SxProps, Theme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { contentHeaderTitleAtom } from "./contentHeaderRecoilState";
import React from "react";

const HeaderContainerSC = styled("div")(
  ({ theme }) => `
        background-color: ${theme.palette.background.paper};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        padding: 3em 3em 0 3em;
`
);

const TitleAndTabsContentBoxSC = styled("div")(
  () => `
        display: flex;
        flex-direction: column;
        margin-right: 3em;
        margin-left: -30px;
`
);

const titleAndItemsBoxStyles = { display: "flex", flexDirection: "row", alignItems: "center" };
const titleBoxStyles = { marginRight: "5em", marginLeft: "15px" };
const titleStyles = { display: "flex" };
const tabsBoxStyles = { marginBottom: "2em", marginTop: "1.5em" };
const leftItemsBoxStyles = { justifyContent: "space-between" };

interface ContentHeaderProps {
  leftItems?: JSX.Element;
  rightItems?: JSX.Element;
  tabs?: Array<JSX.Element>;
  tabsStyles?: SxProps<Theme> | undefined;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ tabsStyles, leftItems, rightItems, tabs }) => {
  const pageTitle = useRecoilValue(contentHeaderTitleAtom);

  return (
    <HeaderContainerSC>
      <TitleAndTabsContentBoxSC>
        <Box sx={titleAndItemsBoxStyles}>
          <Box sx={titleBoxStyles}>
            <Typography sx={titleStyles} variant="h2">
              {pageTitle}
            </Typography>
          </Box>
          <Box sx={leftItemsBoxStyles}>{leftItems}</Box>
        </Box>
        <Box sx={{ ...tabsBoxStyles, ...tabsStyles }}>{tabs}</Box>
      </TitleAndTabsContentBoxSC>
      <Box>{rightItems}</Box>
    </HeaderContainerSC>
  );
};

export default ContentHeader;
