import { useState, useEffect } from "react";
import { Route } from "routes/routes";
import { ability } from "./config/ability";
import { useAuthorization } from "./useAuthorization";

export const useAuthorizedRoutes = (routes: Route[]): Route[] => {
  const [routesArray, setRoutesArray] = useState([routes[0]]);
  const isAuthDone = useAuthorization();

  useEffect(() => {
    if (routes) {
      try {
        const cleanedRoutes = traverseRoutes(routes);
        setRoutesArray(cleanedRoutes);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isAuthDone, routes, setRoutesArray]);

  return routesArray;
};

const traverseRoutes = (routes: Route[], path: string[] = ["Home"]): Route[] => {
  return routes.reduce<Route[]>((filteredRoutes, route) => {
    const subject = route.scope === "Home" ? route.scope : `${path.join("/")}/${route.scope}`;

    if (ability.can("read", subject)) {
      const subRoutes = traverseRoutes(route.subRoutes ?? [], [subject]);
      return filteredRoutes.concat({ ...route, subRoutes });
    }
    return filteredRoutes;
  }, []);
};
