import { Typography, styled } from "@mui/material";
import PLIcon from "components/patternLib/PLIcon";
import React from "react";

const MessageWrapperSC = styled("div")(
  () => `
  display: flex;
   flex-direction: row;
   padding-top: var(--spacing-2);
   align-items: center;
`
);

const messageFontStyles = {
  fontSize: "var(--copytext-font-size-smaller)",
  color: "var(--color-warning)",
  fontWeight: 400,
  fontFamily: "var(--copytext-font-family)",
  paddingLeft: "7px",
};

export const WarningMessage = ({ text }: { text: string }) => {
  return (
    <MessageWrapperSC>
      <PLIcon iconName="triangle-with-exclamation-mark" size="16px" color="var(--color-warning)" />
      <Typography sx={messageFontStyles}>{text} </Typography>
    </MessageWrapperSC>
  );
};
export default WarningMessage;
