import { Box, Typography } from "@mui/material";
import { getHours } from "date-fns";
import { useAuth } from "oidc-react";
import React from "react";
import translations from "translations";
import AlertManagementSummary from "./AlertManagementSummary";
import AssetManagementSummary from "./AssetManagementSummary";
import DeviceManagementSummary from "./DeviceManagementSummary";
import { OverlineSC, SalutationBoxSC, salutationWrapper, SummaryTilesWrapperSC } from "./styles";

const Home: React.FC<{}> = () => {
  const givenName = useAuth()?.userData?.profile?.given_name ?? "";
  const firstName = `${givenName.charAt(0).toUpperCase()}${givenName.substring(1)}`;
  const currentHours = getHours(new Date());

  const { morning, afternoon, evening } = translations.globals.time;
  const { good } = translations.globals.terms;

  const timeOfDay = currentHours < 12 ? morning : currentHours < 18 ? afternoon : evening;
  const salutation = `${good.charAt(0).toUpperCase()}${good.substring(1)} ${timeOfDay
    .charAt(0)
    .toUpperCase()}${timeOfDay.substring(1)}, ${firstName}!`;

  return (
    <SalutationBoxSC>
      <Box sx={salutationWrapper}>
        <OverlineSC />
        <Typography variant="h4">{salutation}</Typography>
      </Box>
      <SummaryTilesWrapperSC>
        <AssetManagementSummary />
        <DeviceManagementSummary />
        <AlertManagementSummary />
      </SummaryTilesWrapperSC>
    </SalutationBoxSC>
  );
};

export default Home;
