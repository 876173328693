export default {
  label: "Alert Management",
  alerts: "My Alerts",
  noAlertsMessage: "There are no alerts to show.",
  recentAlerts: "Recent Notifications",
  alertDefinitions: "Alert Management",
  scopeEdit: {
    operator: "Operator",
    value: "Value",
    WarningScope: "Warning Scope",
    connectivity: {
      title: "Disconnect Alert Delay",
      message:
        "Set the delay time for the disconnect alert, specifying the duration after which the alert will be triggered upon device disconnection from the cloud.",
    },
  },
  NotificationResubmissionInterval: {
    h1: "1 h",
    h6: "6 h",
    h12: "12 h",
    h24: "24 h",
    never: "Never",
  },
  createAlert: "Create Alert",
  editAlert: "Edit Alert",
  deleteAlert: "Delete Alert",
  deleteAlertMessage: `By clicking "Confirm" the selected alert(s) will be deleted. You’ll not be able to restore this data.`,
  toastMessageCreate: { success: "Alert created successfully", error: "Error creating alert" },
  toastMessageUpdate: { success: "Alert updated successfully", error: "Error updating alert" },
  toastMessageDelete: { success: "Alert deleted successfully", error: "Error deleting alert" },
};
