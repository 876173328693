import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Typography } from "@mui/material";
import translations from "translations";
import { createRoleData } from "../../RecoilState/recoilState";
import { useRecoilState } from "recoil";

const useStyles = makeStyles({
  root: {
    alignSelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "30px",
  },
  roleName: {
    marginBottom: "15px",
  },
  roleDescription: {
    marginTop: "30px",
    marginBottom: "15px",
  },
  roleDescriptionInput: {
    width: "100%",
  },
});
const CreateRoleEditor: React.FC<{}> = () => {
  const classes = useStyles();
  const [roleData, setRoleData] = useRecoilState<{ description: string; name: string }>(createRoleData);

  return (
    <div className={classes.root}>
      <Typography className={classes.roleName} variant="h6">
        {translations.pages.userManagement.table.column.userRole}
      </Typography>
      <div>
        <TextField
          value={roleData.name}
          required
          onChange={(e: React.ChangeEvent) => {
            const input = Object(e).target.value as string;
            setRoleData({
              description: roleData.description,
              name: input,
            });
          }}
        />
      </div>
      <Typography variant="h6" className={classes.roleDescription}>
        {translations.pages.userManagement.table.column.roleDescription}
      </Typography>
      <div>
        <TextField
          className={classes.roleDescriptionInput}
          value={roleData.description}
          required
          onChange={(e: React.ChangeEvent) => {
            const input = Object(e).target.value;
            setRoleData({
              description: input,
              name: roleData.name,
            });
          }}
        />
      </div>
    </div>
  );
};

export default CreateRoleEditor;
