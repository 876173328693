import { styled, Typography } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { LeftTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import React from "react";
import translations from "translations";
import GroupAssetsMap from "./GroupAssetsMap";
import { useGetCurrentPosition } from "./useGetCurrentPosition";

const NoDataContainerSC = styled("div")(
  () => `
  display: flex;
  height: 35vh;
  justify-content: center;
  align-items: center;
  `
);

const CurrentPositionTile: React.FC<{
  queryBuilderResultIds: string[] | undefined;
  queryResultLoading: boolean | undefined;
}> = ({ queryBuilderResultIds, queryResultLoading }) => {
  const { currentPositionsData, loading } = useGetCurrentPosition(queryBuilderResultIds);

  const hasPositionsData = (currentPositionsData && currentPositionsData?.length > 0) ?? false;
  const isLoading = loading ?? loading === undefined ?? queryResultLoading ?? queryResultLoading === undefined;

  const { label, noDataMessage } = translations.pages.groups.assetGroup.assetGroupDetails.currentPositionTile;

  return (
    <LeftTileWrapperSC>
      <Typography variant="h4">{label}</Typography>
      <LoadingSpinner sx={{ height: "40vh" }} loading={isLoading}>
        {hasPositionsData ? (
          <GroupAssetsMap currentPositionsData={currentPositionsData ?? []} />
        ) : (
          !isLoading &&
          !hasPositionsData && (
            <NoDataContainerSC>
              <Typography variant="copy">{noDataMessage}</Typography>
            </NoDataContainerSC>
          )
        )}
      </LoadingSpinner>
    </LeftTileWrapperSC>
  );
};
export default CurrentPositionTile;
