import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssociatedAssetDevicesForAssetQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetAssociatedAssetDevicesForAssetQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string, mappingAssetTag: Array<{ __typename?: 'MappingAssetTag', id: string, assetTag: { __typename?: 'AssetTag', id: string, tagValue: string } }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, sellingUnitSerialNumber?: Maybe<string>, serialNumber?: Maybe<string>, articleNumber?: Maybe<string>, generalItem?: Maybe<string> }> } }> };


export const GetAssociatedAssetDevicesForAssetDocument = gql`
    query getAssociatedAssetDevicesForAsset($where: AssetDeviceFilterInput) {
  assetDevices(where: $where) {
    id
    asset {
      id
      mappingAssetTag {
        id
        assetTag {
          id
          tagValue
        }
      }
      assetMasterData {
        id
        sellingUnitSerialNumber
        serialNumber
        articleNumber
        generalItem
      }
    }
  }
}
    `;

/**
 * __useGetAssociatedAssetDevicesForAssetQuery__
 *
 * To run a query within a React component, call `useGetAssociatedAssetDevicesForAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociatedAssetDevicesForAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociatedAssetDevicesForAssetQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAssociatedAssetDevicesForAssetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssociatedAssetDevicesForAssetQuery, GetAssociatedAssetDevicesForAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssociatedAssetDevicesForAssetQuery, GetAssociatedAssetDevicesForAssetQueryVariables>(GetAssociatedAssetDevicesForAssetDocument, options);
      }
export function useGetAssociatedAssetDevicesForAssetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssociatedAssetDevicesForAssetQuery, GetAssociatedAssetDevicesForAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssociatedAssetDevicesForAssetQuery, GetAssociatedAssetDevicesForAssetQueryVariables>(GetAssociatedAssetDevicesForAssetDocument, options);
        }
export type GetAssociatedAssetDevicesForAssetQueryHookResult = ReturnType<typeof useGetAssociatedAssetDevicesForAssetQuery>;
export type GetAssociatedAssetDevicesForAssetLazyQueryHookResult = ReturnType<typeof useGetAssociatedAssetDevicesForAssetLazyQuery>;
export type GetAssociatedAssetDevicesForAssetQueryResult = Apollo.QueryResult<GetAssociatedAssetDevicesForAssetQuery, GetAssociatedAssetDevicesForAssetQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
