const toolBarStyles = {
  "&.MuiToolbar-root": {
    "&.MuiTablePagination-toolbar": {
      backgroundColor: "var(--color-steel-100)",
      border: "1px solid rgba(224, 224, 224, 1)",
      padding: "5px 20px 5px 10px",
      minHeight: "42px",
      height: "48px",
      fontSize: "14px",
      fontFamily: "var(--copytext-font-family)",
      "& .MuiSvgIcon-root": {
        fontSize: "24px",
      },
    },
  },
};
export const muiToolbarOverrides = {
  root: {
    ...toolBarStyles,
  },
};
