import { Typography } from "@mui/material";
import PLTextlink from "components/patternLib/PLTextlink";
import { useGetRecentAssignmentsForSummaryQuery } from "graphqlBase/AssetDevices/__generated__/getRecentAssignmentsForSummary";
import { useGetOpenAssignmentsForBadgeValueQuery } from "graphqlBase/Assets/__generated__/getOpenAssignmentsForBadgeValue";
import { SortEnumType } from "graphqlBase/types.d";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import translations from "translations";
import { formatDateTime } from "translations/formatter";
import { currentAssetTab } from "../AssetManagement/assetTabsState";
import { NoDataSummaryTile } from "./NoDataSummaryTile";
import { SummaryTableCellSC } from "./styles";
import SummaryTile from "./SummaryTile";


interface RecentAssignment {
  assetId: string;
  date: string;
  assetName: string;
}

const AssetManagementSummary: React.FC<{}> = () => {
  const history = useHistory();
  const setCurrentTab = useSetRecoilState(currentAssetTab);

  const [recentAssignments, setRecentAssignments] = useState<RecentAssignment[] | undefined>();

  const variables = {
    order: [{ createdAt: SortEnumType.Desc }],
    take: 5,
    where: { asset: { assetMasterDataId: { neq: null } } },
  };

  const { label, openAssignments } = translations.pages.assetManagement;
  const { recentAssignments: tableTitle, countOpenAssets, noDataAssetSummary } = translations.pages.home;


  const { data: assignmentsData, loading: assignmentsLoading } = useGetRecentAssignmentsForSummaryQuery({ variables });
  const { data: openAssignmentsData, loading: openAssignmentsLoading } = useGetOpenAssignmentsForBadgeValueQuery();

  useEffect(() => {
    if (!assignmentsData) return;
    const assignments = assignmentsData?.assetDevices?.map((current) => {
      return {
        date: current.createdAt ?? "",
        assetName: current.asset?.assetMasterData?.generalItem ?? "",
        assetId: current.asset.id,
      };
    });
    setRecentAssignments(assignments);
  }, [assignmentsData]);

  const openAssignmentCount = openAssignmentsData?.assets?.length;
  const tableElements = recentAssignments?.map((row) => (
    <>
      <SummaryTableCellSC first size="small">
        <Typography variant="copy">{formatDateTime(new Date(row.date) ?? new Date().toISOString())}</Typography>
      </SummaryTableCellSC>
      <SummaryTableCellSC last size="small">
        <PLTextlink
          width="fit-content"
          onClick={() => history.push(`/assetManagement/assetDetails/${row.assetId}`)}
          label={row.assetName}
          size="small"
          bold={false}
          uppercase={false}
        />
      </SummaryTableCellSC>
    </>
  ));

  const handleSeeOpenAssignments = () => {
    setCurrentTab(2);
    history.push("/assetManagement");
  };

  const contentFooter = (
    <PLTextlink
      onClick={handleSeeOpenAssignments}
      label={countOpenAssets(openAssignmentCount ?? 0)}
      size="small"
      bold={false}
      uppercase={false}
    />
  );

  const NoTableDataComponent = <NoDataSummaryTile message={noDataAssetSummary} />;

  return (
    <SummaryTile
      iconName="products"
      tableTitle={tableTitle}
      title={label}
      loading={assignmentsLoading || openAssignmentsLoading}
      tableElements={tableElements ?? []}
      contentFooterTitle={openAssignments}
      contentFooter={contentFooter}
      handleSeeMore={() => history.push("/assetManagement")}
      NoTableDataComponent={NoTableDataComponent}
    />
  );
};

export default AssetManagementSummary;
