import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ManageMappingRoleUsersMutationVariables = Types.Exact<{
  creates: Array<Types.MappingRoleUserCreateType> | Types.MappingRoleUserCreateType;
  deletes?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
}>;


export type ManageMappingRoleUsersMutation = { createMappingRoleUsers?: Maybe<Array<Maybe<{ __typename?: 'MappingRoleUser', id: string }>>>, deleteMappingRoleUsers?: Maybe<Array<Maybe<{ __typename?: 'MappingRoleUser', id: string }>>> };


export const ManageMappingRoleUsersDocument = gql`
    mutation manageMappingRoleUsers($creates: [MappingRoleUserCreateType!]!, $deletes: [UUID!]) {
  createMappingRoleUsers(mappingRoleUsers: $creates) {
    id
  }
  deleteMappingRoleUsers(ids: $deletes) {
    id
  }
}
    `;
export type ManageMappingRoleUsersMutationFn = Apollo.MutationFunction<ManageMappingRoleUsersMutation, ManageMappingRoleUsersMutationVariables>;

/**
 * __useManageMappingRoleUsersMutation__
 *
 * To run a mutation, you first call `useManageMappingRoleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageMappingRoleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageMappingRoleUsersMutation, { data, loading, error }] = useManageMappingRoleUsersMutation({
 *   variables: {
 *      creates: // value for 'creates'
 *      deletes: // value for 'deletes'
 *   },
 * });
 */
export function useManageMappingRoleUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ManageMappingRoleUsersMutation, ManageMappingRoleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ManageMappingRoleUsersMutation, ManageMappingRoleUsersMutationVariables>(ManageMappingRoleUsersDocument, options);
      }
export type ManageMappingRoleUsersMutationHookResult = ReturnType<typeof useManageMappingRoleUsersMutation>;
export type ManageMappingRoleUsersMutationResult = Apollo.MutationResult<ManageMappingRoleUsersMutation>;
export type ManageMappingRoleUsersMutationOptions = Apollo.BaseMutationOptions<ManageMappingRoleUsersMutation, ManageMappingRoleUsersMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
