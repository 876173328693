export default {
  operator: "Operator",
  selectField: "Select Field",
  selectOperator: "Select operator",
  rule: "Rule",
  ruleSet: "Rule-Set",
  not: "Not",
  selectValueSource: "Select value source",
  errorGroupQuery: "Could not get results as there is a problem with the query.",
  updateQueryMessage: "Please update group query rules.",
  emptyGroupNameMessage: "Group name cannot be empty.",
  groupName: "Group Name",
  groupDescription: "Description",
  runQuery: "Run Query",
  saveQuery: "Save Query",
  queryBuilder: "Query Builder",
  noResultsQuery: "This query didn't yield any results.",
};
