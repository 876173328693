import { QueryLazyOptions } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { atom, RecoilState, useRecoilCallback, useRecoilState } from "recoil";
export const selectedRowsFilterAtom = atom({
  key: "selectedRowsFilterAtom",
  default: false,
});
export function useFilterTableSelectedRows<V extends { [key: string]: unknown }, A>({
  queryOuter,
  atom,
  getIdsFromAtom,
  entity,
}: {
  atom: RecoilState<A>;
  entity: string;
  getIdsFromAtom: (atom: A) => string[];
  queryOuter: (options?: QueryLazyOptions<V>) => void;
}) {
  const defaultVariables = {} as V;
  const [selectedIds, setSelectedIds] = useRecoilState(atom);
  const selectedRowIds = getIdsFromAtom(selectedIds);
  const [selectedRowsFilterOn, setSelectedRowsFilterOn] = useRecoilState(selectedRowsFilterAtom);
  const [dataGridVariables, setDataGridVariables] = useState<V>(defaultVariables);
  const getSelectedRowIds = useRecoilCallback(
    ({ snapshot }) => async () => {
      const atomVals = await snapshot.getPromise(atom);
      return getIdsFromAtom(atomVals);
    },
    []
  );
  const applySelectedRowsFilterFunction = async ({
    variables,
    selectedRowsFilterOn,
    entity,
  }: {
    entity: string;
    selectedRowsFilterOn: boolean;
    variables: V;
  }): Promise<V> => {
    const selectedRowIds = await getSelectedRowIds();
    const lowerCasedEntity = entity.toLowerCase();
    if (selectedRowsFilterOn && !!selectedRowIds.length) {
      const { where } = ((variables ?? { where: {} }) as unknown) as { where: { [key: string]: unknown } };
      return {
        ...variables,
        where: {
          ...where,
          [`${lowerCasedEntity}Id`]: { in: selectedRowIds },
        },
      };
    }
    return variables;
  };
  const query: typeof queryOuter = useCallback(
    ({ variables, ...rest } = { variables: defaultVariables }) => {
      setDataGridVariables(variables ?? defaultVariables);
      if (!selectedRowsFilterOn) queryOuter({ ...rest, variables });
    },
    [queryOuter, selectedRowsFilterOn]
  );

  useEffect(() => {
    if (selectedRowsFilterOn && !selectedRowIds.length) setSelectedRowsFilterOn(false);
  }, [!!selectedRowIds.length, selectedRowsFilterOn]);

  useEffect(() => {
    applySelectedRowsFilterFunction({
      selectedRowsFilterOn,
      variables: dataGridVariables,
      entity,
    })
      .then((variablesNext) => {
        queryOuter({ variables: variablesNext });
      })
      .catch(console.log);
  }, [selectedRowsFilterOn, getSelectedRowIds, dataGridVariables]);

  return { selectedRowsFilterOn, setSelectedRowsFilterOn, query, selectedIds, setSelectedIds };
}
