import { atom } from "recoil";

export const shoWOpenAssignmentsConfirmMontalAtom = atom({
  key: "shoWOpenAssignmentsConfirmMontalAtom",
  default: false,
});

export const currentDeviceAtom = atom<string | undefined>({
  key: "currentDeviceAtom",
  default: undefined,
});
