import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMappingDeviceConfigurationDevicesMutationVariables = Types.Exact<{
  mappingDeviceConfigurationDevices?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingDeviceConfigurationDeviceCreateType>> | Types.InputMaybe<Types.MappingDeviceConfigurationDeviceCreateType>>;
}>;


export type CreateMappingDeviceConfigurationDevicesMutation = { createMappingDeviceConfigurationDevices?: Maybe<Array<Maybe<{ __typename?: 'MappingDeviceConfigurationDevice', id: string, deviceId: string, deviceConfigurationId: string, dateFrom: string, dateTo: string, deviceConfiguration: { __typename?: 'DeviceConfiguration', id: string, configurationJSON: string } }>>> };


export const CreateMappingDeviceConfigurationDevicesDocument = gql`
    mutation createMappingDeviceConfigurationDevices($mappingDeviceConfigurationDevices: [MappingDeviceConfigurationDeviceCreateType]) {
  createMappingDeviceConfigurationDevices(mappingDeviceConfigurationDevices: $mappingDeviceConfigurationDevices) {
    id
    deviceId
    deviceConfigurationId
    deviceConfiguration {
      id
      configurationJSON
    }
    dateFrom
    dateTo
  }
}
    `;
export type CreateMappingDeviceConfigurationDevicesMutationFn = Apollo.MutationFunction<CreateMappingDeviceConfigurationDevicesMutation, CreateMappingDeviceConfigurationDevicesMutationVariables>;

/**
 * __useCreateMappingDeviceConfigurationDevicesMutation__
 *
 * To run a mutation, you first call `useCreateMappingDeviceConfigurationDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingDeviceConfigurationDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingDeviceConfigurationDevicesMutation, { data, loading, error }] = useCreateMappingDeviceConfigurationDevicesMutation({
 *   variables: {
 *      mappingDeviceConfigurationDevices: // value for 'mappingDeviceConfigurationDevices'
 *   },
 * });
 */
export function useCreateMappingDeviceConfigurationDevicesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMappingDeviceConfigurationDevicesMutation, CreateMappingDeviceConfigurationDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMappingDeviceConfigurationDevicesMutation, CreateMappingDeviceConfigurationDevicesMutationVariables>(CreateMappingDeviceConfigurationDevicesDocument, options);
      }
export type CreateMappingDeviceConfigurationDevicesMutationHookResult = ReturnType<typeof useCreateMappingDeviceConfigurationDevicesMutation>;
export type CreateMappingDeviceConfigurationDevicesMutationResult = Apollo.MutationResult<CreateMappingDeviceConfigurationDevicesMutation>;
export type CreateMappingDeviceConfigurationDevicesMutationOptions = Apollo.BaseMutationOptions<CreateMappingDeviceConfigurationDevicesMutation, CreateMappingDeviceConfigurationDevicesMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
