import { LhChange, LhChangeItem } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { styled } from "@mui/material";
import PLTexttoggle from "components/patternLib/form/PLTexttoggle";
import PLTexttoggleItem from "components/patternLib/form/PLTexttoggleItem";
import React from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import translations from "translations";
import {
  configurationsStateFieldComposerFromStateButtons,
  configurationsStateFieldAtom,
  movementStateArrayAtom,
  isValidJsonAtom,
} from "../recoilState";

interface StateButtonProps {
  statePart: 0 | 1;
  statesArray: string[];
}
const StateButtons = ({ statesArray, statePart }: StateButtonProps) => {
  const isValidJson = useRecoilValue(isValidJsonAtom);
  const setMovementStatesArray = useSetRecoilState(movementStateArrayAtom);
  const [composedConfigStateField, setComposedConfigStateField] = useRecoilState(
    configurationsStateFieldComposerFromStateButtons(statePart)
  );
  const [configurationsStateField, setConfigAtom] = useRecoilState(configurationsStateFieldAtom);

  const handleHeaderTabChange = (event: CustomEvent<LhChange>) => {
    if (statePart === 0) {
      if (statesArray[event.detail.value as number] === "TARGET_SITE") {
        setMovementStatesArray(["SETUP", "USE"]);
        setConfigAtom("TARGET_SITE_IN_SETUP");
      }
      if (
        (statesArray[event.detail.value as number] !== "TARGET_SITE" && configurationsStateField.includes("SETUP")) ||
        configurationsStateField.includes("USE")
      ) {
        setMovementStatesArray(["REST", "MOVT"]);
        setConfigAtom(`${statesArray[event.detail.value as number]}_IN_REST`);
      }
    }
    setComposedConfigStateField(statesArray[event.detail.value as number]);
  };

  return (
    <PLTexttoggle
      value={`${statesArray.findIndex((item) => item === composedConfigStateField)}`}
      disabled={!isValidJson}
      size="big"
      lhChange={handleHeaderTabChange}
      key="device-details-tabs"
    >
      {statesArray.map((label: string, index: number) => {
        return (
          <PLTexttoggleItem
            key={`${label}-${index}`}
            //@ts-ignore
            label={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.configStateKeys[label]}
            active={label === composedConfigStateField}
            value={`${index}`}
          />
        );
      })}
    </PLTexttoggle>
  );
};

export default StateButtons;
