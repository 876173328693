export const assetDeviceApplicationState = [
  { value: "UNKNOWN", label: "Unknown", extendedLabel: "" },
  { value: "STORAGE_SITE_IN_REST", label: "Storage site", extendedLabel: ", in rest" },
  { value: "STORAGE_SITE_IN_MOVT", label: "Storage site", extendedLabel: ", in movement" },
  { value: "TRANSPORT_IN_REST", label: "Transport", extendedLabel: ", in rest" },
  { value: "TRANSPORT_IN_MOVT", label: "Transport", extendedLabel: ", in movement" },
  { value: "TARGET_SITE_IN_SETUP", label: "Construction site", extendedLabel: ", in setup" },
  { value: "TARGET_SITE_IN_USE", label: "Construction site", extendedLabel: ", in use" },
];

export const lhbDeviceState = [
  { value: "UNKNOWN", label: "Unknown", extendedLabel: "" },
  { value: "DECOUPLED", label: "Decoupled", extendedLabel: "" },
  { value: "COUPLED_UNUSED", label: "Coupled", extendedLabel: ", Passive" },
  { value: "COUPLED_IDLE", label: "Coupled", extendedLabel: ", Idle" },
  { value: "COUPLED_USED", label: "Coupled", extendedLabel: ", Active" },
];

export const assetAttributeIcon: { [key: string]: string } = {
  [`tiltunit`]: "LiTiU",
  [`tiltrotator`]: "tiltrotator",
  [`paletfork`]: "pallet-fork",
  [`stickextension`]: "stick-extension",
  [`swilvebucket`]: "4in1-bucket",
  [`ditchcleaningbucket`]: "2-1-bucket",
  [`clamshell`]: "clamshell-grab",
  [`multi-tinegrapple`]: "multi-tine-grab",
  [`bucket`]: "bucket",
  [`sortinggrapple`]: "sorting-grab",
};
