import React from "react";
import { useRouteMatch } from "react-router-dom";

export interface MatchParams {
  notificationId: string;
}

const useGetNotificationIdFromRoute = () => {
  const match = useRouteMatch<MatchParams>("/alertManagement/alertEditing/:notificationId");

  return match?.params?.notificationId;
};
export default useGetNotificationIdFromRoute;
