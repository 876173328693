import React, { useEffect, useState } from "react";
import Props from "./index.d";
import EditGeofence from "./EditGeofence";
import translations from "translations";
import L from "leaflet";

export type TextFieldComponentProps = Props;

const GeoJSONPolygon: React.FC<Props> = (props) => {
  const { title, data, disabled, isPartOfSummary, testId, ...rest } = props;
  const [polygon, setPolygon] = useState<L.LatLngExpression[] | undefined>(undefined);
  useEffect(() => {
    if (!data.state.coordinates) return;
    setPolygon(data.state.coordinates);
  }, [data.state.coordinates]);

  useEffect(() => {
    data.setAndSave({
      type: "Polygon",
      //@ts-ignore
      coordinates: polygon as [number, number],
      crs: 4326,
    });
  }, [polygon]);

  return (
    <div>
      {disabled && isPartOfSummary && <p>{translations.pages.fleetInventory.multiSelectLabel}</p>}
      <EditGeofence
        testId={testId}
        polygon={polygon}
        setPolygon={setPolygon}
        initalPosition={props.initalPosition}
        zoom={props.zoom}
        disabled={disabled}
      />
    </div>
  );
};

export default GeoJSONPolygon;
