import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLStepsProps = DropOnONLh<JSX.PatternlibSteps>;
export type PLStepsExtendedProps = PLStepsProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedSteps = wrapWc<JSX.PatternlibSteps>("patternlib-steps");
export const useRefPLSteps = () => useRef<HTMLPatternlibStepsElement>(null);

const PLSteps = React.forwardRef<HTMLPatternlibStepsElement, PLStepsExtendedProps>((props, ref) => {
  const myRef = useRefPLSteps();
  return (
    <WrappedSteps ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedSteps>
  );
});
PLSteps.displayName = "PLSteps";

export default PLSteps;
