import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetDevicesForAssetGroupWorkloadOverviewQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetDeviceSortInput> | Types.AssetDeviceSortInput>;
}>;


export type GetAssetDevicesForAssetGroupWorkloadOverviewQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string } }> };


export const GetAssetDevicesForAssetGroupWorkloadOverviewDocument = gql`
    query getAssetDevicesForAssetGroupWorkloadOverview($where: AssetDeviceFilterInput, $take: Int, $skip: Int, $order: [AssetDeviceSortInput!]) {
  assetDevices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    asset {
      id
    }
  }
}
    `;

/**
 * __useGetAssetDevicesForAssetGroupWorkloadOverviewQuery__
 *
 * To run a query within a React component, call `useGetAssetDevicesForAssetGroupWorkloadOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDevicesForAssetGroupWorkloadOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDevicesForAssetGroupWorkloadOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetDevicesForAssetGroupWorkloadOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetDevicesForAssetGroupWorkloadOverviewQuery, GetAssetDevicesForAssetGroupWorkloadOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetDevicesForAssetGroupWorkloadOverviewQuery, GetAssetDevicesForAssetGroupWorkloadOverviewQueryVariables>(GetAssetDevicesForAssetGroupWorkloadOverviewDocument, options);
      }
export function useGetAssetDevicesForAssetGroupWorkloadOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetDevicesForAssetGroupWorkloadOverviewQuery, GetAssetDevicesForAssetGroupWorkloadOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetDevicesForAssetGroupWorkloadOverviewQuery, GetAssetDevicesForAssetGroupWorkloadOverviewQueryVariables>(GetAssetDevicesForAssetGroupWorkloadOverviewDocument, options);
        }
export type GetAssetDevicesForAssetGroupWorkloadOverviewQueryHookResult = ReturnType<typeof useGetAssetDevicesForAssetGroupWorkloadOverviewQuery>;
export type GetAssetDevicesForAssetGroupWorkloadOverviewLazyQueryHookResult = ReturnType<typeof useGetAssetDevicesForAssetGroupWorkloadOverviewLazyQuery>;
export type GetAssetDevicesForAssetGroupWorkloadOverviewQueryResult = Apollo.QueryResult<GetAssetDevicesForAssetGroupWorkloadOverviewQuery, GetAssetDevicesForAssetGroupWorkloadOverviewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
