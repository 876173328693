import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetDetailsForGalleryQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetAssetDetailsForGalleryQuery = { asset?: Maybe<{ __typename?: 'Asset', id: string, userId?: Maybe<string>, createdAt?: Maybe<string>, assetImage: Array<{ __typename?: 'AssetImage', id: string, pictureBlobStorageUrl: string, description?: Maybe<string> }>, assetDevice: Array<{ __typename?: 'AssetDevice', id: string, description?: Maybe<string> }> }> };


export const GetAssetDetailsForGalleryDocument = gql`
    query getAssetDetailsForGallery($id: UUID!) {
  asset(id: $id) {
    id
    userId
    createdAt
    assetImage {
      id
      pictureBlobStorageUrl
      description
    }
    assetDevice {
      id
      description
    }
  }
}
    `;

/**
 * __useGetAssetDetailsForGalleryQuery__
 *
 * To run a query within a React component, call `useGetAssetDetailsForGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDetailsForGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDetailsForGalleryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetDetailsForGalleryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAssetDetailsForGalleryQuery, GetAssetDetailsForGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetDetailsForGalleryQuery, GetAssetDetailsForGalleryQueryVariables>(GetAssetDetailsForGalleryDocument, options);
      }
export function useGetAssetDetailsForGalleryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetDetailsForGalleryQuery, GetAssetDetailsForGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetDetailsForGalleryQuery, GetAssetDetailsForGalleryQueryVariables>(GetAssetDetailsForGalleryDocument, options);
        }
export type GetAssetDetailsForGalleryQueryHookResult = ReturnType<typeof useGetAssetDetailsForGalleryQuery>;
export type GetAssetDetailsForGalleryLazyQueryHookResult = ReturnType<typeof useGetAssetDetailsForGalleryLazyQuery>;
export type GetAssetDetailsForGalleryQueryResult = Apollo.QueryResult<GetAssetDetailsForGalleryQuery, GetAssetDetailsForGalleryQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
