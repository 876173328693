/* eslint-disable react/display-name */
/* eslint-disable filenames/match-exported */
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  GridFilterOperator,
  GridRow,
  GridRowId,
  GridRowParams,
  GridRowProps,
  GridRowsProp,
  GridSelectionModel,
  GridFilterItem,
  GridFilterInputValue,
  GridColDef,
} from "@mui/x-data-grid-pro";
import PLIcon from "components/patternLib/PLIcon";
import { EntityMappings } from "graphqlBase/entityMappings";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useMemo } from "react";
import translations from "translations";
import { CheckboxCell } from "./Cells";
import { FooterWithPagination, NoPaginationFooter } from "./Footers";
import LoadingOverlay from "./LoadingOverlay";
import useMakeDataGrid from "./serverSide";
import DateTimeFilterInput from "./serverSide/FilterInputComponents/DateTimeFilterInput";
import { BaseRow, Column, GridWrapperProps, useMakeDataGridProps, PartialFilterOverrides } from "./serverSide/types";
import { StyledContainer, StyledDataGrid, tableComponentStyles, TableContainerSC, tableWrapper } from "./styles";
import NoDataOverlay from "./Table/NoDataOverlay";
import { HandleDeleteProps, HandleEditProps, Toolbar } from "./Toolbars";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

export * from "./hooks/useFilterTableSelectedRows";
export const testIDs = getTestIDs();

const theme = createTheme({
  palette: {
    primary: { main: "#000000" },
  },
});
interface DataGridComponentProps<R extends BaseRow, E extends keyof EntityMappings> extends GridWrapperProps<R, E> {
  checkboxSelection?: boolean;
  disableColumnResize?: boolean;
  handleDelete?: HandleDeleteProps;
  handleEdit?: HandleEditProps;
  handleOnRowClick?: (params: GridRowParams) => void;
  hasOnlyDefaultToolbar?: boolean;
  hasPagination?: boolean;
  hasToolbar?: boolean;
  initialstate?: GridInitialStatePro;
  loading?: boolean;
  noDataMessage: string;
  onRowHover?: (params: GridRowProps) => void;
  selectedRows?: GridRowId[] | undefined;
  selectedRowsFilterOn?: boolean;
  setIsTagModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRows?: (rows: GridRowsProp) => void;
  setSelectedRowsFilterOn?: React.Dispatch<React.SetStateAction<boolean>>;
  tableHeight: string;
  tableTitle: string;
  withMargin?: boolean;
  withWrapper?: boolean;
}

const filterOverrides: PartialFilterOverrides = {
  dateTime: (definitions: string[]) => {
    return definitions.reduce<GridFilterOperator[]>((filterEntries, definition) => {
      if (definition !== "gte") return filterEntries;
      return [
        {
          label: translations.globals.filterOperators.dateTime[definition],
          value: definition,
          getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => null,
          InputComponent: DateTimeFilterInput,
          InputComponentProps: { type: "dateTime" },
        },
      ];
    }, []);
  },
  string: (definitions: string[]) => {
    return definitions.reduce<GridFilterOperator[]>((filterEntries, definition) => {
      if (definition === "contains" || definition === "ncontains") {
        const obj = {
          label: translations.globals.filterOperators.string[definition],
          value: definition,
          getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => null,
          InputComponent: GridFilterInputValue,
          InputComponentProps: { type: "string" },
        };
        filterEntries.push(obj);
      }
      return filterEntries;
    }, []);
  },
};

const useDataGrid = <R extends BaseRow, E extends keyof EntityMappings>(
  config: Omit<useMakeDataGridProps<R, E>, "defaultProps">
) => {
  const { DataGrid, ...rest } = useMakeDataGrid<R, E>({
    ...config,
    DataGridComponent: StyledDataGrid,
    filterOverrides: { ...filterOverrides, ...config.filterOverrides },
  });

  const ExtendedDataGrid: React.FC<DataGridComponentProps<R, E>> = useMemo(
    () => ({
      rows,
      columns,
      checkboxSelection = true,
      disableColumnResize = true,
      handleOnRowClick,
      selectedRows,
      hasOnlyDefaultToolbar = false,
      hasPagination = true,
      hasToolbar = true,
      setIsTagModalOpen,
      setSelectedRows,
      selectedRowsFilterOn,
      setSelectedRowsFilterOn,
      initialstate,
      tableTitle,
      noDataMessage,
      handleDelete,
      handleEdit,
      loading,
      tableHeight,
      withMargin = true,
      onRowHover,
      withWrapper = true,
      headerHeight,
      rowHeight,
      getDetailPanelContent,
      getDetailPanelHeight,
      keepNonExistentRowsSelected,
      ...props
    }) => {
      const FilterIcon = () => {
        return <PLIcon size="18px" iconName="filter-adjust" />;
      };
      const RemoveIcon = () => {
        return <PLIcon size="small" iconName="x" />;
      };
      const QuickFilterIcon = () => {
        return <PLIcon size="18px" iconName="search" />;
      };

      const NoDataComponent = () => <NoDataOverlay message={noDataMessage} />;

      const customComponents = {
        Toolbar: hasToolbar ? Toolbar : null,
        BaseCheckbox: CheckboxCell,
        OpenFilterButtonIcon: FilterIcon,
        QuickFilterIcon: QuickFilterIcon,
        Footer: hasPagination ? FooterWithPagination : NoPaginationFooter,
        FilterPanelDeleteIcon: RemoveIcon,
        NoRowsOverlay: NoDataComponent,
        LoadingOverlay,
        Row: (props: GridRowProps) => (
          <div onMouseEnter={() => onRowHover?.(props)}>
            <GridRow {...props} />
          </div>
        ),
        DetailPanelExpandIcon: (props: any) => <ExpandMoreIcon sx={{ fontSize: "40px", display: "flex" }} {...props} />,
        DetailPanelCollapseIcon: (props: any) => (
          <ExpandMoreIcon sx={{ fontSize: "40px", display: "flex" }} {...props} />
        ),
      };

      const customComponentProps = {
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          selectedRows,
          tableTitle,
          handleDelete,
          handleEdit,
          setIsTagModalOpen,
          selectedRowsFilterOn,
          setSelectedRowsFilterOn,
          hasOnlyDefaultToolbar,
        },
        filterProps: { debounceMs: 500 },
        filterPanel: {
          filterFormProps: {
            linkOperatorInputProps: {
              variant: "outlined",
              size: "small",
            },
            columnInputProps: {
              variant: "outlined",
              size: "small",
              sx: { mt: "auto" },
            },
            operatorInputProps: {
              variant: "outlined",
              size: "small",
              sx: { mt: "auto" },
            },
            valueInputProps: {
              variant: "outlined",
              size: "small",
              sx: { mt: "auto" },
            },
          },
          sx: tableComponentStyles,
        },
      };

      const handleSetSelectedRows = (ids: GridSelectionModel) => {
        const selectedIDs = new Set(ids);
        const selectedRowData: GridRowsProp = (rows ?? []).reduce<GridRowsProp>((acc: any, curr: any) => {
          if (selectedIDs.has(curr.id.toString())) {
            return acc.concat(curr);
          } else {
            return acc;
          }
        }, []);
        setSelectedRows?.(selectedRowData);
      };

      return (
        <ThemeProvider theme={theme}>
          <Box sx={withWrapper ? tableWrapper : null}>
            <TableContainerSC withMargin={withMargin}>
              <StyledContainer props={{ height: tableHeight }}>
                <DataGrid
                  //@ts-ignore
                  props={{ height: tableHeight, initialstate: { pinnedColumns: { left: [], right: [] } } }}
                  rows={rows}
                  columns={(columns as unknown) as Column<R, E>[]}
                  checkboxSelection={checkboxSelection}
                  rowHeight={rowHeight ?? 50}
                  headerHeight={headerHeight ?? 50}
                  pagination
                  disableColumnResize={disableColumnResize}
                  pageSize={props.pageSize ?? 20}
                  rowsPerPageOptions={[10, 20, 50]}
                  components={customComponents}
                  componentsProps={customComponentProps}
                  onRowClick={handleOnRowClick}
                  disableSelectionOnClick
                  onSelectionModelChange={handleSetSelectedRows}
                  loading={loading}
                  selectionModel={selectedRows}
                  keepNonExistentRowsSelected={keepNonExistentRowsSelected}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={getDetailPanelHeight}
                />
              </StyledContainer>
            </TableContainerSC>
          </Box>
        </ThemeProvider>
      );
    },
    [DataGrid]
  );
  return { DataGrid: ExtendedDataGrid, ...rest };
};
export default useDataGrid;
