import { styled } from "@mui/material";

export const ChartsContainerSC = styled("div")(
  () => `
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  margin-left: -8px;
  flex: 1;
  `
);

export const TitleAndPickerContainerSC = styled("div")(
  () => `
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  `
);

export const TitleButtonBoxSC = styled("div")(
  () => `
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      align-items: center;
      align-self: center;
      margin-bottom: 2vh;
      `
);

export const GraphBoxSC = styled("div")(
  () => `
      display: flex;
      flex-direction: column;
      min-width: fit-content;
      max-width: 48%;
      flex: 2;
    `
);

export const tooltipCommonStyles = {
  fontWeight: 400,
  color: "#51585D",
  fontSize: "16px",
};
