import {
  fieldMiddleWares as deviceFieldMiddleWares,
  queryMiddleWares as deviceQueryMiddleWares,
} from "components/templates/QueryBuilder/middleware/Device";
import {
  queryMiddleWares as assetDeviceQueryMiddleWares,
  fieldMiddleWares as assetDeviceFieldMiddleWares,
} from "components/templates/QueryBuilder/middleware/AssetDevice";
import { FieldMiddleWares, QueryMiddleWares } from "components/templates/QueryBuilder/types";

interface MiddlewareMappingsType {
  [key: string]: { fieldMiddleWares: FieldMiddleWares; queryMiddleWares: QueryMiddleWares };
}

export const middleWareMappings: MiddlewareMappingsType = {
  Device: { fieldMiddleWares: deviceFieldMiddleWares, queryMiddleWares: deviceQueryMiddleWares },
  AssetDevice: { fieldMiddleWares: assetDeviceFieldMiddleWares, queryMiddleWares: assetDeviceQueryMiddleWares },
};
