import React, { useCallback } from "react";
import { useAuthorization } from "./useAuthorization";

export const AuthorizationComponent: React.FC<{}> = ({ children }) => {
  const isAuthDone = useAuthorization();
  const secureChildren = useCallback(() => {
    if (!isAuthDone) return null;
    return <>{children}</>;
  }, [isAuthDone, children]);
  return secureChildren();
};
