import { UserManager, User } from "oidc-react";
import { WebStorageStateStore } from "oidc-client-ts";

const authentication = new UserManager({
  authority:
    process.env.REACT_APP_REMOTE_ENV === "prod"
      ? process.env.REACT_APP_OPENID_AUTHORITY_PROD
      : process.env.REACT_APP_OPENID_AUTHORITY,
  client_id:
    process.env.REACT_APP_REMOTE_ENV === "prod"
      ? process.env.REACT_APP_OPENID_CLIENT_ID_PROD
      : process.env.REACT_APP_OPENID_CLIENT_ID,
  redirect_uri:
    process.env.REACT_APP_REMOTE_ENV === "prod"
      ? process.env.REACT_APP_OPENID_REDIRECT_URI_PROD
      : process.env.REACT_APP_OPENID_REDIRECT_URI,
  response_type: "code",
  scope: "openid profile email role company products",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silent_redirect_uri:
    process.env.REACT_APP_REMOTE_ENV === "prod"
      ? process.env.REACT_APP_OPENID_REDIRECT_URI_PROD
      : process.env.REACT_APP_OPENID_REDIRECT_URI,
});

authentication.events.addSilentRenewError((error) => {
  console.error("Silent renew error:", error);
  window.location.href =
    process.env.REACT_APP_REMOTE_ENV === "prod"
      ? process.env.REACT_APP_OPENID_REDIRECT_URI_PROD ?? ""
      : process.env.REACT_APP_OPENID_REDIRECT_URI ?? "";
});

export const oidcConfig = {
  onBeforeSignIn: () => {
    const intitalref = window.location.href;
    window.localStorage.setItem("LitrackInitialref", intitalref);
  },
  onSignIn: (user: User | null) => {
    const intitalref = window.localStorage.getItem("LitrackInitialref");
    if (intitalref) window.location.href = intitalref;
  },
  userManager: authentication,
  location: window.location,
};
export default authentication;
