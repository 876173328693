export default {
  name: "Tag",
  plural: "Tags",
  columns: {
    id: "ID",
    tagValue: "Tag Name",
    createdAt: "Erstellt am",
    modifiedAt: "Geändert am",
  },
};
