import { DataGridPro, GridColDef, GridStateApi } from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/entityMappings";
import * as React from "react";
import { BaseRow, DataGridFactoryParams, GridWrapperProps } from "./types";
import { makeGridState } from "./gridStatePersist";
import { useRecoilState, useRecoilValue } from "recoil";
import debounce from "lodash/debounce";
import { GridStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { selectedRowsFilterAtom } from "..";

export function DataGridFactory<R extends BaseRow, E extends keyof EntityMappings>({
  apiRef,
  executeQuery,
  DataGridComponent = DataGridPro,
  tableMode = "server",
  persistance,
  tableId,
  persistDelay = 1000,
}: DataGridFactoryParams) {
  const GridWrapper: React.FC<GridWrapperProps<R, E>> = ({ rows, columns, ...props }) => {
    const selectedRowsFilterOn = useRecoilValue(selectedRowsFilterAtom);
    const [rowCount, setRowCount] = React.useState<number>(0);
    const pagination = props.pagination ?? true;

    const gridRows = React.useMemo(() => rows?.slice(0, props.pageSize ?? 99) ?? [], [rows]);

    const pageInfo = apiRef?.current?.state?.pagination ?? { pageSize: 0, page: 0 };
    /* eslint-disable */
    const rowsCalc = (pageInfo.page + 1) * pageInfo.pageSize;
    const rowCountPre =
      (rows?.length ?? 0) > gridRows.length ? rowsCalc + 1 : pageInfo.page * pageInfo.pageSize + gridRows.length;



    React.useEffect(() => {
      setRowCount((curr) => {
        return Math.max(...[rowCountPre, curr]);
      });
    }, [rowCountPre, setRowCount]);

    React.useEffect(() => {
      if(!selectedRowsFilterOn) return
      setRowCount(0)
    }, [selectedRowsFilterOn]);

    const serverSideLogic = tableMode === "server";
    const tableState = makeGridState(tableId, persistance);

    const [persistedTabelState, setTableState] = useRecoilState(tableState);


    const persistState = React.useCallback(
      debounce((state: GridStateApi<GridStatePro>["state"]) => {
        if (persistance)
          setTableState(state);
      }, persistDelay),
      []
    );



    return (
      <DataGridComponent
        {...props}
        experimentalFeatures={{ rowPinning: true }}
        initialState={persistance ? persistedTabelState : undefined}
        apiRef={apiRef}
        processRowUpdate={(newV, oldV) => {
          return newV;
        }}
        filterMode={tableMode}
        sortingMode={tableMode}
        paginationMode={tableMode}
        onStateChange={(state, event, details) => {
          if (props.onStateChange) props.onStateChange(state, event, details)
          persistState(state)
        }}
        onFilterModelChange={(model, details) => {
          if (serverSideLogic) executeQuery();
          setRowCount(0);
          if (props.onFilterModelChange) props.onFilterModelChange(model, details);
        }}
        onSortModelChange={(model, details) => {
          if (serverSideLogic) executeQuery();
          if (props.onSortModelChange) props.onSortModelChange(model, details);
        }}
        onPageChange={(page, details) => {
          if (serverSideLogic) executeQuery();
          if (props.onPageChange) props.onPageChange(page, details);
        }}
        onPageSizeChange={(pageSize, details) => {
          if (serverSideLogic) executeQuery();
          if (props.onPageSizeChange) props.onPageSizeChange(pageSize, details);
        }}
        pagination={pagination}
        rowCount={pagination ? rowCount : undefined}
        rows={gridRows}
        columns={columns as GridColDef[]}
      />
    );
  };

  return GridWrapper;
}
