// eslint-disable-next-line filenames/match-exported
import React, { useCallback, useState } from "react";
import translations from "translations";
import { FormProps } from "../index.d";
import { createRoleData, isButtonDisabled } from "../RecoilState/recoilState";
import { useRecoilState, useSetRecoilState } from "recoil";
import CreateRoleEditor from "./CreateRoleEditor";
import { useCreateRolesMutation } from "graphqlBase/Settings/__generated__/createRoles";
import { GetRolesDocument } from "graphqlBase/Settings/__generated__/getRoles";
import { SaveButton } from "../SaveButton";
import { styled, Box, Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useStyles } from "../UserRolesManager/utils";
import FormModal from "../FormModal";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import useBlockHistory, { UseBlockHistoryCb } from "routes/useBlockHistory";

const DropdownWrapperSC = styled("div")({
  zIndex: 999,
  backgroundColor: "#fff",
  height: "100%",
  minHeight: "40vh",
  padding: "52px 45px 100px 45px",
});

const CreateRole: React.FC<FormProps> = () => {
  const history = useHistory();
  const [createRoleMutation] = useCreateRolesMutation({
    refetchQueries: [{ query: GetRolesDocument }],
  });
  const setIsDisabled = useSetRecoilState(isButtonDisabled);
  const [roleToSave, setRoleToSave] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { setBlockCallBack, continueAfterBlock, setPreventPageClose } = useBlockHistory();
  const [roleData, setRoleData] = useRecoilState<{ description: string; name: string }>(createRoleData);
  const { backButton, saveButtonCreateEditRole, titleCreateRole } = useStyles({});
  const createRole = async () => {
    const { data } = await createRoleMutation({
      variables: {
        roles: roleData,
      },
    });

    return data;
  };

  const TriggerBlocker = useCallback(
    (block: boolean) => {
      const blockerCb: UseBlockHistoryCb = () => {
        setOpenModal(true);
        return false;
      };
      setBlockCallBack(() => (block ? blockerCb : undefined));
      setPreventPageClose(block);
    },
    [setBlockCallBack, setPreventPageClose]
  );

  const doMutations = () => {
    TriggerBlocker(false);
    setRoleToSave(true);
    return createRole();
  };
  const cancelRoleToSave = () => {
    setRoleToSave(false);
  };
  const cancelDiscardChanges = () => {
    setOpenModal(false);
  };

  if (roleData.name.length === 0) {
    setIsDisabled(true);
  } else {
    setIsDisabled(false);
  }

  const handleClick = () => {
    history.push(`/settings/userManagement/userRolesManager`);
    setRoleData({ description: "", name: "" });
  };

  return (
    <>
      <Box sx={{ pb: "81px", pt: "17px" }}>
        <DropdownWrapperSC>
          <div className={titleCreateRole}>
            <Button className={backButton} color="inherit" disableTouchRipple onClick={() => handleClick()}>
              <TrendingFlatIcon />
            </Button>
            <Typography variant="h2">{translations.pages.userManagement.button.create.role}</Typography>
          </div>
          <CreateRoleEditor />
          <div className={saveButtonCreateEditRole}>
            <SaveButton doMutations={() => doMutations()} setBlocked={TriggerBlocker} />
          </div>
        </DropdownWrapperSC>
      </Box>
      <FormModal
        handleConfirm={() => handleClick()}
        handleCancel={cancelRoleToSave}
        isOpen={roleToSave}
        titles={translations.pages.userManagement.userRoleManager.useRoleCreate}
        message={<>{translations.pages.userManagement.userRoleManager.createdUserRole}</>}
        confirmLabel={translations.pages.userManagement.userRoleManager.label}
        cancelLabel={translations.globals.button.cancel}
      />
      <FormModal
        handleConfirm={() => continueAfterBlock()}
        handleCancel={cancelDiscardChanges}
        isOpen={openModal}
        titles={translations.pages.userManagement.userRoleManager.discardChanges}
        message={<>{translations.pages.userManagement.userRoleManager.DiscardMessage}</>}
        confirmLabel={translations.globals.button.yes}
        cancelLabel={translations.globals.button.cancel}
      />
    </>
  );
};

export default CreateRole;
