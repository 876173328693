import { styled } from "@mui/material";
import PLHeaderPortal from "components/patternLib/PLHeaderPortal";
import React from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";

export const NavContainer = styled("div")(
  () => `
    display: grid;
    grid-column-start: 2;
    grid-template-columns: 3fr auto;
    grid-row-start: 1;
`
);

export const HeaderWrapperSC = styled("div")(
  () => `
  height: fit-content;
  width: 100%;
  box-shadow: 0px 3px 5px rgba(58, 64, 70, 0.1);
  z-index: 50;
  position: relative;
  `
);

const Header: React.FC<{}> = ({}) => {
  const history = useHistory();

  return (
    <HeaderWrapperSC>
      <PLHeaderPortal
        brandLogo="/Logo-web.png"
        padding="0 20px 0 100px"
        lhClickBrandLogo={() => history.push("/")}
        moreSlotWidth="100%"
      />
    </HeaderWrapperSC>
  );
};

export default Header;
