import { SortEnumType } from "graphqlBase/types";
import { useCallback, useEffect, useState } from "react";
import { useGetSensorMeasurementHistoriesForDeviceLazyQuery } from "graphqlBase/SensorMeasurementHistories/__generated__/getsensorMeasurementHistoryForDevice";
import { getStringsSave } from "translations";
import useGetSensorMeasurements from "./useGetSensorMeasurements";

const POLL_INTERVAL = 120;

export const capabilitiesSort = ["localeTimeMeasured", "Humidity", "Temperature"];
let refetchInterval: NodeJS.Timeout | undefined;

const useGetSensorMeasurementHistory = (deviceId: string) => {
  const variables = { where: { deviceId: { eq: deviceId } }, order: { utcTimeMeasured: "DESC" as SortEnumType } };
  const [
    query,
    { data: sensorMeasurementsResult, loading: loadingSensorMeasurements, refetch },
  ] = useGetSensorMeasurementHistoriesForDeviceLazyQuery({
    variables,
    fetchPolicy: "cache-and-network",
  });

  const refetchFunc = useCallback(
    () => () => {
      if (refetch) refetch().catch(console.log);
    },

    [refetch]
  );

  useEffect(() => {
    refetchInterval = setInterval(refetchFunc, POLL_INTERVAL * 1000);
    return () => {
      if (refetchInterval) clearInterval(refetchInterval);
    };
  }, [refetchFunc]);

  const [latestDataCurrent, setLatestDataCurrent] = useState<Array<{ key: string; value: string }>>([]);
  const [latestDate, setLatestDate] = useState<Date>(new Date("1970-01-01"));

  const { data, loadingcapabilities } = useGetSensorMeasurements(sensorMeasurementsResult?.sensorMeasurementHistories);
  useEffect(() => {
    if (new Date(data[0]?.timeStamp ?? "1970-01-01") <= latestDate || !data.length) return;

    const latestData = capabilitiesSort.reduce<Array<{ key: string; value: string }>>(
      (latestDataInner, capabilityName) => {
        if (capabilityName === "localeTimeMeasured") {
          setLatestDate(new Date(data[0]?.timeStamp ?? "1970-01-01"));
          return latestDataInner.concat({
            key: getStringsSave(`entities.capability.capabilityNames.utcTimeMeasured`),
            value: data[0]?.localeTimeMeasured ?? "",
          });
        }
        const latestEntry = data.find((d) => d.capabilityKey === capabilityName);
        return latestDataInner.concat({
          key: getStringsSave(`entities.capability.capabilityNames.${capabilityName}`),
          value: latestEntry?.valueString ?? "",
        });
      },
      []
    );

    setLatestDataCurrent(latestData);
  }, [data, latestDate]);

  return {
    data,
    latestData: latestDataCurrent,
    loading: loadingSensorMeasurements || loadingcapabilities,
    refetch: refetchFunc,
    query,
    variables,
  };
};

export default useGetSensorMeasurementHistory;
