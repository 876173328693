export default {
  name: "Device Notification",
  plural: "Device Notifications",
  alertRule: "Alert Rule",
  columns: {
    id: "ID",
    notificationDefinitionId: "Notification Definition ID",
    actualValue: "Actual Value",
    value: "Value",
    notificationSend: "Date",
    createdAt: "Created At",
    modifiedAt: "Modified At",
  },
};
