/* eslint-disable filenames/match-exported */
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/entityMappings";
import * as React from "react";
import { addNewRowPre } from "./addNewRowPre";
import { DataGridFactory } from "./DataGridFactory";
import useOnCellEditCommit from "./hooks";
import { makeColumnsPre } from "./makeColumnsPre";
import { makeSeverSideFilterPre } from "./makeSeverSideFilterPre";
import { BaseRow, useMakeDataGridProps } from "./types.d";
import { useExecuteQuery } from "./useExecuteQuery";

const useMakeDataGrid = <R extends BaseRow, E extends keyof EntityMappings>(params: useMakeDataGridProps<R, E>) => {
  const {
    query,
    variables,
    DataGridComponent,
    tableMode,
    persistance,
    tableId,
    persistDelay,
    filterOverrides,
    quickfilterSeperatorFunc,
  } = params;
  const apiRef = useGridApiRef();
  const { onCellEditCommit, submitCellChanges } = useOnCellEditCommit({});
  const executeQuery = useExecuteQuery({ query, apiRef, variables, quickfilterSeperatorFunc });

  return React.useMemo(() => {
    return {
      DataGrid: DataGridFactory<R, E>({
        apiRef,
        executeQuery,
        DataGridComponent,
        tableMode,
        persistance,
        tableId,
        persistDelay,
      }),
      getRowCount: () => apiRef.current.getAllRowIds().length,
      useMakeColumns: makeColumnsPre<R, E>(params),
      makeSeverSideFilter: makeSeverSideFilterPre<R, E>(filterOverrides ?? {}),
      submitCellChanges,
      apiRef: apiRef,
      addNewRow: addNewRowPre<Partial<EntityMappings[E]["create"]>>(apiRef),
    };
  }, [executeQuery]);
};

export default useMakeDataGrid;
