import { styled } from "@mui/material";

export const LeftTileWrapperSC = styled("div")(
  ({ theme }) => `
   background-color: ${theme.palette.background.paper};
    margin: 36px 0 36px 0;
    padding: 30px;
    min-width: fit-content;
`
);

export const RightTileWrapperSC = styled("div")(
  ({ theme }) => `
    background-color: ${theme.palette.background.paper};
    margin: 36px 0 36px 36px;
    padding: 30px;
    height: fit-content;
`
);

export const NoDataContainerSC = styled("div")(
  ({ height }: { height?: string | number }) => `
      height: ${height ?? "30vh"};
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    `
);
