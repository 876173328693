export default {
  name: "unit",
  plural: "units",
  columns: {
    deviceCapability: "device capability",
    deviceModelCapability: "device model capability",
    id: "ID",
    isDeleted: "is deleted",
    tenantId: "tenant ID",
    unitName: "unit name",
    unitSymbol: "unit symbol",
    uri: "uri",
  },
};
