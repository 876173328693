import { green, orange } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import colors from "../colors";
import * as React from "react";
import { muiCheckboxOverrides } from "./Checkbox";
import { muiTableRowOverrides } from "./TableRow";
import { muiTextFieldOverrides } from "./TextField";
import { muiTableCellOverrides } from "./TableCell";
import { muiTableContainerOverrides } from "./TableContainer";
import { muiPaperrOverrides } from "./Paper";
import { muiButtonBaseOverrides } from "./ButtonBase";
import { muiToolbarOverrides } from "./ToolBar";
import { muiTablePaginationOverrides } from "./TablePagination";
import { muiIconOverrides } from "./Icon";
import { muiButtonOverrides } from "./Button";
import { muiListOverrides } from "./List";

const fontFamily = "var(--copytext-font-family)";
const fontFamilyHeading = "var(--headline-font-family)";
const fontWeightBlack = 800;

const outerTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        sx: {
          ...muiButtonBaseOverrides?.root,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          ...muiButtonOverrides?.root,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          ...muiCheckboxOverrides?.root,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          ...muiTextFieldOverrides?.root,
        },
      },
    },
    MuiList: {
      defaultProps: {
        sx: {
          ...muiListOverrides?.root,
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        sx: {
          ...muiTableRowOverrides.root,
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        sx: {
          ...muiTableCellOverrides.root,
        },
      },
    },
    MuiTableContainer: {
      defaultProps: {
        sx: {
          ...muiTableContainerOverrides.root,
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        sx: {
          ...muiToolbarOverrides.root,
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        sx: {
          ...muiIconOverrides.root,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          ...muiPaperrOverrides.root,
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.brandYellow,
    },
    secondary: { main: colors.steelBlue },
    success: {
      main: colors.green,
    },
    warning: { main: colors.orange },
    error: {
      main: colors.red,
    },
    text: {
      primary: colors.tBlack,
    },
    background: {
      default: "#F1F3F6",
      paper: "#FFFFFF",
    },
  },
  colors,
  typography: {
    htmlFontSize: 10,
    fontFamily,
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
});
const h1 = {
  fontWeight: 800,
  fontFamily: "LiebherrText-Bold",
  fontSize: "18px",
  lineHeight: 1.9,
  marginBottom: "18px",
};

outerTheme.typography.body1 = {
  lineHeight: 1,
  "& .MuiTypography-h6": {
    ...h1,
  },
};

outerTheme.typography.body2 = {
  lineHeight: 2.8,
  "& .MuiTypography-h6": {
    ...h1,
  },
};
const WithTheme: React.FC<{}> = ({ children }) => {
  return <ThemeProvider theme={outerTheme}>{children}</ThemeProvider>;
};
export default WithTheme;
