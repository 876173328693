import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetsForOpenAssignmentQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetSortInput> | Types.AssetSortInput>;
}>;


export type GetAssetsForOpenAssignmentQuery = { assets: Array<{ __typename?: 'Asset', id: string, assetDevice: Array<{ __typename?: 'AssetDevice', id: string, createdAt?: Maybe<string>, device: { __typename?: 'Device', id: string, serialNo: string, deviceName: string, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string } } }> }> };


export const GetAssetsForOpenAssignmentDocument = gql`
    query getAssetsForOpenAssignment($where: AssetFilterInput, $take: Int, $skip: Int, $order: [AssetSortInput!]) {
  assets(where: $where, take: $take, skip: $skip, order: $order) {
    id
    assetDevice {
      id
      createdAt
      device {
        id
        serialNo
        deviceName
        deviceType {
          id
          deviceTypeName
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssetsForOpenAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssetsForOpenAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsForOpenAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsForOpenAssignmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetsForOpenAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetsForOpenAssignmentQuery, GetAssetsForOpenAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetsForOpenAssignmentQuery, GetAssetsForOpenAssignmentQueryVariables>(GetAssetsForOpenAssignmentDocument, options);
      }
export function useGetAssetsForOpenAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetsForOpenAssignmentQuery, GetAssetsForOpenAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetsForOpenAssignmentQuery, GetAssetsForOpenAssignmentQueryVariables>(GetAssetsForOpenAssignmentDocument, options);
        }
export type GetAssetsForOpenAssignmentQueryHookResult = ReturnType<typeof useGetAssetsForOpenAssignmentQuery>;
export type GetAssetsForOpenAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssetsForOpenAssignmentLazyQuery>;
export type GetAssetsForOpenAssignmentQueryResult = Apollo.QueryResult<GetAssetsForOpenAssignmentQuery, GetAssetsForOpenAssignmentQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
