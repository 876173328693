/* eslint-disable react/jsx-max-depth */
import { ApolloProvider } from "@apollo/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import { RecoilRoot } from "recoil";
import theme from "themes/theme";
import client from "./apollo/client";
import MetaDecorator from "./components/templates/MetaDecorator";
import Router from "./routes/Router";
import StartupOperations from "components/organisms/StartupOperations";
import AuthenticationCompontent from "authentication/AuthenticationCompontent";
import { AuthorizationComponent } from "authorization";
import ErrorBoundary from "globalErrorhandling/ErrorBoundary";
import ErrorComponent from "globalErrorhandling/ErrorComponent";
import { RecoilTunnel, RecoilDevTools } from "recoil-toolkit";
import { CubeProvider } from "@cubejs-client/react";
import { cubejsApi } from "cubejs/config/cubejsApi";
import { LicenseInfo } from "@mui/x-license-pro";
import "leaflet.markercluster";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_KEY ?? "");

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }
declare module "@mui/material/styles" {
  interface TypographyVariants {
    h4Black: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h4Black?: React.CSSProperties;
  }
}

function App() {
  return (
    <AuthenticationCompontent>
      <AuthorizationComponent>
        <RecoilRoot>
          <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CubeProvider cubejsApi={cubejsApi}>
                  <ErrorBoundary>
                    <StartupOperations>
                      <CssBaseline />
                      <MetaDecorator />
                      <Router />
                      <RecoilTunnel />
                      <RecoilDevTools forceSerialize />
                      <ErrorComponent />
                    </StartupOperations>
                  </ErrorBoundary>
                </CubeProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </ApolloProvider>
        </RecoilRoot>
      </AuthorizationComponent>
    </AuthenticationCompontent>
  );
}

export default App;
