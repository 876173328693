import query from "./query/en";

export default {
  label: "Groups",
  createGroup: "Create New Group",
  updateGroup: "Edit Group",
  groupCreatedTitle: "Group created",
  groupCreatedMessage: "Your group has been successfully created.",
  groupInfo: "Group Info",
  groupDetails: "Group Details",
  viewGroup: "View Group",
  noGroupDescription: "No description provided",
  seeEntityGroups: (entityName: string) => `${entityName} Groups`,
  assetGroup: {
    label: "Asset Group",
    labelPlural: "Asset Groups",
    myAssetGroups: "My Asset Groups",
    createAssetGroup: "Create Asset Group",
    noDataMessage: "There are no asset groups to show",
    amountOfAssets: "Current Amount of Assets",
    assetGroupDetails: {
      label: "Asset Group Details",
      currentPositionTile: {
        label: "Current Position",
        noDataMessage: "There are no positions recorded for these assets.",
      },
      groupWorkloadTile: {
        label: "Group Workload Overview",
        noDataMessage: "No data available for this assets.",
      },
    },
  },
  deviceGroup: {
    label: "Device Group",
    labelPlural: "Device Groups",
    myDeviceGroups: "My Device Groups",
    noDataMessage: "There are no device groups to show",
  },
  deleteGroup: "Delete Group",
  deleteGroupMessage: `By clicking "Confirm" the selected group(s) will be deleted. You’ll not be able to restore this data.`,
  query,
};
