import { AssetDeviceUnassignmentMutationVariables } from "graphqlBase/AssetDevices/__generated__/assetDeviceUnassignment";
import { atom } from "recoil";

interface AssetDeviceUnpairing {
  assetName: string;
  deviceName: string;
  variables: AssetDeviceUnassignmentMutationVariables;
}

export const assetDeviceUnpairingModalStateAtom = atom({
  key: "assetDeviceUnpairingModalStateAtom",
  default: false,
});

export const assetDeviceUnpairingAtom = atom<AssetDeviceUnpairing | undefined>({
  key: "assetDeviceUnpairingAtom",
  default: undefined,
});
