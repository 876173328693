import PLDatepicker from "components/patternLib/form/PLDatepicker";
import React from "react";
import { useSetRecoilState } from "recoil";
import { sensorMeasurementHistoryAggregateSelectorFamily } from "../../Configuration/recoilState";

const ConnectionsMonitoringDatePicker = () => {
  const now = new Date().toISOString();
  const setStartDate = useSetRecoilState(sensorMeasurementHistoryAggregateSelectorFamily("startDate"));
  const setEndDate = useSetRecoilState(sensorMeasurementHistoryAggregateSelectorFamily("endDate"));
  const handleDate = (event: any) => {
    if (event.detail.value && event.detail.value.start !== event.detail.value.end) {
      const newStartDate = new Date(event.detail.value.start).toISOString();
      const newEndDate = new Date(event.detail.value.end).toISOString();
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };
  return <PLDatepicker dateRange width="300px" maximumDate={now} lhChange={handleDate} />;
};

export default ConnectionsMonitoringDatePicker;
