import styled from "@emotion/styled";
import { FormControlLabel } from "@material-ui/core";
import { TreeItem, treeItemClasses, TreeItemProps, TreeView } from "@mui/lab";
import { alpha } from "@mui/material";
import Can from "authorization";
import ConfirmAndDelete from "components/molecules/ConfirmAndDelete";
import PLCheckbox from "components/patternLib/form/PLCheckbox";
import PLIcon from "components/patternLib/PLIcon";
import { genericDeleteParams } from "components/templates/table-factory/Table/Cells/actionsCellFactory/recoilStates";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import translations from "translations";
import { CheckboxState } from ".";
import { FlattenData } from "..";
import { isSearchbarUsedAtom } from "../recoilBox";

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
  [`& .MuiTreeView-root`]: { height: "100%" },
  [`& .MuiTreeItem-content`]: {
    flexDirection: "row-reverse",
    padding: "11px 10px",
  },
  [`& .MuiTreeItem-content.Mui-selected.Mui-focused`]: {
    backgroundColor: "white",
  },
  [`& .MuiTreeItem-label`]: { marginLeft: "12px" },
  [`& .Mui-selected `]: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  [`& .Mui-focused`]: {
    backgroundColor: "white",
  },
  [`& .MuiFormControlLabel-label`]: {
    fontFamily: "var(--form-text-font-family)",
    fontSize: "var(--form-size-text)",
    marginLeft: "-16px",
  },
  [`& .${treeItemClasses.group}`]: {
    paddingLeft: 25,
    marginBottom: 22,
    marginTop: 11,
    borderLeft: `1px solid ${alpha("#C2C2C2", 0.4)}`,
  },
  [`& .labelIcons`]: {
    display: "flex",
    justifyContent: "space-between",
    "&:hover .deleteEditIcons": {
      display: "flex",
    },
  },
  [`& .deleteEditIcons`]: { display: "none" },
}));

interface CheckboxListProps {
  afterDelete: () => void;
  getStateForId: (id: string) => CheckboxState | undefined;
  idsToRender?: string[];
  indentLevel?: number;
  items: FlattenData[];
  onClick?: (id: string) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  getStateForId,
  idsToRender = [],
  onClick = () => {},
  afterDelete,
}) => {
  const [deleteParams, setDeleteParams] = useRecoilState(genericDeleteParams);
  const isSearchbarUsed = useRecoilValue(isSearchbarUsedAtom);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const history = useHistory();

  if (!idsToRender.length) {
    idsToRender = items.filter((i) => !i.parentId).map((i) => i.id);
  }

  const getChildNodes = (parentId: string) => {
    const nodeItems = items.filter((i) => i.parentId === parentId);
    const childrenIdsToRender = nodeItems.map((i) => i.id);
    if (!nodeItems.length) return null;
    return (
      <CheckboxList
        items={items}
        idsToRender={childrenIdsToRender}
        onClick={onClick}
        getStateForId={getStateForId}
        afterDelete={afterDelete}
      />
    );
  };

  useEffect(() => {
    if (isSearchbarUsed) {
      setExpanded(items.filter((i) => !i.parentId).map((i) => i.id));
    } else {
      setExpanded([]);
    }
  }, [isSearchbarUsed, items]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  return (
    <>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<PLIcon iconName="arrow-up" />}
        defaultExpandIcon={<PLIcon iconName="arrow-down" />}
        expanded={expanded}
        onNodeToggle={handleToggle}
        sx={{ flexGrow: 1, maxWidth: 400 }}
      >
        {idsToRender.map((id, index) => {
          const item = items.find((i) => i.id === id);
          const checkboxState = getStateForId(id);
          return (
            <div key={item?.id}>
              <StyledTreeItem
                nodeId={id}
                sx={{
                  borderLeft: `8px solid ${item?.storageAreaTypeColor}`,
                  borderBottom: !item?.parentId ? "1px solid #D3D8DD" : "none",
                }}
                label={
                  <div className="labelIcons">
                    <FormControlLabel
                      control={
                        <PLCheckbox
                          lhChange={() => onClick(item?.id ?? "")}
                          onClick={(e) => e.stopPropagation()}
                          checked={checkboxState === CheckboxState.CHECKED}
                          indeterminate={checkboxState === CheckboxState.INDETERMINATE}
                        />
                      }
                      label={
                        !item?.parentId
                          ? `${item?.storageAreaTypeName} (${item?.storageArea?.length})`
                          : item.storageAreaName
                      }
                    />
                    {item?.parentId && (
                      <div className="deleteEditIcons">
                        <Can I="read" a="UpdateGeofenceUI">
                          <PLIcon
                            iconName="edit"
                            size="24px"
                            slot="icon"
                            onClick={() => history.push(`/fleetInventory/editGeoFence/${item.id}`)}
                          />
                        </Can>
                        <Can I="read" a="DeleteGeofenceUI">
                          <PLIcon
                            iconName="bin"
                            size="24px"
                            slot="icon"
                            onClick={() =>
                              setDeleteParams({
                                entity: "StorageAreas",
                                id: item.id,
                                afterDelete,
                                modalTitle: translations.pages.fleetInventory.deleteGeofenceTitle,
                                modalMessage: translations.pages.fleetInventory.deleteGeofenceModalMessage,
                              })
                            }
                          />
                        </Can>
                      </div>
                    )}
                  </div>
                }
              >
                {getChildNodes(item?.id ?? "")}
              </StyledTreeItem>
            </div>
          );
        })}
      </TreeView>
      <ConfirmAndDelete
        title={deleteParams.modalTitle ?? ""}
        message={deleteParams?.modalMessage}
        show={!!deleteParams?.id && !!deleteParams?.entity}
      />
    </>
  );
};

export default CheckboxList;
