import { useAuthorizedRoutes } from "authorization";
import PLAvatar from "components/patternLib/PLAvatar";
import PLSidebar, { PLSidebarProps } from "components/patternLib/PLSidebar";
import { useAuth } from "oidc-react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Route } from "routes/routes";
import SideBarRoute from "./SideBarRoute";

export interface PLSubRoute {
  icon: string;
  label: string;
}
export interface NavigationSidebarProps extends PLSidebarProps {
  routes: Route[];
}

const Navigator: React.FC<NavigationSidebarProps> = ({ width, lhCollapse, routes, ...rest }) => {
  const history = useHistory();

  const routesAuthorized = useAuthorizedRoutes(routes);
  const auth = useAuth();

  const renderRoutes = routesAuthorized.filter((route) => route.menuItem);

  const user = auth?.userData?.profile
    ? `${auth?.userData?.profile.given_name} ${auth?.userData?.profile.family_name}`
    : "";
  const initials =
    `${auth?.userData?.profile.given_name?.charAt(0)}${auth?.userData?.profile.family_name?.charAt(0)}` ?? "";

  return (
    <PLSidebar width={width} userName={user} userInfo="Liebherr" lhCollapse={lhCollapse} {...rest}>
      <SideBarRoute routes={renderRoutes} parentPath="" history={history} width={width} />

      <PLAvatar slot="sidebar-avatar" initials={initials} />
    </PLSidebar>
  );
};

export default Navigator;
