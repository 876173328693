import { Box, Button, styled } from "@mui/material";
import React from "react";
import { ConjunctionOption, TypedMap } from "react-awesome-query-builder";

interface ConjsProps {
  readonly?: boolean | undefined;
  disabled?: boolean | undefined;
  setConjunction?: ((conj: string) => void) | undefined;
  conjunctionOptions?: TypedMap<ConjunctionOption>;
}

const ConjunctionsButtonSC = styled(Button)(({ checked }: { checked: boolean }) => {
  return {
    minWidth: "fit-content",
    borderRadius: 0,
    height: 23,
    width: 30,
    color: "#000",
    textTransform: "none",
    fontWeight: 400,
    fontFamily: "var(--copytext-font-family)",
    fontSize: 12,
    backgroundColor: checked ? "var(--primary-color-background)" : "var(--color-steel-200)",
    "&:hover": {
      backgroundColor: checked ? "var(--primary-color-background)" : "var(--color-steel-200)",
    },
  };
});

export default ({ disabled, readonly, conjunctionOptions, setConjunction }: ConjsProps) => {
  return (
    <Box sx={{ display: disabled ?? readonly ? "none" : "flex" }}>
      {Object.values(conjunctionOptions ?? {})?.map((conjunction) => (
        <ConjunctionsButtonSC
          checked={conjunction.checked}
          onClick={() => setConjunction?.(conjunction.key)}
          variant="contained"
          disableElevation
          disableFocusRipple
          disableTouchRipple
          key={conjunction.id}
        >
          {conjunction.label}
        </ConjunctionsButtonSC>
      ))}
    </Box>
  );
};
