import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAssetTagsMutationVariables = Types.Exact<{
  createAssetTags?: Types.InputMaybe<Array<Types.InputMaybe<Types.AssetTagCreateType>> | Types.InputMaybe<Types.AssetTagCreateType>>;
}>;


export type CreateAssetTagsMutation = { createAssetTags?: Maybe<Array<Maybe<{ __typename?: 'AssetTag', id: string, tagValue: string }>>> };


export const CreateAssetTagsDocument = gql`
    mutation createAssetTags($createAssetTags: [AssetTagCreateType]) {
  createAssetTags(assetTags: $createAssetTags) {
    id
    tagValue
  }
}
    `;
export type CreateAssetTagsMutationFn = Apollo.MutationFunction<CreateAssetTagsMutation, CreateAssetTagsMutationVariables>;

/**
 * __useCreateAssetTagsMutation__
 *
 * To run a mutation, you first call `useCreateAssetTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetTagsMutation, { data, loading, error }] = useCreateAssetTagsMutation({
 *   variables: {
 *      createAssetTags: // value for 'createAssetTags'
 *   },
 * });
 */
export function useCreateAssetTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAssetTagsMutation, CreateAssetTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateAssetTagsMutation, CreateAssetTagsMutationVariables>(CreateAssetTagsDocument, options);
      }
export type CreateAssetTagsMutationHookResult = ReturnType<typeof useCreateAssetTagsMutation>;
export type CreateAssetTagsMutationResult = Apollo.MutationResult<CreateAssetTagsMutation>;
export type CreateAssetTagsMutationOptions = Apollo.BaseMutationOptions<CreateAssetTagsMutation, CreateAssetTagsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
