import PLButton from "components/patternLib/PLButton";
import * as React from "react";
import translations from "translations";
import Props from "./index.d";

const SubmitButton: React.FC<Props> = (props) => {
  const {
    disabled,
    testId,
    data: { setAndSave },
    title,
  } = props;

  return (
    <PLButton
      data-testid={testId}
      label={title ?? translations.globals.terms.save}
      onClick={() => setAndSave(true)}
      disabled={disabled}
    />
  );
};

export default SubmitButton;
