import { styled, TableCell } from "@mui/material";

export const DoughnutTableSC = styled("div")(
  () => `
        display: flex;
        flex-direction: row;
        min-width: fit-content;
        min-height: fit-content;
        justify-content: flex-start;
        height: 100%;
  `
);

export const TableSC = styled("div")(
  () => `
       height: fit-content;
       margin: 60px 0 1vh 0;
       min-width: fit-content;
       max-width: 95%;
       width: auto;

  `
);

export const ItemNameTableCellSC = styled(TableCell)(
  () => `
      padding: 5px;
       line-height: 1.5;
       height: fit-content;
       white-space: nowrap;
  `
);

export const ItemColorIconTableCellSC = styled(TableCell)(
  () => `
      padding: 5px;
     line-height: 1.5;
     height: 15,
  `
);

export const ItemColorIconBoxSC = styled("div")(
  ({ color }: { color?: string }) => `
    display: flex;  
    color: ${color ?? "rgba(0,0,0,0.1)"};
     align-items: center;
  `
);

export const ItemValueTableCellSC = styled(TableCell)(
  () => `
    padding: 5px;
    margin-left: 10px;
    line-height: 1.5;
    height: 15px;
    text-align: end;
    white-space: nowrap;
  `
);

export const TableAndButtonBoxSC = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
  `
);
