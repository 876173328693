import { Box, Typography } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { RightTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import DetailInfo, { ArrayValue } from "components/atomics/DetailInfo";
import { useGetAssetTypeForAssetGroupResultsQuery } from "graphqlBase/AssetDevices/__generated__/getAssetTypeForAssetGroupResults";
import React, { useEffect, useState } from "react";
import translations from "translations";
interface GroupResultCountTileProps {
  queryBuilderResultIds?: string[];
}

interface AssetTypeAndCountType {
  [key: string]: { label: string; value: number };
}

const titleBoxStyles = { marginBottom: "25px" };
const topListBoxStyles = {
  display: "flex",
  alignItems: "center",
  marginBottom: "15px",
  minHeight: "fit-content",
  height: "100%",
  width: "100%",
};

const cranePart = "crane";
const excavator = "excavator";
const attachment = "attachment";

const GroupResultCountTile: React.FC<GroupResultCountTileProps> = ({ queryBuilderResultIds }) => {
  const [assetTypeAndCount, setAssetTypeAndCount] = useState<ArrayValue[] | undefined>();
  const variables = {
    where: { and: [{ deviceId: { in: queryBuilderResultIds }, asset: { assetMasterDataId: { neq: null } } }] },
  };
  const { data, loading: dataLoading } = useGetAssetTypeForAssetGroupResultsQuery({ variables });

  useEffect(() => {
    if (!assetTypeAndCount && !!data?.assetDevices.length) {
      const assetCountData: AssetTypeAndCountType = {
        cranePart: { label: translations.globals.pluralTerms.craneParts, value: 0 },
        excavator: { label: translations.globals.pluralTerms.excavators, value: 0 },
        attachment: { label: translations.globals.pluralTerms.attachments, value: 0 },
      };

      if (data?.assetDevices) {
        data?.assetDevices.forEach((assetDevice) => {
          const typeLower = assetDevice.asset?.assetSource?.assetSourceName.toLowerCase();
          if (typeLower) {
            if (typeLower.includes(cranePart)) {
              assetCountData.cranePart.value = assetCountData.cranePart.value + 1;
            }
            if (typeLower.includes(excavator)) {
              assetCountData.excavator.value = assetCountData.excavator.value + 1;
            }
            if (typeLower.includes(attachment)) {
              assetCountData.attachment.value = assetCountData.attachment.value + 1;
            }
          }
        });
        setAssetTypeAndCount(Object.values(assetCountData).map(({ label, value }) => ({ label, value: `${value}` })));
      }
    }
  }, [assetTypeAndCount, data]);

  return (
    <RightTileWrapperSC>
      <Box sx={titleBoxStyles}>
        <Typography variant="h4">{translations.pages.groups.assetGroup.amountOfAssets}</Typography>
      </Box>
      <LoadingSpinner sx={{ minHeight: "10vh" }} loading={dataLoading}>
        <Box sx={topListBoxStyles}>
          <Box sx={{ width: "100%" }}>
            <DetailInfo noSeparator data={assetTypeAndCount ?? []} itemHeight="30px" />
          </Box>
        </Box>
      </LoadingSpinner>
    </RightTileWrapperSC>
  );
};
export default GroupResultCountTile;
