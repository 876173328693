import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRolesMutationVariables = Types.Exact<{
  roles?: Types.InputMaybe<Array<Types.InputMaybe<Types.RoleCreateType>> | Types.InputMaybe<Types.RoleCreateType>>;
}>;


export type CreateRolesMutation = { createRoles?: Maybe<Array<Maybe<{ __typename?: 'Role', id: string, name: string, description?: Maybe<string> }>>> };


export const CreateRolesDocument = gql`
    mutation createRoles($roles: [RoleCreateType]) {
  createRoles(roles: $roles) {
    id
    name
    description
  }
}
    `;
export type CreateRolesMutationFn = Apollo.MutationFunction<CreateRolesMutation, CreateRolesMutationVariables>;

/**
 * __useCreateRolesMutation__
 *
 * To run a mutation, you first call `useCreateRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolesMutation, { data, loading, error }] = useCreateRolesMutation({
 *   variables: {
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useCreateRolesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolesMutation, CreateRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateRolesMutation, CreateRolesMutationVariables>(CreateRolesDocument, options);
      }
export type CreateRolesMutationHookResult = ReturnType<typeof useCreateRolesMutation>;
export type CreateRolesMutationResult = Apollo.MutationResult<CreateRolesMutation>;
export type CreateRolesMutationOptions = Apollo.BaseMutationOptions<CreateRolesMutation, CreateRolesMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
