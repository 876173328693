import LoadingSpinner from "components/atomics/LoadingSpinner";
import { ClickAwayListener, Drawer, DrawerProps, Typography } from "@mui/material";
import PLIcon from "components/patternLib/PLIcon";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import {
  CloseIconBoxSC,
  DrawerContentSC,
  DrawerHeaderSC,
  DrawerMainContentSC,
  DrawerTitleBoxSC,
  paperStyles,
} from "./styles";

export const testIDs = getTestIDs();

interface SidebarDrawerProps extends DrawerProps {
  buttons?: JSX.Element[];
  closeOnOutsideClick?: boolean;
  footerContent?: JSX.Element;
  loading?: boolean;
  maxWidth?: string | number;
  minWidth?: string | number;
  onClose: () => void;
  open: boolean;
  title?: string;
}

const SidebarDrawer: React.FC<SidebarDrawerProps> = ({
  open = false,
  onClose,
  loading,
  buttons,
  maxWidth,
  minWidth,
  children,
  title,
  footerContent,
  transitionDuration,
  PaperProps,
  SlideProps,
  variant,
  anchor,
  closeOnOutsideClick = true,
}) => {
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => open && closeOnOutsideClick && onClose()}
    >
      <Drawer
        // variant must be persistent to appear under the app header
        variant={variant ?? "persistent"}
        anchor={anchor ?? "right"}
        open={open}
        onClose={onClose}
        PaperProps={PaperProps ?? { style: paperStyles(maxWidth, minWidth) }}
        transitionDuration={transitionDuration}
        SlideProps={SlideProps}
      >
        <DrawerContentSC>
          <DrawerMainContentSC>
            {title && (
              <DrawerHeaderSC>
                <CloseIconBoxSC onClick={onClose}>
                  <PLIcon iconName="x" size="40px" />
                </CloseIconBoxSC>

                <DrawerTitleBoxSC>
                  <Typography variant="h5">{title}</Typography>
                </DrawerTitleBoxSC>
              </DrawerHeaderSC>
            )}
            <LoadingSpinner loading={loading ?? false}>{children}</LoadingSpinner>
          </DrawerMainContentSC>
          {footerContent}
        </DrawerContentSC>
      </Drawer>
    </ClickAwayListener>
  );
};

export default SidebarDrawer;
