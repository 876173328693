import gql from "graphql-tag";
import client from "apollo/client";

export type DeletableEntity = "AssetAttributeDataTypes" | "AssetAttributes" | "AssetAttributeUnits" | "AssetAttributeValues" | "AssetDevices" | "AssetImages" | "AssetMasterDatas" | "AssetMasterDataSources" | "AssetNotes" | "Assets" | "AssetSources" | "AssetTags" | "AssetTypeAttributeSets" | "Capabilities" | "DeviceAttributes" | "DeviceAttributeValues" | "DeviceCapabilities" | "DeviceConfigurations" | "DeviceFirmwareConfigurationSchemas" | "DeviceFirmwareReleaseTypes" | "DeviceFirmwares" | "DeviceHeartbeatCurrentValues" | "DeviceHeartbeatHistories" | "DeviceModelAttributeSets" | "DeviceModelCapabilities" | "DeviceModels" | "DeviceModelVersions" | "DeviceNotifications" | "Devices" | "DeviceTypes" | "GatewayDeviceConnections" | "GroupEntities" | "GroupEntityFilterDataTypes" | "GroupEntityFilters" | "GroupEntityFilterUiTypes" | "GroupOperators" | "Groups" | "MappingAssetTags" | "MappingAttributeAssetTypeAttributeSets" | "MappingAttributeDeviceModelAttributeSets" | "MappingDeviceConfigurationDevices" | "MappingDeviceConfigurationDeviceStatuses" | "MappingDeviceFirmwareDevices" | "MappingDeviceFirmwareDeviceStatuses" | "MappingDeviceFirmwareDeviceTypes" | "MappingDeviceNotifications" | "MappingDeviceTags" | "MappingGroupEntityFilterGroupOperators" | "MappingGroupEntityGroupEntityFilters" | "MappingNotificationScopeNotificationOperators" | "MappingNotificationUsers" | "MappingRoleScopes" | "MappingRoleUsers" | "MappingUserScopes" | "NotificationDefinitions" | "NotificationOperators" | "Notifications" | "NotificationScopes" | "Roles" | "RuleActions" | "RuleReferences" | "RuleReferenceUnits" | "RuleResultTypes" | "Rules" | "Scopes" | "SensorMeasurementCurrentValues" | "SensorMeasurementHistories" | "StorageAreas" | "StorageAreaTypes" | "Tags" | "Tenants" | "Translations" | "Units" | "UserConfigurations" | "Users"; 

export interface GenericDeleteProps {
  entity: DeletableEntity;
  afterDelete?: (data: any) => void;
}

export type IdType = string[] | string;
export type DeleteItemsFucType = (idsIn: IdType) => Promise<IdType>;

export default ({ entity, afterDelete }: GenericDeleteProps) => async (idsIn: IdType) => {
  const ids = typeof idsIn === "string" ? [idsIn] : idsIn;

  const mutation = gql`mutation GenericDelete${entity}($ids:[UUID!]){
        result: delete${entity}(ids:$ids){
           id
           __typename
         }
       }`;

  const result = await client.mutate({
    mutation,
    variables: { ids },
  });

  const deletedIds: { id: string; __typename: string }[] = result.data?.result.map((row: { id: string }) => row.id);

  if (afterDelete) afterDelete(deletedIds);

  return deletedIds;
};