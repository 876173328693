import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationScopeByNotificationIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetNotificationScopeByNotificationIdQuery = { notification?: Maybe<{ __typename?: 'Notification', id: string, notificationScope: { __typename?: 'NotificationScope', id: string } }> };


export const GetNotificationScopeByNotificationIdDocument = gql`
    query getNotificationScopeByNotificationId($id: UUID!) {
  notification(id: $id) {
    id
    notificationScope {
      id
    }
  }
}
    `;

/**
 * __useGetNotificationScopeByNotificationIdQuery__
 *
 * To run a query within a React component, call `useGetNotificationScopeByNotificationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationScopeByNotificationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationScopeByNotificationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationScopeByNotificationIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetNotificationScopeByNotificationIdQuery, GetNotificationScopeByNotificationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNotificationScopeByNotificationIdQuery, GetNotificationScopeByNotificationIdQueryVariables>(GetNotificationScopeByNotificationIdDocument, options);
      }
export function useGetNotificationScopeByNotificationIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationScopeByNotificationIdQuery, GetNotificationScopeByNotificationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNotificationScopeByNotificationIdQuery, GetNotificationScopeByNotificationIdQueryVariables>(GetNotificationScopeByNotificationIdDocument, options);
        }
export type GetNotificationScopeByNotificationIdQueryHookResult = ReturnType<typeof useGetNotificationScopeByNotificationIdQuery>;
export type GetNotificationScopeByNotificationIdLazyQueryHookResult = ReturnType<typeof useGetNotificationScopeByNotificationIdLazyQuery>;
export type GetNotificationScopeByNotificationIdQueryResult = Apollo.QueryResult<GetNotificationScopeByNotificationIdQuery, GetNotificationScopeByNotificationIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
