import { getGridStringOperators, GridRowId } from "@mui/x-data-grid-pro";
import TagChips from "components/molecules/TagChips";
import { alertFormLoadingAtom } from "components/pages/AlertManagement/AlertEditing/recoilState";
import { selectedAssetsForAlertAtom } from "components/pages/AlertManagement/AlertEditing/recoilState/selectedAssetsForAlertState";
import useDataGrid, { useFilterTableSelectedRows } from "components/templates/dataGridTable";
import {
  GetAssetsForMappingDeviceNotificationsQuery,
  ResultType,
  useGetAssetsForMappingDeviceNotificationsLazyQuery,
} from "graphqlBase/Assets/__generated__/getAssetsForMappingDeviceNotifications";
import { MappingAssetTag } from "graphqlBase/types";
import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import translations from "translations";
import useManageMappingDeviceNotifications from "../useManageMappingDeviceNotifications";

export type Asset = ResultType<GetAssetsForMappingDeviceNotificationsQuery["assets"]>;

interface MappingAssetNotificationsProps {
  notificationId?: string;
}

const MappingAssetNotifications: React.FC<MappingAssetNotificationsProps> = ({ notificationId }) => {
  const formLoading = useRecoilValue(alertFormLoadingAtom);
  
  const [queryOuter, { data: assetsData, loading: assetsLoading }] = useGetAssetsForMappingDeviceNotificationsLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const {
    query,
    selectedIds,
    setSelectedIds,
    setSelectedRowsFilterOn,
    selectedRowsFilterOn,
  } = useFilterTableSelectedRows({
    queryOuter,
    atom: selectedAssetsForAlertAtom,
    getIdsFromAtom: (rows) => rows.map((row) => row.id),
    entity: "asset",
  });
  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef } = useDataGrid<Asset, "Asset">({
    query,
    variables: {
      where: {
        assetMasterDataId: { neq: null },
        assetDevice: { any: true },
      },
    },
    tableId: `MappingAssetDeviceNotifications_${notificationId}`,
    persistance: "client",
  });

  const { loading, setSelectedRows } = useManageMappingDeviceNotifications({
    notificationId,
    apiRef,
    selectedIds,
    setSelectedIds
  });

  const columns = useMakeColumns(
    [
      {
        field: "generalItem",
        headerName: `Article name`,
        flex: 1,
        valueGetter: (params) => {
          return params.row?.assetMasterData?.generalItem ?? "";
        },
        remoteOrder: (sort) => ({ assetMasterData: { generalItem: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ filterValue }) => ({
            assetMasterData: { generalItem: filterValue },
          }),
        }),
        type: "string",
      },
      {
        field: "articleNumber",
        headerName: `Article`,
        flex: 1,
        remoteOrder: (sort) => ({ assetMasterData: { articleNumber: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ filterValue }) => ({
            assetMasterData: { articleNumber: filterValue },
          }),
        }),
        valueGetter: (params) => {
          return params.row?.assetMasterData?.articleNumber ?? "";
        },
        type: "string",
      },
      {
        field: "serialNumber",
        headerName: `Serial Number`,
        flex: 1,
        valueGetter: (params) => {
          return params.row?.assetMasterData?.serialNumber ?? "";
        },
        remoteOrder: (sort) => ({ assetMasterData: { serialNumber: sort } }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            assetMasterData: { serialNumber: filterValue },
          }),
        }),
        type: "string",
      },
      {
        field: "tags",
        headerName: translations.entities.tag.plural,
        flex: 1,
        renderCell: (params) => {
          return <TagChips mappingAssetTag={params.row?.mappingAssetTag as MappingAssetTag[]} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators(),
          filterPath: ({ filterValue }) => ({
            mappingAssetTag: { some: { assetTag: { tagValue: filterValue } } },
          }),
        }),
        type: "stringArray",
      },
    ],
    []
  );

  const selectedRows = useMemo(
    () =>
      (selectedIds ?? []).reduce<GridRowId[]>((assetDeviceIds, element) => {
        return assetDeviceIds.concat(element.id);
      }, []),
    [selectedIds]
  );
  return (
    <>
      <DataGrid
        noDataMessage={translations.pages.assetManagement.noAssetsMessage}
        tableTitle={translations.pages.assetManagement.myAssets}
        selectedRowsFilterOn={selectedRowsFilterOn}
        setSelectedRowsFilterOn={setSelectedRowsFilterOn}
        tableHeight="65vh"
        columns={columns}
        rows={assetsData?.assets}
        loading={loading || formLoading || assetsLoading}
        withMargin={false}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
    </>
  );
};

export default MappingAssetNotifications;
