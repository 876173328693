import RadioGroup from "components/molecules/RadioGroup";
import React, { useEffect } from "react";
import Props from "./index.d";
import useGetForeignKeyList from "./useGetForeignKeyList";

const ForeignKeyRadioGroup: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    title,
    pickDefaultFirst,
    foreignKeyParams,
    horizontal,
  } = props;
  const elems = useGetForeignKeyList({ foreignKeyParams }).map((elem) => {
    return { ...elem, label: elem.label.replace(" Scope", "") };
  });

  const handleChange = (item: string) => {
    setAndSave(item);
  };

  useEffect(() => {
    if (pickDefaultFirst && !state && elems?.length) setAndSave(elems[0].value);
  }, [elems, state, pickDefaultFirst]);

  return (
    <>
      <RadioGroup
        itemsMinWidth="fit-content"
        selected={state}
        horizontal={horizontal}
        onSelectItem={handleChange}
        title={title}
        items={elems}
        gap="100px"
      />
    </>
  );
};

export default ForeignKeyRadioGroup;
