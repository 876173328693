import { useCreateAssetsMutation } from "graphqlBase/Assets/__generated__/createAssets";
import {
  AssetMasterDataCreateForm,
  AssetMasterDataCreateProps,
  AssetMasterDataCreateValidateForm,
  AssetMasterDataCreateValidateFormProps,
} from "raft/AssetMasterDataForm";
import { AssetMasterDataInputCreate } from "raft/internal/schemaTypes";
import React from "react";
import { useHistory } from "react-router-dom";
import { assetCreateFormErrorsAtom } from "./assetCreateFormState";
import { useResetRecoilState } from "recoil";

const rules: Rules = {
  generalItem: [
    ({ allData }) => ((allData.generalItem?.length ?? 1) < 1 ? `The asset name must be set` : undefined),
    ({ allData }) => ((allData.generalItem?.length ?? 1) > 100 ? "The assetname is to long" : undefined),
  ],
  articleNumber: [
    ({ allData }) => ((allData.articleNumber?.length ?? 1) > 16 ? "The article number is to long" : undefined),
  ],
  serialNumber: [
    ({ allData }) => ((allData.supplierNumber?.length ?? 1) > 20 ? "The serial number is to long" : undefined),
  ],
  supplier: [({ allData }) => ((allData.supplier?.length ?? 1) > 12 ? "The supplier number is to long" : undefined)],

  supplierNumber: [
    ({ allData }) => ((allData.supplierNumber?.length ?? 1) > 3 ? "The supplier number is to long" : undefined),
  ],
};

type Rules = {
  [key in keyof AssetMasterDataInputCreate]: ((props: AssetMasterDataCreateValidateFormProps) => string | undefined)[];
};

type Errors<T> = Partial<{ [k in keyof T]: string[] }>;

const applyRules = (rules: Rules) => (
  data: AssetMasterDataCreateValidateFormProps
): Errors<AssetMasterDataInputCreate> => {
  const errors: { [key in keyof AssetMasterDataInputCreate]?: string[] } = {};
  Object.entries(rules).forEach(([key, rulesForKey]) => {
    const errorsList = (rulesForKey ?? []).reduce<string[]>((acc, rule) => {
      const error = rule(data);
      if (error) {
        return acc.concat(error);
      }
      return acc;
    }, []);
    if (errorsList.length) {
      errors[key as keyof AssetMasterDataInputCreate] = errorsList;
    }
  });
  return errors;
};

const AssetCreate: React.FC = () => {
  const [createAssets] = useCreateAssetsMutation();
  const history = useHistory();
  const resetAssetCreateErrors = useResetRecoilState(assetCreateFormErrorsAtom);

  const afterCreate: AssetMasterDataCreateProps["afterCreate"] = (data) => {
    createAssets({
      variables: {
        assets: [
          {
            assetMasterDataId: data.id ?? "",
            assetSourceId: "d2bb33b7-9e89-472c-998c-9f522bad3c07",
            customUniqueIdentifier: data.customUniqueIdentifier as string,
          },
        ],
      },
    })
      .then((res) => {
        history.goBack();
      })
      .catch(console.error);
  };

  const onUpdateDataMiddleWare: AssetMasterDataCreateProps["onUpdateDataMiddleWare"] = ({ data }) => {
    return {
      ...data,
      assetMasterDataSourceId: "d845c70b-bbb6-416d-bef0-7b1e2f7c816c",
    };
  };

  const validateForm: AssetMasterDataCreateValidateForm = (props) => {
    return applyRules(rules)(props);
  };

  const handleCancel = () => {
    resetAssetCreateErrors();
    history.goBack();
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <AssetMasterDataCreateForm
        afterCreate={afterCreate}
        onUpdateDataMiddleWare={onUpdateDataMiddleWare}
        validateForm={validateForm}
        onCancel={handleCancel}
      />
    </div>
  );
};
export default AssetCreate;
