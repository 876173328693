import { GridRowId, GridValidRowModel } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import {
  SelectedAsset,
  preMappedDeviceNotificationsAtom,
  isFormSubmittedAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import { selectedDevicesForAlertAtom } from "components/pages/AlertManagement/AlertEditing/recoilState/selectedDevicesForAlertState";
import { useGetAssetsForMappingDeviceNotificationsQuery } from "graphqlBase/Assets/__generated__/getAssetsForMappingDeviceNotifications";
import { useGetMappingDeviceNotificationsForNotificationLazyQuery } from "graphqlBase/MappingDeviceNotifications/__generated__/getMappingDeviceNotificationsForNotification";
import { useGetNotificationScopeByNotificationIdLazyQuery } from "graphqlBase/Notifications/__generated__/getNotificationScopeByNotificationId";
import { useEffect } from "react";
import { SetterOrUpdater, useRecoilState,useRecoilValue,useSetRecoilState } from "recoil";
import { SelectedDevice } from "./MappingDeviceNotificationsTable";

export default ({
  notificationId,
  apiRef,
  selectedIds,
  setSelectedIds
}: {
  notificationId?: string;
  apiRef?: React.MutableRefObject<GridApiPro>;
  selectedIds:SelectedAsset[];
  setSelectedIds: SetterOrUpdater<SelectedAsset[]>;
}) => {
  const setSelectedDevices = useSetRecoilState(selectedDevicesForAlertAtom);
  const isFormSubmitted = useRecoilValue(isFormSubmittedAtom);
  const [preMappedDeviceNotifications, setPreMappedDeviceNotifications] = useRecoilState(
    preMappedDeviceNotificationsAtom
  );
  const { data: assetsData, loading: assetsLoading } = useGetAssetsForMappingDeviceNotificationsQuery();
  const [fetchNotificationScope, { data: scopesData }] = useGetNotificationScopeByNotificationIdLazyQuery({
    fetchPolicy: "network-only",
  });
  const [query, { data, loading }] = useGetMappingDeviceNotificationsForNotificationLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!notificationId) return;
    fetchNotificationScope({ variables: { id: notificationId } });
  }, [notificationId]);
  useEffect(() => {
    if (scopesData && scopesData.notification?.notificationScope.id !== "2c920815-0262-4125-932c-3a9be981d8ca") {
      query({
        variables: {
          notificationMappingFilter: { notificationId: { eq: notificationId } },
        },
      });
    }
  }, [scopesData]);
  useEffect(() => {
    if (!selectedIds) return;

    setSelectedDevices((prevState) => {
      const deletedDevices = (prevState ?? [])?.reduce<SelectedDevice[]>((acc, curr) => {
        const isDeviceSelectedInAssetsTable = selectedIds?.find(
          (item) => item.assetDevice?.[0]?.deviceId === curr.id
        );
        if (!isDeviceSelectedInAssetsTable) return acc.concat({ ...curr, toDelete: true });
        else return acc;
      }, []);
      return deletedDevices;
    });
  }, [selectedIds]);
  // set selected assets
  useEffect(() => {
    if (!data?.mappingDeviceNotifications || !assetsData || !apiRef?.current || isFormSubmitted) return;
    
    let selectedOnUi = [...(selectedIds ?? [])];

    const preSelected = (data?.mappingDeviceNotifications ?? []).reduce<SelectedAsset[]>((acc, current) => {
      const selectedAsset = assetsData?.assets?.find((asset) => asset?.assetDevice?.[0]?.deviceId === current.deviceId);
      if (!selectedAsset) return acc;
      const toDelete =
        selectedIds?.find((selected) => selected?.assetDevice?.[0]?.deviceId === current.deviceId)?.toDelete ??
        false;
      selectedOnUi = selectedOnUi.filter((item) => item?.assetDevice?.[0]?.deviceId !== current.deviceId);
      return acc.concat({ ...selectedAsset, toDelete });
    }, []);
    setSelectedIds(preSelected.concat(selectedOnUi));

    if (!preMappedDeviceNotifications) setPreMappedDeviceNotifications(data?.mappingDeviceNotifications);
  }, [data, assetsData]);

  const setSelectedRows = (rows: readonly GridValidRowModel[]) => {
    const assetIDsOnUi = apiRef?.current?.getAllRowIds ? apiRef?.current?.getAllRowIds() : [];
    const checkedIds = rows.map(({ id }) => id) as GridRowId[];

    setSelectedIds((preSelectedAssets) => {
      const selectedAssetsNextPre = (preSelectedAssets ?? []).reduce<SelectedAsset[]>((acc, curr) => {
        if (!assetIDsOnUi.includes(curr.id)) return acc.concat(curr as SelectedAsset);

        if (checkedIds.includes(curr.id)) return acc.concat({ ...curr, toDelete: false });

        return acc.concat({ ...curr, toDelete: true });
      }, []);

      return rows.reduce<SelectedAsset[]>((acc, curr) => {
        if (acc.find((e) => e.id === curr.id)) return acc;

        return acc.concat(curr as SelectedAsset)
      }, selectedAssetsNextPre).filter((item) => !item.toDelete);
    });
  };

  return { loading: loading || assetsLoading, setSelectedRows };
};
