import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  activeCommon,
  disabledCommon,
  hoverCommon,
  darkDisabledCommon,
} from "themes/Button/IconButton/commonButtonStyles";
import React from "react";

interface StyleProps {
  animation?: "pulse" | "spin";
  icon?: boolean;
  label?: string;
  style?: React.CSSProperties;
}

export const useOtherStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootButton: (props: StyleProps) => ({
      width: props?.style?.width ? props?.style?.width : props?.label ? "217px" : "40px",
      "& svg": {
        animation: props?.animation === "spin" ? "spin 1s infinite steps(8)" : "none",
      },
    }),
    label: {
      fontSize: "1em",
      marginLeft: 15,
      marginRight: 15,
      textTransform: "none",
      whiteSpace: "nowrap",
      boxSizing: "content-box",
    },
    danger: {
      backgroundColor: theme.colors.red400,
      color: theme.colors.tWhite,
      border: 0,
      ...disabledCommon,
      "& svg": {
        color: theme.colors.tWhite,
      },
      "&:hover": {
        backgroundColor: theme.colors.red500,
      },
      "&:active": {
        backgroundColor: theme.colors.red600,
      },
    },
    radial: {
      color: theme.colors.tBlack,
      backgroundColor: theme.colors.grey100,
      borderColor: theme.colors.grey100,
      borderRadius: 20,
      ...disabledCommon,
      ...hoverCommon,
      ...activeCommon,
    },
    darkPrimary: {
      ...darkDisabledCommon,
    },
    darkSecondary: {
      backgroundColor: theme.colors.tBlack,
      color: theme.colors.tWhite,
      border: `1px solid ${theme.colors.tWhite}`,
      "& svg": {
        color: theme.colors.tWhite,
      },
      "&:disabled": {
        backgroundColor: theme.colors.grey400,
        color: theme.colors.grey100,
        borderColor: theme.colors.grey400,
        "& div svg": {
          color: theme.colors.grey100,
        },
        "&:hover": {
          backgroundColor: theme.colors.grey400,
          color: theme.colors.grey100,
          borderColor: theme.colors.grey400,
        },
      },
      "&:hover": {
        backgroundColor: theme.colors.grey300,
        color: theme.colors.tBlack,
        borderColor: theme.colors.grey300,
        "& svg": {
          color: theme.colors.tBlack,
        },
      },
      "&:active": {
        backgroundColor: theme.colors.tWhite,
        color: theme.colors.tBlack,
        borderColor: theme.colors.tWhite,
      },
    },
    darkDanger: {
      backgroundColor: theme.colors.red500,
      color: theme.colors.tWhite,
      ...darkDisabledCommon,
      "&:hover": {
        backgroundColor: theme.colors.red400,
        color: theme.colors.tWhite,
      },
      "&:active": {
        backgroundColor: theme.colors.red500,
        color: theme.colors.tWhite,
      },
    },
    darkRadial: {
      backgroundColor: theme.colors.grey100,
      color: theme.colors.tBlack,
      borderColor: theme.colors.grey100,
      ...darkDisabledCommon,
      "&:hover": {
        backgroundColor: theme.colors.grey700,
        color: theme.colors.grey100,
      },
      "&:active": {
        backgroundColor: theme.colors.tBlack,
        color: theme.colors.grey100,
      },
    },
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      "& svg": {
        margin: "auto",
        height: "1.5em",
        width: "1.5em",
      },
    },
    combinedWrapper: (props: StyleProps) => ({
      display: "grid",
      gridTemplateRows: 40,
      gridTemplateColumns: "40px 0.5fr 3.5fr 0.5fr 40px",
      alignItems: "center",
      width: 217,
      paddingLeft: 5,
      "& svg": {
        height: "2em",
        width: "2em",
        gridRowStart: 1,
        gridRowEnd: 1,
        gridColumnStart: 1,
        gridColumnEnd: 2,
      },
      "& span": {
        margin: 0,
        marginLeft: 15,
        marginRight: 15,
        textTransform: "none",
        gridRowStart: 1,
        gridRowEnd: 2,
        gridColumnStart: 3,
        gridColumnEnd: "auto",
      },
    }),
    "@keyframes spin": {
      "0%": { transform: "rotate(360deg)" },
      "100%": { transform: "rotate(0deg)" },
    },
  })
);
