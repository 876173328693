import cache from "apollo/cache";
import client from "apollo/client";
import {
  CreateGroupsDocument,
  CreateGroupsMutation,
  CreateGroupsMutationVariables,
} from "graphqlBase/Groups/__generated__/createGroups";
import { GroupCreateType } from "graphqlBase/types";
import { useState } from "react";

const usePersistGroups = () => {
  const [loading, setLoading] = useState(false);

  const handleAdd = async (group: GroupCreateType) => {
    const variables = { groups: [group] };
    setLoading(true);
    return await client
      .mutate<CreateGroupsMutation, CreateGroupsMutationVariables>({
        mutation: CreateGroupsDocument,
        variables,
      })
      .then((result) => {
        cache.modify({
          fields: {
            groups(existingGroupRefs, { readField }) {
              return [...existingGroupRefs, group];
            },
          },
        });
        return result?.data?.createGroups?.[0]?.id;
      })
      .catch((e: any) => {
        console.error("handle add group error", e.message);
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  return { handleAdd, loading };
};

export default usePersistGroups;
