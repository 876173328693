import { LhChangeItem } from "@liebherr/patternlib/dist/types/utils/interfaces";
import Can from "authorization";
import { authorizedTabsArray } from "authorization/AuthorizedTabs/authorizedTabsArray";
import AuthorizedTabsCan from "authorization/AuthorizedTabs/AuthorizedTabsCan";
import ContentHeader from "components/organisms/ContentHeader";
import PLBadge from "components/patternLib/PLBadge";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import PLTabs from "components/patternLib/PLTabs";
import PLTabsItem from "components/patternLib/PLTabsItem";
import { TableWrapperSC } from "components/templates/dataGridTable/styles";
import { useGetOpenAssignmentsForBadgeValueQuery } from "graphqlBase/Assets/__generated__/getOpenAssignmentsForBadgeValue";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import translations from "translations";
import AssetGroups from "./AssetGroups";
import AssetOverview from "./AssetOverview";
import { currentAssetTab } from "./assetTabsState";
import OpenAssignments from "./OpenAssignments";

const AssetManagement: React.FC<{}> = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useRecoilState(currentAssetTab);
  const { data: dataForBadgeValue } = useGetOpenAssignmentsForBadgeValueQuery({
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });

  const handleHeaderTabChange = (event: CustomEvent<LhChangeItem>) => {
    setCurrentTab(event.detail.itemId as number);
  };

  const handleAddAssetGroup = () => {
    history.push("/assetManagement/createAssetGroup");
  };
  const handleAddAsset = () => {
    history.push("/assetManagement/createAsset");
  };

  const { myAssets, openAssignments } = translations.pages.assetManagement;
  const { myAssetGroups } = translations.pages.groups.assetGroup;

  const headerTabLabels = [
    { label: myAssets, scope: "MyAssetsUI" },
    { label: myAssetGroups, scope: "MyAssetGroupsUI" },
    { label: openAssignments, scope: "OpenAssignmentsUI" },
  ];

  const HeaderTabs = (
    <PLTabs lhChangeItem={handleHeaderTabChange} key="tabs">
      {authorizedTabsArray(headerTabLabels).map(({ label }, index) => {
        return label === openAssignments ? (
          <React.Fragment key={`${label}-${index}`}>
            <PLTabsItem active={currentTab === index} label={label} />
            <PLBadge iconSize="0px" topPosition="2px" value={dataForBadgeValue?.assets.length.toString()} noStates />
          </React.Fragment>
        ) : (
          <PLTabsItem key={`${label}-${index}`} active={currentTab === index} label={label} />
        );
      })}
    </PLTabs>
  );

  const addGroupsButton = (
    <Can I="read" a="CreateAssetGroupUI">
      <PLButton
        onClick={handleAddAssetGroup}
        key="create-group-button"
        iconPosition="left"
        label={translations.pages.groups.createGroup}
      >
        <PLIcon iconName="plus" size="24px" slot="icon" />
      </PLButton>
    </Can>
  );
  const createAssetButton = (
    <Can I="read" a="CreateAlertUI">
      <PLButton
        onClick={handleAddAsset}
        key="create-asset-button"
        iconPosition="left"
        label={translations.pages.assetManagement.createAsset}
      >
        <PLIcon iconName="plus" size="24px" slot="icon" />
      </PLButton>
    </Can>
  );
  const getCreateButtons = () => {
    switch (currentTab) {
      case 1:
        return addGroupsButton;

      case 0:
        return createAssetButton;

      default:
        return undefined;
    }
  };

  return (
    <>
      <ContentHeader tabs={[HeaderTabs]} rightItems={getCreateButtons()} />
      <TableWrapperSC>
        {currentTab === 0 && (
          <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
            <AssetOverview key="asset-overview-tab-content" />
          </AuthorizedTabsCan>
        )}
        {currentTab === 1 && (
          <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
            <AssetGroups key="asset-groups-tab-content" />
          </AuthorizedTabsCan>
        )}
        {currentTab === 2 && (
          <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
            <OpenAssignments key="open-assignments-tab-content" setCurrentTab={setCurrentTab} />
          </AuthorizedTabsCan>
        )}
      </TableWrapperSC>
    </>
  );
};

export default AssetManagement;
