import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PersistNotificationDefinitionsMutationVariables = Types.Exact<{
  deleteNotificationDefinitions?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
  notificationDefinitions?: Types.InputMaybe<Array<Types.InputMaybe<Types.NotificationDefinitionCreateType>> | Types.InputMaybe<Types.NotificationDefinitionCreateType>>;
  updateNotificationDefinitions?: Types.InputMaybe<Array<Types.InputMaybe<Types.NotificationDefinitionUpdateType>> | Types.InputMaybe<Types.NotificationDefinitionUpdateType>>;
}>;


export type PersistNotificationDefinitionsMutation = { deleteNotificationDefinitions?: Maybe<Array<Maybe<{ __typename: 'NotificationDefinition', id: string }>>>, createNotificationDefinitions?: Maybe<Array<Maybe<{ __typename: 'NotificationDefinition', id: string }>>>, updateNotificationDefinitions?: Maybe<Array<Maybe<{ __typename?: 'NotificationDefinition', id: string }>>> };


export const PersistNotificationDefinitionsDocument = gql`
    mutation persistNotificationDefinitions($deleteNotificationDefinitions: [UUID!], $notificationDefinitions: [NotificationDefinitionCreateType], $updateNotificationDefinitions: [NotificationDefinitionUpdateType]) {
  deleteNotificationDefinitions(ids: $deleteNotificationDefinitions) {
    id
    __typename
  }
  createNotificationDefinitions(notificationDefinitions: $notificationDefinitions) {
    id
    __typename
  }
  updateNotificationDefinitions(notificationDefinitions: $updateNotificationDefinitions) {
    id
  }
}
    `;
export type PersistNotificationDefinitionsMutationFn = Apollo.MutationFunction<PersistNotificationDefinitionsMutation, PersistNotificationDefinitionsMutationVariables>;

/**
 * __usePersistNotificationDefinitionsMutation__
 *
 * To run a mutation, you first call `usePersistNotificationDefinitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersistNotificationDefinitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [persistNotificationDefinitionsMutation, { data, loading, error }] = usePersistNotificationDefinitionsMutation({
 *   variables: {
 *      deleteNotificationDefinitions: // value for 'deleteNotificationDefinitions'
 *      notificationDefinitions: // value for 'notificationDefinitions'
 *      updateNotificationDefinitions: // value for 'updateNotificationDefinitions'
 *   },
 * });
 */
export function usePersistNotificationDefinitionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersistNotificationDefinitionsMutation, PersistNotificationDefinitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PersistNotificationDefinitionsMutation, PersistNotificationDefinitionsMutationVariables>(PersistNotificationDefinitionsDocument, options);
      }
export type PersistNotificationDefinitionsMutationHookResult = ReturnType<typeof usePersistNotificationDefinitionsMutation>;
export type PersistNotificationDefinitionsMutationResult = Apollo.MutationResult<PersistNotificationDefinitionsMutation>;
export type PersistNotificationDefinitionsMutationOptions = Apollo.BaseMutationOptions<PersistNotificationDefinitionsMutation, PersistNotificationDefinitionsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
