import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLStatusPillProps = DropOnONLh<JSX.PatternlibStatusPill>;
export type PLStatusPillExtendedProps = PLStatusPillProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedStatusPill = wrapWc<JSX.PatternlibStatusPill>("patternlib-status-pill");
export const useRefPLStatusPill = () => useRef<HTMLPatternlibStatusPillElement>(null);

const PLStatusPill = React.forwardRef<HTMLPatternlibStatusPillElement, PLStatusPillExtendedProps>((props, ref) => {
  const myRef = useRefPLStatusPill();
  return (
    <WrappedStatusPill ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedStatusPill>
  );
});
PLStatusPill.displayName = "PLStatusPill";

export default PLStatusPill;
