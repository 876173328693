import React, { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
import { formatDate } from "translations/formatter";
import { GridRowParams, getGridStringOperators } from "@mui/x-data-grid-pro";
import PLTextlink from "components/patternLib/PLTextlink";
import {
  ResultType,
  GetAssetsForOpenAssignmentQuery,
  useGetAssetsForOpenAssignmentLazyQuery,
} from "graphqlBase/Assets/__generated__/getAssetsForOpenAssignments";
import useDataGrid from "components/templates/dataGridTable";
import { useSetRecoilState } from "recoil";
import { currentDeviceAtom } from "./AssetAssignment/recoilBox";

interface OpenAssignmentsProps {
  setCurrentTab: Dispatch<SetStateAction<number>>;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "&:focus-visible": {
    outline: "none",
  },
};
const variables = {
  where: {
    and: [
      { assetMasterDataId: { eq: null } },
      { assetDevice: { any: true } },
      { assetSourceId: { eq: "d2bb33b7-9e89-472c-998c-9f522bad3c07" } },
    ],
  },
};
type Asset = ResultType<GetAssetsForOpenAssignmentQuery["assets"]>;
const OpenAssignments: React.FC<OpenAssignmentsProps> = () => {
  const [query, { data, loading, error }] = useGetAssetsForOpenAssignmentLazyQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });
  const setCurrentDeviceName = useSetRecoilState(currentDeviceAtom);

  const history = useHistory();

  const { DataGrid, useMakeColumns, makeSeverSideFilter, getRowCount } = useDataGrid<Asset, "Asset">({
    query,
    variables,
    tableId: "OpenAssignments",
    persistance: "runTime",
  });
  const columns = useMakeColumns(
    [
      {
        field: "assetId",
        headerName: "Open Assignment ID",
        renderHeader: (params) => `Open Assignment ID (${getRowCount()})`,
        sortable: false,
        flex: 2,

        valueGetter: (params) => {
          return params.row.id;
        },
        type: "string",
      },
      {
        field: "createdAt",
        headerName: translations.globals.time.creationDate,
        flex: 2,
        valueGetter: (params) => {
          return formatDate(new Date((params.row.assetDevice && params.row.assetDevice[0]?.createdAt) ?? ""));
        },
        remoteFilter: makeSeverSideFilter("dateTime", {
          filterPath: ({ where, filterValue }) => ({
            createdAt: filterValue,
          }),
        }),
        remoteOrder: (sort) => ({ createdAt: sort }),
        type: "dateTime",
      },

      {
        field: "device",
        headerName: translations.entities.device.name,
        flex: 1,
        renderCell: (params) => (
          <PLTextlink
            label={params.row.assetDevice[0].device.serialNo}
            onClick={() => history.push(`/deviceManagement/deviceDetails/${params.row.assetDevice[0].device.id}`)}
          />
        ),
        remoteFilter: makeSeverSideFilter("string", {
          filterOperators: getGridStringOperators().filter(
            (operator) =>
              operator.value !== "isEmpty" &&
              operator.value !== "equals" &&
              operator.value !== "isNotEmpty" &&
              operator.value !== "isAnyOf"
          ),
          filterPath: ({ where, filterValue }) => ({
            assetDevice: { some: { device: { serialNo: filterValue } } },
          }),
        }),
        type: "string",
      },
      {
        field: "deviceTypeName",
        headerName: translations.entities.deviceType.columns.deviceTypeName,
        flex: 2,
        valueGetter: (params) => {
          return params.row.assetDevice ? params.row.assetDevice[0]?.device?.deviceType?.deviceTypeName : "";
        },
        type: "string",
      },
    ],
    []
  );

  const handleOnRowClick = (params: GridRowParams) => {
    setCurrentDeviceName(params.row.assetDevice[0].device.deviceName);
    history.push(`assetManagement/openAssignments/${params.row.id}`);
  };
  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage={translations.pages.assetManagement.noAssetsMessage}
          handleOnRowClick={handleOnRowClick}
          columns={columns}
          rows={data?.assets}
          checkboxSelection={false}
          tableTitle={translations.pages.assetManagement.openAssignments}
          tableHeight="65vh"
          loading={loading}
          hasOnlyDefaultToolbar
        />
      )}
    </>
  );
};
export default OpenAssignments;
