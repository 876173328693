import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLModalProps = DropOnONLh<JSX.PatternlibModal>;
export type PLModalExtendedProps = PLModalProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedModal = wrapWc<JSX.PatternlibModal>("patternlib-modal");
export const useRefPLModal = () => useRef<HTMLPatternlibModalElement>(null);

const PLModal = React.forwardRef<HTMLPatternlibModalElement, PLModalExtendedProps>((props, ref) => {
  const myRef = useRefPLModal();
  return (
    <WrappedModal ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedModal>
  );
});
PLModal.displayName = "PLModal";

export default PLModal;
