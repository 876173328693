import * as React from "react";
import { Theme as AugmentedTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import Props from "./index.d";

export type DisplayFieldComponentProps = Props;
const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      // color: theme.colors.grey500,
      fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: 0,
      "& .MuiInputBase-input": {
        padding: 0,
        paddingLeft: "8px",
      },
    },
    titleBox: {
      margin: "0.2em 0.5em 0.5em 0.4em",
    },
  })
);

const TextFieldComponent: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, onBlur, updateValue },
    testId,
    placeholder = "placeholder",
  } = props;
  const classes = useStyles(props);

  return (
    <Alert severity="warning" data-testid={testId}>
      <div className={classes.root}>
        <div className={classes.titleBox}>
          <Typography align="left" variant="inherit">
            {state}
          </Typography>
        </div>
      </div>
    </Alert>
  );
};

export default TextFieldComponent;
