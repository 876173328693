import { lhGalleryCarouselItemSelectedEvent } from "@liebherr/patternlib";
import { PatternlibGalleryCarouselCustomEvent } from "@liebherr/patternlib/dist/types/components";

import PLGalleryCarousel from "components/patternLib/PLGalleryCarousel";
import PLGalleryCarouselItem from "components/patternLib/PLGalleryCarouselItem";
import PLImage from "components/patternLib/PLImage";
import React from "react";
import { AssetImageCarousel } from "../..";
interface Props {
  currentPageId: string;
  images: AssetImageCarousel[];
  setCurrentPageId: React.Dispatch<React.SetStateAction<string>>;
}
const ImageGalleryCarousel: React.FC<Props> = ({ images, currentPageId, setCurrentPageId }) => {
  return (
    <>
      <PLGalleryCarousel
        value={currentPageId}
        lhGalleryCarouselItemSelected={(
          event: PatternlibGalleryCarouselCustomEvent<lhGalleryCarouselItemSelectedEvent>
        ) => {
          setCurrentPageId(event.detail.value);
        }}
      >
        {images.map((image) => (
          <PLGalleryCarouselItem key={image.id} value={image.id}>
            <PLImage objectFit="contain" alt={image.alt} src={image.src} width="120px" height="120px" />
          </PLGalleryCarouselItem>
        ))}
      </PLGalleryCarousel>
    </>
  );
};

export default ImageGalleryCarousel;
