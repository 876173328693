import { Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Props from "components/formElements/fields/dropdown/index.d";
import tableColors from "components/templates/table-factory/theme/tableColors";
import ArrowDownIcon from "icons/components/ArrowDownIcon";
import ArrowUpIcon from "icons/components/ArrowUpIcon";
import React, { useState } from "react";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      dropdownClosed: {
        backgroundColor: tableColors.lbcgrey,
        border: `1px solid ${tableColors.lbcgrey}`,
        borderRadius: " 4px",
        boxSizing: "border-box",
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        height: "2.5em",
        minWidth: "200px",
        overflow: "hidden",
        width: "100%",
        "&:hover": {
          backgroundColor: theme.colors.grey200,
        },
      },
      dropdownContent: {
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: "16px",
        marginTop: 0,
        outline: "none",
        width: "100%",
      },
      dropdownDisabled: {
        // disabled
        background: theme.colors.tWhite,
        border: `1px solid ${tableColors.lbcgrey}`,
        borderRadius: "4px",
        boxSizing: "border-box",
        cursor: "not-allowed",
        color: theme.colors.grey400,
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        height: "2.5em",
        minWidth: "max-content",
        overflow: "hidden",
        width: "100%",
        WebkitTextFillColor: theme.colors.grey400,
      },
      dropdownHeader: {
        // List Items
        alignContent: "center",
        boxSizing: "border-box",
        color: "black",
        display: "flex",
        fontSize: "16px",
        height: "1.125em",
        justifyContent: "space-between",
        lineHeight: 1.2,
        margin: "0.56em 0",
        minWidth: "max-content",
        outline: "none",
        overflow: "hidden",
        padding: "0 0.6em 0 0.6em",
        textAlign: "left",
        width: "100%",
      },
      dropdownIcon: {
        background: "transparent", // button
        border: "none", // button
        boxSizing: "border-box", // button
        color: "inherit",
        fontSize: "20px", // button
        float: "right", // button
        height: "20px", // button
        outline: "none", // button
        paddingBottom: "1.5em",
        width: "maxContent", // button
      },
      dropdownListClosed: {
        display: "none",
      },
      dropdownListOpen: {
        backgroundColor: theme.colors.tWhite,
        boxShadow: "rgba(0, 0, 0, 0.25) 0 4px 16px 0",
        boxSizing: "border-box",
        color: "black",
        display: "flex",
        flexFlow: "column wrap",
        fontSize: "16px",
        height: "auto",
        marginTop: 8,
        marginBottom: 8,
        maxHeight: "340px",
        overflowY: "auto",
        padding: 0,
        position: "absolute",
        width: "100%",

        listStyle: "none",
        maxWidth: "inherit",
        textAlign: "left",
        zIndex: 1,
      },
      dropdownOpen: {
        backgroundColor: theme.colors.tWhite,
        border: `1px solid ${theme.colors.grey400}`,
        borderRadius: "4px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0 4px 16px 0",
        boxSizing: "border-box",
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        height: "2.5em",
        minWidth: "200px",
        overflow: "hidden",
        alignItems: "center",
        width: "100%",
        zIndex: 1,
      },
      label: {
        opacity: (props: { disabled: boolean }) => (props.disabled ? 0.5 : 1),
      },
      root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "400px",
        "& li": {
          padding: "11.5px 9px",
          "&:hover": {
            backgroundColor: tableColors.lbcgrey,
          },
        },
      },
      title: {
        WebkitTextFillColor: theme.colors.grey500,
        color: theme.colors.grey500,
        display: "block",
        fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1.2,
        margin: "0.2em 0.5em 0.5em 0.4em",
        maxWidth: "fit-content",
      },
    }),
  { name: "Dropdown" }
);

const DropDown: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    options,
    testId,
    disabled,
    title,
  } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAndSave(event.target.value as string);
  };

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string>(options ? options[0] : "empty");
  const classes = useStyles({ disabled });

  const handleOutsideClick = () => {
    setOpen(false);
    document.removeEventListener("click", handleOutsideClick);
  };

  const toggleDropdown = () => {
    if (!open && !disabled) {
      setOpen(true);
      document.addEventListener("click", handleOutsideClick);
    } else {
      setOpen(false);
      document.removeEventListener("click", handleOutsideClick);
    }
  };

  const handleSelect = (value: string) => {
    if (value !== selected) handleChange;
    setOpen(false);
    setSelected(value);
  };

  return (
    <div className={classes.root}>
      <Typography align="right" className={classes.title} variant="body2">
        {title}:
      </Typography>
      <div
        data-testid={testId}
        id="dropdown"
        style={{
          height: "max-content",
          minWidth: "max-content",
          position: "relative",
          width: "400px",
        }}
      >
        <div
          id="wrapper"
          className={clsx({
            [classes.dropdownOpen]: open && !disabled,
            [classes.dropdownClosed]: !open && !disabled,
            [classes.dropdownDisabled]: disabled,
          })}
          onClick={toggleDropdown}
        >
          <div id="header" className={classes.dropdownHeader}>
            <div id="content" className={classes.dropdownContent}>
              <div className={classes.label}>{selected}</div>
              {disabled ? (
                <ArrowDownIcon color="#ededed" />
              ) : open ? (
                <ArrowUpIcon color="#ededed" />
              ) : (
                <ArrowDownIcon color="#ededed" />
              )}
            </div>
          </div>
          <ul
            className={clsx({
              [classes.dropdownListOpen]: open,
              [classes.dropdownListClosed]: !open,
            })}
          >
            {options.map((option) => (
              <li key={option} onClick={() => handleSelect(option)}>
                <span className={classes.label}>{option}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
