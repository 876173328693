import React, { CSSProperties } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress } from "@mui/material";
import { CircularProgressProps } from "@mui/material/CircularProgress/CircularProgress";

interface LoadingIndicatorProps extends CircularProgressProps {
  isLoading?: boolean;
  padding?: CSSProperties["padding"];
  testId?: string;
}

const useStyles = makeStyles<Theme, LoadingIndicatorProps>(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: (props) => props.padding ?? "2rem",
      },
    }),
  { name: "LoadingIndicator" }
);

const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  const classes = useStyles(props);
  const { testId, isLoading = true, padding, ...cpProps } = props;

  if (!isLoading) return null;

  return (
    <div className={classes.root}>
      <CircularProgress {...cpProps} data-testid={testId} />
    </div>
  );
};

export default LoadingIndicator;
