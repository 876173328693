import { LhChangeItem } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { Box, Grid } from "@mui/material";
import AuthorizedTabsCan from "authorization/AuthorizedTabs/AuthorizedTabsCan";
import { authorizedTabsArray } from "authorization/AuthorizedTabs/authorizedTabsArray";
import ContentHeader from "components/organisms/ContentHeader";
import { contentHeaderTitleAtom } from "components/organisms/ContentHeader/contentHeaderRecoilState";
import PLStatusPill from "components/patternLib/PLStatusPill";
import PLTabs from "components/patternLib/PLTabs";
import PLTabsItem from "components/patternLib/PLTabsItem";
import { useGetAssetDeviceAndTypeByIdQuery } from "graphqlBase/Assets/__generated__/getAssetDeviceAndTypeById";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import theme from "themes/theme";
import translations from "translations";
import AssetMasterDataTile from "./DashboardUtilization/tiles/AssetMasterDataTile";
import CouplingOverViewTile from "./DashboardUtilization/tiles/CouplingOverviewTile";
import CurrentCouplingsTile from "./DashboardUtilization/tiles/CurrentCouplingsTile";
import CurrentPositionTile from "./DashboardUtilization/tiles/CurrentPositionTile";
import EnvironmentalMeasurementsTile from "./DashboardUtilization/tiles/EnvironmentalMeasurementsTile";
import WorkloadOverviewTile from "./DashboardUtilization/tiles/WorkloadOverviewTile";
import AlertsTile from "./DetailsAndAlerts/tiles/AlertsTile";
import AssociatedAssetsTile from "./DetailsAndAlerts/tiles/AssociatedAssetsTile";
import TechnicalDataTile from "./DetailsAndAlerts/tiles/TechnicalDataTile";
import { deviceIdState } from "./assetDetailState";

const rootStyles = {
  margin: "auto",
  backgroundColor: theme.palette.background.default,
  minHeight: "fit-content",
};

const tagsContainerStyles = {
  display: "flex",
  height: "50%",
  alignItems: "center",
  width: "calc(100% + 10px)",
  justifyContent: "space-between",
  flexDirection: "row",
  columnGap: "8px",
};

const AssetDetails: React.FC<{}> = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { assetId }: { assetId: string } = useParams();
  const { data, loading } = useGetAssetDeviceAndTypeByIdQuery({ variables: { assetId: assetId ?? "" } });
  const isCranePart = data?.asset?.assetSource?.assetSourceName.includes("CRANE");
  const isExcavator = data?.asset?.assetSource?.assetSourceName.includes("EXCAVATOR") ?? false;
  const setPageTitle = useSetRecoilState(contentHeaderTitleAtom);
  const setDeviceId = useSetRecoilState(deviceIdState);
  const resetDeviceId = useResetRecoilState(deviceIdState);

  const sellingUnitSerialNumber = data?.asset?.assetMasterData?.sellingUnitSerialNumber ?? undefined;
  const generalItem = data?.asset?.assetMasterData?.generalItem;
  const deviceId = data?.asset?.assetDevice?.[0]?.device?.id;

  const { details, alerts } = translations.globals.terms;

  useEffect(() => {
    if (!loading && generalItem) setPageTitle(generalItem);
  }, [generalItem, loading, setPageTitle]);

  useEffect(() => {
    setDeviceId(deviceId);
    if (deviceId) return () => resetDeviceId();
  }, [deviceId, resetDeviceId, setDeviceId]);

  const headerTabLabels = [
    { label: translations.globals.terms.dashboard, scope: "AssetGroupDashboardUI" },
    { label: `${details} & ${alerts}`, scope: "AssetGroupAssetListUI" },
  ];

  const handleHeaderTabChange = (event: CustomEvent<LhChangeItem>) => {
    setCurrentTab(event.detail.itemId as number);
  };

  const HeaderTabs = (
    <PLTabs lhChangeItem={handleHeaderTabChange} key="asset-details-tabs">
      {authorizedTabsArray(headerTabLabels).map(({ label }, index) => {
        return <PLTabsItem key={`${label}-${index}`} active={currentTab === index} label={label} />;
      })}
    </PLTabs>
  );

  const Tags = (
    <Box sx={tagsContainerStyles}>
      {data?.asset?.mappingAssetTag.map((tag) => {
        return <PLStatusPill key={tag.assetTag.id} label={tag.assetTag.tagValue} />;
      })}
    </Box>
  );

  return (
    <Box sx={rootStyles}>
      <ContentHeader tabsStyles={{ marginBottom: 0 }} tabs={[HeaderTabs]} rightItems={Tags} />
      <Grid container>
        <>
          {currentTab === 0 && (
            <Grid item xs={8} sm={6} md={8} lg={8} xl={9}>
              <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
                <WorkloadOverviewTile assetId={assetId} />
                {!isCranePart && <CouplingOverViewTile assetId={assetId} />}
                <CurrentPositionTile />
                <EnvironmentalMeasurementsTile />
              </AuthorizedTabsCan>
            </Grid>
          )}
          {currentTab === 1 && (
            <Grid item xs={8} sm={6} md={8} lg={8} xl={9}>
              <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
                {!isCranePart && <TechnicalDataTile />}
                <AlertsTile />
                {!!isCranePart && (
                  <AssociatedAssetsTile sellingUnitSerialNumber={sellingUnitSerialNumber} assetId={assetId} />
                )}
                {/* <MediaTile /> */}
              </AuthorizedTabsCan>
            </Grid>
          )}
          <Grid item xs={4} sm={6} md={4} lg={4} xl={3}>
            <AssetMasterDataTile assetId={assetId} />
            {!isCranePart && !!deviceId && <CurrentCouplingsTile isExcavator={isExcavator} deviceId={deviceId} />}
          </Grid>
        </>
      </Grid>
    </Box>
  );
};
export default AssetDetails;
