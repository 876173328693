import { Box, Typography } from "@mui/material";
import PLModal from "components/patternLib/PLModal";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { getGroupIdAfterSaveGroupAtom } from "../groupEditingState";

interface SaveGroupModalProps {
  entityName: string;
  handleGoToAllGroups: () => void;
  handleGoToGroup: () => void;
  isEditing: boolean;
}

const { groupCreatedMessage, groupCreatedTitle, viewGroup, seeEntityGroups } = translations.pages.groups;
const { changesSavedTitle, changesSavedMessage } = translations.globals.popupMessages;

export const testIDs = getTestIDs();

const AfterSaveModal: React.FC<SaveGroupModalProps> = ({
  handleGoToGroup,
  handleGoToAllGroups,
  entityName,
  isEditing,
}) => {
  const [groupIdAfterSaveGroup, setGroupIdAfterSaveGroup] = useRecoilState(getGroupIdAfterSaveGroupAtom);
  const onClose = () => setGroupIdAfterSaveGroup(undefined);

  const entity = entityName === "AssetDevice" ? "Asset" : entityName;
  const title = isEditing ? changesSavedTitle : groupCreatedTitle;
  const message = isEditing ? changesSavedMessage : groupCreatedMessage;

  return (
    <PLModal
      allowCloseOnBackdrop
      modalTitle={title}
      lhCancel={() => {
        handleGoToAllGroups();
        onClose();
      }}
      lhConfirm={() => {
        handleGoToGroup();
        onClose();
      }}
      lhClose={onClose}
      show={!!groupIdAfterSaveGroup}
      confirmLabel={viewGroup}
      cancelLabel={seeEntityGroups(entity)}
    >
      <Box slot="modal-content">
        <Typography variant="copy">{message}</Typography>
      </Box>
    </PLModal>
  );
};

export default AfterSaveModal;
