const buttonBaseStyles = {
  "&.MuiButtonBase-root": {
    color: "var(--color-black)",
    "&.MuiCheckbox-root": {
      marginLeft: "10%",
    },
    "&.MuiIconButton-root:hover": {
      backgroundColor: "transparent",
      color: "var(--color-black)",
    },
    "&.MuiIconButton-root": {
      color: "var(--color-black)",
      "&.MuiButtonBase-root": {
        display: "inline-flex",
        lineHeight: "24px",
        fontSize: "14px",
        letterSpacing: "var(--letter-spacing-basic)",
        textTransform: "uppercase",
        borderRadius: 0,
        fontFamily: "LiebherrText",
        ".MuiSvgIcon-root": {
          fontSize: "1.4rem",
        },
        "&:disabled": {
          color: "var(--color-steel-500)",
        },
      },
      "&.MuiIconButton-colorPrimary": {
        borderColor: "var(--primary-color)",
        backgroundColor: "var(--primary-color)",
        color: "var(--button-text-color-primary)",
        fontFamily: "var(--font-liebherr-text-bold)",
        padding: "8px 20px",

        "&:hover": {
          borderColor: "var(--primary-color-accent)",
          backgroundColor: "var(--primary-color-accent)",
          color: "var(--color-black)",
        },
      },
    },
  },
};
export const muiButtonBaseOverrides = {
  root: {
    ...buttonBaseStyles,
  },
};
