import { ToggleButton } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import {
  DataGridPro,
  GridFooterContainer,
  GridSelectedRowCount,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import theme from "themes/theme";
import { DataGridComponentType } from "./serverSide/types";
interface StyledGridType extends DataGridComponentType {
  props: { height: string };
}

export const StyledDataGrid = (styled(DataGridPro)(({ props }: { props: { height: string } }) => {
  return {
    fontSize: "14px",
    backgroundColor: "#ffffff",
    border: "1px solid var(--color-steel-300)",
    borderRadius: 0,
    height: props.height,
    ".MuiButtonBase-root.MuiCheckbox-root": {
      borderRadius: 0,
      height: 24,
      width: 24,
      color: "transparent",
      border: "1px solid var(--color-black)",
      ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
        height: 30,
        width: 30,
      },
    },
    ".MuiButtonBase-root.Mui-checked": {
      borderRadius: 0,
      color: "var(--primary-color)",
      backgroundColor: "var(--color-black)",
      borderColor: "var(--primary-color)",
      ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
        height: 32,
        width: 32,
      },
    },
    ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
    ".MuiDataGrid-columnHeaders": {
      backgroundColor: "#F0F3F6",
      padding: "0",
      maxHeight: "fit-content",
    },
    ".MuiDataGrid-columnHeader": {
      padding: "10px",
      fontWeight: 700,
      "&.MuiDataGrid-columnHeaderCheckbox": {
        padding: "0 0 0 0",
        display: "grid",
      },
      "&:not(:last-child)": {
        borderRight: "1.5px solid #D3D8DD",
      },
    },
    ".MuiDataGrid-columnHeadersInner": { backgroundColor: "#F0F3F6" },
    ".MuiDataGrid-toolbarContainer": { backgroundColor: "#F0F3F6", fontSize: "14px" },
    ".MuiDataGrid-pinnedColumnHeaders": { backgroundColor: "inherit" },
    ".MuiDataGrid-columnHeaderTitle": {
      fontFamily: "var(--font-liebherr-text-bold)",
      fontSize: "14px",
    },
    "	.MuiDataGrid-columnHeaderDraggableContainer": { width: "100%" },

    ".MuiDataGrid-columnSeparator": {
      color: "transparent",
    },
    ".MuiFormControlLabel-labelPlacementEnd": {
      marginLeft: 0,
      marginRight: 0,
    },
    ".MuiDataGrid-row.Mui-selected": {
      backgroundColor: "transparent",
    },
    ".MuiDataGrid-row": {
      borderBottom: "0.5px solid #D3D8DD",
      ".MuiDataGrid-cell": {
        fontFamily: "var(--copytext-font-family)",
        fontSize: "14px",
        cursor: "default",
        border: "none",
      },
    },
  };
}) as unknown) as StyledGridType;

export const StyledContainer = styled("div")(({ props }: { props: { height: string } }) => {
  return {
    height: props.height,
    width: "100%",
  };
});

export const StyledPagination = styled(Pagination)({
  ".Mui-selected": {
    color: "white",
    backgroundColor: "black !important",
    borderRadius: "0px !important",
    opacity: 1,
    "&:hover": {
      backgroundColor: "black !important",
      borderRadius: "0px !important",
    },
  },
  ".MuiPaginationItem-root": {
    height: "30px",
    padding: 0,
    minWidth: "24px",
    width: "24px",
    fontFamily: "var(--copytext-font-family)",
    fontSize: "12px",
    ".Mui-selected": {
      fontFamily: "var(--copytext-font-family-bold)",
    },
    ".MuiButtonBase-root": {
      padding: 0,
    },
    ".MuiPaginationItem-icon": {
      height: "25px",
      width: "fit-content",
      fontSize: "25px",
      fontFamily: "var(--copytext-font-family)",
      fontWeight: "light",
      padding: 0,
    },
    "&:hover": {
      borderRadius: "0px !important",
    },
  },
});

export const StyledPageRowCount = styled("div")({
  fontFamily: "var(--copytext-font-family)",
  fontSize: "12px",
});

export const StyledGridFooterContainer = styled(GridFooterContainer)({
  backgroundColor: "#F0F3F6",
  padding: "5px 20px 5px 10px",
});

export const StyledGridToolbarContainer = styled(GridToolbarContainer)({
  display: "flex",
  height: "55px",
  padding: "10px",
  borderBottom: "1px solid #D3D8DD",
  ".MuiButton-root": {
    "&:hover": { borderRadius: "2px" },
  },
  ".MuiButton-startIcon": {
    display: "flex",
    marginRight: "2px",
    justifyContent: "center",
  },
});

export const StyledGridToolbarContainerInner = styled("div")({
  height: "100%",
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
});

export const StyledGridToolbarOptions = styled("div")({
  flexGrow: 3,
  display: "flex",
  alignItems: "center",
  flex: 3,
  justifyContent: "space-between",
});

export const StyledGridActionsToolbarOptions = styled("div")({
  flexGrow: 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 3,
});

export const StyledGridSelectedRowCount = styled(GridSelectedRowCount)({
  fontFamily: "var(--copytext-font-family)",
  fontSize: "15px",
  "&.MuiDataGrid-selectedRowCount": {
    display: "flex",
    alignItems: "center",
    height: "54px",
    margin: "0 4px",
  },
});
export const StyledGridToolbarColumnsButton = styled(GridToolbarColumnsButton)({
  fontFamily: "var(--copytext-font-family)",
  fontSize: "15px",
  textTransform: "none",
});

export const StyledGridToolbarSelectedRowsButton = styled(ToggleButton)({
  fontFamily: "var(--copytext-font-family)",
  fontSize: "15px",
  textTransform: "none",
  border: "none",
  borderRadius: 0,
  "&.Mui-disabled": {
    border: "none",
  },
});

export const StyledToolbarButton = styled("button")(({ disabled }: { disabled?: boolean }) => {
  return {
    all: "unset",
    display: "flex",
    margin: "0 4px",
    fontFamily: "var(--copytext-font-family)",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "center",
    color: disabled ? "#888E94" : "#000",
    lineHeight: 0,
    cursor: disabled ? "not-allowed" : "pointer",
  };
});

export const StyledActionButton = styled("button")(({ disabled }) => {
  return {
    all: "unset",
    display: "flex",
    alignItems: "center",
    color: disabled ? "#888E94" : "#000",
    lineHeight: 0,
  };
});

export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)({
  display: "flex",
  padding: 0,
  flex: 1,
  alignItems: "center",
  height: "55px",
  verticalAlign: "center",
  justifyContent: "center",
  maxWidth: "211px",
  marginLeft: "3em",
  ".MuiOutlinedInput-input": {
    padding: 0,
    height: "35px",
    fontFamily: "var(--copytext-font-family)",
    fontSize: "14px",
    color: "#51585D",
  },
  ".MuiOutlinedInput-root": { backgroundColor: "white", borderRadius: "0px" },
});

export const tableComponentStyles = {
  "& .MuiDataGrid-filterForm": { p: 2 },
  "& .MuiDataGrid-filterFormLinkOperatorInput": { mr: 2 },
  "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
  "& .MuiDataGrid-filterFormOperatorInput": { mr: 2, width: 150 },
  "& .MuiDataGrid-filterFormValueInput": { width: 200 },
  "& .MuiOutlinedInput-root": { borderRadius: 0, "&:hover": { backgroundColor: "white" } },
  "& .MuiInputLabel-root": { marginTop: "4px" },
};

export const TableContainerSC = styled("div")(
  ({ withMargin }: { withMargin?: boolean }) => `
  background-color: #F0F3F6;
  margin: ${withMargin ? "0 30px 30px 30px" : 0};
  min-height: 20vh;
`
);

export const tableWrapper = {
  backgroundColor: theme.palette.background.paper,
  width: "100%",
  height: "100%",
  paddingBottom: "1%",
};

export const OverlayContainerSC = styled("div")(
  ({ theme }) => `
        background-color: ${theme.palette.background.paper};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-height: 100%;
`
);

export const tableTitleStyles = { height: "100%", display: "flex", alignItems: "center", marginRight: "3em" };

export const filterButtonStyles = {
  fontFamily: "var(--copytext-font-family)",
  fontSize: "15px",
  textTransform: "none",
  lineHeight: 1,
  "&:hover": { backgroundColor: "transparent" },
};

export const toolbarItemsBoxStylesWithRowSelection = {
  display: "flex",
  borderRight: "1.5px solid #D3D8DD",
  paddingRight: "25px",
  height: "54px",
  columnGap: "20px",
};
export const toolbarOnlyDefaultOptions = {
  display: "flex",
  borderRight: "1.5px solid #D3D8DD",
  paddingRight: "25px",
  height: "54px",
  columnGap: "20px",
};

export const TableWrapperSC = styled("div")(
  ({ theme }) => `
  background-color: ${theme.palette.background.paper};
  width: 100%;
  height: 100%;
  padding-bottom: 1%;
   `
);
