import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateImageUploadMutationVariables = Types.Exact<{
  assetId: Types.Scalars['UUID'];
  image?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateImageUploadMutation = { createImageUpload?: Maybe<{ __typename?: 'AssetImage', id: string, assetId: string, thumbnailBlobStorageUrl?: Maybe<string>, pictureBlobStorageUrl: string }> };


export const CreateImageUploadDocument = gql`
    mutation createImageUpload($assetId: UUID!, $image: String) {
  createImageUpload(image: $image, assetId: $assetId) {
    id
    assetId
    thumbnailBlobStorageUrl
    pictureBlobStorageUrl
  }
}
    `;
export type CreateImageUploadMutationFn = Apollo.MutationFunction<CreateImageUploadMutation, CreateImageUploadMutationVariables>;

/**
 * __useCreateImageUploadMutation__
 *
 * To run a mutation, you first call `useCreateImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageUploadMutation, { data, loading, error }] = useCreateImageUploadMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useCreateImageUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateImageUploadMutation, CreateImageUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateImageUploadMutation, CreateImageUploadMutationVariables>(CreateImageUploadDocument, options);
      }
export type CreateImageUploadMutationHookResult = ReturnType<typeof useCreateImageUploadMutation>;
export type CreateImageUploadMutationResult = Apollo.MutationResult<CreateImageUploadMutation>;
export type CreateImageUploadMutationOptions = Apollo.BaseMutationOptions<CreateImageUploadMutation, CreateImageUploadMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
