import { styled, Table, TableCell } from "@mui/material";
import Battery1BarRoundedIcon from "@mui/icons-material/Battery1BarRounded";

export const SalutationBoxSC = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  };
});

export const SummaryTilesWrapperSC = styled("div")(() => {
  return { display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "100%" };
});

export const NoDataSummaryWrapperSC = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "300px",
  };
});

export const OverlineSC = styled("div")(() => {
  return {
    width: 135,
    height: 4,
    top: 170,
    backgroundColor: "var(--color-yellow)",
    marginBottom: "10px",
  };
});

export const TileAndBadgeWrapperSC = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column",
    minHeight: "fit-content",
    marginTop: "4vh",
    width: "25vw",
  };
});

export const SummaryTileSC = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 3px 5px rgba(58, 64, 70, 0.1)",
    padding: "30px",
    minHeight: "664px",
  };
});

export const SummaryTileBadgeSC = styled("div")(({ theme }) => {
  return {
    width: 74,
    height: 74,
    marginBottom: "-35px",
    marginLeft: "25px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 25px rgba(113, 113, 113, 0.25)",
    borderRadius: 50,
    zIndex: 19,
    padding: "10px",
    alignItems: "center",
    display: "flex",
  };
});

export const SummaryTableCellSC = styled(TableCell)(
  ({ first = false, last = false }: { first?: boolean; last?: boolean }) => {
    return {
      padding: "5px",
      paddingLeft: first ? 0 : "8px",
      alignContent: "center",
      textAlign: last ? "right" : "left",
    };
  }
);

export const SummaryTableSC = styled(Table)(({ hasContentFooter }: { hasContentFooter?: boolean }) => {
  return {
    marginTop: "15px",
    minHeight: hasContentFooter ? "50%" : "445px",
  };
});
export const BatteryCellWrapperSC = styled("div")(() => {
  return { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" };
});

export const BatteryIconWrapperSC = styled("div")(() => {
  return {
    display: "flex",
    height: "fit-content",
    width: "fit-content",
    justifyContent: "flex-start",
    minWidth: "40px",
  };
});

export const BatteryIconSC = styled(Battery1BarRoundedIcon)(() => {
  return {
    fontSize: "28px",
    color: "var(--color-warning)",
    transform: "rotate(90deg)",
    alignSelf: "center",
  };
});

export const ContentFooterWrapperSC = styled("div")(() => {
  return { display: "flex", flexDirection: "column" };
});

export const SummaryTableWrapperSC = styled("div")(({ hasContentFooter }: { hasContentFooter?: boolean }) => {
  return { minHeight: hasContentFooter ? "50%" : "445px" };
});

export const iconStyles = { width: "fit-content", marginLeft: "3px" };

export const subTitleWrapper = { marginBottom: "20px" };

export const TableAndContentFooterWrapperSC = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    minHeight: "450px",
  };
});

export const salutationWrapper = { display: "flex", flexDirection: "column" };

export const seeMoreButtonWrapper = { display: "flex", justifyContent: "flex-end" };

export const dividerStyles = (margin?: string) => {
  return { color: "#B4ACAC", margin: margin ?? "20px 0" };
};

export const alertTitleAndDateWrapper = { display: "flex", flexDirection: "column" };
export const batteryVoltageText = { width: "60px", minWidth: "fit-content" };
