import { NotificationDefinition } from "components/pages/AlertManagement/AlertEditing/recoilState";
import { SetterOrUpdater } from "recoil";

export default (
  value: Partial<NotificationDefinition> | { delete: true },
  setNotificationDefinitions: SetterOrUpdater<NotificationDefinition[]>,
  notificationDefinition: NotificationDefinition,
) => {
  setNotificationDefinitions((notificationDefinitions: any[]) => {
    const newNotificationDefinitions = (notificationDefinitions ?? []).reduce<NotificationDefinition[]>(
      (newNotificationDefinitionsInner, notificationDefinitionInner) => {
        if (notificationDefinition.uiId === notificationDefinitionInner.uiId) {
          if (Object.keys(value).includes("delete")) {
            return newNotificationDefinitionsInner;
          }
          return newNotificationDefinitionsInner.concat({ ...notificationDefinitionInner, ...value });
      }
        return newNotificationDefinitionsInner.concat(notificationDefinitionInner);
      },
      []
    );
    return newNotificationDefinitions;
  });
};
