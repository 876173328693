import { atom } from "recoil";
import { MappingDeviceNotification, SelectedAsset } from "./types";

export const preMappedDeviceNotificationsAtom = atom<MappingDeviceNotification[] | undefined>({
  key: "preMappedDeviceNotificationsAtom",
  default: undefined,
});

export const selectedAssetsForAlertAtom = atom<SelectedAsset[]>({
  key: "selectedAssetsForAlertAtom",
  default: [],
});
