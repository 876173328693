import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import PLIcon from "components/patternLib/PLIcon";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import translations from "translations";
import { useFormStyles } from "./utils";

export type SelectChangeEv = SelectChangeEvent<unknown>;

export const testIDs = getTestIDs();
export interface SelectItem {
  label: string;
  value: string;
}
export interface SelectProps extends MuiSelectProps {
  disabled?: boolean;
  icon?: React.ElementType<React.SVGProps<SVGSVGElement>> | undefined;
  iconSize?: string | number; // this prop is needed depending on which SVG icon is used
  id: string;
  label?: string;
  options?: SelectItem[];
  paperMargin?: number; // in em
  placeholder?: string;
  selected: string | string[] | undefined;
  testId?: string;
}

const Select: React.FC<SelectProps> = ({
  autoWidth,
  onChange,
  disabled,
  classes,
  placeholder,
  options = [],
  label,
  id,
  name,
  selected,
  iconSize,
  children,
  testId,
  style,
}) => {
  const selectClasses = useFormStyles({ iconSize, style });
  const labelId = `${id}-label`;

  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        marginTop: 0,
        width: "260px",
        borderRadius: 0,
        cursor: "default",
        position: "fixed",
      } as React.CSSProperties,
    },
  };

  const content = options?.map((option) => {
    return (
      <MenuItem sx={{ cursor: "default" }} value={option.value} key={`option-${option.value}`}>
        {option.label}
      </MenuItem>
    );
  });

  return (
    <FormControl disabled={disabled}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}

      <MuiSelect
        name={name}
        id={id}
        IconComponent={(props: any) => (
          <Box {...props}>
            <PLIcon size="24px" iconName="arrow-down" />
          </Box>
        )}
        autoWidth={autoWidth}
        labelId={labelId}
        disabled={disabled}
        data-testid={testId ?? testIDs.Select}
        value={selected ?? ""}
        onChange={onChange}
        displayEmpty
        renderValue={(selected) =>
          options.reduce<string>(() => {
            if (!selected || selected === "") {
              return placeholder ?? translations.globals.placeholders.Dropdownfield;
            }
            return options.find((option) => option.value === selected)?.label ?? "";
          }, "")
        }
        input={<InputBase id={id} />}
        inputProps={{ className: selectClasses.selectInput }}
        classes={classes}
        className={selectClasses.root}
        MenuProps={MenuProps}
      >
        {options && content}
        {children}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
