import { Button, styled } from "@mui/material";
import PLIcon from "components/patternLib/PLIcon";
import React from "react";

interface AddRuleOrGroupButtonProps {
  onClick?: () => void;
  label?: string | undefined;
}

const AddRuleOrGroupButtonSC = styled(Button)(() => {
  return {
    minWidth: "fit-content",
    padding: 0,
    fontWeight: 400,
    fontFamily: "var(--copytext-font-family)",
    fontSize: 16,
    textTransform: "none",
    color: "#3B6579",
    marginRight: 10,
  };
});

export default ({ onClick, label }: AddRuleOrGroupButtonProps) => {
  return (
    <AddRuleOrGroupButtonSC startIcon={<PLIcon bold color="#3B6579" size="14px" iconName="plus" />} onClick={onClick}>
      {label}
    </AddRuleOrGroupButtonSC>
  );
};
