import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesForConnectedDevicesToGatewayQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.DeviceSortInput> | Types.DeviceSortInput>;
}>;


export type GetDevicesForConnectedDevicesToGatewayQuery = { devices: Array<{ __typename?: 'Device', id: string, serialNo: string, deviceType: { __typename?: 'DeviceType', id: string, deviceTypeName: string }, lastMeasurement?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, utcTimeMeasured: string }>>>, mappingDeviceTag: Array<{ __typename?: 'MappingDeviceTag', id: string, deviceId: string, tagId: string, isDeleted?: Maybe<boolean>, tag: { __typename?: 'Tag', id: string, tagValue: string } }>, mappingDeviceFirmwareDevice: Array<{ __typename?: 'MappingDeviceFirmwareDevice', id: string, deviceFirmware: { __typename?: 'DeviceFirmware', id: string, firmwareVersion: string } }> }> };


export const GetDevicesForConnectedDevicesToGatewayDocument = gql`
    query getDevicesForConnectedDevicesToGateway($where: DeviceFilterInput, $take: Int, $skip: Int, $order: [DeviceSortInput!]) {
  devices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    serialNo
    deviceType {
      id
      deviceTypeName
    }
    lastMeasurement: sensorMeasurementCurrentValue(order: {utcTimeMeasured: DESC}) {
      id
      utcTimeMeasured
    }
    mappingDeviceTag(where: {isDeleted: {neq: true}}) {
      id
      deviceId
      tagId
      isDeleted
      tag {
        id
        tagValue
      }
    }
    mappingDeviceFirmwareDevice {
      id
      deviceFirmware {
        id
        firmwareVersion
      }
    }
  }
}
    `;

/**
 * __useGetDevicesForConnectedDevicesToGatewayQuery__
 *
 * To run a query within a React component, call `useGetDevicesForConnectedDevicesToGatewayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesForConnectedDevicesToGatewayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesForConnectedDevicesToGatewayQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDevicesForConnectedDevicesToGatewayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesForConnectedDevicesToGatewayQuery, GetDevicesForConnectedDevicesToGatewayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesForConnectedDevicesToGatewayQuery, GetDevicesForConnectedDevicesToGatewayQueryVariables>(GetDevicesForConnectedDevicesToGatewayDocument, options);
      }
export function useGetDevicesForConnectedDevicesToGatewayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesForConnectedDevicesToGatewayQuery, GetDevicesForConnectedDevicesToGatewayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesForConnectedDevicesToGatewayQuery, GetDevicesForConnectedDevicesToGatewayQueryVariables>(GetDevicesForConnectedDevicesToGatewayDocument, options);
        }
export type GetDevicesForConnectedDevicesToGatewayQueryHookResult = ReturnType<typeof useGetDevicesForConnectedDevicesToGatewayQuery>;
export type GetDevicesForConnectedDevicesToGatewayLazyQueryHookResult = ReturnType<typeof useGetDevicesForConnectedDevicesToGatewayLazyQuery>;
export type GetDevicesForConnectedDevicesToGatewayQueryResult = Apollo.QueryResult<GetDevicesForConnectedDevicesToGatewayQuery, GetDevicesForConnectedDevicesToGatewayQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
