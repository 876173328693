import { Box, Divider, styled, Typography } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { RightTileWrapperSC } from "components/pages/AssetManagement/tileStyles";
import { getUserById } from "components/pages/utils";
import { useGetDynamicGroupByIdQuery } from "graphqlBase/Groups/__generated__/getDynamicGroupById";
import React, { useEffect, useState } from "react";
import translations from "translations";
import { formatDate } from "translations/formatter";
import DetailInfo from "components/atomics/DetailInfo";

interface GroupInfoTileProps {
  assetGroupId: string;
}

export const DescriptionBoxSC = styled("div")(
  () => `
  display: flex;
  min-height: 5vh;
  margin-top: 10px;
    `
);

const titleBoxStyles = { marginBottom: "25px" };
const topListBoxStyles = { marginBottom: "15px" };
const lowerListBoxStyles = { marginTop: "15px" };
const dividerStyles = { margin: 0 };

const GroupInfoTile: React.FC<GroupInfoTileProps> = ({ assetGroupId }) => {
  const [createdBy, setCreatedBy] = useState<string | undefined>();

  const { data, loading } = useGetDynamicGroupByIdQuery({
    variables: { id: assetGroupId },
    fetchPolicy: "cache-and-network",
  });

  const isoDate = new Date(data?.group?.createdAt ?? new Date().toISOString());

  useEffect(() => {
    if (!createdBy && data?.group?.userId)
      getUserById(data?.group?.userId)
        .then((user) => {
          setCreatedBy(user.firstName);
        })
        .catch(() => {
          setCreatedBy("");
          console.error("failed to fetch user name for asset group");
        });
  }, [createdBy, data]);

  const assetInfo = [
    {
      label: translations.entities.group?.columns?.groupName,
      value: data?.group?.groupName ?? "",
    },
    {
      label: translations.entities.group?.columns?.createdAt,
      value: formatDate(isoDate),
    },
    {
      label: translations.entities.group?.columns?.createdBy,
      value: createdBy,
    },
  ];

  const hasDescription = (data?.group?.groupDescription ?? "").trim().length > 0;

  return (
    <RightTileWrapperSC>
      <Box sx={titleBoxStyles}>
        <Typography variant="h4">{translations.pages.groups.groupInfo}</Typography>
      </Box>
      <LoadingSpinner loading={!!loading || !createdBy || false}>
        <Box>
          <Box sx={topListBoxStyles}>
          <DetailInfo itemHeight="35px" noSeparator data={assetInfo} />
          </Box>
          <Divider variant="middle" sx={dividerStyles} />
          <Box sx={lowerListBoxStyles}>
            <Typography variant="h6">{translations.entities.group.columns.groupDescription}</Typography>
            <DescriptionBoxSC>
              <Typography sx={{ display: "flex" }} variant="copy">
                {hasDescription ? data?.group?.groupDescription : translations.pages.groups.noGroupDescription}
              </Typography>
            </DescriptionBoxSC>
          </Box>
        </Box>
      </LoadingSpinner>
    </RightTileWrapperSC>
  );
};
export default GroupInfoTile;
