import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserInfoByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetUserInfoByIdQuery = { user?: Maybe<{ __typename?: 'User', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, email?: Maybe<string> }> };


export const GetUserInfoByIdDocument = gql`
    query getUserInfoById($id: UUID!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useGetUserInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetUserInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>(GetUserInfoByIdDocument, options);
      }
export function useGetUserInfoByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>(GetUserInfoByIdDocument, options);
        }
export type GetUserInfoByIdQueryHookResult = ReturnType<typeof useGetUserInfoByIdQuery>;
export type GetUserInfoByIdLazyQueryHookResult = ReturnType<typeof useGetUserInfoByIdLazyQuery>;
export type GetUserInfoByIdQueryResult = Apollo.QueryResult<GetUserInfoByIdQuery, GetUserInfoByIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
