import { GetRoleNameAndDescriptionQuery } from "graphqlBase/Role/__generated__/getRoleNameAndDescription";
import { ResultType } from "graphqlBase/types";
import { DefaultValue, GetRecoilValue, SetRecoilState, atom, selectorFamily } from "recoil";

type Role = ResultType<GetRoleNameAndDescriptionQuery["roles"]>;

export interface SelectUser {
  mappingId?: string;
  roleId: string;
  toDelete?: boolean;
  userId: string;
}

export const mappingRoleUsersLoading = atom<boolean | undefined>({
  key: "mappingRoleUsersLoading",
  default: undefined,
});
export const drawerAtom = atom<Role | undefined>({
  key: "drawerAtom",
  default: undefined,
});

export const selectedUsersOnRoleState = atom<SelectUser[]>({
  key: "selectUserState",
  default: [],
});

const getMappingState = ({ get, userId, roleId }: { get: GetRecoilValue; roleId: string; userId: string }) => {
  const mappings = get(selectedUsersOnRoleState);
  return !!(mappings ?? []).find(
    (mapping) => mapping.userId === userId && mapping.roleId === roleId && !mapping.toDelete
  );
};

const setMappingState = ({
  set,
  userId,
  roleId,
  newValue,
}: {
  newValue: boolean | DefaultValue;
  roleId: string;
  set: SetRecoilState;
  userId: string;
}) => {
  set(selectedUsersOnRoleState, (prevState) => {
    const currState = prevState.slice(0, 9999);
    const toDelete = newValue instanceof DefaultValue ? true : !newValue;
    const indexInPrev = currState.findIndex((mapped) => mapped.roleId === roleId && mapped.userId === userId);

    if (~indexInPrev) {
      currState[indexInPrev] = { ...currState[indexInPrev], toDelete };
      return currState;
    } else {
      return currState.concat({
        userId,
        roleId,
      });
    }
  });
};

export const mappingRoleUserSelector = selectorFamily<boolean, { roleId: string; userId: string }>({
  key: "mappingRoleUserSelector",

  get: ({ userId, roleId }) => ({ get }) => getMappingState({ get, userId, roleId }),
  set: ({ userId, roleId }) => ({ set }, newValue) => setMappingState({ set, newValue, userId: userId, roleId }),
});
