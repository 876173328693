import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceTagsForTagsListQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DeviceFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.DeviceSortInput> | Types.DeviceSortInput>;
}>;


export type GetDeviceTagsForTagsListQuery = { devices: Array<{ __typename?: 'Device', id: string, mappingDeviceTag: Array<{ __typename?: 'MappingDeviceTag', id: string, tag: { __typename?: 'Tag', id: string, tagValue: string } }> }> };


export const GetDeviceTagsForTagsListDocument = gql`
    query getDeviceTagsForTagsList($where: DeviceFilterInput, $take: Int, $skip: Int, $order: [DeviceSortInput!]) {
  devices(where: $where, take: $take, skip: $skip, order: $order) {
    id
    mappingDeviceTag(where: {isDeleted: {neq: true}}) {
      id
      tag {
        id
        tagValue
      }
    }
  }
}
    `;

/**
 * __useGetDeviceTagsForTagsListQuery__
 *
 * To run a query within a React component, call `useGetDeviceTagsForTagsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceTagsForTagsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceTagsForTagsListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDeviceTagsForTagsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceTagsForTagsListQuery, GetDeviceTagsForTagsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceTagsForTagsListQuery, GetDeviceTagsForTagsListQueryVariables>(GetDeviceTagsForTagsListDocument, options);
      }
export function useGetDeviceTagsForTagsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceTagsForTagsListQuery, GetDeviceTagsForTagsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceTagsForTagsListQuery, GetDeviceTagsForTagsListQueryVariables>(GetDeviceTagsForTagsListDocument, options);
        }
export type GetDeviceTagsForTagsListQueryHookResult = ReturnType<typeof useGetDeviceTagsForTagsListQuery>;
export type GetDeviceTagsForTagsListLazyQueryHookResult = ReturnType<typeof useGetDeviceTagsForTagsListLazyQuery>;
export type GetDeviceTagsForTagsListQueryResult = Apollo.QueryResult<GetDeviceTagsForTagsListQuery, GetDeviceTagsForTagsListQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
