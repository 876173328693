export default {
  name: "Asset Master Data",
  plural: "Asset Master Data",
  automaticallyIdentified: "Assignments",
  notAutomaticallyIdentified: "Open Assignments",
  notAutomaticallyIdentifiedMessage: "The Asset could not be identified automatically and must be selected manually",
  pairedAssetDevice: "Paired Asset and Device",
  masterData: "Master Data",
  columns: {
    craneSerialNo: "Crane SerialNo",
    id: "ID",
    artileNr: "Article No.",
    itemName: "Article Name",
    itemConstructionNumber: "Constr. No.",
    itemManufacturer: "Manufacturer",
    itemManufacturerNo: "Item Manufacturer No",
    itemMeasurement: "Item Measurement",
    itemWeight: "Item Weight",
    isDeleted: "Is Deleted",
    assetSerialNo: "Serial No.",
    articleNumber: "Article",
  },
};
