import { LhChange } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { Box } from "@mui/material";
import MandatoryFieldMessage from "components/pages/AlertManagement/AlertEditing/MandatoryFieldMessage";
import { hasAlertNameAtom, hasFormErrorsAtom } from "components/pages/AlertManagement/AlertEditing/recoilState";
import PLTextinput from "components/patternLib/form/PLTextinput";
import * as React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Props from "./index.d";

const AlertName: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    placeholder = "placeholder",
    disabled,
    testId,
    errors,
    isPartOfSummary,
    width,
    ...rest
  } = props;
  const formHasErrors = useRecoilValue(hasFormErrorsAtom);
  const setHasAlertName=useSetRecoilState(hasAlertNameAtom);
  
  React.useEffect(() => {
    if(!state){
    setHasAlertName(false)
    }
    else setHasAlertName(true)
  }, [state])

  const handleChange = (event: CustomEvent<LhChange>) => {
    updateValue(event);
  };

  return (
    <>
      {props.disabled && isPartOfSummary ? null : (
        <Box sx={{ flexDirection: "column" }}>
          <PLTextinput
            showRemoveIcon={false}
            lhBlur={onBlur}
            status={!state && formHasErrors ? "error" : "default"}
            value={state}
            lhChange={handleChange}
            readonly={disabled}
            label={title}
            width={width}
          />
          {!state && formHasErrors && <MandatoryFieldMessage />}
        </Box>
      )}
    </>
  );
};

export default AlertName;
