import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceIdsOnArticleNumberValueQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetDeviceIdsOnArticleNumberValueQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, deviceId: string, asset: { __typename?: 'Asset', id: string, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, articleNumber?: Maybe<string> }> } }> };


export const GetDeviceIdsOnArticleNumberValueDocument = gql`
    query getDeviceIdsOnArticleNumberValue($where: AssetDeviceFilterInput) {
  assetDevices(where: $where) {
    id
    deviceId
    asset {
      id
      assetMasterData {
        id
        articleNumber
      }
    }
  }
}
    `;

/**
 * __useGetDeviceIdsOnArticleNumberValueQuery__
 *
 * To run a query within a React component, call `useGetDeviceIdsOnArticleNumberValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceIdsOnArticleNumberValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceIdsOnArticleNumberValueQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDeviceIdsOnArticleNumberValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceIdsOnArticleNumberValueQuery, GetDeviceIdsOnArticleNumberValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceIdsOnArticleNumberValueQuery, GetDeviceIdsOnArticleNumberValueQueryVariables>(GetDeviceIdsOnArticleNumberValueDocument, options);
      }
export function useGetDeviceIdsOnArticleNumberValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceIdsOnArticleNumberValueQuery, GetDeviceIdsOnArticleNumberValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceIdsOnArticleNumberValueQuery, GetDeviceIdsOnArticleNumberValueQueryVariables>(GetDeviceIdsOnArticleNumberValueDocument, options);
        }
export type GetDeviceIdsOnArticleNumberValueQueryHookResult = ReturnType<typeof useGetDeviceIdsOnArticleNumberValueQuery>;
export type GetDeviceIdsOnArticleNumberValueLazyQueryHookResult = ReturnType<typeof useGetDeviceIdsOnArticleNumberValueLazyQuery>;
export type GetDeviceIdsOnArticleNumberValueQueryResult = Apollo.QueryResult<GetDeviceIdsOnArticleNumberValueQuery, GetDeviceIdsOnArticleNumberValueQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
