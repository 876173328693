import { BinaryFilter } from "@cubejs-client/core";
import { atom, selector } from "recoil";

export type RecoilFilterType = BinaryFilter;

export const filtersAtom = atom<RecoilFilterType[]>({
  key: "filters",
  default: [],
});

export const filtersSelector = selector<RecoilFilterType[]>({
  key: "filtersSelectorkey",
  get: ({ get }) => {
    return get(filtersAtom);
  },
  set: ({ set }, newValueIn) => {
    set(filtersAtom, (prevState) => {
      if (!Array.isArray(newValueIn)) return prevState;

      return newValueIn.flatMap((newValue) => {
        const hasTheSameMemberName = prevState.find((item) => item.member === newValue.member);
        if (hasTheSameMemberName) {
          return prevState.reduce<RecoilFilterType[]>((acc, item) => {
            if (item.member !== newValue.member) return [...acc, item];
            else if (
              item.member === newValue.member &&
              newValue.operator !== "inDateRange" &&
              newValue.member !== "FactUtilization.dimassetid"
            ) {
              const newValues = item.values
                .filter((elem) => !(newValue.values ?? []).includes(elem))
                .concat((newValue.values ?? []).filter((elem) => !(item.values ?? []).includes(elem)));

              if (!newValues.length) return acc;
              return [...acc, { ...item, values: newValues }];
            } else return [...acc, { ...item, values: newValue.values }];
          }, []);
        } else return prevState.concat(newValue);
      });
    });
  },
});

export const dateRangeState = atom<{ start: Date; end: Date } | undefined>({
  key: "dateRangeState",
  default: undefined,
});
