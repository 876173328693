export default {
  name: "device capability",
  plural: "device capabilities",
  columns: {
    capability: "capability",
    capabilityId: "capability ID",
    device: "device",
    id: "ID",
    isDeleted: "is deleted",
    technicalMax: "technical Max",
    technicalMin: "technical Min",
    tenantId: "tenant ID",
    unit: "unit",
    unitId: "unit ID",
  },
};
