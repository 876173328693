import { SelectItem } from "components/molecules/MultiSelectFreeSolo";
import { TagCreateType } from "graphqlBase/types";
import { atom } from "recoil";

export const tagsToCreateAtom = atom<TagCreateType[]>({
  key: "tagsToCreateAtom",
  default: [],
});

export const freeSoloChipsListAtom = atom<Array<SelectItem>>({
  key: "freeSoloChipsListAtom",
  default: [],
});

export const assignedTagErrorAtom = atom<string | undefined>({
  key: "assignedTagErrorAtom",
  default: undefined,
});
