import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import ErrorPage from "./ErrorPage";
import { globalErrorUIState } from "./globalErrorState";

export default function ErrorComponent() {
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = useRecoilState(globalErrorUIState);

  useEffect(() => {
    setOpen(!!errors.length);
  }, [errors, setOpen]);

  return <>{open && <ErrorPage />}</>;
}
