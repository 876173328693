import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTabsItemProps = DropOnONLh<JSX.PatternlibTabsItem>;
export type PLTabsItemExtendedProps = PLTabsItemProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTabsItem = wrapWc<JSX.PatternlibTabsItem>("patternlib-tabs-item");
export const useRefPLTabsItem = () => useRef<HTMLPatternlibTabsItemElement>(null);

const PLTabsItem = React.forwardRef<HTMLPatternlibTabsItemElement, PLTabsItemExtendedProps>((props, ref) => {
  const myRef = useRefPLTabsItem();
  return (
    <WrappedTabsItem ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTabsItem>
  );
});
PLTabsItem.displayName = "PLTabsItem";

export default PLTabsItem;
