import { AuthContextProps, AuthProvider, useAuth } from "oidc-react";
import React, { useCallback } from "react";
import { oidcConfig } from ".";
import jwtDecode from "jwt-decode";
import { formatDateTime } from "translations/formatter";
export const AuthenticationCompontent: React.FC<{}> = (props) => {
  // if (process.env.REACT_APP_DISABLE_AUTH === "true" || process.env.REACT_APP_FOR_TEST === "true")
  if (process.env.REACT_APP_DISABLE_AUTH === "true") return <>{props.children}</>;
  return (
    <AuthProvider {...oidcConfig}>
      <AuthWrapper>{props.children}</AuthWrapper>
    </AuthProvider>
  );
};

const authLogger = (auth: AuthContextProps | null) => {
  if (process.env.NODE_ENV === "development" && auth?.userData?.access_token) {
    const { access_token: accessToken, profile } = auth?.userData;

    let validTill = "";
    try {
      //@ts-ignore
      validTill = new Date(+jwtDecode(accessToken).exp * 1000);
    } catch (e) {}
    console.log(`\n{"authorization":"Bearer ${accessToken ?? ""}"}

  user: ${profile.name}
  valid until: ${validTill}


  
  `);
  }
};

const AuthWrapper: React.FC<{}> = ({ children }) => {
  const auth = useAuth();
  const secureChildren = useCallback(() => {
    if (!auth?.userData?.access_token ?? null) return null;
    authLogger(auth);
    return <>{children}</>;
  }, [auth, children]);
  return secureChildren();
};

export default AuthenticationCompontent;
