import { useCreateDeviceConfigurationsMutation } from "graphqlBase/DeviceConfiguration/__generated__/createDeviceConfigurations";
import { GetDevicesForDeviceConfigDocument } from "graphqlBase/Devices/__generated__/getDevicesForDeviceConfig";
import { GetMappingDeviceConfigurationDevicesDocument } from "graphqlBase/Devices/__generated__/getMappingDeviceConfigurationDevices";
import { useCreateMappingDeviceConfigurationDevicesMutation } from "graphqlBase/MappingDeviceConfigurationDevice/__generated__/createMappingDeviceConfigurationDevices";
import { useUpdateMappingDeviceConfigurationDevicesMutation } from "graphqlBase/MappingDeviceConfigurationDevice/__generated__/updateMappingDeviceConfigurationDevices";
import { useRecoilValue } from "recoil";
import { deviceConfigurationAtom, mappingDeviceConfigurationDeviceId } from "./recoilState";

const useHandleSaveConfig = (deviceId: string) => {
  const currentDeviceConfiguration = useRecoilValue(deviceConfigurationAtom);
  const idMappingDeviceconfiguration = useRecoilValue(mappingDeviceConfigurationDeviceId);
  const [createDeviceConfigurationsMutation] = useCreateDeviceConfigurationsMutation();
  const [createMappingDeviceConfigurationDevicesMutation] = useCreateMappingDeviceConfigurationDevicesMutation();
  const [updateMappingDeviceConfigurationDevicesMutation] = useUpdateMappingDeviceConfigurationDevicesMutation();
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const handleSaveConfig = async (config?: string) => {
    const configurationJSON = config ?? JSON.stringify(currentDeviceConfiguration);

    try {
      const { data: deviceConfigurationData } = await createDeviceConfigurationsMutation({
        variables: {
          deviceConfigurations: [
            {
              configurationJSON: configurationJSON,
            },
          ],
        },
      });

      if (!deviceConfigurationData?.createDeviceConfigurations?.[0]?.id) {
        return;
      }
      const { errors } = await createMappingDeviceConfigurationDevicesMutation({
        variables: {
          mappingDeviceConfigurationDevices: [
            {
              deviceConfigurationId: deviceConfigurationData.createDeviceConfigurations[0].id,
              deviceId: deviceId,
              mappingDeviceConfigurationDeviceStatusId: "d0591ed4ab37489e8874788083f52e5c",
            },
          ],
        },
        refetchQueries: idMappingDeviceconfiguration
          ? undefined
          : [
              {
                query: GetDevicesForDeviceConfigDocument,
                variables: { where: { deviceId: { eq: deviceId } } },
              },
              {
                query: GetMappingDeviceConfigurationDevicesDocument,
                variables: { where: { deviceId: { eq: deviceId } } },
              },
            ],
      });

      if (!idMappingDeviceconfiguration || errors) {
        return;
      }
      await updateMappingDeviceConfigurationDevicesMutation({
        variables: {
          mappingDeviceConfigurationDevices: [
            {
              id: idMappingDeviceconfiguration,
              mappingDeviceConfigurationDeviceStatusId: "d0591ed4ab37489e8874788083f52e5c",
            },
          ],
        },
        refetchQueries: [
          {
            query: GetDevicesForDeviceConfigDocument,
            variables: { where: { deviceId: { eq: deviceId } } },
          },
          {
            query: GetMappingDeviceConfigurationDevicesDocument,
            variables: { where: { deviceId: { eq: deviceId } } },
          },
        ],
      });
    } catch (error) {}
  };
  return handleSaveConfig;
};
export default useHandleSaveConfig;
