import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceIdsOnGeneralItemValueQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetDeviceIdsOnGeneralItemValueQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, deviceId: string, asset: { __typename?: 'Asset', id: string, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string> }> } }> };


export const GetDeviceIdsOnGeneralItemValueDocument = gql`
    query getDeviceIdsOnGeneralItemValue($where: AssetDeviceFilterInput) {
  assetDevices(where: $where) {
    id
    deviceId
    asset {
      id
      assetMasterData {
        id
        generalItem
      }
    }
  }
}
    `;

/**
 * __useGetDeviceIdsOnGeneralItemValueQuery__
 *
 * To run a query within a React component, call `useGetDeviceIdsOnGeneralItemValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceIdsOnGeneralItemValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceIdsOnGeneralItemValueQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDeviceIdsOnGeneralItemValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceIdsOnGeneralItemValueQuery, GetDeviceIdsOnGeneralItemValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceIdsOnGeneralItemValueQuery, GetDeviceIdsOnGeneralItemValueQueryVariables>(GetDeviceIdsOnGeneralItemValueDocument, options);
      }
export function useGetDeviceIdsOnGeneralItemValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceIdsOnGeneralItemValueQuery, GetDeviceIdsOnGeneralItemValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceIdsOnGeneralItemValueQuery, GetDeviceIdsOnGeneralItemValueQueryVariables>(GetDeviceIdsOnGeneralItemValueDocument, options);
        }
export type GetDeviceIdsOnGeneralItemValueQueryHookResult = ReturnType<typeof useGetDeviceIdsOnGeneralItemValueQuery>;
export type GetDeviceIdsOnGeneralItemValueLazyQueryHookResult = ReturnType<typeof useGetDeviceIdsOnGeneralItemValueLazyQuery>;
export type GetDeviceIdsOnGeneralItemValueQueryResult = Apollo.QueryResult<GetDeviceIdsOnGeneralItemValueQuery, GetDeviceIdsOnGeneralItemValueQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
