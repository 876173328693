import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLListProps = DropOnONLh<JSX.PatternlibList>;
export type PLListExtendedProps = PLListProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedList = wrapWc<JSX.PatternlibList>("patternlib-list");
export const useRefPLList = () => useRef<HTMLPatternlibListElement>(null);

const PLList = React.forwardRef<HTMLPatternlibListElement, PLListExtendedProps>((props, ref) => {
  const myRef = useRefPLList();
  return (
    <WrappedList ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedList>
  );
});
PLList.displayName = "PLList";

export default PLList;
