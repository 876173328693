import React from "react";
import { TablePaginationProps } from "../tableTypes";
import MuiTablePagination from "@mui/material/TablePagination";
import translations from "translations";
import getTestIDs from "lib/utils/getTestIDs";
export const testIDs = getTestIDs();
const TablePagination: React.FC<TablePaginationProps<object>> = ({
  tableProps,
  TablePaginationActions,
  config,
  tableInstance,
}) => {
  const {
    gotoPage,
    setPageSize,
    pageCount,
    pageOptions,
    onChangePage,
    rows,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <>
      {config.pagination === true && (
        <MuiTablePagination
          data-testid={testIDs.TablePagination}
          component="div"
          // count={rows.length}
          //   count={100}
          count={rows.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          //   page={pageCount}
          labelDisplayedRows={() => null}
          rowsPerPageOptions={tableProps?.rowsPerPageOptions}
          labelRowsPerPage={translations.globals.table.rowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={(e, page) => {
            gotoPage(page);
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          ActionsComponent={(actionProps) =>
            TablePaginationActions({
              ...actionProps,
              disable: tableProps?.disablePagination ?? pageSize > rows.length,
              pageOptions,
              pageCount,
              onChangePage,
            })
          }
        />
      )}
    </>
  );
};

export default TablePagination;
