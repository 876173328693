import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLSidebarProps = DropOnONLh<JSX.PatternlibSidebar>;
export type PLSidebarExtendedProps = PLSidebarProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedSidebar = wrapWc<JSX.PatternlibSidebar>("patternlib-sidebar");
export const useRefPLSidebar = () => useRef<HTMLPatternlibSidebarElement>(null);

const PLSidebar = React.forwardRef<HTMLPatternlibSidebarElement, PLSidebarExtendedProps>((props, ref) => {
  const myRef = useRefPLSidebar();
  return (
    <WrappedSidebar ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedSidebar>
  );
});
PLSidebar.displayName = "PLSidebar";

export default PLSidebar;
