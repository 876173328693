import { LhChange } from "@liebherr/patternlib";
import {
  PatternlibDropzoneCustomEvent,
  PatternlibTextinputCustomEvent,
} from "@liebherr/patternlib/dist/types/components";
import { useCreateImageUploadMutation } from "graphqlBase/Assets/__generated__/createImageUpload";
import { useUpdateAssetImagesMutation } from "graphqlBase/Assets/__generated__/updateAssetImages";
import { useCallback, useState } from "react";
import translations from "translations";

interface UseHandleSavePhotoProps {
  afterMutation: () => Promise<void>;
  assetId: string;
  setDropzoneModalOpen: (value: boolean) => void;
  setStep: (value: 0 | 1) => void;
}

interface UseHandleSavePhotoReturn {
  clearStates: () => void;
  convertImageToBase64: (file: File) => Promise<void>;
  file: File | undefined;
  handleCloseToast: () => void;
  handleDescription: (event: PatternlibTextinputCustomEvent<LhChange>) => void;
  handleUploadedPictures: (event: PatternlibDropzoneCustomEvent<LhChange>) => void;
  image: string | undefined;
  imageDescription: string | undefined;
  saveImage: () => Promise<any>;
  toastObject: { message: string; open: boolean; severity: "success" | "error" } | undefined;
}
export interface FileWithPath extends File {
  readonly path?: string;
}
export const useHandleSavePhoto = ({
  assetId,
  afterMutation,
  setDropzoneModalOpen,
  setStep,
}: UseHandleSavePhotoProps): UseHandleSavePhotoReturn => {
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<string>();
  const [imageDescription, setImageDescription] = useState<string>();
  const [toastObject, setToastObject] = useState<
    { message: string; open: boolean; severity: "success" | "error" } | undefined
  >();
  const [createImageUpload] = useCreateImageUploadMutation();
  const [createImageDescription] = useUpdateAssetImagesMutation();
  const toBase64 = useCallback(
    (files: FileWithPath) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      }),
    []
  );
  const handleCloseToast = useCallback(() => {
    setToastObject(undefined);
  }, []);
  const clearStates = useCallback(() => {
    setFile(undefined);
    setImage(undefined);
    setStep(0);
    setImageDescription(undefined);
    setDropzoneModalOpen(false);
    setTimeout(() => {
      handleCloseToast();
    }, 3000);
  }, []);

  const handleUploadedPictures = useCallback((event: PatternlibDropzoneCustomEvent<LhChange>) => {
    const file = (event.detail.value as File[])[0];
    setFile(file);
  }, []);

  const convertImageToBase64 = useCallback(
    async (file: File) => {
      const base64 = await toBase64(file);
      setImage(base64);
    },
    [toBase64]
  );

  const saveImage = useCallback(async () => {
    if (!image) return;
    try {
      const res = await createImageUpload({
        variables: {
          assetId: assetId,
          image: image.split(",")[1],
        },
      });
      const { data } = await createImageDescription({
        variables: {
          assetImages: [{ id: res.data?.createImageUpload?.id ?? "", description: imageDescription ?? "" }],
        },
      });
      await afterMutation();
      setToastObject({
        open: true,
        message: translations.pages.assetManagement.assetDetails.toastMessageSuccess,
        severity: "success",
      });
      return data;
    } catch (error) {
      setToastObject({
        open: true,
        message: translations.pages.assetManagement.assetDetails.toastMessageError,
        severity: "error",
      });
    } finally {
      setDropzoneModalOpen(false);
      clearStates();
    }
  }, [
    afterMutation,
    assetId,
    clearStates,
    createImageDescription,
    createImageUpload,
    image,
    imageDescription,
    setDropzoneModalOpen,
  ]);

  const handleDescription = useCallback((event: PatternlibTextinputCustomEvent<LhChange>) => {
    setImageDescription(event.detail.value as string);
  }, []);

  return {
    file,
    image,
    imageDescription,
    handleUploadedPictures,
    convertImageToBase64,
    saveImage,
    handleDescription,
    clearStates,
    toastObject,
    handleCloseToast,
  };
};
