export default {
  name: "Notification Scope",
  plural: "Notification Scopes",
  columns: {
    id: "ID",
    deviceModelCapabilityId: "Device Model Capability ID",
    scopeName: "Scope Name",
    dataType: "Data Type",
    createdAt: "Created At",
    modifiedAt: "Modified At",
    tenantId: "",
  },
  scopeNames: {
    "Humidity Scope": "Humidity",
    "Device Zustand": "Device State",
    "Temperature Scope": "Temperature",
    "Location Scope": "GPS/Geofence",
  },
};
