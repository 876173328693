import { styled, Typography } from "@mui/material";
import PLDatepicker from "components/patternLib/form/PLDatepicker";
import React from "react";
import { useSetRecoilState } from "recoil";
import { dateRangeForPositionHistorySelectorFamily } from "./recoilState";
const DateRangeSC = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});
const PositionHistoryDateRangePicker = () => {
  const now = new Date().toISOString();
  const setStartDate = useSetRecoilState(dateRangeForPositionHistorySelectorFamily("startDate"));
  const setEndDate = useSetRecoilState(dateRangeForPositionHistorySelectorFamily("endDate"));
  const handleDate = (event: any) => {
    if (event.detail.value) {
      const newStartDate = new Date(event.detail.value.start).toISOString();
      const newEndDate = new Date(event.detail.value.end).toISOString();

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };
  return (
    <DateRangeSC>
      <Typography sx={{ alignSelf: "center" }} variant="copy">
        Date Range
      </Typography>
      <PLDatepicker width="450px" dateRange maximumDate={now} lhChange={handleDate} />
    </DateRangeSC>
  );
};

export default PositionHistoryDateRangePicker;
