import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from "@mui/material";
import Props from "./index.d";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: (props: {
        flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
      }) => props.flexDirection ?? "row",
      justifyContent: (props: {
        flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
        justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | undefined
      }) => props.justifyContent ?? "space-between",
      "& > div": {
        margin: (props: {
        flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
        justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | undefined
        marginOfChildren?: number | string;
        }) => props.marginOfChildren ?? 0,
      },
    },
  })
);
interface GFCFieldSet_FixedFooter extends Props {
  children: React.ReactNode;
}

const FieldSetFixedFooter: React.FC<GFCFieldSet_FixedFooter> = (props) => {
  const classes = useStyles(props);
  //  @ts-ignore
  if (props.hidden) return null;
  const childrenArray = React.Children.map(props.children, (child) => child);
  return (
    <div className={classes.root}>
      <div>{childrenArray?.[0]}</div>
      <div>{childrenArray?.[1]}</div>
    </div>
  );
};
export default FieldSetFixedFooter;
