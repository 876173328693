import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTooltipProps = DropOnONLh<JSX.PatternlibTooltip>;
export type PLTooltipExtendedProps = PLTooltipProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTooltip = wrapWc<JSX.PatternlibTooltip>("patternlib-tooltip");
export const useRefPLTooltip = () => useRef<HTMLPatternlibTooltipElement>(null);

const PLTooltip = React.forwardRef<HTMLPatternlibTooltipElement, PLTooltipExtendedProps>((props, ref) => {
  const myRef = useRefPLTooltip();
  return (
    <WrappedTooltip ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTooltip>
  );
});
PLTooltip.displayName = "PLTooltip";

export default PLTooltip;
