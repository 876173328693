import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetTypeForAssetGroupResultsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetAssetTypeForAssetGroupResultsQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string, assetMasterDataId?: Maybe<string>, assetSource?: Maybe<{ __typename?: 'AssetSource', id: string, assetSourceName: string }> } }> };


export const GetAssetTypeForAssetGroupResultsDocument = gql`
    query getAssetTypeForAssetGroupResults($where: AssetDeviceFilterInput) {
  assetDevices(where: $where) {
    id
    asset {
      id
      assetMasterDataId
      assetSource {
        id
        assetSourceName
      }
    }
  }
}
    `;

/**
 * __useGetAssetTypeForAssetGroupResultsQuery__
 *
 * To run a query within a React component, call `useGetAssetTypeForAssetGroupResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetTypeForAssetGroupResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetTypeForAssetGroupResultsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAssetTypeForAssetGroupResultsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetTypeForAssetGroupResultsQuery, GetAssetTypeForAssetGroupResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetTypeForAssetGroupResultsQuery, GetAssetTypeForAssetGroupResultsQueryVariables>(GetAssetTypeForAssetGroupResultsDocument, options);
      }
export function useGetAssetTypeForAssetGroupResultsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetTypeForAssetGroupResultsQuery, GetAssetTypeForAssetGroupResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetTypeForAssetGroupResultsQuery, GetAssetTypeForAssetGroupResultsQueryVariables>(GetAssetTypeForAssetGroupResultsDocument, options);
        }
export type GetAssetTypeForAssetGroupResultsQueryHookResult = ReturnType<typeof useGetAssetTypeForAssetGroupResultsQuery>;
export type GetAssetTypeForAssetGroupResultsLazyQueryHookResult = ReturnType<typeof useGetAssetTypeForAssetGroupResultsLazyQuery>;
export type GetAssetTypeForAssetGroupResultsQueryResult = Apollo.QueryResult<GetAssetTypeForAssetGroupResultsQuery, GetAssetTypeForAssetGroupResultsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
