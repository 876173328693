import alertManagement from "./alertManagement/en";
import assetManagement from "./assetManagement/en";
import configurator from "./configurator/en";
import development from "./development/en";
import deviceManagement from "./deviceManagement/en";
import feedback from "./feedback/en";
import groups from "./groups/en";
import liTrack from "./liTrack/en";
import fleetInventory from "./fleetInventory/en";
import settings from "./settings/en";
import noAccessMessage from "./noAccessMessage/en";
import home from "./home/en";
import userManagement from "./userManagement/en";

export default {
  development,
  configurator,
  alertManagement,
  liTrack,
  feedback,
  groups,
  home,
  fleetInventory,
  settings,
  assetManagement,
  deviceManagement,
  noAccessMessage,
  userManagement,
};
