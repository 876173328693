import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetStorageAreaTypesQueryVariables = Types.Exact<{
  storageAreaTypes?: Types.InputMaybe<Types.StorageAreaTypeFilterInput>;
  storageAreas?: Types.InputMaybe<Types.StorageAreaFilterInput>;
}>;


export type GetStorageAreaTypesQuery = { storageAreaTypes: Array<{ __typename: 'StorageAreaType', id: string, storageAreaTypeName: string, storageAreaTypeColor?: Maybe<string>, storageArea: Array<{ __typename: 'StorageArea', id: string, storageAreaName: string, parentId: string, geofence?: Maybe<{ __typename: 'GeoJSONPolygonType', coordinates?: Maybe<Array<Maybe<Array<Maybe<[number,number]>>>>>, type: Types.GeoJsonGeometryType, bbox: Array<number> }> }> }> };


export const GetStorageAreaTypesDocument = gql`
    query getStorageAreaTypes($storageAreaTypes: StorageAreaTypeFilterInput, $storageAreas: StorageAreaFilterInput) {
  storageAreaTypes(where: $storageAreaTypes) {
    id
    storageAreaTypeName
    storageAreaTypeColor
    storageArea(where: $storageAreas) {
      id
      storageAreaName
      parentId: storageAreaTypeId
      geofence {
        coordinates
        type
        bbox
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useGetStorageAreaTypesQuery__
 *
 * To run a query within a React component, call `useGetStorageAreaTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageAreaTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageAreaTypesQuery({
 *   variables: {
 *      storageAreaTypes: // value for 'storageAreaTypes'
 *      storageAreas: // value for 'storageAreas'
 *   },
 * });
 */
export function useGetStorageAreaTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStorageAreaTypesQuery, GetStorageAreaTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStorageAreaTypesQuery, GetStorageAreaTypesQueryVariables>(GetStorageAreaTypesDocument, options);
      }
export function useGetStorageAreaTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStorageAreaTypesQuery, GetStorageAreaTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStorageAreaTypesQuery, GetStorageAreaTypesQueryVariables>(GetStorageAreaTypesDocument, options);
        }
export type GetStorageAreaTypesQueryHookResult = ReturnType<typeof useGetStorageAreaTypesQuery>;
export type GetStorageAreaTypesLazyQueryHookResult = ReturnType<typeof useGetStorageAreaTypesLazyQuery>;
export type GetStorageAreaTypesQueryResult = Apollo.QueryResult<GetStorageAreaTypesQuery, GetStorageAreaTypesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
