import { LhChangeItem } from "@liebherr/patternlib/dist/types/utils/interfaces";
import Can from "authorization";
import { authorizedTabsArray } from "authorization/AuthorizedTabs/authorizedTabsArray";
import AuthorizedTabsCan from "authorization/AuthorizedTabs/AuthorizedTabsCan";
import { toastStateAtom } from "components/atomics/Toast/toastState";
import ContentHeader from "components/organisms/ContentHeader";
import PLBadge from "components/patternLib/PLBadge";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import PLTabs from "components/patternLib/PLTabs";
import PLTabsItem from "components/patternLib/PLTabsItem";
import { TableWrapperSC } from "components/templates/dataGridTable/styles";
import { useGetRecentNotificationCountForBadgeQuery } from "graphqlBase/DeviceNotification/__generated__/getRecentNotificationCountForBadge";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import AlertOverview from "./AlertOverview";
import { currentAlertTab } from "./alertTabsState";
import RecentNotifications from "./RecentNotifications";

const { alerts, recentAlerts, createAlert } = translations.pages.alertManagement;

const AlertManagement: React.FC<{}> = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useRecoilState(currentAlertTab);
  const setToastObject = useSetRecoilState(toastStateAtom);
  const headerTabLabels = [
    { label: alerts, scope: "MyAlertsUI" },
    { label: recentAlerts, scope: "RecentNotificationsUI" },
  ];
  const { data: dataForBadgeValue } = useGetRecentNotificationCountForBadgeQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: Number(process.env.REACT_APP_POLL_INTERVAL_IN_MILLISECONDS_FOR_REFETCHING),
  });

  const handleHeaderTabChange = (event: CustomEvent<LhChangeItem>) => {
    setCurrentTab(event.detail.itemId as number);
  };

  const handleCreateAlert = () => {
    setToastObject(undefined);
    history.push("/alertManagement/createAlert");
  };

  const HeaderTabs = (
    <PLTabs lhChangeItem={handleHeaderTabChange} key="tabs">
      {authorizedTabsArray(headerTabLabels).map(({ label }, index) => {
        return label === recentAlerts ? (
          <React.Fragment key={`${label}-${index}`}>
            <PLTabsItem active={currentTab === index} label={label} />
            <PLBadge
              iconSize="0px"
              topPosition="2px"
              value={(dataForBadgeValue?.deviceNotifications?.length ?? 0).toString()}
              noStates
            />
          </React.Fragment>
        ) : (
          <PLTabsItem key={`${label}-${index}`} active={currentTab === index} label={label} />
        );
      })}
    </PLTabs>
  );

  const createAlertButton = (
    <Can I="read" a="CreateAlertUI">
      <PLButton onClick={handleCreateAlert} key="create-alert-button" iconPosition="left" label={createAlert}>
        <PLIcon iconName="plus" size="24px" slot="icon" />
      </PLButton>
    </Can>
  );

  return (
    <>
      <ContentHeader tabs={[HeaderTabs]} rightItems={currentTab === 0 ? createAlertButton : undefined} />
      <TableWrapperSC>
        {currentTab === 0 && (
          <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
            <AlertOverview key="alert-overview-tab-content" />
          </AuthorizedTabsCan>
        )}
        {currentTab === 1 && (
          <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
            <RecentNotifications key="recent-alerts-tab-content" />
          </AuthorizedTabsCan>
        )}
      </TableWrapperSC>
    </>
  );
};

export default AlertManagement;
