import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceLocationQueryVariables = Types.Exact<{
  deviceIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetDeviceLocationQuery = { deviceLocation?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementMovementHistory', deviceId: string, utcTimeMeasured: string, isLast?: Maybe<boolean>, distance?: Maybe<number>, location?: Maybe<{ __typename?: 'GeoJSONPointType', coordinates?: Maybe<[number,number]> }>, azureMapsLocations?: Maybe<Array<Maybe<{ __typename?: 'AzureMapReverseBatchResponseItemsContentAddresses', address?: Maybe<{ __typename?: 'AzureMapsReverseBatchResponseItemsContentAddress', streetName?: Maybe<string>, streetNumber?: Maybe<string> }> }>>> }>>>, assetDevices: Array<{ __typename?: 'AssetDevice', id: string, assetId: string, deviceId: string, asset: { __typename: 'Asset', id: string, assetMasterData?: Maybe<{ __typename: 'AssetMasterData', id: string, generalItem?: Maybe<string> }>, assetSource?: Maybe<{ __typename: 'AssetSource', id: string, assetSourceName: string }> }, device: { __typename?: 'Device', id: string, sensorMeasurementCurrentValue?: Maybe<Array<Maybe<{ __typename?: 'SensorMeasurementCurrentValue', id: string, utcTimeMeasured: string, deviceId: string, capabilityId: string, valueString?: Maybe<string>, valueInt?: Maybe<number> }>>> } }> };


export const GetDeviceLocationDocument = gql`
    query getDeviceLocation($deviceIds: [UUID!], $where: AssetDeviceFilterInput) {
  deviceLocation(deviceIds: $deviceIds) {
    deviceId
    utcTimeMeasured
    isLast
    distance
    location {
      coordinates
    }
    azureMapsLocations {
      address {
        streetName
        streetNumber
      }
    }
  }
  assetDevices(where: $where) {
    id
    assetId
    deviceId
    asset {
      id
      assetMasterData {
        id
        generalItem
        __typename
      }
      assetSource {
        id
        assetSourceName
        __typename
      }
      __typename
    }
    device {
      id
      sensorMeasurementCurrentValue(where: {capabilityId: {eq: "5063bcd9-9e7b-42f6-84b4-eeac931a2b1f"}}) {
        id
        utcTimeMeasured
        deviceId
        capabilityId
        valueString
        valueInt
      }
    }
  }
}
    `;

/**
 * __useGetDeviceLocationQuery__
 *
 * To run a query within a React component, call `useGetDeviceLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceLocationQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDeviceLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceLocationQuery, GetDeviceLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceLocationQuery, GetDeviceLocationQueryVariables>(GetDeviceLocationDocument, options);
      }
export function useGetDeviceLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceLocationQuery, GetDeviceLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceLocationQuery, GetDeviceLocationQueryVariables>(GetDeviceLocationDocument, options);
        }
export type GetDeviceLocationQueryHookResult = ReturnType<typeof useGetDeviceLocationQuery>;
export type GetDeviceLocationLazyQueryHookResult = ReturnType<typeof useGetDeviceLocationLazyQuery>;
export type GetDeviceLocationQueryResult = Apollo.QueryResult<GetDeviceLocationQuery, GetDeviceLocationQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
