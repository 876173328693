import React, { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, Tooltip, ZoomControl } from "react-leaflet";
import * as L from "leaflet";
import { PositionHistoryMarkerProps } from "./index.d";
import { themeColors } from "themes/colors";
import { useRecoilValue } from "recoil";
import { isPositionHoveredOnTable } from "./recoilState";
const bounds: L.LatLngBoundsExpression | undefined = [];
const PositionHistoryMarker: React.FC<PositionHistoryMarkerProps> = ({
  movement,
  index,
  movementHistory,
  movementHistoryLocations,
  lineDecoratorState,
  setLineDecoratorState,
}) => {
  const isMarkerHighlighted = useRecoilValue(isPositionHoveredOnTable(`${index}`));
  if (!movement?.location?.coordinates) return null;
  bounds.push(movement?.location?.coordinates);
  const icon = L.divIcon({
    iconSize: [0, 0],
    iconAnchor: [15, 15],
    html: movement.isLast
      ? `<svg width="30" height="30" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.3" cx="10" cy="10.009" r="10" fill="#2E8DE5"/>
        <circle cx="10" cy="10.009" r="6" fill="white"/>
        <circle cx="10" cy="10.009" r="5" fill="#2E8DE5"/>
        </svg>`
      : `<svg width="30" height="30" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
         <circle opacity="0.3" cx="10" cy="10.009" r="10" fill="#6B7278"/>
         <circle cx="10" cy="10.009" r="6" fill="white"/>
         <circle cx="10" cy="10.009" r="5" fill="#6B7278"/>
      </svg>
  
  `,
  });
  const highlightedIcon = L.divIcon({
    iconSize: [0, 0],
    iconAnchor: [60, 60],
    html: `<svg width="120" height="120" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#2E8DE5" fill-opacity="0.12"></circle>
    <circle opacity="0.3" cx="40" cy="40" r="10" fill="#2E8DE5"></circle>
    <circle cx="40" cy="40" r="6" fill="white"></circle>
    <circle cx="40" cy="40" r="5" fill="#2E8DE5"></circle>
    </svg>
  
  `,
  });
  return (
    <Marker
      position={{ lat: movement?.location?.coordinates?.[0], lng: movement?.location?.coordinates?.[1] }}
      icon={isMarkerHighlighted ? highlightedIcon : icon}
      eventHandlers={{
        click: (e: L.LeafletMouseEvent) => {
          const map: L.Map = e.sourceTarget._map;
          map.eachLayer((layer) => {
            const item = Object(layer).editing;
            const polyline = Object(item)._poly;
            if (polyline) layer.removeFrom(map);
          });
          if (lineDecoratorState) map.removeLayer(lineDecoratorState.decoratorState);
          const previousPosition: L.LatLngTuple = movementHistory[index - 1]?.location?.coordinates as L.LatLngTuple;

          const nextPosition: L.LatLngTuple = movementHistory[index + Number(1)]?.location
            ?.coordinates as L.LatLngTuple;
          const currentPosition: L.LatLngTuple = movement?.location?.coordinates as L.LatLngTuple;
          if (nextPosition && currentPosition && previousPosition) {
            const polyLine = L.polyline([previousPosition, currentPosition, nextPosition], {
              color: themeColors.darkGrey,
            }).addTo(map);
            const decorator = L.polylineDecorator(polyLine, {
              patterns: [
                {
                  offset: 30,
                  repeat: 100,
                  symbol: L.Symbol.arrowHead({
                    pixelSize: 15,
                    polygon: false,
                    pathOptions: { stroke: true, color: "#6B7278" },
                  }),
                },
              ],
            }).addTo(map);
            setLineDecoratorState({ decoratorState: decorator, lineState: polyLine });
            map.fitBounds([previousPosition, currentPosition, nextPosition]);
          }
        },

        mouseover: (e: L.LeafletMouseEvent) => e.target.openPopup(),
        mouseout: (e: L.LeafletMouseEvent) => e.target.closePopup(),
        add: (e: L.LeafletEvent) => {
          const map: L.Map = e.sourceTarget._map;
          map.fitBounds(bounds);
        },
      }}
    >
      <Popup>
        {`${movementHistoryLocations && (movementHistoryLocations[index]?.label ?? "")}
        ${movementHistoryLocations && (movementHistoryLocations[index]?.value ?? "")}`}
      </Popup>
    </Marker>
  );
};

export default PositionHistoryMarker;
