import { sub } from "date-fns";
import { atom, selector, selectorFamily } from "recoil";

export interface SiteObjectType {
  lat: number;
  lon: number;
  rad: number;
}
export type SiteObjectTypeKey = keyof SiteObjectType;

export interface DeviceConfigurationObjectType {
  accel_full_scale: string;
  accel_level_movt: number;
  accel_level_shock: number;
  battery_level_period: number;
  ble_scan_period: number;
  command_request_period: number;
  data_xfer_period: number;
  environmental_sense_period: number;
  gnss_movt_detect_offset: number;
  gnss_pos_period: number;
  site_position?: SiteObjectType;
  state: string;
  state_timeout: number;
}

export type DeviceConfigurationObjectTypeKey = keyof Omit<
  DeviceConfigurationObjectType,
  "state" | "accel_full_scale" | "site_position"
>;

export const isValidJsonAtom = atom<boolean | PromiseLike<any>>({
  key: "isValidJsonAtom",
  default: true,
});

export const deviceConfiguration = atom<string | null | undefined>({
  key: "deviceConfiguration",
  default: null,
});

export const initialDeviceConfigurationAtom = atom<DeviceConfigurationObjectType[] | null | undefined>({
  key: "initialDeviceConfigurationAtom",
  default: null,
});

export const defaultConfigurationJsonAtom = atom<string | undefined>({
  key: "defaultConfigurationJsonAtom",
  default: undefined,
});
export const deviceConfigurationAtom = atom<DeviceConfigurationObjectType[] | null | undefined>({
  key: "deviceConfigurationAtom",
  default: null,
});

export const mappingDeviceConfigurationDeviceId = atom<string | null | undefined>({
  key: "mappingDeviceConfigurationDeviceId",
  default: null,
});

export const deviceConfigurationSchema = atom<string | null | undefined>({
  key: "deviceConfigurationSchema",
  default: null,
});

export const movementStateArrayAtom = atom<string[]>({
  key: "movementStateArrayAtom",
  default: ["REST", "MOVT"],
});

export const configurationsStateFieldAtom = atom<string>({
  key: "siteStateAtom",
  default: "STORAGE_SITE_IN_REST",
});

export const configurationsStateFieldComposerFromStateButtons = selectorFamily<string, number>({
  key: "configurationsStateFieldComposerFromStateButtons",

  get: (stringPart) => ({ get }) => {
    const stringParts = get(configurationsStateFieldAtom).split("_IN_");
    return stringParts[stringPart] ?? "";
  },
  set: (stringPart) => ({ set }, newValue) => {
    return set(configurationsStateFieldAtom, (current) => {
      const stringParts = current.split("_IN_").slice(0, 99);
      stringParts[stringPart] = newValue as string;
      return stringParts.join("_IN_");
    });
  },
});

export const deviceConfigurationModifier = selectorFamily<number, DeviceConfigurationObjectTypeKey>({
  key: "deviceConfigurationModifier",
  get: (field) => ({ get }) => {
    const configArray = get(deviceConfigurationAtom);
    const configState = get(configurationsStateFieldAtom);
    const config = configArray?.find((elem) => elem.state === configState);
    if (!config) return 0;
    return config[field] ?? 0;
  },
  set: (field) => ({ get, set }, newValue) => {
    const configArray = get(deviceConfigurationAtom)?.slice(0, 99) ?? [];
    const configState = get(configurationsStateFieldAtom);
    const configArrayIndex = configArray?.findIndex((elem) => elem.state === configState) ?? 0;
    configArray[configArrayIndex] = { ...configArray[configArrayIndex], [field]: newValue };
    set(deviceConfigurationAtom, configArray);
  },
});

export const siteAreaDefinitionModifier = selector<SiteObjectType>({
  key: "siteAreaDefinitionModifier",
  get: ({ get }) => {
    const configArray = get(deviceConfigurationAtom);
    const configState = get(configurationsStateFieldAtom);
    const config = configArray?.find((elem) => elem.state === configState);
    if (!config) return { lat: 0, lon: 0, rad: 0 };
    return config.site_position ?? { lat: 0, lon: 0, rad: 0 };
  },
  set: ({ get, set }, newValue) => {
    const configArray = get(deviceConfigurationAtom)?.slice(0, 99) ?? [];
    const configState = get(configurationsStateFieldAtom);
    const indexes = [];

    for (let i = 0; i < configArray.length; i++) {
      const elem = configArray[i];
      if (elem.state.includes(configState.substring(0, configState.indexOf("_")))) {
        indexes.push(i);
      }
    }

    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i];
      configArray[index] = { ...configArray[index], site_position: newValue as SiteObjectType };
    }
    set(deviceConfigurationAtom, configArray);
  },
});

export interface Variables {
  capabilityIds: string[];
  deviceIds: string[] | null;
  dimensions: string[];
  endDate: string;
  gatewayDeviceConnectionIds: string[] | null;
  metrics: string[];
  startDate: string;
  timeBucketInMinutes: number;
}
export type VariablesKey = keyof Omit<Variables, "metrics">;
export const sensorMeasurementHistoryAggregateVariablesAtom = atom<Variables>({
  key: "sensorMeasurementHistoryAggregateVariablesAtom",
  default: {
    dimensions: ["DEVICE_ID"],
    metrics: ["COUNT"],
    gatewayDeviceConnectionIds: null,
    deviceIds: null,
    capabilityIds: [
      "5063bcd9-9e7b-42f6-84b4-eeac931a2b1f",
      "4d891dd6-5e4a-4ccc-bc40-83cda080fff0",
      "e4b14165-5282-4e38-99e4-c90f5b3adc9f",
      "80bec84b-2c50-4e12-9a70-56b06e5b92e5",
      "2d68c5ca-5caf-4666-8901-980bf33534ff",
    ],
    startDate: sub(new Date(), { months: 1 }).toISOString(),
    endDate: new Date().toISOString(),
    timeBucketInMinutes: 720,
  },
});

export const sensorMeasurementHistoryAggregateSelectorFamily = selectorFamily<
  string | string[] | number | null,
  VariablesKey
>({
  key: "sensorMeasurementHistoryAggregateSelectorFamily",
  get: (field) => ({ get }) => {
    const variables = get(sensorMeasurementHistoryAggregateVariablesAtom);
    return variables[field];
  },
  set: (field) => ({ get, set }, newValue) => {
    const variables = get(sensorMeasurementHistoryAggregateVariablesAtom);
    set(sensorMeasurementHistoryAggregateVariablesAtom, { ...variables, [field]: newValue });
  },
});

export const durationOptionAtom = atom<string>({
  key: "durationOptionAtom",
  default: "1 months",
});
