// eslint-disable-next-line filenames/match-exported
import React from "react";
import { useStyles } from "./utils";
import { Box, Typography, IconButton, Paper, Modal, DialogContentText } from "@mui/material";
import CloseIcon from "icons/components/CloseIcon";
import { Buttons } from "./Buttons";

interface SaveModalProps {
  cancelLabel: string;
  confirmLabel: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  isOpen: boolean;
  message: JSX.Element;
  titles: string;
}

const FormModal: React.FC<SaveModalProps> = ({
  handleConfirm,
  message,
  titles,
  isOpen,
  handleCancel,
  confirmLabel,
  cancelLabel,
}) => {
  const { rootPaper, modalHeader, modalHeaderLabel, modalContent, modalFooter } = useStyles({});

  return (
    <Modal
      onClose={() => {
        handleCancel();
      }}
      open={isOpen}
    >
      <Paper className={rootPaper}>
        <div className={modalHeader}>
          <div className={modalHeaderLabel}>
            <Box slot="modal-content">
              <Typography variant="h3">{titles}</Typography>
            </Box>
          </div>
          <IconButton
            color="inherit"
            onClick={() => {
              handleCancel();
            }}
          >
            <CloseIcon style={{ width: "24px", height: "26px" }} />
          </IconButton>
        </div>
        <div className={modalContent}>
          <Box slot="modal-content">
            <Typography style={{ lineHeight: "24px" }} variant="body1">
              {message}
            </Typography>
          </Box>
        </div>
        <div className={modalFooter}>
          <Buttons
            doMutations={() => {
              handleConfirm();
            }}
            cancelAction={() => {
              handleCancel();
            }}
            confirmLabel={confirmLabel}
            cancelLabel={cancelLabel}
          />
        </div>
      </Paper>
    </Modal>
  );
};

export default FormModal;
