import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetCouplingsForExcavatorQueryVariables = Types.Exact<{
  deviceId: Types.Scalars['UUID'];
}>;


export type GetCouplingsForExcavatorQuery = { couplingStatusExcavator?: Maybe<{ __typename?: 'CouplingStatus', couplingState: number, couplingStateDescriptive?: Maybe<string>, couplingStateDatetime: string, couplingStateOperatingMinutes: number, relatedCoupledDevices?: Maybe<Array<Maybe<{ __typename?: 'CoupledDevice', device: { __typename?: 'Device', id: string, assetDevice?: Maybe<Array<Maybe<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string, assetAttributeValue: Array<{ __typename?: 'AssetAttributeValue', id: string, value: string }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', generalItem?: Maybe<string> }> } }>>> } }>>> }> };


export const GetCouplingsForExcavatorDocument = gql`
    query getCouplingsForExcavator($deviceId: UUID!) {
  couplingStatusExcavator(deviceId: $deviceId) {
    couplingState
    couplingStateDescriptive
    couplingStateDatetime
    couplingStateOperatingMinutes
    relatedCoupledDevices {
      device {
        id
        assetDevice {
          id
          asset {
            id
            assetAttributeValue(where: {assetAttributeId: {eq: "89F8EF48-CF7E-48C9-A93E-8BA58B85D91E"}}) {
              id
              value
            }
            assetMasterData {
              generalItem
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCouplingsForExcavatorQuery__
 *
 * To run a query within a React component, call `useGetCouplingsForExcavatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouplingsForExcavatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouplingsForExcavatorQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetCouplingsForExcavatorQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCouplingsForExcavatorQuery, GetCouplingsForExcavatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCouplingsForExcavatorQuery, GetCouplingsForExcavatorQueryVariables>(GetCouplingsForExcavatorDocument, options);
      }
export function useGetCouplingsForExcavatorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCouplingsForExcavatorQuery, GetCouplingsForExcavatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCouplingsForExcavatorQuery, GetCouplingsForExcavatorQueryVariables>(GetCouplingsForExcavatorDocument, options);
        }
export type GetCouplingsForExcavatorQueryHookResult = ReturnType<typeof useGetCouplingsForExcavatorQuery>;
export type GetCouplingsForExcavatorLazyQueryHookResult = ReturnType<typeof useGetCouplingsForExcavatorLazyQuery>;
export type GetCouplingsForExcavatorQueryResult = Apollo.QueryResult<GetCouplingsForExcavatorQuery, GetCouplingsForExcavatorQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
