import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesWithLowBatteryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDevicesWithLowBatteryQuery = { sensorMeasurementCurrentValues: Array<{ __typename?: 'SensorMeasurementCurrentValue', id: string, valueFloat?: Maybe<number>, deviceId: string }> };


export const GetDevicesWithLowBatteryDocument = gql`
    query getDevicesWithLowBattery {
  sensorMeasurementCurrentValues(where: {and: [{capabilityId: {eq: "80bec84b-2c50-4e12-9a70-56b06e5b92e5"}}, {valueFloat: {lt: 3}}]}) {
    id
    valueFloat
    deviceId
  }
}
    `;

/**
 * __useGetDevicesWithLowBatteryQuery__
 *
 * To run a query within a React component, call `useGetDevicesWithLowBatteryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesWithLowBatteryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesWithLowBatteryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDevicesWithLowBatteryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesWithLowBatteryQuery, GetDevicesWithLowBatteryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesWithLowBatteryQuery, GetDevicesWithLowBatteryQueryVariables>(GetDevicesWithLowBatteryDocument, options);
      }
export function useGetDevicesWithLowBatteryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesWithLowBatteryQuery, GetDevicesWithLowBatteryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesWithLowBatteryQuery, GetDevicesWithLowBatteryQueryVariables>(GetDevicesWithLowBatteryDocument, options);
        }
export type GetDevicesWithLowBatteryQueryHookResult = ReturnType<typeof useGetDevicesWithLowBatteryQuery>;
export type GetDevicesWithLowBatteryLazyQueryHookResult = ReturnType<typeof useGetDevicesWithLowBatteryLazyQuery>;
export type GetDevicesWithLowBatteryQueryResult = Apollo.QueryResult<GetDevicesWithLowBatteryQuery, GetDevicesWithLowBatteryQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
