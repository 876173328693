import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetTagsQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.AssetTagFilterInput>;
  order?: Types.InputMaybe<Array<Types.AssetTagSortInput> | Types.AssetTagSortInput>;
}>;


export type GetAssetTagsQuery = { assetTags: Array<{ __typename?: 'AssetTag', id: string, tagValue: string }> };


export const GetAssetTagsDocument = gql`
    query getAssetTags($skip: Int, $take: Int, $where: AssetTagFilterInput, $order: [AssetTagSortInput!]) {
  assetTags(skip: $skip, take: $take, where: $where, order: $order) {
    id
    tagValue
  }
}
    `;

/**
 * __useGetAssetTagsQuery__
 *
 * To run a query within a React component, call `useGetAssetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetTagsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetTagsQuery, GetAssetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetTagsQuery, GetAssetTagsQueryVariables>(GetAssetTagsDocument, options);
      }
export function useGetAssetTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetTagsQuery, GetAssetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetTagsQuery, GetAssetTagsQueryVariables>(GetAssetTagsDocument, options);
        }
export type GetAssetTagsQueryHookResult = ReturnType<typeof useGetAssetTagsQuery>;
export type GetAssetTagsLazyQueryHookResult = ReturnType<typeof useGetAssetTagsLazyQuery>;
export type GetAssetTagsQueryResult = Apollo.QueryResult<GetAssetTagsQuery, GetAssetTagsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
