import { Button, styled } from "@mui/material";
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPaginationRowRangeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { StyledGridFooterContainer, StyledPageRowCount, StyledPagination } from "../styles";
import React from "react";

const NoPaginationFooterSC = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "flex-end",
  alignItems: "center",
});

export const NoPaginationFooter = ({ handleShowAll }: { handleShowAll: () => void }) => {
  const apiRef = useGridApiContext();
  const totalRows = gridRowCountSelector(apiRef);

  return (
    <StyledGridFooterContainer>
      <NoPaginationFooterSC>
        {totalRows > 7 && <Button onClick={handleShowAll} disableRipple>{`Show all (${totalRows})`}</Button>}
      </NoPaginationFooterSC>
    </StyledGridFooterContainer>
  );
};

export const FooterWithPagination = () => {
  const apiRef = useGridApiContext();
  const totalRows = gridRowCountSelector(apiRef);
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const currPageRowCount = gridPaginationRowRangeSelector(apiRef);

  return (
    <StyledGridFooterContainer>
      <StyledPagination
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        shape="rounded"
      />
      <StyledPageRowCount>
        {`${(currPageRowCount?.firstRowIndex ?? -1) + 1} - ${
          (currPageRowCount?.lastRowIndex ?? -1) + 1
        } of ${totalRows}`}
      </StyledPageRowCount>
    </StyledGridFooterContainer>
  );
};
