import { QqlFormGenConfig } from "@ml-pa/react-apollo-form-tool/types"

const config: QqlFormGenConfig = {
  clientPath: "src/apollo/client",
  graphpQqlEndPoint: "http://localhost:50006/graphql/",
  clientSchema: "./src/graphqlBase/client/clientSchema.graphql",
  raftGqlSchemas: "./src/**/*raftExt.graphql",
  localConfigurator: true,
  afterChange: "src/lib/formToolHelpers/testAfter.ts",
  localizationPath: "src/translations",
  languages: ["de", "en"],
  useHC11: false,
  out: "src/raft",
  elements: [
    "src/components/formElements/forms",
    "src/components/formElements/fieldSets",
    "src/components/formElements/fields",
  ],
  forms: [
    {
      entity: "storageArea",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "notification",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "group",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "assetMasterData",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
  ],
}
export default config
