import { styled, useMediaQuery } from "@mui/material";

import TagChipWithTooltip from "components/molecules/TagChipWithTooltip";
import { Asset } from "components/pages/AssetManagement/AssetOverview";
import PLPill from "components/patternLib/PLPill";
import PLTooltip from "components/patternLib/PLTooltip";
import React, { useEffect, useState } from "react";

export interface TagChipsProps<T> {
  mappingAssetTag?: Asset["mappingAssetTag"];
  mappingDeviceTag?: T[];
}

const ChipsContainerSC = styled("div")(
  () => `
      display: inline-flex;
      flex-wrap: wrap;
`
);

const ExtraChipsCountSC = styled("div")(
  () => `
      width: fit-content;
      white-space: pre-line;
      z-index: 999999999;
`
);

export const TagChips = <
  T extends {
    id: string;
    tag: { tagValue: string };
  }
>({
  mappingAssetTag,
  mappingDeviceTag,
}: TagChipsProps<T>) => {
  const isSmall = useMediaQuery("(max-width:600px)");
  const isMedium = useMediaQuery("(max-width:1650px)");

  const [slice, setSlice] = useState<number>(2);

  useEffect(() => {
    if (isSmall) {
      setSlice(1);
    } else if (isMedium) {
      setSlice(2);
    } else {
      setSlice(3);
    }
  }, [isMedium, isSmall]);

  const ExtraTags = (length: number) => (
    <ExtraChipsCountSC>
      <PLTooltip tooltipPosition="left">
        <PLPill height="22px" label={`+${length}`} noStates />
      </PLTooltip>
    </ExtraChipsCountSC>
  );

  const tags = mappingAssetTag ?? mappingDeviceTag;

  return (
    <ChipsContainerSC>
      {mappingAssetTag &&
        mappingAssetTag
          .slice(0, slice)
          .map((mapping) => <TagChipWithTooltip key={mapping.id} tagValue={mapping.assetTag.tagValue} />)}
      {mappingDeviceTag &&
        mappingDeviceTag
          .slice(0, slice)
          .map((mapping) => <TagChipWithTooltip tagValue={mapping.tag.tagValue} key={mapping.id} />)}
      {tags && tags.length > slice && ExtraTags(tags.length - slice)}
    </ChipsContainerSC>
  );
};
export default TagChips;
