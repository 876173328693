import { styled } from "@mui/material";
import theme from "themes/theme";

export const mainContainerStyles = {
  height: "100%",
};

export const containerStyles = ({
  open,
  sideBarWidth,
}: {
  open: boolean;
  sideBarWidth: { open: string; closed: string };
}) => {
  return {
    maxHeight: "calc(100% - 90px)",
    width: `${open ? `calc(100%-${sideBarWidth.open})` : `calc(100%-${sideBarWidth.closed})`}`,
    padding: "1%",
    overflowY: "auto",
    marginLeft: `${open ? `${sideBarWidth.open}` : `${sideBarWidth.closed}`}`,
    [theme.breakpoints.between(1200, 1439)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.between(1440, 1639)]: {
      paddingLeft: open ? "120px" : "20px",
      paddingRight: open ? "120px" : "20px",
    },
    [theme.breakpoints.up(1640)]: {
      paddingLeft: "120px",
      paddingRight: "120px",
    },
  };
};

export const RootWrapperSC = styled("div")(
  ({ theme }) => `
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color:${theme.palette.background.default};
`
);

export const HeaderContainerSC = styled("div")(
  () => `
    position: relative;
    width: 100%;
    height: min-content;
`
);

export const titleStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  paddingLeft: "20px",
};

export const BreadcrumbBoxSC = styled("div")(
  () => `
        display: flex;
        padding: 0.5% 0.1%;
`
);

export const NavigationContainerSC = styled("div")(
  () => `
        height: calc(100% - 90px);
        position: absolute;
`
);
