import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      marginTop: "45%",
      marginLeft: "45%",
    },
  })
);

export default () => {
  const classes = useStyles();
  return (
    <div>
      <CircularProgress className={classes.progress} size={"5%"} />
    </div>
  );
};
