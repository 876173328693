import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { useMemo, useState } from "react";
import { Scope, operations, audiences, Area, Audience } from ".";
import {
  mappingRoleScopesSelectorGroup,
  mappingRoleScopesSelector,
  MappingRoleScopesSelectorGroupState,
} from "../../RecoilState/MappingRoleScopesTableState";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider } from "@mui/material/styles";
import { RecoilState, useRecoilState } from "recoil";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
export interface AudienceRowsProps {
  area: Area;
  areaName: string;
  disabled: boolean;
  roleId: string;
}
interface ScopeCheckBoxProps {
  disabled: boolean;
  id: string;
}
interface ScopeGroupCheckBoxProps {
  disabled: boolean;
  state: RecoilState<MappingRoleScopesSelectorGroupState>;
}
const ScopeCheckBox: React.FC<ScopeCheckBoxProps> = ({ id, disabled }) => {
  const state = mappingRoleScopesSelector(id);
  const [checked, setChecked] = useRecoilState(state);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return <Checkbox checked={checked} onChange={handleChange} disabled={disabled} />;
};
const ScopeGroupCheckBox: React.FC<ScopeGroupCheckBoxProps> = ({ state, disabled }) => {
  const [{ checked, indeterminate }, setChecked] = useRecoilState(state);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ checked: event.target.checked, indeterminate: false });
  };
  return <Checkbox checked={checked} onChange={handleChange} disabled={disabled} indeterminate={indeterminate} />;
};

interface GroupedState {
  CreateGroup: string[];
  DeleteGroup: string[];
  ReadGroup: string[];
  UpdateGroup: string[];
}

export const AreaRows: React.FC<AudienceRowsProps> = ({ area, areaName, roleId, disabled }) => {
  const [state, setState] = useState(false);
  const groupedStates = useMemo(
    () =>
      Object.values(area).reduce<GroupedState>(
        (groupedStates, { Create, Update, Read, Delete }) => {
          const { CreateGroup, DeleteGroup, ReadGroup, UpdateGroup } = groupedStates;
          return {
            CreateGroup: Create ? CreateGroup.concat(Create.id) : CreateGroup,
            DeleteGroup: Delete ? DeleteGroup.concat(Delete.id) : DeleteGroup,
            ReadGroup: Read ? ReadGroup.concat(Read.id) : ReadGroup,
            UpdateGroup: Update ? UpdateGroup.concat(Update.id) : UpdateGroup,
          };
        },
        {
          CreateGroup: [],
          DeleteGroup: [],
          ReadGroup: [],
          UpdateGroup: [],
        }
      ),
    [area]
  );
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row" onClick={() => setState((prev) => !prev)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {areaName}
            <IconButton aria-label="delete">
              {!state ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>

        {operations.map((operation) => {
          const groupIdentifier = `${operation}Group` as keyof GroupedState;
          const p = groupedStates[groupIdentifier];
          return (
            <TableCell key={`${areaName} ${operation}`}>
              <ScopeGroupCheckBox
                state={mappingRoleScopesSelectorGroup(groupedStates[groupIdentifier])}
                disabled={disabled}
              />
            </TableCell>
          );
        })}
      </TableRow>
      {state &&
        Object.entries(area).map(([audienceName, audience]) => (
          <TableRow key={audienceName} sx={{ "&:last-child td, &:last-child th": { border: 0 }, height: "0px" }}>
            <TableCell component="th" scope="row">
              {audienceName}
            </TableCell>
            {operations.map((operation) => {
              if (!audience[operation]) return <TableCell key={operation} />;
              return (
                <TableCell key={operation}>
                  <ScopeCheckBox id={audience[operation]?.id ?? ""} disabled={disabled} />
                </TableCell>
              );
            })}
          </TableRow>
        ))}
    </>
  );
};
