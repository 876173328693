import asset from "./asset/en";
import assetAttribute from "./assetAttribute/en";
import assetAttributeValue from "./assetAttributeValue/en";
import assetDevice from "./assetDevice/en";
import assetImage from "./assetImage/en";
import assetMasterData from "./assetMasterData/en";
import assetType from "./assetType/en";
import assetTypeAttributeSet from "./assetTypeAttributeSet/en";
import capability from "./capability/en";
import device from "./device/en";
import deviceAttribute from "./deviceAttribute/en";
import deviceAttributeValue from "./deviceAttributeValue/en";
import deviceCapability from "./deviceCapability/en";
import deviceModel from "./deviceModel/en";
import deviceModelAttributeSet from "./deviceModelAttributeSet/en";
import deviceModelCapability from "./deviceModelCapability/en";
import deviceModelVersion from "./deviceModelVersion/en";
import deviceNotification from "./deviceNotification/en";
import deviceType from "./deviceType/en";
import gatewayDeviceConnection from "./gatewayDeviceConnection/en";
import group from "./group/en";
import manufacturer from "./manufacturer/en";
import mappingAttributeAssetTypeAttributeSet from "./mappingAttributeAssetTypeAttributeSet/en";
import mappingAttributeDeviceModelAttributeSet from "./mappingAttributeDeviceModelAttributeSet/en";
import mappingDeviceNotification from "./mappingDeviceNotification/en";
import notification from "./notification/en";
import notificationDefinition from "./notificationDefinition/en";
import notificationOperator from "./notificationOperator/de";
import notificationScope from "./notificationScope/en";
import queryStatisticsOutputType from "./queryStatisticsOutputType/en";
import rule from "./rule/en";
import ruleAction from "./ruleAction/en";
import ruleReference from "./ruleReference/en";
import ruleReferenceUnit from "./ruleReferenceUnit/en";
import ruleResultType from "./ruleResultType/en";
import tag from "./tag/de";
import tenant from "./tenant/en";
import role from "./role/en";
import unit from "./unit/en";
import user from "./user/en";
import dateRangeSelector from "./dateRangeSelector/en";
import storageArea from "./storageArea/en";

export default {
  asset,
  assetAttribute,
  assetAttributeValue,
  assetDevice,
  assetImage,
  assetMasterData,
  assetType,
  assetTypeAttributeSet,
  device,
  capability,
  dateRangeSelector,
  deviceAttribute,
  deviceAttributeValue,
  deviceCapability,
  deviceModel,
  deviceType,
  deviceModelAttributeSet,
  deviceModelCapability,
  deviceModelVersion,
  deviceNotification,
  gatewayDeviceConnection,
  group,
  manufacturer,
  mappingAttributeAssetTypeAttributeSet,
  mappingAttributeDeviceModelAttributeSet,
  mappingDeviceNotification,
  notification,
  notificationDefinition,
  notificationOperator,
  notificationScope,
  queryStatisticsOutputType,
  rule,
  ruleAction,
  ruleReference,
  ruleReferenceUnit,
  ruleResultType,
  storageArea,
  tag,
  role,
  unit,
  user,
  tenant,
};
