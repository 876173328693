import { atom } from "recoil";

export const groupNameAtom = atom<string | undefined>({
  key: "groupNameAtom",
  default: undefined,
});

export const groupDescriptionAtom = atom<string | undefined>({
  key: "groupDescriptionAtom",
  default: undefined,
});

export const entityIdAtom = atom<string | undefined>({
  key: "entityIdAtom",
  default: undefined,
});

export const getGroupIdAfterSaveGroupAtom = atom<string | undefined>({
  key: "getGroupIdAfterSaveGroupAtom",
  default: undefined,
});
