import React from "react";

import { useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "graphqlBase/Settings/__generated__/getUserById";
import RolesTable from "./RolesTable";

const MappingRolesUserUpdate: React.FC<{}> = () => {
  const { userId }: { userId: string } = useParams();

  const { data } = useGetUserByIdQuery({
    variables: { id: userId },
  });

  return <>{data && <RolesTable userData={data} />}</>;
};
export default MappingRolesUserUpdate;
