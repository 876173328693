export default {
  name: "Tenant",
  plural: "Tenants",
  columns: {
    id: "ID",
    tenantIdentifier: "Tenant ID",
    tenantName: "Tenant Name",
    isDeleted: "is deleted",
    createdAt: "Created at",
    modifiedAt: "Modified at",
  },
};
