const textFieldStyles = {
  "&.MuiInputBase-root": {
    "&.MuiTablePagination-select": {
      fontSize: "14px",
      fontFamily: "var(--form-size-text)",
    },
  },
  "&.MuiOutlinedInput-root": {
    borderRadius: 0,
    height: "42px",
    fontSize: "18px",
    backgroundColor: "var(--color-white)",
    fontFamily: "var(--copytext-font-family)",

    ".Mui-focused, .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--color-black)",
      BorderRadius: "2px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "24px",
      color: "var(--color-steel-800)",
    },
  },
};
export const muiTextFieldOverrides = {
  root: {
    ...textFieldStyles,
  },
};
