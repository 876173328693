import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTexttoggleProps = DropOnONLh<JSX.PatternlibTexttoggle>;
export type PLTexttoggleExtendedProps = PLTexttoggleProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTexttoggle = wrapWc<JSX.PatternlibTexttoggle>("patternlib-texttoggle");
export const useRefPLTexttoggle = () => useRef<HTMLPatternlibTexttoggleElement>(null);

const PLTexttoggle = React.forwardRef<HTMLPatternlibTexttoggleElement, PLTexttoggleExtendedProps>((props, ref) => {
  const myRef = useRefPLTexttoggle();
  return (
    <WrappedTexttoggle ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTexttoggle>
  );
});
PLTexttoggle.displayName = "PLTexttoggle";

export default PLTexttoggle;
