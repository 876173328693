import { BinaryFilter } from "@cubejs-client/core";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import { DateRangeForCube } from "./DateRangeForCube";
import GeofencesBarChart from "./charts/bar/GeofencesBarChart";
import { dateRangeState, filtersAtom, filtersSelector } from "./charts/filtersState";
import { LeftTileWrapperSC, NoDataContainerSC } from "components/pages/AssetManagement/tileStyles";
import GeofencesTypeDoughnut from "./charts/doughnuts/GeofencesTypeDoughnut";
import { TitleAndPickerContainerSC, TitleButtonBoxSC, GraphBoxSC, ChartsContainerSC } from "./styles";
import AssetStateDoughnut from "./charts/doughnuts/AssetStateDoughnut";
import AssetUtilizationBarChart from "./charts/bar/AssetUtilizationBarChart";
import PLTextlink from "components/patternLib/PLTextlink";
import { noChartDataState, chartsLoadingState } from "./chartsState";
import { addYears, isSameDay, isToday } from "date-fns";

const DATE_YEAR_AGO = addYears(new Date(), -1);

const WorkloadOverviewTile: React.FC<{ assetId: string }> = ({ assetId }) => {
  const [selectedDate, setSelectedDate] = useRecoilState(dateRangeState);
  const [isFiltersReseted, setIsFilterReseted] = useState(false);

  const resetFilters = useResetRecoilState(filtersAtom);
  const noChartData = useRecoilValue(noChartDataState);
  const chartsLoading = useRecoilValue(chartsLoadingState);

  const [filters, setFilters] = useRecoilState(filtersSelector);
  const filterByAssetId = {
    member: "FactUtilization.dimassetid",
    operator: "equals",
    values: [assetId],
  };

  useEffect(() => {
    if (!isFiltersReseted && filters.length) {
      resetFilters();
      setIsFilterReseted(true);
    }
  }, [isFiltersReseted]);

  useEffect(() => {
    setFilters([filterByAssetId as BinaryFilter]);
  }, [assetId]);

  const { noDataForDateRangeMessage, noDataMessage } = translations.pages.assetManagement.assetDetailGraphs;

  const isFilteredByDate = !!filters.find((filter) => filter.member === "DimDate.date");
  const noDataText = isFilteredByDate ? noDataForDateRangeMessage : noDataMessage;
  const isDefaultDateRange =
    isToday(selectedDate?.end as Date) && isSameDay(selectedDate?.start as Date, DATE_YEAR_AGO);

  return (
    <LeftTileWrapperSC>
      <TitleAndPickerContainerSC>
        <TitleButtonBoxSC>
          <Typography variant="h4">{translations.pages.assetManagement.assetDetailGraphs.label}</Typography>
          {(filters.length > 2 || !isDefaultDateRange) && (
            <PLTextlink
              onClick={() => {
                resetFilters();
                setSelectedDate(undefined);
                setFilters([filterByAssetId as BinaryFilter]);
              }}
              label={translations.pages.assetManagement.assetDetailGraphs.resetFilter}
              uppercase={false}
              bold={false}
              size="small"
              style={{ height: "fit-content" }}
            />
          )}
        </TitleButtonBoxSC>
        <DateRangeForCube />
      </TitleAndPickerContainerSC>
      {!chartsLoading && noChartData && (
        <NoDataContainerSC sx={{ height: "50vh" }}>
          <Typography variant="copy">{noDataText}</Typography>
        </NoDataContainerSC>
      )}
      <ChartsContainerSC sx={{ display: !noChartData || chartsLoading ? "flex" : "none" }}>
        <GraphBoxSC>
          <GeofencesTypeDoughnut />
          <GeofencesBarChart />
        </GraphBoxSC>
        <GraphBoxSC>
          <AssetStateDoughnut />
          <AssetUtilizationBarChart />
        </GraphBoxSC>
      </ChartsContainerSC>
    </LeftTileWrapperSC>
  );
};
export default WorkloadOverviewTile;
