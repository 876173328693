export default {
  name: "Notification",
  plural: "Notifications",
  columns: {
    id: "ID",
    notificationScopeId: "Notification Scope ID",
    createdBy: "Created By",
    recipientEmail: "Recipient Email",
    notificationText: "Warning Text",
    resubmissionInterval: "Resubmission Interval",
    severity: "Severity",
    createdAt: "Created At",
    modifiedAt: "Modified At",
    tenantId: "",
    isActive: "Active",
    asset: "Asset",
    device: "Device",
    warningDefinition: "Warning Definition",
  },
};
