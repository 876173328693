import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetSensorMeasurementHistoryAggregateQueryVariables = Types.Exact<{
  deviceIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
  gatewayDeviceConnectionIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
  capabilityIds?: Types.InputMaybe<Array<Types.Scalars['UUID']> | Types.Scalars['UUID']>;
  dimensions?: Types.InputMaybe<Array<Types.Dimensions> | Types.Dimensions>;
  metrics?: Types.InputMaybe<Array<Types.Metrics> | Types.Metrics>;
  startDate: Types.Scalars['DateTime'];
  endDate: Types.Scalars['DateTime'];
  timeBucketInMinutes: Types.Scalars['Int'];
}>;


export type GetSensorMeasurementHistoryAggregateQuery = { sensorMeasurementHistoryAggregate?: Maybe<{ __typename?: 'SensorMeasurementHistoryAggregate', data?: Maybe<Array<Maybe<{ __typename?: 'AggregateData', timeBucket: string, dimensions?: Maybe<Array<Maybe<Array<{ __typename?: 'KeyValuePairOfStringAndGuid', key: string, value: string }>>>>, metrics?: Maybe<Array<Maybe<Array<{ __typename?: 'KeyValuePairOfStringAndNullableOfSingle', key: string, value?: Maybe<number> }>>>> }>>> }> };


export const GetSensorMeasurementHistoryAggregateDocument = gql`
    query getSensorMeasurementHistoryAggregate($deviceIds: [UUID!], $gatewayDeviceConnectionIds: [UUID!], $capabilityIds: [UUID!], $dimensions: [Dimensions!], $metrics: [Metrics!], $startDate: DateTime!, $endDate: DateTime!, $timeBucketInMinutes: Int!) {
  sensorMeasurementHistoryAggregate(gatewayDeviceConnectionIds: $gatewayDeviceConnectionIds, deviceIds: $deviceIds, capabilityIds: $capabilityIds, dimensions: $dimensions, metrics: $metrics, startDate: $startDate, endDate: $endDate, timeBucketInMinutes: $timeBucketInMinutes) {
    data {
      timeBucket
      dimensions {
        key
        value
      }
      metrics {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetSensorMeasurementHistoryAggregateQuery__
 *
 * To run a query within a React component, call `useGetSensorMeasurementHistoryAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorMeasurementHistoryAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorMeasurementHistoryAggregateQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *      gatewayDeviceConnectionIds: // value for 'gatewayDeviceConnectionIds'
 *      capabilityIds: // value for 'capabilityIds'
 *      dimensions: // value for 'dimensions'
 *      metrics: // value for 'metrics'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      timeBucketInMinutes: // value for 'timeBucketInMinutes'
 *   },
 * });
 */
export function useGetSensorMeasurementHistoryAggregateQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSensorMeasurementHistoryAggregateQuery, GetSensorMeasurementHistoryAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSensorMeasurementHistoryAggregateQuery, GetSensorMeasurementHistoryAggregateQueryVariables>(GetSensorMeasurementHistoryAggregateDocument, options);
      }
export function useGetSensorMeasurementHistoryAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSensorMeasurementHistoryAggregateQuery, GetSensorMeasurementHistoryAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSensorMeasurementHistoryAggregateQuery, GetSensorMeasurementHistoryAggregateQueryVariables>(GetSensorMeasurementHistoryAggregateDocument, options);
        }
export type GetSensorMeasurementHistoryAggregateQueryHookResult = ReturnType<typeof useGetSensorMeasurementHistoryAggregateQuery>;
export type GetSensorMeasurementHistoryAggregateLazyQueryHookResult = ReturnType<typeof useGetSensorMeasurementHistoryAggregateLazyQuery>;
export type GetSensorMeasurementHistoryAggregateQueryResult = Apollo.QueryResult<GetSensorMeasurementHistoryAggregateQuery, GetSensorMeasurementHistoryAggregateQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
