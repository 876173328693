import { useHistory, useLocation } from "react-router-dom";
import React from "react";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";
import translations from "translations";
import getTestIDs from "lib/utils/getTestIDs";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";

export const testIDs = getTestIDs();
const AddGeofenceLinkButton: React.FC<{ small: boolean }> = ({ small }) => {
  const location = useLocation();
  const history = useHistory();
  function handleClick() {
    history.push(`${location.pathname}/addGeoFence`);
  }

  return (
    <div>
      <PLButton
        size={small ? "small" : "big"}
        iconPosition="left"
        label={translations.pages.fleetInventory.addGeoFence.label}
        onClick={handleClick}
      >
        <PLIcon iconName="plus" size="24px" slot="icon" />
      </PLButton>
    </div>
  );
};
export default AddGeofenceLinkButton;
