import { Box } from "@mui/material";
import {
  NotificationDefinition,
  isFormSubmittedAtom,
  hasFormErrorsAtom,
  isTheFormValidSelector,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import {
  isScopeConnectivityAtom,
  notificationDefinitionsAtom,
  prevNotificationDefinitionsAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState/notificationDefinitionsState";
import PLIcon from "components/patternLib/PLIcon";
import PLTextlink from "components/patternLib/PLTextlink";
import { useGetNotificationDefinitionsOnNotificationLazyQuery } from "graphqlBase/NotificationDefinition/__generated__/getNotificationDefinitionsOnNotification";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import translations from "translations";
import { v4 as uuidv4 } from "uuid";
import ScopeDefinitionListRow from "./ScopeDefinitionListRow";
import Props from "./index.d";
import useGetScopeDefinitions from "./useGetScopeDefinitions";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import MandatoryFieldMessage from "components/pages/AlertManagement/AlertEditing/MandatoryFieldMessage";

const loadingStyles = {
  display: "flex",
  minHeight: "10vh",
  width: "50%",
  flexDirection: "column",
  justifyContent: "flex-start",
};

const NotificationScopeEdit: React.FC<Props> = ({ data: { state } }) => {
  const { notificationId }: { notificationId: string } = useParams();
  const formHasErrors = useRecoilValue(hasFormErrorsAtom);
  const { validDefinitionCount } = useRecoilValue(isTheFormValidSelector);
  const [notificationDefinitions, setNotificationDefinitions] = useRecoilState(notificationDefinitionsAtom);
  const [isScopeConnectivity, setIsScopeConnectivity] = useRecoilState(isScopeConnectivityAtom);
  const [prevNotificationDefinitions, setPrevNotificationDefinitions] = useRecoilState(prevNotificationDefinitionsAtom);
  const isFormSubmitted = useRecoilValue(isFormSubmittedAtom);
  const { operators } = useGetScopeDefinitions({ notificationScopeId: state });
  const [
    fetchDefinitions,
    { data: definitionsData, loading },
  ] = useGetNotificationDefinitionsOnNotificationLazyQuery({
    fetchPolicy: "network-only",
  });
  
  useLayoutEffect(() => {
    if (isFormSubmitted) return;
    setNotificationDefinitions(undefined);
  }, [state]);
  useEffect(() => {
    if (state === "2c920815-0262-4125-932c-3a9be981d8ca") setIsScopeConnectivity(true);
    else setIsScopeConnectivity(false);
  }, [state]);
  useEffect(() => {
    if (!notificationId || isFormSubmitted) return;
    fetchDefinitions({ variables: { notificationId } });
  }, []);

  useEffect(() => {
    if (!definitionsData?.notificationDefinitions?.length || isFormSubmitted) return;
    const definitions = definitionsData?.notificationDefinitions.reduce<NotificationDefinition[]>(
      (definitions, current) => {
        const { notificationOperatorId, id, storageArea, value, notification } = current;
        return definitions.concat({
          notificationOperatorId: notificationOperatorId ?? "",
          id,
          storageArea: storageArea ? { id: storageArea.id, storageAreaName: storageArea.storageAreaName } : undefined,
          value,
          uiId: id,
          scopeId: notification?.notificationScope.id,
        });
      },
      []
    );
    setNotificationDefinitions(definitions);
    if (!prevNotificationDefinitions) setPrevNotificationDefinitions(definitions);
  }, [definitionsData]);

  const addNewNotificationDefinition = () => {
    if (!state) return;
    setNotificationDefinitions((prevNotificationDefinitions) => {
      if (!!prevNotificationDefinitions && isScopeConnectivity) return [...prevNotificationDefinitions];
      return [...(prevNotificationDefinitions ?? [])].concat({
        notificationOperatorId: isScopeConnectivity ? "f33e2f28-2b38-4852-ac5f-f33cd5c9dc2c" : "",
        uiId: uuidv4().toString(),
        scopeId: state,
      });
    });
  };

  useEffect(() => {
    if (!isScopeConnectivity) return;
    addNewNotificationDefinition();
  }, [isScopeConnectivity]);

  return (
    <LoadingSpinner loading={loading}>
      <Box sx={loadingStyles}>
        {notificationDefinitions?.map((notificationDefinition, index) => {
          return (
            <ScopeDefinitionListRow
              isScopeConnectivity={isScopeConnectivity}
              key={notificationDefinition.uiId}
              notificationDefinition={notificationDefinition}
              setNotificationDefinitions={setNotificationDefinitions}
              operators={operators}
              index={index}
              selectedScopeId={state}
            />
          );
        })}
        {!isScopeConnectivity && (
          <Box sx={{ width: "fit-content" }}>
            <PLTextlink
              onClick={() => {
                addNewNotificationDefinition();
              }}
              label={`${translations.globals.button.add} ${translations.pages.groups.query.rule}`}
              iconPosition="left"
            >
              <PLIcon iconName="plus" size="24px" slot="icon" />
            </PLTextlink>
            {!validDefinitionCount && formHasErrors && !notificationDefinitions && <MandatoryFieldMessage />}
          </Box>
        )}
      </Box>
    </LoadingSpinner>
  );
};

export default NotificationScopeEdit;
