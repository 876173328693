import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationDefinitionsForAssetDetailsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.NotificationDefinitionFilterInput>;
}>;


export type GetNotificationDefinitionsForAssetDetailsQuery = { notificationDefinitions: Array<{ __typename?: 'NotificationDefinition', id: string, notification: { __typename?: 'Notification', id: string, notificationText?: Maybe<string>, createdBy: string, notificationScope: { __typename?: 'NotificationScope', id: string, scopeName?: Maybe<string> }, mappingNotificationUser: Array<{ __typename?: 'MappingNotificationUser', id: string, user: { __typename?: 'User', id: string, email?: Maybe<string> } }> }, deviceNotification: Array<{ __typename?: 'DeviceNotification', id: string, notificationSend: string }> }> };


export const GetNotificationDefinitionsForAssetDetailsDocument = gql`
    query getNotificationDefinitionsForAssetDetails($where: NotificationDefinitionFilterInput) {
  notificationDefinitions(where: $where) {
    id
    notification {
      id
      notificationText
      createdBy
      notificationScope {
        id
        scopeName
      }
      mappingNotificationUser {
        id
        user {
          id
          email
        }
      }
    }
    deviceNotification(order: {notificationSend: DESC}) {
      id
      notificationSend
    }
  }
}
    `;

/**
 * __useGetNotificationDefinitionsForAssetDetailsQuery__
 *
 * To run a query within a React component, call `useGetNotificationDefinitionsForAssetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationDefinitionsForAssetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationDefinitionsForAssetDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNotificationDefinitionsForAssetDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationDefinitionsForAssetDetailsQuery, GetNotificationDefinitionsForAssetDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNotificationDefinitionsForAssetDetailsQuery, GetNotificationDefinitionsForAssetDetailsQueryVariables>(GetNotificationDefinitionsForAssetDetailsDocument, options);
      }
export function useGetNotificationDefinitionsForAssetDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationDefinitionsForAssetDetailsQuery, GetNotificationDefinitionsForAssetDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNotificationDefinitionsForAssetDetailsQuery, GetNotificationDefinitionsForAssetDetailsQueryVariables>(GetNotificationDefinitionsForAssetDetailsDocument, options);
        }
export type GetNotificationDefinitionsForAssetDetailsQueryHookResult = ReturnType<typeof useGetNotificationDefinitionsForAssetDetailsQuery>;
export type GetNotificationDefinitionsForAssetDetailsLazyQueryHookResult = ReturnType<typeof useGetNotificationDefinitionsForAssetDetailsLazyQuery>;
export type GetNotificationDefinitionsForAssetDetailsQueryResult = Apollo.QueryResult<GetNotificationDefinitionsForAssetDetailsQuery, GetNotificationDefinitionsForAssetDetailsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
