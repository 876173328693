export default {
  application_settings: "Application Settings",
  article_details: "Article Details",
  asset_details: "Asset Details",
  assign_amount: "Assign Amount",
  detailView: "Detail View",
  new_customer_request: "New Custom",
  request_details: "Request Details",
  asset_manager: "Asset Manager",
};
