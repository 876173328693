import L from "leaflet";

export default (cluster: any) =>
  L.divIcon({
    ...cluster,
    iconAnchor: [16, 43],
    iconSize: [0, 0],
    html: `<svg width="32" height="43" viewBox="0 0 32 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <style>
                .text { 13px sans-serif; fill:black;}
              </style>
            <g filter="url(#filter0_d_1275_132669)">
            <path d="M28 13.6C28 20.5588 17.3333 36 16 36C14.6667 36 4 20.5588 4 13.6C4 6.64121 9.37258 1 16 1C22.6274 1 28 6.64121 28 13.6Z" fill="#FFD000"/>
            
            <text x="50%" y="50%" text-anchor="middle" font-size="12px" font-weight="700" class="text">${cluster.getChildCount()}</text>
            </g>
            <defs>
            <filter id="filter0_d_1275_132669" x="0" y="0" width="32" height="43" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="3"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.25098 0 0 0 0 0.27451 0 0 0 0.3 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1275_132669"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1275_132669" result="shape"/>
            </filter>
            </defs>
            </svg>`,
  });
