export default {
  creationTime: "Creation Time",
  creationDate: "Creation Date",
  measurementDate: "Measurement Date",
  last24Hours: "Last 24 Hours",
  last3Days: "Last 3 Days",
  lastWeek: "Last Week",
  last2Weeks: "Last 2 Weeks",
  lastMonth: "Last Month",
  lastYear: "Last Year",
  date: "date",
  hours: "hours",
  minutes: "minutes",
  hour: "hour",
  minute: "minute",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  today: "today",
  timeBucket: {
    tenMin: "10 min",
    oneHour: "1 hour",
    sixHours: "6 hours",
    twelveHours: "12 hours",
    twentyfourHours: "24 hours",
  },
  now: "just now",
};
