export default {
  label: "Asset Management",
  linkText: "Device Detail",
  tabs: {
    automatic: {
      label: "Automatically Identified",
    },
    automaticFailed: {
      label: "No automatic identification possible",
    },
    unknown: {
      label: "Unknown Objects",
    },
  },
  assetTags: {
    updateTags: "Update Asset Tags",
  },
  noDeviceText: "No device assignment",
  assetAssignment: {
    label: "Asset Assignment",
    assetIdentification: "Asset Identification",
    imageGallery: {
      noImagesMessage: "No asset images available",
      noImageDescriptionMessage: "No description available",
    },
  },
  assetAssignmentConfirmationModal: {
    title: "Assignment Completed",
    assignmentConfirmationMessage: "has been successfully assigned to",
  },
  assetDeviceUAssignmentConfirmationModal: {
    title: "Unpairing",
    assignmentConfirmationMessageBeforeDeviceName: "Are you sure that you want to disconnect",
    assignmentConfirmationMessageAfterDeviceName:
      "You will still be able to see the collected data, but no new data will be created and no alerts setup will be possible.",
    errorMessage:
      "A problem has occured when unpairing the device from this asset. Please contact the IT support department for more information and assistance.",
    toastMessage: "Device unpaired successfully",
  },
  assetDetails: {
    label: "Asset Details",
    noAssetDetailsMessage: "There is no info for this asset",
    assetMasterDataTile: {
      label: "Basics",
    },
    currentPositionTile: {
      label: "Current Position",
      noDataMessage: "No position data is available for the last 7 days",
      firstLocatedDateMessage: "This Asset is located here since",
      lastUpdateLocationDateMessage: "Last update",
    },
    couplingOverviewTile: {
      label: "Coupling Overview",
    },
    currentlyCoupledTile: {
      label: "Currently Coupled With",
    },
    envMeasurementsTile: {
      label: "Environmental Measurements",
      noDataMessage: "No measurement recorded within the last 24h for this asset",
    },
    technicalDataTile: {
      label: "Technical Data",
      noDataMessage: "No technical data available for this asset.",
    },
    associatedAssetsTile: {
      label: "Associated Assets",
      noDataMessage: "There are no associated assets for this asset.",
    },
    alertsTile: { label: "Alerts", noDataMessage: "There are no alerts for this asset." },
    uploadImageButton: "Upload Picture",
    uploadImageDropzoneLabel: "Choose photo",
    dropZoneModalTitle: "Upload picture",
    dropzoneButtonLabel: "Select File",
    describePhoto: "Describe Photo",
    toastMessageSuccess: "Asset picture uploaded successfully",
    toastMessageError: "Error uploading asset picture",
  },
  assetDetailGraphs: {
    label: "Workload Overview",
    hours: "Hours",
    assetState: {
      label: "Asset State",
      stateHistory: "State History",
      noAssetStateHistoryMessage: "There is no state history for this asset",
    },
    timeDistribution: {
      label: "Distribution Over Time",
    },
    geofences: {
      label: "Geofences",
    },
    resetFilter: "Reset filter",
    noDataForDateRangeMessage: "No data available for chosen time period. Select another range.",
    noDataMessage: "No data available for this asset.",
  },
  assetDeviceUnassignment: {
    modalTitle: "Unpairing Tracker",
    modalMessage:
      "This action will remove your Item from the LiTrack application and make the tracker available for a new pairing. Do you want to continue?",
  },
  assetAssignmentTable: {
    label: "Asset-Device Pairing",
  },
  assetDetailsAndAlerts: {
    associatedAssets: "Associated Assets",
    noAssociatedAssetsMessage: "There are no associated assets for this asset.",
    noAlertsMessage: "There are no alerts for this asset.",
  },
  assetTrackerInfos: "Asset & Tracker Information",
  media: "Media",
  myAssets: "My Assets",
  openAssignments: "Open Assignments",
  noAssetsMessage: "There are no assets to show.",
  createAsset: "Create Asset",
};
