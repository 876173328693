import cubejs from "@cubejs-client/core";
import { GetCubeJsTokenDocument } from "graphqlBase/DeviceConfiguration/__generated__/getCubeJsToken";
import client from "apollo/client";
import jwtDecode from "jwt-decode";

const API_URL = process.env.REACT_APP_CUBEJS_TOKEN;

export const cubejsApi = cubejs(
  async () => {
    const apiTokenPromise = await client.query({
      query: GetCubeJsTokenDocument,
    });
    const apiToken = apiTokenPromise.data.identityServerUserScopes.cubeJsJwtToken;

    const currentTimestamp = new Date().getTime();
    //@ts-ignore
    const expTimestamp = +jwtDecode(apiTokenPromise.data.identityServerUserScopes.cubeJsJwtToken).exp * 1000;
    const isTokenValid = expTimestamp > currentTimestamp;
    if (isTokenValid) return apiToken;

    const apiTokenRefreshPromise = await client.query({
      query: GetCubeJsTokenDocument,
      fetchPolicy: "network-only",
    });
    return apiTokenRefreshPromise.data.identityServerUserScopes.cubeJsJwtToken;
  },
  {
    apiUrl: `${API_URL}`,
  }
);
