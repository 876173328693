import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, styled, Table, TableCell, TableRow, Typography } from "@mui/material";
import PLTextinput from "components/patternLib/form/PLTextinput";
import {
  deviceConfigurationModifier,
  deviceConfigurationAtom,
  initialDeviceConfigurationAtom,
  isValidJsonAtom,
  configurationsStateFieldAtom,
  siteAreaDefinitionModifier,
  DeviceConfigurationObjectTypeKey,
} from "./recoilState";
import { LhChange } from "@liebherr/patternlib/dist/types/utils/interfaces";
import DeviceConfigByStateHandler from "./DeviceConfigByStateHandler";
import Ajv, { LimitParams } from "ajv";
import translations from "translations";
import PLButton from "components/patternLib/PLButton";
import PLModal from "components/patternLib/PLModal";
import theme from "themes/theme";
import useHandleSaveConfig from "./useHandleSaveConfig";
import RestoreDefaultConfig from "./RestoreDefaultConfig";
import OvertakeOtherDevicesConfig from "./OvertakeOtherDevicesConfig";
import PLDropdown from "components/patternLib/form/PLDropdown";
import PLOption from "components/patternLib/form/PLOption";
import { LhSingleOptionSelectEvent } from "@liebherr/patternlib";
import useBlockHistory, { UseBlockHistoryCb } from "routes/useBlockHistory";

const ConfigurationEditorSC = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(6),
  rowGap: theme.spacing(2),
});

const ButtonsSC = styled("div")({
  display: "flex",
  columnGap: theme.spacing(3),
});

const TableContainerSC = styled("div")({
  margin: `${theme.spacing(6)} 0`,
});

const HandlingPannelSC = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});
const TextLinkSC = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: theme.spacing(1),
});
interface Props {
  deviceId: string;
  deviceTypeName?: string;
  jsonSchema: JSON;
}

interface EditCell {
  error: Ajv.ErrorObject | null | undefined;
  field: DeviceConfigurationObjectTypeKey;
}

const fieldsWithMinValues: {
  [K in DeviceConfigurationObjectTypeKey]: {
    hasMinValue: boolean;
    unit: string;
  };
} = {
  accel_level_movt: {
    hasMinValue: false,
    unit: "mg",
  },
  accel_level_shock: {
    hasMinValue: false,
    unit: "mg",
  },
  battery_level_period: {
    hasMinValue: true,
    unit: "seconds",
  },
  ble_scan_period: {
    hasMinValue: true,
    unit: "seconds",
  },
  command_request_period: {
    hasMinValue: true,
    unit: "seconds",
  },
  data_xfer_period: {
    hasMinValue: true,
    unit: "seconds",
  },
  environmental_sense_period: {
    hasMinValue: true,
    unit: "seconds",
  },
  gnss_movt_detect_offset: {
    hasMinValue: false,
    unit: "mg",
  },
  gnss_pos_period: {
    hasMinValue: true,
    unit: "seconds",
  },
  state_timeout: {
    hasMinValue: false,
    unit: "seconds",
  },
};
const timeOptions = [
  { label: "minutes", value: 60 },
  { label: "seconds", value: 1 },
];
const EditCell: React.FC<EditCell> = ({ field, error }) => {
  const [state, setState] = useRecoilState(deviceConfigurationModifier(field));
  const [timeUnit, setTimeUit] = useState<number>(60);
  const params = error?.params as LimitParams;

  const hasTimeUnit = fieldsWithMinValues[field].unit !== "mg";

  const onlhChange = (event: CustomEvent<LhChange>) => {
    if (event.detail.value) {
      if (hasTimeUnit) {
        setState(+event.detail.value * timeUnit);
      } else {
        setState(+event.detail.value);
      }
    }
  };
  const handleChange = (event: CustomEvent<LhSingleOptionSelectEvent>) => {
    setTimeUit(+(event.detail.selected?.value ?? "1"));
  };

  const getStep = () => {
    if (hasTimeUnit && timeUnit === 60) return 1;
    else return 30;
  };

  return (
    <>
      <TableCell scope="row">
        <Typography variant="copy">
          {" "}
          {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.configKeys[field]}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <PLTextinput
          value={hasTimeUnit ? `${state / timeUnit}` : `${state}`}
          type="number"
          step={getStep()}
          lhChange={onlhChange}
          status={error ? "error" : undefined}
          errorText={
            params &&
            `${error?.keyword} value for this parameter is ${hasTimeUnit ? params.limit / timeUnit : params.limit}`
          }
        />
      </TableCell>
      <TableCell scope="row" align="right" sx={{ width: "17%", verticalAlign: error ? "baseline" : "inherit" }}>
        {hasTimeUnit ? (
          <PLDropdown lhSelect={handleChange} showRemoveIcon={false} value={`${timeUnit}`}>
            {timeOptions.map((e) => {
              return <PLOption label={e.label} value={`${e.value}`} key={e.value} />;
            })}
          </PLDropdown>
        ) : (
          <Typography variant="copy">{fieldsWithMinValues[field].unit}</Typography>
        )}
      </TableCell>
    </>
  );
};

const ConfigurationEditor: React.FC<Props> = ({ deviceId, jsonSchema, deviceTypeName }) => {
  const [openModal, setOpenModal] = useState(false);
  const setIsValidJson = useSetRecoilState(isValidJsonAtom);
  const configurationsStateField = useRecoilValue(configurationsStateFieldAtom);
  const [currentDeviceConfiguration, setDeviceConfiguration] = useRecoilState(deviceConfigurationAtom);
  const { setBlockCallBack, continueAfterBlock, setPreventPageClose } = useBlockHistory();
  const positionLatLng = useRecoilValue(siteAreaDefinitionModifier);
  const { lat, lon } = positionLatLng;
  const initialDeviceConfiguration = useRecoilValue(initialDeviceConfigurationAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSaveConfig = useHandleSaveConfig(deviceId);
  const ajv = new Ajv({ allErrors: true });
  const validate = ajv.compile(jsonSchema);
  const valid = validate(currentDeviceConfiguration);
  const disabled = configurationsStateField.includes("TRANSPORT")
    ? !valid || JSON.stringify(currentDeviceConfiguration) === JSON.stringify(initialDeviceConfiguration)
    : !valid ||
      JSON.stringify(currentDeviceConfiguration) === JSON.stringify(initialDeviceConfiguration) ||
      !lat ||
      !lon;
  console.log(JSON.stringify(currentDeviceConfiguration) === JSON.stringify(initialDeviceConfiguration));
  useEffect(() => {
    setIsValidJson(valid);
  }, [valid]);
  useEffect(() => {
    TriggerBlocker(!disabled);
  }, [disabled]);

  const TriggerBlocker = useCallback(
    (block: boolean) => {
      const blockerCb: UseBlockHistoryCb = () => {
        setOpenModal(true);
        return false;
      };
      setBlockCallBack(() => (block ? blockerCb : undefined));
      setPreventPageClose(block);
    },
    [setBlockCallBack, setPreventPageClose]
  );
  const cancelDiscardChanges = () => {
    setOpenModal(false);
  };
  const fields = Object.keys(fieldsWithMinValues) as DeviceConfigurationObjectTypeKey[];
  return (
    <ConfigurationEditorSC>
      <DeviceConfigByStateHandler />
      <TableContainerSC>
        <Table>
          {fields.map((field, index) => {
            const error = validate.errors?.find((er) => er.dataPath.includes(field));
            return (
              <TableRow key={index}>
                <EditCell field={field} error={error} />
              </TableRow>
            );
          })}
        </Table>
      </TableContainerSC>

      <HandlingPannelSC>
        <TextLinkSC>
          <RestoreDefaultConfig deviceId={deviceId} />
          <OvertakeOtherDevicesConfig deviceId={deviceId} deviceTypeName={deviceTypeName} />
        </TextLinkSC>
        <ButtonsSC>
          <PLButton
            disabled={JSON.stringify(currentDeviceConfiguration) === JSON.stringify(initialDeviceConfiguration)}
            type="secondary"
            label="Cancel"
            onClick={() => setDeviceConfiguration(initialDeviceConfiguration)}
          />
          <PLButton
            disabled={disabled}
            label={translations.globals.button.saveChanges}
            onClick={() => setIsModalOpen(true)}
          />
        </ButtonsSC>
      </HandlingPannelSC>
      <PLModal
        showIcon
        allowCloseOnBackdrop
        modalTitle={translations.pages.deviceManagement.deviceDetails.deviceConfiguration.newConfiguration.modalTitle}
        lhCancel={() => {
          setIsModalOpen(false);
        }}
        lhConfirm={async () => {
          await handleSaveConfig();
          setIsModalOpen(false);
        }}
        lhClose={() => setIsModalOpen(false)}
        show={isModalOpen}
        iconName="settings"
      >
        <>
          <Box slot="modal-content">
            <Typography variant="copy">
              {translations.pages.deviceManagement.deviceDetails.deviceConfiguration.newConfiguration.message}
            </Typography>
          </Box>
        </>
      </PLModal>
      <PLModal
        showIcon
        lhConfirm={() => continueAfterBlock()}
        lhCancel={cancelDiscardChanges}
        lhClose={cancelDiscardChanges}
        show={openModal}
        allowCloseOnBackdrop
        modalTitle={translations.pages.userManagement.userRoleManager.discardChanges}
        confirmLabel={translations.globals.button.yes}
        cancelLabel={translations.globals.button.cancel}
        iconName="question-mark"
      >
        <>
          <Box slot="modal-content">
            <Typography variant="copy">{translations.pages.userManagement.userRoleManager.DiscardMessage}</Typography>
          </Box>
        </>
      </PLModal>
    </ConfigurationEditorSC>
  );
};

export default ConfigurationEditor;
