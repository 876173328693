import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetCouplingsForAttachmentQueryVariables = Types.Exact<{
  deviceId: Types.Scalars['UUID'];
}>;


export type GetCouplingsForAttachmentQuery = { couplingStatusAttachment?: Maybe<{ __typename?: 'CouplingStatus', couplingState: number, couplingStateDescriptive?: Maybe<string>, couplingStateDatetime: string, couplingStateOperatingMinutes: number, relatedCoupledDevices?: Maybe<Array<Maybe<{ __typename?: 'CoupledDevice', device: { __typename?: 'Device', id: string, assetDevice?: Maybe<Array<Maybe<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string, assetAttributeValue: Array<{ __typename?: 'AssetAttributeValue', id: string, value: string }>, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', generalItem?: Maybe<string> }> } }>>> } }>>> }> };


export const GetCouplingsForAttachmentDocument = gql`
    query getCouplingsForAttachment($deviceId: UUID!) {
  couplingStatusAttachment(deviceId: $deviceId) {
    couplingState
    couplingStateDescriptive
    couplingStateDatetime
    couplingStateOperatingMinutes
    relatedCoupledDevices {
      device {
        id
        assetDevice {
          id
          asset {
            id
            assetAttributeValue(where: {assetAttributeId: {eq: "89F8EF48-CF7E-48C9-A93E-8BA58B85D91E"}}) {
              id
              value
            }
            assetMasterData {
              generalItem
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCouplingsForAttachmentQuery__
 *
 * To run a query within a React component, call `useGetCouplingsForAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouplingsForAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouplingsForAttachmentQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetCouplingsForAttachmentQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCouplingsForAttachmentQuery, GetCouplingsForAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCouplingsForAttachmentQuery, GetCouplingsForAttachmentQueryVariables>(GetCouplingsForAttachmentDocument, options);
      }
export function useGetCouplingsForAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCouplingsForAttachmentQuery, GetCouplingsForAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCouplingsForAttachmentQuery, GetCouplingsForAttachmentQueryVariables>(GetCouplingsForAttachmentDocument, options);
        }
export type GetCouplingsForAttachmentQueryHookResult = ReturnType<typeof useGetCouplingsForAttachmentQuery>;
export type GetCouplingsForAttachmentLazyQueryHookResult = ReturnType<typeof useGetCouplingsForAttachmentLazyQuery>;
export type GetCouplingsForAttachmentQueryResult = Apollo.QueryResult<GetCouplingsForAttachmentQuery, GetCouplingsForAttachmentQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
