import { styled } from "@mui/material";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import React, { useState } from "react";
import * as L from "leaflet";
import { LineDecoratorType, PositionHistoryMapProps } from "./index.d";
import "leaflet-polylinedecorator";
import PositionHistoryMarker from "./PositionHistoryMarker";
import RemoveLayers from "./RemoveLayers";
const MapContainerSC = styled(MapContainer)({
  height: "100vh",
  width: "75vw",
  [`& .leaflet-control-attribution `]: {
    display: "none",
  },
  [`& .leaflet-popup-content-wrapper`]: {
    borderRadius: 0,
    border: "none",
  },
  [`& .leaflet-popup-close-button`]: {
    display: "none",
  },
});

const center: L.LatLngTuple = [52.634281158447266, 13.288796424865723];
const zoom: number | undefined = 10;

const PositionHistoryMap: React.FC<PositionHistoryMapProps> = ({ movementHistory, movementHistoryLocations }) => {
  const [lineDecoratorState, setLineDecoratorState] = useState<LineDecoratorType>(undefined);
  return (
    <MapContainerSC center={[52.634281158447266, 13.288796424865723]} zoom={zoom} zoomControl={false}>
      <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <ZoomControl position="bottomright" />
      <RemoveLayers lineDecoratorState={lineDecoratorState} />
      {movementHistory?.map((movement, index) => {
        return (
          <PositionHistoryMarker
            key={`${movement?.deviceId}${index}`}
            movement={movement}
            index={index}
            movementHistory={movementHistory}
            movementHistoryLocations={movementHistoryLocations}
            lineDecoratorState={lineDecoratorState}
            setLineDecoratorState={setLineDecoratorState}
          />
        );
      })}
    </MapContainerSC>
  );
};

export default PositionHistoryMap;
