import PLOption from "components/patternLib/form/PLOption";
import { LhSingleOptionSelectEvent } from "@liebherr/patternlib/dist/types/utils/events/select";
import PLDropdown from "components/patternLib/form/PLDropdown";
import React, { useEffect } from "react";
import translations from "translations";
import Props from "./index.d";
import useGetForeignKeyList from "./useGetForeignKeyList";
import { PatternlibDropdownCustomEvent } from "@liebherr/patternlib/dist/types/components";

const ForeignKeySelector: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    disabled,
    title,
    testId,
    pickDefaultFirst,
    foreignKeyParams,
    isPartOfSummary,
  } = props;
  const elems = useGetForeignKeyList({ foreignKeyParams });

  const handleChange = (event: PatternlibDropdownCustomEvent<LhSingleOptionSelectEvent>) => {
    setAndSave((event.detail.selected?.value as unknown) as string);
  };
  useEffect(() => {
    if (pickDefaultFirst && !state && !!elems && elems.length) setAndSave(elems[0].value);
  }, [elems, state, pickDefaultFirst]);

  return (
    <>
      {props.disabled && isPartOfSummary ? (
        <>
          <PLDropdown label={translations.pages.fleetInventory.multiSelectLabel} disabled />
        </>
      ) : (
        <div>
          {!!title && (
            <PLDropdown
              data-testid={testId}
              disabled={disabled}
              label={title}
              value={state}
              lhSelect={handleChange}
              style={{ width: 315 }}
              showRemoveIcon={false}
            >
              {elems.map((e) => {
                return <PLOption label={e.label} value={e.value} />;
              })}
            </PLDropdown>
          )}
        </div>
      )}
    </>
  );
};

export default ForeignKeySelector;
