import React from "react";
import { Button, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
interface ButtonProps {
  cancelAction: () => void;
  cancelLabel: string;
  confirmLabel: string;
  doMutations: () => void;
}

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "32px",
    paddingTop: "32px",
    width: "100%",
    paddingRight: "25px",
    paddingLeft: "11px",
  },
});

export const Buttons: React.FC<ButtonProps> = ({ cancelAction, doMutations, confirmLabel, cancelLabel }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttons}>
      <Button
        color="secondary"
        variant="outlined"
        style={{ width: "15%" }}
        onClick={() => {
          cancelAction();
        }}
      >
        {cancelLabel}
      </Button>
      <IconButton
        color="primary"
        onClick={() => {
          doMutations();
        }}
      >
        {confirmLabel} <ArrowRightAltIcon style={{ marginLeft: "15px" }} />
      </IconButton>
    </div>
  );
};
