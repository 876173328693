import { LhSingleOptionSelectEvent } from "@liebherr/patternlib/dist/types/utils/events/select";
import { LhChange, Status } from "@liebherr/patternlib/dist/types/utils/interfaces";
import { Box, Typography } from "@mui/material";
import {
  NotificationDefinition,
  isFormSubmittedAtom,
  isTheFormValidSelector,
  notificationDefinitionsAtom,
  hasFormErrorsAtom,
} from "components/pages/AlertManagement/AlertEditing/recoilState";
import PLIcon from "components/patternLib/PLIcon";
import PLDropdown from "components/patternLib/form/PLDropdown";
import PLOption from "components/patternLib/form/PLOption";
import PLTextinput from "components/patternLib/form/PLTextinput";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import setNotificationDefinition from "../setNotificationDefinition";
import LocationScopeSelectAutocomplete from "./LocationScopeSelectAutocomplete";
import { selectedLocationAtomFamily } from "./LocationScopeSelectAutocomplete/locationScopeState";
import { ScopeDefinitionListRowProps } from "./index.d";
import { AlertRuleRowSC, AlertValueWrapperSC, GeofenceWrapperSC, UnitAndDeleteRowButtonSC, UnitTextSC } from "./styles";
import MandatoryFieldMessage from "components/pages/AlertManagement/AlertEditing/MandatoryFieldMessage";
import SliderPLStyle from "components/atomics/SliderPLStyle";

const ScopeDefinitionListRow: React.FC<ScopeDefinitionListRowProps> = ({
  notificationDefinition,
  setNotificationDefinitions,
  operators,
  index,
  key,
  selectedScopeId,
  isScopeConnectivity,
}) => {
  const { scopeId } = notificationDefinition;
  const formHasErrors = useRecoilValue(hasFormErrorsAtom);
  const { definitionRowCount,validDefinitionCount,hasDefinitionOperator,hasDefinitionValue } = useRecoilValue(isTheFormValidSelector);
  const [selectedOperator, setSelectedOperator] = useState<string | undefined>();
  const [textOrSliderValue, setTextOrSliderValue] = useState<string | undefined>();

  const setSelectedGeofence = useSetRecoilState(selectedLocationAtomFamily(notificationDefinition?.uiId));

  const isFormSubmitted = useRecoilValue(isFormSubmittedAtom);

  const [initialValuesSet, setInitialValuesSet] = useState<boolean>(false);
  
  const resetNotificationDefinitions = useResetRecoilState(notificationDefinitionsAtom);

  const isGeofence = scopeId?.includes("1c920815-0262-4125-932c-3a9be981d8ca");
  const isHumidity = scopeId?.includes("c9c9a874-abb4-4cd7-b766-95276516e2b1");
  const isTemperature = scopeId?.includes("5b4215c9-8d5f-46ad-b848-fbff137da06e");

  useEffect(() => {
    if (!notificationDefinition || initialValuesSet) return;
    const { notificationOperatorId, value, storageArea } = notificationDefinition;
    setSelectedOperator(notificationOperatorId);
    if (!!value) setTextOrSliderValue(value);
    if (!!storageArea) setSelectedGeofence(storageArea?.storageAreaName);
    setInitialValuesSet(true);
  }, [notificationDefinition, initialValuesSet]);

  useEffect(() => {
    if (isFormSubmitted) return;
    if (selectedScopeId !== scopeId) resetNotificationDefinitions();
  }, [selectedScopeId]);

  const handleChangePreSelect = (event: CustomEvent<LhSingleOptionSelectEvent>) => {
    const { selected } = event.detail;
    setSelectedOperator((selected?.value as string) ?? undefined);

    setNotificationDefinition(
      { notificationOperatorId: selected ? (selected?.value as string) : "" },
      setNotificationDefinitions,
      notificationDefinition
    );
  };

  const handleChangePre = (key: keyof NotificationDefinition) => (event: CustomEvent<LhChange>) => {
    const { value } = event.detail;
    setNotificationDefinition({ [key]: value ?? "" }, setNotificationDefinitions, notificationDefinition);
    setTextOrSliderValue(value?.toString());
  };

  const deleteRow = () => {
    setNotificationDefinition({ delete: true }, setNotificationDefinitions, notificationDefinition);
  };
  const onSliderChange = (key: keyof NotificationDefinition) => (event: Event, value: number | number[]) => {
    setNotificationDefinition({ [key]: value.toString() ?? "" }, setNotificationDefinitions, notificationDefinition);

    setTextOrSliderValue(value?.toString());
  };
  return (
    <AlertRuleRowSC key={key}>
      {isScopeConnectivity && (
        <Box sx={{ width: "700px" }}>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography variant="copyBold">
              {translations.pages.alertManagement.scopeEdit.connectivity.title}
            </Typography>
            <Box sx={{ display: "flex", columnGap: "8px",marginTop:"10px" }}>
              <PLIcon iconName="info-circle" size="24px" />
              <Typography>{translations.pages.alertManagement.scopeEdit.connectivity.message}</Typography>
            </Box>
          </Box>
          <SliderPLStyle onChange={onSliderChange("value")} value={Number(textOrSliderValue)} step={5}/>
          <Typography sx={{ marginTop: "4px", padding: "4px" }}>
            {!!textOrSliderValue ? `${textOrSliderValue} min` : "0 min"}{" "}
          </Typography>
          {!validDefinitionCount && formHasErrors && <MandatoryFieldMessage />}
        </Box>
      )}
      {!isScopeConnectivity && (
        <>
          {isGeofence && (
            <GeofenceWrapperSC>
              <LocationScopeSelectAutocomplete
                id={notificationDefinition.uiId}
                label={index === 0 ? "Location" : undefined}
                notificationDefinition={notificationDefinition}
                setNotificationDefinitions={setNotificationDefinitions}
              />
            </GeofenceWrapperSC>
          )}
          <Box sx={{ flexDirection: "column" }}>
          <PLDropdown
            id="notificationDefinition_operators"
            lhSelect={handleChangePreSelect}
            value={selectedOperator}
            width="280px"
            label={index === 0 ? translations.pages.alertManagement.scopeEdit.operator : undefined}
            status={!hasDefinitionOperator && formHasErrors ? "error" : "default"}
            preventInput
            showRemoveIcon={false}
          >
            {operators?.map((e) => (
              <PLOption key={e.value} label={e.label} value={e.value} />
            ))}
          </PLDropdown>
          {!hasDefinitionOperator && formHasErrors && <MandatoryFieldMessage />}
          </Box>
          {!isGeofence && (
            <AlertValueWrapperSC>
              <Box sx={{ flexDirection: "column" }}>
              <PLTextinput
                showRemoveIcon={false}
                lhChange={handleChangePre("value")}
                value={textOrSliderValue ?? ""}
                label={index === 0 ? translations.pages.alertManagement.scopeEdit.value : undefined}
                width="280px"
                id="notificationDefinition_value"
                status={!hasDefinitionValue && formHasErrors ? "error" : "default"}
              />
              {!hasDefinitionValue && formHasErrors && <MandatoryFieldMessage />}
              </Box>
            </AlertValueWrapperSC>
          )}
        </>
      )}
      <UnitAndDeleteRowButtonSC isfirstrow={index === 0}>
        {isHumidity && <UnitTextSC variant="copy">%</UnitTextSC>}
        {isTemperature && <UnitTextSC variant="copy"> °C </UnitTextSC>}
        {definitionRowCount > 1 && (
          <Box onClick={deleteRow} style={{ cursor: "pointer" }}>
            <PLIcon size="30px" iconName="bin" />
          </Box>
        )}
      </UnitAndDeleteRowButtonSC>
    </AlertRuleRowSC>
  );
};

export default ScopeDefinitionListRow;
