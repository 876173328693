import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetStorageAreasForListQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.StorageAreaFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.StorageAreaSortInput> | Types.StorageAreaSortInput>;
}>;


export type GetStorageAreasForListQuery = { storageAreas: Array<{ __typename?: 'StorageArea', id: string, storageAreaName: string, storageAreaType: { __typename?: 'StorageAreaType', storageAreaTypeName: string }, geofence?: Maybe<{ __typename?: 'GeoJSONPolygonType', coordinates?: Maybe<Array<Maybe<Array<Maybe<[number,number]>>>>>, type: Types.GeoJsonGeometryType, bbox: Array<number> }> }> };


export const GetStorageAreasForListDocument = gql`
    query getStorageAreasForList($where: StorageAreaFilterInput, $take: Int, $skip: Int, $order: [StorageAreaSortInput!]) {
  storageAreas(where: $where, take: $take, skip: $skip, order: $order) {
    id
    storageAreaName
    storageAreaType {
      storageAreaTypeName
    }
    geofence {
      coordinates
      type
      bbox
    }
  }
}
    `;

/**
 * __useGetStorageAreasForListQuery__
 *
 * To run a query within a React component, call `useGetStorageAreasForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageAreasForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageAreasForListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetStorageAreasForListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStorageAreasForListQuery, GetStorageAreasForListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStorageAreasForListQuery, GetStorageAreasForListQueryVariables>(GetStorageAreasForListDocument, options);
      }
export function useGetStorageAreasForListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStorageAreasForListQuery, GetStorageAreasForListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStorageAreasForListQuery, GetStorageAreasForListQueryVariables>(GetStorageAreasForListDocument, options);
        }
export type GetStorageAreasForListQueryHookResult = ReturnType<typeof useGetStorageAreasForListQuery>;
export type GetStorageAreasForListLazyQueryHookResult = ReturnType<typeof useGetStorageAreasForListLazyQuery>;
export type GetStorageAreasForListQueryResult = Apollo.QueryResult<GetStorageAreasForListQuery, GetStorageAreasForListQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
