import { Typography, Box } from "@mui/material";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { LeftTileWrapperSC, NoDataContainerSC } from "components/pages/AssetManagement/tileStyles";
import PLList from "components/patternLib/PLList";
import { useGetAssetAttributeValuesForTechnicalDataLazyQuery } from "graphqlBase/AssetAttributeValue/__generated__/getAssetAttributeValuesForTechnicalData";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import translations from "translations";
import { deviceIdState } from "../../../assetDetailState";
import DetailList, { ArrayValue } from "components/atomics/DetailList";

const TechnicalDataTile: React.FC<{}> = () => {
  const [technicalData, setTechnicalData] = useState<ArrayValue[] | undefined>();
  const deviceId = useRecoilValue(deviceIdState);

  const [query, { data, loading, error }] = useGetAssetAttributeValuesForTechnicalDataLazyQuery();

  useEffect(() => {
    query({
      variables: { where: { deviceId: { eq: deviceId } } },
    });
  }, [deviceId]);

  useEffect(() => {
    const attributeValues = data?.assetDevices[0]?.asset?.assetAttributeValue;
    if (attributeValues?.length) {
      const formattedData = attributeValues.reduce<ArrayValue[]>((formattedData, current) => {
        const label = current.assetAttribute?.attributeName ?? "";
        const value = current?.valueFloat;
        const unit = current.assetAttribute?.assetAttributeUnit?.assetAttributeUnitSymbol;

        if (!!label && !!value) {
          return formattedData.concat({
            label,
            value: `${value} ${unit ?? ""}`,
          });
        }
        return formattedData;
      }, []);

      setTechnicalData(formattedData);
    }
  }, [data]);

  const { label, noDataMessage } = translations.pages.assetManagement.assetDetails.technicalDataTile;

  return (
    <LeftTileWrapperSC sx={{ padding: "30px", width: "100%" }}>
      <Typography variant="h4">{label}</Typography>
      <LoadingSpinner sx={{ marginTop: "20px" }} loading={loading}>
        {!loading && (!!error || !data?.assetDevices[0]?.asset?.assetAttributeValue?.length) ? (
          <NoDataContainerSC>
            <Typography variant="copy">{noDataMessage}</Typography>
          </NoDataContainerSC>
        ) : (
          <Box>
            <DetailList itemProps={{ itemHeight: "46px" }} data={technicalData ?? []} />
          </Box>
        )}
      </LoadingSpinner>
    </LeftTileWrapperSC>
  );
};
export default TechnicalDataTile;
