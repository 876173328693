import { LhChange } from "@liebherr/patternlib";
import { styled } from "@material-ui/core";
import PLTexttoggle from "components/patternLib/form/PLTexttoggle";
import PLTexttoggleItem from "components/patternLib/form/PLTexttoggleItem";
import React from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { durationOptionForPositionHistoryAtom } from "./recoilState";

const { time: timeTexts, terms: termTexts } = translations.globals;
const durationOptions = [
  { value: "custom", label: termTexts.custom },
  { value: "0 hours", label: timeTexts.today },
  { value: "1 weeks", label: timeTexts.lastWeek },
  { value: "1 months", label: timeTexts.lastMonth },
];

const DateToggle: React.FC<{}> = () => {
  const [selectedValue, setSelectedValue] = useRecoilState(durationOptionForPositionHistoryAtom);

  const handleToggleItemChange = (event: CustomEvent<LhChange>) => {
    setSelectedValue(durationOptions[event.detail.value as number].value);
  };

  const optionsIndex = durationOptions.findIndex((item) => item.value === selectedValue);
  return (
    <PLTexttoggle value={`${optionsIndex}`} size="big" lhChange={handleToggleItemChange} key="device-details-tabs">
      {durationOptions.map(({ label }, index: number) => {
        return <PLTexttoggleItem key={`${label}-${index}`} label={label} value={`${index}`} />;
      })}
    </PLTexttoggle>
  );
};

export default DateToggle;
