import { LhChangeItem } from "@liebherr/patternlib/dist/types/utils/interfaces";
import Can from "authorization";
import { authorizedTabsArray } from "authorization/AuthorizedTabs/authorizedTabsArray";
import AuthorizedTabsCan from "authorization/AuthorizedTabs/AuthorizedTabsCan";
import ContentHeader from "components/organisms/ContentHeader";
import PLButton from "components/patternLib/PLButton";
import PLIcon from "components/patternLib/PLIcon";
import PLTabs from "components/patternLib/PLTabs";
import PLTabsItem from "components/patternLib/PLTabsItem";
import { TableWrapperSC } from "components/templates/dataGridTable/styles";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import translations from "translations";
import DeviceGroups from "./DeviceGroups";
import DeviceOverview from "./DeviceOverview";
import { currentDeviceTab } from "./deviceTabsState";

export const testIDs = getTestIDs();
const { myDevices, myDeviceGroups } = translations.pages.deviceManagement;

const DeviceManagement: React.FC<{}> = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useRecoilState(currentDeviceTab);
  const headerTabLabels = [
    { label: myDevices, scope: "MyDevicesUI" },
    { label: myDeviceGroups, scope: "MyDeviceGroupsUI" },
  ];
  const handleHeaderTabChange = (event: CustomEvent<LhChangeItem>) => {
    setCurrentTab(event.detail.itemId as number);
  };
  const HeaderTabs = (
    <PLTabs lhChangeItem={handleHeaderTabChange} key="device-management-tabs">
      {authorizedTabsArray(headerTabLabels).map(({ label }, index) => {
        return <PLTabsItem key={`${label}-tab-${index}`} active={currentTab === index} label={label} />;
      })}
    </PLTabs>
  );

  const handleCreateDeviceGroup = () => {
    history.push("/deviceManagement/createDeviceGroup");
  };
  return (
    <>
      <ContentHeader
        tabs={[HeaderTabs]}
        rightItems={
          currentTab === 1 ? (
            <Can I="read" a="CreateDeviceGroupUI">
              <PLButton
                onClick={handleCreateDeviceGroup}
                key="add-groups-button"
                iconPosition="left"
                label={translations.pages.groups.createGroup}
              >
                <PLIcon iconName="plus" slot="icon" />
              </PLButton>
            </Can>
          ) : undefined
        }
      />
      <TableWrapperSC>
        {currentTab === 0 && (
          <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
            <DeviceOverview />
          </AuthorizedTabsCan>
        )}
        {currentTab === 1 && (
          <AuthorizedTabsCan tabs={headerTabLabels} currentTab={currentTab}>
            <DeviceGroups />
          </AuthorizedTabsCan>
        )}
      </TableWrapperSC>
    </>
  );
};

export default DeviceManagement;
