import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMappingAssetTagsMutationVariables = Types.Exact<{
  createMappingAssetTags?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingAssetTagCreateType>> | Types.InputMaybe<Types.MappingAssetTagCreateType>>;
}>;


export type CreateMappingAssetTagsMutation = { createMappingAssetTags?: Maybe<Array<Maybe<{ __typename?: 'MappingAssetTag', id: string, assetTagId: string, assetId: string }>>> };


export const CreateMappingAssetTagsDocument = gql`
    mutation createMappingAssetTags($createMappingAssetTags: [MappingAssetTagCreateType]) {
  createMappingAssetTags(mappingAssetTags: $createMappingAssetTags) {
    id
    assetTagId
    assetId
  }
}
    `;
export type CreateMappingAssetTagsMutationFn = Apollo.MutationFunction<CreateMappingAssetTagsMutation, CreateMappingAssetTagsMutationVariables>;

/**
 * __useCreateMappingAssetTagsMutation__
 *
 * To run a mutation, you first call `useCreateMappingAssetTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMappingAssetTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMappingAssetTagsMutation, { data, loading, error }] = useCreateMappingAssetTagsMutation({
 *   variables: {
 *      createMappingAssetTags: // value for 'createMappingAssetTags'
 *   },
 * });
 */
export function useCreateMappingAssetTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMappingAssetTagsMutation, CreateMappingAssetTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMappingAssetTagsMutation, CreateMappingAssetTagsMutationVariables>(CreateMappingAssetTagsDocument, options);
      }
export type CreateMappingAssetTagsMutationHookResult = ReturnType<typeof useCreateMappingAssetTagsMutation>;
export type CreateMappingAssetTagsMutationResult = Apollo.MutationResult<CreateMappingAssetTagsMutation>;
export type CreateMappingAssetTagsMutationOptions = Apollo.BaseMutationOptions<CreateMappingAssetTagsMutation, CreateMappingAssetTagsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
