import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetMasterDatasForAssetAssignmentQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetMasterDataFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.AssetMasterDataSortInput> | Types.AssetMasterDataSortInput>;
}>;


export type GetAssetMasterDatasForAssetAssignmentQuery = { assetMasterDatas: Array<{ __typename?: 'AssetMasterData', id: string, articleNumber?: Maybe<string>, generalItem?: Maybe<string>, serialNumber?: Maybe<string>, supplier?: Maybe<string>, weight?: Maybe<number>, itemConstructionNumber?: Maybe<string>, asset: Array<{ __typename?: 'Asset', id: string }> }> };


export const GetAssetMasterDatasForAssetAssignmentDocument = gql`
    query getAssetMasterDatasForAssetAssignment($where: AssetMasterDataFilterInput, $take: Int, $skip: Int, $order: [AssetMasterDataSortInput!]) {
  assetMasterDatas(where: $where, take: $take, skip: $skip, order: $order) {
    id
    articleNumber
    generalItem
    serialNumber
    supplier
    weight
    itemConstructionNumber
    asset {
      id
    }
  }
}
    `;

/**
 * __useGetAssetMasterDatasForAssetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssetMasterDatasForAssetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetMasterDatasForAssetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetMasterDatasForAssetAssignmentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAssetMasterDatasForAssetAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetMasterDatasForAssetAssignmentQuery, GetAssetMasterDatasForAssetAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetMasterDatasForAssetAssignmentQuery, GetAssetMasterDatasForAssetAssignmentQueryVariables>(GetAssetMasterDatasForAssetAssignmentDocument, options);
      }
export function useGetAssetMasterDatasForAssetAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetMasterDatasForAssetAssignmentQuery, GetAssetMasterDatasForAssetAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetMasterDatasForAssetAssignmentQuery, GetAssetMasterDatasForAssetAssignmentQueryVariables>(GetAssetMasterDatasForAssetAssignmentDocument, options);
        }
export type GetAssetMasterDatasForAssetAssignmentQueryHookResult = ReturnType<typeof useGetAssetMasterDatasForAssetAssignmentQuery>;
export type GetAssetMasterDatasForAssetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssetMasterDatasForAssetAssignmentLazyQuery>;
export type GetAssetMasterDatasForAssetAssignmentQueryResult = Apollo.QueryResult<GetAssetMasterDatasForAssetAssignmentQuery, GetAssetMasterDatasForAssetAssignmentQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
