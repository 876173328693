import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { isButtonDisabled } from "./RecoilState/recoilState";

export const useDisableButton = (addedEntitiesLength: number, removedEntitiesLength: number) => {
  const [isDisabled, setIsDisabled] = useRecoilState(isButtonDisabled);

  useEffect(() => {
    addedEntitiesLength || removedEntitiesLength ? setIsDisabled(false) : setIsDisabled(true);
  }, [addedEntitiesLength, removedEntitiesLength, setIsDisabled]);
  return isDisabled;
};
