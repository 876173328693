import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDevicesInGeofencesQueryVariables = Types.Exact<{
  geofencesCoordinates?: Types.InputMaybe<Types.Scalars['Coordinates']>;
}>;


export type GetDevicesInGeofencesQuery = { queryDeviceWithinGeofence?: Maybe<{ __typename?: 'GeofenceDevices', geofenceDevices?: Maybe<Array<Maybe<{ __typename?: 'GeofenceDevice', measurementTimeUTC: string, id: string, device: { __typename?: 'Device', id: string, deviceName: string, serialNo: string, assetDevice?: Maybe<Array<Maybe<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string, assetMasterData?: Maybe<{ __typename?: 'AssetMasterData', id: string, generalItem?: Maybe<string>, serialNumber?: Maybe<string>, articleNumber?: Maybe<string>, supplier?: Maybe<string>, supplierNumber?: Maybe<string> }> } }>>> }, location?: Maybe<{ __typename?: 'GeoJSONPointType', coordinates?: Maybe<[number,number]> }> }>>> }> };


export const GetDevicesInGeofencesDocument = gql`
    query getDevicesInGeofences($geofencesCoordinates: Coordinates) {
  queryDeviceWithinGeofence(geofences: {type: MultiPolygon, coordinates: $geofencesCoordinates}) {
    geofenceDevices {
      id: deviceId
      device {
        id
        deviceName
        serialNo
        assetDevice {
          id
          asset {
            id
            assetMasterData {
              id
              generalItem
              serialNumber
              articleNumber
              supplier
              supplierNumber
            }
          }
        }
      }
      measurementTimeUTC
      location {
        coordinates
      }
    }
  }
}
    `;

/**
 * __useGetDevicesInGeofencesQuery__
 *
 * To run a query within a React component, call `useGetDevicesInGeofencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesInGeofencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesInGeofencesQuery({
 *   variables: {
 *      geofencesCoordinates: // value for 'geofencesCoordinates'
 *   },
 * });
 */
export function useGetDevicesInGeofencesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesInGeofencesQuery, GetDevicesInGeofencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesInGeofencesQuery, GetDevicesInGeofencesQueryVariables>(GetDevicesInGeofencesDocument, options);
      }
export function useGetDevicesInGeofencesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesInGeofencesQuery, GetDevicesInGeofencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesInGeofencesQuery, GetDevicesInGeofencesQueryVariables>(GetDevicesInGeofencesDocument, options);
        }
export type GetDevicesInGeofencesQueryHookResult = ReturnType<typeof useGetDevicesInGeofencesQuery>;
export type GetDevicesInGeofencesLazyQueryHookResult = ReturnType<typeof useGetDevicesInGeofencesLazyQuery>;
export type GetDevicesInGeofencesQueryResult = Apollo.QueryResult<GetDevicesInGeofencesQuery, GetDevicesInGeofencesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
