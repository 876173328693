/* eslint-disable filenames/match-exported */
import { ApolloClient, gql } from "@apollo/client";
import { useApolloClient } from "@apollo/client/react";
import React, { useCallback, useMemo, useState } from "react";
import { SetterOrUpdater, useRecoilState } from "recoil";
import getGqlQuery from "./middleware/rawQuerytoGqlQuery";
import { queryBuilderQueryStrings, queryExecutionResult } from "./middleware/recoilState";
import QueryBuilderComponentPre from "./components/QueryBuilderComponent";
import { FieldMiddleWares, QueryBuilderQueryStrings, QueryMiddleWares } from "./types";
interface QueryBuilderProps {
  entityName: string;
  fieldMiddleWares?: FieldMiddleWares;
  queryBuilderId: string;
  queryMiddleWares?: QueryMiddleWares;
  queryStringsReturnValue: string;
}

export interface QueryResults {
  data?: string[] | undefined;
  error?: boolean | undefined;
  loading?: boolean | undefined;
}

const buildAndExecute = async ({
  queryMiddleWares,
  setQueryStrings,
  queryStrings,
  client,
  entityName,
  setExecutionResult,
  queryStringsReturnValue,
}: {
  client: ApolloClient<object>;
  entityName: string;
  queryMiddleWares?: QueryMiddleWares;
  queryStrings: QueryBuilderQueryStrings | undefined;
  queryStringsReturnValue: string;
  setExecutionResult: SetterOrUpdater<QueryResults>;
  setQueryStrings: SetterOrUpdater<QueryBuilderQueryStrings | undefined>;
}) => {
  if (!queryStrings) return;
  const jSONQuery = await getGqlQuery({
    tree: queryStrings.rAWQuery,
    entityName,
    queryMiddleWares,
    client,
    queryStringsReturnValue,
  });

  const query = gql`
    ${jSONQuery}
  `;
  setExecutionResult({ data: [], loading: true, error: undefined });
  const { data, loading, error } = await client.query({ query, fetchPolicy: "network-only" });
  const parsedData = (data?.result ?? []).map((item: any) => `${item[queryStringsReturnValue] ?? item.id}`);
  setExecutionResult({ data: parsedData, loading, error: !!error });
  setQueryStrings((prev) => (prev ? { ...prev, jSONQuery } : undefined));
};

const useQueryBuilder = ({
  queryBuilderId,
  entityName,
  fieldMiddleWares,
  queryMiddleWares,
  queryStringsReturnValue,
}: QueryBuilderProps) => {
  const [queryStrings, setQueryStrings] = useRecoilState(queryBuilderQueryStrings(queryBuilderId));
  const [queryError, setQueryError] = useState<boolean | undefined>();
  const [executionResult, setExecutionResult] = useRecoilState(queryExecutionResult);

  const client = useApolloClient();

  const executeQuery = useCallback(() => {
    setExecutionResult({ loading: true });

    buildAndExecute({
      setQueryStrings,
      client,
      entityName,
      queryStrings,
      setExecutionResult,
      queryMiddleWares,
      queryStringsReturnValue,
    }).catch((e) => {
      setQueryError(true);
      setExecutionResult({ data: undefined, loading: undefined, error: e.message });
    });
  }, [client, entityName, queryStrings, setExecutionResult, setQueryStrings]);

  const clearQueryStates = () => {
    setExecutionResult({ data: undefined, loading: undefined, error: undefined });
    setQueryStrings(undefined);
  };

  const queryStringsOut = useMemo(() => {
    const rAWQuery = queryStrings?.rAWQuery ? JSON.stringify(queryStrings?.rAWQuery) : "";
    const jSONQuery = queryStrings?.jSONQuery ?? "";

    return { rAWQuery, jSONQuery, error: queryError };
  }, [queryStrings, queryError]);

  const setQueryStringsExposed = useMemo(
    () => ({ rAWQuery, jSONQuery }: { jSONQuery: string; rAWQuery: string }) => {
      try {
        setQueryStrings({ jSONQuery, rAWQuery: JSON.parse(rAWQuery), issuer: "user" });
        setQueryError(false);
      } catch (e) {
        setQueryError(true);
      }
    },
    []
  );
  const QueryBuilderComponent = useMemo(() => {
    return () => (
      <QueryBuilderComponentPre
        entityName={entityName}
        queryBuilderId={queryBuilderId}
        fieldMiddleWares={fieldMiddleWares}
      />
    );
  }, [entityName, fieldMiddleWares, queryBuilderId]);
  return {
    queryStrings: queryStringsOut,
    setQueryStrings: setQueryStringsExposed,
    executeQuery,
    QueryBuilderComponent,
    clearQueryStates,
    executionResult,
  };
};

export default useQueryBuilder;
