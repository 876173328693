import { SxProps, Theme, Typography } from "@mui/material";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import PLPill from "components/patternLib/PLPill";
import React from "react";
import {
  inputLabelStyles,
  autocompleteRootStyles,
  inputStyles,
  InputWrapperSC,
  InputLabelWrapperSC,
  InputChipWrapperSC,
} from "./styles";
export interface SelectItem {
  label: string;
  value: string;
}

interface MultiSelectFreeSoloProps {
  customSx?: SxProps<Theme>;
  disabled?: boolean;
  filterOptions?: (options: SelectItem[], state: object) => SelectItem[];
  inputValue?: string;
  isStatusError?: boolean;
  label?: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: (string | SelectItem)[],
    reason: AutocompleteChangeReason
  ) => void;
  onChangeInput?: OutlinedTextFieldProps["onChange"];
  options: SelectItem[];
  placeholder?: string;
  selected?: SelectItem[];
}

const MultiSelectFreeSolo: React.FC<MultiSelectFreeSoloProps> = ({
  options,
  onChange,
  placeholder,
  disabled,
  selected = [],
  label,
  onChangeInput,
  inputValue,
  filterOptions,
  customSx,
  isStatusError,
}) => {
  return (
    <InputWrapperSC sx={customSx}>
      {label && (
        <InputLabelWrapperSC>
          <Typography style={inputLabelStyles} variant="copy">
            {label}
          </Typography>
        </InputLabelWrapperSC>
      )}
      <Autocomplete
        sx={{ ...autocompleteRootStyles(isStatusError), ...customSx }}
        multiple
        id="tags-filled"
        options={options}
        value={selected}
        placeholder={placeholder}
        filterOptions={filterOptions}
        freeSolo
        getOptionLabel={(option: SelectItem | string) => {
          if (typeof option === "string") {
            return option;
          } else {
            return option.label;
          }
        }}
        disabled={disabled}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} onChange={onChangeInput} value={inputValue} sx={inputStyles} variant="outlined" />
        )}
        renderTags={(value: Array<SelectItem | string>, getTagProps) => {
          return selected.map((optionIn: SelectItem | string, index: number) => {
            const option = typeof optionIn === "string" ? optionIn : optionIn?.label;
            return (
              <InputChipWrapperSC key={option}>
                <PLPill
                  disabled={disabled}
                  label={option}
                  lhClose={getTagProps({ index }).onDelete}
                  deleteIcon
                  height="25px"
                />
              </InputChipWrapperSC>
            );
          });
        }}
      />
    </InputWrapperSC>
  );
};

export default MultiSelectFreeSolo;
