import { getGridStringOperators, GridValidRowModel } from "@mui/x-data-grid-pro";
import Toast from "components/atomics/Toast";
import { toastStateAtom } from "components/atomics/Toast/toastState";
import useDataGrid, { useFilterTableSelectedRows } from "components/templates/dataGridTable";
import { IconTextCell } from "components/templates/dataGridTable/Cells";
import { genericDeleteParams } from "components/templates/table-factory/Table/Cells/actionsCellFactory/recoilStates";
import { globalErrorUIState } from "globalErrorhandling/globalErrorState";
import {
  GetNotificationsQuery,
  useGetNotificationsLazyQuery,
  ResultType,
} from "graphqlBase/Notifications/__generated__/getNotifications";
import getTestIDs from "lib/utils/getTestIDs";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import { formatDateTime } from "translations/formatter";
import { selectedNotificationsAtom } from "./recoilState";

const { noAlertsMessage, deleteAlert, deleteAlertMessage, alerts } = translations.pages.alertManagement;
export const testIDs = getTestIDs();

const TODAY_DATE = new Date().toISOString();

export type Notification = ResultType<GetNotificationsQuery["notifications"]>;

interface SelectedNotification extends Notification {
  toDelete: boolean;
}

const { yes, no, never } = translations.globals.terms;

const AlertOverview: React.FC<{}> = () => {
  const [toastObject, setToastObject] = useRecoilState(toastStateAtom);
  const [queryOuter, { data, loading, error, refetch }] = useGetNotificationsLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const setDeleteParams = useSetRecoilState(genericDeleteParams);
  const history = useHistory();

  const {
    query,
    selectedIds,
    setSelectedIds,
    setSelectedRowsFilterOn,
    selectedRowsFilterOn,
  } = useFilterTableSelectedRows({
    queryOuter,
    atom: selectedNotificationsAtom,
    getIdsFromAtom: (rows) => rows.map((row) => row.id),
    entity: "notification",
  });

  const { DataGrid, useMakeColumns, makeSeverSideFilter, getRowCount, apiRef } = useDataGrid<
    Notification,
    "Notification"
  >({
    query,
    variables: {
      where: { notificationDefinition: { some: { isRemoved: { neq: true } } } },
    },
    tableId: "AlertOverview",
    persistance: "runTime",
  });

  const columns = useMakeColumns(
    [
      {
        field: "notificationText",
        headerName: translations.entities.notification.columns.notificationText,
        renderHeader: () => `Alert name (${getRowCount()})`,
        flex: 2,
        valueGetter: (params) => {
          return params.row?.notificationText ?? "";
        },
        remoteOrder: (sort) => ({ notificationText: sort }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            notificationText: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "scopeName",
        headerName: `Warning Area`,
        flex: 1.5,
        remoteOrder: (sort) => ({
          notificationScope: { scopeName: sort },
        }),
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            notificationScope: { scopeName: filterValue },
          }),
        }),
        valueGetter: (params) => params.row?.notificationScope?.scopeName?.replace("Scope", "") ?? "",
        type: "string",
      },
      {
        field: "createdAt",
        headerName: "Date of creation",
        flex: 2,
        remoteOrder: (sort) => ({ createdAt: sort }),
        valueGetter: (params) => {
          const isoDate = new Date(params.row?.createdAt ?? TODAY_DATE);
          return `${formatDateTime(isoDate)}`;
        },
        remoteFilter: makeSeverSideFilter("dateTime", {
          filterPath: ({ where, filterValue }) => ({
            createdAt: filterValue,
          }),
        }),
        type: "dateTime",
      },
      {
        field: "isActive",
        headerName: "Active",
        flex: 1,
        headerAlign: "left",
        align: "left",
        valueGetter: (params) => {
          return params.row?.isActive ?? false;
        },
        renderCell: (params) => {
          const active = params.row?.isActive;
          const color = active ? "var(--color-success)" : "var(--color-warning)";
          const iconName = active ? "checkmark" : "x";
          return (
            <IconTextCell width="30%" text={active ? yes : no} color={color} iconName={iconName} iconColor={color} />
          );
        },
        remoteFilter: makeSeverSideFilter("boolean", {
          filterOperators: getGridStringOperators(),
          filterPath: ({ where, filterValue }) => ({
            isActive: filterValue,
          }),
        }),
        type: "ReactElement",
        valueType: "boolean",
      },
      {
        field: "firedCount",
        headerName: "Fired",
        flex: 1,
        valueGetter: (params) => {
          let deviceCount: number = 0;
          params.row?.notificationDefinition?.forEach((notificationDef) => {
            deviceCount = Array.from(notificationDef?.deviceNotification ?? [])?.length;
          });

          const countText = deviceCount < 1 ? "" : deviceCount === 1 ? "time" : "times";
          return `${deviceCount > 0 ? deviceCount : never} ${countText}`;
        },
        type: "string",
      },
    ],
    [refetch]
  );
  const handleCloseToast = () => {
    setToastObject(undefined);
  };
  const afterDelete = () => {
    if (refetch)
      refetch().catch(() => {
        return null;
      });
  };

  const handleDeleteRows = (ids: string[]) => {
    setDeleteParams({
      entity: "Notifications",
      id: ids,
      modalTitle: deleteAlert,
      modalMessage: deleteAlertMessage,
      afterDelete,
    });
  };

  const handleEditRow = (id: string) => {
    setToastObject(undefined);
    history.push(`/alertManagement/alertEditing/${id}`);
  };
  const handleEditInfo = {
    handleEditRow: handleEditRow,
    editScope: "UpdateAlertUI",
  };

  const handleDeleteInfo = {
    handleDeleteRows,
    deleteScope: "DeleteAlertUI",
  };

  const selectedRows = useMemo(
    () =>
      selectedIds?.reduce<string[]>((notificationIds, element) => {
        return notificationIds.concat(element.id);
      }, []),
    [selectedIds]
  );

  const handleSelectRow = (rows: readonly GridValidRowModel[]) => {
    setSelectedIds((selectedAssets) => {
      const notificationIDsOnUi = apiRef.current.getAllRowIds ? apiRef.current.getAllRowIds() : [];
      const chekedIds = rows.map(({ id }) => id) as string[];
      const SelectedUsersNextPre = selectedAssets.reduce<SelectedNotification[]>((acc, curr) => {
        if (!notificationIDsOnUi.includes(curr.id)) return acc.concat(curr as SelectedNotification);
        if (chekedIds.includes(curr.id)) {
          return acc.concat({ ...curr, toDelete: false });
        } else {
          return acc.concat({ ...curr, toDelete: true });
        }
      }, []);
      return rows
        .reduce<SelectedNotification[]>((acc, curr) => {
          if (acc.find((e) => e.id === curr.id)) return acc;

          return acc.concat(curr as SelectedNotification);
        }, SelectedUsersNextPre)
        .filter((item) => !item.toDelete);
    });
  };

  return (
    <>
      {!error && (
        <DataGrid
          noDataMessage={noAlertsMessage}
          tableTitle={alerts}
          tableHeight="65vh"
          columns={columns}
          rows={data?.notifications}
          selectedRowsFilterOn={selectedRowsFilterOn}
          setSelectedRowsFilterOn={setSelectedRowsFilterOn}
          loading={loading}
          handleDelete={handleDeleteInfo}
          handleEdit={handleEditInfo}
          selectedRows={selectedRows}
          setSelectedRows={handleSelectRow}
        />
      )}

      {toastObject && (
        <Toast
          message={toastObject.message}
          open={toastObject.open}
          severity={toastObject.severity}
          handleCloseToast={handleCloseToast}
        />
      )}
    </>
  );
};

export default AlertOverview;
