import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetAttributeValuesForTechnicalDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetDeviceFilterInput>;
}>;


export type GetAssetAttributeValuesForTechnicalDataQuery = { assetDevices: Array<{ __typename?: 'AssetDevice', id: string, asset: { __typename?: 'Asset', id: string, assetAttributeValue: Array<{ __typename?: 'AssetAttributeValue', id: string, valueFloat?: Maybe<number>, assetAttribute: { __typename?: 'AssetAttribute', id: string, attributeName: string, assetAttributeUnit?: Maybe<{ __typename?: 'AssetAttributeUnit', id: string, assetAttributeUnitSymbol?: Maybe<string> }> } }> } }> };


export const GetAssetAttributeValuesForTechnicalDataDocument = gql`
    query getAssetAttributeValuesForTechnicalData($where: AssetDeviceFilterInput) {
  assetDevices(where: $where) {
    id
    asset {
      id
      assetAttributeValue {
        id
        valueFloat
        assetAttribute {
          id
          attributeName
          assetAttributeUnit {
            id
            assetAttributeUnitSymbol
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssetAttributeValuesForTechnicalDataQuery__
 *
 * To run a query within a React component, call `useGetAssetAttributeValuesForTechnicalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetAttributeValuesForTechnicalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetAttributeValuesForTechnicalDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAssetAttributeValuesForTechnicalDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssetAttributeValuesForTechnicalDataQuery, GetAssetAttributeValuesForTechnicalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAssetAttributeValuesForTechnicalDataQuery, GetAssetAttributeValuesForTechnicalDataQueryVariables>(GetAssetAttributeValuesForTechnicalDataDocument, options);
      }
export function useGetAssetAttributeValuesForTechnicalDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssetAttributeValuesForTechnicalDataQuery, GetAssetAttributeValuesForTechnicalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAssetAttributeValuesForTechnicalDataQuery, GetAssetAttributeValuesForTechnicalDataQueryVariables>(GetAssetAttributeValuesForTechnicalDataDocument, options);
        }
export type GetAssetAttributeValuesForTechnicalDataQueryHookResult = ReturnType<typeof useGetAssetAttributeValuesForTechnicalDataQuery>;
export type GetAssetAttributeValuesForTechnicalDataLazyQueryHookResult = ReturnType<typeof useGetAssetAttributeValuesForTechnicalDataLazyQuery>;
export type GetAssetAttributeValuesForTechnicalDataQueryResult = Apollo.QueryResult<GetAssetAttributeValuesForTechnicalDataQuery, GetAssetAttributeValuesForTechnicalDataQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
