import translation from "@ml-pa/react-apollo-form-tool/formElements/components/editor/wysiwyg/translations";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import Toast from "components/atomics/Toast";
import { toastStateAtom } from "components/atomics/Toast/toastState";
import getTestIDs from "lib/utils/getTestIDs";
import { useAuth } from "oidc-react";
import {
  NotificationCreateForm,
  NotificationCreateOnUpdateDataMiddleWareProps,
  NotificationUpdateForm,
  NotificationUpdateOnUpdateDataMiddleWareProps,
} from "raft/NotificationForm";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import translations from "translations";
import usePersistNestedData from "./hooks/usePersistNestedData";
import usePersistNotificationDefinitions from "./hooks/usePersistNotificationDefinitions";
import {
  alertFormLoadingAtom,
  hasFormErrorsAtom,
  isFormSubmittedAtom,
  notificationDefinitionsAtom,
  preMappedDeviceNotificationsAtom,
  prevNotificationDefinitionsAtom,
  selectedAssetsForAlertAtom,
} from "./recoilState";
import {
  preMappedDeviceNotificationsForGateWayAtom,
  selectedDevicesForAlertAtom,
} from "./recoilState/selectedDevicesForAlertState";
import { preMappedUserNotificationsAtom, selectedUsersForAlertAtom } from "./recoilState/selectedUsersForAlertState";

export const testIDs = getTestIDs();
const userId = "8d19b369f0b94e59a3faf620080f4498";

const AlertEditing: React.FC<{}> = () => {
  const auth = useAuth();
  const history = useHistory();
  const [toastObject, setToastObject] = useRecoilState(toastStateAtom);
  const { notificationId }: { notificationId?: string } = useParams();
  const persistNestedData = usePersistNestedData();
  const persistNotificationDefinitions = usePersistNotificationDefinitions();
  const resetNotificationDefinitions = useResetRecoilState(notificationDefinitionsAtom);
  const resetPrevNotificationDefinitions = useResetRecoilState(prevNotificationDefinitionsAtom);
  const resetHasFormErrors = useResetRecoilState(hasFormErrorsAtom);
  const resetSelectedAssetsState = useResetRecoilState(selectedAssetsForAlertAtom);
  const resetPreSelectedAssetsState = useResetRecoilState(preMappedDeviceNotificationsAtom);
  const resetSelectedDevicesState = useResetRecoilState(selectedDevicesForAlertAtom);
  const resetPreSelectedDevicesState = useResetRecoilState(preMappedDeviceNotificationsForGateWayAtom);
  const resetSelectedUsersState = useResetRecoilState(selectedUsersForAlertAtom);
  const resetPreSelectedUsersState = useResetRecoilState(preMappedUserNotificationsAtom);
  const resetFormSubmitted = useResetRecoilState(isFormSubmittedAtom);

  const [formLoading, setFormLoading] = useRecoilState(alertFormLoadingAtom);

  useEffect(() => {
    return () => clearStates();
  }, []);

  const clearStates = () => {
    setFormLoading(false);
    resetHasFormErrors();
    resetNotificationDefinitions();
    resetSelectedAssetsState();
    resetPreSelectedAssetsState();
    resetSelectedUsersState();
    resetPreSelectedUsersState();
    resetPrevNotificationDefinitions();
    resetFormSubmitted();
    resetSelectedDevicesState();
    resetPreSelectedDevicesState();
  };

  const handleCancel = () => {
    history.push("/alertManagement");
    clearStates();
  };

  if (!auth?.userData?.profile.email ?? null) return null;

  const persistData = async (notificationId: string) => {
    await persistNotificationDefinitions(notificationId);
    await persistNestedData(notificationId);
  };

  const onUpdateDataMiddleWareNotificationUpdate = ({ data }: NotificationUpdateOnUpdateDataMiddleWareProps) => {
      return {
        isActive: data.isActive ?? true,
        recipientEmail: data.recipientEmail ?? auth?.userData?.profile.email ?? "",
      };
    },
    onUpdateMiddleWareNotificationCreate = ({ data }: NotificationCreateOnUpdateDataMiddleWareProps) => {
      return {
        step: data.step ?? 0,
        isActive: data.isActive ?? true,
        recipientEmail: data.recipientEmail ?? auth?.userData?.profile.email ?? "",
        createdBy: userId,
      };
    },
    afterSave = async (data: { id: string }) => {
      try {
        await persistData(data.id);
        setToastObject({
          open: true,
          message: notificationId
            ? translations.pages.alertManagement.toastMessageUpdate.success
            : translations.pages.alertManagement.toastMessageCreate.success,
          severity: "success",
        });
        handleCancel();
      } catch (error) {
        console.error(error);
        setToastObject({
          open: true,
          message: notificationId
            ? translations.pages.alertManagement.toastMessageUpdate.error
            : translations.pages.alertManagement.toastMessageCreate.error,
          severity: "error",
        });
      } finally {
        handleCancel();
      }
    };
  const handleCloseToast = () => {
    setToastObject(undefined);
    location.reload();
  };
  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <LoadingSpinner loading={formLoading ?? false}>
          {notificationId && (
            <NotificationUpdateForm
              id={notificationId}
              onUpdateDataMiddleWare={onUpdateDataMiddleWareNotificationUpdate}
              onCancel={handleCancel}
              afterUpdate={afterSave}
            />
          )}
          {!notificationId && (
            <NotificationCreateForm
              onUpdateDataMiddleWare={onUpdateMiddleWareNotificationCreate}
              onCancel={handleCancel}
              afterCreate={afterSave}
            />
          )}
        </LoadingSpinner>
        {toastObject && (
          <Toast
            message={toastObject.message}
            open={toastObject.open}
            severity={toastObject.severity}
            handleCloseToast={handleCloseToast}
          />
        )}
      </div>
    </>
  );
};
export default AlertEditing;
