import { styled, Theme } from "@mui/material";
import * as L from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import React, { useEffect, useState } from "react";
import { FeatureGroup, MapContainer, TileLayer, useMap, Polygon } from "react-leaflet";
import "./editGeofence.css";
import { useSearchControl } from "components/pages/MapAndTable/utils";
import Toast from "components/atomics/Toast";
import translations from "translations";

const MapContainerSC = styled(MapContainer)({
  height: "80vh",
  width: "65vw",
  zIndex: 0,
  [`& .leaflet-control-attribution `]: {
    display: "none",
  },
  [` .leaflet-draw-actions li:first-child a`]: {
    display: "none",
  },
});

const drawnItems = L.featureGroup([]);
const drawControl = new L.Control.Draw({
  edit: {
    featureGroup: drawnItems,
  },
  draw: { rectangle: false, circle: false, polyline: false, circlemarker: false, marker: false },
  position: "topright",
});

interface EditGeofenceInnerProps {
  polygon?: L.LatLngExpression[];
  setPolygon: (polygon?: L.LatLngExpression[]) => void;
  setOpenToast:(arg:boolean)=>void
}

function isClockwise(poly: [number, number][]) {
  let sum = 0;
  for (let i = 0; i < poly.length - 1; i++) {
    const cur = poly[i],
      next = poly[i + 1];
    sum += (next[0] - cur[0]) * (next[1] + cur[1]);
  }
  return sum > 0;
}

export const EditGeofenceInner: React.FC<EditGeofenceInnerProps> = ({ polygon, setPolygon,setOpenToast }) => {
  const map = useMap();
  useSearchControl({ map, style: "bar" });
  const [isMapDragged,setIsMapDragged] = useState<boolean>(false)
  const bounds = L.latLngBounds([]);
  useEffect(() => {
    map.on("dragstart", function (event) {
      setIsMapDragged(true)
    });
    if (polygon?.length && !isMapDragged) {
      (polygon ?? []).forEach((coord) => {
        if (coord) bounds.extend(coord);
      });

      setTimeout(() => map.fitBounds(bounds).getBounds().getCenter(), 50);
    }
  }, [polygon, bounds,isMapDragged]);
  const savePolygon = (layer: L.Polygon<any>) => {
    const poly = layer
      .toGeoJSON()
      //@ts-ignore
      .geometry.coordinates.map((coo) => coo.map((po) => [po[1], po[0]]))[0] as LatLngExpression[];

    const polyToSave = !isClockwise(poly) ? poly : poly.reverse();
    // @ts-ignore
    setPolygon([polyToSave]);
  };
  useEffect(() => {
    drawnItems.addTo(map);
    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, function (event) {
      const layer = event.layer;
      if (layer instanceof L.Polygon) {
        savePolygon(layer);
      }
      drawnItems.clearLayers();
    });
    map.on("mouseout", function (event) {
      drawnItems.eachLayer((layer) => {
        if (layer instanceof L.Polygon) {
          const alowedPolygonSize = 455;
          const polygon = L.rectangle(layer
            .toGeoJSON()
            //@ts-ignore
            .geometry.coordinates.map((coo) => coo.map((po) => [po[1], po[0]]))[0] as LatLngExpression[]);
          const polygonSize = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0] as L.LatLngLiteral[]) * 0.005;
          if (polygonSize < alowedPolygonSize) {
             setOpenToast(true);
          }
          savePolygon(layer);
        }
      });
    });
    map.on(L.Draw.Event.DELETED, function (event) {
      setPolygon(undefined);
    });
    map.invalidateSize();
    return () => {
      map.off(L.Draw.Event.CREATED);
      map.off(L.Draw.Event.EDITED);
    };
  }, [map]);

  useEffect(() => {
    return () => {
      drawnItems.clearLayers();
    };
  }, []);

  useEffect(() => {
    if (polygon) {
      const polygon1 = L.polygon(polygon);
      drawnItems.clearLayers();
      drawnItems.addLayer(polygon1).addEventListener("click", (d) => {
        // @ts-ignore

        drawControl._toolbars.edit._modes.edit.handler.enable();
      });

      setTimeout(() => {
        // @ts-ignore

        drawControl._toolbars.edit._modes.edit.handler.enable();
        // @ts-ignore

        drawControl._toolbars.draw._modes.polygon.handler.disable();
      }, 200);
    }
  }, [polygon, map]);

  return null;
};
interface EditGeofenceProps extends EditGeofenceInnerProps {
  disabled: boolean;
  initalPosition: {
    lat: number;
    lng: number;
  };
  testId?: string;
  zoom: number;
}
const EditGeofence: React.FC<Omit<EditGeofenceProps,"setOpenToast">> = ({ zoom, initalPosition, polygon, setPolygon, disabled, testId }) => {
  const [openToast, setOpenToast] = useState(false);
  const handleCloseToast = () => {
    setOpenToast(false);
  };
  if (disabled)
    return (
      <MapContainerSC
        id="EditGeofenceMapDisabled"
        center={initalPosition}
        zoom={zoom}
        scrollWheelZoom
        data-testid={testId}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FeatureGroup>
          <Polygon
            eventHandlers={{
              add: (e) => {
                const map: L.Map = e.target._map;
                const bounds: L.LatLngBounds = e.sourceTarget._bounds;
                map.invalidateSize();
                if (map && bounds.isValid()) map.setView(bounds.getCenter()).fitBounds(bounds);
              },
            }}
            key="editorPolygon"
            attribution=""
            positions={polygon ?? []}
          />
        </FeatureGroup>
      </MapContainerSC>
    );
  return (
    <>
    <MapContainerSC id="EditGeofenceMap" center={initalPosition} zoom={zoom} scrollWheelZoom data-testid={testId}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FeatureGroup>
        <EditGeofenceInner polygon={polygon} setPolygon={setPolygon} setOpenToast={setOpenToast}/>
      </FeatureGroup>
      
    </MapContainerSC>
    <Toast
        open={openToast}
        message={translations.pages.fleetInventory.smallGeofenceWarning}
        handleCloseToast={handleCloseToast}
        severity="error"
      />
    </>
  );
};

export default EditGeofence;
