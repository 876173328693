import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SensorMeasurementHistoryFilterInput>;
  order?: Types.InputMaybe<Array<Types.SensorMeasurementHistorySortInput> | Types.SensorMeasurementHistorySortInput>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetDeviceStateSensorMeasurementsForAssetStateHistoryQuery = { sensorMeasurementHistories: Array<{ __typename?: 'SensorMeasurementHistory', id: string, valueInt?: Maybe<number>, utcTimeMeasured: string, deviceId: string }> };


export const GetDeviceStateSensorMeasurementsForAssetStateHistoryDocument = gql`
    query getDeviceStateSensorMeasurementsForAssetStateHistory($where: SensorMeasurementHistoryFilterInput, $order: [SensorMeasurementHistorySortInput!], $skip: Int, $take: Int) {
  sensorMeasurementHistories(where: $where, take: $take, skip: $skip, order: $order) {
    id
    valueInt
    utcTimeMeasured
    deviceId
  }
}
    `;

/**
 * __useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery__
 *
 * To run a query within a React component, call `useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceStateSensorMeasurementsForAssetStateHistoryQuery, GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDeviceStateSensorMeasurementsForAssetStateHistoryQuery, GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables>(GetDeviceStateSensorMeasurementsForAssetStateHistoryDocument, options);
      }
export function useGetDeviceStateSensorMeasurementsForAssetStateHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeviceStateSensorMeasurementsForAssetStateHistoryQuery, GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDeviceStateSensorMeasurementsForAssetStateHistoryQuery, GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables>(GetDeviceStateSensorMeasurementsForAssetStateHistoryDocument, options);
        }
export type GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryHookResult = ReturnType<typeof useGetDeviceStateSensorMeasurementsForAssetStateHistoryQuery>;
export type GetDeviceStateSensorMeasurementsForAssetStateHistoryLazyQueryHookResult = ReturnType<typeof useGetDeviceStateSensorMeasurementsForAssetStateHistoryLazyQuery>;
export type GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryResult = Apollo.QueryResult<GetDeviceStateSensorMeasurementsForAssetStateHistoryQuery, GetDeviceStateSensorMeasurementsForAssetStateHistoryQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
