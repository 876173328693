export default {
  name: "User",
  plural: "Users",
  columns: {
    id: "ID",
    userName: "Username",
    firstName: "First Name",
    lastName: "Last Name",
    authenticationProvider: "Authentication Provider",
    email: "Email",
    language: "Language",
    userDescription: "User Description",
    lastLogin: "Last Login",
    createdAt: "Created at",
    modifiedAt: "Modified at",
    isDeleted: "is deleted",
  },
};
