import L, { LatLng, LatLngExpression } from "leaflet";
import React, { useMemo, useState } from "react";
import { FeatureGroup, Marker, Polygon, Popup, useMap, useMapEvents } from "react-leaflet";
import { filterEmptyCoords, useSearchControl } from "../utils";
import { DeviceInGeofence } from "..";
import { Box, Divider, Typography } from "@mui/material";
import styled from "@emotion/styled";
import translations from "translations";
import clusterPinMarker from "components/atomics/markers/clusterPinMarker";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { StorageArea } from ".";
import { formatDate, formatTimeDistance } from "translations/formatter";
import PLTextlink from "components/patternLib/PLTextlink";
import { useHistory } from "react-router-dom";
interface StorageAreasProps {
  devicesByStorageAreaId: { [key: string]: DeviceInGeofence[] };
  storageAreas: StorageArea[];
}

const lbcMarkerIcon = new L.Icon({
  iconUrl:
    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA0NCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF83NDk5XzQzMzM1KSI+CjxwYXRoIGQ9Ik00MCAxOS43MkM0MCAzMC4wNTg4IDI0IDUzIDIyIDUzQzIwIDUzIDQgMzAuMDU4OCA0IDE5LjcyQzQgOS4zODEyMyAxMi4wNTg5IDEgMjIgMUMzMS45NDExIDEgNDAgOS4zODEyMyA0MCAxOS43MloiIGZpbGw9IiNGRkQwMDAiLz4KPGNpcmNsZSBjeD0iMjIiIGN5PSIyMSIgcj0iOCIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9kXzc0OTlfNDMzMzUiIHg9IjAiIHk9IjAiIHdpZHRoPSI0NCIgaGVpZ2h0PSI2MCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0IGR5PSIzIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMC4yMjc0NTEgMCAwIDAgMCAwLjI1MDk4IDAgMCAwIDAgMC4yNzQ1MSAwIDAgMCAwLjMgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd183NDk5XzQzMzM1Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93Xzc0OTlfNDMzMzUiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cgo=",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const PolygonSC = styled(Polygon)(({ props }: { props: { color: string } }) => {
  return {
    fill: props.color,
    strokeWidth: 0,
    fillOpacity: "40%",
  };
});

const StorageAreas: React.FC<StorageAreasProps> = ({ storageAreas, devicesByStorageAreaId }) => {
  const [halfPercOfmapAcreage, setHalfPercOfMapAcreage] = useState<number>(0);
  const map = useMap();
  const history = useHistory();

  useSearchControl({ map, style: "bar" });
  const MapAcreageObserver = () => {
    useMapEvents({
      moveend: ({ target }) => {
        const bounds1 = target.getBounds();
        const latlngs: L.LatLngBoundsExpression = [
          [bounds1._northEast.lat, bounds1._northEast.lng],
          [bounds1._southWest.lat, bounds1._southWest.lng],
        ];
        const polygon = L.rectangle(latlngs);
        setHalfPercOfMapAcreage(L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0] as L.LatLngLiteral[]) * 0.005);
      },
    });
    return null;
  };
  const polygonCalculations = useMemo(() => {
    return storageAreas.reduce<{ areasAcreages: number[]; polygonsCenter: LatLng[] }>(
      (acc, storageArea) => {
        const polygon = L.polygon(storageArea?.geofence?.coordinates as L.LatLngExpression[][]);
        const center = polygon.getBounds().getCenter();
        const areasAcreage = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0] as L.LatLngLiteral[]);
        acc.areasAcreages.push(areasAcreage);
        acc.polygonsCenter.push(center);
        return acc;
      },
      { areasAcreages: [], polygonsCenter: [] }
    );
  }, [storageAreas]);

  const { areasAcreages, polygonsCenter } = polygonCalculations;

  return (
    <>
      <MapAcreageObserver />
      <FeatureGroup>
        {storageAreas.map((storageArea, index) => {
          const isStorageAreaAcreageHalfPercOfTotal = areasAcreages[index] < halfPercOfmapAcreage;
          const geofencesFirstPoint = storageArea?.geofence?.coordinates ? storageArea?.geofence?.coordinates[0] : null;
          const firstPoint = geofencesFirstPoint ? geofencesFirstPoint[0] : null;
          const devices = devicesByStorageAreaId[storageArea.id];
          const assetsInGeofence = devices ? devices.length : 0;
          const markersContentLength = storageArea.storageAreaName.length + assetsInGeofence.toString().length + 4;
          if (!firstPoint) return;
          const zoomedOutStorageAreaLabel = L.divIcon({
            html: `${storageArea.storageAreaName} (${assetsInGeofence})`,
            iconSize: [markersContentLength < 8 ? markersContentLength * 12 : markersContentLength * 8, 30],
            iconAnchor: [markersContentLength < 8 ? markersContentLength * 12 : markersContentLength * 8, 30],
            className: `markerIcon`,
          });
          const zoomedInstorageAreaLabel = L.divIcon({
            html: `${storageArea.storageAreaName}`,
            className: `zoomedInstorageAreaLabel`,
          });
          return isStorageAreaAcreageHalfPercOfTotal ? (
            <React.Fragment key={storageArea.id}>
              <Marker icon={zoomedOutStorageAreaLabel} position={polygonsCenter[index] as LatLngExpression} />
            </React.Fragment>
          ) : (
            <React.Fragment key={storageArea.id}>
              <PolygonSC
                props={{ color: storageArea.storageAreaType.storageAreaTypeColor as string }}
                attribution={storageArea.storageAreaName}
                positions={filterEmptyCoords(storageArea.geofence?.coordinates ?? [])}
              >
                <Marker position={polygonsCenter[index] as LatLngExpression} icon={zoomedInstorageAreaLabel} />
              </PolygonSC>
              <MarkerClusterGroup iconCreateFunction={(cluster: any) => clusterPinMarker(cluster)}>
                {devices &&
                  devices.map((el) => {
                    return (
                      <Marker
                        key={el.device.id}
                        icon={lbcMarkerIcon}
                        position={el.location?.coordinates as LatLngExpression}
                      >
                        {el.device.assetDevice ? (
                          <Popup>
                            <Box sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
                              <PLTextlink
                                bold={false}
                                label={el.device.assetDevice?.[0]?.asset.assetMasterData?.generalItem ?? "" ?? ""}
                                onClick={() =>
                                  history.push(`/assetManagement/assetDetails/${el.device.assetDevice?.[0]?.asset.id}`)
                                }
                              />
                              <Typography variant="copy">
                                {translations.entities.assetType.columns.manufacturer} -{" "}
                                {el.device.assetDevice[0]?.asset.assetMasterData?.supplier ?? ""}
                              </Typography>
                              <Typography variant="copy">
                                {translations.entities.assetType.columns.articleNo} -{" "}
                                {el.device.assetDevice[0]?.asset.assetMasterData?.articleNumber ?? ""}
                              </Typography>
                              <Typography variant="copy">
                                {translations.globals.time.measurementDate} -{" "}
                                {formatDate(new Date(el.measurementTimeUTC)) ?? ""}
                                {` (${formatTimeDistance(new Date(el.measurementTimeUTC))})` ?? ""}
                              </Typography>
                              <Divider />
                              <Box sx={{ display: "flex", alignItems: "end", columnGap: "5px" }}>
                                <Typography variant="copy">
                                  {translations.entities.device.name} {translations.entities.device.columns.serialNo} -
                                </Typography>
                                <PLTextlink
                                  bold={false}
                                  label={el.device.serialNo ?? ""}
                                  onClick={() => history.push(`/deviceManagement/deviceDetails/${el.device.id}`)}
                                />
                              </Box>
                            </Box>
                          </Popup>
                        ) : null}
                      </Marker>
                    );
                  })}
              </MarkerClusterGroup>
            </React.Fragment>
          );
        })}
      </FeatureGroup>
    </>
  );
};

export default StorageAreas;
