import Can from "authorization";
import React from "react";
import { TabInterface } from "./authorizedTabsArray";

interface AuthorizedTabsCan {
  currentTab: number;
  tabs: TabInterface[];
}

const AuthorizedTabsCan: React.FC<AuthorizedTabsCan> = ({ tabs, currentTab, children }) => {
  return (
    <Can I="read" a={tabs[currentTab].scope}>
      {children}
    </Can>
  );
};

export default AuthorizedTabsCan;
