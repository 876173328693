/* eslint-disable react/display-name */
import { useGetAllCapabilitiesQuery } from "graphqlBase/Capabilities/__generated__/getAllCapabilities";
import {
  GetSensorMeasurementHistoriesForDeviceQuery,
  ResultType,
} from "graphqlBase/SensorMeasurementHistories/__generated__/getsensorMeasurementHistoryForDevice";
import convertUTCDateToLocalDate from "lib/utils/convertUTCDateToLocalDate";
import getCleanGPS from "lib/utils/getCleanGPS";
import { useMemo } from "react";
import { formatDateTime, getNumber } from "translations/formatter";

type SensorMeasurementBase = ResultType<GetSensorMeasurementHistoriesForDeviceQuery["sensorMeasurementHistories"]>;
export interface SensorMeasurement extends SensorMeasurementBase {
  capabilityDescription: string;
  capabilityKey: string;
  capabilityName: string;
  localeTimeMeasured: string;
  timeStamp: string;
}

const capabilitiesToHide = ["v"];

export const deviceApplicationState = [
  "",
  "STORAGE_SITE_IN_REST",
  "STORAGE_SITE_IN_MOVT",
  "TRANSPORT_IN_REST",
  "TRANSPORT_IN_MOVT",
  "TARGET_SITE_IN_SETUP",
  "TARGET_SITE_IN_USE",
  "END_OF_LIFE",
];

const getCleanData = ({
  valueString,
  capabilityName,
  unitSymbol,
}: {
  capabilityName: string;
  unitSymbol: string;
  valueString?: string;
}): string | undefined => {
  if (!valueString || capabilitiesToHide.includes(capabilityName)) return;
  const cleanUnitSymbol = unitSymbol === "no Unit" && unitSymbol.length ? "" : ` ${unitSymbol}`;
  switch (capabilityName) {
    case "App State":
      return deviceApplicationState[+valueString] ?? "";
    case "Humidity":
    case "Temperature":
      return `${getNumber(+valueString / 1, 1)}${cleanUnitSymbol}`;
    case "Longitude":
    case "Latitude":
      return `${getCleanGPS(+valueString).toFixed(3)}`;
  }
  return valueString;
};

const useGetSensorMeasurements = (
  sensorMeasurementsResult: GetSensorMeasurementHistoriesForDeviceQuery["sensorMeasurementHistories"] | undefined
) => {
  const {
    data: capabilitiesResult,
    loading: loadingcapabilities,
    refetch: refetchGetAllcapabilitiesQuery,
  } = useGetAllCapabilitiesQuery();

  const data: SensorMeasurement[] = useMemo(() => {
    return (
      (sensorMeasurementsResult ?? []).reduce<SensorMeasurement[]>((sensorMeasurements, sensorMeasurement) => {
        if (!sensorMeasurement) return sensorMeasurements;

        const { unitSymbol, capabilityName, capabilityDescription } = (capabilitiesResult?.capabilities ?? []).reduce(
          (result, capability) => {
            if (capability.id !== sensorMeasurement.capabilityId) return result;
            return {
              capabilityName: capability.capabilityName,
              capabilityDescription: capability.capabilityDescription ?? "",
              unitSymbol:
                capability.deviceCapability[0]?.unit?.unitSymbol ??
                capability.deviceModelCapability[0]?.unit?.unitSymbol ??
                "no Unit",
            };
          },
          {
            unitSymbol: "no Unit",
            capabilityName: "",
            capabilityDescription: "",
          }
        );
        const valueString = getCleanData({
          valueString: sensorMeasurement?.valueString ?? undefined,
          capabilityName,
          unitSymbol,
        });
        return sensorMeasurements.concat({
          ...sensorMeasurement,
          timeStamp: sensorMeasurement.utcTimeMeasured,
          utcTimeMeasured: formatDateTime(new Date(sensorMeasurement.utcTimeMeasured)),
          localeTimeMeasured: formatDateTime(convertUTCDateToLocalDate(new Date(sensorMeasurement.utcTimeMeasured))),
          capabilityName,
          capabilityDescription,
          capabilityKey: capabilityName,
          valueString: valueString ?? "",
        });
      }, []) ?? []
    );
  }, [capabilitiesResult, sensorMeasurementsResult]);
  return { data, loadingcapabilities, refetchGetAllcapabilitiesQuery };
};

export default useGetSensorMeasurements;
