import colors from "../colors";
import { muiIconButtonOverrides } from "themes/Button/IconButton";

export const muiToggleButtonOverrides = {
  root: {
    ...muiIconButtonOverrides.root,
    border: "1px solid",
    backgroundColor: colors.tWhite,
    color: colors.tBlack,
    borderColor: colors.tBlack,
    padding: "1px 6px",
    "& svg": {
      color: colors.tBlack,
    },
    "&:hover": {
      backgroundColor: colors.grey200,
      borderColor: colors.grey200,
      color: colors.tBlack,
    },
    "&.Mui-selected": {
      backgroundColor: colors.blueLight,
      color: colors.tWhite,
      borderColor: colors.blueLight,
      boxShadow: "0px 8px 16px 0px #002bff59, 0px 2px 8px 0px #002bff59",
      "& svg": {
        color: colors.tWhite,
      },
    },
    "&.MuiToggleButton-root.Mui-selected:hover": {
      backgroundColor: colors.blueLight,
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-around",
  },
};
