import * as React from "react";
import { Theme as AugmentedTheme, useTheme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    form: {
      // flexGrow: 1,
      // backgroundColor: theme.palette.secondary.light,

      display: "flex",
      flexDirection: "column",
      width: "90%",
      height: "90%",
      margin: "5%",
    },
  })
);

export default () => {
  const { form } = useStyles({});
  return <Skeleton animation={false} className={form} />;
};
