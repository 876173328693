import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Alert from '@mui/material/Alert';

import { Theme } from "@mui/material";
import Props from "./index.d";

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: ({ width }) => ({
      width: width ?? "100%",
      height: "100%",
    }),
    child: {
      width: "100%",
      display: "flex",
      padding: "5px 0px",
      "& :first-child": {
        width: "100%",
      },
    },
    formBody: {
      width: "100%",
      "@media (max-height: 800px)": {
        display: "flex",
        justifyContent: "center",
      },
    },
    fields: {
      width: "100%",
    },
    map: {
      padding: "10px 0",
      "@media (max-height: 800px)": {
        margin: "0px 20px",
      },
    },
    name: { paddingBottom: "5px" },
    locationType: { padding: "5px 0" },
    description: { padding: "5px 0" },
  })
);
interface GFCFieldSet_StorageArea extends Props {
  children: React.ReactNode[];
}

const FieldSetHorizontal: React.FC<GFCFieldSet_StorageArea> = (props) => {
  const classes = useStyles(props);
  const [title, stepper, name, hiddenField, locationType, description, warning, map] = props.children;
  return (
    <div className={classes.root}>
      <div>
        <div>
          <div>{title}</div>
          <div>{stepper}</div>
        </div>
        <div className={classes.formBody}>
          <div className={classes.fields}>
            <div className={classes.name}>{name}</div>
            <div className={classes.locationType}>{locationType}</div>
            <div className={classes.description}>{description}</div>
          </div>
          <div>
            <div>{warning}</div>
            <div className={classes.map}>{map}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FieldSetHorizontal;
