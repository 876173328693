import * as React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from "@mui/material";
import Props from "./index.d";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      [theme.breakpoints.down('lg')]: {
        flexDirection: "column",
      },
    },
    child: {
      flexGrow: 1,
      width: "0%",
      "&:not(:first-child)": {
        margin: "0 0 0 3em",
      },
      [theme.breakpoints.down('lg')]: {
        width: "100%",
        "&:not(:first-child)": {
          margin: "3em 0 0 0",
        },
      },
    },
  })
);
interface GFCFieldSet_ResponsiveColumns extends Props {
  children: React.ReactNode;
}

const FieldSetResponsiveColumns: React.FC<GFCFieldSet_ResponsiveColumns> = (props) => {
  const { children } = props;
  const classes = useStyles();
  //  @ts-ignore
  if (props.hidden) return null;
  const childrenArray = React.Children.map(children, (child) => child);
  return (
    <div className={classes.root}>
      {childrenArray?.map((child, i) => (
        <div className={classes.child} key={i}>
          {child}
        </div>
      ))}
    </div>
  );
};
export default FieldSetResponsiveColumns;
