import { styled } from "@mui/material";

export const IconTextCellWrapperSC = styled("div")(
  ({ width }: { width?: string | number }) => `
       display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        margin-left: -0.5em;
        width: ${width ?? "70%"};
`
);
