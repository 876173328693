import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type CreateGroupsMutationVariables = Types.Exact<{
  groups?: Types.InputMaybe<Array<Types.InputMaybe<Types.GroupCreateType>> | Types.InputMaybe<Types.GroupCreateType>>;
}>;


export type CreateGroupsMutation = { createGroups?: Maybe<Array<Maybe<{ __typename?: 'Group', id: string, groupName: string, rAWQuery?: Maybe<string>, jSONQuery: string, createdAt?: Maybe<string>, groupDescription?: Maybe<string>, userId?: Maybe<string> }>>> };


export const CreateGroupsDocument = gql`
    mutation createGroups($groups: [GroupCreateType]) {
  createGroups(groups: $groups) {
    id
    groupName
    rAWQuery
    jSONQuery
    createdAt
    groupDescription
    userId
  }
}
    `;
export type CreateGroupsMutationFn = Apollo.MutationFunction<CreateGroupsMutation, CreateGroupsMutationVariables>;

/**
 * __useCreateGroupsMutation__
 *
 * To run a mutation, you first call `useCreateGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupsMutation, { data, loading, error }] = useCreateGroupsMutation({
 *   variables: {
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useCreateGroupsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGroupsMutation, CreateGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateGroupsMutation, CreateGroupsMutationVariables>(CreateGroupsDocument, options);
      }
export type CreateGroupsMutationHookResult = ReturnType<typeof useCreateGroupsMutation>;
export type CreateGroupsMutationResult = Apollo.MutationResult<CreateGroupsMutation>;
export type CreateGroupsMutationOptions = Apollo.BaseMutationOptions<CreateGroupsMutation, CreateGroupsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
