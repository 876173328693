const en = {
  name: "Rule",
  plural: "rules",
  columns: {
    deviceCapability: "device capability",
    deviceCapabilityId: "deviceCapability ID",
    id: "ID",
    isDeleted: "is deleted",
    isEnabled: "is enabled",
    ruleActionFailure: "rule action failure",
    ruleActionFailureId: "rule action failure ID",
    ruleActionSuccess: "rule action success",
    ruleActionSuccessId: "rule action success ID",
    ruleDescription: "rule description",
    ruleMessageFailure: "rule message failure",
    ruleMessageSuccess: "rule message success",
    ruleName: "rule name",
    ruleOperator: "rule operator",
    ruleReference: "rule reference",
    ruleReferenceId: "rule reference ID",
    ruleResult: "rule result",
    ruleResultType: "rule result type",
    ruleResultTypeId: "rule result type ID",
    tenantId: "tenant ID",
    unitId: "unit ID",
  },
};
export default en;
