import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type AssetDeviceUnassignmentMutationVariables = Types.Exact<{
  deviceSerialNumber?: Types.InputMaybe<Types.Scalars['String']>;
  assetCustomUniqueIdentifier?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AssetDeviceUnassignmentMutation = { unpairingAssetDevice?: Maybe<{ __typename?: 'AssetDevicePairing', status: boolean, statusMessage?: Maybe<string> }> };


export const AssetDeviceUnassignmentDocument = gql`
    mutation assetDeviceUnassignment($deviceSerialNumber: String, $assetCustomUniqueIdentifier: String) {
  unpairingAssetDevice(deviceSerialNumber: $deviceSerialNumber, assetCustomUniqueIdentifier: $assetCustomUniqueIdentifier) {
    status
    statusMessage
  }
}
    `;
export type AssetDeviceUnassignmentMutationFn = Apollo.MutationFunction<AssetDeviceUnassignmentMutation, AssetDeviceUnassignmentMutationVariables>;

/**
 * __useAssetDeviceUnassignmentMutation__
 *
 * To run a mutation, you first call `useAssetDeviceUnassignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssetDeviceUnassignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assetDeviceUnassignmentMutation, { data, loading, error }] = useAssetDeviceUnassignmentMutation({
 *   variables: {
 *      deviceSerialNumber: // value for 'deviceSerialNumber'
 *      assetCustomUniqueIdentifier: // value for 'assetCustomUniqueIdentifier'
 *   },
 * });
 */
export function useAssetDeviceUnassignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssetDeviceUnassignmentMutation, AssetDeviceUnassignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssetDeviceUnassignmentMutation, AssetDeviceUnassignmentMutationVariables>(AssetDeviceUnassignmentDocument, options);
      }
export type AssetDeviceUnassignmentMutationHookResult = ReturnType<typeof useAssetDeviceUnassignmentMutation>;
export type AssetDeviceUnassignmentMutationResult = Apollo.MutationResult<AssetDeviceUnassignmentMutation>;
export type AssetDeviceUnassignmentMutationOptions = Apollo.BaseMutationOptions<AssetDeviceUnassignmentMutation, AssetDeviceUnassignmentMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
