import { MappingRoleScopeCreateType } from "graphqlBase/types";
import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  MappingRoleScopesExtendedRegistry,
  mappingRoleScopesState,
} from "../../RecoilState/MappingRoleScopesTableState";
import { useManageMappingRoleScopesMutation } from "graphqlBase/MappingRoleScope/__generated__/manageMappingRoleScopes";

export const getCreatesDeletesPre = ({ roleId }: { roleId: string }) => (data: MappingRoleScopesExtendedRegistry) => {
  const { creates, deletes } = Object.values(data).reduce<{
    creates: MappingRoleScopeCreateType[];
    deletes: string[];
  }>(
    ({ creates, deletes }, mapping) => {
      if (!mapping.id && !mapping.isDeleted)
        return {
          creates: creates.concat({ ...mapping, roleId }),
          deletes,
        };
      if (mapping.id && mapping.isDeleted)
        return {
          creates,
          deletes: deletes.concat(mapping.id),
        };
      return {
        creates,
        deletes,
      };
    },
    { creates: [], deletes: [] }
  );
  const hasChanges = !!(creates.length + deletes.length);
  return { creates, deletes, hasChanges };
};

export const useGetMappingsToSave = ({ roleId }: { roleId: string }) => {
  const [saveMappings] = useManageMappingRoleScopesMutation();
  const getCreatesDeletes = getCreatesDeletesPre({ roleId });
  const getData = useRecoilCallback(
    ({ snapshot }) => async () => await snapshot.getPromise(mappingRoleScopesState),
    []
  );
  return async () => {
    const data = await getData();
    const { creates, deletes } = getCreatesDeletes(data);
    return await saveMappings({ variables: { creates, deletes } });
  };
};
export const useHasSelectedRolesChanges = () => {
  const data = useRecoilValue(mappingRoleScopesState);
  const { hasChanges } = getCreatesDeletesPre({ roleId: "dummy" })(data);
  return { hasChanges };
};
