import {
  Checkbox as MUICheckbox,
  CheckboxClassKey,
  CheckboxProps as MUICheckboxProps,
  FormControlLabel,
  Icon,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import CheckIcon from "icons/components/CheckIcon";

export const testIDs = getTestIDs();
export interface CheckboxProps extends MUICheckboxProps {
  checked?: boolean;
  classes?: Partial<Record<CheckboxClassKey, string>>;
  disabled?: boolean;
  label?: string;
  testId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: "center",
    display: "inline-flex",
    cursor: "initial",
    "& .MuiIcon-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": {
        display: "flex",
        height: "1em",
        width: "1em",
      },
    },
  },
  label: {
    color: theme.colors.grey500,
    fontFamily: "corporateroman, Helvetica Neue, HelveticaNeue, Helvetica, swiss, Arial-Narrow sans-serif",
    fontSize: 16,
    margin: "0.2em 0.4em 0.2em 0.3em",
  },
}));

const Checkbox: React.FC<CheckboxProps> = ({
  classes,
  checked = false,
  disabled = false,
  label = "",
  onChange,
  testId,
}) => {
  const checkboxClasses = useStyles();

  return (
    <FormControlLabel
      classes={checkboxClasses}
      value="top"
      control={
        <MUICheckbox
          data-testid={testId ?? testIDs.Checkbox}
          classes={classes}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          checkedIcon={
            <Icon>
              <CheckIcon />
            </Icon>
          }
          color="default"
        />
      }
      label={label}
      labelPlacement="end"
    />
  );
};

export default Checkbox;
