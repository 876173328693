import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import getTestIDs from "lib/utils/getTestIDs";
import React from "react";
import { Link, matchPath, useHistory, useLocation } from "react-router-dom";
import { flattenRoutes } from "routes/Router";
import routes from "routes/routes";
import translations from "translations";
export const testIDs = getTestIDs();

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20vh",
  },
});

const NoAccessMessage = () => {
  const classes = useStyles();
  const location = useLocation();
  const pathname = location.pathname;
  const routesForNoMatch = flattenRoutes(routes());
  const currentRoute = routesForNoMatch.find(({ path, exact }) => matchPath(pathname ?? "", { path, exact }));
  return (
    <div className={classes.container}>
      <Typography variant="h2">
        {currentRoute
          ? translations.pages.noAccessMessage.restrictedLink.title
          : translations.pages.noAccessMessage.noPageFound.title}
      </Typography>

      <Typography variant="body1">
        {currentRoute
          ? translations.pages.noAccessMessage.restrictedLink.text
          : translations.pages.noAccessMessage.noPageFound.text}
        <Link to="/">{translations.pages.noAccessMessage.goBackLink}</Link>
      </Typography>
    </div>
  );
};
export default NoAccessMessage;
