import { Box, Typography } from "@mui/material";
import PLTextlink from "components/patternLib/PLTextlink";
import {
  GetDevicesForHomeSummaryQuery,
  ResultType,
  useGetDevicesForHomeSummaryQuery,
} from "graphqlBase/Devices/__generated__/getDevicesForHomeSummary";
import React from "react";
import { useHistory } from "react-router-dom";
import translations from "translations";
import { NoDataSummaryTile } from "../NoDataSummaryTile";
import {
  BatteryCellWrapperSC,
  BatteryIconSC,
  BatteryIconWrapperSC,
  batteryVoltageText,
  SummaryTableCellSC,
} from "../styles";
import SummaryTile from "../SummaryTile";
import useGetDevicesToUpdateFirmware from "./useGetDevicesToUpdateFirmware";
import useGetDevicesWithLowBattery from "./useGetDevicesWithLowBattery";

export interface DeviceListItem {
  batteryValue: number;
  deviceId: string;
  deviceName: string;
}

export type Device = ResultType<GetDevicesForHomeSummaryQuery["devices"]>;

const DeviceManagementSummary: React.FC<{}> = () => {
  const history = useHistory();
  const { data: devicesData, loading: devicesLoading } = useGetDevicesForHomeSummaryQuery();
  const {
    devicesLowBattery,
    loading: devicesLowBatteryLoading,
    totalLowBatteryDevicesCount,
  } = useGetDevicesWithLowBattery(devicesData?.devices);
  const { devicesCountUpdateFirmware, loading: devicesToUpdateFirmwareLoading } = useGetDevicesToUpdateFirmware(
    devicesData?.devices
  );

  const handleGoToDevices = () => {
    history.push("/deviceManagement");
  };

  const {
    countFirmwareUpdateDevices,
    firmwareUpdate,
    countDeviceCriticalBattery,
    noDataDeviceSummary,
    noLowBatteryDevicesSummary,
  } = translations.pages.home;
  const { label, deviceDetails } = translations.pages.deviceManagement;

  const contentFooter = (
    <PLTextlink
      onClick={handleGoToDevices}
      label={countFirmwareUpdateDevices(devicesCountUpdateFirmware ?? 0)}
      size="small"
      bold={false}
      uppercase={false}
    />
  );

  const tableElements = devicesLowBattery?.map((row) => (
    <>
      <SummaryTableCellSC first size="small">
        <PLTextlink
          onClick={() => history.push(`/deviceManagement/deviceDetails/${row.deviceId}`)}
          label={row.deviceName}
          size="small"
          bold={false}
          uppercase={false}
          width="fit-content"
        />
      </SummaryTableCellSC>
      <SummaryTableCellSC last size="small">
        <BatteryCellWrapperSC>
          <BatteryIconWrapperSC>
            <BatteryIconSC />
          </BatteryIconWrapperSC>
          <Box sx={batteryVoltageText}>
            <Typography color="var(--color-warning)" variant="copy">
              {`${row.batteryValue} V`}
            </Typography>
          </Box>
        </BatteryCellWrapperSC>
      </SummaryTableCellSC>
    </>
  ));

  const NoTableDataComponent = (
    <NoDataSummaryTile
      iconName={totalLowBatteryDevicesCount === 0 ? "checklist" : undefined}
      message={
        !devicesData?.devices?.length
          ? noDataDeviceSummary
          : totalLowBatteryDevicesCount === 0
          ? noLowBatteryDevicesSummary
          : ""
      }
    />
  );

  return (
    <SummaryTile
      iconName="rss-feed"
      tableTitle={deviceDetails.lowBattery}
      title={label}
      loading={devicesLowBatteryLoading || devicesLoading || devicesToUpdateFirmwareLoading}
      tableElements={tableElements ?? []}
      handleSeeMore={handleGoToDevices}
      contentFooterTitle={firmwareUpdate}
      contentFooter={contentFooter}
      warningMessage={countDeviceCriticalBattery(totalLowBatteryDevicesCount)}
      NoTableDataComponent={NoTableDataComponent}
    />
  );
};

export default DeviceManagementSummary;
