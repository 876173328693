import client from "apollo/client";
import {
  PersistNotificationDefinitionsDocument,
  PersistNotificationDefinitionsMutation,
  PersistNotificationDefinitionsMutationVariables,
} from "graphqlBase/NotificationDefinition/__generated__/persistNotificationDefinitions";
import { useGetNotificationsCallback } from "./useGetNotificationsCallback";

const usePersistNotificationDefinitions = () => {
  const getNotifcationNestedData = useGetNotificationsCallback();

  const getData = async (notificationId: string): Promise<PersistNotificationDefinitionsMutationVariables> => {
    const { notificationDefinitionsList } = await getNotifcationNestedData();

    const updateNotificationDefinitions = notificationDefinitionsList?.reduce<
      PersistNotificationDefinitionsMutationVariables["updateNotificationDefinitions"]
    >((updateNotificationDefinitions, { id, toUpdate, notificationOperatorId, storageArea, value }) => {
      if (!toUpdate || !id) return updateNotificationDefinitions;
      if (Array.isArray(updateNotificationDefinitions))
        return updateNotificationDefinitions.concat({
          id,
          notificationOperatorId,
          storageAreaId: storageArea?.id,
          notificationId,
          value,
        });
    }, []);

    const deleteNotificationDefinitions = (notificationDefinitionsList ?? []).reduce<
      PersistNotificationDefinitionsMutationVariables["deleteNotificationDefinitions"]
    >((deleteNotificationDefinitions, { toDelete, id }) => {
      if (!toDelete || !id) return deleteNotificationDefinitions;
      return (deleteNotificationDefinitions ?? []).concat(id);
    }, []);

    const notificationDefinitions = notificationDefinitionsList?.reduce<
      PersistNotificationDefinitionsMutationVariables["notificationDefinitions"]
    >((notificationDefinitions, { notificationOperatorId, value, storageArea, id }) => {
      if (id) return notificationDefinitions;
      if (Array.isArray(notificationDefinitions))
        return notificationDefinitions.concat({
          notificationOperatorId,
          value,
          notificationId,
          storageAreaId: storageArea?.id,
        });
    }, []);

    return {
      notificationDefinitions,
      deleteNotificationDefinitions,
      updateNotificationDefinitions,
    };
  };

  return async (notificationId: string) => {
    const variables: PersistNotificationDefinitionsMutationVariables = await getData(notificationId);
    return await client.mutate<PersistNotificationDefinitionsMutation, PersistNotificationDefinitionsMutationVariables>(
      {
        mutation: PersistNotificationDefinitionsDocument,
        variables,
      }
    );
  };
};
export default usePersistNotificationDefinitions;
