import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Props from "./index.d";
import translations from "translations";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      root: {
        padding: (props) => (props.padding && props.action === "subract" ? props.padding : 0),
      },
    }),
  { name: "StepperButton" }
);

const StepperButton: React.FC<Props> = (props) => {
  const {
    data: { state, setAndSave },
    length,
    title,
    testId,
    disabled,
    action,
  } = props;

  const classes = useStyles(props);

  const handleClick = () => {
    const modifier = action === "add" ? 1 : -1;
    setAndSave(state + modifier);
  };

  return (
    <div className={classes.root}>
      {action === "subract" && (
        <Button
          data-testid={testId ? `${testId}${action}` : undefined}
          buttonType="secondary"
          label={translations.globals.terms.back}
          disabled={+state === 0 || disabled}
          onClick={handleClick}
          style={{ width: "118px" }}
        />
      )}
      {action === "add" && state < length && (
        <Button
          data-testid={testId ? `${testId}${action}` : undefined}
          buttonType="primary"
          label={translations.globals.terms.next}
          disabled={disabled}
          onClick={handleClick}
          style={{ width: "118px" }}
        />
      )}
    </div>
  );
};
export default StepperButton;
