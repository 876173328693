import { assetDeviceApplicationState, lhbDeviceState } from "components/pages/AssetManagement/utils";
import { useEffect, useState } from "react";
import { Coordinates } from "./GroupAssetsMap";
import { useGetDeviceLocationLazyQuery } from "graphqlBase/Devices/__generated__/getDeviceLocation";

export const useGetCurrentPosition = (queryBuilderResultIds?: string[] | undefined) => {
  const [query, { data, loading }] = useGetDeviceLocationLazyQuery();
  const [currentPositionsData, setCurrentPositionsData] = useState<Array<Coordinates> | undefined>();

  useEffect(() => {
    if (!queryBuilderResultIds?.length) return;

    query({
      variables: {
        deviceIds: queryBuilderResultIds,
        where: {
          and: [{ deviceId: { in: queryBuilderResultIds } }],
        },
      },
    });
  }, [queryBuilderResultIds]);

  useEffect(() => {
    if (!data?.deviceLocation) return;

    const positions = data?.deviceLocation.reduce<Array<Coordinates>>((positions, current) => {
      const position = current?.location?.coordinates;
      if (!position || !current?.deviceId) return positions;
      const assetDevice = data.assetDevices.find((item) => item.deviceId === current.deviceId);
      const appState = assetDevice?.device.sensorMeasurementCurrentValue?.find((value) => !!value?.valueInt)?.valueInt;
      const assetState = assetDevice?.asset?.assetSource?.assetSourceName.includes("CRANE")
        ? assetDeviceApplicationState[appState ?? -1]
        : lhbDeviceState[appState ?? -1];
      const tooltipItems = {
        title: assetDevice?.asset?.assetMasterData?.generalItem,
        value: assetState ? `${assetState.label}${assetState.extendedLabel}` : undefined,
      };
      return positions.concat({
        position,
        tooltipItems,
      } as Coordinates);
    }, []);

    setCurrentPositionsData(positions ?? []);
  }, [data]);

  return { currentPositionsData, loading };
};
