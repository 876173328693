import type * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type PersistNotificationNestedsMutationVariables = Types.Exact<{
  deleteMappingDeviceNotifications: Array<Types.Scalars['UUID']> | Types.Scalars['UUID'];
  deleteMappingNotificationUsers: Array<Types.Scalars['UUID']> | Types.Scalars['UUID'];
  mappingDeviceNotifications?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingDeviceNotificationCreateType>> | Types.InputMaybe<Types.MappingDeviceNotificationCreateType>>;
  mappingNotificationUsers?: Types.InputMaybe<Array<Types.InputMaybe<Types.MappingNotificationUserCreateType>> | Types.InputMaybe<Types.MappingNotificationUserCreateType>>;
}>;


export type PersistNotificationNestedsMutation = { deleteMappingDeviceNotifications?: Maybe<Array<Maybe<{ __typename?: 'MappingDeviceNotification', id: string }>>>, createMappingDeviceNotifications?: Maybe<Array<Maybe<{ __typename?: 'MappingDeviceNotification', id: string }>>>, deleteMappingNotificationUsers?: Maybe<Array<Maybe<{ __typename?: 'MappingNotificationUser', id: string }>>>, createMappingNotificationUsers?: Maybe<Array<Maybe<{ __typename?: 'MappingNotificationUser', id: string }>>> };


export const PersistNotificationNestedsDocument = gql`
    mutation persistNotificationNesteds($deleteMappingDeviceNotifications: [UUID!]!, $deleteMappingNotificationUsers: [UUID!]!, $mappingDeviceNotifications: [MappingDeviceNotificationCreateType], $mappingNotificationUsers: [MappingNotificationUserCreateType]) {
  deleteMappingDeviceNotifications(ids: $deleteMappingDeviceNotifications) {
    id
  }
  createMappingDeviceNotifications(mappingDeviceNotifications: $mappingDeviceNotifications) {
    id
  }
  deleteMappingNotificationUsers(ids: $deleteMappingNotificationUsers) {
    id
  }
  createMappingNotificationUsers(mappingNotificationUsers: $mappingNotificationUsers) {
    id
  }
}
    `;
export type PersistNotificationNestedsMutationFn = Apollo.MutationFunction<PersistNotificationNestedsMutation, PersistNotificationNestedsMutationVariables>;

/**
 * __usePersistNotificationNestedsMutation__
 *
 * To run a mutation, you first call `usePersistNotificationNestedsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersistNotificationNestedsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [persistNotificationNestedsMutation, { data, loading, error }] = usePersistNotificationNestedsMutation({
 *   variables: {
 *      deleteMappingDeviceNotifications: // value for 'deleteMappingDeviceNotifications'
 *      deleteMappingNotificationUsers: // value for 'deleteMappingNotificationUsers'
 *      mappingDeviceNotifications: // value for 'mappingDeviceNotifications'
 *      mappingNotificationUsers: // value for 'mappingNotificationUsers'
 *   },
 * });
 */
export function usePersistNotificationNestedsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersistNotificationNestedsMutation, PersistNotificationNestedsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PersistNotificationNestedsMutation, PersistNotificationNestedsMutationVariables>(PersistNotificationNestedsDocument, options);
      }
export type PersistNotificationNestedsMutationHookResult = ReturnType<typeof usePersistNotificationNestedsMutation>;
export type PersistNotificationNestedsMutationResult = Apollo.MutationResult<PersistNotificationNestedsMutation>;
export type PersistNotificationNestedsMutationOptions = Apollo.BaseMutationOptions<PersistNotificationNestedsMutation, PersistNotificationNestedsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
export type Maybe<T> = Types.Maybe<T>;
