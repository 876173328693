import { LhChange } from "@liebherr/patternlib/dist/types/utils/interfaces";
import PLTextinput from "components/patternLib/form/PLTextinput";
import React, { useEffect } from "react";
import { RuleValue, Field } from "react-awesome-query-builder";

interface FieldProps {
  field?: string;
  placeholder?: string;
  setValue?: (val: RuleValue) => void;
  value?: RuleValue;
}

export default ({ value, placeholder, setValue, field }: FieldProps) => {
  const handleChange = (event: CustomEvent<LhChange>) => {
    if (event.detail.value) setValue?.(event.detail.value);
  };
  useEffect(() => {
    setTimeout(() => setValue?.(undefined), 1);
  }, [field]);

  return (
    <PLTextinput value={value} lhChange={handleChange} size="small" showRemoveIcon={false} placeholder={placeholder} />
  );
};
