import { JSX } from "@liebherr/patternlib";
import React, { useRef } from "react";
import { wrapWc } from "wc-react";

type DropOnONLh<T> = {
  [K in keyof T as K extends `onLh${infer I}` ? `lh${I}` : K]: T[K];
};
export type PLTextlinkProps = DropOnONLh<JSX.PatternlibTextlink>;
export type PLTextlinkExtendedProps = PLTextlinkProps & React.HTMLAttributes<unknown> & React.RefAttributes<unknown>;

const WrappedTextlink = wrapWc<JSX.PatternlibTextlink>("patternlib-textlink");
export const useRefPLTextlink = () => useRef<HTMLPatternlibTextlinkElement>(null);

const PLTextlink = React.forwardRef<HTMLPatternlibTextlinkElement, PLTextlinkExtendedProps>((props, ref) => {
  const myRef = useRefPLTextlink();
  return (
    <WrappedTextlink ref={ref ?? myRef} {...props}>
      {props.children}
    </WrappedTextlink>
  );
});
PLTextlink.displayName = "PLTextlink";

export default PLTextlink;
