import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"
export const genericDeleteFunction = genericDeleteFunctionPre

export type AssetMasterDataUpdateOnUpdateDataMiddleWare = OnUpdateDataMiddleWare<SchemaTypes.AssetMasterDataInputUpdate>
export type AssetMasterDataUpdateOnUpdateDataMiddleWareProps = OnUpdateDataMiddleWareProps<SchemaTypes.AssetMasterDataInputUpdate>
export type AssetMasterDataUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.AssetMasterDataInputUpdate,
  SchemaTypes.AssetMasterDataInputUpdateProps["injectedValues"]
>
export type AssetMasterDataUpdateBeforeRemoteMuatationProps = BeforeRemoteMuatationProps<
  SchemaTypes.AssetMasterDataInputUpdate,
  SchemaTypes.AssetMasterDataInputUpdateProps["injectedValues"]
>
export type AssetMasterDataUpdateValidateForm = ValidateForm<
  SchemaTypes.AssetMasterDataInputUpdate,
  SchemaTypes.AssetMasterDataInputUpdateProps["injectedValues"]
>
export type AssetMasterDataUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.AssetMasterDataInputUpdate,
  SchemaTypes.AssetMasterDataInputUpdateProps["injectedValues"]
>

export interface AssetMasterDataUpdateProps
  extends SchemaTypes.AssetMasterDataInputUpdateProps {
  id: string
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.AssetMasterDataInputUpdate>
  afterUpdate?: (data: SchemaTypes.AssetMasterDataInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.AssetMasterDataInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.AssetMasterDataInputUpdate,
    SchemaTypes.AssetMasterDataInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.AssetMasterDataInputUpdate,
    SchemaTypes.AssetMasterDataInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const AssetMasterDataUpdateForm: React.FC<AssetMasterDataUpdateProps> = (
  props
) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: AssetMasterDataUpdate } = makeNewFormMemoized({
      entity: "assetMasterData",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <AssetMasterDataUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type AssetMasterDataCreateOnUpdateDataMiddleWare = OnUpdateDataMiddleWare<SchemaTypes.AssetMasterDataInputCreate>
export type AssetMasterDataCreateOnUpdateDataMiddleWareProps = OnUpdateDataMiddleWareProps<SchemaTypes.AssetMasterDataInputCreate>
export type AssetMasterDataCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.AssetMasterDataInputCreate,
  SchemaTypes.AssetMasterDataInputCreateProps["injectedValues"]
>
export type AssetMasterDataCreateBeforeRemoteMuatationProps = BeforeRemoteMuatationProps<
  SchemaTypes.AssetMasterDataInputCreate,
  SchemaTypes.AssetMasterDataInputCreateProps["injectedValues"]
>
export type AssetMasterDataCreateValidateForm = ValidateForm<
  SchemaTypes.AssetMasterDataInputCreate,
  SchemaTypes.AssetMasterDataInputCreateProps["injectedValues"]
>
export type AssetMasterDataCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.AssetMasterDataInputCreate,
  SchemaTypes.AssetMasterDataInputCreateProps["injectedValues"]
>

export interface AssetMasterDataCreateProps
  extends SchemaTypes.AssetMasterDataInputCreateProps {
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.AssetMasterDataInputCreate>
  afterCreate?: (
    data: SchemaTypes.AssetMasterDataInputCreate & { id: string }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.AssetMasterDataInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.AssetMasterDataInputCreate,
    SchemaTypes.AssetMasterDataInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.AssetMasterDataInputCreate,
    SchemaTypes.AssetMasterDataInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const AssetMasterDataCreateForm: React.FC<AssetMasterDataCreateProps> = (
  props
) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: AssetMasterDataCreate } = makeNewFormMemoized({
      entity: "assetMasterData",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <AssetMasterDataCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
