import React, { ComponentType, useEffect, useState } from "react";
import {
  Client,
  Localization,
  ElementType,
  EntityNames,
  FormComponent,
  Operation,
  TreeElement,
} from "../formGen.model";
import { QqlFormGenConfig } from "../types";
import registerElements from "./RenderChilds";

import { getConfig, saveConfig } from "./fetchConfig";
import { BeforeRemoteMuatation } from "../formGen.model";
import UnloadedForm from "./unloadedForm";
import { Channel } from "cwb";

interface BuildFormVars {
  elements: ElementType[];
  client: Client;
  localization: Localization;
  onUpdateDataMiddleWare?: any;
  beforeRemoteMuatation?: BeforeRemoteMuatation;
  entity: EntityNames;
  operation: Operation;
  clientLocal?: any;
  afterChange?: any;
  config: QqlFormGenConfig;
  clientFields: string[];
  externalFields: string[];
  formVersion?: string;
  useHC11?: boolean;
  allFields: string[];
}

const TREEFILE = "tree";
const chan = new Channel("raftConfig");
export default class FormGenerator {
  public Form: FormComponent;
  private createForm: registerElements;
  private client: Client;
  private localization: Localization;
  private tree: TreeElement | undefined;
  private clientLocal: any | undefined;
  private entity: EntityNames;
  private config: QqlFormGenConfig;
  private operation: Operation;
  private formVersion: string;

  constructor({
    elements,
    client,
    localization,
    entity,
    clientLocal,
    operation,
    config,
    clientFields,
    externalFields,
    afterChange,
    onUpdateDataMiddleWare,
    beforeRemoteMuatation,
    formVersion,
    useHC11,
    allFields,
  }: BuildFormVars) {
    this.createForm = new registerElements({
      elements,
      client,
      config,
      clientFields,
      externalFields,
      afterChange,
      onUpdateDataMiddleWare,
      beforeRemoteMuatation,
      localization,
      useHC11,
      allFields,
    });
    this.client = client;
    this.localization = localization;
    this.config = config;
    this.clientLocal = clientLocal;
    this.entity = entity;
    this.operation = operation;
    this.formVersion = formVersion ? formVersion : "active";
    this.Form = this.getForm();
  }

  private getForm = () => (props: any) => {
    const [NewForm, setnewForm] = useState<FormComponent | null>(null);
    const [state, setstate] = useState<any>();
    if (process.env.NODE_ENV === "development") {
      chan.on([TREEFILE, this.entity, this.operation, this.formVersion].join("/"), (d: any) => setstate(d));
    }
    useEffect(() => {
      this.getFormInner(state).then((fo) => {
        setnewForm(fo);
      });
    }, [state]);

    return NewForm ? <NewForm {...props} /> : <UnloadedForm />;
  };

  private getFormInner = async (conf: any) => {
    // const client = useApolloClient();

    const path = [TREEFILE, this.entity, this.operation, this.formVersion];

    const {
      data: { configurator },
    } = this.clientLocal
      ? this.clientLocal(path.join("/"))
      : await getConfig({
          client: this.clientLocal ? this.clientLocal : this.client,
          keys: path,
        });
    // console.log(conf, configurator);
    const { tree, config: treeConfig } = JSON.parse(conf ? conf : configurator.data) as {
      tree: TreeElement;
      config: any;
    };
    this.tree = tree;
    // console.log(tree, [TREEFILE, this.entity, this.operation, "active"]);
    return () =>
      React.memo(
        this.createForm.getForm({
          path: path.join("/"),
          tree,
          treeConfig: {
            ...treeConfig,
            entity: this.entity,
            operation: this.operation,
          },
        })
      );
  };
}
