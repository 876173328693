import React, { ReactElement } from "react";
import { getFlatDataFromTree, FlatDataItem } from "react-sortable-tree";
import { DataProxy } from "apollo-cache";

import gql from "graphql-tag";
import { oc } from "ts-optchain";
import pickBy from "lodash/pickBy";
import {
  AnyProperties,
  Client,
  Localization,
  DataItem,
  ElementType,
  FormComponent,
  FormGeneratorProps,
  BeforeRemoteMuatation,
  StateEffects,
  Updaters,
  Tree,
  TreeElement,
  Type,
  QueryArray,
  ValidateForm,
  ValidateFormProps,
} from "../formGen.model";
import { AfterChangeProps, QqlFormGenConfig, OnUpdateDataMiddleWareProps } from "../types";
import cleanParams from "./cleanParams";
import fieldState from "./fieldState";
import generateQeuery from "./generateQeuery";

import generateMutation from "./generateMutation";
import validateIsHidden, { Disabled, Hide, Validate, isDisabled, validateForm } from "./validate";
import Loader from "./loader";
import afterCreateOrDelete from "./afterCreateOrDelete";
import { upperFirst } from "lodash";

interface FlatDataItemFull extends FlatDataItem {
  treeIndex: number;
}

const Base = (props: any) => {
  // console.log(props);
  return null;
};

const getFlatData = (treeData: Tree) =>
  getFlatDataFromTree({
    treeData,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  });

const BaseHiddenField = (props: any) => {
  // console.log(props.id, props.data.state);
  return <div>{props.data.state}</div>;
  return <div style={{ display: "none" }} />;
};
const fallback: ElementType = {
  uiType: "fallback",
  Component: Base,
};

interface Hiders {
  dataIds: string[];
  hidden: any;
  setHidden: (data: any) => void;
  id: string;
  Hide: Hide;
}

interface IsDisabled {
  dataIds: string[];
  disabled: any;
  setDisabled: (data: any) => void;
  id: string;
  Disabled: Disabled;
}
interface HasError {
  dataIds: string;
  errors: string[];
  setErrors: (data: any) => void;
  id: string;
}

class FormGenerator {
  public elements: ElementType[];
  public client: Client;
  public localization: Localization;
  public data: DataItem | undefined;
  public config: QqlFormGenConfig;
  public query = "";
  public propsCache: any = {};
  public clientFields: string[];
  public externalFields: string[];
  public fieldTypes: { [key: string]: Type } = {};
  public afterCreate?: (data: any) => void;
  public updateAfterCreateQueries?: QueryArray;
  public updateAfterDeleteQueries?: QueryArray;
  public fetchAllFieldsOnUpdate?: boolean = false;
  public afterUpdate?: (data: any) => void;
  public afterDelete?: (data: any) => void;
  public onCancel: () => void = () => {};
  public defaultValues: { [key: string]: any } = {};
  public path = "";
  public updaters: Updaters[] = [];
  public hiders: Hiders[] = [];
  public isDisabled: IsDisabled[] = [];
  public errors: HasError[] = [];
  public validate: (data: any) => Validate = () => ({
    Validate: "Yes",
    Constraints_Validate: [],
  });
  public treeConfig: any;
  public params: any;
  public injectedValues?: any;
  public variables: any;
  public tree?: TreeElement;
  public flatTree: FlatDataItemFull[] = [];
  public afterChange?: (data: AfterChangeProps<any>) => void;
  public onUpdateDataMiddleWare?: (data: OnUpdateDataMiddleWareProps<any>) => any;
  public beforeRemoteMuatation?: BeforeRemoteMuatation;
  public onUpdateDataMiddleWareLocal?: (data: OnUpdateDataMiddleWareProps<any>) => any;
  public validateForm?: ValidateForm<any, any>;
  public beforeRemoteMuatationLocal?: BeforeRemoteMuatation;
  public modifyProps?: { [key: string]: (val: any) => any };

  public useHC11: boolean = false;
  public allFields: string[];
  public hiddenFields: string[] = [];
  public callBackOnHiddenEmptyFields: {
    fields: string[];
    func: (allHiddenOrNot: boolean) => string;
    notHiddenOrEmpty: boolean;
  }[] = [];

  constructor({
    elements,
    client,
    config,
    clientFields,
    externalFields,
    afterChange,
    onUpdateDataMiddleWare,
    beforeRemoteMuatation,
    localization,
    useHC11,
    allFields,
  }: FormGeneratorProps) {
    this.elements = this.checkUnique(elements);
    this.client = client;
    this.localization = localization;
    this.afterChange = afterChange;
    this.onUpdateDataMiddleWare = onUpdateDataMiddleWare;
    this.beforeRemoteMuatation = beforeRemoteMuatation;
    this.clientFields = clientFields;
    this.externalFields = externalFields;
    this.config = config;
    this.useHC11 = !!useHC11;
    this.allFields = allFields;
  }

  public saveData = (d: any) => {
    this.update(d);
  };

  public upDateCacheAfterMappingMutation = (
    cache: DataProxy
    // id: string,
    // operation: "add" | "remove"
  ) => {
    const hello = cache.readQuery({
      query: gql(this.query),
    });

    // cache.writeQuery({
    //   query: gql(this.query),
    //   data: {
    //     readConfigs:
    //       operation === "add"
    //         ? readConfigs.concat([{ id, __typename: "RAFGConfig" }])
    //         : readConfigs.filter(conf => conf.id !== id)
    //   }
    // });
    // console.log(hello);
  };

  public saveMappingData = (dataIn: any) => {
    function differenceOf2Arrays(array1: number[], array2: number[]) {
      const temp: number[] = [],
        temp2: number[] = [];

      for (const i in array1) {
        if (!array2.includes(array1[i])) {
          temp.push(array1[i]);
        }
      }
      for (const j in array2) {
        if (!array1.includes(array2[j])) {
          temp2.push(array2[j]);
        }
      }
      return [temp, temp2];
    }

    const entityName = Object.keys(dataIn)[0],
      mappingInfos = this.treeConfig.usedMappingInfos.find(
        (mappingInfo: any) => Object.keys(mappingInfo)[0] === entityName
      )[entityName],
      oldData = [...this.data![entityName]],
      entityUpperCase = entityName.charAt(0).toUpperCase() + entityName.slice(1),
      mappingIds = mappingInfos.mappingIds,
      data = dataIn[entityName],
      newDataIds = data.map((row: any) => row.id),
      newDataIdsForDelete = data.map((row: any) => row.id),
      oldDataIds = oldData.map((row: any) => row.id),
      oldDataIdsForDelete = oldData.map((row: any) => row.id);

    const [toCreate] = differenceOf2Arrays(newDataIds, oldDataIds),
      [_, toDelete] = differenceOf2Arrays(newDataIdsForDelete, oldDataIdsForDelete);

    const createMutations = toCreate.map((id) => {
      const value = data.find((row: any) => row.id === id);

      const mutationString = `
      mutation Raftcreate${entityUpperCase}{
        ${entityName}: create${entityUpperCase}(${entityName}:{${mappingIds.innerId}:${value.innerId},${
        mappingIds.outerId
      }:${value.outerId}}){
         ${Object.entries(mappingIds).map(
           ([key, value]) => `
            ${key}:${value}
            
            `
         )}
         ${
           ""
           //    Object.keys(mappingInfos.mappings).map(
           //    mapping =>
           //      `mapping: ${mapping}{\n\t\t\t\tid\n\t\t\t\ttranslationKey\n\t\t\t}`
           //  )
         }
        }
      }`;

      return this.client.mutate({
        mutation: gql(mutationString),
        fetchPolicy: "no-cache",
        // refetchQueries: [{ query: gql(this.query), variables: this.params }],
        // update: (proxy, { data }) => {
        //   const { result } = proxy.readQuery({
        //     query: gql(this.query),
        //     variables: this.params,
        //   }) || { result: {} };
        //   const newEntityData = result[entityName].concat(data[entityName]);

        //   console.log(newEntityData, data, "newEntityData");
        //   const newResult = {
        //     ...result,
        //     [entityName]: newEntityData,
        //   };

        //   this.update({ [entityName]: newEntityData }, false);
        //   proxy.writeQuery({
        //     query: gql(this.query),
        //     variables: this.params,
        //     data: {
        //       result: newResult,
        //     },
        //   });
        // },
      });
    });

    const deleteMutations = toDelete.map((id) => {
      const mutationString = `mutation Raftdelete${entityUpperCase}{
        ${entityName}:  delete${entityUpperCase}(id:${id}){
          id
        }
      }`;

      return this.client.mutate({
        mutation: gql(mutationString),
        fetchPolicy: "no-cache",
        // refetchQueries: [{ query: gql(this.query), variables: this.params }],
        // update: (proxy, { data }) => {
        //   const { result } = proxy.readQuery({
        //     query: gql(this.query),
        //     variables: this.params,
        //   }) || { result: {} };
        //   const newResult = {
        //     ...result,
        //     [entityName]: result[entityName].filter(
        //       (conf: any) => conf.id !== id
        //     ),
        //   };
        //   proxy.writeQuery({
        //     query: gql(this.query),
        //     variables: this.params,
        //     data: {
        //       result: newResult,
        //     },
        //   });
        // },
      });
    });

    const mutations = createMutations.concat(deleteMutations);

    Promise.all(mutations)
      .then((r) => {
        r.forEach((result) => {
          if (result.data[entityName].length) {
            const newData = data.map((entry: any) => {
              const newId = result.data[entityName].find((res: any) => res.outerId === entry.outerId);
              return { ...entry, ...newId };
            });

            this.update({ [entityName]: newData }, true, true);
          }
        });
      })
      .catch((r) => console.log(r));

    // console.log(data);
    this.update({ [entityName]: data }, false, true);
  };

  public checkUnique = (elements: ElementType[]) => {
    if (process.env.NODE_ENV == "production") {
      return elements;
    }

    const uiTypesArray = elements.map((element) => element.uiType);
    if (uiTypesArray.length !== Array.from(new Set(uiTypesArray)).length) {
      throw Error(
        `FormGenerator Error: uiTypes of elements are not unique \n\t${uiTypesArray
          .map((name, index) => `${index} => ${name}`)
          .join("\n\t")}`
      );
    }

    return elements.concat([{ uiType: "BaseHiddenField", Component: BaseHiddenField }]);
  };

  public getElement = ({ config: { uiType } }: TreeElement): ElementType => {
    const element = this.elements.find((element) => element.uiType === uiType);
    // if (!element)
    return element ? element : fallback;
  };

  public mutate = ({
    data: dataIn,
    fields,
    isDefaultData = false,
  }: {
    data: any;
    fields: string[];
    isDefaultData?: boolean;
  }) => {
    if (!fields.length) return;
    const { entity, operation } = this.treeConfig,
      { mutationString, mutationStringLocal, cacheUpdaters } = generateMutation({
        entity,
        operation,
        fields,
        clientFields: this.clientFields,
        externalFields: this.externalFields,
        treeConfig: this.treeConfig,
        allFields: this.allFields,
        updateAfterCreateQueries: this.updateAfterCreateQueries,
        fetchAllFieldsOnUpdate: this.fetchAllFieldsOnUpdate,
      });
    // @todo fix if inputfields are fixed
    const { __typename, submit, cancel, id, formErrors, virtualField, ...data } = dataIn,
      newParams: { [key: string]: any } = {},
      newParamsLocal: { [key: string]: any } = {},
      requiredData = {};

    this.treeConfig.requiredFields.forEach(
      // @ts-ignore
      (field: string) => (requiredData[field] = this.data![field])
    );

    Object.entries(this.params).forEach(([key, value]: [string, any]) => {
      if (this.useHC11 && key === "id") {
        //@ts-ignore
        newParams[`${entity}Id`] = value;
      } else {
        //@ts-ignore
        newParams[key] = value;
      }
    });

    const dataRemote = pickBy(
        data,
        (val: any, key: string) =>
          !this.clientFields.includes(`${entity}.${key}`) && !this.externalFields.includes(`${entity}.${key}`)
      ),
      dataLocal = pickBy(
        data,
        (val: any, key: string) =>
          this.clientFields.includes(`${entity}.${key}`) && !this.externalFields.includes(`${entity}.${key}`)
      );

    const makeUpdate = (data: Object, mutationString: string, isLocal: boolean = false) => {
      const nonNulls: { [key: string]: any } = {},
        setNull: { [key: string]: true } = {};
      Object.entries(data).forEach(([key, val]: [string, any]) => {
        if (val !== null || isLocal) {
          nonNulls[key] = val;
        } else {
          if (operation === "update") setNull[key] = true;
        }
      });

      if (Object.keys(data).length) {
        return this.client.mutate({
          mutation: gql(mutationString),
          variables: {
            data: [
              operation === "delete"
                ? this.params.id
                : operation === "update"
                ? { ...newParams, ...requiredData, ...nonNulls }
                : { ...newParams, ...nonNulls },
            ],
            setNull: operation === "update" ? setNull : undefined,
          },
        });
      } else {
        return Promise.resolve({ data: { result: [{}] } });
      }
    };

    // console.log(variables);

    const cleandedData = this.beforeRemoteMuatation
      ? this.beforeRemoteMuatation({
          data: dataRemote,
          allData: this.data,
          fields: Object.keys(dataIn),
          path: this.path,
          client: this.client,
          injectedValues: this.injectedValues,
        })
      : dataRemote;
    const cleandedDataLocal = this.beforeRemoteMuatationLocal
      ? this.beforeRemoteMuatationLocal({
          data: cleandedData,
          allData: this.data,
          fields: Object.keys(dataIn),
          path: this.path,
          client: this.client,
          injectedValues: this.injectedValues,
        })
      : cleandedData;
    // console.log(cleandedData);
    Promise.all([makeUpdate(cleandedDataLocal, mutationString), makeUpdate(dataLocal, mutationStringLocal, true)])
      .then((results) => {
        return results.reduce(
          (all: any, result: any) => {
            if (!result.data.result) return all;
            return {
              data: {
                result: [{ ...all.data.result[0], ...result.data.result[0] }],
              },
            };
          },
          { data: { result: [{}] } }
        );
      })
      .then((r) => {
        const { submit, cancel, __typename, ...data } = this.data!,
          emptyData = {};
        Object.keys({ ...data, ...r.data.result[0] }).forEach((element) => {
          // @ts-ignore
          emptyData[element] = undefined;
        });

        if (this.updateAfterCreateQueries) {
          afterCreateOrDelete({
            data: { ...data, ...r.data.result[0] },
            client: this.client,
            queries: this.updateAfterCreateQueries,
            type: "add",
            // cacheUpdaters,
            entity: this.treeConfig.entity,
          }).then(() => {
            if (this.afterCreate) {
              this.afterCreate({ ...data, ...r.data.result[0] });
              this.update({ ...emptyData }, true);
            }
          });
        } else {
          if (this.afterCreate) {
            this.afterCreate({ ...data, ...r.data.result[0] });
            this.update({ ...emptyData }, true);
          }
        }
        if (this.afterUpdate && !isDefaultData) {
          // console.log({ ...data, ...r.data.result[0] });
          this.afterUpdate({ ...data, ...r.data.result[0] });
        }
        if (this.updateAfterDeleteQueries) {
          afterCreateOrDelete({
            data: { ...data, ...r.data.result[0] },
            client: this.client,
            queries: this.updateAfterDeleteQueries,
            type: "add",
            // cacheUpdaters,
            entity: this.treeConfig.entity,
          }).then(() => {
            if (this.afterDelete) {
              this.afterDelete({ ...data, ...r.data.result[0] });
              this.update({ ...emptyData }, true);
            }
          });
        } else {
          if (this.afterDelete) {
            this.afterDelete({ ...data, ...r.data.result[0] });
            this.update({ ...emptyData }, true);
          }
        }
        if (submit) {
          //@ts-ignore
          this.update({ submit: false }, true);
        }
      })
      .catch((e) => {
        console.log("err", e);
        // console.log(dataRemote, mutationString);
        // console.log(dataLocal, mutationStringLocal);
      });

    const entityUpperCase = entity.charAt(0).toUpperCase() + entity.slice(1);
    if (operation === "update")
      this.client.writeFragment({
        id: `${entityUpperCase}:${this.params.id}`,
        fragment: gql`
        fragment myTodo on ${entityUpperCase} {
          ${Object.keys(data).join(", \n")}
          __typename
        }
      `,
        data: { ...data, __typename: `${entityUpperCase}` },
      });
  };

  getUpdatedValues = (data: any, dataPre: any) => {
    if (!data) return {};
    if (!this.fieldTypes) return data;

    return Object.entries(data).reduce((newData: any, [key, value]: [string, any]) => {
      const realValue =
          (value || value === 0) && ["number", "integer", "decimal"].includes(this.fieldTypes[key]) ? +value : value,
        currentValue = oc(dataPre)[key]();

      if (realValue !== currentValue || key === "step") {
        newData[key] = realValue;
      }

      return newData;
    }, {});
    // console.log(data, nn);
  };

  public update = (dataIn: AnyProperties, isFromApollo = false, isMappingData = false, isFromAfter = false) => {
    // if (!isFromApollo && !isMappingData && !isFromAfter) console.log(dataIn);
    if (dataIn.cancel) {
      this.onCancel();
      //@ts-ignore
      this.data.cancel = false;
      return;
    }
    const changedFields: string[] = [];
    // const data = this.getUpdatedValues(dataIn);

    const dataPre = this.getUpdatedValues(dataIn, {
      ...this.injectedValues,
      ...this.data,
    });
    const dataNew = this.onUpdateDataMiddleWare
      ? this.getUpdatedValues(
          this.onUpdateDataMiddleWare({
            path: this.path,
            query: this.query,
            variables: this.variables,
            data: { ...this.data, ...dataPre },
            dataBefore: { ...this.data },
            operation: this.treeConfig.operation,
            entity: this.treeConfig.entity,
            changedFields: Object.keys(dataPre),
            change: dataPre,
            origin: isFromApollo ? "store" : "user",
            client: this.client,
            flatTreeData: this.flatTree,
            hiddenFields: this.hiders.reduce<string[]>((hiddenFields, hider) => {
              console.log(hider);
              if (hider.hidden) return hiddenFields.concat(hider.id);
              return hiddenFields;
            }, []),
          }),
          { ...this.data, ...dataPre }
        ) || {}
      : dataPre;

    const dataNewLocal = this.onUpdateDataMiddleWareLocal
      ? this.getUpdatedValues(
          this.onUpdateDataMiddleWareLocal({
            path: this.path,
            query: this.query,
            variables: this.variables,
            data: { ...this.data, ...dataNew },
            dataBefore: { ...this.data },
            operation: this.treeConfig.operation,
            entity: this.treeConfig.entity,
            changedFields: Object.keys(dataNew),
            change: dataNew,
            origin: isFromApollo ? "store" : "user",
            client: this.client,
            flatTreeData: this.flatTree,
            hiddenFields: this.hiders.reduce<string[]>((hiddenFields, hider) => {
              if (hider.hidden) return hiddenFields.concat(hider.id);
              return hiddenFields;
            }, []),
          }),
          { ...this.data, ...dataNew }
        ) || {}
      : dataNew;

    const data = { ...dataPre, ...dataNewLocal };

    Object.entries(data).forEach(([name, value]) => {
      changedFields.push(name);
      this.updaters.forEach(({ dataId, stateEffects }) => {
        //  if (this.data && this.data.has && this.data[name] === name) {

        if (dataId === name) {
          if (stateEffects.state !== value) {
            stateEffects.setstate(value);
          }
        }
      });
    });

    const dataBefore = { ...this.data };
    this.data = { ...this.data, ...data };
    const toValidateHidden: Hiders[] = [];
    const usedHiders: string[] = [];
    const toAddToIsDisabled: IsDisabled[] = [];
    const usedIsDisabled: string[] = [];
    changedFields.forEach((field) => {
      // console.log(changedFields, this.hiders);

      this.hiders.forEach((hider) => {
        if (hider.dataIds.includes(field) && !usedHiders.includes(hider.id)) {
          toValidateHidden.push(hider);
          usedHiders.push(hider.id);
        }
      });
      this.isDisabled.forEach((disabled) => {
        if (!disabled.dataIds.length) {
          return;
        }

        if (disabled.dataIds.includes(field) && !usedIsDisabled.includes(disabled.id)) {
          toAddToIsDisabled.push(disabled);
          usedIsDisabled.push(disabled.id);
        }
      });
    });
    toValidateHidden.forEach((validator) => {
      // console.log(validator);
      const toHide = validateIsHidden(validator.Hide, {
        ...this.data,
      });

      if (validator.hidden !== toHide) {
        if (!toHide) {
          this.hiddenFields = this.hiddenFields.filter((field) => field !== validator.id);
        } else {
          this.hiddenFields.push(validator.id);
        }
        // console.log(validator.id, toHide);
        validator.setHidden(toHide);
      }
    });

    const getPathToDataFields = (tree: FlatDataItemFull["node"], path: string[]): string[][] => {
      if (!Array.isArray(tree.children) && tree.id) return [path.concat([tree.id, tree.dataId])];
      if (Array.isArray(tree.children)) {
        const childPaths = tree.children.reduce<string[][]>((list, elem) => {
          return list.concat(getPathToDataFields(elem, path.concat(tree.id)));
        }, []);
        return childPaths;
      }
      return [path];
    };

    const stepperFields = this.flatTree.filter((elem) => elem.node.config.uiType === "GFCFieldSet_Stepper");

    const newData: { [key: string]: any } = {};
    const withsiblingBefore = stepperFields.map((stepperField) => {
      const pathsToDataFieldsPre = getPathToDataFields(stepperField.node, []);
      const pathsToDataFields = pathsToDataFieldsPre.reduce<{ dataId: string; toCheckHidden: string[] }[]>(
        (list, elem) => {
          return list.concat({ dataId: elem.slice(-1)[0], toCheckHidden: elem.slice(1, -1) });
        },
        []
      );
      const before = stepperFields.filter(
        (stepperRel) =>
          stepperRel.parentNode.id === stepperField.parentNode.id && stepperRel.treeIndex < stepperField.treeIndex
      );
      const fieldsSelf: string[] = stepperField.node.config.includedFields.filter((field: string) => field !== "id");
      const after = stepperFields.filter(
        (stepperRel) =>
          stepperRel.parentNode.id === stepperField.parentNode.id && stepperRel.treeIndex > stepperField.treeIndex
      );
      const afterIds = after.map((elem) => elem.node.id);
      const fieldsAfter = after.reduce<string[]>((fieldsAfter, elem) => {
          const newFields = elem.node.config.includedFields.filter(
            (field: string) => field !== "id" && !fieldsAfter.includes(field)
          );
          return fieldsAfter.concat(newFields);
        }, []),
        fieldsBefore = before.reduce<string[]>((fieldsBefore, elem) => {
          const newFields = elem.node.config.includedFields.filter(
            (field: string) => field !== "id" && !fieldsBefore.includes(field)
          );
          return fieldsBefore.concat(newFields);
        }, []);

      const fieldsAfterExclusive = fieldsAfter.filter(
        (field) => !fieldsBefore.includes(field) && !fieldsSelf.includes(field)
      );
      const fieldsBeforeExclusive = fieldsBefore.filter(
        (field) => !fieldsAfter.includes(field) && !fieldsSelf.includes(field)
      );
      const fieldsSelfExclusive = fieldsSelf.filter(
        (field) => !fieldsAfter.includes(field) && !fieldsBefore.includes(field)
      );
      const previous = before.reduce<FlatDataItemFull | null>((previous, current) => {
        return current;
      }, null);
      const next = after.reduceRight<FlatDataItemFull | null>((previous, current) => {
        return current;
      }, null);
      const previousId = previous?.node.id;
      const nextId = next?.node.id;

      return {
        pathsToDataFields,
        id: stepperField.node.id,
        self: stepperField,
        before,
        after,
        isFirst: !before.length,
        afterIds,
        fieldsSelf,
        fieldsAfter,
        fieldsBefore,
        fieldsAfterExclusive,
        fieldsBeforeExclusive,
        fieldsSelfExclusive,
        next,
        nextId,
        previous,
        previousId,
      };
    });

    const fieldsDone = withsiblingBefore.map((elem, index, steppers) => {
      const imDone = elem.pathsToDataFields.every(({ dataId, toCheckHidden }) => {
        return (
          ({ ...this.data, ...data }[dataId] ?? null) !== null ||
          toCheckHidden.some((field) => this.hiddenFields.includes(field))
        );
      });
      const selfChanged = elem.pathsToDataFields.some(({ dataId }) => changedFields.includes(dataId));

      if (!elem.nextId) return;
      const isNextHidden = this.hiddenFields.includes(elem.nextId);

      // console.log(
      //   elem.id,
      //   elem.previousId,
      //   { hidden: this.hiddenFields.includes(elem.id) },
      //   { imDone },
      //   { selfChanged },
      //   { changedFields },
      //   { isNextHidden },
      //   elem.nextId
      // );
      if (!elem.previousId && this.hiddenFields.includes(elem.id)) {
        const hideTogle = this.hiders.find((hider) => hider.id === elem.id);

        hideTogle?.setHidden(false);
        // return true
      }

      if (selfChanged && imDone && isNextHidden) {
        const hideTogle = this.hiders.find((hider) => hider.id === elem.nextId);
        hideTogle?.setHidden(false);
        // return true
      }
      if (selfChanged && !imDone) {
        const cleanedFields = elem.fieldsAfterExclusive.map((elem) => {
          if (data[elem] !== null) {
            newData[elem] = null;

            return true;
          }
          return false;
        });
        if (cleanedFields.some((v) => v)) {
          this.hiders.filter((hider) => elem.afterIds.includes(hider.id)).forEach((hider) => hider.setHidden(true));
          return true;
        }
      }
      return false;
    });

    const touchedState = fieldsDone.some((v) => v);
    if (touchedState) {
      this.update({ ...data, ...newData }, false, false, false);
      return;
    }
    toAddToIsDisabled.forEach((disabled) => {
      const toDisable = isDisabled(disabled.Disabled, {
        ...this.data,
      });
      // console.log(disabled.id);
      //  if (disabled.disabled !== toDisable) console.log(disabled.id, toDisable);
      if (disabled.disabled !== toDisable) {
        disabled.setDisabled(toDisable);
      }
    });

    const { Validate, Constraints_Validate, ...formErrorsPre } =
      this.validate({
        ...this.data,
        ...data,
      }) || {};

    const formErrors = this.validateForm
      ? this.validateForm({
          data,
          allData: this.data,
          fields: Object.keys(dataIn),
          path: this.path,
          client: this.client,
          injectedValues: this.injectedValues,
          errors: formErrorsPre as AnyProperties,
        })
      : formErrorsPre;
    if (JSON.stringify(this.data?.formErrors || {}) !== JSON.stringify(formErrors || {})) {
      this.errors.forEach((error) => {
        if (Object.keys(formErrors).includes(error.dataIds)) {
          const errorsForField = Object.entries(formErrors).reduce((errorsForField, [key, value]) => {
            if (key === error.dataIds) {
              return errorsForField.concat(value);
            }
            return errorsForField;
          }, []);
          error.setErrors(errorsForField);
        } else {
          if (error.errors.length) error.setErrors([]);
        }
        if (error.dataIds === "submit") {
          const allErrors = Object.values(formErrors).reduce((allErrors, error) => {
            return allErrors.concat(error);
          }, []);
          error.setErrors(allErrors);
        }
      });
      this.update({ formErrors });
    }
    if (Object.keys(formErrors).length) return;

    if (this.afterChange && changedFields.length && !isFromAfter) {
      this.afterChange({
        path: this.path,
        update: (data: AnyProperties, isFromApollo = false, isMappingData = false, isFromAfter = true) => {
          // console.log(d);
          this.update(data, isFromApollo, isMappingData, isFromAfter);
        },
        query: this.query,
        variables: this.variables,
        data: this.data,
        dataBefore,
        operation: this.treeConfig.operation,
        entity: this.treeConfig.entity,
        changedFields,
        change: data,
        origin: isFromApollo ? "store" : "user",
        client: this.client,
        flatTreeData: this.flatTree,
        hiddenFields: this.hiders.reduce<string[]>((hiddenFields, hider) => {
          if (hider.hidden) return hiddenFields.concat(hider.id);
          return hiddenFields;
        }, []),
      });
    }

    if (
      !!(
        (!isFromApollo &&
          !isMappingData &&
          !isFromAfter &&
          this.tree!.config.saveOnChange !== false &&
          changedFields.length &&
          this.treeConfig.operation === "update") ||
        data.submit
      )
    ) {
      const dataToWrite: Record<string, any> = {};
      if (data.submit) {
        Object.keys(dataBefore).forEach((field) => {
          // @ts-ignore
          dataToWrite[field] = dataBefore[field];
        });
      } else {
        changedFields.forEach((field) => {
          // @ts-ignore
          dataToWrite[field] = data[field];
        });
      }

      this.mutate({
        // data: data.id ? dataToWrite : { ...dataToWrite, id: data.id },
        data: dataToWrite,
        fields: changedFields,
      });
    }
  };

  public getComponentProps = (tree: TreeElement) => {
    const getDefaultValue = (type?: string) => {
      switch (type) {
        case "mapping":
          return [];
        default:
          return "";
      }
    };

    const dataId = tree.dataId ? tree.dataId : undefined;
    // if (tree.config.uiTypeDisplay === "HiddenField") return {};
    const { config } = tree,
      {
        dataType,
        uiType,
        injectRaftValues,
        uiTypeDisplay,
        Hide,
        disabled: isDisabledIn = { disabled: "Inherit" },
        raftDefaultValue,
        ...params
      } = config,
      isMapping = dataType === "mapping",
      valuePre = this.data && dataId && this.data[dataId] !== null ? this.data[dataId] : undefined;

    let value = valuePre || oc(tree).config.defaultValue(getDefaultValue(dataType));

    if (dataId) {
      const defaultValue = oc(tree).config.raftDefaultValue(oc(tree).config.defaultValue(this.defaultValues[dataId]));

      if (defaultValue !== null || defaultValue != undefined) {
        this.defaultValues[dataId] = defaultValue;
        if ((valuePre === null || valuePre === undefined) && defaultValue !== null && defaultValue !== undefined) {
          value = defaultValue;
        }
      }
    }

    const isInitalHidden = this.injectedValues
      ? validateIsHidden(
          Hide || {
            Hide: "No",
            rulesFields: [],
          },
          {
            ...this.injectedValues,
            ...this.data,
          }
        )
      : uiType === "GFCFieldSet_Stepper" || Hide.Hide === "Yes";
    if (isInitalHidden && !this.hiddenFields.includes(tree.id)) {
      this.hiddenFields.push(tree.id);
    }
    const [hidden, setHidden] = React.useState(isInitalHidden);

    const hide = {
      hidden,
      setHidden,
      dataIds: Hide.rulesFields || [],
      id: tree.id,
      Hide,
    };

    const hidersIndex = this.hiders.findIndex((u) => u.id === tree.id);
    if (hidersIndex > -1) {
      this.hiders[hidersIndex] = hide;
    } else {
      this.hiders.push(hide);
    }

    // dataIds: string[];
    // disabled: any;
    // setDisabled: (data: any) => void;

    // Disabled: Disabled;
    // console.log(tree.id, isDisabledIn);
    // if (!isDisabledIn) console.log(config);
    const [disabled, setDisabled] = React.useState<boolean | undefined>(
      isDisabledIn.disabled === "Inherit"
        ? undefined
        : isDisabled(isDisabledIn, {
            ...this.injectedValues,
            ...this.data,
          })
    );

    // if (isDisabledIn.disabled !== "Inherit") {
    //   console.log(tree.id, disabled, isDisabledIn);
    // }

    const isDisabledObj: IsDisabled = {
      disabled,
      setDisabled,
      dataIds: isDisabledIn.rulesFields || [],
      id: tree.id,
      Disabled: isDisabledIn,
    };

    // console.log(isDisabledObj.dataIds, tree.id);

    const isDisabledIndex = this.isDisabled.findIndex((u) => u.id === tree.id);
    if (isDisabledIndex > -1) {
      this.isDisabled[isDisabledIndex] = isDisabledObj;
    } else {
      this.isDisabled.push(isDisabledObj);
    }
    // console.log(tree)
    const cleanedParams = cleanParams(params, this.config, this.localization, this);
    const [errors, setErrors] = React.useState<string[]>([]);
    if (cleanedParams.validate && cleanedParams.validate.Validate !== "No") {
      this.validate = (data: any) =>
        validateForm(cleanParams(params, this.config, this.localization, this).validate, data);
    } else {
      const validate: HasError = {
        errors,
        setErrors: (errs) => {
          setErrors(errs);
        },
        dataIds: dataId || "#nope",
        id: tree.id,
      };

      const validatorsIndex = this.errors.findIndex((u) => u.id === tree.id);
      if (validatorsIndex > -1) {
        this.errors[validatorsIndex] = validate;
      } else {
        this.errors.push(validate);
      }
    }
    const testObj =
      process.env.NODE_ENV !== "test" && process.env.REACT_APP_FOR_TEST !== "true"
        ? {}
        : {
            testId: `${this.treeConfig.operation}${upperFirst(this.treeConfig.entity)}_${dataId}_${uiType?.replace(
              /GFCField*_/i,
              ""
            )}`,
          };
    const props = {
      hidden,
      disabled,
      raftQuery: this.query,
      raftVariables: this.variables,
      raftId: tree.id,
      id: dataId,
      fieldId: dataId,
      errors,
      isRequiredField: !!tree.required,
      ...testObj,
      ...cleanedParams,
      update: (val: any) => this.update(val),
    };
    // console.log(dataId, hidden);

    const stateEffects = fieldState(
      value,
      dataId || "dd",
      isMapping ? this.saveMappingData : this.saveData,
      !!tree.required
    );
    if (dataId) {
      const updatersIndex = this.updaters.findIndex((u) => u.id === tree.id);
      if (updatersIndex > -1) {
        this.updaters[updatersIndex] = { dataId, stateEffects, id: tree.id };
      } else {
        this.updaters.push({ dataId, stateEffects, id: tree.id });
      }
      // console.log(dataId, this.updaters.length);

      if (isMapping) {
        props.data = { ...stateEffects, innerId: this.params.id };
      } else {
        props.data = stateEffects;
      }
    }
    const propModifyName = (uiType ?? "").replace("GFCField_", "").replace("GFCFieldSet_", "").replace("GFCForm_", "");
    if (this.modifyProps && this.modifyProps.hasOwnProperty(propModifyName ?? "") && uiType) {
      return {
        ...this.modifyProps[propModifyName](Object.freeze({ ...props, tree })),
      };
    }
    return props;
  };

  public getData = (variables: any) => {
    this.variables = variables;
    if (["update", "read"].includes(this.treeConfig.operation)) {
      try {
        const { result } = this.client.readQuery({
          query: gql(this.query),
          variables,
        }) || { result: null };

        this.update({ ...result }, true);
      } catch (e) {
        // console.log("cache empty", e);
      }
      this.client
        .query({
          query: gql(this.query),
          variables,
          fetchPolicy: "network-only",
        })
        .then(
          (
            result = {
              loading: false,
              networkStatus: 7,
              // stale: true,
              data: {
                result: {},
              },
            }
          ) => {
            // console.log(result);
            const defaultData = Object.entries({
              ...this.defaultValues,
              ...variables,
            }).reduce((newDefaultValues: { [key: string]: any }, [key, value]) => {
              if (
                (result.data.result[key] == null || result.data.result[key] == undefined) &&
                value !== null &&
                value !== undefined
              ) {
                return { ...newDefaultValues, [key]: value };
              }
              return newDefaultValues;
            }, {});

            this.update({ ...result.data.result, ...defaultData }, true);

            if (Object.keys(defaultData).length) {
              this.mutate({
                data: { ...defaultData },
                fields: Object.keys(defaultData),
                isDefaultData: true,
              });
            }
          }
        )

        .catch((e) => console.log("err", e, this.query, variables));
    }
  };

  public getForm({ path, tree, treeConfig }: { path: string; tree: TreeElement; treeConfig: any }): FormComponent {
    this.tree = tree;
    this.path = path;

    this.flatTree = getFlatData([tree]) as FlatDataItemFull[];

    this.treeConfig = treeConfig;
    this.query = generateQeuery(treeConfig, this.clientFields, this.externalFields, this.useHC11);
    this.fieldTypes = treeConfig.usedFieldTypes;
    if (!tree.children) {
      return () => <div />;
    }

    const wholeTree = this.buildTree({ tree, treeConfig, disabled: false });

    return wholeTree;
  }

  private buildTree = (treeProps: {
    tree: TreeElement;
    calls?: number;
    treeConfig?: any;
    disabled?: boolean;
  }): React.ComponentType<any> => {
    const { tree, calls = 0, treeConfig = {}, disabled: disabledPre = false } = treeProps;

    const { Component, RenderChilds } = this.getElement(tree);

    if (tree.config.uiType === "HiddenField") {
      return () => null;
    }

    const isWithFlattTree =
      ["GFCFieldSet_ForSubMenu", "GFCField_DownloadReport"].includes(tree.config.uiType || "") ||
      tree.config.getFlatTree;
    //  return () => null;

    if (!tree.children) {
      const LL = React.memo(({ id }: { id: string }) => {
        const { hidden, disabled: disabledInner, ...newProps } = this.getComponentProps(tree);
        // } = useGetComponentProps(tree, this);
        const disabled = disabledInner !== undefined ? disabledInner : disabledPre;
        if (!!hidden) {
          return null;
        }
        return <Component {...newProps} flatTree={isWithFlattTree ? this.flatTree : []} disabled={disabled} />;
      });
      return ({ id }: { id: string }) => <LL id={tree.dataId || ""} />;
    }
    return React.memo(
      (props: {
        params: DataItem[] | undefined;
        injectedValues?: any;
        afterCreate?: (data: any) => void;
        afterUpdate?: (data: any) => void;
        onUpdateDataMiddleWare?: (data: OnUpdateDataMiddleWareProps<any>) => any;
        beforeRemoteMuatation?: BeforeRemoteMuatation;
        validateForm?: ValidateForm<any, any>;
        afterDelete?: (data: any) => void;
        modifyProps?: { [key: string]: (val: any) => any };
        onCancel?: () => void;
        updateAfterCreateQueries?: QueryArray;
        updateAfterDeleteQueries?: QueryArray;
        fetchAllFieldsOnUpdate?: boolean;
      }) => {
        if (calls === 0) {
          if (props.afterCreate) {
            this.afterCreate = props.afterCreate;
          }
          if (props.onCancel) {
            this.onCancel = props.onCancel;
          }
          if (props.afterUpdate) {
            this.afterUpdate = props.afterUpdate;
          }
          if (props.onUpdateDataMiddleWare) {
            this.onUpdateDataMiddleWareLocal = props.onUpdateDataMiddleWare;
          }
          if (props.beforeRemoteMuatation) {
            this.beforeRemoteMuatationLocal = props.beforeRemoteMuatation;
          }
          if (props.validateForm) {
            this.validateForm = props.validateForm;
          }
          if (props.afterDelete) {
            this.afterDelete = props.afterDelete;
          }
          if (props.modifyProps) {
            this.modifyProps = props.modifyProps;
          }
          if (props.updateAfterCreateQueries) {
            this.updateAfterCreateQueries = props.updateAfterCreateQueries;
          }
          if (props.updateAfterCreateQueries) {
            this.updateAfterCreateQueries = props.updateAfterCreateQueries;
          }
          if (props.fetchAllFieldsOnUpdate) {
            this.fetchAllFieldsOnUpdate = props.fetchAllFieldsOnUpdate;
          }

          this.params = props.params;
          this.injectedValues = props.injectedValues;
          this.data = props.injectedValues;
          this.getData(props.params);
        }

        const { disabled: disabledInner, hidden, ...newProps } = this.getComponentProps(tree);
        // console.log(tree);
        // } = useGetComponentProps(tree, this);

        const disabled = disabledInner ? disabledInner : disabledPre;

        if (tree.config.uiType === "HiddenField") {
          return null;
        }
        if (!!hidden) {
          return (
            <>
              <div style={{ display: "none" }}>
                <Component
                  {...newProps}
                  flatTree={isWithFlattTree ? this.flatTree : []}
                  disabled={disabled}
                  hidden={hidden}
                >
                  {tree.children &&
                    tree.children.map((child: any, index: number) => {
                      const ChildInner = this.buildTree({
                        tree: child,
                        calls: calls + 1,
                        treeConfig,
                        disabled,
                      });
                      return <ChildInner key={index} id={child.id} />;
                    })}
                </Component>
              </div>
              {!calls && <Loader />}
            </>
          );
        }
        return (
          <Component {...newProps} flatTree={isWithFlattTree ? this.flatTree : []} disabled={disabled} hidden={hidden}>
            {tree.children &&
              tree.children.map((child: any, index: number) => {
                // if (child.id === "FieldSet-K202L62TJ9JQNMVUXPQ")
                //   console.log(JSON.stringify(child));
                const ChildInner = this.buildTree({
                  tree: child,
                  calls: calls + 1,
                  treeConfig,
                  disabled,
                });
                return <ChildInner key={index} includedFields={child.config.includedFields || []} tree={child} />;
              })}
          </Component>
        );
      }
    );
  };
}

export default FormGenerator;
